import { SelectOption } from "@ni/common/types";

export const feeIntervalList: SelectOption[] = [
  {
    value: "Y",
    label: "Yearly",
    tooltip: "Fee will be charged on yearly basis on your specified base date",
  },
  {
    value: "M",
    label: "Monthly",
    tooltip: "Fee will be charged on statement date after card activation",
  },
  {
    value: "N",
    label: "None",
    tooltip:
      "Membership fees will not be applied on this product. You may specify a one-time joining fee, card production fees on upcoming screens",
  },
];
