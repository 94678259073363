import { SelectOption } from "@ni/common/types";

interface LoyaltyAccrualRadioOptions {
  default?: string;
  options: SelectOption[];
}

export const RADIO_GROUP_ACCRUAL_TYPE: LoyaltyAccrualRadioOptions = {
  default: "N",
  options: [
    {
      label: "Transaction based (earn points for each eligible transaction)",
      value: "N",
    },
    {
      label: "Cumulative (earn points based on net spending during a specified period)",
      value: "C",
    },
  ],
};

export const RADIO_GROUP_ACCRUAL_FREQ: LoyaltyAccrualRadioOptions = {
  default: "B",
  options: [
    { label: "Billing cycle", value: "B" },
    { label: "Year", value: "Y" },
    {
      label: "Both (allowing customer to select preferred option)",
      value: "B-Y",
      tooltip: "Yearly accrual may offer higher accrual rates than rates per billing cycle",
    },
  ],
};

export const RADIO_GROUP_ACCRUAL_MODE: LoyaltyAccrualRadioOptions = {
  default: "AMOUNT",
  options: [
    { label: "Net amount of transactions", value: "AMOUNT" },
    { label: "Net count of transactions", value: "COUNT" },
  ],
};
