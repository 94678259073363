import { FC, useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Input, Modal, Select, Space, Switch, Typography, Upload } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import cn from "classnames";

import { CloudUploadOutlined, PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { branchListOptions, elementGroupOptions, elementTypeListOptions, uploadFilesProps } from "@ni/common/mocks";
import { SelectOption } from "@ni/common/types";
import { ElementTemplateApi } from "@ni/sdk/apis";
import { ElementTemplate, ElementType, State } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface ElementTemplateEditProps {
  elementTemplate: ElementTemplate;
  form: FormInstance;
  isElementTypeDataCapture: boolean;
  getElementTemplates?: () => void;
}

const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;

const elementTemplateServiceApi = new ElementTemplateApi();

const ElementTemplateEdit: FC<ElementTemplateEditProps> = ({
  form,
  elementTemplate,
  isElementTypeDataCapture,
  getElementTemplates = () => {},
}) => {
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [modalForm] = Form.useForm();
  const [, setIsLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string>("");

  const options = [
    { id: 1, value: "value" },
    { id: 2, value: "new value" },
  ];
  const mockGroupOptions: SelectOption[] = useMemo(
    () => [
      { value: "all", label: "All" },
      { value: "data-capture", label: "Data capture" },
      { value: "visual-element", label: "Visual element" },
    ],
    [],
  );

  useEffect(() => {
    if (elementTemplate) {
      form.setFieldsValue({
        code: elementTemplate.code,
        label: elementTemplate.label,
        elementGroup: mockGroupOptions.filter(item => item.label === elementTemplate.elementGroup) ?? "All",
        elementType: elementTemplate.elementType,
        hint: elementTemplate.hint,
        hiddenConditions: elementTemplate.hiddenConditions,
        currentState: elementTemplate.currentState,
      });
    }
  }, [elementTemplate, form, mockGroupOptions]);

  useEffect(() => {
    form.setFieldsValue({
      elementGroup: isElementTypeDataCapture ? "data-capture" : "visual-element",
    });
  }, [form, isElementTypeDataCapture]);

  const onCancelImportModalOpen = () => {
    setIsImportModalOpen(false);
  };

  const onElementTemplateSwitch = () => {
    setIsLoading(true);

    elementTemplateServiceApi
      .editElementTemplate(
        {
          currentState: elementTemplate.currentState === State.ENABLED ? State.DISABLED : State.ENABLED,
        },
        elementTemplate.id!,
      )
      .then(() => {
        if (getElementTemplates) {
          getElementTemplates();
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Space direction="vertical" size={20} className={styles["element-settings-wrapper"]}>
      <Space direction="horizontal">
        <Form.Item name="currentState" valuePropName="checked" className="m-b-0">
          <Switch
            defaultChecked={elementTemplate.currentState === State.ENABLED}
            checked={elementTemplate.currentState === State.ENABLED}
            onClick={() => onElementTemplateSwitch()}
          />
        </Form.Item>
        <Typography.Text strong={true}>Enable</Typography.Text>
      </Space>
      {isElementTypeDataCapture && (
        <div className={styles["layout"]}>
          <div className={styles["info"]}>
            <div className={styles["one-row"]}>
              <Form.Item
                className={styles["form-item"]}
                name="elementGroup"
                label="Element group"
                tooltip={{ title: "Element group", icon: <QuestionCircleFilled /> }}
              >
                <Select
                  disabled={true}
                  placeholder="Select element group"
                  onChange={(value: string) => setSelectedGroup(value)}
                >
                  {elementGroupOptions.map(option => (
                    <Option key={option.value + option.label} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles["form-item"]}
                name="elementType"
                label="Element type"
                tooltip={{ title: "Element type", icon: <QuestionCircleFilled /> }}
              >
                <Select placeholder="Select type" disabled={!selectedGroup}>
                  {Object.values(ElementType).map(item => (
                    <Select.Option key={Math.random()} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              className={styles["form-item"]}
              name="label"
              label="Label"
              tooltip={{ title: "Label", icon: <QuestionCircleFilled /> }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className={styles["form-item"]}
              name="hint"
              label="Hint text"
              tooltip={{ title: "Hint text", icon: <QuestionCircleFilled /> }}
            >
              <TextArea rows={4} />
            </Form.Item>
          </div>
          <div className={cn(styles["info"], styles["feature"])}>
            <div className={styles["one-row"]}>
              <Form.Item
                className={styles["form-item"]}
                name="feature"
                label="Feature"
                tooltip={{ title: "Feature", icon: <QuestionCircleFilled /> }}
              >
                <Input />
              </Form.Item>{" "}
              <Form.Item
                className={styles["form-item"]}
                name="code"
                label="Code"
                tooltip={{ title: "Code", icon: <QuestionCircleFilled /> }}
              >
                <Input />
              </Form.Item>
            </div>
            <Form.Item
              className={styles["form-item"]}
              name="hint"
              label="Hint text"
              tooltip={{ title: "Hint text", icon: <QuestionCircleFilled /> }}
            >
              <TextArea rows={4} />
            </Form.Item>
          </div>
          <div className={cn(styles["info"], styles["validation"])}>
            <div className={styles["one-row"]}>
              <Form.Item
                className={styles["form-item"]}
                name="disabledConditions"
                label="Disabled conditions"
                tooltip={{ title: "Disabled conditions", icon: <QuestionCircleFilled /> }}
              >
                <Input />
              </Form.Item>{" "}
              <Form.Item
                className={styles["form-item"]}
                name="hiddenConditions"
                label="Hidden conditions"
                tooltip={{ title: "Hidden conditions", icon: <QuestionCircleFilled /> }}
              >
                <Input />
              </Form.Item>
            </div>
            <div className={styles["one-row"]}>
              <Form.Item
                className={styles["form-item"]}
                name="defaultValue"
                label="Default value"
                tooltip={{ title: "Default value", icon: <QuestionCircleFilled /> }}
              >
                <Input />
              </Form.Item>{" "}
              <Form.Item
                className={styles["form-item"]}
                name="code"
                label="Code"
                tooltip={{ title: "Code", icon: <QuestionCircleFilled /> }}
              >
                <Input />
              </Form.Item>
            </div>
            <Form.Item name="editableProductStates" label="Editable product states">
              <Select
                mode="multiple"
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={options}
              />
            </Form.Item>
            <Form.Item name="editableTenantStates" label="Editable tenant states">
              <Select
                mode="multiple"
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="Please select"
                options={options}
              />
            </Form.Item>
          </div>
          <div className={styles["buttons"]}>
            <Button className={styles["add-validation"]} type="link" onClick={() => {}}>
              <PlusOutlined />
              Add validation
            </Button>
          </div>
        </div>
      )}
      {!isElementTypeDataCapture && (
        <div>
          <Form.Item
            className={styles["form-item"]}
            name="elementGroup"
            label="Element group"
            tooltip={{ title: "Element group", icon: <QuestionCircleFilled /> }}
          >
            <Select disabled={true}>
              {mockGroupOptions.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles["form-item"]}
            name="code"
            label="Code"
            tooltip={{ title: "code", icon: <QuestionCircleFilled /> }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles["form-item"]}
            name="elementType"
            label="Element type"
            tooltip={{ title: "Element type", icon: <QuestionCircleFilled /> }}
          >
            <Select>
              {elementTypeListOptions.map(item => (
                <Select.Option key={item.value + item.label} value={item.value}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles["form-item"]}
            name="label"
            label="Label"
            tooltip={{ title: "Label", icon: <QuestionCircleFilled /> }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className={styles["form-item"]}
            name="hint"
            label="Hint text"
            tooltip={{ title: "Hint text", icon: <QuestionCircleFilled /> }}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            className={styles["form-item"]}
            name="hiddenConditions"
            label="Hidden conditions"
            tooltip={{ title: "Hidden conditions", icon: <QuestionCircleFilled /> }}
          >
            <Input />
          </Form.Item>
        </div>
      )}
      <Modal
        className={styles["modal-import"]}
        title="Import element"
        open={isImportModalOpen}
        width="800px"
        onCancel={onCancelImportModalOpen}
        footer={[
          <div className={styles["modal-import-footer"]}>
            <Button className={styles["modal-import-button"]} key="back" onClick={onCancelImportModalOpen}>
              Cancel
            </Button>
            <Button
              className={styles["modal-import-button"]}
              disabled={true}
              key="submit"
              type="primary"
              loading={false}
              onClick={modalForm.submit}
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <Form form={modalForm} layout="vertical" onFinish={() => {}}>
          <Form.Item>Some description text...</Form.Item>
          <Form.Item>
            <div>Upload file</div>
            <Dragger {...uploadFilesProps}>
              <div className="dragger-title">
                <CloudUploadOutlined className={styles["button"]} />
                <p className="ant-upload-text">Drag & Drop your files here or Browse</p>
              </div>
              <p className="ant-upload-hint">Max size 10MB</p>
            </Dragger>
          </Form.Item>
          <Divider>or</Divider>

          <Form.Item
            name="newDisplayName"
            label="Import from VCS"
            rules={[{ type: "string", max: 256, message: " Cannot be empty. Max length is 256 letters" }]}
          >
            <Select placeholder="Select a branch">
              {branchListOptions.map(option => (
                <Select.Option key={option.value + option.label} value={option.value}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
};

export default ElementTemplateEdit;
