import { useEffect, useMemo, useState } from "react";
import { Form, Input, Modal } from "antd";
import { Rule, RuleObject } from "rc-field-form/lib/interface";

import { PctParams } from "@ni/common/constants";
import { FormValues } from "@ni/common/types";
import { ParameterTable } from "@ni/sdk/models";

import styles from "./styles.module.scss";

export interface ModalVisibilityConfiguration {
  isOpen: boolean;
  type: "" | "rename" | "duplicate" | "create" | "delete";
}

interface PctModalProps extends ModalVisibilityConfiguration {
  currentName: string;
  pctList: ParameterTable[];
  onCancel: () => void;
  onDuplicatePct: (value: { [PctParams.displayName]: string }) => Promise<void>;
  onCreatePct: (value: FormValues) => Promise<void>;
  onRenamePct: (value: FormValues) => Promise<void>;
  onDeletePct: () => Promise<void>;
}

const formRules = (pctNameSet: Set<string>): Rule[] => [
  { required: true, type: "string", max: 80, message: " Cannot be empty. Max length is 80 letters" },
  {
    type: "string",
    validator: (_: RuleObject, value: string) =>
      value && pctNameSet.has(value?.trim()) ? Promise.reject() : Promise.resolve(),
    message: " Pricing Control Table name already in use under this product",
  },
];

export const PctModal = ({
  currentName,
  isOpen,
  type,
  pctList,
  onCancel,
  onDuplicatePct,
  onCreatePct,
  onRenamePct,
  onDeletePct,
}: PctModalProps) => {
  const [modalForm] = Form.useForm<FormValues>();
  const [title, setTitle] = useState("");

  const pctFormRule = useMemo(() => formRules(new Set(pctList?.map(x => x?.displayName ?? "") ?? [])), [pctList]);

  useEffect(() => {
    if (!isOpen && type === "") {
      modalForm.setFieldValue(PctParams.displayName, "");
      return;
    }

    if (isOpen && type === "create") {
      setTitle("Add Pricing Control Table");
      modalForm.setFieldValue(PctParams.displayName, "");
    }

    if (isOpen && type === "rename") {
      setTitle("Rename Pricing Control Table");
      modalForm.setFieldValue(PctParams.displayName, currentName);
    }

    if (isOpen && type === "duplicate") {
      setTitle("Copy of Pricing control table will be created");
      modalForm.setFieldValue(PctParams.displayName, `Copy of ${currentName}`);
    }

    if (isOpen && type === "delete") {
      setTitle(
        pctList?.length > 1
          ? "Are you sure you want to delete this Pricing Control Table?"
          : "Unable to delete. Please create another table before deleting this one",
      );
    }
  }, [currentName, isOpen, modalForm, pctList?.length, type]);

  const submitHandler = (value: FormValues) => {
    if (type === "duplicate") {
      void onDuplicatePct(value as unknown as { [PctParams.displayName]: string }).then(() => {
        onCancel();
      });
    }

    if (type === "create") {
      void onCreatePct(value).then(() => {
        onCancel();
      });
    }

    if (type === "rename") {
      void onRenamePct(value).then(() => {
        onCancel();
      });
    }

    if (type === "delete") {
      void onDeletePct().then(() => {
        onCancel();
      });
    }
  };

  return (
    <Modal
      title={title}
      okText="Confirm"
      cancelText="Cancel"
      forceRender={true}
      getContainer={false}
      open={isOpen}
      centered={true}
      onOk={modalForm.submit}
      onCancel={onCancel}
      okButtonProps={{ hidden: type === "delete" && pctList.length < 2 }}
    >
      <Form form={modalForm} layout="vertical" onFinish={submitHandler}>
        {type === "duplicate" && (
          <Form.Item
            className={styles["pct-select-item"]}
            name={PctParams.displayName}
            label="Provide the name for the new Pricing control table"
            rules={pctFormRule}
          >
            <Input />
          </Form.Item>
        )}
        {type === "create" && (
          <Form.Item
            className={styles["pct-select-item"]}
            name={PctParams.displayName}
            label="Name"
            rules={pctFormRule}
          >
            <Input />
          </Form.Item>
        )}
        {type === "rename" && (
          <Form.Item
            className={styles["pct-select-item"]}
            name={PctParams.displayName}
            label="Name"
            rules={pctFormRule}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
