import { FC } from "react";
import { Collapse, Divider } from "antd";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

interface LinkInterface {
  href: string;
  label: string;
}

interface CollapsedLinksProps {
  header?: string;
  list?: LinkInterface[];
}

const { Panel } = Collapse;

export const CollapsedLinks: FC<CollapsedLinksProps> = ({ header = "Related Links", list = [] }) => {
  return (
    <>
      <Divider dashed={true} />
      <Collapse defaultActiveKey={["1"]} ghost={true} className={styles["collapsed-links"]}>
        <Panel header={header} key="1">
          {list.map(link => (
            <Link key={link.href} to={link.href}>
              {link.label}
            </Link>
          ))}
        </Panel>
      </Collapse>
    </>
  );
};
