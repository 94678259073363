import { FC } from "react";
import { Button, Card, Tooltip } from "antd";

import { BlockOutlined, DeleteOutlined, EditOutlined, LockOutlined, SettingOutlined } from "@ant-design/icons";
import { useProductStateFormDisabled } from "@ni/common/hooks";
import { CopyIcon } from "@ni/common/icons";

import styles from "./styles.module.scss";

interface PctListItemProps {
  rowId: string;
  externalCode?: string;
  pctId: number;
  displayedName?: string;
  isLoading: boolean;
  renamePctHandler: (pct: number) => void;
  editPctHandler: (pct: number) => void;
  duplicateClickHandler: (pct: number) => void;
  deletePctHandler: (pctId: number) => void;
}

export const PctListItem: FC<PctListItemProps> = ({
  rowId,
  externalCode,
  pctId,
  displayedName,
  isLoading,
  renamePctHandler,
  editPctHandler,
  duplicateClickHandler,
  deletePctHandler,
}: PctListItemProps) => {
  const isDisabled = useProductStateFormDisabled();

  return (
    <div className={styles["list-item"]}>
      <Card
        actions={[
          <Tooltip key="renameIcon" title="Rename" className={styles["pct-icon"]}>
            <Button
              type="text"
              htmlType="button"
              icon={<EditOutlined key="edit" />}
              disabled={isDisabled}
              onClick={() => renamePctHandler(pctId)}
            />
          </Tooltip>,
          <Tooltip key="copyIcon" title="Duplicate" className={styles["pct-icon"]}>
            <Button
              type="text"
              htmlType="button"
              icon={<CopyIcon key="copy" />}
              disabled={isDisabled}
              onClick={() => duplicateClickHandler(pctId)}
            />
          </Tooltip>,

          <Tooltip key="settingIcon" title={isDisabled ? "Review" : "Edit"} className={styles["pct-icon"]}>
            <Button
              type="text"
              htmlType="button"
              icon={isDisabled ? <BlockOutlined /> : <SettingOutlined key="setting" />}
              onClick={() => editPctHandler(pctId)}
            />
          </Tooltip>,
        ]}
        style={{ minWidth: 300 }}
        loading={isLoading}
      >
        <Card.Meta
          title={
            <div className={styles["pct-displayed-name"]} title={displayedName}>
              <div>{displayedName}</div>
              <Tooltip key="deleteIcon" title="Delete" className={styles["pct-delete-icon"]}>
                <Button
                  type="text"
                  htmlType="button"
                  icon={isDisabled ? <LockOutlined key="lock" /> : <DeleteOutlined key="delete" />}
                  disabled={isDisabled}
                  onClick={() => deletePctHandler(pctId)}
                />
              </Tooltip>
            </div>
          }
          description={<div className={styles["pct-external-code"]}>{externalCode || `00${rowId}`.slice(-3)}</div>}
        />
      </Card>
    </div>
  );
};
