import { FC, useEffect, useState } from "react";
import { Form, Input, Mentions, Select } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { Variable, VariableGroup } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface EventGroupEditProps {
  form: FormInstance;
  smsVariableList: Variable[];
}
const EventGroupEdit: FC<EventGroupEditProps> = ({ form, smsVariableList }) => {
  const [variableGroupsOptions] = useReduxState<VariableGroup[]>("variableGroupsOptions", []);
  const [isEnabled, setIsEnabled] = useState(false);

  const onChangeValue = (value: string) => {
    form?.setFieldsValue({
      messageContent: value.replace("%%", "%"),
    });
  };

  useEffect(() => {
    if (form.getFieldValue("variableGroups")?.length) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [form.getFieldValue("variableGroups")]);

  return (
    <div className={styles["event-group-settings-wrapper"]}>
      <div className={styles["title"]}>Group Settings</div>
      <Form.Item name="groupName" label="Group name" required={true}>
        <Input />
      </Form.Item>
      <Form.Item name="applyConditions" label="Apply conditions">
        <Input />
      </Form.Item>
      <Form.Item className={styles["phase-input-long"]} label="Variable groups" name="variableGroups">
        <Select mode="multiple">
          {variableGroupsOptions?.map(type => (
            <Select.Option key={type.id} value={type.name}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className={styles["title"]}>SUBGroup settings</div>
      <Form.Item name="name" label="Subgroup name">
        <Input />
      </Form.Item>
      <Form.Item name="applyConditionsSG" label="Apply conditions">
        <Input />
      </Form.Item>
      <div className={styles["title"]}>general SMS template</div>
      <Form.Item
        className={styles["form-item"]}
        name="messageContent"
        label="Message content"
        tooltip={{
          title: "Message text",
          icon: <QuestionCircleFilled />,
        }}
      >
        <Mentions
          rows={6}
          prefix="%"
          onChange={onChangeValue}
          disabled={!isEnabled}
          options={smsVariableList?.map(smsVariable => ({
            key: smsVariable?.code,
            label: smsVariable.description,
            value: smsVariable?.code,
          }))}
        />
      </Form.Item>
    </div>
  );
};

export default EventGroupEdit;
