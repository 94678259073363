import { Switch } from "antd";
import cn from "classnames";
import type { FC } from "react";

import { CopyOutlined, DeleteOutlined, HolderOutlined, UpOutlined } from "@ant-design/icons";
import { State } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface ExtraHeaderProps {
  itemName: string;
  index: number;
  isActive?: boolean;
  isShowArrow: boolean;
  phasePagesLength?: number;
  isShowDescription: boolean;
}

interface ExtraPanelProps {
  onSwitchPhase: () => void;
  onCopyModalClick: () => void;
  onDeleteModalClick: () => void;
  phaseState: string;
}

export const extraHeader: FC<ExtraHeaderProps> = ({
  itemName,
  index,
  isActive,
  isShowArrow,
  phasePagesLength,
  isShowDescription,
}) => {
  return (
    <div className={styles[`collapse-header`]} key={index}>
      <HolderOutlined style={{ fontSize: "150%" }} />
      <div className={styles["order"]}>{index + 1 ?? "-"}</div>
      <div className={styles["title"]}>
        <div className={styles["name"]}>
          {itemName}
          {isShowArrow && isActive ? (
            <UpOutlined className={styles["arrow"]} />
          ) : (
            <UpOutlined className={cn(styles["arrow"], styles["open"])} />
          )}
        </div>
        {isShowDescription && <div className={styles["description"]}>{phasePagesLength} pages</div>}
      </div>
    </div>
  );
};

export const extraPanel: FC<ExtraPanelProps> = ({
  onSwitchPhase,
  onCopyModalClick,
  onDeleteModalClick,
  phaseState,
}) => {
  return (
    <div className={styles["collapse-header-extra"]} key={Math.random()}>
      <Switch
        defaultChecked={phaseState === State.ENABLED}
        checked={phaseState === State.ENABLED}
        onClick={onSwitchPhase}
      />
      <CopyOutlined className={styles["button"]} onClick={onCopyModalClick} />
      <DeleteOutlined className={styles["button"]} onClick={onDeleteModalClick} />
    </div>
  );
};
