import { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import update from "immutability-helper";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { phaseTypeOptions } from "@ni/common/mocks";
import { Spin } from "@ni/common/ui";
import { onConditionChangeValidation } from "@ni/common/utils";
import { PageApi, PhaseApi } from "@ni/sdk/apis";
import {
  BatchChangePagesRequest,
  ChangePhaseRequest,
  CreatePhaseRequest,
  Page,
  Phase,
  PhaseType,
  PlainPage,
  State,
} from "@ni/sdk/models";

import { ItemTypes } from "../../constants";
import PageCard from "../PageCard";
import PageDrawer from "../PageDrawer";

import styles from "./styles.module.scss";

const phaseServiceApi = new PhaseApi();
const pageServiceApi = new PageApi();

interface PhaseFormProps {
  phase: Phase;
  workflowId: number;
  getPhases: () => void;
}

interface PhaseDetailsForm {
  id: number;
  code: string;
  phaseName: string;
  skipConditions: string;
  order: number;
  phaseType: PhaseType;
  state: State;
}

const PhaseForm: FC<PhaseFormProps> = props => {
  const { phase, workflowId, getPhases } = props;

  const [phaseForm] = Form.useForm();
  const [phaseItems, setPhaseItems] = useReduxState<Phase[]>("phaseItems", []);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedPage, setSelectedPage] = useState<Page>({});
  const [newPage, setNewPage] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [maxOrderValue, setMaxOrderValue] = useState<number>(-1);
  const [pageItems, setPageItems] = useReduxState<Page[]>(`${phase.id!}-pageItems`);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!isLoading) {
      phaseForm.setFieldsValue({
        id: phase.id || undefined,
        code: phase.code || "",
        name: phase.name || "",
        skipConditions: phase.skipConditions || "",
        order: phase.order || "",
        phaseType: phase.phaseType || "",
        state: phase.state || "",
      });
    }
  }, []);

  const onEditHandleClick = (value: Page) => {
    setNewPage(false);
    setVisible(true);
    setSelectedPage(value);
  };

  const onGetPages = () => {
    setIsLoading(true);
    pageServiceApi
      .getPages(Number(id), Number(phase.id))
      .then(response => {
        setPageItems(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const closeDrawerHandler = () => {
    setVisible(false);
    onGetPages();
  };

  useEffect(() => {
    if (phase.pages?.length) {
      onGetPages();
    }
  }, []);

  useEffect(() => {
    const maxOrder = phaseItems.reduce((max, obj) => (max.order! > obj.order! ? max : obj));
    setMaxOrderValue(maxOrder.order!);
  }, [phaseItems]);

  const onSavePhase = (requestBody: ChangePhaseRequest, phaseId: string) => {
    phaseServiceApi
      .editPhase(requestBody, Number(id), Number(phaseId))
      .then(response => {
        const newPhaseItemsArray = phaseItems.map(element => {
          if (element.id === response.data.id) {
            return response.data;
          }
          return element;
        });
        setPhaseItems(newPhaseItemsArray);
      })
      .catch(() => {});
  };

  const onCreatePhase = (requestBody: CreatePhaseRequest) => {
    setIsLoading(true);
    phaseServiceApi
      .createPhase(requestBody, Number(id))
      .then(response => {
        setIsLoading(false);
        const newPhaseItemsArray = phaseItems.map(element => {
          if (element.id === response.data.id) {
            return response.data;
          }
          return element;
        });
        setPhaseItems(newPhaseItemsArray);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onFinish = (values: PhaseDetailsForm) => {
    const requestBody: ChangePhaseRequest = {
      code: values.code,
      name: values.phaseName,
      skipConditions: values.skipConditions,
      order: values.order,
      phaseType: values.phaseType,
      state: values.state,
    };

    if (values.id) {
      return onSavePhase(requestBody as PhaseDetailsForm, String(values.id));
    }
    if (values.id === undefined) {
      return onCreatePhase({ ...requestBody, state: State.ENABLED, order: maxOrderValue + 1 } as CreatePhaseRequest);
    }

    return false;
  };

  const onCreateBtn = () => {
    setNewPage(true);
    setVisible(true);
  };

  const onValueChange = (): void => {
    const isFieldsFilled =
      phaseForm.getFieldValue("phaseName")?.length > 0 &&
      phaseForm.getFieldValue("code")?.length > 0 &&
      phaseForm.getFieldValue("phaseType")?.length > 0;

    setIsEnabled(
      isFieldsFilled &&
        ((phaseForm.getFieldValue("skipConditions")?.length > 0 &&
          onConditionChangeValidation(String(phaseForm.getFieldValue("skipConditions")))) ||
          phaseForm.getFieldValue("skipConditions") === undefined ||
          phaseForm.getFieldValue("skipConditions")?.length === 0),
    );
  };

  const findPageCard = useCallback(
    (pageId: string) => {
      const card = pageItems?.filter(c => c.id === Number(pageId))[0] as {
        id: number;
        code: string;
        phaseName: string;
        skipConditions: string;
        order: number;
        phaseType: PhaseType;
        state: State;
      };
      return {
        card,
        index: pageItems?.indexOf(card),
      };
    },
    [pageItems],
  );

  const onUpdatePagesOrder = (items: Page[]) => {
    setIsLoading(true);

    const requestBody: PlainPage[] = items?.map((item, index) => {
      return {
        id: item.id!,
        code: item.code || "",
        description: item.description || "",
        header: item.header || "",
        hiddenConditions: item.hiddenConditions || undefined,
        order: index,
        state: item.state || State.DISABLED,
      };
    });

    const pageList: BatchChangePagesRequest = {
      pages: requestBody,
    };

    pageServiceApi
      .putPages(pageList, workflowId, phase.id!)
      .then(() => {
        onGetPages();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const movePageCard = useCallback(
    (pageId: string, atIndex: number) => {
      const { card, index } = findPageCard(pageId);

      setPageItems(
        update(pageItems, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },

    [findPageCard, pageItems, setPageItems],
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.PAGE }));

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Form
        id="phaseForm"
        className={styles["phase-details-form"]}
        form={phaseForm}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={onValueChange}
        initialValues={{ id: phase.id || undefined, phaseName: phase.name || "" }}
        key={phase.id}
      >
        <div className={styles["phase-details-form-info"]}>
          <div className={styles["title"]}>PHASE</div>
          <div className={styles["phase-details-form-inputs"]}>
            <Form.Item hidden={true} name={["id"]} />
            <Form.Item hidden={true} name={["order"]} />
            <Form.Item hidden={true} name={["state"]} />

            <Form.Item
              className={styles["phase-input"]}
              label="Phase name"
              name="phaseName"
              rules={[{ required: true, message: "Phase name is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className={styles["phase-input"]}
              label="Code"
              name="code"
              rules={[{ required: true, message: "Phase code is required" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={styles["phase-details-form-inputs"]}>
            <Form.Item className={styles["phase-input"]} label="Skip conditions" name="skipConditions">
              <Input />
            </Form.Item>
            <Form.Item
              className={styles["phase-input"]}
              label="Type"
              name="phaseType"
              rules={[{ required: true, message: "Phase type is required" }]}
            >
              <Select>
                {phaseTypeOptions?.map(type => (
                  <Select.Option key={type.label + type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Button
            form="phaseForm"
            loading={isLoading}
            className={styles["submit-button"]}
            type="primary"
            size="large"
            key="submit"
            htmlType="submit"
            disabled={!isEnabled}
          >
            Save
          </Button>
        </div>
        <div className={styles["phase-details-form-pages"]}>
          <div className={styles["title"]}>PAGES</div>
          <DndProvider backend={HTML5Backend} key={phase.id}>
            <div
              ref={drop}
              className={styles["draggable-phases"]}
              onDragOver={e => {
                e.preventDefault();
              }}
              onDragEnd={e => {
                e.preventDefault();
              }}
              onDragLeave={e => {
                e.preventDefault();
              }}
            >
              {pageItems?.length ? (
                pageItems
                  .filter(pageItem => pageItem.state !== State.DELETED)
                  ?.map((page, index) => (
                    <PageCard
                      page={page}
                      key={page.id ?? `new-${page.order ?? "page"}`}
                      id={String(page.id)}
                      phaseId={phase.id!}
                      movePageCard={movePageCard}
                      findPageCard={findPageCard}
                      index={index}
                      workflowId={workflowId}
                      pageItems={pageItems}
                      onUpdatePagesOrder={onUpdatePagesOrder}
                      onEditHandleClick={onEditHandleClick}
                    />
                  ))
              ) : (
                <div className={styles["empty-pages"]}>This phase does not have pages yet.</div>
              )}
            </div>
          </DndProvider>
          <div className={styles["phase-details-form-buttons"]}>
            <Button
              className={styles["button"]}
              type="link"
              htmlType="button"
              onClick={onCreateBtn}
              loading={isLoading}
            >
              <PlusOutlined />
              Add page
            </Button>
          </div>
        </div>
      </Form>
      <PageDrawer
        opened={visible}
        closeDrawer={closeDrawerHandler}
        page={selectedPage}
        newPage={newPage}
        workflowId={Number(id)}
        phaseId={phase.id as number}
        getPhases={getPhases}
        pageItems={pageItems}
      />
    </>
  );
};

export default PhaseForm;
