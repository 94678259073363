import { useReduxState } from "../store/useReduxState";

export const useTopBarLoader = () => {
  const [state, setState] = useReduxState<boolean>("isLoading");
  const startLoading = () => {
    setState(true);
  };
  const stopLoading = () => {
    setState(false);
  };

  return { isLoading: state, startLoading, stopLoading };
};
