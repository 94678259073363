import { FC, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useTenant } from "@ni/common/hooks";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";

import { usePlanApi } from "../../../hooks";

import { pagesRoute } from "./route";

import styles from "../../../Style.module.scss";

export const InstallmentPaymentPlansEdit: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: tenantId, planId } = useParams<{ id: string; planId: string }>();

  const { tenant } = useTenant({ tenantId: parseInt(tenantId ?? "0", 10) });
  const { currentPlan } = usePlanApi(parseInt(planId ?? "0", 10));

  const handleIppChange = useCallback(
    (id: number): void => {
      navigate(`/tenant/${tenantId}/edit-ipp/${id}/${getLastRoute(location.pathname)}`);
    },
    [location.pathname, navigate, tenantId],
  );

  return (
    currentPlan?.id &&
    tenant?.plans?.length &&
    handleIppChange && (
      <RoutesHandler
        routePrefix="/"
        routesList={pagesRoute}
        deps={[currentPlan?.id, tenant?.plans, handleIppChange]}
        ParentComponent={({ navItems, children }) => (
          <div className={styles["ni-program"]}>
            <Sidenav items={navItems} disabledItemsViewType="hidden">
              <SidenavEntitySelect value={currentPlan?.id} entities={tenant?.plans ?? []} onChange={handleIppChange} />
            </Sidenav>
            <div className={styles["ni-program-content"]}>{children}</div>
          </div>
        )}
      />
    )
  );
};
