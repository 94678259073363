import { FC, useEffect } from "react";
import { Form } from "antd";

import { PRODUCT_IDS } from "@ni/common/constants";
import { useGetProductsToApply, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, ProductCardList, TooltipInfo } from "@ni/common/ui";
import { FullDashboardProduct, LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

export const LoyaltyApplying: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);
  const { processWizardRequest, setAppliedProducts, appliedProducts } = useLoyaltyWizardApi();

  const { products: productsList } = useGetProductsToApply(
    wizardResponse?.tenant?.id as number,
    wizardResponse.loyaltyProgramTemplate?.type === "NORMAL" ? "N" : "C",
  );

  useEffect(() => {
    if (appliedProducts?.length) {
      form.setFieldValue(
        PRODUCT_IDS,
        appliedProducts.map(x => x.product.id),
      );
    } else {
      form.setFieldValue(PRODUCT_IDS, productsList?.map(x => x.product.id) ?? []);
    }
  }, [appliedProducts, form, productsList]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      if (typeof (values[PRODUCT_IDS] as number[])?.length === "number") {
        await processWizardRequest({
          pageId: wizardResponse.pageId as number,
          tenantId: wizardResponse?.tenant?.id,
          loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
          collectedValues: {
            [PRODUCT_IDS]: (values[PRODUCT_IDS] as number[]).join(","),
          } as unknown as LoyaltyWizardRequest["collectedValues"],
        });
        setAppliedProducts(
          (values[PRODUCT_IDS] as number[])?.map((id: number) =>
            productsList?.find(x => x?.product?.id === id),
          ) as FullDashboardProduct[],
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={false}
      pageTitle="Loyalty Applying to Products"
      pageSubtitle={
        <TooltipInfo
          label="Select the products you want to apply the newly created loyalty program template"
          tooltipProps={{
            title:
              "After wizard completing you can change specific parameters for particular products and create more loyalty programs",
          }}
        />
      }
      size="sm"
      formSize="full"
      level="tenant"
      submitHandler={onFinish}
      submitLabel="Complete Setup"
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item name={PRODUCT_IDS}>
        <ProductCardList products={productsList} hasCheckAll={true} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
