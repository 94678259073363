import axios from "axios";

import { KeycloakInterceptor } from "@ni/common/utils";

export default function withInterceptors<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>) {
  KeycloakInterceptor(axios);

  return function WithInterceptors(props: P): JSX.Element {
    return <Component {...props} />;
  };
}
