/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { Collapse, TableColumnType } from "antd";

import { Tab } from "@ni/common/types";

import { Tabs } from "../Tabs";

import { ScenariosTable, TableData } from "./ScenariosTable";

import styles from "./styles.module.scss";

const { Panel } = Collapse;

export const CollapsedTable = ({
  title,
  tabsList,
  columns,
}: {
  title: string;
  tabsList: { title: string; tableData: TableData }[];
  columns: TableColumnType<{ [x: string]: any }>[];
}) => {
  const pages: Tab<string>[] = useMemo(
    () =>
      tabsList.map(
        (x, index): Tab<string> => ({
          key: index.toString(),
          label: <> {x.title} </>,
          children: <ScenariosTable tableData={x.tableData} columns={columns} />,
        }),
      ),
    [columns, tabsList],
  );
  return (
    <Collapse ghost={true} className={styles["collapsed-table"]}>
      <Panel header={title} key="1">
        {pages.length > 1 ? (
          <Tabs pagesList={pages} disableTabKey={true} isCheckEnabled={false} discardAfterChangeTab={false} />
        ) : (
          pages[0].children
        )}
      </Panel>
    </Collapse>
  );
};
