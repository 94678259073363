/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InitWizardRequest } from '../model';
// @ts-ignore
import { InitWizardResponse } from '../model';
// @ts-ignore
import { TenantProductWizardRequest } from '../model';
// @ts-ignore
import { TenantProductWizardResponse } from '../model';
/**
 * ProductAndTenantWizardApi - axios parameter creator
 * @export
 */
export const ProductAndTenantWizardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Initializes Product creation Wizard for given TenantId and returns Workflow and Workflow page. If TenantId is not provided then Tenant Management Phase is starting.
         * @summary Initialize Product creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWizard: async (initWizardRequest: InitWizardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initWizardRequest' is not null or undefined
            assertParamExists('initWizard', 'initWizardRequest', initWizardRequest)
            const localVarPath = `/api/v1/wizard/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initWizardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores collected data for given TenantId and ProductId and returns Workflow and Workflow page
         * @summary Store collected data for given TenantId and ProductId
         * @param {TenantProductWizardRequest} tenantProductWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processWizardRequest: async (tenantProductWizardRequest: TenantProductWizardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantProductWizardRequest' is not null or undefined
            assertParamExists('processWizardRequest', 'tenantProductWizardRequest', tenantProductWizardRequest)
            const localVarPath = `/api/v1/wizard/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantProductWizardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAndTenantWizardApi - functional programming interface
 * @export
 */
export const ProductAndTenantWizardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductAndTenantWizardApiAxiosParamCreator(configuration)
    return {
        /**
         * Initializes Product creation Wizard for given TenantId and returns Workflow and Workflow page. If TenantId is not provided then Tenant Management Phase is starting.
         * @summary Initialize Product creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initWizard(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitWizardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initWizard(initWizardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stores collected data for given TenantId and ProductId and returns Workflow and Workflow page
         * @summary Store collected data for given TenantId and ProductId
         * @param {TenantProductWizardRequest} tenantProductWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processWizardRequest(tenantProductWizardRequest: TenantProductWizardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantProductWizardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processWizardRequest(tenantProductWizardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductAndTenantWizardApi - factory interface
 * @export
 */
export const ProductAndTenantWizardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductAndTenantWizardApiFp(configuration)
    return {
        /**
         * Initializes Product creation Wizard for given TenantId and returns Workflow and Workflow page. If TenantId is not provided then Tenant Management Phase is starting.
         * @summary Initialize Product creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWizard(initWizardRequest: InitWizardRequest, options?: any): AxiosPromise<InitWizardResponse> {
            return localVarFp.initWizard(initWizardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores collected data for given TenantId and ProductId and returns Workflow and Workflow page
         * @summary Store collected data for given TenantId and ProductId
         * @param {TenantProductWizardRequest} tenantProductWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processWizardRequest(tenantProductWizardRequest: TenantProductWizardRequest, options?: any): AxiosPromise<TenantProductWizardResponse> {
            return localVarFp.processWizardRequest(tenantProductWizardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductAndTenantWizardApi - interface
 * @export
 * @interface ProductAndTenantWizardApi
 */
export interface ProductAndTenantWizardApiInterface {
    /**
     * Initializes Product creation Wizard for given TenantId and returns Workflow and Workflow page. If TenantId is not provided then Tenant Management Phase is starting.
     * @summary Initialize Product creation Wizard
     * @param {InitWizardRequest} initWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAndTenantWizardApiInterface
     */
    initWizard(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig): AxiosPromise<InitWizardResponse>;

    /**
     * Stores collected data for given TenantId and ProductId and returns Workflow and Workflow page
     * @summary Store collected data for given TenantId and ProductId
     * @param {TenantProductWizardRequest} tenantProductWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAndTenantWizardApiInterface
     */
    processWizardRequest(tenantProductWizardRequest: TenantProductWizardRequest, options?: AxiosRequestConfig): AxiosPromise<TenantProductWizardResponse>;

}

/**
 * ProductAndTenantWizardApi - object-oriented interface
 * @export
 * @class ProductAndTenantWizardApi
 * @extends {BaseAPI}
 */
export class ProductAndTenantWizardApi extends BaseAPI implements ProductAndTenantWizardApiInterface {
    /**
     * Initializes Product creation Wizard for given TenantId and returns Workflow and Workflow page. If TenantId is not provided then Tenant Management Phase is starting.
     * @summary Initialize Product creation Wizard
     * @param {InitWizardRequest} initWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAndTenantWizardApi
     */
    public initWizard(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig) {
        return ProductAndTenantWizardApiFp(this.configuration).initWizard(initWizardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores collected data for given TenantId and ProductId and returns Workflow and Workflow page
     * @summary Store collected data for given TenantId and ProductId
     * @param {TenantProductWizardRequest} tenantProductWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAndTenantWizardApi
     */
    public processWizardRequest(tenantProductWizardRequest: TenantProductWizardRequest, options?: AxiosRequestConfig) {
        return ProductAndTenantWizardApiFp(this.configuration).processWizardRequest(tenantProductWizardRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
