/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyLoyaltyProgramTemplateRequest } from '../model';
// @ts-ignore
import { ChangeLoyaltyProgramTemplateRequest } from '../model';
// @ts-ignore
import { CopyLoyaltyProgramTemplateRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { LoyaltyProgram } from '../model';
// @ts-ignore
import { LoyaltyProgramTemplate } from '../model';
/**
 * LoyaltyProgramTemplateApi - axios parameter creator
 * @export
 */
export const LoyaltyProgramTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Applies Loyalty Program Template to selected products
         * @summary Apply Loyalty Program Template for selected products
         * @param {ApplyLoyaltyProgramTemplateRequest} applyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToProducts: async (applyLoyaltyProgramTemplateRequest: ApplyLoyaltyProgramTemplateRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyLoyaltyProgramTemplateRequest' is not null or undefined
            assertParamExists('applyToProducts', 'applyLoyaltyProgramTemplateRequest', applyLoyaltyProgramTemplateRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applyToProducts', 'id', id)
            const localVarPath = `/api/v1/loyalty-program-templates/{id}/loyalty-programs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyLoyaltyProgramTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies Loyalty Program Template by ID with new DisplayName and returns created one
         * @summary Copy Loyalty Program Template by OriginalId
         * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy1: async (copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyLoyaltyProgramTemplateRequest' is not null or undefined
            assertParamExists('copy1', 'copyLoyaltyProgramTemplateRequest', copyLoyaltyProgramTemplateRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copy1', 'id', id)
            const localVarPath = `/api/v1/loyalty-program-templates/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyLoyaltyProgramTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Loyalty Program Template by ID
         * @param {ChangeLoyaltyProgramTemplateRequest} changeLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit2: async (changeLoyaltyProgramTemplateRequest: ChangeLoyaltyProgramTemplateRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeLoyaltyProgramTemplateRequest' is not null or undefined
            assertParamExists('edit2', 'changeLoyaltyProgramTemplateRequest', changeLoyaltyProgramTemplateRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('edit2', 'id', id)
            const localVarPath = `/api/v1/loyalty-program-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeLoyaltyProgramTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Loyalty Program Template by ID
         * @summary Get Loyalty Program Template by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById1', 'id', id)
            const localVarPath = `/api/v1/loyalty-program-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyProgramTemplateApi - functional programming interface
 * @export
 */
export const LoyaltyProgramTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyProgramTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Applies Loyalty Program Template to selected products
         * @summary Apply Loyalty Program Template for selected products
         * @param {ApplyLoyaltyProgramTemplateRequest} applyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyToProducts(applyLoyaltyProgramTemplateRequest: ApplyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyToProducts(applyLoyaltyProgramTemplateRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copies Loyalty Program Template by ID with new DisplayName and returns created one
         * @summary Copy Loyalty Program Template by OriginalId
         * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copy1(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyProgramTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copy1(copyLoyaltyProgramTemplateRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Loyalty Program Template by ID
         * @param {ChangeLoyaltyProgramTemplateRequest} changeLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit2(changeLoyaltyProgramTemplateRequest: ChangeLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyProgramTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit2(changeLoyaltyProgramTemplateRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Loyalty Program Template by ID
         * @summary Get Loyalty Program Template by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyProgramTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyProgramTemplateApi - factory interface
 * @export
 */
export const LoyaltyProgramTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyProgramTemplateApiFp(configuration)
    return {
        /**
         * Applies Loyalty Program Template to selected products
         * @summary Apply Loyalty Program Template for selected products
         * @param {ApplyLoyaltyProgramTemplateRequest} applyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToProducts(applyLoyaltyProgramTemplateRequest: ApplyLoyaltyProgramTemplateRequest, id: number, options?: any): AxiosPromise<Array<LoyaltyProgram>> {
            return localVarFp.applyToProducts(applyLoyaltyProgramTemplateRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies Loyalty Program Template by ID with new DisplayName and returns created one
         * @summary Copy Loyalty Program Template by OriginalId
         * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy1(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: any): AxiosPromise<LoyaltyProgramTemplate> {
            return localVarFp.copy1(copyLoyaltyProgramTemplateRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Loyalty Program Template by ID
         * @param {ChangeLoyaltyProgramTemplateRequest} changeLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit2(changeLoyaltyProgramTemplateRequest: ChangeLoyaltyProgramTemplateRequest, id: number, options?: any): AxiosPromise<LoyaltyProgramTemplate> {
            return localVarFp.edit2(changeLoyaltyProgramTemplateRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Loyalty Program Template by ID
         * @summary Get Loyalty Program Template by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1(id: number, options?: any): AxiosPromise<LoyaltyProgramTemplate> {
            return localVarFp.getById1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyProgramTemplateApi - interface
 * @export
 * @interface LoyaltyProgramTemplateApi
 */
export interface LoyaltyProgramTemplateApiInterface {
    /**
     * Applies Loyalty Program Template to selected products
     * @summary Apply Loyalty Program Template for selected products
     * @param {ApplyLoyaltyProgramTemplateRequest} applyLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApiInterface
     */
    applyToProducts(applyLoyaltyProgramTemplateRequest: ApplyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Array<LoyaltyProgram>>;

    /**
     * Copies Loyalty Program Template by ID with new DisplayName and returns created one
     * @summary Copy Loyalty Program Template by OriginalId
     * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApiInterface
     */
    copy1(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<LoyaltyProgramTemplate>;

    /**
     * 
     * @summary Edit Loyalty Program Template by ID
     * @param {ChangeLoyaltyProgramTemplateRequest} changeLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApiInterface
     */
    edit2(changeLoyaltyProgramTemplateRequest: ChangeLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<LoyaltyProgramTemplate>;

    /**
     * Returns Loyalty Program Template by ID
     * @summary Get Loyalty Program Template by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApiInterface
     */
    getById1(id: number, options?: AxiosRequestConfig): AxiosPromise<LoyaltyProgramTemplate>;

}

/**
 * LoyaltyProgramTemplateApi - object-oriented interface
 * @export
 * @class LoyaltyProgramTemplateApi
 * @extends {BaseAPI}
 */
export class LoyaltyProgramTemplateApi extends BaseAPI implements LoyaltyProgramTemplateApiInterface {
    /**
     * Applies Loyalty Program Template to selected products
     * @summary Apply Loyalty Program Template for selected products
     * @param {ApplyLoyaltyProgramTemplateRequest} applyLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApi
     */
    public applyToProducts(applyLoyaltyProgramTemplateRequest: ApplyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramTemplateApiFp(this.configuration).applyToProducts(applyLoyaltyProgramTemplateRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies Loyalty Program Template by ID with new DisplayName and returns created one
     * @summary Copy Loyalty Program Template by OriginalId
     * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApi
     */
    public copy1(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramTemplateApiFp(this.configuration).copy1(copyLoyaltyProgramTemplateRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Loyalty Program Template by ID
     * @param {ChangeLoyaltyProgramTemplateRequest} changeLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApi
     */
    public edit2(changeLoyaltyProgramTemplateRequest: ChangeLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramTemplateApiFp(this.configuration).edit2(changeLoyaltyProgramTemplateRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Loyalty Program Template by ID
     * @summary Get Loyalty Program Template by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramTemplateApi
     */
    public getById1(id: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramTemplateApiFp(this.configuration).getById1(id, options).then((request) => request(this.axios, this.basePath));
    }
}
