import { FC } from "react";
import { Form, Space } from "antd";
import cn from "classnames";
import { Link, useParams } from "react-router-dom";

import { ArrowRightOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { authBlockResponseOptions } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import {
  CustomFormWrapper,
  NetworkForm,
  PageItemLayoutElements,
  PageItemLayoutGroup,
  TooltipInfo,
} from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant, TenantValue } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

const stringsKeys = [
  "nic-unique-client-reg-number",
  "nic-unique-client-number",
  "nic-cs-domain-noncompl",
  "nic-noncompl-auth-block-3",
  "nic-noncompl_level_a-recalc-client-update",
  "nic-noncompl_level_a-recalc-dec-daily-sa",
  "nic-noncompl_c-auth-block-3",
  "nic-noncompl_level_c-suppl-recalc-client-update",
  "nic-noncompl_level_c-suppl-recalc-dec-daily-sa",
  "nic-exid-use",
];

const fieldsToReset: (keyof FormValues)[] = [
  "nic-noncompl-auth-block-3",
  "nic-noncompl_level_a-recalc-client-update",
  "nic-noncompl_level_a-recalc-dec-daily-sa",
  "nic-noncompl_c-auth-block-3",
  "nic-noncompl_level_c-suppl-recalc-client-update",
  "nic-noncompl_level_c-suppl-recalc-dec-daily-sa",
];

export const ClientIdentification: FC = () => {
  const [form] = Form.useForm();
  const { id: tenantId } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useHydrateForm({
    form,
    entityFields: tenant.tenantValues ?? [],
    keys: {
      strings: stringsKeys,
    },
  });

  const resetDisabledFormValues = () => {
    const resetValues = fieldsToReset.reduce((acc: Partial<FormValues>, fieldName) => {
      acc[fieldName] = "";
      return acc;
    }, {});

    form.setFieldsValue(resetValues);
  };

  const handleEnableIdExpiry = (checked: boolean) => {
    if (checked) {
      form.resetFields(fieldsToReset);
    } else {
      resetDisabledFormValues();
    }
  };

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    if (!values["nic-cs-domain-noncompl"]) resetDisabledFormValues();

    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys(values).map((val: string) => {
        return {
          fieldCode: val,
          value: values[val],
        };
      }) as TenantValue[],
    };

    tenantsServiceApi
      .editTenant(requestBody, parseInt(tenantId ?? "0", 10))
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Client Identification"
      form={form}
      submitHandler={onFinish}
      formSize="lg"
      size="full"
      submitLabel="Save"
    >
      <PageItemLayoutGroup className={styles["client-identification-section-wrapper"]}>
        <div className={cn(styles["section-title"], styles["text-uppercase"])}>Client Identification</div>
        <PageItemLayoutElements>
          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "nic-unique-client-reg-number",
              initialValue: false,
              label: "Impose Uniqueness of the field that stores client ID number",
              tooltip: "Domestic identification document number (like IQAMA ID or Passport number)",
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "nic-unique-client-number",
              initialValue: true,
              label: "Impose Uniqueness of the field that stores CIF number",
              tooltip:
                "CIF number is a unique number that the banks assign to each customer. This number is a part of the digital file – CIF, which is an acronym for Customer Information File. It is an electronic file on a bank’s computer system that stores all pertinent information about a customer.",
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "nic-exid-use",
              initialValue: true,
              label: "Enable using External card ID (EXID)",
              tooltip:
                "EXID is additional unique identifier of the card contract that can be referred by a party not having access to the card number, for example due to the fact that this party is not PCI/DSS certified instead of using PAN.",
            }}
          />
        </PageItemLayoutElements>
      </PageItemLayoutGroup>
      <PageItemLayoutGroup className={styles["id-expire-wrapper"]}>
        <div className={cn(styles["section-title"], styles["text-uppercase"])}>ID expire functionality</div>
        <div>
          <div className={styles["content-header"]}>
            <NetworkForm.Switch
              onChange={handleEnableIdExpiry}
              formItemOptions={{
                valuePropName: "checked",
                name: "nic-cs-domain-noncompl",
                initialValue: tenant.countryCode === "SA",
              }}
              customLabel={
                <span className={styles["header-text"]}>
                  <TooltipInfo
                    label="Enable ID Expire Functionality"
                    code="nic-cs-domain-noncompl"
                    tooltipProps={{
                      title:
                        "In order to comply with local KYC (know your customer) procedures, client has to provide their ID details (Iqama ID or Passport Number ID) to respective issuing bank. Functionality implies blocking client’s cards once this ID get expired. Based on submission of new/renewed ID details, cards will get unblocked for further usage.",
                    }}
                  />
                </span>
              }
            />
          </div>
          <div className={styles["content-wrapper"]}>
            <PageItemLayoutGroup>
              <PageItemLayoutElements>
                <div className={styles["section-title"]}>For Account</div>
                <div className={styles["section-description"]}>
                  Following rules will be applied for primary and supplementary cards if primary cardholder ID is
                  expired.
                </div>
              </PageItemLayoutElements>
              <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                {() => (
                  <NetworkForm.Select
                    optionList={authBlockResponseOptions}
                    disabled={!form.getFieldValue("nic-cs-domain-noncompl")}
                    formItemOptions={{
                      className: styles["select-item"],
                      name: "nic-noncompl-auth-block-3",
                      label: "Response code",
                      tooltip: {
                        title:
                          "The following response will be received (by POS or ATM) in case of transaction by card which was blocked due to ID expiration.",
                        icon: <QuestionCircleFilled />,
                      },
                      initialValue: "RC=05",
                    }}
                  />
                )}
              </Form.Item>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <NetworkForm.Switch
                      disabled={!form.getFieldValue("nic-cs-domain-noncompl")}
                      formItemOptions={{
                        valuePropName: "checked",
                        name: "nic-noncompl_level_a-recalc-client-update",
                        initialValue: true,
                        label: "Enable using External card ID (EXID)",
                      }}
                    />
                  )}
                </Form.Item>
              </Space>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <NetworkForm.Switch
                      disabled={!form.getFieldValue("nic-cs-domain-noncompl")}
                      formItemOptions={{
                        valuePropName: "checked",
                        name: "nic-noncompl_level_a-recalc-dec-daily-sa",
                        initialValue: true,
                        label:
                          "Enable checking ID renewal state in every morning CDU (Contract Daily Updates) procedure",
                      }}
                    />
                  )}
                </Form.Item>
              </Space>
              <div className={styles["section-title"]}>For Card</div>
              <div className={styles["section-description"]}>
                Following rules will be applied for card if cardholder ID is expired.
              </div>

              <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                {() => (
                  <NetworkForm.Select
                    optionList={authBlockResponseOptions}
                    disabled={!form.getFieldValue("nic-cs-domain-noncompl")}
                    formItemOptions={{
                      className: styles["select-item"],
                      name: "nic-noncompl_c-auth-block-3",
                      label: "Response code",
                      tooltip: {
                        title:
                          "The following response will be received (by POS or ATM) in case of transaction by card which was blocked due to ID expiration.",
                        icon: <QuestionCircleFilled />,
                      },
                      initialValue: "RC=05",
                    }}
                  />
                )}
              </Form.Item>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <NetworkForm.Switch
                      disabled={!form.getFieldValue("nic-cs-domain-noncompl")}
                      formItemOptions={{
                        valuePropName: "checked",
                        name: "nic-noncompl_level_c-suppl-recalc-client-update",
                        initialValue: true,
                        label:
                          "Enable checking ID renewal state immediately once the client/contract was updated in the system",
                      }}
                    />
                  )}
                </Form.Item>
              </Space>
              <Space direction="horizontal" size={8}>
                <Form.Item noStyle={true} dependencies={["nic-cs-domain-noncompl"]}>
                  {() => (
                    <NetworkForm.Switch
                      disabled={!form.getFieldValue("nic-cs-domain-noncompl")}
                      formItemOptions={{
                        valuePropName: "checked",
                        name: "nic-noncompl_level_c-suppl-recalc-dec-daily-sa",
                        initialValue: true,
                        label:
                          "Enable checking ID renewal state in every morning CDU (Contract Daily Updates) procedure",
                      }}
                    />
                  )}
                </Form.Item>
              </Space>
              <PageItemLayoutElements>
                <div className={styles["info"]}>
                  <span>Appropriate Block Code for ID expiration functionality could be configured.</span>
                  <Link to={`/tenant/${tenantId}/status-management-codes`}>
                    <span className={styles["info-link-text"]}>View Status Management Codes settings</span>
                    <ArrowRightOutlined />
                  </Link>
                </div>
                <div className={styles["info"]}>
                  <span>
                    ID Expiry SMS could be sent to remind the customers to update the ID details. For convenience this
                    has been generated for you, please visit the SMS page from the dashboard to modify the SMS settings.
                  </span>
                  <Link to={`/tenant/${tenantId}/sms-templates`}>
                    <span className={styles["info-link-text"]}>View SMS templates settings</span>
                    <ArrowRightOutlined />
                  </Link>
                </div>
              </PageItemLayoutElements>
            </PageItemLayoutGroup>
          </div>
        </div>
      </PageItemLayoutGroup>
    </CustomFormWrapper>
  );
};
