import { FC, useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Table, TableColumnType, Typography } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { useNavigate } from "react-router-dom";

import {
  BankOutlined,
  BlockOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  TableOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { DashboardFilters } from "@ni/common/types";
import { ActionsCell, CardDisplayCell, MenuItemModel, PaymentSchemeCell } from "@ni/common/ui";
import {
  checkIfProductCreatedSuccessfully,
  checkIfProductReadyToUat,
  getErrorInstance,
  mapToServerSorting,
  UserService,
} from "@ni/common/utils";
import { FinancialInstitutionApi, ProductApi } from "@ni/sdk/apis";
import { FullDashboardProduct, Order, SortedFilteredPageRequest } from "@ni/sdk/models";

import styles from "./TableView.module.scss";

const productServicesApi = new ProductApi();
const financialInstitutionServiceApi = new FinancialInstitutionApi();

interface DuplicateProductForm {
  newDisplayName: string;
}

interface TableViewProps {
  filters: DashboardFilters;
  isFISelected: boolean;
  selectedFIId: string;
}

export const TableView: FC<TableViewProps> = ({ filters, isFISelected, selectedFIId }) => {
  const navigate = useNavigate();
  const [, setIsLoading] = useState<boolean>(false);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [modalId, setModalId] = useState<string | undefined>();
  const [modalForm] = Form.useForm();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [products, setProducts] = useReduxState<FullDashboardProduct[]>("dashboardTableProducts", []);
  const [productsLoading, setProductsLoading] = useState<boolean>(false);
  const [tablePageConfig, setTablePageConfig] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });
  const [dashboardPageConfig, setDashboardPageConfig] = useState<SortedFilteredPageRequest>();

  const getProducts = (): void => {
    if (dashboardPageConfig && Object.keys(dashboardPageConfig)) {
      setProductsLoading(true);
      productServicesApi
        .getDashboardProducts(dashboardPageConfig)
        .then(response => {
          setTablePageConfig({
            ...tablePageConfig,
            current: (dashboardPageConfig.pageLimits?.number as number) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setProducts(response.data.content as FullDashboardProduct[]);
          setProductsLoading(false);
        })
        .catch(() => {
          setProductsLoading(false);
        });
    }
  };

  const getProductsIfFISelected = (): void => {
    if (dashboardPageConfig && Object.keys(dashboardPageConfig)) {
      setProductsLoading(true);
      financialInstitutionServiceApi
        .getDashboardProducts1(dashboardPageConfig, Number(selectedFIId))
        .then(response => {
          setTablePageConfig({
            ...tablePageConfig,
            current: (dashboardPageConfig.pageLimits?.number as number) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setProducts(response.data.content as FullDashboardProduct[]);
          UserService.updateToken()
            .then(() => {})
            .catch(() => {});
          setProductsLoading(false);
        })
        .catch(() => {
          setProductsLoading(false);
        });
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<FullDashboardProduct> | SorterResult<FullDashboardProduct>[],
  ) => {
    let sortArray: Order[] = [];
    if (sorter) {
      if (Array.isArray(sorter)) {
        sortArray = sorter
          .filter(sort => sort.order)
          .map(sortObject => ({
            direction: mapToServerSorting(sortObject.order),
            property: sortObject.columnKey as string,
          }));
      } else if (sorter.order) {
        sortArray = [{ property: sorter.columnKey as string, direction: mapToServerSorting(sorter.order) }];
      }
    }

    setDashboardPageConfig({
      ...dashboardPageConfig,
      pageLimits: {
        size: pagination.pageSize,
        number: (pagination.current as number) - 1,
      },
      sorting: sortArray.length ? sortArray : undefined,
    });
  };

  useEffect(() => {
    if (isFISelected) {
      getProductsIfFISelected();
    } else {
      getProducts();
    }
  }, [dashboardPageConfig]);

  useEffect(() => {
    setDashboardPageConfig({
      ...filters,
      sorting: dashboardPageConfig?.sorting as Order[],
      pageLimits: {
        size: tablePageConfig.pageSize,
        number: 0,
      },
    });
  }, [filters]);

  const onDuplicateProduct = (formData: DuplicateProductForm): void => {
    setModalLoading(true);
    productServicesApi
      .copyProduct(formData, parseInt(modalId as string, 10))
      .then(() => {
        setModalLoading(false);
        setModalOpened(false);
        getProducts();
      })
      .catch(() => {
        setModalLoading(false);
      });
  };

  const onCancelModal = (): void => {
    setModalOpened(false);
  };

  const onDeleteDraftProduct = () => {
    setModalLoading(true);

    productServicesApi
      .deleteProductById(Number(modalId))
      .then(() => {
        setProducts(prev => prev.filter(product => product.product.id !== Number(modalId)));

        notification.success({
          placement: "topRight",
          duration: 5,
          message: "Product has been deleted successfully!",
        });
      })
      .catch(response => {
        const errorInstance = getErrorInstance(response);
        notification.error({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {errorInstance?.response.status} <br />
              {errorInstance?.response.data.errorMessage}
            </div>
          ),
        });
      })
      .finally(() => {
        setDeleteModalOpen(false);
        setModalLoading(false);
        setModalId(undefined);
      });
  };

  const getActionItems = (item: FullDashboardProduct): MenuItemModel[] => {
    const finished: boolean = checkIfProductCreatedSuccessfully(item?.product?.lastProcessedPage?.code as string);
    const readyToUat: boolean = checkIfProductReadyToUat(item?.product?.productState);

    return [
      {
        label: "Tenant settings",
        icon: <BankOutlined />,
        actionCallBack: (data: FullDashboardProduct) => {
          const { tenant } = data;
          navigate(`/tenant/${tenant?.id || ""}/details`);
        },
      },
      {
        label: "Product settings",
        icon: <CreditCardOutlined />,
        disabled: !finished,
        actionCallBack: (data: FullDashboardProduct) => {
          const { tenant, product } = data;
          navigate(`/tenant/${tenant?.id || ""}/product/${product?.id || ""}/product-details`);
        },
      },
      {
        label: "Pricing control tables",
        icon: <TableOutlined />,
        disabled: !finished,
        actionCallBack: (data: FullDashboardProduct) => {
          const { tenant, product } = data;
          navigate(`/tenant/${tenant?.id || ""}/product/${product?.id || ""}/pct`);
        },
      },
      {
        label: "Duplicate product",
        icon: <BlockOutlined />,
        disabled: !finished,
        actionCallBack: (data: FullDashboardProduct) => {
          const { product } = data;

          setModalId(product?.id?.toString() || "");
          modalForm.setFieldsValue({
            newDisplayName: `${product?.name || ""} - copy`,
          });

          setModalOpened(true);
        },
      },
      {
        disabled: !readyToUat,
        label: finished ? "Test in sandbox" : "Continue creating",
        icon: finished ? <PlayCircleOutlined /> : <SettingOutlined />,
        actionCallBack: (data: FullDashboardProduct) => {
          const { tenant, product } = data;
          if (!finished) {
            const queries = new URLSearchParams({
              tenantId: String(tenant.id),
              productId: String(product.id),
            });

            navigate({
              pathname: `/create-product`,
              search: queries.toString(),
            });
          } else {
            setIsLoading(true);
            const id = product?.id;
            productServicesApi
              .confirmUat(id)
              .then(() => {
                setIsLoading(false);
              })
              .catch(response => {
                const errorInstance = getErrorInstance(response);
                notification.error({
                  placement: "topRight",
                  duration: 5,
                  message: (
                    <div>
                      {errorInstance?.response.status} <br />
                      {errorInstance?.response.data.errorMessage}
                    </div>
                  ),
                });
                setIsLoading(false);
              });
          }
        },
      },
      ...(item.product.productState === "DRAFT"
        ? [
            {
              label: "Delete product",
              icon: <DeleteOutlined />,
              actionCallBack: (data: FullDashboardProduct) => {
                const { product } = data;
                setModalId(product.id.toString() ?? "");
                setDeleteModalOpen(true);
              },
            },
          ]
        : []),
    ];
  };

  const columns: TableColumnType<FullDashboardProduct>[] = [
    {
      title: "Tenant",
      dataIndex: ["tenantName"],
      key: "tenant.name",
      sorter: { multiple: 1 },
      width: 300,
      render: (_: string, item: FullDashboardProduct) => (
        <p className={styles["table-view-tenant-name"]}>{item.tenant?.name}</p>
      ),
    },
    {
      title: "Product Name",
      dataIndex: ["productName"],
      key: "product.name",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        return (
          <CardDisplayCell
            tenantId={item.tenant?.id || 0}
            productId={item.product?.id || 0}
            finished={checkIfProductCreatedSuccessfully(item?.product?.lastProcessedPage?.code as string)}
            cardImage={item?.product?.cardImage}
            productName={item?.product?.name as string}
            prodIps={item?.product?.paymentScheme}
          />
        );
      },
      width: 300,
    },
    {
      title: "Product Type",
      dataIndex: ["productType"],
      key: "product.productType",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        return item?.product?.productType;
      },
    },
    {
      title: "Payment Scheme",
      dataIndex: ["paymentScheme"],
      key: "product.paymentScheme",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        return <PaymentSchemeCell ips={item?.product?.paymentScheme} paymentScheme={item?.product?.paymentScheme} />;
      },
    },
    {
      title: "Co-badge Scheme",
      dataIndex: ["coBadgeName"],
      key: "product.coBadgeScheme",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        return item?.product?.coBadgeScheme || "-";
      },
    },
    {
      title: "BIN",
      dataIndex: ["bin"],
      key: "product.bin",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        return item.product?.bin || "-";
      },
    },
    {
      title: "Currency",
      dataIndex: ["currency"],
      key: "product.currency",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        return item.product?.currency || "-";
      },
    },
    {
      title: "Balance",
      dataIndex: ["balance"],
      key: "product.balanceOwner",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        if (!item.product?.balanceOwner) {
          return "-";
        }

        return item.product?.balanceOwner === "CMS" ? "Network" : "Tenant";
      },
    },
    {
      title: "Product State",
      key: "product.productState",
      sorter: { multiple: 1 },
      render: (_: string, item: FullDashboardProduct) => {
        return checkIfProductCreatedSuccessfully(item?.product?.lastProcessedPage?.code as string) ? (
          <span>{item.product?.productState}</span>
        ) : (
          <span>
            {item.product?.productState} <WarningOutlined className={styles["table-draft-warning-sign"]} />
          </span>
        );
      },
    },
    {
      title: "",
      key: "actions",
      width: 100,
      render: (_: string, item: FullDashboardProduct) => <ActionsCell items={getActionItems(item)} rowData={item} />,
    },
  ];

  return (
    <div className={styles["tenant-table-view"]}>
      <Table<FullDashboardProduct>
        className={styles["tenant-table"]}
        columns={columns}
        dataSource={products}
        loading={productsLoading}
        rowKey={(item: FullDashboardProduct) => `${item.tenant?.id}_${item.product?.id}` || Math.random()}
        onChange={handleTableChange}
        pagination={tablePageConfig}
        rowClassName={(item: FullDashboardProduct) =>
          checkIfProductCreatedSuccessfully(item.product?.lastProcessedPage?.code as string) ? "" : "table-row-grayed"
        }
      />

      <Modal
        title="Confirmation"
        open={deleteModalOpen}
        width="600px"
        onCancel={() => setDeleteModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={modalLoading} onClick={onDeleteDraftProduct}>
            Confirm
          </Button>,
        ]}
      >
        <Typography.Text>Are you sure you want to delete this product? This action is irreversible.</Typography.Text>
      </Modal>

      <Modal
        title="Copy of product will be created"
        open={modalOpened}
        width="800px"
        onCancel={onCancelModal}
        footer={[
          <Button key="back" onClick={onCancelModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={modalLoading} onClick={modalForm.submit}>
            Confirm
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="vertical" onFinish={onDuplicateProduct}>
          <Form.Item
            name="newDisplayName"
            label="Provide the name for the new product"
            rules={[{ type: "string", max: 256, message: " Cannot be empty. Max length is 256 letters" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
