export enum PctParams {
  displayName = "displayName",
}

export enum PctValueParams {
  moto = "nic-pers-restr-moto-en",
  ecomm = "nic-pers-restr-ecomm-en",
  contactless = "nic-pers-restr-contactless-en",
  fallback = "nic-pers-restr-fallback-en",
  country = "nic-pers-restr-for-country-en",
  atm = "nic-pers-restr-cash-atm-en",
  all = "nic-pers-restr-all-dr-en",
  eCommerceList = "nic-mcc-restr-card-pers-rules",
  mada = "nic-c-mada-contactless-cum",
}
