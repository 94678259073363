/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { ComponentProps, useState } from "react";
import { Form, FormInstance, notification } from "antd";
import cn from "classnames";

import { FormValues } from "@ni/common/types";
import { ElementDashboard } from "@ni/sdk/models";

import { NetworkForm } from "../FormInput";

import styles from "./styles.module.scss";

interface Props extends ComponentProps<"td"> {
  form: FormInstance<FormValues>;
  name: string;
  editable: boolean;
  record: ElementDashboard;
  children: React.ReactNode;
}

const EditableCell = ({ form, name, editable, record, children, ...restProps }: Props) => {
  const [editing, setEditing] = useState<boolean>(false);

  const enabledCode = `${record?.parmCode}@enabled`;
  const mandatoryCode = `${record?.parmCode}@mandatory`;
  const minLengthCode = `${record?.parmCode}@minLength`;
  const maxLengthCode = `${record?.parmCode}@maxLength`;

  const save = async () => {
    try {
      await form.validateFields();
      setEditing(false);
    } catch {
      notification.error({
        placement: "topRight",
        duration: 10,
        message: "Error",
        description: "Something went wrong. Please try again Later.",
      });
    }
  };

  const discard = () => {
    setEditing(false);
    form.resetFields([name]);
  };

  if (!editable) return <td {...restProps}>{children}</td>;

  return (
    <td {...restProps} className={cn(restProps.className, styles["editable-cell"])}>
      <Form.Item dependencies={[enabledCode]} noStyle={true}>
        {({ getFieldValue, setFieldsValue }) => {
          const isDisabled = !getFieldValue(enabledCode);

          if (isDisabled && (getFieldValue(minLengthCode) || getFieldValue(maxLengthCode)))
            setFieldsValue({
              [mandatoryCode]: undefined,
              [minLengthCode]: undefined,
              [maxLengthCode]: undefined,
            });

          return (
            <div
              className={cn(isDisabled && styles["is-disabled"])}
              onClick={() => setEditing(!isDisabled)}
              style={{
                display: "flex",
                alignItems: "center",
                height: 40,
                width: "100%",
                padding: !editing ? "0 8px" : "unset",
              }}
            >
              {editing ? (
                <NetworkForm.Number
                  formItemOptions={{ name, className: "m-b-0 w-p-100" }}
                  ref={e => editing && e?.focus()}
                  onBlur={save}
                  onPressEnter={save}
                  onKeyDown={e => e.key === "Escape" && discard()}
                  className={styles["dataframework-input"]}
                  formatter={undefined}
                  parser={undefined}
                />
              ) : (
                (form?.getFieldValue(name) as string) ?? ""
              )}
            </div>
          );
        }}
      </Form.Item>
    </td>
  );
};

export { EditableCell };
