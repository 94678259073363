import { ReactNode } from "react";

import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number | unknown[];
  tooltip?: ReactNode;
}

interface CheckOptions extends Options {
  options: SelectOption[];
}

export const profitTypeOption: CheckOptions = {
  code: "profit-type",
  name: "Choose profit options you want to apply to your plan",
  default: ["I", "P", "M", "F"],
  options: [
    { label: "Interest rate", value: "I" },
    {
      label: "Processing fee from cardholder",
      value: "P",
      tooltip:
        "The fee is charged to the cardholder as a processing fee in case the transaction amount or balance is booked for this plan",
    },
    {
      label: "Incentive fee from merchant",
      value: "M",
      tooltip:
        "The calculated Incentive fee will be reported to the tenant as part of customized reports and the tenant can reconcile and charge the merchant accordingly",
    },
    {
      label: "Foreclosure fee from cardholder",
      value: "F",
      tooltip:
        "IPP Foreclosure is the process of closing the IPP Plan by the system or by the request from the customer. When the IPP plan is foreclosed, the IPP balance is moved to the retail balance and also system allows to charge foreclosure fees",
    },
  ],
};
