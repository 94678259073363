import { useEffect } from "react";

import { Dictionary } from "@ni/sdk/models";

import { useConfigurationApi } from "../api";
import { useReduxState } from "../store";

const PERSO_BUREAU = "perso";

export const useGetPerso = () => {
  const [persoList, setSchemeList] = useReduxState<Dictionary[]>("paymentPersoListState", []);
  const [persoMap, setSchemeMap] = useReduxState<Map<string, string>>("paymentPersoMapState");

  const { getDictionariesByCode } = useConfigurationApi();

  useEffect(() => {
    if (persoList.length === 0)
      void getDictionariesByCode(PERSO_BUREAU)
        .then(response => {
          setSchemeList(response?.dictionaries || []);
          return response?.dictionaries;
        })
        .then(schemeList => {
          if (schemeList) {
            const schemeHolder = new Map();
            schemeList.forEach(scheme => {
              schemeHolder.set(scheme.value, scheme.displayValue);
            });
            setSchemeMap(schemeHolder);
          }
        });
  }, [persoList.length, getDictionariesByCode, setSchemeList, setSchemeMap]);

  return { persoList, persoMap };
};
