import { memo, useCallback } from "react";
import { AxiosResponse } from "axios";
import update from "immutability-helper";
import type { FC } from "react";
import { useDrop } from "react-dnd";

import { useReduxState } from "@ni/common/hooks";
import { PhaseApi } from "@ni/sdk/apis";
import { BatchChangePhasesRequest, Page, Phase, PhaseType, PlainPhase, State } from "@ni/sdk/models";

import { ItemTypes } from "../../constants";
import PhaseCard from "../PhaseCard";

import styles from "./styles.module.scss";

const phaseServiceApi = new PhaseApi();

interface DraggablePhaseSettingsProps {
  getPhases: () => void;
  workflowId: number;
}

const ContainerFunc: FC<DraggablePhaseSettingsProps> = props => {
  const { getPhases, workflowId } = props;
  const [phaseItems, setPhaseItems] = useReduxState<Phase[]>("phaseItems", []);
  const [, setIsLoading] = useReduxState<boolean>("isPhaseLoading");

  const findPhaseCard = useCallback(
    (id: string) => {
      const card = phaseItems?.filter(c => c.id === Number(id))[0] as {
        id: number;
        code: string;
        phaseName: string;
        skipConditions: string;
        order: number;
        phaseType: PhaseType;
        state: State;
        pages: Page[];
      };
      return {
        card,
        index: phaseItems?.indexOf(card),
      };
    },
    [phaseItems],
  );

  const onUpdatePhasesOrder = (items: Phase[]) => {
    setIsLoading(true);

    const requestBody: PlainPhase[] = items.map((item, index) => {
      return {
        id: item.id!,
        code: item.code || "",
        name: item.name || "",
        order: index,
        phaseType: item.phaseType || "PCT_MANAGEMENT",
        state: item.state || "DISABLED",
        skipConditions: item.skipConditions || "",
      };
    });

    const phaseList: BatchChangePhasesRequest = {
      phases: requestBody,
    };

    phaseServiceApi
      .putPhases(phaseList, workflowId)
      .then((response: AxiosResponse<Phase[]>) => {
        setPhaseItems(response.data);
        getPhases();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const movePhaseCard = useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findPhaseCard(id);
      setPhaseItems(
        update(phaseItems, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },
    [findPhaseCard, phaseItems, setPhaseItems],
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.PHASE }));

  return (
    <div ref={drop} className={styles["draggable-phases"]}>
      {phaseItems.length ? (
        phaseItems.map((phase, index) => (
          <PhaseCard
            key={phase.id ?? `new-${phase.order ?? "phase"}`}
            phase={phase}
            id={String(phase.id)}
            movePhaseCard={movePhaseCard}
            findPhaseCard={findPhaseCard}
            index={index}
            workflowId={workflowId}
            getPhases={getPhases}
            onUpdatePhasesOrder={onUpdatePhasesOrder}
          />
        ))
      ) : (
        <div className={styles["empty-description"]}>You do not have any phases yet.</div>
      )}
    </div>
  );
};

const PhaseContainer = memo(ContainerFunc);

export default PhaseContainer;
