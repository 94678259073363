import { useMemo } from "react";
import { Form, Space } from "antd";
import { useParams } from "react-router-dom";

import {
  LTY_ACCR_B_RATE,
  LTY_ACCR_CAP_CTD,
  LTY_ACCR_RATE,
  LTY_ACCR_Y_RATE,
  LTY_PROGRAM_ACCR_FREQ,
  LTY_PROGRAM_ACCR_MODE,
  LTY_PROGRAM_ACCR_TYPE,
} from "@ni/common/constants";
import { useHydrateForm, useNumberOfTotalPointesCapDecimals, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, Tabs } from "@ni/common/ui";
import { LoyaltyProgramTemplateType, Tenant } from "@ni/sdk/models";

import { useLoyaltyProgramTemplateApi } from "../../../../hooks/tenant/useLoyaltyProgramTemplateApi";

import { RADIO_GROUP_ACCRUAL_FREQ, RADIO_GROUP_ACCRUAL_MODE, RADIO_GROUP_ACCRUAL_TYPE } from "./constants";

const stringsKeys = [
  LTY_ACCR_B_RATE,
  LTY_ACCR_CAP_CTD,
  LTY_ACCR_RATE,
  LTY_ACCR_Y_RATE,
  LTY_PROGRAM_ACCR_FREQ,
  LTY_PROGRAM_ACCR_MODE,
  LTY_PROGRAM_ACCR_TYPE,
];

export const PointAccrualSetup = () => {
  const [form] = Form.useForm<FormValues>();
  const [tenant] = useReduxState<Tenant>("tenant", {});

  const { templateId } = useParams<{ templateId: string }>();

  const { loyaltyProgramTemplate, editLoyaltyProgramTemplate } = useLoyaltyProgramTemplateApi({
    loyaltyTemplateId: parseInt(templateId ?? "0", 10),
  });

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: [
        ...(loyaltyProgramTemplate?.programValues ?? []),
        ...(loyaltyProgramTemplate?.programPctValues ?? []),
      ],
      keys: {
        strings: stringsKeys,
      },
      allowParse: true,
    },
    [loyaltyProgramTemplate],
  );

  const decimalScale = useNumberOfTotalPointesCapDecimals(tenant);
  const isCumulativeType = Form.useWatch<string>(LTY_PROGRAM_ACCR_TYPE, form) === "C";
  const accrualFrequency = Form.useWatch<string>(LTY_PROGRAM_ACCR_FREQ, form);

  const manipulateFormFields = () => {
    if (!isCumulativeType)
      return {
        shouldShowRate: true,
        shouldShowCap: true,
        accrualPercentageRateKey: LTY_ACCR_RATE,
        accrualPercentageRateTooltip:
          "This parameter specifies the rate at which points are accrued for the transaction made by the customer. This field is percentage value,  for example if it is set as 1% then points are accrued at the rate of 1% for transactions. ",
      };

    switch (accrualFrequency) {
      case "B":
        return {
          shouldShowRate: true,
          shouldShowCap: true,
          shouldShowTab: false,
          accrualPercentageRateKey: LTY_ACCR_B_RATE,
          accrualPercentageRateTooltip:
            "This parameter specifies the rate at which points are accrued for the eligible transactions in the cycle.",
        };
      case "Y":
        return {
          shouldShowRate: true,
          shouldShowCap: false,
          shouldShowTab: false,
          accrualPercentageRateKey: LTY_ACCR_Y_RATE,
          accrualPercentageRateTooltip:
            "This parameter specifies the rate at which points are accrued for the eligible transactions in the year",
        };
      default:
        return {
          shouldShowTab: true,
        };
    }
  };

  const { shouldShowRate, shouldShowCap, shouldShowTab, accrualPercentageRateKey, accrualPercentageRateTooltip } =
    useMemo(manipulateFormFields, [isCumulativeType, accrualFrequency]);

  const accrualPointTabs = useMemo(
    () =>
      loyaltyProgramTemplate?.id
        ? [
            {
              label: <>Billing Cycle</>,
              key: "B" as const,
              children: (
                <Space direction="vertical" size="large" className="w-p-100">
                  <NetworkForm.Number
                    formItemOptions={{
                      name: LTY_ACCR_B_RATE,
                      required: true,
                      rules: [{ required: true }],
                      label: "Points accrual percentage rate",
                      tooltip:
                        "This parameter specifies the rate at which points are accrued for the eligible transactions in the cycle.",
                    }}
                    stringMode={true}
                    min={0}
                    precision={2}
                    addonAfter="%"
                  />

                  <NetworkForm.Number
                    formItemOptions={{
                      name: LTY_ACCR_CAP_CTD,
                      label: "Total points cap per cycle",
                      tooltip:
                        "This parameter specifies the maximum points that can be earned under one program in the single billing cycle. For example, if maximum cap is defined as 500, then 500 is the maximum points that can be earned on cycle-to-date transactions even though total accrued points are more than 500.",
                    }}
                    stringMode={true}
                    min={0}
                    precision={decimalScale}
                  />
                </Space>
              ),
            },
            {
              // eslint-disable-next-line no-irregular-whitespace
              label: <> Yearly </>,
              key: "Y" as const,
              children: (
                <NetworkForm.Number
                  formItemOptions={{
                    name: LTY_ACCR_Y_RATE,
                    required: true,
                    rules: [{ required: true }],
                    label: "Points accrual percentage rate",
                    tooltip:
                      "This parameter specifies the rate at which points are accrued for the eligible transactions in the year.",
                  }}
                  stringMode={true}
                  min={0}
                  precision={2}
                />
              ),
            },
          ]
        : [],
    [decimalScale, loyaltyProgramTemplate?.id],
  );

  const onFinish = async (values: FormValues) => {
    const alteredData: { values: FormValues; pctValues: FormValues; type: LoyaltyProgramTemplateType } = {
      values: {},
      pctValues: {},
      type: "NORMAL",
    };
    [...stringsKeys].forEach(key => {
      if (
        !values[key]?.toString() &&
        [...(loyaltyProgramTemplate?.programValues ?? []), ...(loyaltyProgramTemplate?.programPctValues ?? [])]?.find(
          item => item.code === key,
        )
      ) {
        values[key] = "";
      }
    });
    Object.keys(values).forEach(key => {
      if ([LTY_PROGRAM_ACCR_FREQ, LTY_PROGRAM_ACCR_MODE].includes(key)) alteredData.values[key] = values[key];
      else if (key !== LTY_PROGRAM_ACCR_TYPE) alteredData.pctValues[key] = values[key];
      else alteredData.type = values[key] === "C" ? "CUMULATIVE" : "NORMAL";
    });
    await editLoyaltyProgramTemplate(alteredData, "both");
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Point Accrual Setup"
      pageSubtitle="Loyalty points are earned for eligible transactions. Please define the basic accrual setup for the loyalty program."
      size="md"
      formSize="md"
      level="tenant"
      submitLabel="Save"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Radio
        formItemOptions={{
          name: LTY_PROGRAM_ACCR_TYPE,
          label: "Loyalty program accrual type:",
          initialValue: RADIO_GROUP_ACCRUAL_TYPE.default,
        }}
        radioList={RADIO_GROUP_ACCRUAL_TYPE.options}
      />

      {isCumulativeType && (
        <>
          <NetworkForm.Radio
            formItemOptions={{
              name: LTY_PROGRAM_ACCR_FREQ,
              label: "Choose point accrual frequency:",
              required: true,
              initialValue: RADIO_GROUP_ACCRUAL_FREQ.default,
              rules: [{ required: true, message: "Please choose point accrual frequency" }],
            }}
            radioList={RADIO_GROUP_ACCRUAL_FREQ.options}
          />

          <NetworkForm.Radio
            formItemOptions={{
              name: LTY_PROGRAM_ACCR_MODE,
              label: "Choose cumulative program accrual mode:",
              required: true,
              initialValue: RADIO_GROUP_ACCRUAL_MODE.default,
              rules: [{ required: true, message: "Please choose cumulative program accrual mode" }],
            }}
            radioList={RADIO_GROUP_ACCRUAL_MODE.options}
          />
        </>
      )}

      {shouldShowTab && (
        <Tabs
          form={form}
          onSave={onFinish}
          pagesList={accrualPointTabs}
          initialValues={initialValues}
          discardAfterChangeTab={true}
          isCheckEnabled={true}
        />
      )}

      {shouldShowRate && accrualPercentageRateKey && (
        <NetworkForm.Number
          formItemOptions={{
            name: accrualPercentageRateKey,
            label: "Points accrual percentage rate",
            required: true,
            rules: [{ required: true }],
            tooltip: accrualPercentageRateTooltip,
          }}
          stringMode={true}
          min={0}
          precision={2}
          addonAfter="%"
        />
      )}

      {shouldShowCap && (
        <NetworkForm.Number
          formItemOptions={{
            name: LTY_ACCR_CAP_CTD,
            label: "Total points cap per cycle",
            tooltip:
              "This parameter specifies about the maximum points that can be earned under one program in the single billing cycle. For example, if maximum cap is defined as 500, then 500 is the maximum points that can be earned on cycle to date transactions even though total accrued points are more than 500.",
          }}
          stringMode={true}
          min={0}
          precision={decimalScale}
        />
      )}
    </CustomFormWrapper>
  );
};
