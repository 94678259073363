import { Page, Phase } from "@ni/sdk/models";

export const getPhaseAndPageOrder = (phases: Phase[], pageKey: string, pageId?: number) => {
  const phase = phases?.find(phase => phase.pages?.some(page => page.code === pageKey || page.id === pageId));

  if (!phase) return null;

  return {
    phaseOrder: phase.order as number,
    pageOrder: (phase.pages as Page[]).find(page => page.code === pageKey || page.id === pageId)?.order as number,
    page: (phase.pages as Page[]).find(page => page.code === pageKey || page.id === pageId) as Page,
  };
};
