/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ReactNode } from "react";
import { TableColumnType } from "antd";

import { TooltipInfo } from "../../../../ui/src/lib/components/TooltipInfo/TooltipInfo";

export const graceScenariosTableColumns: TableColumnType<{ [x: string]: ReactNode }>[] = [
  {
    title: "",
    dataIndex: "subTitle",
    render: (subTitle: string) => (
      <TooltipInfo
        label={subTitle}
        tooltipProps={{
          title: {
            R: "Transactions were made in the previous billing cycles",
            N: "Transactions were made in the current billing cycle",
            P: "Depending on scenario can be full payment by the full payment day or due payment (MTP) by due date",
          }[subTitle?.charAt(0) as "R" | "N" | "P"],
          className: "tooltip-title",
        }}
      />
    ),
  },
  {
    title: <TooltipInfo label="Billed amount" tooltipProps={{}} />,
    dataIndex: "billedAmount",
  },
  {
    title: (
      <TooltipInfo
        label="Accrued interest"
        tooltipProps={{
          title: "Accrued interest refers to the amount of interest that has been calculated but not yet billed",
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "accrued",
  },
  {
    title: <TooltipInfo label="Billed interest" tooltipProps={{}} />,
    dataIndex: "billedInterest",
  },
  {
    title: (
      <TooltipInfo
        label="Deferred interest"
        tooltipProps={{
          title:
            "Accrued interest that was not billed in the current billing cycle, but was delayed for potential billing in the next cycle",
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "deferred",
  },
  {
    title: (
      <TooltipInfo
        label="Waived interest"
        tooltipProps={{
          title: "Interest that was waived due to compliance with the grace option conditions",
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "waived",
  },
];

export const graceScenariosTabsList = [
  {
    title: "No grace option",
    tableData: {
      rates: [
        {
          label: "Interest rate",
          value: "15%",
        },
        {
          label: "MTP",
          value: "5%",
        },
      ],
      scenarios: [
        {
          key: "a",
          label: "Alice",
          mainDescription: "Always bill interest at the end of the cycle",
          scenarioDescription: "Pays Full payment always",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$101,234.11",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "danger", value: "$1,257.53" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
        {
          key: "b",
          label: "Bob",
          mainDescription: "Always bill interest at the end of the cycle",
          scenarioDescription: "Pays MTP in the first cycle, but pays Full payment in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$101,329.11",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,258.71" },
                  { type: "danger", value: "$1,258.71" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
        {
          key: "c",
          label: "Charlie",
          mainDescription: "Always bill interest at the end of the cycle",
          scenarioDescription: "Pays Full payment in the first cycle, but pays MTP in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$6,234.11",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "danger", value: "$1,257.53" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
        {
          key: "d",
          label: "Dan",
          mainDescription: "Always bill interest at the end of the cycle",
          scenarioDescription: "Pays MTP only",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$6,238.86",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,090.25" },
                  { type: "", value: "$1,258.71" },
                  { type: "danger", value: "$1,258.71" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
      ],
    },
  },
  {
    title: "  Option D  ",
    tableData: {
      rates: [
        {
          label: "Interest rate",
          value: "15%",
        },
        {
          label: "MTP",
          value: "5%",
        },
      ],
      scenarios: [
        {
          key: "a",
          label: "Alice",
          mainDescription:
            "If full payment is done, then defer interest on new debits, waive deferred interest and interest on residual balance",
          scenarioDescription: "Pays Full payment always",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "success", value: "$1.23" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100,000.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "", value: "" },
                  { type: "warning", value: "$24.66" },
                  { type: "success", value: "$2,465.76" },
                ],
              },
            },
          ],
        },
        {
          key: "b",
          label: "Bob",
          mainDescription:
            "If full payment is done, then defer interest on new debits, waive deferred interest and interest on residual balance",
          scenarioDescription: "Pays MTP in the first cycle, but pays Full payment in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$101,329.11",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,258.71" },
                  { type: "", value: "" },
                  { type: "warning", value: "$24.66" },
                  { type: "success", value: "$1,234.05" },
                ],
              },
            },
          ],
        },
        {
          key: "c",
          label: "Charlie",
          mainDescription:
            "If full payment is done, then defer interest on new debits, waive deferred interest and interest on residual balance",
          scenarioDescription: "Pays Full payment in the first cycle, but pays MTP in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "success", value: "$1.23" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5,000.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "danger", value: "$2,490.41" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
        {
          key: "d",
          label: "Dan",
          mainDescription:
            "If full payment is done, then defer interest on new debits, waive deferred interest and interest on residual balance",
          scenarioDescription: "Pays MTP only",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$6,238.86",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,090.25" },
                  { type: "", value: "$1,258.71" },
                  { type: "danger", value: "$1,258.71" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
      ],
    },
  },
  {
    title: "  Option R  ",
    tableData: {
      rates: [
        {
          label: "Interest rate",
          value: "15%",
        },
        {
          label: "MTP",
          value: "5%",
        },
      ],
      scenarios: [
        {
          key: "a",
          label: "Alice",
          mainDescription:
            "Always bill interest on the residual balance, always defer interest on new debits, if full payment is done, then waive deferred interest",
          scenarioDescription: "Pays Full payment always",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1.23" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100,001.23",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "danger", value: "$1,232.88" },
                  { type: "warning", value: "$24.66" },
                  { type: "success", value: "$1,232.88" },
                ],
              },
            },
          ],
        },
        {
          key: "b",
          label: "Bob",
          mainDescription:
            "Always bill interest on the residual balance, always defer interest on new debits, if full payment is done, then waive deferred interest",
          scenarioDescription: "Pays MTP in the first cycle, but pays Full payment in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,23" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100,096.23",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,258.71" },
                  { type: "danger", value: "$1,234.05" },
                  { type: "warning", value: "$24.66" },
                  { type: "success", value: "$1,232.88" },
                ],
              },
            },
          ],
        },
        {
          key: "c",
          label: "Charlie",
          mainDescription:
            "Always bill interest on the residual balance, always defer interest on new debits, if full payment is done, then waive deferred interest",
          scenarioDescription: "Pays Full payment in the first cycle, but pays MTP in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1.23" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5,001.23",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "danger", value: "$2,465.76" },
                  { type: "warning", value: "$24.66" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
        {
          key: "d",
          label: "Dan",
          mainDescription:
            "Always bill interest on the residual balance, always defer interest on new debits, if full payment is done, then waive deferred interest",
          scenarioDescription: "Pays MTP only",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1.23" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5,005.98",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,090.25" },
                  { type: "", value: "$1,258.71" },
                  { type: "danger", value: "$2,466.93" },
                  { type: "warning", value: "$24.66" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
      ],
    },
  },
  {
    title: "  Option G  ",
    tableData: {
      rates: [
        {
          label: "Interest rate",
          value: "15%",
        },
        {
          label: "MTP",
          value: "5%",
        },
      ],
      scenarios: [
        {
          key: "a",
          label: "Alice",
          mainDescription:
            "Always defer interest on new debits, if full payment is done, then waive deferred interest and interest on the residual balance",
          scenarioDescription: "Pays Full payment always",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "success", value: "$1.23" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100,000.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "", value: "" },
                  { type: "warning", value: "$24.66" },
                  { type: "success", value: "$2,465.76" },
                ],
              },
            },
          ],
        },
        {
          key: "b",
          label: "Bob",
          mainDescription:
            "Always defer interest on new debits, if full payment is done, then waive deferred interest and interest on the residual balance",
          scenarioDescription: "Pays MTP in the first cycle, but pays Full payment in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1,23" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100,096.23",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$1,258.71" },
                  { type: "", value: "" },
                  { type: "warning", value: "$24.66" },
                  { type: "success", value: "$2,466.93" },
                ],
              },
            },
          ],
        },
        {
          key: "c",
          label: "Charlie",
          mainDescription:
            "Always defer interest on new debits, if full payment is done, then waive deferred interest and interest on the residual balance",
          scenarioDescription: "Pays Full payment in the first cycle, but pays MTP in the second one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$100.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "", value: "" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "success", value: "$1.23" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,000.00",
                  accrued: "$1,232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5,000.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,000.00" },
                  { type: "", value: "$1,257.53" },
                  { type: "danger", value: "$2,465.76" },
                  { type: "warning", value: "$24.66" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
        {
          key: "d",
          label: "Dan",
          mainDescription:
            "Always defer interest on new debits, if full payment is done, then waive deferred interest and interest on the residual balance",
          scenarioDescription: "Pays MTP only",
          cycles: [
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100.00",
                  accrued: "$1.23",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accrued: "$1232.88",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5.00",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$100,095.00" },
                  { type: "", value: "$1,234.11" },
                  { type: "danger", value: "$1.23" },
                  { type: "warning", value: "$1,232.88" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Residual Balance",
                  billedAmount: "$100,095.00",
                  accrued: "$1,234.05",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accrued: "$24.66",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
                {
                  subTitle: "Payment",
                  billedAmount: "$5,005.98",
                  accrued: "",
                  billedInterest: "",
                  deferred: "",
                  waived: "",
                },
              ],
              summary: {
                data: [
                  { type: "", value: "$97,090.25" },
                  { type: "", value: "$1,258.71" },
                  { type: "danger", value: "$2,466.93" },
                  { type: "warning", value: "$24.66" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
      ],
    },
  },
];
