import { FC, useMemo } from "react";
import { Form } from "antd";

import { GEN_FEE_OPTIONS } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CheckboxGroup, CustomFormWrapper, FormItemLabel } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { CHECKBOX_GROUP_GENERAL_FEES_PRESET } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const GeneralFeesPresetPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const { isProductTypePrepaid, isMultiCurrency } = useMemo(
    () => ({
      isProductTypePrepaid:
        (getFormValueFromProductValues(wizardResponse.product?.productValues, "product-type") as string) === "Prepaid",
      isMultiCurrency:
        (getFormValueFromProductValues(wizardResponse.product?.productValues, "curr-setup") as string) === "mult",
    }),
    [wizardResponse.product?.productValues],
  );

  const filteredCheckboxes = isProductTypePrepaid
    ? CHECKBOX_GROUP_GENERAL_FEES_PRESET.filter(
        checkbox => !["L", "O", "X", `${isMultiCurrency && "I"}`].includes(checkbox.value),
      )
    : CHECKBOX_GROUP_GENERAL_FEES_PRESET;

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      lists: [GEN_FEE_OPTIONS],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        ...(values[GEN_FEE_OPTIONS] && {
          [GEN_FEE_OPTIONS]: (values[GEN_FEE_OPTIONS] as string[]).join(","),
        }),
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="General Fees Pre-set"
      pageSubtitle="General lifecycle fees refer to the fees associated with owning and using a card throughout its lifespan. These fees are incurred regardless of the transactions made."
      size="full"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={GEN_FEE_OPTIONS}
        label={<FormItemLabel label="Choose general fees you want to apply to your product" code={GEN_FEE_OPTIONS} />}
      >
        <CheckboxGroup checkboxes={filteredCheckboxes} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
