import { FC, useEffect, useState } from "react";
import { Button, Col, Empty, Flex, Form, Input, Modal, Pagination, Row, Space, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { EditOutlined, SettingOutlined } from "@ant-design/icons";
import { initialInsurancePagination, useDebaunce, useLoyaltyProgramTemplates, useReduxState } from "@ni/common/hooks";
import { CopyIcon, WandIcon } from "@ni/common/icons";
import { FormValues } from "@ni/common/types";
import { CardPanel, CollapsedLinks, FilterControls, SettingsPageLayout } from "@ni/common/ui";
import { updateFilters } from "@ni/common/utils";
import { LoyaltyProgramTemplate, LoyaltyProgramTemplateState, Tenant } from "@ni/sdk/models";

import { loyaltyFilterInputs } from "../constants";

const filterKeys = ["state", "redemption_type", "loyalty_program_template.type"];

export const LoyaltyProgramTemplates: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const [modalForm] = Form.useForm<FormValues>();

  const { id: tenantId } = useParams<{ id: string }>();

  const searchQuery = Form.useWatch<string>("search", form);
  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });

  const [renameModalOpen, setRenameModalOpen] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<LoyaltyProgramTemplate>({});

  const [tenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading] = useReduxState<boolean>("isLoading");
  const [isPrintMode] = useReduxState<boolean>("isPrintMode");

  const navigate = useNavigate();

  const {
    loyaltyProgramTemplates,
    copyLoyaltyProgramTemplate,
    editLoyaltyProgramTemplate,
    pageHasContent,
    pagination,
    setPagination,
    setFilters,
  } = useLoyaltyProgramTemplates({
    tenantId: parseInt(tenantId ?? "0", 10),
    isFetchEnabled: true,
  });

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setFilters(filters => {
        return updateFilters(filterKeys, filters, undefined, debouncedQuery);
      });

      setPagination(initialInsurancePagination);
    }
  }, [debouncedQuery, setPagination, setFilters]);

  const handleValuesChange = (changedValues: FormValues) => {
    const { search } = changedValues;

    if (typeof search === "string") return;

    setFilters(filters => {
      return updateFilters(filterKeys, filters, changedValues, (search as string) || debouncedQuery);
    });

    setPagination(initialInsurancePagination);
  };

  const handlePaginationChange = (page: number) => {
    setPagination(prev => ({ ...prev, current: page }));
  };

  const handleContinueClick = (id: number) => {
    const queries = new URLSearchParams({ tenantId: tenantId!, loyaltyTemplateId: id.toString() });

    navigate({
      pathname: "/create-new-loyalty-program",
      search: queries.toString(),
    });
  };

  const handleNewTemplateClick = () => {
    const queries = new URLSearchParams({ tenantId: tenantId! });

    navigate({
      pathname: "/create-new-loyalty-program",
      search: queries.toString(),
    });
  };

  const renderSubtitleText = () => (
    <>
      You can apply the following loyalty program templates to any products or create a new one. These program templates
      can be redefined at a specific product level.
      {!pageHasContent && (
        <>
          <br />
          <br />
          You don&apos;t have any loyalty programs yet. Our smart wizard will guide you through the process.
        </>
      )}
    </>
  );

  const handleRenameClick = (obj: LoyaltyProgramTemplate) => {
    modalForm.setFieldsValue({
      renameNewDisplayName: obj.name,
    });
    setSelectedTemplate(obj);
    setRenameModalOpen(true);
  };

  const handleSettingsClick = (templateId: number) => {
    navigate(`../edit-template/${templateId}/transaction-eligibility`);
  };

  const handleDuplicateClick = (obj: LoyaltyProgramTemplate) => {
    modalForm.setFieldsValue({
      copyNewDisplayName: `${obj.name} - copy`,
    });
    setSelectedTemplate(obj);
    setDuplicateModalOpen(true);
  };

  const renderActionButtons = (obj: LoyaltyProgramTemplate) => {
    return obj.state === LoyaltyProgramTemplateState.DRAFT
      ? [
          <Button
            key="loyaltyWizard"
            type="text"
            htmlType="button"
            className="text-primary"
            icon={<WandIcon />}
            onClick={() => handleContinueClick(obj.id!)}
          >
            Continue Creating
          </Button>,
        ]
      : [
          <Tooltip key="loyaltyEdit" title="Rename">
            <Button type="text" htmlType="button" icon={<EditOutlined />} onClick={() => handleRenameClick(obj)} />
          </Tooltip>,
          <Tooltip key="loyaltySettings" title="Edit">
            <Button
              type="text"
              htmlType="button"
              icon={<SettingOutlined />}
              onClick={() => handleSettingsClick(obj.id!)}
            />
          </Tooltip>,
          <Tooltip key="loyaltyCopy" title="Duplicate">
            <Button type="text" htmlType="button" icon={<CopyIcon />} onClick={() => handleDuplicateClick(obj)} />
          </Tooltip>,
        ];
  };

  const toggleTemplate = async (values: FormValues, templateId: number) => {
    const { state } = values;
    await editLoyaltyProgramTemplate(
      { state: state ? LoyaltyProgramTemplateState.ACTIVE : LoyaltyProgramTemplateState.INACTIVE },
      templateId,
    );
  };

  const renameTemplate = async (values: FormValues, templateId: number) => {
    const { renameNewDisplayName } = values;

    await editLoyaltyProgramTemplate({ name: renameNewDisplayName }, templateId);
    setRenameModalOpen(false);
  };

  const duplicateTemplate = async (values: FormValues, templateId: number) => {
    const { copyNewDisplayName } = values;

    await copyLoyaltyProgramTemplate(copyNewDisplayName as string, templateId);
    setDuplicateModalOpen(false);
  };

  const relatedLinks = [
    {
      href: "../loyalty-settings",
      label: "Loyalty - General settings",
    },
  ];

  return (
    <SettingsPageLayout pageTitle="Loyalty Program Templates" pageSubtitle={renderSubtitleText()}>
      <Space direction="vertical" size={40} className="w-p-100">
        {pageHasContent && (
          <div>
            <FilterControls
              form={form}
              inputs={loyaltyFilterInputs}
              onValuesChange={handleValuesChange}
              searchName="search"
            />

            <Space direction="vertical" size={16} className="w-p-100">
              <Row wrap={true} gutter={[16, 16]}>
                {loyaltyProgramTemplates?.content?.map(obj => (
                  <Col key={`${obj.id}-${obj.name}`} span={8}>
                    <CardPanel
                      title={obj.name}
                      details={[
                        {
                          field: "Point accrual",
                          value: obj?.accrualDisplayDetails ?? "-",
                        },
                        {
                          field: "Redemption type",
                          value:
                            obj?.redemptionType === "C"
                              ? "Cashback"
                              : obj?.redemptionType === "E"
                                ? "External Voucher"
                                : "-",
                        },
                      ]}
                      actions={renderActionButtons(obj)}
                      toggleState={obj.state === LoyaltyProgramTemplateState.ACTIVE}
                      toggleDisabled={obj.state === LoyaltyProgramTemplateState.DRAFT}
                      onToggle={values => toggleTemplate(values, obj.id!)}
                      vertical={true}
                    />
                  </Col>
                ))}
              </Row>

              <Flex justify="flex-end">
                <Pagination
                  hideOnSinglePage={true}
                  showSizeChanger={false}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  current={pagination.current}
                  onChange={page => handlePaginationChange(page)}
                />
              </Flex>

              {!loyaltyProgramTemplates.hasContent && (
                <Flex justify="center" align="center">
                  <Empty />
                </Flex>
              )}
            </Space>
          </div>
        )}

        {!isPrintMode && (
          <Button size="large" type="primary" htmlType="button" loading={isLoading} onClick={handleNewTemplateClick}>
            New loyalty program template
          </Button>
        )}
      </Space>

      {tenant?.loyaltyProgramTemplates?.some(program => program.state === LoyaltyProgramTemplateState.ACTIVE) &&
        !isPrintMode && <CollapsedLinks list={relatedLinks} />}

      <Modal
        title="Rename Loyalty Program Template"
        width="500px"
        open={renameModalOpen}
        onCancel={() => setRenameModalOpen(false)}
        footer={[
          <Button key="back" disabled={isLoading} onClick={() => setRenameModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={modalForm.submit}>
            Rename
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          autoComplete="off"
          layout="vertical"
          disabled={isLoading}
          onFinish={values => renameTemplate(values, selectedTemplate.id!)}
        >
          <Form.Item
            name="renameNewDisplayName"
            label="Name"
            rules={[
              {
                type: "string",
                required: true,
                max: 64,
                message: "Cannot be empty. Max length is 64 letters",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Duplicate Loyalty Program Template"
        width="500px"
        open={duplicateModalOpen}
        onCancel={() => setDuplicateModalOpen(false)}
        footer={[
          <Button key="back" disabled={isLoading} onClick={() => setDuplicateModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={modalForm.submit}>
            Duplicate
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          autoComplete="off"
          layout="vertical"
          disabled={isLoading}
          onFinish={values => duplicateTemplate(values, selectedTemplate.id!)}
        >
          <Form.Item
            name="copyNewDisplayName"
            label="Name"
            rules={[
              {
                type: "string",
                required: true,
                max: 64,
                message: "Cannot be empty. Max length is 64 letters",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </SettingsPageLayout>
  );
};
