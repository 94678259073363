import { createRoot } from "react-dom/client";

import { UserService } from "@ni/common/utils";

import App from "./App";

import "antd/dist/reset.css";
import "@ni/common/scss";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

// console.error = (...data: any[]) => {
//   // temporary handler for the annoying "findDOMNode" warning (uncomment to hide), before deps upgrade
//   if (!data[0]?.toString().includes("findDOMNode")) console.error(...data);
// };

UserService.initKeycloak(() => root.render(<App />));
