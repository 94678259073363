import { FC, useMemo } from "react";
import { Button, Flex, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps } from "@ni/common/types";
import { Product, Tenant } from "@ni/sdk/models";

import { ProductsOverviewTable } from "../../components";
import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import styles from "./styles.module.scss";

export const InsuranceSuccessPage: FC<BaseWizardPageProps> = () => {
  const navigate = useNavigate();

  const [tenant] = useReduxState<Tenant>("tenant", {});
  const { wizardResponse, appliedPrograms } = useInsuranceWizardApi();

  const instantiatedInsuranceTemplates = useMemo(() => {
    const productMap = new Map();

    tenant?.products?.forEach(product => {
      productMap.set(product.id, product);
    });

    const updatedInsuranceArray = appliedPrograms
      ?.map(obj => {
        const product = productMap.get(obj.productId) as Product;
        return product ? { ...obj, product } : obj;
      })
      .sort((a, b) => parseInt(a.code!, 10) - parseInt(b.code!, 10));

    return updatedInsuranceArray;
  }, [appliedPrograms, tenant?.products]);

  const getPageDetails = () => {
    const details = {
      title: "",
      description: "",
    } as { title: string; description: string };

    if (appliedPrograms.length === 1) {
      details.title = "Program";
      details.description =
        "You can find below program you just created using this template, you can visit the -product level or pricing control table level- program settings for further customization";
    }

    if (appliedPrograms.length > 1) {
      details.title = "Programs";
      details.description =
        "You can find below programs you just created using this template, you can visit the -product level or pricing control table level- programs settings for further customization";
    }

    if (appliedPrograms.length === 0) {
      details.title = "Template";
      details.description =
        "Insurance template was created successfully, you can apply this template to eligible products from insurance program product level options or from inside insurance template management on tenant level";
    }

    return details;
  };

  return (
    <Space direction="vertical" size={40} className={styles["wizard-success-layout"]}>
      <Flex vertical={true} gap={6}>
        <Typography.Title level={1} className={styles["wizard-success-title"]}>
          Congratulations! {getPageDetails().title} Created Successfully
        </Typography.Title>
        <Typography.Text>{getPageDetails().description}</Typography.Text>
      </Flex>

      <Flex vertical={false} gap={40} className="w-p-100">
        <div className={styles["wizard-success-details"]}>
          <Typography.Title level={3}>{wizardResponse.insuranceTemplate?.name ?? "-"}</Typography.Title>
          <Typography.Text>Template name</Typography.Text>
        </div>
        <div className={styles["wizard-success-details"]}>
          <Typography.Title level={3}>{wizardResponse.insuranceTemplate?.insuranceCompanyName ?? "-"}</Typography.Title>
          <Typography.Text>Company name</Typography.Text>
        </div>
      </Flex>

      {appliedPrograms.length !== 0 && (
        <ProductsOverviewTable
          data={instantiatedInsuranceTemplates}
          tenantId={String(wizardResponse.tenant?.id) ?? "0"}
        />
      )}

      <Flex vertical={false} gap={16} className={styles["wizard-success-actions"]}>
        <Button size="large" type="default" htmlType="submit" onClick={() => navigate("/")}>
          Back to Dashboard
        </Button>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          disabled={!wizardResponse?.tenant?.id}
          onClick={() => {
            if (wizardResponse?.tenant?.id)
              navigate(`/tenant/${wizardResponse?.tenant?.id}/insurance-program-templates`);
          }}
        >
          Back to Insurance Program Templates
        </Button>
      </Flex>
    </Space>
  );
};
