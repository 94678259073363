import { FC, useState } from "react";
import { ConfigProvider, Form } from "antd";
import Search from "antd/lib/transfer/search";

import { FinancialInstitutionsFilters } from "@ni/common/types";
import { PageHeading } from "@ni/common/ui";

import FinancialInstitutionsTableView from "../FinancialInstitutionsTableView";

import styles from "./styles.module.scss";

const FinancialInstitutionsLayout: FC = () => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<FinancialInstitutionsFilters>({});

  const onFormChange = (currentValue: { [key: string]: string }, value: { [key: string]: string }) => {
    const filter: FinancialInstitutionsFilters = Object.keys(value).reduce((acc, key) => {
      if (key === "searchValue" && value[key]) {
        return { ...acc, search: value[key] };
      }
      return acc;
    }, {} as FinancialInstitutionsFilters);
    setFilters(filter);
  };

  const extra = (
    <div className={styles["extra"]} key="extradiv">
      <Form.Item name="searchValue">
        <Search placeholder="Search" />
      </Form.Item>
    </div>
  );

  return (
    <ConfigProvider componentSize="middle">
      <Form form={form} className={styles["financial-institutions-layout"]} onValuesChange={onFormChange}>
        <PageHeading text="Financial Institutions" extraElements={[extra]} />
        <FinancialInstitutionsTableView filters={filters} />
      </Form>
    </ConfigProvider>
  );
};

export default FinancialInstitutionsLayout;
