import { SelectOption } from "@ni/common/types";

export const nicCardCarrAddressLevPrim: SelectOption[] = [
  { value: "A", label: "To account holder" },
  { value: "C", label: "To card holder" },
];

export const nicCardCarrAddressLevSuppl: SelectOption[] = [
  { value: "A", label: "To primary account holder" },
  { value: "C", label: "To supplementary card holder" },
];
