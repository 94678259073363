import { FC } from "react";
import { Form, Typography } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import { usePlanApi } from "../../../../hooks";

import { annualInterestRate, ippInterestEnabled } from "./constants";

const stringsKeys = [ippInterestEnabled, annualInterestRate];

export const IPPInterestRate: FC = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, pctId, planId } = useParams<{ id: string; pctId: string; planId: string }>();
  const { currentPlan, editPlan, formValuesConverter } = usePlanApi(parseInt(planId ?? "0", 10));

  const isInterestEnabled = Form.useWatch<boolean>(ippInterestEnabled, form) === true;

  const getInterestPlanValue =
    currentPlan?.planValues?.find(item => item.fieldCode === ippInterestEnabled)?.value ??
    !!currentPlan?.planValues?.find(item => item.fieldCode === "nic-ipp-inst-int-plan-rate");

  useHydrateForm(
    {
      form,
      entityFields:
        currentPlan.planPctValues && currentPlan?.planPctValues?.length > 0
          ? currentPlan.planPctValues
          : currentPlan.planValues && currentPlan?.planValues?.length > 0
            ? [...currentPlan.planValues, { fieldCode: ippInterestEnabled, value: getInterestPlanValue.toString() }]
            : [],
      keys: {
        strings: stringsKeys,
      },
      allowParse: true,
    },
    [currentPlan],
  );

  const onFinish = async (values: FormValues) => {
    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && currentPlan?.planPctValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });
    await editPlan({ planPctValues: formValuesConverter(values, parseInt(pctId!, 10)) });
  };

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/edit-ipp/${currentPlan.id}/profit?tab=interest`,
      label: `Installment Payment Plans - ${currentPlan.name} - Interest Income`,
    },
  ];

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="IPP Interest Rate"
      pageSubtitle="This parameter indicates the interest rate that will be applied to the IPP balance amount."
      level="product"
      size="md"
      formSize="md"
      gap={40}
      submitLabel="Save"
      linkTitle="PCT Installment Plans"
      submitHandler={onFinish}
      additionalRoute={`/pct/${pctId}/installment-payment-plans`}
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: ippInterestEnabled,
          label: <Typography.Text strong={true}>Enable Interest Income</Typography.Text>,
          valuePropName: "checked",
          initialValue: false,
        }}
      />

      {isInterestEnabled && (
        <NetworkForm.Number
          formItemOptions={{
            name: annualInterestRate,
            label: "Annual interest rate",
          }}
          min={0}
          max={100}
          precision={2}
          controls={false}
          addonAfter="%"
        />
      )}
    </CustomFormWrapper>
  );
};
