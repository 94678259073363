import { SelectOption } from "@ni/common/types";
import { OrderDirectionEnum } from "@ni/sdk/models";

export const phaseTypeOptions: SelectOption[] = [
  { value: "TENANT_MANAGEMENT", label: "TENANT MANAGEMENT" },
  { value: "PRODUCT_MANAGEMENT", label: "PRODUCT MANAGEMENT" },
  { value: "PCT_MANAGEMENT", label: "PCT MANAGEMENT" },
];
export const branchListOptions: SelectOption[] = [
  { value: "Branch One", label: "Branch One" },
  { value: "Branch Two", label: "Branch Two" },
];

export const clientTypeOptions: SelectOption[] = [
  { value: "CONSUMER", label: "CONSUMER" },
  { value: "COMMERCIAL", label: "COMMERCIAL" },
];
export const clientTypeOptionsFilter: SelectOption[] = [
  { value: "CONSUMER", label: "CONSUMER" },
  { value: "COMMERCIAL", label: "COMMERCIAL" },
  { value: "all", label: "All" },
];
export const productTypeOptionsWithoutAll: SelectOption[] = [
  { value: "PREPAID", label: "Prepaid" },
  { value: "DEBIT", label: "Debit" },
  { value: "CREDIT", label: "Credit" },
];
export const productTypeOptionsFilter: SelectOption[] = [
  { value: "PREPAID", label: "Prepaid" },
  { value: "DEBIT", label: "Debit" },
  { value: "CREDIT", label: "Credit" },
  { value: "all", label: "All" },
];
export const balanceSourceOptions: SelectOption[] = [
  { value: "CORE_BANKING_SYSTEM", label: "Tenant" },
  { value: "CARD_MANAGEMENT_SYSTEM", label: "Network International" },
];
export const balanceSourceOptionsFilter: SelectOption[] = [
  { value: "CORE_BANKING_SYSTEM", label: "Tenant" },
  { value: "CARD_MANAGEMENT_SYSTEM", label: "Network International" },
  { value: "all", label: "All" },
];
export const productSubtypeOptions: SelectOption[] = [
  { value: "NONE", label: "None" },
  { value: "SALARY", label: "Salary card" },
  { value: "ELSE", label: "Else" },
];
export const productSubtypeOptionsFilter: SelectOption[] = [
  { value: "NONE", label: "None" },
  { value: "SALARY", label: "Salary card" },
  { value: "ELSE", label: "Else" },
  { value: "all", label: "All" },
];
export const workflowTypeOptionsFilter: SelectOption[] = [
  { value: "PRODUCT", label: "Product" },
  { value: "LOYALTY", label: "Loyalty" },
  { value: "all", label: "All" },
];
export const workflowTypesWithoutAll: SelectOption[] = [
  { value: "PRODUCT", label: "Product" },
  { value: "LOYALTY", label: "Loyalty" },
];

export const workflowDashboardPageConfig = {
  pageLimits: {
    number: 0,
    size: 1000,
  },
  sorting: [
    {
      direction: "ASC" as OrderDirectionEnum,
      property: "id",
    },
  ],
  filter: {},
};
