export const NIC_TOKEN_SUSPEND_UNEXP = "nic-token-suspend-unexp";
export const NIC_TOKEN_CLOSE_FOR_CLOSED = "nic-token-close-for-closed";
export const ABU_CLOSE_STAND_RULES = "abu-close-stand-rules";
export const NIC_ABU_OPTOUT_UNEXP = "nic-abu-optout-unexp";
export const NIC_ABU_VISA_ISS_ID = "nic-abu-visa_iss_id";

export const RADIO_GROUP_SUSPEND_UNEXP = [
  { value: "NULL", label: "Do not suspend tokens for expired and inactive cards" },
  { value: "Active or Inactive", label: "Suspend tokens if after expiration the card was not re-issued" },
  { value: "Active", label: "Suspend tokens if the card was not re-issued or was re-issued and not activated" },
];

export const RADIO_GROUP_OUTPUT_UNEXP = [
  { value: "NULL", label: "Do not opt-out expired and inactive cards" },
  { value: "Active or Inactive", label: "Opt-out the card if it was not re-issued after expiration" },
  { value: "Active", label: "Opt-out the card if it was not re-issued or was re-issued and not activated" },
];
