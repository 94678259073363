import { ReactNode } from "react";

import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
  addonAfter?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

export const coverageOption: NumericOptions = {
  code: "ins-cov-max",
  name: "Insurance maximum coverage amount",
  decimalsNumber: 2,
  min: 0,
  tooltip:
    "Specify the maximum insurance coverage amount for credit card holder outstanding balance, this parameter impact truncation indicator and premium amount calculation",
};

export const insuranceCoverOption: RadioOptions = {
  code: "ins-cov-ipp",
  name: "Default insurance covers: principal amount, unpaid fees, interests, VAT & due installments. Confirm whether to include unbilled payment plans installments",
  default: "TOTAL_BALANCE; -IPP_RET-LOAN-UNB",
  options: [
    {
      label: "Do not include unbilled IPP",
      value: "TOTAL_BALANCE; -IPP_RET-LOAN-UNB",
    },
    {
      label: "Include unbilled IPP loan only",
      value: "TOTAL_BALANCE",
    },
    {
      label: "Include both unbilled IPP loan and interest",
      value: "TOTAL_BALANCE; IPP_RET-INT-UNB",
    },
  ],
};

export const actionOption: RadioOptions = {
  code: "ins-cov-trunc",
  name: "Specify system action in case account's outstanding balance exceeds the maximum coverage amount",
  default: "F",
  tooltip:
    "Parameter that controls the action to take if the balance subject to insurance exceeds the maximum at risk that the Financial Institution defines as the maximum coverage.",
  options: [
    {
      label: "Premium calculation truncated to the maximum coverage instead of outstanding liability",
      value: "F",
      tooltip: "Premium is calculated based on maximum coverage amount instead of total outstanding",
    },
    {
      label: "Insurance is automatically cancelled and automatically reinstated when balance is eligible",
      value: "A",
      tooltip: "System shall auto reinstate insurance once the balance is less than specified maximum coverage amount",
    },
    {
      label: "Insurance is automatically cancelled and gets reinstated manually",
      value: "M",
      tooltip:
        "System will automatically cancel the insurance once outstanding balance is higher than maximum coverage, system will allow to manually reinstate when balance is less than maximum coverage",
    },
  ],
};
