import { ReactNode } from "react";

import { blockCodeValuesAndLabels, FieldValue, ProductBcParams } from "@ni/common/constants";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

interface DropdownOptions extends Options {
  options?: FieldValue[];
}

export const autoOption: DropdownOptions = {
  code: "ins-cncl-delinq-auto",
  name: "Specify delinquency level to cancel insurance with auto re-instantiation",
  default: "3",
  options: blockCodeValuesAndLabels.get(ProductBcParams.delinquencyLevel),
  tooltip:
    "This parameter specifies the delinquency level, at which insurance will be cancelled and can be auto re-instated incase delinquency level is lowered to lower levels or performing.",
};

export const manualOption: DropdownOptions = {
  code: "ins-cncl-delinq-man",
  name: "Specify delinquency level to cancel insurance with manual re-instantiation",
  default: "4",
  options: blockCodeValuesAndLabels.get(ProductBcParams.delinquencyLevel),
  tooltip:
    "This parameter specifies the delinquency level, at which insurance will be cancelled and can be reinstated manually only.",
};

export const ageOption: NumericOptions = {
  code: "ins-cncl-age-limit",
  name: "Insurance program cancellation age limit",
  decimalsNumber: 0,
  min: 0,
  default: 51,
  tooltip:
    "In case cardholder customer reaches a certain age as defined in this parameter; insurance will be automatically cancelled.",
};
