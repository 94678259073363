import { TablePaginationConfig } from "antd";

import { SdmElementDashboardPageData } from "@ni/sdk/models";

export const initialDataFramework: SdmElementDashboardPageData = {
  content: [],
  hasContent: false,
  hasNext: false,
  hasPrevious: false,
  isEmpty: false,
  isFirst: false,
  isLast: false,
  number: 0,
  numberOfElements: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
};

export const initialDataFrameworkPagination: TablePaginationConfig = {
  pageSize: 500,
  current: 1,
  total: 0,
  showSizeChanger: false,
};
