import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";

import { validationTypes } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { Validation } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface ValidationModalProps {
  isOpened: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: (values: FormValues) => void;
  isNewValidation: boolean;
  selectedValidationItem?: Validation;
}

const { TextArea } = Input;

export const ValidationModal = ({
  isOpened,
  isLoading,
  onCancel,
  onConfirm,
  isNewValidation,
  selectedValidationItem,
}: ValidationModalProps) => {
  const [form] = Form.useForm();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (isNewValidation) {
      form.setFieldsValue({
        validationType: "",
        value: "",
        message: "",
        id: "",
      });
      setIsEnabled(false);
    } else {
      form.setFieldsValue({
        validationType: selectedValidationItem?.validationType,
        value: selectedValidationItem?.value,
        message: selectedValidationItem?.message,
        id: selectedValidationItem?.id,
      });
      setIsEnabled(false);
    }
  }, [form, isNewValidation, selectedValidationItem]);

  const onChange = () => {
    const isFieldsFilled =
      form.getFieldValue("validationType")?.length > 0 && form.getFieldValue("message")?.length > 0;

    setIsEnabled(isFieldsFilled);
  };

  return (
    <Modal
      className={styles["modal-validation"]}
      title={isNewValidation ? "Add validation" : "Edit validation"}
      open={isOpened}
      width="800px"
      onCancel={onCancel}
      footer={[
        <div className={styles["modal-validation-footer"]}>
          <Button className={styles["modal-validation-button"]} key="back" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className={styles["modal-validation-button"]}
            disabled={!isEnabled}
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={form.submit}
          >
            Confirm
          </Button>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onConfirm} onChange={onChange}>
        <div className={styles["form-items"]}>
          <Form.Item hidden={true} name="id" />
          <Form.Item required={true} name="validationType" label="Validation type">
            <Select placeholder="Select type" onChange={onChange}>
              {validationTypes.map(branch => (
                <Select.Option key={branch.label} value={branch.value}>
                  {branch.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="value" label="Validation value">
            <Input />
          </Form.Item>
        </div>
        <div>
          <Form.Item required={true} name="message" label="Message">
            <TextArea rows={4} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
