import { FC, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useReduxState, useToggleFeature } from "@ni/common/hooks";
import { DashboardTenantUI, ShortTenant } from "@ni/common/types";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";
import { ProductApi, TenantApi } from "@ni/sdk/apis";
import { Tenant } from "@ni/sdk/models";

import { getTenantPagesRoutes } from "./pages/route";

import styles from "./styles.module.scss";

const tenantServiceApi = new TenantApi();
const productServiceApi = new ProductApi();

export const TenantSettings: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [dashboardCards] = useReduxState<DashboardTenantUI[]>("dashboardCards");
  const [tenants, setTenants] = useReduxState<ShortTenant[]>("tenants", []);
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const { checkFeatureDisabled } = useToggleFeature();
  const { id } = useParams<{ id: string }>();

  const getTenant = () => {
    setIsLoading(true);
    tenantServiceApi
      .getTenantById(parseInt(id ?? "", 10))
      .then(response => {
        setTenant(response.data || {});
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getTenants = () => {
    if (!dashboardCards?.length) {
      setIsLoading(true);
      productServiceApi
        .getDashboardProducts({
          pageLimits: {
            number: 0,
            size: 100,
          },
        })
        .then(response => {
          const content: ShortTenant[] = [];
          response.data.content?.forEach(val => {
            if (!content.find(item => item.id === val.tenant.id)) {
              content.push(val.tenant as ShortTenant);
            }
          });

          setTenants(content);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setTenants(
        dashboardCards?.map(x => ({ id: x.id, name: x.name, financialInstitutionId: x.financialInstitutionId })),
      );
    }
  };

  useEffect(() => {
    getTenant();
    getTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChangeTenant = useCallback(
    (tenantId: number): void => {
      navigate(`/tenant/${tenantId}/${getLastRoute(pathname)}`);
    },
    [navigate, pathname],
  );

  return (
    <RoutesHandler
      deps={[tenant.id, tenants, onChangeTenant]}
      routesList={getTenantPagesRoutes(checkFeatureDisabled)}
      ParentComponent={({ navItems, children }) => (
        <div className={styles["ni-tenant"]}>
          <Sidenav items={navItems} disabledItemsViewType="hidden" className={styles["sidenav"]}>
            <SidenavEntitySelect value={tenant?.id} entities={tenants} onChange={onChangeTenant} />
          </Sidenav>
          <div className={styles["ni-tenant-content"]}>{children}</div>
        </div>
      )}
    />
  );
};
