import { SelectOption } from "@ni/common/types";

export const nicDeclTransFeeValPredefRcOptions: SelectOption[] = [
  { value: "01", label: "01 - Refer to card issuer" },
  { value: "02", label: "02 - Refer to card issuer's special condition" },
  { value: "03", label: "03 - Invalid merchant / source" },
  { value: "04", label: "04 - PICK UP" },
  { value: "05", label: "05 - Do not Honour" },
  { value: "07", label: "07 - Pick-up card, special condition" },
  { value: "41", label: "41 - Pick up, lost card" },
  { value: "43", label: "43 - Pick up, stolen card" },
  { value: "51", label: "51 - Not sufficient funds" },
  { value: "54", label: "54 - Expired card / target" },
  { value: "57", label: "57 - Transaction not permitted to cardholder" },
  { value: "55", label: "55 - Incorrect PIN" },
  { value: "61", label: "61 - Exceeds withdrawal amount limit" },
  { value: "65", label: "65 - Exceeds withdrawal frequency limit" },
  { value: "75", label: "75 - Allowable number of PIN tries exceeded" },
  { value: "83", label: "83 - Transaction failed" },
];

export const nicGeneralCardRelatedParamsOptions: SelectOption[] = [
  { value: "05", label: "RC=05 (Do not Honour)" },
  { value: "01", label: "RC=01 (Refer to card issuer)" },
  { value: "54", label: "RC=54 (Expired card / target)" },
];
