import { Dispatch, FC, SetStateAction } from "react";
import { Button, Tag, Typography } from "antd";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { MessageIcon } from "@ni/common/icons";
import { TooltipInfo } from "@ni/common/ui";
import { Variable } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface IProps extends Variable {
  setIsDeleteModalOpened: Dispatch<SetStateAction<boolean>>;
  setDeleteId: Dispatch<SetStateAction<null | Variable["id"]>>;
  setIsEditModalOpened: Dispatch<SetStateAction<boolean>>;
}

export const VariableItem: FC<IProps> = props => {
  const { code, id, description, example, setIsDeleteModalOpened, setDeleteId, setIsEditModalOpened } = props;

  const codeWithPercent = (value: string) => {
    if (value.at(0) !== "%" && value.at(-1) !== "%") {
      return `%${value}%`;
    }
    if (value.at(0) !== "%") {
      return `%${value}`;
    }
    if (value.at(-1) !== "%") {
      return `${value}%`;
    }
    return value;
  };

  return (
    <div className={styles["card"]}>
      <div className={styles["card-layout"]}>
        <div className={styles["card-layout-left-col"]}>
          <Typography.Text>{description}</Typography.Text>
          <Tag className={styles["card-layout-tag"]}>{codeWithPercent(code)}</Tag>
        </div>
        <div className={styles["card-layout-right-col"]}>
          {example && (
            <div className={styles["example"]}>
              <TooltipInfo tooltipProps={{ placement: "top", title: example }}>
                <div>
                  <MessageIcon className={styles["table-cell-icon"]} />
                </div>
              </TooltipInfo>
            </div>
          )}
          <Button
            type="text"
            shape="circle"
            onClick={() => {
              setIsEditModalOpened(true);
              setDeleteId(id);
            }}
            icon={<EditOutlined width={16} />}
          />
          <Button
            type="text"
            shape="circle"
            onClick={() => {
              setIsDeleteModalOpened(true);
              setDeleteId(id);
            }}
            icon={<DeleteOutlined width={16} />}
          />
        </div>
      </div>
    </div>
  );
};
