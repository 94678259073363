import { FC, Key, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Menu } from "antd";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { NavbarItem } from "@ni/common/types";
import { validateJsonPath } from "@ni/common/utils";
import { Product, Tenant, UserFull } from "@ni/sdk/models";

import styles from "./styles.module.scss";

type DisabledItemsViewType = "blocked" | "hidden";

interface SidenavProps {
  items: NavbarItem[];
  disabled?: boolean;
  bordered?: boolean;
  disabledItemsViewType?: DisabledItemsViewType;
  className?: string;
  children?: JSX.Element;
}

interface MenuItem {
  key: string;
  children: MenuItem[];
  label: string;
  type: string;
  disabled?: boolean;
}

const getItem = (
  label: ReactNode,
  key: Key,
  nestedItems?: MenuItem[],
  type?: "group",
  disabled?: boolean,
): MenuItem => {
  return { key, children: nestedItems, label, type, disabled } as MenuItem;
};

const getMenuItems = (
  items: NavbarItem[],
  tenant: Tenant,
  user: UserFull,
  product: Product,
  disabledItemsViewType: DisabledItemsViewType,
  submenuKey = 1,
): MenuItem[] => {
  let currentSubmenuKey = 1;

  const menu = items.map(navItem => {
    const { name, route, dependency, dependencyType, nestedItems } = navItem;

    let disabled = false;
    if (dependency && dependencyType) {
      if (dependencyType === "TENANT") {
        disabled = !validateJsonPath(tenant, dependency);
      }

      if (dependencyType === "USER" && user) {
        disabled = !validateJsonPath(user, dependency);
      }

      if (dependencyType === "PRODUCT" && product) {
        disabled = !validateJsonPath(product, dependency);
      }
    }

    if (nestedItems) {
      currentSubmenuKey += 1;

      return getItem(
        name,
        `${submenuKey.toString()}_${currentSubmenuKey.toString()}`,
        getMenuItems(nestedItems, tenant, user, product, disabledItemsViewType),
        undefined,
        disabled,
      );
    }
    return getItem(name, route ?? "", undefined, undefined, disabled);
  });

  if (disabledItemsViewType === "hidden") {
    return menu.filter(el => !el.disabled);
  }

  return menu;
};

const getRoute = (items: MenuItem[], path: string): { key: string; parent: string } => {
  let foundKey = "";
  let parent = "";

  items.forEach(({ children, key }: MenuItem) => {
    if (path.endsWith(key)) {
      foundKey = key;
    }

    if (!foundKey && children) {
      const foundElement = getRoute(children, path);
      if (foundElement.key) {
        foundKey = foundElement.key;
        parent = foundElement.parent || key;
      }
    }
  });

  return { key: foundKey, parent };
};

export const Sidenav: FC<SidenavProps> = ({
  items,
  disabled,
  bordered = true,
  disabledItemsViewType = "blocked",
  className = "",
  children,
}) => {
  const [tenant] = useReduxState<Tenant>("tenant", {});
  const [user] = useReduxState<UserFull>("user");
  const [product] = useReduxState<Product>("currentProduct", {});

  const [selectedItem, setSelectedItem] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = useMemo(
    () => getMenuItems(items, tenant, user, product, disabledItemsViewType, 1),
    [disabledItemsViewType, items, product, tenant, user],
  );

  const [openedKeys, setOpenedKeys] = useState<string[]>([getRoute(menuItems, location.pathname).parent]);

  const clickHandler = useCallback(
    ({ key }: { key: string }): void => {
      if (!disabled) {
        setSelectedItem(key);
        navigate(key);
      }
    },
    [disabled, navigate],
  );

  useEffect(() => {
    if (menuItems.length) {
      const initialRoute = getRoute(menuItems, location.pathname);
      if (initialRoute.key) setSelectedItem(initialRoute.key);
      else if (/^\/tenant\//.test(location.pathname)) {
        const key = menuItems[0]?.children?.length ? menuItems[0]?.children[0]?.key : menuItems[0]?.key;
        clickHandler({ key });
      }
    }
  }, [clickHandler, location.pathname, menuItems]);

  const onOpenKeysChange = (keys: string[]) => {
    setOpenedKeys(keys);
  };

  return (
    <div className={cn(styles["ni-sidenav"], className)}>
      <div className={cn(styles["ni-sidenav-fixed"], bordered && styles["ni-sidenav-fixed-border"])}>
        <div className={styles["ni-sidenav-content"]}>{children}</div>
        <Menu
          className={styles["ni-sidenav-menu"]}
          mode="inline"
          items={menuItems}
          selectedKeys={[selectedItem]}
          onClick={clickHandler}
          openKeys={openedKeys}
          onOpenChange={onOpenKeysChange}
        />
      </div>
    </div>
  );
};
