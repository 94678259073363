import { FC } from "react";
import { Form } from "antd";

import { useHydrateForm } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { ageOption, autoOption, manualOption } from "./constants";

export const InsuranceCancelationPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest } = useInsuranceWizardApi();

  useHydrateForm({
    form,
    entityFields: (wizardResponse.insuranceTemplate?.values as { code: string; value: string }[]) ?? [],
    keys: {
      strings: [ageOption.code, autoOption.code, manualOption.code],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    void processWizardRequest({
      pageId: wizardResponse?.pageId as number,
      tenantId: wizardResponse.tenant?.id,
      insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Insurance Cancellation Settings"
      pageSubtitle="Insurance can be cancelled for an account at any time manually by the customer, or automatically by system, based upon certain factors like account delinquency level or customer age. You can customize the age parameter on either product level settings or a specific pricing control table level settings, from related screens after wizard"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="md"
      formSize="md"
      gap={40}
      onValuesChange={form.validateFields}
      submitHandler={onFinish}
    >
      <NetworkForm.Select
        formItemOptions={{
          name: autoOption.code,
          label: autoOption.name,
          initialValue: autoOption.default,
          tooltip: autoOption.tooltip,
          rules: [
            {
              validator: (_, value) =>
                value >= form.getFieldValue(manualOption.code)
                  ? // eslint-disable-next-line prefer-promise-reject-errors
                    Promise.reject(
                      "Delinquency level for auto re-instantiation can not be greater than or equal to selected manual re-instantiation level",
                    )
                  : Promise.resolve(),
            },
          ],
        }}
        optionList={autoOption?.options?.map(({ displayed, value }) => ({ label: displayed, value })) ?? []}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: manualOption.code,
          label: manualOption.name,
          initialValue: manualOption.default,
          tooltip: manualOption.tooltip,
          rules: [
            {
              validator: (_, value) =>
                value <= form.getFieldValue(autoOption.code)
                  ? // eslint-disable-next-line prefer-promise-reject-errors
                    Promise.reject(
                      "Delinquency level for manual re-instantiation cannot be less than selected for automated re-instantiation",
                    )
                  : Promise.resolve(),
            },
          ],
        }}
        optionList={manualOption?.options?.map(({ displayed, value }) => ({ label: displayed, value })) ?? []}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: ageOption.code,
          label: ageOption.name,
          initialValue: ageOption.default,
          tooltip: ageOption.tooltip,
        }}
        addonAfter="years"
        min={ageOption.min}
        max={ageOption.max}
        precision={ageOption.decimalsNumber}
      />
    </CustomFormWrapper>
  );
};
