import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0556 12.6V13C15.0552 13.3712 14.9185 13.727 14.6755 13.9895C14.4325 14.2519 14.103 14.3996 13.7593 14.4H3.01855C2.67487 14.3996 2.34538 14.2519 2.10236 13.9895C1.85934 13.727 1.72264 13.3712 1.72225 13V12.6C1.72225 12.5212 1.73662 12.4432 1.76454 12.3704C1.79246 12.2976 1.83338 12.2315 1.88497 12.1757C1.93656 12.12 1.9978 12.0758 2.06521 12.0457C2.13261 12.0155 2.20485 12 2.27781 12C2.35076 12 2.42301 12.0155 2.49041 12.0457C2.55781 12.0758 2.61906 12.12 2.67064 12.1757C2.72223 12.2315 2.76315 12.2976 2.79107 12.3704C2.81899 12.4432 2.83336 12.5212 2.83336 12.6V13C2.83336 13.053 2.85287 13.1039 2.8876 13.1414C2.92233 13.1789 2.96943 13.2 3.01855 13.2H13.7593C13.8084 13.2 13.8555 13.1789 13.8902 13.1414C13.925 13.1039 13.9445 13.053 13.9445 13V12.6C13.9445 12.4409 14.003 12.2883 14.1072 12.1757C14.2114 12.0632 14.3527 12 14.5 12C14.6474 12 14.7887 12.0632 14.8929 12.1757C14.9971 12.2883 15.0556 12.4409 15.0556 12.6Z"
      fill="#8A8A8D"
    />
    <path
      d="M10.5412 1.33356C10.7045 1.33356 10.861 1.395 10.9765 1.50436C11.0919 1.61373 11.1568 1.76206 11.1568 1.91673V5.53784C11.5565 5.21156 12.0656 5.0305 12.5932 5.02698C13.9532 5.02698 15.0556 6.24542 15.0556 7.74845C15.0556 9.25148 13.9532 10.4699 12.5932 10.4699C12.0656 10.4664 11.5565 10.2853 11.1568 9.95906V10.0811C11.1568 10.2358 11.0919 10.3841 10.9765 10.4935C10.861 10.6029 10.7045 10.6643 10.5412 10.6643C10.3779 10.6643 10.2213 10.6029 10.1059 10.4935C9.99045 10.3841 9.92559 10.2358 9.92559 10.0811V1.91673C9.92559 1.76206 9.99045 1.61373 10.1059 1.50436C10.2213 1.395 10.3779 1.33356 10.5412 1.33356ZM12.5932 9.30358C13.1021 9.30358 13.8244 8.78261 13.8244 7.74845C13.8244 6.71429 13.1021 6.19332 12.5932 6.19332C12.0843 6.19332 11.362 6.71429 11.362 7.74845C11.362 8.78261 12.0843 9.30358 12.5932 9.30358ZM5.61232 1.33356C5.73874 1.33524 5.86154 1.37375 5.96404 1.44387C6.06655 1.51398 6.14377 1.6123 6.18523 1.72545L9.18277 9.88986C9.20972 9.9623 9.22129 10.0391 9.21682 10.1158C9.21236 10.1925 9.19195 10.2676 9.15676 10.3368C9.12157 10.4059 9.0723 10.4679 9.01178 10.519C8.95126 10.5701 8.88067 10.6094 8.80408 10.6346C8.72749 10.6598 8.6464 10.6704 8.56546 10.6658C8.48453 10.6612 8.40535 10.6415 8.33246 10.6079C8.25958 10.5742 8.19443 10.5273 8.14076 10.4697C8.08709 10.4121 8.04595 10.3451 8.01971 10.2724L7.37867 8.52601H3.61368L2.9078 10.288C2.84712 10.4289 2.73071 10.5417 2.58341 10.6024C2.43612 10.6631 2.2696 10.6669 2.11942 10.6129C1.96923 10.5589 1.84727 10.4515 1.77957 10.3135C1.71187 10.1755 1.70378 10.0179 1.75705 9.87431L5.02709 1.7099C5.07192 1.59791 5.15206 1.50171 5.25662 1.43438C5.36117 1.36704 5.48508 1.33184 5.6115 1.33356H5.61232ZM4.08154 7.35967H6.95021L5.57867 3.62348L4.08154 7.35967Z"
      fill="#8A8A8D"
    />
  </svg>
);

export const TextIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
