import { FC } from "react";
import { Empty, Space, Table, TableColumnType } from "antd";
import { Link } from "react-router-dom";

import { FullDashboardProduct } from "@ni/sdk/models";

import { CardView } from "../CardView";

type ItemType = FullDashboardProduct & { name?: string };

interface ProductListProps {
  data: Array<ItemType>;
  tenantId: string;
  route?: string;
  emptyDescription?: string;
}

export const ProductsOverviewTable: FC<ProductListProps> = ({ data, tenantId, route, emptyDescription }) => {
  const columns: TableColumnType<ItemType>[] = [
    {
      title: "Product Name",
      dataIndex: ["displayName"],
      key: "productName",
      width: "25%",
      ellipsis: true,
      render: (_: string, item: ItemType) => (
        <Space size={18}>
          <CardView cardImage={item.product?.cardImage} prodIps={item.product.paymentScheme} cardSize="small" />
          {item.product?.name}
        </Space>
      ),
    },
    {
      title: "Program Code",
      dataIndex: ["code"],
      key: "programCode",
      width: "15%",
      ellipsis: true,
      render: (_: string, item: ItemType) => `${item.product.currency}`,
    },
    {
      title: "Program Name",
      dataIndex: ["name"],
      key: "programName",
      width: "20%",
      ellipsis: true,
      render: (_: string, item: ItemType) => `${item?.name}`,
    },
    {
      title: "",
      key: "actions",
      width: "15%",
      ellipsis: true,
      render: (_: string, item: ItemType) =>
        tenantId && (
          <Link to={`/tenant/${tenantId}/product/${item.product?.id}/${route}`}>Visit Product Settings &#10132;</Link>
        ),
    },
  ];

  return data?.length ? (
    <Table<FullDashboardProduct> rowKey="id" columns={columns} dataSource={data} loading={false} pagination={false} />
  ) : (
    <Empty description={emptyDescription} />
  );
};
