import { Tenant } from "@ni/sdk/models";

import { FormValueReturnType } from "./getFormValueFromProductValues";

export const getFormValueTenantValues = (
  tenant: Tenant,
  field: string,
  returnType: FormValueReturnType = "string",
): string | boolean | number => {
  const value = tenant.tenantValues?.find(val => val.fieldCode === field)?.value;

  switch (returnType) {
    case "string":
      return value as string;
    case "boolean":
      return value === "true";
    case "number":
      return parseFloat(value as string);
    default:
      return value as string;
  }
};
