import { useEffect } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";
import { checkboxFieldsPage11, checkHeader, getLimitsPreparedValues, switchHeader11 } from "@ni/tenant-portal/core";

import { usePct } from "../../../../hooks";
import { prepareFieldsToRender } from "../../../../utils";

import { cashRules, retailRules, totalRules } from "./constants";

export const CardLimitsMonthlyPage = () => {
  const { productId, pctId } = useParams<{ productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, getValueFromPct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    prepareFieldsToRender(checkboxFieldsPage11, (fieldName: string) => {
      form.setFieldValue(fieldName, getValueFromPct(fieldName));
    });

    Object.keys(checkboxFieldsPage11).forEach(key => {
      checkHeader(key, checkboxFieldsPage11, form);
    });

    checkHeader("card-monthly-all-cash-enable", switchHeader11, form);
    checkHeader("card-monthly-all-retail-enable", switchHeader11, form);
    checkHeader("card-monthly-all-total-enable", switchHeader11, form);
  }, [form, getValueFromPct, pct]);

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      let actualValues: FormValues = {};
      actualValues = {
        ...actualValues,
        ...getLimitsPreparedValues(values as { [key: string]: string | boolean }, checkboxFieldsPage11),
      };

      await onSavePct(actualValues);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Card Limits - Monthly"
      pageSubtitle="Card monthly limits are applied in conjunction with card daily and account daily, which ever limit is reached first."
      submitHandler={onFinish}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
    >
      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="card-monthly-all-cash-enable"
        title="Cash"
        tooltip="Turns off all cash limits."
        rules={cashRules}
      />

      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="card-monthly-all-retail-enable"
        title="Retail"
        tooltip="Turns off all retail transactions."
        rules={retailRules}
      />

      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name="card-monthly-all-total-enable"
        title="Total"
        tooltip="Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended."
        rules={totalRules}
      />
    </CustomFormWrapper>
  );
};
