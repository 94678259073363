import { FC, useMemo } from "react";
import { Alert, Form } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { Product } from "@ni/sdk/models";

import { useInsuranceProgramApi } from "../../../../hooks";

import { ageOption, autoOption, manualOption } from "./constants";

export const ProductCancellationSettings: FC = () => {
  const { id: tenantId, insuranceId, productId } = useParams<{ id: string; insuranceId: string; productId: string }>();
  const [form] = Form.useForm<FormValues>();
  const { currentInsuranceProgram: currentInsurance, editInsurance, formValuesConverter } = useInsuranceProgramApi();

  const [currentProduct] = useReduxState<Product>("currentProduct", {});

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [ageOption.code, autoOption.code, manualOption.code],
    },
    allowParse: false,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  const relatedLinks = useMemo(
    () => [
      {
        label: "Insurance Program Templates",
        href: `/tenant/${tenantId}/insurance-program-templates`,
      },
      ...(currentProduct.parameterTables?.flatMap(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/insurance-edit/${insuranceId}/insurance-premium-settings?tab=waiving`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - ${currentInsurance?.name} - Waiving and Cancellation`,
      })) || []),
    ],
    [currentInsurance, currentProduct, tenantId, productId, insuranceId],
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Cancellation Settings"
      pageSubtitle="Insurance can be cancelled for an account at any time; manually by the customer, or automatically by system, based upon certain factors like account delinquency level or customer age. You can also specify program re-instantiation mode from below parameters."
      size="md"
      formSize="full"
      gap={24}
      submitLabel="Save"
      onValuesChange={form.validateFields}
      submitHandler={onFinish}
      level="product"
      linkTitle="Product Settings"
      additionalRoute="/insurance-programs"
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Select
        formItemOptions={{
          name: autoOption.code,
          label: autoOption.name,
          tooltip: autoOption.tooltip,
          initialValue: autoOption.default,
          rules: [
            {
              validator: (_, value) =>
                value >= form.getFieldValue(manualOption.code) ? Promise.reject() : Promise.resolve(),
              message:
                "Delinquency level for auto re-instantiation cannot be greater than or equal to selected manual re-instantiation level",
            },
          ],
        }}
        optionList={autoOption.options?.map(x => ({ label: x.displayed, value: x.value }))}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: manualOption.code,
          label: manualOption.name,
          tooltip: manualOption.tooltip,
          initialValue: manualOption.default,
          rules: [
            {
              validator: (_, value) =>
                value <= form.getFieldValue(autoOption.code) ? Promise.reject() : Promise.resolve(),
              message:
                "Delinquency level for manual re-instantiation cannot be less than selected for automated re-instantiation",
            },
          ],
        }}
        optionList={manualOption.options?.map(x => ({ label: x.displayed, value: x.value }))}
      />

      <Alert
        message="Below preferences will be applied to all contracts under this product, you can override below parameter(s) value for a specific pricing control table in related screens."
        type="info"
        showIcon={true}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: ageOption.code,
          label: ageOption.name,
          tooltip: ageOption.tooltip,
          initialValue: ageOption.default,
        }}
        addonAfter="years"
        min={ageOption.min}
        max={ageOption.max}
        precision={ageOption.decimalsNumber}
      />
    </CustomFormWrapper>
  );
};
