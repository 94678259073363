import { useCallback, useEffect } from "react";

import { sortLoyaltyPrograms } from "@ni/common/utils";
import { LoyaltyProgramApi } from "@ni/sdk/apis";
import { LoyaltyProgram } from "@ni/sdk/models";

import { useReduxState } from "../store";

const loyaltyProgramApi = new LoyaltyProgramApi();

interface UseLoyaltyProgramsProps {
  productId: number;
  isFetchEnabled: boolean;
}

export const useProductLoyaltyPrograms = ({ productId, isFetchEnabled }: UseLoyaltyProgramsProps) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [loyaltyPrograms, setLoyaltyPrograms] = useReduxState<LoyaltyProgram[]>("loyaltyPrograms", []);

  const fetchLoyaltyPrograms = useCallback(
    async (productId: number) => {
      setIsLoading(true);
      try {
        const programsResponse = await loyaltyProgramApi.getLoyaltyProgramsByProductId(productId);

        setLoyaltyPrograms(sortLoyaltyPrograms(programsResponse.data));
      } catch (err) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setLoyaltyPrograms],
  );

  useEffect(() => {
    if (isFetchEnabled) void fetchLoyaltyPrograms(productId);
  }, [fetchLoyaltyPrograms, isFetchEnabled, productId]);

  return {
    loyaltyPrograms,
    setLoyaltyPrograms,
    fetchLoyaltyPrograms,
  };
};
