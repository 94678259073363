export const RADIO_GROUP_TIMER_FROM = [
  {
    value: "START_CONTRACT",
    label: "Account boarding",
    tooltip: "Evening of the account boarding",
  },
  {
    value: "FIRST_CARD_ACT",
    label: "First card activation",
    tooltip: "When the first card was activated under the account",
  },
];

export const RADIO_GROUP_TIMER_PER_TYPE = [
  { value: "", label: "Days" },
  { value: "B", label: "Full billing cycles", tooltip: "Cycle when the timer was triggered is not counted" },
  { value: "M", label: "Calendar months" },
];
