export const RULE_STATEMENTS_OPTIONS = [
  { label: "P2P payment", name: "p2p" as const },
  {
    label: "Primary/Supplementary cards",
    name: "primarySupplementary" as const,
  },
];

export const P2P_PAYMENT_ENABLED_OPTIONS = [
  { label: "It is", value: "PREF_EN=" },
  { label: "It is not", value: "PREF_EN_NOT=" },
];

export const P2P_PAYMENT_ENABLED_OPTIONS_INCLUDED_KEY = "PREF_EN";

export const P2P_PAYMENT_OPTIONS = [
  { label: "P2P payment initiated by the same account", value: "PAYM_P2P_MAIN;" },
  { label: "P2P payment initiated by the same client", value: "PAYM_P2P_CL;" },
  { label: "P2P payment initiated within the same financial institution", value: "PAYM_P2P_FI;" },
];

export const PRIMARY_SUPPLEMENTARY_OPTIONS = [
  {
    label: "It is payment to supplementary card",
    value: "PREF_ORIG.IF_CS_TYPE=PRIM_SUPPLY_CLASS;PREF_ORIG.IF_CS_VALUE=S;",
  },
  { label: "It is payment to primary cards", value: "PREF_ORIG.IF_CS_TYPE=PRIM_SUPPLY_CLASS;PREF_ORIG.IF_CS_VALUE=P;" },
  {
    label: "It is payment from primary card",
    value: "PREF_SELF.IF_CS_TYPE=PRIM_SUPPLY_CLASS;PREF_SELF.IF_CS_VALUE=P;",
  },
  {
    label: "It is payment from supplementary card",
    value: "PREF_SELF.IF_CS_TYPE=PRIM_SUPPLY_CLASS;PREF_SELF.IF_CS_VALUE=S;",
  },
];

export const PRIMARY_SUPPLEMENTARY_OPTIONS_INCLUDED_KEY = "IF_CS_TYPE";
