import { ReactNode } from "react";
import { Space } from "antd";

import { blockCodeValuesAndLabels, FieldValue, ProductBcParams } from "@ni/common/constants";
import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

interface DropdownOptions extends Options {
  options?: FieldValue[];
}

export const nameOption: Options = {
  code: "name",
  name: "Plan name",
  tooltip: "Specify the short plan name to have a semantic meaning for it",
};

export const groupOption: RadioOptions = {
  code: "group",
  name: "Select plan group",
  options: [
    {
      label: "Balance conversion",
      value: "IPP_BC",
      tooltip:
        "Balance conversion allows customers to convert outstanding retail balance -consisting of multiple transactions- into monthly instalments",
    },
    {
      label: "Transaction conversion",
      value: "IPP_TC",
      tooltip: "A single retail transaction is converted into IPP",
    },
    {
      label: "New balance",
      value: "IPP_NB",
      tooltip:
        "new transaction will be posted to cardholder’s account and converted to IPP “Easy Cash or Loan on call”",
    },
  ],
  tooltip: (
    <Space direction="vertical">
      <div>Plan group enables you to categorize configured plans for better usability and filtering options.</div>
      <div>
        Plans that are included in a group can determine the following: specific booking actions; interfaces that can be
        used to book a plan; and specific accounting setup.
      </div>
      <div> If customized segregation is required, please contact your Network International representative</div>
    </Space>
  ),
};

export const currencyOption: DropdownOptions = {
  code: "currency",
  name: "Plan currency",
  options: [
    { displayed: "AED", value: "AED" },
    { displayed: "SAR", value: "SAR" },
    { displayed: "USD", value: "USD" },
    { displayed: "ZAR", value: "ZAR" },
  ],
  tooltip: "All currency-related fields for the plan will be specified in this particular currency",
};

export const tenorOption: NumericOptions = {
  code: "nic-ipp-inst-scheme-plan-tenor",
  name: "Plan tenor (number of months)",
  decimalsNumber: 0,
  min: 0,
  tooltip: "Number of months to split payment/ balance",
};

export const descriptionOption: Options = {
  code: "description",
  name: "Plan description",
  tooltip: "Specify the plan description to have more details about it",
};

export const rolloverPlanOption: DropdownOptions = {
  code: "nic-plan-rollover-dlq-level-plan",
  name: "Plan rollover delinquency level",
  options: blockCodeValuesAndLabels.get(ProductBcParams.delinquencyLevel),
  tooltip:
    "This parameter specifies the delinquency level at which plan will be rolled over. Once the account reached to defined delinquency level then IPP plan balance amount will be moved to retail plan",
};
