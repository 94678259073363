import { FC, useState } from "react";
import { ConfigProvider, Form } from "antd";

import { WorkflowFilters } from "@ni/common/types";
import { AfterWizardEditPageTitle } from "@ni/common/ui";

import UsersTableView from "../UsersTableView";

import styles from "./styles.module.scss";

const UsersLayout: FC = () => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<WorkflowFilters>({});

  const onFormChange = (currentValue: { [key: string]: string }, value: { [key: string]: string }) => {
    const filter: WorkflowFilters = Object.keys(value).reduce((acc, key) => {
      if (key === "searchValue" && value[key]) {
        return { ...acc, search: value[key] };
      }
      if (value[key]) {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: value[key],
          },
        };
      }

      return acc;
    }, {} as WorkflowFilters);
    setFilters(filter);
  };

  return (
    <ConfigProvider componentSize="middle">
      <Form form={form} className={styles["UsersLayout"]} onValuesChange={onFormChange}>
        <div className={styles["title"]}>
          <AfterWizardEditPageTitle title="Users" page="Dashboard" />
        </div>
        <UsersTableView filters={filters} />
      </Form>
    </ConfigProvider>
  );
};

export default UsersLayout;
