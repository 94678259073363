import { FC } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";

import { FileDoneOutlined, RocketOutlined, SettingFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const CompleteProductBoardingPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const onFinish = (): void => {
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {},
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(() => {
        setWizardResponse({});
        setIsLoading(false);
        navigate("/");
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Product Creation Completed Successfully!"
      pageSubtitle="Congratulations! The product parameters and values have been generated into the system."
      size="full"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <div className="text-blue-h3">You are about to get started</div>
      <div className="wizard-inner-block">
        <div className={styles["wizard-range-select"]}>
          <div className={styles["wizard-grey-mini"]}>
            <div className={styles["wizard-grey-mini-text"]}>
              <FileDoneOutlined style={{ color: "#0060A8" }} /> Your product is registered
            </div>
            You can now tune your product behavior with adjusting additional settings and applying features.
            <br />
            <br />
            Network International platform with variety of options allows all tenants and partners to make their card
            products configuration according to all business needs.
            <br />
            <br />
            <p>
              Visit{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/tenant/${wizardResponse.tenant?.id || ""}/product/${
                  wizardResponse.product?.id || ""
                }/product-details`}
              >
                Product settings &#10132;
              </a>{" "}
              and{" "}
              <a target="_blank" rel="noopener noreferrer" href={`/tenant/${wizardResponse.tenant?.id || ""}/details`}>
                Tenant settings &#10132;
              </a>{" "}
              pages to see available options.
            </p>
          </div>
          <div className={styles["wizard-grey-mini"]}>
            <div className={styles["wizard-grey-mini-text"]}>
              <SettingFilled style={{ color: "#0060A8" }} /> Uploading your configuration
            </div>
            You are able to upload your product configuration to sandbox environment and provide it to your development
            team or partner. <br />
            <br />
            Wide range of available APIs can help your team to provide best service and user experience.
            <br />
            <br />
            <p>
              Visit{" "}
              <a target="_blank" rel="noopener noreferrer" href="https://developer.network.ae/">
                Developer portal &#10132;
              </a>{" "}
              for full documentation on our APIs, or try out our API in a{" "}
              <a target="_blank" rel="noopener noreferrer" href="https://developer.network.ae/">
                Sandbox portal &#10132;
              </a>
            </p>
          </div>
          <div className={styles["wizard-grey-mini"]}>
            <div className={styles["wizard-grey-mini-text"]}>
              <RocketOutlined style={{ color: "#0060A8" }} /> Getting access
            </div>
            With registred account you receive access to all of Network International Portals.
            <br />
            <br />
            Now you can invite your team members and colleagues to work upon integration and additional products
            creation and tuning.
            <br />
            <br />
            <p>Please contact your Network International manager to share invites.</p>
          </div>
        </div>
      </div>

      <div className="text-blue-h3">Documentation &amp; Support</div>
      <div className="wizard-inner-block">
        <div className={styles["wizard-range-select"]}>
          <div className={styles["wizard-white-mini"]}>
            <div className={styles["wizard-grey-mini-text"]}>Get Started</div>
            <a target="_blank" rel="noopener noreferrer" href="/">
              Overview your products &#10132;
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/tenant/${wizardResponse.tenant?.id || ""}/product/${
                wizardResponse.product?.id || ""
              }/product-details`}
            >
              Upload your brand logo &#10132;
            </a>
            <a target="_blank" rel="noopener noreferrer" href={`/tenant/${wizardResponse.tenant?.id || ""}/details`}>
              Customise your product &#10132;
            </a>
          </div>
          <div className={styles["wizard-white-mini"]}>
            <div className={styles["wizard-grey-mini-text"]}>Resourses</div>
            <a target="_blank" rel="noopener noreferrer" href="/">
              Download product specification &#10132;
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://developer.network.ae/">
              Get development documentation &#10132;
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://developer.network.ae/">
              Shring implementation time with OpenAPI &#10132;
            </a>
          </div>
          <div className={styles["wizard-white-mini"]}>
            <div className={styles["wizard-grey-mini-text"]}>Use cases</div>
            <a target="_blank" rel="noopener noreferrer" href="https://developer.network.ae/">
              Checkout sandbox examples &#10132;
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://developer.network.ae/">
              Take a look at guidlines &#10132;
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://network.ae/">
              More on Network International &#10132;
            </a>
          </div>
        </div>
      </div>
    </CustomFormWrapper>
  );
};
