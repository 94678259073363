import { FC, ReactNode } from "react";
import { Form, TooltipProps } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";

import { CheckboxGroup, CheckboxGroupProps } from "../../CheckboxGroup";
import { TooltipInfo } from "../../TooltipInfo";

export type NetworkCheckBoxProps = { formItemOptions?: NetWorkFormItemProps } & CheckboxGroupProps;

export const CheckBox: FC<NetworkCheckBoxProps> = ({ formItemOptions, ...props }) => {
  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        tooltip={undefined}
        label={
          formItemOptions?.label ? (
            <TooltipInfo
              label={formItemOptions?.label as string}
              code={formItemOptions?.name as string}
              tooltipProps={
                (formItemOptions?.tooltip
                  ? typeof formItemOptions.tooltip === "string"
                    ? { title: formItemOptions?.tooltip }
                    : typeof formItemOptions.tooltip === "object" &&
                        (formItemOptions.tooltip as { title: ReactNode })?.title
                      ? { title: (formItemOptions.tooltip as { title: ReactNode }).title as ReactNode }
                      : formItemOptions.tooltip
                  : {}) as TooltipProps
              }
            />
          ) : undefined
        }
      >
        <CheckboxGroup {...props} />
      </Form.Item>
    );
  }
  return <CheckboxGroup {...props} />;
};
