import { FC, useEffect, useMemo } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm, DynamicSwitchRule } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import {
  checkboxFieldsPage10,
  checkHeader,
  getCardDailyLimitsPreparedValues,
  switchHeader10,
} from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionCardLimitsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      "nic-c-d-cash-atm-enable": true,
      "nic-c-d-cash-atm-max-am": formDisabledFn("nic-c-d-cash-atm-max-am", "500000"),
      "nic-c-d-cash-atm-max-nr": formDisabledFn("nic-c-d-cash-atm-max-nr", "20"),
      "nic-c-d-cash-atm-max-single": formDisabledFn("nic-c-d-cash-atm-max-single", "500000"),
      "nic-c-d-cash-enable": true,
      "nic-c-d-cash-max-am": formDisabledFn("nic-c-d-cash-max-am", "500000"),
      "nic-c-d-cash-max-nr": formDisabledFn("nic-c-d-cash-max-nr", "20"),
      "nic-c-d-cash-max-single": formDisabledFn("nic-c-d-cash-max-single", "500000"),
      "nic-c-d-cash-pos-enable": true,
      "nic-c-d-cash-pos-max-am": formDisabledFn("nic-c-d-cash-pos-max-am", "500000"),
      "nic-c-d-cash-pos-max-nr": formDisabledFn("nic-c-d-cash-pos-max-nr", "20"),
      "nic-c-d-cash-pos-max-single": formDisabledFn("nic-c-d-cash-pos-max-single", "500000"),
      "nic-c-d-ecomm-enable": true,
      "nic-c-d-ecomm-max-am": formDisabledFn("nic-c-d-ecomm-max-am", "500000"),
      "nic-c-d-ecomm-max-nr": formDisabledFn("nic-c-d-ecomm-max-nr", "20"),
      "nic-c-d-ecomm-max-single": formDisabledFn("nic-c-d-ecomm-max-single", "500000"),
      "nic-c-d-retail-enable": true,
      "nic-c-d-retail-max-am": formDisabledFn("nic-c-d-retail-max-am", "500000"),
      "nic-c-d-retail-max-nr": formDisabledFn("nic-c-d-retail-max-nr", "20"),
      "nic-c-d-retail-max-single": formDisabledFn("nic-c-d-retail-max-single", "500000"),
      "nic-c-d-total-enable": true,
      "nic-c-d-total-max-am": formDisabledFn("nic-c-d-total-max-am", "500000"),
      "nic-c-d-total-max-nr": formDisabledFn("nic-c-d-total-max-nr", 21),
      "nic-c-d-total-max-single": formDisabledFn("nic-c-d-total-max-single", "500000"),
    });

    Object.keys(checkboxFieldsPage10).forEach(key => {
      checkHeader(key, checkboxFieldsPage10, form);
    });

    checkHeader("card-daily-all-cash-enable", switchHeader10, form);
    checkHeader("card-daily-all-retail-enable", switchHeader10, form);
    checkHeader("card-daily-all-total-enable", switchHeader10, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const { pctValues, showCash, showRetail, showTotal } = useMemo(() => {
    const pctValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues;

    return {
      pctValues,
      showCash: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("C"),
      showRetail: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("R"),
      showTotal: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("T"),
    };
  }, [wizardResponse?.product?.parameterTables]);

  const rules = useMemo(() => {
    const rules: Record<"cash" | "retail" | "total", DynamicSwitchRule[]> = {
      cash: [
        {
          name: "nic-c-d-cash-enable",
          label: "All",
          tooltip: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-d-cash-max-nr",
          maxAmount: "nic-c-d-cash-max-am",
          maxSingleAmount: "nic-c-d-cash-max-single",
        },
      ],
      retail: [
        {
          name: "nic-c-d-retail-enable",
          label: "All",
          tooltip: "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-d-retail-max-nr",
          maxAmount: "nic-c-d-retail-max-am",
          maxSingleAmount: "nic-c-d-retail-max-single",
        },
      ],
      total: [
        {
          name: "nic-c-d-total-enable",
          label: "All",
          tooltip:
            "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-d-total-max-nr",
          maxAmount: "nic-c-d-total-max-am",
          maxSingleAmount: "nic-c-d-total-max-single",
        },
      ],
    };

    if (pctValues?.find(x => x.fieldCode === "trans-lim-atm-pos")?.value) {
      rules.cash.push(
        ...[
          {
            name: "nic-c-d-cash-atm-enable",
            label: "ATM",
            tooltip: "This limiter counts all ATM transactions. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-c-d-cash-atm-max-nr",
            maxAmount: "nic-c-d-cash-atm-max-am",
            maxSingleAmount: "nic-c-d-cash-atm-max-single",
          },
          {
            name: "nic-c-d-cash-pos-enable",
            label: "POS",
            tooltip:
              "This limiter counts all cash transactions done at POS terminals. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-c-d-cash-pos-max-nr",
            maxAmount: "nic-c-d-cash-pos-max-am",
            maxSingleAmount: "nic-c-d-cash-pos-max-single",
          },
        ],
      );
    }

    if (pctValues?.find(x => x.fieldCode === "trans-lim-ecom")?.value) {
      rules.retail.push({
        name: "nic-c-d-ecomm-enable",
        label: "Ecommerce",
        tooltip:
          "This limiter includes retail transactions over an electronic network, primarily the internet. Turn it off, if restrictions are not intended.",
        maxNumber: "nic-c-d-ecomm-max-nr",
        maxAmount: "nic-c-d-ecomm-max-am",
        maxSingleAmount: "nic-c-d-ecomm-max-single",
      });
    }

    return rules;
  }, [pctValues]);

  const onFinish = (value: FormValues) => {
    let actualValues: { [key: string]: string } = {};

    actualValues = {
      ...actualValues,
      ...getCardDailyLimitsPreparedValues(value as { [key: string]: string | boolean }),
    };

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: actualValues,
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Card Limits - Daily"
      pageSubtitle="Card daily limits control spending on the card level independently from account limits, which even limit
      is reached first."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      {showCash && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-daily-all-cash-enable"
          title="Cash"
          tooltip="Turns off all cash limits."
          rules={rules.cash}
          disabled={formDisabled}
        />
      )}

      {showRetail && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-daily-all-retail-enable"
          title="Retail"
          tooltip="Turns off all retail transactions."
          rules={rules.retail}
          disabled={formDisabled}
        />
      )}

      {showTotal && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-daily-all-total-enable"
          title="Total"
          tooltip="Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended."
          rules={rules.total}
          disabled={formDisabled}
        />
      )}
    </CustomFormWrapper>
  );
};
