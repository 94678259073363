import { FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import PhaseContainer from "../PhaseContainer";

interface DraggablePhasesSettingsProps {
  getPhases: () => void;
  workflowId: number;
}

const DraggablePhases: FC<DraggablePhasesSettingsProps> = props => {
  const { getPhases, workflowId } = props;

  return (
    <DndProvider backend={HTML5Backend}>
      <PhaseContainer getPhases={getPhases} workflowId={workflowId} key={workflowId} />
    </DndProvider>
  );
};

export default DraggablePhases;
