import { ReactNode } from "react";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumaricOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const dueDateOptions: NumaricOptions = {
  code: "nic-due-date-days",
  name: "Payment due date (number of days from the billing day)",
  decimalsNumber: 0,
  min: 0,
  tooltip:
    "The payment due date is the deadline by which the customer is expected to make a payment. This date is included in the customer's statement.",
};

export const smsDueReminderOptions: NumaricOptions = {
  code: "nic-sms-due-reminder-days",
  name: "SMS due reminder date (number of days from the billing day)",
  decimalsNumber: 0,
  min: 0,
  tooltip: "Please visit the SMS Templates - Global page to modify the appropriate SMS template",
};

export const agingDayOptions: NumaricOptions = {
  code: "nic-dlq-date-days",
  name: "Delinquency aging day (number of days from payment due date)",
  decimalsNumber: 0,
  min: 0,
  tooltip:
    "The delinquency ageing date refers to the point at which an account's delinquency level advances to the next stage",
};

export const mtpOptions: NumaricOptions = {
  code: "nic-mtp-val-rate",
  name: "MTP percentage",
  decimalsNumber: 2,
  min: 0.01,
  max: 100,
  tooltip: "This parameter specifies the percentage to calculate the minimum to pay amount for an account.",
};

export const mtpMinimumOptions: NumaricOptions = {
  code: "nic-mtp-val-base",
  name: "MTP minimum amount",
  decimalsNumber: 2,
  min: 0,
  tooltip:
    "This parameter specifies the minimum amount for MTP. If the calculated amount is lesser than the minimum MTP amount, then minimum MTP amount is requested as due in the statement.",
};
