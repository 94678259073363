import { FormInstance } from "antd";

import { FormValues } from "@ni/common/types";

interface FieldInstance {
  disabled: boolean;
  props: {
    disabled: boolean;
  };
}

export const filterDisabledValues = (values: FormValues, form: FormInstance<FormValues>) => {
  return Object.keys(values).reduce((acc: FormValues, key) => {
    const field = form.getFieldInstance(key) as FieldInstance;
    if (field) {
      const isFieldDisabled = field.props?.disabled ?? field.disabled;
      if (!isFieldDisabled) {
        acc[key] = values[key];
      } else {
        acc[key] = null;
      }
    }
    return acc;
  }, {});
};
