import { FC, useEffect } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

const INITIAL_NAME = "Loyalty program name";

export const TemplateInitiationPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const displayName = Form.useWatch("displayName", form) as string;

  const { processWizardRequest, setAppliedProducts } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  useEffect(() => {
    if (wizardResponse.loyaltyProgramTemplate) {
      form.setFieldValue("displayName", wizardResponse.loyaltyProgramTemplate.name);
    }
  }, [form, wizardResponse]);

  const onFinish = async (values: FormValues) => {
    setAppliedProducts([]);
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: undefined,
        collectedValues: values as LoyaltyWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={displayName?.length === 0 || displayName === INITIAL_NAME}
      pageTitle="Loyalty Program Template Initiation"
      pageSubtitle="Please follow the steps to configure a Loyalty Program Template. Once completed, it can be applied to specific products and overridden. Multiple loyalty programs can be set up, each with different conditions."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.String
        formItemOptions={{ name: "displayName", label: "Loyalty program name", initialValue: INITIAL_NAME }}
        placeholder="Program Name"
        maxLength={64}
      />
    </CustomFormWrapper>
  );
};
