import { memo, useState } from "react";
import { Collapse } from "antd";
import cn from "classnames";
import type { FC } from "react";
import { useDrag, useDrop } from "react-dnd";

import { DeleteOutlined, EditOutlined, HolderOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { ConfirmModal } from "@ni/common/ui";
import { ConfigurationApi } from "@ni/sdk/apis";
import { EventSubgroup } from "@ni/sdk/models";

import { ItemTypes } from "../../constants";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();

const { Panel } = Collapse;

interface CardProps {
  eventSubgroup: EventSubgroup;
  id: string;
  moveEventSubGroupCard: (id: string, to: number) => void;
  findEventSubGroupCard: (id: string) => { index: number };
  templateId: number;
  eventSubGroupItems: EventSubgroup[] | [];
  onEditHandleClick: (value: EventSubgroup) => void;
  index: number;
  onUpdateEventSubgroupOrder: (eventSubGroupItems: EventSubgroup[]) => void;
}

interface Item {
  id: string;
  originalIndex: number;
}

const CardFunc: FC<CardProps> = props => {
  const {
    eventSubgroup,
    id,
    moveEventSubGroupCard,
    findEventSubGroupCard,
    templateId,
    eventSubGroupItems,
    index,
    onEditHandleClick,
    onUpdateEventSubgroupOrder,
  } = props;

  const originalIndex = findEventSubGroupCard(id).index;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [, setIsLoading] = useReduxState<boolean>("isPhaseLoading");
  const [selectedEventSubGroupId, setSelectedEventSubGroupId] = useState<string>("");
  const [, setEventSubGroupItems] = useReduxState<EventSubgroup[]>("eventSubGroupItems", []);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.EVENT_SUB_GROUP,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveEventSubGroupCard(droppedId, index);
        }
        onUpdateEventSubgroupOrder(eventSubGroupItems);
      },
    }),
    [id, originalIndex, moveEventSubGroupCard],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.EVENT_SUB_GROUP,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findEventSubGroupCard(id);
          moveEventSubGroupCard(draggedId, overIndex);
        }
      },
    }),
    [findEventSubGroupCard, moveEventSubGroupCard],
  );

  const onCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onDeleteEventSubGroup = () => {
    setIsLoading(true);

    configurationServiceApi
      .deleteEventSubgroup(templateId, Number(selectedEventSubGroupId))
      .then(() => {
        onCancelDeleteModal();
        const newEventSubGroupList = eventSubGroupItems.filter(item => item.id !== Number(selectedEventSubGroupId));
        setEventSubGroupItems(newEventSubGroupList);
        setIsLoading(false);
      })
      .catch(() => {
        onCancelDeleteModal();
        setIsLoading(false);
      });
  };

  const extraHeader = () => (
    <div className={styles["page-collapse-header"]}>
      <HolderOutlined style={{ fontSize: "150%" }} />
      <div className={styles["order"]}>{index + 1 ?? "-"}</div>
      <div className={styles["title"]}>
        <div className={styles["name"]}>{eventSubgroup?.name ?? "Event SubGroup"}</div>
      </div>
    </div>
  );

  const extraPanel = () => (
    <div className={styles["page-collapse-header-extra"]}>
      <EditOutlined className={styles["button"]} onClick={() => onEditHandleClick(eventSubgroup)} />
      <DeleteOutlined
        className={styles["button"]}
        onClick={() => {
          setIsDeleteModalOpen(true);
          setSelectedEventSubGroupId(id);
        }}
      />
    </div>
  );

  return (
    <div
      className={cn(styles["page"], id !== "undefined" ? isDragging && styles["opacity"] : styles["notDND"])}
      ref={node => {
        if (id !== "undefined") {
          drag(drop(node));
        }
      }}
    >
      <Panel header={extraHeader()} key={id} showArrow={false} extra={extraPanel()} />
      <ConfirmModal
        title="Are you sure delete this Event subgroup? By clicking 'Confirm' you confirm deleting the event subgroup and all its subordinates (events, SMS templates)."
        isOpened={isDeleteModalOpen}
        onCancel={onCancelDeleteModal}
        onConfirm={onDeleteEventSubGroup}
        isLoading={false}
        type="delete"
      />
    </div>
  );
};

const EventSubGroupCard: FC<CardProps> = memo(CardFunc);
export default EventSubGroupCard;
