import { FC } from "react";
import { Button, Table, TableColumnType, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { SettingOutlined } from "@ant-design/icons";
import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { InsuranceProgram } from "@ni/sdk/models";

interface InsuranceTableProps {
  insuranceList?: InsuranceProgram[];
}

export const PCTInsuranceTable: FC<InsuranceTableProps> = ({ insuranceList }) => {
  const navigate = useNavigate();

  const [isLoading] = useReduxState<boolean>("isLoading", false);

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();

  const handleEditClick = (record: InsuranceProgram) => {
    navigate(
      `/tenant/${tenantId}/product/${productId}/pct/${pctId}/insurance-edit/${record.id}/insurance-premium-settings`,
    );
  };

  const columns: TableColumnType<InsuranceProgram>[] = [
    {
      title: "Program code",
      dataIndex: ["insuranceCode"],
      key: "insuranceCode",
      width: "13%",
      ellipsis: true,
      render: (_: string, item: InsuranceProgram, index) =>
        item.code?.toUpperCase() ?? `P${index + 1 < 10 ? `0${index + 1}` : index + 1}`,
    },
    {
      title: "Program name",
      dataIndex: ["name"],
      key: "name",
      width: "38%",
      ellipsis: true,
      render: (_: string, item: InsuranceProgram) => item.name || EMPTY_DATA_FLAG,
    },
    {
      title: "Insurance company",
      dataIndex: ["type"],
      key: "type",
      width: "38%",
      ellipsis: true,
      render: (_: string, item: InsuranceProgram) => item.insuranceCompanyName,
    },
    {
      title: "",
      dataIndex: ["loyaltyProgramTemplateId"],
      key: "loyaltyProgramTemplateId",
      width: "7%",
      ellipsis: true,
      render: (_: string, record: InsuranceProgram) => (
        <Tooltip title="Edit">
          <Button
            type="text"
            htmlType="button"
            icon={<SettingOutlined />}
            className="text-primary"
            onClick={() => handleEditClick(record)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <Table<InsuranceProgram>
      rowKey="id"
      columns={columns}
      dataSource={insuranceList}
      loading={isLoading}
      pagination={false}
    />
  );
};
