import { currencySetupList } from "@ni/common/constants";
import { SelectOption } from "@ni/common/types";

export const productTypeCBSList: SelectOption[] = [
  { value: "Prepaid", label: "Prepaid" },
  { value: "Debit", label: "Debit" },
  { value: "Credit", label: "Credit" },
];
export const getProductTypeCMSList = (isDisabled?: boolean): SelectOption[] => [
  { value: "Prepaid", label: "Prepaid" },
  { value: "Credit", label: "Credit", isExist: isDisabled, disabled: isDisabled },
];

export const getCurrencySetupList = (isDisabled?: boolean): SelectOption[] =>
  currencySetupList.map(x => (x.value === "mult" ? { ...x, isExist: isDisabled, disabled: isDisabled } : { ...x }));

export const interestTypeList: SelectOption[] = [
  { value: "Conventional", label: "Conventional Credit Card" },
  {
    value: "Islamic",
    label: "Islamic Credit Card",
    tooltip: "Islamic credit card is a Shariah law compliant financial product",
  },
];
