import React from "react";

import { FileCardDurationEnum, FileCardModel, FileCardSourceEnum, FileSection, FileTypeEnum } from "@ni/common/types";

export const interfacesOnboarding: FileCardModel[] = [
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.JSON,
    title: "Bulk Demographic Update",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "Account boarding file through which customers are on boarded on to the system and accounts are opened. Also the same format of file is used to update the client demographic details in bulk.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.XML,
    title: "Embossing File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.VendorClient,
    description:
      "File is used for embossing the cards. File generated for cards boarded, reissued, renewed, replaced. Once the cards are boarded, reissued, renewed, replaced successfully. Embossing jobs are run, post which this file is generated and shared with vendor through SFTP for generating physical plastics.",
    exportLink: "https://network.ae/",
  },
];

export const interfacesDumpFiles: FileCardModel[] = [
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Accounts Dump",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "This extract contains all the details for all accounts that don’t have zero balance.",
    exportLink: "https://network.ae/",
    dependsOnOptional: {
      showIf: "balance-owner",
      showIfValueEquals: "CMS",
    },
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Card Dump File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Daily report of all cards with details including the status, demographic, limits etc. Same can be loaded to banks internal system.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Address Dump File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Address dump of account, card and client levels for banks reference for any KYC purpose.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Client Demographic Dump File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Client level demographic details like name, detail, etc. for bank reference.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Contract Dump File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Contract dump file for all the contracts. CSV based file which contains contract details for bank reference.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Events Dump File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Dump for events triggered on particular day for contracts.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Transaction Dump File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "This dump contains the daily transaction details",
    exportLink: "https://network.ae/",
    dependsOnOptional: {
      showIf: "balance-owner",
      showIfValueEquals: "CMS",
    },
  },
];

export const interfacesTokenizationExtracts: FileCardModel[] = [
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Full ILF dump",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Full Token life cycle notifications extract.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Inactive token SMS notification extract",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Inactive visa token extract for SMS notifications.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Daily ILF extract",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Daily extract will have the token life cycle requests received from schemes.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Monthly authorization extract",
    duration: FileCardDurationEnum.Monthly,
    source: FileCardSourceEnum.Client,
    description: "Tokenized transaction details which were declined/approved for the current month.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_interfaces_",
    fileType: FileTypeEnum.CSV,
    title: "Monthly extract for tokenized transactions",
    duration: FileCardDurationEnum.Monthly,
    source: FileCardSourceEnum.Client,
    description: "It contains the tokenized transaction count details for the current month.",
    exportLink: "https://network.ae/",
  },
];

export const interfacesWizardPage: FileSection[] = [
  {
    tag: "wizard_interfaces_onboarding_",
    title: "ONBOARDING",
    files: interfacesOnboarding,
  },
  {
    tag: "wizard_interfaces_dump_files_",
    title: "DUMP FILES",
    files: interfacesDumpFiles,
  },
  {
    tag: "wizard_interfaces_tokenization_extracts_",
    title: "TOKENIZATION'S EXTRACTS",
    files: interfacesTokenizationExtracts,
    dependsOn: {
      fieldLocation: ["product", "productValues"],
      fieldCode: "nic-card-subtype-token-used",
      searchForFieldInArray: true,
      disabledIfValueEquals: "false",
      defaultDisabled: true,
      disabledContent: React.createElement(
        "div",
        {
          key: "div_wizard_tokenization_disabled",
          className: "alert-div",
        },
        [
          React.createElement("span", { key: "div_wizard_tokenization_span" }, [
            "The extracts is not applicable because ",
            React.createElement("strong", { key: "wizard_tokenization_strong" }, ["Tokenization"]),
            " option was not selected.",
          ]),
        ],
      ),
    },
  },
];
