import { memo, ReactNode } from "react";
import { Typography } from "antd";

import { FullDashboardProduct } from "@ni/sdk/models";

import { CardView } from "../CardView";

import styles from "./styles.module.scss";

interface ProductCardProps {
  renderLeft: ReactNode;
  product: FullDashboardProduct;
}

export const ProductCard = memo(({ renderLeft, product: { product } }: ProductCardProps) => {
  return (
    <div className={styles["CardContainer"]}>
      <div className={styles["InnerContainer"]}>
        {renderLeft && renderLeft}
        <div className={styles["TextContainer"]}>
          <Typography.Text className={styles["ProductName"]}>{product?.name}</Typography.Text>
          <Typography.Text className={styles["ProductType"]}>{product?.productType}</Typography.Text>
        </div>
      </div>

      <CardView cardImage={product?.cardImage} prodIps={product?.paymentScheme} cardSize="medium" />
    </div>
  );
});
