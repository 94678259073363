import { ParameterTableValue } from "@ni/sdk/models";

export const enableATotal = "paym-a-total-enabled";
export const enableDTotalOption = "d-paym-a-total-enabled";
export const dTotalNrOption = "nic-d-paym-a-total-nr";
export const dTotalAmOption = "nic-d-paym-a-total-am";
export const dTotalSingleOption = "nic-d-paym-a-total-single";
export const enableBTotalOption = "b-paym-a-total-enabled";
export const bTotalNrOption = "nic-b-paym-a-total-nr";
export const bTotalAmOption = "nic-b-paym-a-total-am";
export const bTotalSingleOption = "nic-b-paym-a-total-single";
export const enableD365TotalOption = "d365-paym-a-total-enabled";
export const d365TotalNrOption = "nic-d365-paym-a-total-nr";
export const d365TotalAmOption = "nic-d365-paym-a-total-am";
export const d365TotalSingleOption = "nic-d365-paym-a-total-single";
export const enableETotalOption = "e-paym-a-total-enabled";
export const eTotalNrOption = "nic-e-paym-a-total-nr";
export const eTotalAmOption = "nic-e-paym-a-total-am";
export const eTotalSingleOption = "nic-e-paym-a-total-single";

export const initialValues: ParameterTableValue[] = [
  {
    fieldCode: enableATotal,
    value: "true",
  },
  {
    fieldCode: dTotalNrOption,
    value: "10",
  },
  {
    fieldCode: dTotalAmOption,
    value: "1000000",
  },
  {
    fieldCode: dTotalSingleOption,
    value: "1000000",
  },
  {
    fieldCode: enableDTotalOption,
    value: "true",
  },
  {
    fieldCode: bTotalNrOption,
    value: "100",
  },
  {
    fieldCode: bTotalAmOption,
    value: "1000000",
  },
  {
    fieldCode: bTotalSingleOption,
    value: "1000000",
  },
  {
    fieldCode: enableBTotalOption,
    value: "true",
  },
  {
    fieldCode: d365TotalNrOption,
    value: "1000",
  },
  {
    fieldCode: d365TotalAmOption,
    value: "1000000",
  },
  {
    fieldCode: d365TotalSingleOption,
    value: "1000000",
  },
  {
    fieldCode: enableD365TotalOption,
    value: "true",
  },
  {
    fieldCode: eTotalNrOption,
    value: "10000",
  },
  {
    fieldCode: eTotalAmOption,
    value: "1000000",
  },
  {
    fieldCode: eTotalSingleOption,
    value: "1000000",
  },
  {
    fieldCode: enableETotalOption,
    value: "true",
  },
];
