import { useCallback, useMemo } from "react";
import { Form, notification, Space } from "antd";
import { useParams } from "react-router-dom";

import {
  ALPHA2_COUNTRIES_LIST,
  LTY_CHECK_RULES,
  LTY_RULE_CNTR,
  LTY_RULE_CNTR_LIST,
  LTY_RULE_MCC,
  LTY_RULE_MCC_LIST,
  LTY_RULE_MERCH,
  LTY_RULE_MERCH_LIST,
  LTY_RULE_TERM_LIST,
  LTY_RULES_CRITERIA,
  MCC_LIST,
  RADIO_GROUP,
  RADIO_GROUP_ELIGIBLE,
} from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, Tabs } from "@ni/common/ui";

import { useLoyaltyProgramTemplateApi } from "../../../../hooks/tenant/useLoyaltyProgramTemplateApi";

import { TAB_TITLES } from "./constants";

type KeyTab = "CNTR" | "TERM" | "MERCH" | "MCC";

const stringsKeys = [LTY_CHECK_RULES, LTY_RULE_MERCH, LTY_RULE_MCC, LTY_RULE_CNTR];
const listsKeys = [LTY_RULES_CRITERIA, LTY_RULE_TERM_LIST, LTY_RULE_MERCH_LIST, LTY_RULE_MCC_LIST, LTY_RULE_CNTR_LIST];

export const TransactionEligibility = () => {
  const [form] = Form.useForm<FormValues>();

  const { templateId } = useParams<{ templateId: string }>();

  const { loyaltyProgramTemplate, editLoyaltyProgramTemplate } = useLoyaltyProgramTemplateApi({
    loyaltyTemplateId: parseInt(templateId ?? "0", 10),
  });

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: [
        ...(loyaltyProgramTemplate?.programValues ?? []),
        ...(loyaltyProgramTemplate?.programPctValues ?? []),
      ],
      keys: {
        strings: stringsKeys,
        lists: listsKeys,
      },
    },
    [loyaltyProgramTemplate],
  );

  const isSpecificRetail = Form.useWatch<string>(LTY_CHECK_RULES, form) === "Y";
  const selectedCriteria = Form.useWatch<string[]>(LTY_RULES_CRITERIA, form);

  const satisfiesMinimumCriterias = useCallback(() => {
    if (isSpecificRetail && (!selectedCriteria || selectedCriteria.length === 0)) {
      notification.error({
        message: "Invalid",
        description: "Please select at least one criteria",
      });
      return false;
    }
    return true;
  }, [isSpecificRetail, selectedCriteria]);

  const tabCriteriaList = useMemo(() => {
    const tabsContent = {
      CNTR: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["CNTR"]}</p>
          <NetworkForm.Switch
            formItemOptions={{
              name: LTY_RULES_CRITERIA,
              label: "Enable country criteria",
            }}
            checked={selectedCriteria?.includes("CNTR")}
            onChange={isChecked => {
              form.setFieldValue(
                LTY_RULES_CRITERIA,
                isChecked ? [...(selectedCriteria ?? []), "CNTR"] : selectedCriteria.filter(x => x !== "CNTR"),
              );
            }}
          />

          {selectedCriteria?.includes("CNTR") && (
            <>
              <NetworkForm.Radio
                formItemOptions={{
                  name: LTY_RULE_CNTR,
                  label: "Select countries",
                  initialValue: RADIO_GROUP[0].value,
                }}
                radioList={RADIO_GROUP}
              />
              <NetworkForm.Select
                formItemOptions={{
                  name: LTY_RULE_CNTR_LIST,
                  label: "Country list",
                  rules: [
                    {
                      required: true,
                      validator: (_, value: string[]) => {
                        if (value && value.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please fill out country list."));
                      },
                    },
                  ],
                  className: "w-p-50",
                }}
                mode="multiple"
                optionFilterProp="children"
                optionList={ALPHA2_COUNTRIES_LIST.map(x => ({ label: x.name, value: x.code }))}
              />
            </>
          )}
        </Space>
      ),
      TERM: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["TERM"]}</p>
          <NetworkForm.Switch
            formItemOptions={{
              name: LTY_RULES_CRITERIA,
              label: "Enable terminal id criteria",
            }}
            checked={selectedCriteria?.includes("TERM")}
            onChange={isChecked => {
              form.setFieldValue(
                LTY_RULES_CRITERIA,
                isChecked ? [...(selectedCriteria ?? []), "TERM"] : selectedCriteria.filter(x => x !== "TERM"),
              );
            }}
          />

          {selectedCriteria?.includes("TERM") && (
            <NetworkForm.Select
              formItemOptions={{
                name: LTY_RULE_TERM_LIST,
                label: "Specify merchant terminal ID list",
                rules: [
                  {
                    required: true,
                    validator: (_, value: string[]) => {
                      if (value && value.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Please fill out Id list."));
                    },
                  },
                ],
                className: "w-p-50",
              }}
              mode="tags"
            />
          )}
        </Space>
      ),
      MERCH: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["MERCH"]}</p>
          <NetworkForm.Switch
            formItemOptions={{
              name: LTY_RULES_CRITERIA,
              label: "Enable merchant id criteria",
            }}
            checked={selectedCriteria?.includes("MERCH")}
            onChange={isChecked => {
              form.setFieldValue(
                LTY_RULES_CRITERIA,
                isChecked ? [...(selectedCriteria ?? []), "MERCH"] : selectedCriteria.filter(x => x !== "MERCH"),
              );
            }}
          />

          {selectedCriteria?.includes("MERCH") && (
            <>
              <NetworkForm.Radio
                formItemOptions={{
                  name: LTY_RULE_MERCH,
                  label: "Select merchants",
                  initialValue: RADIO_GROUP[0].value,
                }}
                radioList={RADIO_GROUP}
              />

              <NetworkForm.Select
                formItemOptions={{
                  name: LTY_RULE_MERCH_LIST,
                  label: "Specify merchant ID list",
                  rules: [
                    {
                      required: true,
                      validator: (_, value: string[]) => {
                        if (value && value.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please fill out Id list."));
                      },
                    },
                  ],
                  className: "w-p-50",
                }}
                mode="tags"
              />
            </>
          )}
        </Space>
      ),
      MCC: (
        <Space direction="vertical" size="large">
          <p>{TAB_TITLES["MCC"]}</p>
          <NetworkForm.Switch
            formItemOptions={{
              name: LTY_RULES_CRITERIA,
              label: "Enable merchant category criteria",
            }}
            checked={selectedCriteria?.includes("MCC")}
            onChange={isChecked => {
              form.setFieldValue(
                LTY_RULES_CRITERIA,
                isChecked ? [...(selectedCriteria ?? []), "MCC"] : selectedCriteria.filter(x => x !== "MCC"),
              );
            }}
          />

          {selectedCriteria?.includes("MCC") && (
            <>
              <NetworkForm.Radio
                formItemOptions={{
                  name: LTY_RULE_MCC,
                  label: "Select merchant category code (MCC)",
                  initialValue: RADIO_GROUP[0].value,
                }}
                radioList={RADIO_GROUP}
              />

              <NetworkForm.Select
                formItemOptions={{
                  name: LTY_RULE_MCC_LIST,
                  label: "Merchant category code list",
                  rules: [
                    {
                      required: true,
                      validator: (_, value: string[]) => {
                        if (value && value.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please fill out merchant category code (MCC) list."));
                      },
                    },
                  ],
                  className: "w-p-50",
                }}
                mode="multiple"
                optionFilterProp="children"
                optionList={MCC_LIST.map(x => ({ label: x.value?.toString(), value: x.key! }))}
              />
            </>
          )}
        </Space>
      ),
    } as const;

    return loyaltyProgramTemplate?.id && LTY_RULES_CRITERIA
      ? [
          {
            key: "CNTR" as KeyTab,
            label: <div title="Country">Country</div>,
            children: tabsContent["CNTR" as KeyTab],
          },
          {
            key: "MCC" as KeyTab,
            label: <div title="Merchant Category">Merchant Category</div>,
            children: tabsContent["MCC" as KeyTab],
          },
          {
            key: "MERCH" as KeyTab,
            label: <div title="Merchant ID">Merchant ID</div>,
            children: tabsContent["MERCH" as KeyTab],
          },
          {
            key: "TERM" as KeyTab,
            label: <div title="Terminal ID">Terminal ID</div>,
            children: tabsContent["TERM" as KeyTab],
          },
        ]
      : [];
  }, [form, loyaltyProgramTemplate?.id, selectedCriteria]);

  const onFinish = async (values: FormValues) => {
    if (!satisfiesMinimumCriterias()) return;

    const stringifiedValues = { ...values };

    [...stringsKeys, ...listsKeys].forEach(key => {
      if (!stringifiedValues[key] && loyaltyProgramTemplate?.programPctValues?.find(item => item.code === key)) {
        stringifiedValues[key] = "";
      } else if (Array.isArray(stringifiedValues[key])) {
        stringifiedValues[key] = (stringifiedValues[key] as string[]).join(",");
      }
    });

    await editLoyaltyProgramTemplate(stringifiedValues);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Transaction Eligibility"
      pageSubtitle="Specify transaction eligibility to participate in loyalty program:"
      size="md"
      formSize="md"
      level="tenant"
      submitLabel="Save"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Radio
        formItemOptions={{
          name: LTY_CHECK_RULES,
          label: "Transaction eligibility mode",
          initialValue: "N",
        }}
        radioList={RADIO_GROUP_ELIGIBLE}
      />

      {isSpecificRetail && (
        <Tabs
          form={form}
          onSave={onFinish}
          pagesList={tabCriteriaList}
          initialValues={{
            ...initialValues,
            [LTY_CHECK_RULES]: isSpecificRetail ? "Y" : "N",
          }}
          isCheckEnabled={!(isSpecificRetail && (!selectedCriteria || !selectedCriteria?.length))}
          discardAfterChangeTab={true}
          excludedFieldsListFromCheck={[LTY_CHECK_RULES]}
        />
      )}
    </CustomFormWrapper>
  );
};
