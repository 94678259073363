import { Provider } from "react-redux";

import { store } from "@ni/common/utils";

export default function withStore<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>) {
  return function WithStore(props: P): JSX.Element {
    return (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    );
  };
}
