import React, { FC, useCallback, useEffect, useState } from "react";
import { Badge, Button, Table, TableColumnType } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";

import { CopyOutlined, DeleteOutlined, EditOutlined, MessageOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { DashboardFilters } from "@ni/common/types";
import { ActionsCell, ConfirmModal, MenuItemModel, MultipleSelectWindow, Spin, TooltipInfo } from "@ni/common/ui";
import { mapToServerSorting } from "@ni/common/utils";
import { ElementTemplateApi } from "@ni/sdk/apis";
import {
  DashboardElementTemplate,
  ElementTemplate,
  Order,
  ParameterTable,
  SortedFilteredPageRequest,
  State,
} from "@ni/sdk/models";

import ElementTemplateDrawer from "../ElementTemplateDetails/ElementTemplateDrawer";

import styles from "./styles.module.scss";

const elementTemplateServiceApi = new ElementTemplateApi();

interface ElementTemplatesTableProps {
  filters: DashboardFilters;
}

const ElementTemplatesTable: FC<ElementTemplatesTableProps> = ({ filters }) => {
  const [isDuplicateModalOpened, setIsDuplicateModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isDeleteAllModalOpened, setIsDeleteAllModalOpened] = useState(false);
  const [isLoading] = useReduxState("isLoading");
  const [newElementTemplate, setNewElementTemplate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isShowMultipleAction, setIsShowMultipleAction] = useState(false);
  const [selectedElementTemplate, setSelectedElementTemplate] = useState<ParameterTable>({});

  const [elementTemplates, setElementTemplates] = useReduxState<DashboardElementTemplate[]>("elementTemplates", []);
  const [isElementTypeDataCapture, setIsElementTypeDataCapture] = useState(false);
  const [isETLoading, setIsETLoading] = useState(false);

  const [tableElementTemplateConfig, setTableElementTemplateConfig] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });
  const [dashboardElementTemplateConfig, setDashboardElementTemplateConfig] = useState<SortedFilteredPageRequest>();

  const getElementTemplates = useCallback((): void => {
    if (dashboardElementTemplateConfig && Object.keys(dashboardElementTemplateConfig)) {
      setIsETLoading(true);

      elementTemplateServiceApi
        .getElementTemplates(dashboardElementTemplateConfig)
        .then(response => {
          setTableElementTemplateConfig({
            ...tableElementTemplateConfig,
            current: (dashboardElementTemplateConfig.pageLimits?.number as number) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setElementTemplates(response.data.content as DashboardElementTemplate[]);
          setIsETLoading(false);
        })
        .catch(() => {
          setIsETLoading(false);
        });
    }
  }, [dashboardElementTemplateConfig, setElementTemplates, tableElementTemplateConfig]);

  useEffect(() => {
    getElementTemplates();
  }, [dashboardElementTemplateConfig]);

  useEffect(() => {
    setDashboardElementTemplateConfig({
      ...filters,
      sorting: dashboardElementTemplateConfig?.sorting as Order[],
      pageLimits: {
        size: tableElementTemplateConfig.pageSize,
        number: 0,
      },
    });
  }, [filters, tableElementTemplateConfig.pageSize]);

  const onCancelDuplicateModal = (): void => {
    setIsDuplicateModalOpened(false);
  };

  const onCancelDeleteModal = (): void => {
    setIsDeleteModalOpened(false);
  };

  const onCancelDeleteAllModal = (): void => {
    setIsDeleteAllModalOpened(false);
  };

  const onEditBtn = () => {
    setNewElementTemplate(false);
    setVisible(true);
    setIsElementTypeDataCapture(true);
  };

  const getActionItems = (): MenuItemModel[] => {
    return [
      {
        label: "Edit element",
        icon: <EditOutlined className={styles["table-action-icon"]} />,
        actionCallBack: (data: ElementTemplate) => {
          onEditBtn();
          setSelectedElementTemplate(data);
        },
      },
      {
        label: "Duplicate element",
        icon: <CopyOutlined className={styles["table-action-icon"]} />,
        actionCallBack: () => {
          setIsDuplicateModalOpened(true);
        },
      },
      {
        label: "Delete element",
        icon: <DeleteOutlined className={styles["table-action-icon"]} />,
        actionCallBack: () => {
          setIsDeleteModalOpened(true);
        },
      },
    ];
  };

  const columns: TableColumnType<DashboardElementTemplate>[] = [
    Table.SELECTION_COLUMN,
    {
      title: "Code",
      dataIndex: ["code"],
      key: "code",
      sorter: (a, b) => Number(a.code) - Number(b.code),
      render: (_: string, item: DashboardElementTemplate) => (
        <p className="table-view-element-template-name">{item.code}</p>
      ),
    },
    {
      title: "Values dictionary",
      dataIndex: ["valuesDictionary"],
      key: "dictionaries",
      render: (_: string, item: DashboardElementTemplate) => {
        return item?.elementValueCode ?? "-";
      },
    },
    {
      title: "Element group",
      dataIndex: ["elementGroup"],
      key: "elementGroup",
      sorter: (a, b) => (a.elementGroup?.length || 0) - (b.elementGroup?.length || 0),
      render: (_: string, item: DashboardElementTemplate) => {
        return item?.elementGroup as string;
      },
    },
    {
      title: "Element type",
      dataIndex: ["elementType"],
      key: "elementType",
      sorter: (a, b) => Number(a.elementType) - Number(b.elementType),
      render: (_: string, item: DashboardElementTemplate) => {
        return item?.elementType;
      },
    },
    {
      title: "Label",
      dataIndex: ["label"],
      key: "label",
      sorter: (a, b) => (a.label?.length || 0) - (b.label?.length || 0),
      width: 150,
      render: (_: string, item: DashboardElementTemplate) => {
        return item?.label;
      },
    },
    {
      title: "Feature name",
      dataIndex: ["featureName"],
      key: "feature",
      sorter: (a, b) => (a.featureName?.length ?? 0) - (b.featureName?.length ?? 0),
      render: (_: string, item: DashboardElementTemplate) => {
        return item.featureName || "-";
      },
    },
    {
      title: "Current state",
      dataIndex: ["currentState"],
      key: "currentState",
      sorter: (a, b) => (a.currentState?.length ?? 0) - (b.currentState?.length ?? 0),
      render: (_: string, item: DashboardElementTemplate) => {
        if (item.currentState === State.ENABLED) {
          return <Badge status="success" text="Enabled" />;
        }
        if (item.currentState === State.DISABLED) {
          return <Badge status="error" text="Disabled" />;
        }
        return <Badge status="default" text="-" />;
      },
    },
    {
      title: "",
      dataIndex: ["hint"],
      key: "hint",
      width: 50,
      render: (_: string, item: DashboardElementTemplate) => {
        return (
          item.hint && (
            <div className={styles["description"]}>
              <TooltipInfo tooltipProps={{ placement: "top", title: item.hint }}>
                <MessageOutlined className={styles["table-cell-icon"]} />
              </TooltipInfo>
            </div>
          )
        );
      },
    },
    {
      title: "",
      dataIndex: ["actions"],
      key: "actions",
      width: 100,
      render: (_: string, item: DashboardElementTemplate) => (
        <div>
          <ActionsCell items={getActionItems()} rowData={item} />
        </div>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (newSelectedRowKeys.length > 1) {
      setIsShowMultipleAction(true);
    } else {
      setIsShowMultipleAction(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<ElementTemplate> | SorterResult<ElementTemplate>[],
  ) => {
    let sortArray: Order[] = [];
    if (sorter) {
      if (Array.isArray(sorter)) {
        sortArray = sorter
          .filter(sort => sort.order)
          .map(sortObject => ({
            direction: mapToServerSorting(sortObject.order),
            property: sortObject.columnKey as string,
          }));
      } else if (sorter.order) {
        sortArray = [{ property: sorter.columnKey as string, direction: mapToServerSorting(sorter.order) }];
      }
    }

    setDashboardElementTemplateConfig({
      ...dashboardElementTemplateConfig,
      pageLimits: {
        size: pagination.pageSize,
        number: (pagination.current as number) - 1,
      },
      sorting: sortArray.length ? sortArray : undefined,
    });
  };

  const handleDeleteElementTemplate = () => {};

  const handleDeleteSelectedElementTemplates = () => {
    selectedRowKeys.forEach(() => handleDeleteElementTemplate());
  };

  if (isLoading) {
    return <Spin />;
  }

  const handleConfirmDuplicate = () => {};

  const closeDrawerHandler = () => {
    setVisible(false);
  };

  return (
    <div className={styles["element-template-table"]}>
      <Table<ElementTemplate>
        rowSelection={rowSelection}
        className={styles["element-template-table-view"]}
        columns={columns}
        dataSource={elementTemplates}
        loading={isETLoading}
        rowKey={(item: ElementTemplate) => `${String(item?.id)}` || Math.random()}
        onChange={handleTableChange}
        pagination={tableElementTemplateConfig}
        onRow={elementTemplate => {
          return {
            onDoubleClick: () => {
              onEditBtn();
              setSelectedElementTemplate(elementTemplate);
            },
          };
        }}
      />

      <ConfirmModal
        title="Copy of element template will be created"
        isOpened={isDuplicateModalOpened}
        onCancel={onCancelDuplicateModal}
        onConfirm={handleConfirmDuplicate}
        isLoading={false}
        type="duplicate"
        description="Please enter the code to create copy."
        propertyName="Code"
      />
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the element template. Are you sure?"
        isOpened={isDeleteModalOpened}
        onCancel={onCancelDeleteModal}
        onConfirm={handleDeleteElementTemplate}
        isLoading={false}
        type="delete"
      />
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting of selected element templates. Are you sure?"
        isOpened={isDeleteAllModalOpened}
        onCancel={onCancelDeleteAllModal}
        onConfirm={handleDeleteSelectedElementTemplates}
        isLoading={false}
        type="delete"
      />

      <MultipleSelectWindow
        isShow={isShowMultipleAction}
        setIsShow={setIsShowMultipleAction}
        count={selectedRowKeys.length}
      >
        <Button
          type="link"
          onClick={() => {
            setIsDeleteAllModalOpened(true);
          }}
        >
          <DeleteOutlined />
          <span>Delete all</span>
        </Button>
      </MultipleSelectWindow>

      <ElementTemplateDrawer
        opened={visible}
        closeDrawer={closeDrawerHandler}
        elementTemplate={selectedElementTemplate}
        newElementTemplate={newElementTemplate}
        isElementTypeDataCapture={isElementTypeDataCapture}
        getElementTemplates={getElementTemplates}
      />
    </div>
  );
};

export default ElementTemplatesTable;
