import { useParamsList, usePrepareWizardPages, useWizardController } from "@ni/common/hooks";
import { WizardProcess } from "@ni/common/ui";

import { WIZARD_PAGES } from "./constants";
import { useWizard } from "./hooks";

export const Wizard = (): JSX.Element => {
  const {
    params: { tenantId: tenantIdQuery, productId: productIdQuery, page: pageQuery },
  } = useParamsList(["tenantId", "productId", "page"]);

  const { phases, lastSubmittedStep, processWizardRequest } = useWizard();

  const { pages, doesPageExistQuery } = usePrepareWizardPages({
    pageQuery: pageQuery ?? "",
    wizardPages: WIZARD_PAGES,
    lastSubmittedStep,
    phases,
  });

  const [wizardPlacement, setWizardPlacement] = useWizardController({
    phases,
    wizardType: "product",
    lastSubmittedStep,
    tenantIdQuery,
    pageQuery,
    productIdQuery,
    doesPageExist: doesPageExistQuery,
    processWizardRequest,
  });

  return (
    <WizardProcess
      lastSubmittedStep={lastSubmittedStep}
      wizardPlacementState={[wizardPlacement, setWizardPlacement]}
      wizardPhases={phases ?? []}
      wizardPagesList={pages}
    />
  );
};
