import { FC, useMemo } from "react";
import { Form, Space, Switch } from "antd";

import { NIC_CCY } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import {
  tawarruqProfitAmount,
  tawarruqProfitBase,
  tawarruqProfitPercentage,
  tawarruqSalesFee,
  tawarruqSalesFeeAmount,
  tawarruqSalesFeePercentage,
  tawarruqSalesFeeType,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

const stringsKeys = [
  tawarruqProfitAmount.code,
  tawarruqProfitBase.code,
  tawarruqProfitPercentage.code,
  tawarruqSalesFee.code,
  tawarruqSalesFeeAmount.code,
  tawarruqSalesFeePercentage.code,
  tawarruqSalesFeeType.code,
];

export const IslamicCreditTawarruqPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const tawarruqBase = Form.useWatch(tawarruqProfitBase.code, form);
  const tawarruqSalesFeeEnabled = Form.useWatch(tawarruqSalesFee.code, form);
  const tawarruqSalesFeeBase = Form.useWatch(tawarruqSalesFeeType.code, form);

  const productCurrency = useMemo(
    () => getFormValueFromProductValues(wizardResponse.product?.productValues, NIC_CCY),
    [wizardResponse.product?.productValues],
  );

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: stringsKeys,
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Tawarruq Profit Settings"
      pageSubtitle="Tawarruq is a financial concept in Islamic Banking that involves a buy-and-sell arrangement to achieve a specific financial objective, while complying with Islamic principles that prohibit interest (usury) and promote ethical financial transactions. Tawarruq profit is a component of this concept and is calculated and charged in accordance with Islamic banking principles. The Tawarruq balance includes all billed amounts, fees, charges, and profits. Customers can make payments toward their accounts until the full payment date to avoid Tawarruq profit."
      size="md"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={tawarruqProfitBase.code}
        label={tawarruqProfitBase.label}
        initialValue={tawarruqProfitBase.default}
      >
        <RadioGroup radioList={tawarruqProfitBase.options} gap={8} disabled={formDisabled} />
      </Form.Item>

      {tawarruqBase !== "Amount" && (
        <Form.Item
          name={tawarruqProfitPercentage.code}
          label={tawarruqProfitPercentage.label}
          rules={[{ required: true, message: "Tawarruq profit percentage is required" }]}
        >
          <NetworkForm.Number {...tawarruqProfitPercentage.props} />
        </Form.Item>
      )}

      {tawarruqBase !== tawarruqProfitBase.default && (
        <Form.Item
          name={tawarruqProfitAmount.code}
          label={tawarruqProfitAmount.label}
          rules={[{ required: true, message: "Tawarruq profit amount is required" }]}
        >
          <NetworkForm.Number {...tawarruqProfitAmount.props} addonAfter={productCurrency} />
        </Form.Item>
      )}

      <Space direction="horizontal" size={8}>
        <Form.Item valuePropName="checked" name={tawarruqSalesFee.code}>
          <Switch disabled={formDisabled} />
        </Form.Item>
        <TooltipInfo label={tawarruqSalesFee.label} tooltipProps={{ title: tawarruqSalesFee.tooltip }} />
      </Space>

      {tawarruqSalesFeeEnabled && (
        <Space direction="vertical" size={40}>
          <Form.Item
            name={tawarruqSalesFeeType.code}
            label={tawarruqSalesFeeType.label}
            initialValue={tawarruqSalesFeeType.default}
          >
            <RadioGroup radioList={tawarruqSalesFeeType.options} gap={8} disabled={formDisabled} />
          </Form.Item>

          {tawarruqSalesFeeBase === "Percentage" && (
            <Form.Item
              name={tawarruqSalesFeePercentage.code}
              label={tawarruqSalesFeePercentage.label}
              rules={[{ required: true, message: "Tawarruq sales fee percentage is required" }]}
            >
              <NetworkForm.Number {...tawarruqSalesFeePercentage.props} />
            </Form.Item>
          )}

          {tawarruqSalesFeeBase === "Amount" && (
            <Form.Item
              name={tawarruqSalesFeeAmount.code}
              label={tawarruqSalesFeeAmount.label}
              rules={[{ required: true, message: "Tawarruq sales fee amount is required" }]}
            >
              <NetworkForm.Number {...tawarruqSalesFeeAmount.props} addonAfter={productCurrency} />
            </Form.Item>
          )}
        </Space>
      )}
    </CustomFormWrapper>
  );
};
