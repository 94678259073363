import cn from "classnames";

import styles from "./styles.module.scss";

interface RegistrationHeaderProps {
  description: string;
}

const RegistrationHeader = ({ description }: RegistrationHeaderProps) => {
  return (
    <div className={styles["registration-header"]}>
      <div className={cn(styles["title"], styles["red"])}>Get started </div>
      <div className={cn(styles["title"], styles["blue"])}>with Network International</div>
      <div className={styles["description"]}>{description}</div>
    </div>
  );
};

export default RegistrationHeader;
