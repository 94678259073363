import { ReactNode } from "react";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const canChargeWhenTemporaryExceedsPermanent: Options = {
  code: "crlim-temp-fee-mode",
  name: "Charge fee in case of temporary limit exceeds permanent limit",
  default: false,
  tooltip:
    "This parameter provides an option to charge fee if temporary credit limit updated higher than permanent credit limit.",
};

export const temporaryLimitFeeAmount: NumericOptions = {
  code: "nic-crlim-temp-fee",
  name: "Temporary limit fee amount",
  decimalsNumber: 2,
  min: 0,
};

export const canChargeWhenIncreasingPermanentLimit: Options = {
  code: "crlim-perm-fee-mode",
  name: "Charge fee in case of increasing permanent limit",
  default: false,
  tooltip:
    "This parameter provides an option to charge fee if new permanent credit limit is higher than current permanent credit limit.",
};

export const permanentLimitIncreaseFeeAmount: NumericOptions = {
  code: "nic-crlim-perm-fee",
  name: "Permanent limit increase fee amount",
  decimalsNumber: 2,
  min: 0,
};
