import { FC } from "react";
import { Space, Table, TableColumnType } from "antd";
import { Link } from "react-router-dom";

import { CardView } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { InsuranceProgram, Product } from "@ni/sdk/models";

type InstantiatedInsuranceData = InsuranceProgram & {
  product?: Product;
};

interface ProductListProps {
  data?: Array<InstantiatedInsuranceData>;
  tenantId: string;
}

export const ProductsOverviewTable: FC<ProductListProps> = ({ data, tenantId }) => {
  const columns: TableColumnType<InstantiatedInsuranceData>[] = [
    {
      title: "Product Name",
      dataIndex: ["displayName"],
      key: "productName",
      width: "25%",
      ellipsis: true,
      render: (_: string, item: InstantiatedInsuranceData) => (
        <Space size={18}>
          <CardView
            cardImage={item.product?.cardImage}
            prodIps={(getFormValueFromProductValues(item.product?.productValues, "nic-prod-ips") as string) || "-"}
            cardSize="small"
          />
          {item.product?.displayName}
        </Space>
      ),
    },
    {
      title: "Program Code",
      dataIndex: ["code"],
      key: "programCode",
      width: "15%",
      ellipsis: true,
      render: (_: string, item: InstantiatedInsuranceData) => `P${item.code}`,
    },
    {
      title: "Program Name",
      dataIndex: ["name"],
      key: "programName",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "",
      key: "actions",
      width: "15%",
      ellipsis: true,
      render: (_: string, item: InstantiatedInsuranceData) => (
        <Link to={`/tenant/${tenantId}/product/${item.product?.id}/insurance-programs`}>
          Visit Product Settings &#10132;
        </Link>
      ),
    },
  ];

  return (
    <Table<InstantiatedInsuranceData>
      rowKey="id"
      columns={columns}
      dataSource={data}
      loading={false}
      pagination={false}
    />
  );
};
