interface Record {
  [key: string]: string | boolean | number;
}

export const mergeObjects = (array: Record[]): Record => {
  const mergedObject: Record = {};

  array.forEach(obj => {
    Object.keys(obj).forEach(key => {
      mergedObject[key] = obj[key];
    });
  });

  return mergedObject;
};
