import React, { FC, useCallback } from "react";
import { Button, Checkbox, Radio, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { arrayMoveImmutable } from "array-move";
import cn from "classnames";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";

import { DeleteOutlined, HolderOutlined, PlusOutlined } from "@ant-design/icons";
import { MultiCurrency } from "@ni/common/types";

import { SearchableDropdown } from "../../SearchableDropdown";
import { TooltipInfo } from "../../TooltipInfo";

import "./style.css";
import styles from "./styles.module.scss";

export interface MultiCurrencyTableProps {
  formDisabled: boolean;
  isLoading: boolean;
  countriesDictionary: MultiCurrency[];
  dataSource: MultiCurrency[];
  currenciesMaxThreshold: number;
  setDataSource(dataSource: MultiCurrency[]): void;
}

interface DraggableBodyRowProps {
  className: string;
  style: React.CSSProperties;
  "data-row-key": React.Key;
  dataSource: { index: React.Key }[];
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);

const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);

const DragHandle = SortableHandle(() => (
  <HolderOutlined style={{ cursor: "move", color: "#8A8A8D", fontSize: "1.25rem" }} />
));

export const MultiCurrencyTable: FC<MultiCurrencyTableProps> = ({
  formDisabled,
  isLoading,
  countriesDictionary,
  dataSource,
  currenciesMaxThreshold,
  setDataSource,
}) => {
  const baseCurrencyRadioHandler = (index: number) => {
    if (dataSource[index].baseCurrency) {
      return;
    }

    const prevBaseCurrIndex = dataSource.findIndex(el => el.baseCurrency);
    const newDataSource = dataSource.slice().map((currency, index) => {
      if (prevBaseCurrIndex === index) {
        return {
          ...currency,
          baseCurrency: false,
          autoOpening: false,
        };
      }

      return {
        ...currency,
        baseCurrency: false,
      };
    });
    newDataSource[index].baseCurrency = !newDataSource[index].baseCurrency;
    newDataSource[index].autoOpening = true;
    setDataSource(newDataSource);
  };

  const autoOpeningCheckboxHandler = (index: number) => {
    const newDataSource = dataSource.slice();
    newDataSource[index].autoOpening = !newDataSource[index].autoOpening;
    setDataSource(newDataSource);
  };

  const deleteClickHandler = (index: number) => {
    const newDataSource = dataSource
      .filter((_, elementIndex) => elementIndex !== index)
      .map((item, index) => ({ ...item, priority: 501 - (index + 1) }));
    setDataSource(newDataSource);
  };

  const pickOfAddingNewCurrencyHandler = ({ key }: { key: string }) => {
    const currentSize = dataSource.length;
    if (key) {
      const newData = dataSource.slice();
      newData.push(
        countriesDictionary
          .filter(item => item.currencyCode === key)
          .map(() => {
            return {
              priority: 501 - (currentSize + 1),
              currencyCode: key,
              currencyName: countriesDictionary.filter(i => i.currencyCode === key)[0]?.currencyName || "",
              autoOpening: false,
              baseCurrency: false,
            };
          })[0],
      );
      setDataSource(newData);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter((el: MultiCurrency) => !!el);
      setDataSource(newData.map((item, index) => ({ ...item, priority: 501 - (index + 1) })));
    }
  };

  const getCurrenciesToPick = useCallback(() => {
    return countriesDictionary
      .filter(dic => !dataSource.find(item => item.currencyCode === dic.currencyCode))
      .map(item => {
        return {
          label: `${item.currencyCode} - ${item.currencyName}`,
          value: item.currencyCode,
          key: item.currencyCode,
        };
      });
  }, [countriesDictionary, dataSource]);

  const DragHandleRender = () => <DragHandle />;

  const PriorityNameRender = () => (
    <TooltipInfo
      label="Priority"
      tooltipProps={{
        title:
          "A wallet corresponding to a transaction currency will be debited first. In case of insufficient funds, the system will automatically debit the wallets in order of priority. To change the priority, simply drag and drop the rows.",
        overlayStyle: { maxWidth: "350px" },
      }}
    />
  );

  const PriorityRender = (_: string, { priority }: MultiCurrency) => {
    return 501 - priority;
  };

  const CurrencyNameRender = (currencyName: string) => {
    const Name = SortableHandle(() => (
      <Space style={{ cursor: "move" }} direction="horizontal">
        {currencyName}
      </Space>
    ));

    return <Name />;
  };

  const CurrencyCodeRender = (currencyCode: string) => {
    if (formDisabled) {
      return <div>{currencyCode}</div>;
    }
    const Name = SortableHandle(() => <div style={{ cursor: "move" }}>{currencyCode}</div>);
    return <Name />;
  };

  const CheckboxRender = (autoOpening: boolean, { baseCurrency }: MultiCurrency, index: number) =>
    (
      <Checkbox
        className={styles["auto-opening-checkbox"]}
        checked={autoOpening}
        disabled={baseCurrency || formDisabled}
        onClick={() => autoOpeningCheckboxHandler(index)}
      />
    ) as React.ReactNode;

  const BaseCurrencyNameRender = () => (
    <TooltipInfo
      label="Base currency"
      tooltipProps={{
        title:
          "A wallet in a base currency will be opened by default and all limits, fees and pricing values as well as main reports will be nominated in the base currency",
        overlayStyle: { maxWidth: "350px" },
      }}
    />
  );

  const BaseCurrencyRender = (baseCurrency: boolean, _record: MultiCurrency, index: number) => {
    return <Radio checked={baseCurrency} onClick={() => baseCurrencyRadioHandler(index)} />;
  };

  const WalletTitleRender = () => (
    <TooltipInfo
      label="Wallet auto-opening"
      tooltipProps={{
        title:
          "Wallets could be opened automatically or by client’s request. Please note that auto-opening may result in the possibility of unnecessary wallets being created.",
        overlayStyle: { maxWidth: "350px" },
      }}
    />
  );

  const DeleteButtonRender = (_: boolean, { baseCurrency }: MultiCurrency, index: number) => {
    return (
      <Tooltip title="Delete">
        <Button
          type="text"
          disabled={baseCurrency || formDisabled}
          onClick={() => deleteClickHandler(index)}
          className="text-primary"
          icon={<DeleteOutlined />}
        />
      </Tooltip>
    );
  };

  const columns: ColumnsType<MultiCurrency> = [
    !formDisabled
      ? {
          width: "3.5%",
          render: DragHandleRender,
          className: "drag-invisible",
        }
      : {
          width: "0.1%",
        },
    {
      title: <PriorityNameRender />,
      dataIndex: "priority",
      render: PriorityRender,
      width: "10%",
      className: "drag-visible",
    },
    {
      title: "Currency name",
      dataIndex: "currencyName",
      render: CurrencyNameRender,
      width: "20%",
      className: "drag-visible",
    },
    {
      title: "Currency code",
      dataIndex: "currencyCode",
      render: CurrencyCodeRender,
      width: "15%",
      className: "drag-invisible",
    },
    {
      title: <BaseCurrencyNameRender />,
      dataIndex: "baseCurrency",
      render: BaseCurrencyRender,
      width: "15%",
      className: "drag-invisible",
    },
    {
      title: <WalletTitleRender />,
      dataIndex: "autoOpening",
      render: CheckboxRender,
      width: 160,
      className: "drag-invisible",
    },
    {
      title: "",
      dataIndex: "",
      render: DeleteButtonRender,
      width: "6%",
      className: "drag-invisible",
    },
  ];

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle={true}
      disableAutoscroll={true}
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<DraggableBodyRowProps> = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(x => x.priority === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className={cn(styles["currencies-table"], "currencies-table", styles["full-width"])}>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="priority"
        className={cn(styles["ant-table-cell"])}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        loading={isLoading || dataSource.length === 0}
      />

      <div>
        {!formDisabled && getCurrenciesToPick().length > 0 && currenciesMaxThreshold >= dataSource.length && (
          <SearchableDropdown
            placement="top"
            trigger={["click"]}
            menu={{ onClick: pickOfAddingNewCurrencyHandler }}
            options={getCurrenciesToPick()}
          >
            <Button type="default" icon={<PlusOutlined />} className={styles["add-button"]}>
              Add currency
            </Button>
          </SearchableDropdown>
        )}
      </div>
    </div>
  );
};
