import { QpRowkey, QpRowkeyPct, QpRowkeyProduct } from "@ni/sdk/models";

import { getObjectByRowkey } from "./getObjectByRowkey";

export const transformRowkeysToOptions = <
  T extends (QpRowkey & { products: QpRowkeyProduct[] }) & { pcts: QpRowkeyPct[] },
>(
  qpRowkeys: QpRowkey[],
  rowkey: string,
  isProduct: boolean,
) => {
  if (qpRowkeys.length === 0) return [];

  const rowkeyObject = getObjectByRowkey<T>(qpRowkeys, rowkey, isProduct);

  if (!rowkeyObject || Object.keys(rowkeyObject).length === 0) return [];

  if (isProduct) {
    return rowkeyObject.products.map((product: QpRowkeyProduct) => ({
      value: product.rowkey!,
      label: `${product.product} - ${product.displayName}`,
    }));
  }

  return rowkeyObject.pcts.map((pct: QpRowkeyPct) => ({
    value: pct.rowkey,
    label: `PCT - ${pct.pct}`,
  }));
};
