import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type StatePayload<T = unknown> = {
  key: string;
  data: T;
};

export type StateCallbackPayload<T = unknown> = {
  key: string;
  callback: (prev: T) => T;
};

const initialState: Record<string, unknown> = {};

export const STATE_NAME = "state";

const slice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    setStateAction: (state, action: PayloadAction<StatePayload>) => {
      state[action.payload.key] = action.payload.data;
    },

    setStateWithCallbackAction: (state, action: PayloadAction<StateCallbackPayload>) => {
      const prevState = state[action.payload.key];
      let prev;
      if (Array.isArray(prevState)) {
        prev = [...(prevState as unknown[])];
      } else {
        prev = { ...(state[action.payload.key] || {}) };
      }
      state[action.payload.key] = action.payload.callback(prev);
    },

    setStackInitialStateAction: (state, action: PayloadAction<StatePayload>) => {
      state[action.payload.key] = action.payload.data;
    },
  },
});

export const { setStateAction, setStackInitialStateAction, setStateWithCallbackAction } = slice.actions;
export default slice.reducer;
