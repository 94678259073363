import { FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { EventGroup } from "@ni/sdk/models";

import EventGroupContainer from "../EventGroupContainer";

interface DraggableEventGroupsSettingsProps {
  smsTemplates: EventGroup[];
}

const DraggableEventGroups: FC<DraggableEventGroupsSettingsProps> = props => {
  const { smsTemplates } = props;

  return (
    <DndProvider backend={HTML5Backend}>
      <EventGroupContainer templates={smsTemplates} />
    </DndProvider>
  );
};

export default DraggableEventGroups;
