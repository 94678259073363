import { useEffect, useState } from "react";

import { useDebaunce } from "@ni/common/hooks";
import { Filter, FilterInput, FilterProps, SettingsPageLayout } from "@ni/common/ui";
import { SortedFilteredPageRequest } from "@ni/sdk/models";

import { DeploymentsTable } from "../../components";

import { useDeployments } from "./useDeployments";

export interface DeploymentFormValues {
  searchValue: string;
  deploymentState: string;
  dateRange: Date[];
}

const FILTER_INPUTS: FilterInput[] = [
  {
    type: "select",
    name: "deploymentState",
    placeholder: "Status",
    options: [
      { label: "All states", value: null },

      { label: "Failure", value: "FAILURE" },

      { label: "Success", value: "SUCCESS" },

      { label: "In progress", value: "IN_PROGRESS" },
    ],
  },
  {
    type: "dateRange",
    name: "dateRange",
    placeholder: "Select Start Time",
  },
  {
    type: "search",
    name: "searchValue",
    placeholder: "Search By FI/Tenant/Product",
  },
];

export const Deployments = () => {
  const { deploymentList, pagination, setPagination, setDeploymentsFilters, fetchDepeloymentById, reTryDeployment } =
    useDeployments();

  const [searchQuery, setSearchQeury] = useState<string | undefined>();

  const debauncedQuery = useDebaunce({ value: searchQuery as string, delay: 1000 });

  useEffect(() => {
    if (debauncedQuery !== undefined) {
      setDeploymentsFilters(filters => ({
        ...filters,
        search: debauncedQuery,
      }));
    }
  }, [debauncedQuery, setDeploymentsFilters]);

  const onValuesChange = (values: DeploymentFormValues) => {
    const { searchValue, deploymentState, dateRange } = values;
    if (typeof searchValue === "string") {
      setSearchQeury(searchValue);
      return;
    }

    const applyFilterChanges = (filters: SortedFilteredPageRequest) => {
      const hasDeploymentState = typeof deploymentState === "string" || deploymentState === null;
      const hasDateRange = Array.isArray(dateRange) && dateRange.length > 0;
      const shouldClearDateRange = dateRange === null;

      const newFilters = {
        ...filters.filter,
      };

      if (hasDeploymentState) {
        newFilters["deployment.state"] = deploymentState;
      }

      if (hasDateRange) {
        newFilters["deployment.startDate"] = dateRange[0]?.toISOString().replace("Z", "");
        newFilters["deployment.endDate"] = dateRange[1]?.toISOString().replace("Z", "");
      }

      if (shouldClearDateRange) {
        newFilters["deployment.startDate"] = "";
        newFilters["deployment.endDate"] = "";
      }

      return { filter: newFilters };
    };

    setDeploymentsFilters(applyFilterChanges);
  };

  return (
    <SettingsPageLayout pageTitle="Deployments" size="full">
      <Filter
        filterInputs={FILTER_INPUTS}
        compact={true}
        onValuesChange={onValuesChange as FilterProps["onValuesChange"]}
      />
      <DeploymentsTable
        deploymentList={deploymentList}
        pagination={pagination}
        setPagination={setPagination}
        fetchDepeloymentById={fetchDepeloymentById}
        reTryDeployment={reTryDeployment}
        setDeploymentsFilters={setDeploymentsFilters}
      />
    </SettingsPageLayout>
  );
};
