import { ReactNode } from "react";

import { SelectOption } from "@ni/common/types";

interface Options {
  label: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

export const creditInterestType: RadioOptions = {
  code: "credit-type",
  label: "Product Interest type",
  default: "Conventional",
  options: [
    {
      value: "Conventional",
      label: "Conventional Credit Card",
    },
    {
      value: "Islamic",
      label: "Islamic Credit Card",
      tooltip: "Islamic credit card is a Shariah law compliant financial product",
    },
  ],
};

export const creditProfitStructure: RadioOptions = {
  code: "credit-type-islamic",
  label: "Islamic credit card profit structure",
  default: "Murabaha",
  options: [
    {
      value: "Murabaha",
      label: "Murabaha",
      tooltip:
        "Murabaha is a cost plus financing structure in which the seller provides the cost and profit margin of an asset",
    },
    {
      value: "Tawarruq",
      label: "Tawarruq",
      tooltip:
        "Tawarruq is a financial concept in Islamic banking that involves a buy-and-sell arrangement to achieve a specific financial objective",
    },
  ],
};
