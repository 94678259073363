export const checkboxFieldsPage11: { [key: string]: string[] } = {
  "nic-c-m-cash-for-enable": ["nic-c-m-cash-for-max-am", "nic-c-m-cash-for-max-nr", "nic-c-m-cash-for-max-single"],
  "nic-c-m-cash-enable": ["nic-c-m-cash-max-am", "nic-c-m-cash-max-nr", "nic-c-m-cash-max-single"],
  "nic-c-m-retail-for-enable": [
    "nic-c-m-retail-for-max-am",
    "nic-c-m-retail-for-max-nr",
    "nic-c-m-retail-for-max-single",
  ],
  "nic-c-m-retail-enable": ["nic-c-m-retail-max-am", "nic-c-m-retail-max-nr", "nic-c-m-retail-max-single"],
  "nic-c-m-total-enable": ["nic-c-m-total-max-am", "nic-c-m-total-max-nr", "nic-c-m-total-max-single"],
};

export const switchHeader11: { [key: string]: string[] } = {
  "card-monthly-all-cash-enable": ["nic-c-m-cash-enable", "nic-c-m-cash-for-enable"],
  "card-monthly-all-retail-enable": ["nic-c-m-retail-for-enable", "nic-c-m-retail-enable"],
  "card-monthly-all-total-enable": ["nic-c-m-total-enable"],
};
