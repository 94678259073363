import { SelectOption } from "@ni/common/types";

export const clientContractLevelListOptions: SelectOption[] = [
  {
    value: "A",
    label: "Per account",
  },
  {
    value: "C",
    label: "Per card",
    tooltip: "It makes sense, if you want to have separate limits for primary and supplementary cards",
  },
  {
    value: "B",
    label: "Both",
  },
  {
    value: "N",
    label: "None",
    tooltip: "You can add limits after creating the product",
  },
];

export const transactionPeriodListPerAccountOptions: SelectOption[] = [
  {
    value: "D",
    label: "Day ",
  },
  {
    value: "B",
    label: "Billing ",
  },
];

export const transactionPeriodListPerCardOptions: SelectOption[] = [
  {
    value: "D",
    label: "Day ",
  },
  {
    value: "M",
    label: "Month",
  },
  {
    value: "B",
    label: "Billing ",
  },
];

export const transactionPeriodListBothOptions: SelectOption[] = [
  {
    value: "D",
    label: "Day ",
  },
  {
    value: "M",
    label: "Month (not available for account)",
  },
  {
    value: "B",
    label: "Billing ",
  },
];

export const transactionCategoryListOptions: SelectOption[] = [
  {
    value: "C",
    label: "Cash",
  },
  {
    value: "R",
    label: "Retail",
  },
  {
    value: "T",
    label: "Total",
  },
];
