import { FC, useMemo } from "react";
import { Form } from "antd";

import { PAYM_LIM_CONTR_LEVEL, PAYM_LIM_PERIODS } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CheckboxGroup, CustomFormWrapper, FormItemLabel } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import {
  CHECKBOX_GROUP_CONTRACT_LEVEL,
  CHECKBOX_GROUP_PAYM_LIM_PERIODS_A,
  CHECKBOX_GROUP_PAYM_LIM_PERIODS_A_C,
  CHECKBOX_GROUP_PAYM_LIM_PERIODS_C,
  PAYM_LIM_CONTR_LEVEL_LABEL,
  PAYM_LIM_PERIODS_LABEL,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const PaymentLimitsPresetPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const contrLevel = Form.useWatch<string[]>(PAYM_LIM_CONTR_LEVEL, form);

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [PAYM_LIM_CONTR_LEVEL],
      lists: [PAYM_LIM_PERIODS],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const contractLevelValue = (values[PAYM_LIM_CONTR_LEVEL] as string[]) ?? [];
    const formatContractLevel =
      contractLevelValue?.length === 0
        ? "N"
        : contractLevelValue?.length === 2
        ? "B"
        : contractLevelValue[0].toString();

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        [PAYM_LIM_CONTR_LEVEL]: formatContractLevel,
        ...(values[PAYM_LIM_PERIODS] && {
          [PAYM_LIM_PERIODS]: (values[PAYM_LIM_PERIODS] as string[]).join(","),
        }),
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const filteredCheckboxes = useMemo(() => {
    if (contrLevel) {
      if (contrLevel.includes("A") && !contrLevel.includes("C")) return CHECKBOX_GROUP_PAYM_LIM_PERIODS_A;
      if (contrLevel.includes("C") && !contrLevel.includes("A")) return CHECKBOX_GROUP_PAYM_LIM_PERIODS_C;
      if (contrLevel.includes("A") && contrLevel.includes("C")) return CHECKBOX_GROUP_PAYM_LIM_PERIODS_A_C;
    }
    return [];
  }, [contrLevel]);

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Payment Limits Pre-set"
      pageSubtitle="Payment limits provide the feasibility to apply restrictions on frequency and amounts of payments to account/card"
      size="md"
      formSize="md"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item name={PAYM_LIM_CONTR_LEVEL} label={PAYM_LIM_CONTR_LEVEL_LABEL}>
        <CheckboxGroup checkboxes={CHECKBOX_GROUP_CONTRACT_LEVEL} showSelectAll={false} />
      </Form.Item>

      {filteredCheckboxes.length > 0 && (
        <Form.Item
          name={PAYM_LIM_PERIODS}
          label={<FormItemLabel label={PAYM_LIM_PERIODS_LABEL} code={PAYM_LIM_PERIODS} />}
          rules={[
            {
              required: true,
              message: "Period is required",
            },
          ]}
        >
          <CheckboxGroup checkboxes={filteredCheckboxes} />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
