import { useCallback, useEffect } from "react";

import { FeatureCode, FeatureGuard } from "@ni/common/types";
import { FeatureApi } from "@ni/sdk/apis";

import { useReduxState } from "../store";

let hasFeatures = false;
const featureApi = new FeatureApi();

export const useToggleFeature = (
  featureCode?: FeatureCode,
): {
  isDisabled: boolean;
  isInitiated: boolean;
  checkFeatureDisabled: FeatureGuard;
  executeDisabledFeaturesGetter: () => Promise<void>;
} => {
  const [isInitiated, setIsInitiated] = useReduxState<boolean>("isDisabledFeaturesInitiated", false);
  const [features, setFeatures] = useReduxState<Set<string> | undefined>("disabledFeatures");

  const executeDisabledFeaturesGetter = useCallback(async () => {
    try {
      const {
        data: { content },
      } = await featureApi.getFeatures({ pageLimits: { size: 500 } });
      setFeatures(
        content?.filter(x => x.state === "DISABLED").reduce((curr, { code }) => curr.add(code), new Set<string>()),
      );
      setIsInitiated(true);
    } catch (error) {
      setIsInitiated(false);
      hasFeatures = false;
    }
  }, [setFeatures, setIsInitiated]);

  useEffect(() => {
    if (!features && !hasFeatures && !isInitiated) {
      hasFeatures = true;
      void executeDisabledFeaturesGetter();
    }
  }, [executeDisabledFeaturesGetter, features, isInitiated, setFeatures, setIsInitiated]);

  return {
    isDisabled: features && featureCode ? features?.has(featureCode) : true,
    isInitiated,
    checkFeatureDisabled: (code: FeatureCode) => features?.has(code) ?? true,
    executeDisabledFeaturesGetter,
  };
};
