import { useEffect, useMemo, useState } from "react";
import { Form, Space, Typography } from "antd";
import { useParams } from "react-router-dom";

import { CO_BADGED_NAME, MCC_LIST, NIC_CCY, PctValueParams } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { GetProductWithId, SelectOption } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";

import { usePct } from "../../../hooks";

import styles from "../styles.module.scss";

type FieldValue = { [index: string]: string | (string | undefined)[] | boolean };

export interface PctEditProductDetails {
  isMada: boolean;
  currency: string;
}

export const TransactionRestrictionsPage = () => {
  const { pctId } = useParams<{ pctId: string }>();
  const [currentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);

  const { pct, getValueFromPct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm();

  const [pctEditProductDetails, setPctEditProductDetails] = useState<PctEditProductDetails>({
    isMada: false,
    currency: "",
  });

  const initialValues = useMemo(() => {
    const fieldsValues: FieldValue = {};

    fieldsValues[PctValueParams.moto] = getValueFromPct(PctValueParams.moto) === "true";
    fieldsValues[PctValueParams.ecomm] = getValueFromPct(PctValueParams.ecomm) === "true";
    fieldsValues[PctValueParams.contactless] = getValueFromPct(PctValueParams.contactless) === "true";
    fieldsValues[PctValueParams.fallback] = getValueFromPct(PctValueParams.fallback) === "true";
    fieldsValues[PctValueParams.country] = getValueFromPct(PctValueParams.country) === "true";
    fieldsValues[PctValueParams.atm] = getValueFromPct(PctValueParams.atm) === "true";
    fieldsValues[PctValueParams.all] = getValueFromPct(PctValueParams.all) === "true";

    fieldsValues[PctValueParams.eCommerceList] =
      pct.pctProductValues
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        ?.find(item => item.fieldCode === PctValueParams.eCommerceList)
        ?.value?.split(",")
        .map(selected => MCC_LIST.find(mcc => mcc.key === selected)?.value)
        .filter(item => item) || [];

    return fieldsValues;
  }, [getValueFromPct, pct.pctProductValues]);

  useEffect(() => {
    setPctEditProductDetails({
      currency: getFormValueFromProductValues(currentProduct?.productValues ?? [], NIC_CCY) as string,
      isMada: (getFormValueFromProductValues(currentProduct?.productValues ?? [], CO_BADGED_NAME) as string) === "MADA",
    });
  }, [currentProduct]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Transaction Restrictions"
      pageSubtitle={
        <>
          Transaction Restrictions regulate transactions based on various criteria, including channels, geographic
          areas, merchants, and transaction attributes, and can be applied at different organizational levels.
          Transactions can be restricted by specific criteria, such as Mail Order/Telephone Order (MOTO) transactions,
          allowing precise control.
          <br />
          <br />
          Merchant Category Codes (MCCs), four-digit codes assigned to businesses by financial institutions, help
          classify the type of goods or services provided. The system can restrict authorizations based on MCCs, which
          must be configured accordingly. When a transaction is restricted, a clear response code &quot;Transaction not
          permitted to cardholder&quot; informs cardholders of the decline reason
        </>
      }
      submitHandler={onSavePct}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
    >
      <Space direction="vertical">
        <Typography.Text strong={true}>Please select the transaction types to be disabled</Typography.Text>

        <NetworkForm.Switch
          formItemOptions={{
            name: PctValueParams.moto,
            label: "MOTO (Mail Order/ Telephone Order) transactions",
            tooltip:
              "These are a type of card not present (CNP) payment. The customer provides merchant with his payment card details over the phone, via email or in a letter. Instead of swiping a card, the card data is entered manually. This can be done via a conventional card terminal as well as a virtual terminal.",
            valuePropName: "checked",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: PctValueParams.ecomm,
            label: "E-commerce transactions",
            tooltip:
              "E-commerce (electronic commerce) is the buying and selling of goods and services, or the transmitting of funds or data, over an electronic network, primarily the internet.",
            valuePropName: "checked",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: PctValueParams.contactless,
            label: "Contactless transactions",
            tooltip:
              "Contactless payments are transactions made by tapping either a contactless chip card or payment-enabled mobile or wearable device over a contactless-enabled payment terminal. Cards, phones, watches and other devices use the same contactless technology.",
            valuePropName: "checked",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: PctValueParams.fallback,
            label: "Chip fallback transactions",
            tooltip:
              "Chip fallback refers to a transaction where a customer uses an EMV (chip) card in a chip-capable terminal, but the transaction doesn’t go through as a chip transaction. Instead, it “falls back” on magnetic strip technology.",
            valuePropName: "checked",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: PctValueParams.country,
            label: "Foreign country transactions",
            tooltip: "Transactions that occur in a country different from the card issuance country.",
            valuePropName: "checked",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: PctValueParams.atm,
            label: "Cash ATM transactions",
            tooltip: "Cash withdrawal transactions at Automated Teller Machines.",
            valuePropName: "checked",
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: PctValueParams.all,
            label: "All debit transactions",
            tooltip: "Any kind of transactions that leads to debiting user account.",
            valuePropName: "checked",
          }}
        />
      </Space>

      <div className={styles["inner-item"]}>
        <NetworkForm.Select
          mode="multiple"
          optionList={MCC_LIST.map(x => ({ label: x.value, value: x.value }) as SelectOption)}
          allowClear={true}
          formItemOptions={{
            name: PctValueParams.eCommerceList,
            label: "Please enter the Merchant Category Code (MCC) to exclude",
            tooltip:
              "System provides the feasibility to restrict certain transactions which are done on specific MCC's (Merchant category code). One or more MCC's can be defined in the system to restrict / block the user authorizations. The restriction of MCCs is allowed can be overridden at the user account level to allow transactions in special cases.",
          }}
        />
      </div>

      {pctEditProductDetails.isMada && (
        <NetworkForm.Number
          formItemOptions={{
            name: PctValueParams.mada,
            label: "MADA (Regulatory) contactless cumulative amount limit (per card)",
            className: styles["pct-select-item"],
          }}
          addonAfter="SAR"
        />
      )}
    </CustomFormWrapper>
  );
};
