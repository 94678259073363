import { FC } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { GRACE_OPT, graceScenariosTableColumns, graceScenariosTabsList } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CollapsedTable, CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import {
  CASH_INTEREST_GRACE_OPTIONS,
  RETAIL_INTEREST_GRACE_OPTIONS,
  ZERO_FEES_OPTIONS,
  ZERO_INTERESTS_OPTIONS,
} from "./constants";

export const InterestGracePage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const formValues = useHydrateForm({
    form,
    entityFields: currentProduct.productValues ?? [],
    keys: {
      strings: [
        GRACE_OPT,
        CASH_INTEREST_GRACE_OPTIONS.code,
        RETAIL_INTEREST_GRACE_OPTIONS.code,
        ZERO_FEES_OPTIONS.code,
        ZERO_INTERESTS_OPTIONS.code,
      ],
    },
    allowParse: true,
  });

  const onFinish = onUpdateProduct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Interest Grace Options"
      pageSubtitle="In certain cases, interest charges may be waived. The customer's behavior over at least two billing cycles is used to determine whether interest will be waived or not. During the first cycle, interest may be deferred and during the second cycle, deferred interest may be waived. Please specify the grace options below."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      {Object.keys(formValues)?.length && (
        <>
          <NetworkForm.Radio
            formItemOptions={{
              name: RETAIL_INTEREST_GRACE_OPTIONS.code,
              label: RETAIL_INTEREST_GRACE_OPTIONS.name,
              initialValue: formValues[GRACE_OPT] ?? RETAIL_INTEREST_GRACE_OPTIONS.default,
            }}
            radioList={RETAIL_INTEREST_GRACE_OPTIONS.options}
            initialValue={(formValues[GRACE_OPT] as string) ?? RETAIL_INTEREST_GRACE_OPTIONS.default}
          />

          <NetworkForm.Radio
            formItemOptions={{
              name: CASH_INTEREST_GRACE_OPTIONS.code,
              label: CASH_INTEREST_GRACE_OPTIONS.name,
              initialValue: formValues[GRACE_OPT] ?? CASH_INTEREST_GRACE_OPTIONS.default,
            }}
            radioList={CASH_INTEREST_GRACE_OPTIONS.options}
            initialValue={(formValues[GRACE_OPT] as string) ?? CASH_INTEREST_GRACE_OPTIONS.default}
          />

          <CollapsedTable
            title="What if scenarios"
            tabsList={graceScenariosTabsList}
            columns={graceScenariosTableColumns}
          />

          <NetworkForm.Switch
            formItemOptions={{
              name: ZERO_FEES_OPTIONS.code,
              valuePropName: "checked",
              label: ZERO_FEES_OPTIONS.name,
              initialValue: ZERO_FEES_OPTIONS.default,
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{
              name: ZERO_INTERESTS_OPTIONS.code,
              valuePropName: "checked",
              label: ZERO_INTERESTS_OPTIONS.name,
              initialValue: ZERO_INTERESTS_OPTIONS.default,
            }}
          />
        </>
      )}
    </CustomFormWrapper>
  );
};
