import { SelectOption } from "@ni/common/types";

export const nicQuasiCashOptions: SelectOption[] = [
  { value: "4829", label: "4829 Money Transfer" },
  { value: "6051", label: "6051 Foreign Currency,Money,TC's" },
  { value: "7995", label: "7995 Casino" },
  { value: "9406", label: "9406 Government-Owned Lotteries (Non-U.S.region)" },
  { value: "6050", label: "6050 Quasi Cash, Fin Inst" },
  { value: "6539", label: "6539 Funding [Excluding MoneySend]" },
  { value: "9754", label: "9754 Gambling - Horse Racing, Dog Racing, Non-Sports Intrastate Internet Gambling" },
  { value: "7800", label: "7800 Government Owned Lottery" },
  { value: "7801", label: "7801 Internet Gambling" },
  { value: "7802", label: "7802 Government Licensed Horse/Dog Racing" },
];
