import { configureStore } from "@reduxjs/toolkit";

import reducer from "./slices";

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const getState =
  <T = unknown>(key: string) =>
  (rootState: RootState): T =>
    rootState.state[key] as T;
