import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="14" fill="#FFEDEF" />
    <path
      d="M28.2241 10.668C27.1858 8.28981 25.4762 6.26622 23.305 4.84523C21.1337 3.42424 18.595 2.66757 16.0001 2.66797C9.08673 2.66797 3.4014 7.93064 2.7334 14.668"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0005 10.6693H28.5338C28.6389 10.6693 28.7429 10.6486 28.8399 10.6084C28.937 10.5682 29.0252 10.5092 29.0995 10.435C29.1738 10.3607 29.2327 10.2725 29.2729 10.1754C29.3131 10.0784 29.3338 9.97433 29.3338 9.86927V5.33594M3.8418 21.3359C5.90046 26.0453 10.5991 29.3359 16.0658 29.3359C22.9805 29.3359 28.6658 24.0733 29.3338 17.3359"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.06673 21.3359H3.5334C3.32123 21.3359 3.11774 21.4202 2.96771 21.5703C2.81768 21.7203 2.7334 21.9238 2.7334 22.1359V26.6693"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3333 20C19.5101 20 19.6797 19.9298 19.8047 19.8047C19.9298 19.6797 20 19.5101 20 19.3333C20 19.1565 19.9298 18.987 19.8047 18.8619C19.6797 18.7369 19.5101 18.6667 19.3333 18.6667C19.1565 18.6667 18.987 18.7369 18.8619 18.8619C18.7369 18.987 18.6667 19.1565 18.6667 19.3333C18.6667 19.5101 18.7369 19.6797 18.8619 19.8047C18.987 19.9298 19.1565 20 19.3333 20ZM12.6667 13.3333C12.8435 13.3333 13.013 13.2631 13.1381 13.1381C13.2631 13.013 13.3333 12.8435 13.3333 12.6667C13.3333 12.4899 13.2631 12.3203 13.1381 12.1953C13.013 12.0702 12.8435 12 12.6667 12C12.4899 12 12.3203 12.0702 12.1953 12.1953C12.0702 12.3203 12 12.4899 12 12.6667C12 12.8435 12.0702 13.013 12.1953 13.1381C12.3203 13.2631 12.4899 13.3333 12.6667 13.3333Z"
      fill="#E64360"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20 12L12 20" stroke="#E64360" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const PercentRoundedRedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconSvg} {...props} />
);
