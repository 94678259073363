export const validateNumberInput = (
  value: number | string | undefined,
  allowFloat: boolean = true,
  errorMessage: string = "",
): Promise<void> => {
  if (allowFloat && typeof value === "number" && !Number.isNaN(value)) return Promise.resolve();
  if (!allowFloat && Number.isInteger(value)) return Promise.resolve();
  return Promise.reject(
    new Error(errorMessage ?? (allowFloat ? "Input should be a number" : "Input should be an integer")),
  );
};
