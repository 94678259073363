import { FC, ReactNode, useEffect, useMemo } from "react";
import { Form } from "antd";

import { useHydrateForm, useParamsList, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";
import { Tenant } from "@ni/sdk/models";

import { usePlanApi } from "../../../../hooks";

import { KeyTab, tab1, tab2, tab3, tab4, tabsObjectCodes } from "./constants";

export const IppProfit: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentPlan, editPlan, formValuesConverter } = usePlanApi();
  const {
    params: { tab },
  } = useParamsList(["tab"]);

  const [tenant] = useReduxState<Tenant>("currentTenant");

  const isTab1Enabled = Form.useWatch<boolean>(tab1.enableInterestOption.code, form);
  const isTab2Enabled = Form.useWatch<boolean>(tab2.enableProcessingFeeOption.code, form);
  const isTab3Enabled = Form.useWatch<boolean>(tab3.enableIncentiveFeeOption.code, form);
  const isTab4Enabled = Form.useWatch<boolean>(tab4.enableForeclosureFeeOption.code, form);

  const isInterestAmountOriginalBalance = Form.useWatch<string>(tab1.interestAmountOption.code, form) === "W";
  const feeFixed2 = Form.useWatch<string>(tab2.feeProcessingFixedOption.code, form)?.toString();
  const feePercentage2 = Form.useWatch<string>(tab2.feeProcessingPercentageOption.code, form)?.toString();
  const minFee2 = +(Form.useWatch<string>(tab2.minProcessingFeeOption.code, form) as unknown as number);
  const maxFee2 = +(Form.useWatch<string>(tab2.maxProcessingFeeOption.code, form) as unknown as number);
  const feeFixed3 = Form.useWatch<string>(tab3.feeFixedIncentiveOption.code, form)?.toString();
  const feePercentage3 = Form.useWatch<string>(tab3.feePercentageIncentiveOption.code, form)?.toString();
  const minFee3 = +(Form.useWatch<string>(tab3.minFeeIncentiveOption.code, form) as unknown as number);
  const maxFee3 = +(Form.useWatch<string>(tab3.maxFeeIncentiveOption.code, form) as unknown as number);
  const feeFixed4 = Form.useWatch<string>(tab4.feeFixedForeclosureOption.code, form)?.toString();
  const feePercentage4 = Form.useWatch<string>(tab4.feePercentageForeclosureOption.code, form)?.toString();
  const minFee4 = +(Form.useWatch<string>(tab4.minFeeForeclosureOption.code, form) as unknown as number);
  const maxFee4 = +(Form.useWatch<string>(tab4.maxFeeForeclosureOption.code, form) as unknown as number);

  const initialValues = useHydrateForm({
    form,
    entityFields: [
      {
        fieldCode: tab1.enableInterestOption.code,
        value: currentPlan?.planValues
          ?.find(x => x.fieldCode === "profit-type")
          ?.value?.includes("I")
          ?.toString(),
      },
      ...(currentPlan?.planValues ?? []),
    ],
    keys: {
      strings: [
        ...tabsObjectCodes.foreclosure,
        ...tabsObjectCodes.incentive,
        ...tabsObjectCodes.interest,
        ...tabsObjectCodes.processing,
      ],
    },
    allowParse: true,
  });

  const tabList = useMemo(() => {
    const tabsContent: { [tab in KeyTab]: ReactNode } = {
      interest: tab1.page({ isInterestEnabled: isTab1Enabled, isInterestAmountOriginalBalance }),
      processing: tab2.page({
        form,
        addonAfter: currentPlan?.currency?.toString(),
        feePercentage: feePercentage2,
        maxFee: maxFee2,
        minFee: minFee2,
      }),
      incentive: tab3.page({
        form,
        addonAfter: currentPlan?.currency?.toString(),
        feePercentage: feePercentage3,
        maxFee: maxFee3,
        minFee: minFee3,
      }),
      foreclosure: tab4.page({
        form,
        addonAfter: currentPlan?.currency?.toString(),
        feePercentage: feePercentage4,
        maxFee: maxFee4,
        minFee: minFee4,
      }),
    } as const;
    return [
      {
        key: "interest",
        label: <div title="Interest">&emsp;Interest&emsp;</div>,
        children: tabsContent["interest" as KeyTab],
      },
      {
        key: "processing",
        label: <div title="Processing fee">Processing fee</div>,
        children: tabsContent["processing" as KeyTab],
      },
      {
        key: "incentive",
        label: <div title="Incentive fee">Incentive fee</div>,
        children: tabsContent["incentive" as KeyTab],
      },
      {
        key: "foreclosure" as KeyTab,
        label: <div title="Foreclosure fee">Foreclosure fee</div>,
        children: tabsContent["foreclosure" as KeyTab],
      },
    ];
  }, [
    currentPlan?.currency,
    feePercentage2,
    feePercentage3,
    feePercentage4,
    form,
    isInterestAmountOriginalBalance,
    isTab1Enabled,
    maxFee2,
    maxFee3,
    maxFee4,
    minFee2,
    minFee3,
    minFee4,
  ]);

  const canSave = useMemo(
    () => ({
      processing: isTab2Enabled && !feeFixed2 && !feePercentage2 && tab === "processing",
      incentive: isTab3Enabled && !feeFixed3 && !feePercentage3 && tab === "incentive",
      foreclosure: isTab4Enabled && !feeFixed4 && !feePercentage4 && tab === "foreclosure",
      interest: false,
    }),
    [
      isTab2Enabled,
      feeFixed2,
      feePercentage2,
      tab,
      isTab3Enabled,
      feeFixed3,
      feePercentage3,
      isTab4Enabled,
      feeFixed4,
      feePercentage4,
    ],
  );

  useEffect(() => {
    if (tab && Object.keys(initialValues)?.length > 0) {
      if (
        isTab1Enabled &&
        (!form.getFieldValue(tab1.interestAmountOption.code) || !form.getFieldValue(tab1.interestAmountOption.code))
      ) {
        form.setFieldsValue({
          [tab1.interestAmountOption.code]: tab1.interestAmountOption.default,
          [tab1.dailyInterestOption.code]: tab1.dailyInterestOption.default,
        });
      }
      if (!feePercentage2 && form.getFieldValue(tab2.feeProcessingPercentageOption.code) == null) {
        form.resetFields([tab2.minProcessingFeeOption.code, tab2.maxProcessingFeeOption.code]);
      }
      if (!feePercentage3 && form.getFieldValue(tab3.feePercentageIncentiveOption.code) == null) {
        form.resetFields([tab3.minFeeIncentiveOption.code, tab3.maxFeeIncentiveOption.code]);
      }
      if (!feePercentage4 && form.getFieldValue(tab4.feePercentageForeclosureOption.code) == null) {
        form.resetFields([tab4.minFeeForeclosureOption.code, tab4.maxFeeForeclosureOption.code]);
      }
    }
  }, [feePercentage2, feePercentage3, feePercentage4, form, initialValues, isTab1Enabled, tab]);

  const onFinish = (values: FormValues, valuesTab?: string) => {
    const tabValues = tabsObjectCodes[(valuesTab ?? tab) as KeyTab].reduce((returned, code) => {
      return { ...returned, [code]: values[code] ?? "" };
    }, {});

    void editPlan({ planValues: formValuesConverter(tabValues) });
  };

  const relatedLinks = useMemo(() => {
    const pageMap = {
      interest: { title: "Interest Rate", href: "interest-rate" },
      processing: { title: "Processing Fee", href: "processing-fee" },
    };

    return tab === "interest" || tab === "processing"
      ? tenant?.products
          ?.filter(
            product =>
              product.lastProcessedPage?.code === "8_SUCCESS_PAGE" &&
              product.productValues?.some(values => values.fieldCode === "balance-owner" && values.value === "CMS") &&
              product.productValues?.some(values => values.fieldCode === "product-type" && values.value === "Credit"),
          )
          .flatMap(product =>
            (product.parameterTables ?? []).map(table => ({
              href: `/tenant/${tenant?.id}/product/${product?.id}/pct/${table.id}/installment-payment-plans/${currentPlan?.id}/${pageMap[tab].href}`,
              label: `${product.displayName} - ${table.displayName} PCT - ${currentPlan?.name} - ${pageMap[tab].title}`,
            })),
          ) ?? []
      : [];
  }, [currentPlan?.id, currentPlan?.name, tab, tenant?.id, tenant?.products]);

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Profit from IPP"
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitLabel="Save"
      linkTitle="Plan List"
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
      buttonDisabled={canSave[(tab as KeyTab) ?? "interest"] ?? false}
      relatedLinks={relatedLinks}
    >
      <Tabs
        pagesList={tabList}
        isCheckEnabled={true}
        form={form}
        initialValues={initialValues}
        onSave={onFinish}
        discardAfterChangeTab={false}
      />
    </CustomFormWrapper>
  );
};
