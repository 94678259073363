/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeUserRequest } from '../model';
// @ts-ignore
import { ChangeUserUiGuideProgressRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InviteUserRequest } from '../model';
// @ts-ignore
import { RestCreateUserUiGuideProgressRequest } from '../model';
// @ts-ignore
import { User } from '../model';
// @ts-ignore
import { UserFull } from '../model';
// @ts-ignore
import { UserUiGuideProgress } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Ui Guide Progress
         * @summary Create Ui Guide Progress
         * @param {RestCreateUserUiGuideProgressRequest} restCreateUserUiGuideProgressRequest 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUiGuideProgress: async (restCreateUserUiGuideProgressRequest: RestCreateUserUiGuideProgressRequest, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restCreateUserUiGuideProgressRequest' is not null or undefined
            assertParamExists('createUserUiGuideProgress', 'restCreateUserUiGuideProgressRequest', restCreateUserUiGuideProgressRequest)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createUserUiGuideProgress', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/ui-guide-progress`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restCreateUserUiGuideProgressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Evict user permission cache
         * @summary Evict permission cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictUserCache: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/cache/evict`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns User by ID
         * @summary Get User by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns User and Financial Institution information
         * @summary Get User info by Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change Ui Guide Progress
         * @summary Change Ui Guide Progress
         * @param {ChangeUserUiGuideProgressRequest} changeUserUiGuideProgressRequest 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUiGuideProgress: async (changeUserUiGuideProgressRequest: ChangeUserUiGuideProgressRequest, userId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeUserUiGuideProgressRequest' is not null or undefined
            assertParamExists('putUserUiGuideProgress', 'changeUserUiGuideProgressRequest', changeUserUiGuideProgressRequest)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putUserUiGuideProgress', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putUserUiGuideProgress', 'id', id)
            const localVarPath = `/api/v1/users/{userId}/ui-guide-progress/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserUiGuideProgressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset User Password
         * @summary Reset User Password
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetPassword', 'id', id)
            const localVarPath = `/api/v1/users/{id}/reset-password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns 200 if the invitation was sent successfully
         * @summary Send invitation
         * @param {InviteUserRequest} inviteUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitation: async (inviteUserRequest: InviteUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteUserRequest' is not null or undefined
            assertParamExists('sendInvitation', 'inviteUserRequest', inviteUserRequest)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the updated user
         * @summary Update user by ID
         * @param {ChangeUserRequest} changeUserRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById: async (changeUserRequest: ChangeUserRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeUserRequest' is not null or undefined
            assertParamExists('updateUserById', 'changeUserRequest', changeUserRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserById', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Ui Guide Progress
         * @summary Create Ui Guide Progress
         * @param {RestCreateUserUiGuideProgressRequest} restCreateUserUiGuideProgressRequest 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserUiGuideProgress(restCreateUserUiGuideProgressRequest: RestCreateUserUiGuideProgressRequest, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUiGuideProgress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserUiGuideProgress(restCreateUserUiGuideProgressRequest, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Evict user permission cache
         * @summary Evict permission cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evictUserCache(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evictUserCache(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns User by ID
         * @summary Get User by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns User and Financial Institution information
         * @summary Get User info by Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFull>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change Ui Guide Progress
         * @summary Change Ui Guide Progress
         * @param {ChangeUserUiGuideProgressRequest} changeUserUiGuideProgressRequest 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserUiGuideProgress(changeUserUiGuideProgressRequest: ChangeUserUiGuideProgressRequest, userId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUiGuideProgress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserUiGuideProgress(changeUserUiGuideProgressRequest, userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reset User Password
         * @summary Reset User Password
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns 200 if the invitation was sent successfully
         * @summary Send invitation
         * @param {InviteUserRequest} inviteUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvitation(inviteUserRequest: InviteUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvitation(inviteUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the updated user
         * @summary Update user by ID
         * @param {ChangeUserRequest} changeUserRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserById(changeUserRequest: ChangeUserRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserById(changeUserRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Create Ui Guide Progress
         * @summary Create Ui Guide Progress
         * @param {RestCreateUserUiGuideProgressRequest} restCreateUserUiGuideProgressRequest 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUiGuideProgress(restCreateUserUiGuideProgressRequest: RestCreateUserUiGuideProgressRequest, userId: number, options?: any): AxiosPromise<UserUiGuideProgress> {
            return localVarFp.createUserUiGuideProgress(restCreateUserUiGuideProgressRequest, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Evict user permission cache
         * @summary Evict permission cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evictUserCache(options?: any): AxiosPromise<object> {
            return localVarFp.evictUserCache(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns User by ID
         * @summary Get User by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns User and Financial Institution information
         * @summary Get User info by Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMe(options?: any): AxiosPromise<UserFull> {
            return localVarFp.getUserMe(options).then((request) => request(axios, basePath));
        },
        /**
         * Change Ui Guide Progress
         * @summary Change Ui Guide Progress
         * @param {ChangeUserUiGuideProgressRequest} changeUserUiGuideProgressRequest 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUiGuideProgress(changeUserUiGuideProgressRequest: ChangeUserUiGuideProgressRequest, userId: number, id: number, options?: any): AxiosPromise<UserUiGuideProgress> {
            return localVarFp.putUserUiGuideProgress(changeUserUiGuideProgressRequest, userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset User Password
         * @summary Reset User Password
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns 200 if the invitation was sent successfully
         * @summary Send invitation
         * @param {InviteUserRequest} inviteUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvitation(inviteUserRequest: InviteUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendInvitation(inviteUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the updated user
         * @summary Update user by ID
         * @param {ChangeUserRequest} changeUserRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById(changeUserRequest: ChangeUserRequest, id: number, options?: any): AxiosPromise<User> {
            return localVarFp.updateUserById(changeUserRequest, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * Create Ui Guide Progress
     * @summary Create Ui Guide Progress
     * @param {RestCreateUserUiGuideProgressRequest} restCreateUserUiGuideProgressRequest 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    createUserUiGuideProgress(restCreateUserUiGuideProgressRequest: RestCreateUserUiGuideProgressRequest, userId: number, options?: AxiosRequestConfig): AxiosPromise<UserUiGuideProgress>;

    /**
     * Evict user permission cache
     * @summary Evict permission cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    evictUserCache(options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * Returns User by ID
     * @summary Get User by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserById(id: number, options?: AxiosRequestConfig): AxiosPromise<User>;

    /**
     * Returns User and Financial Institution information
     * @summary Get User info by Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserMe(options?: AxiosRequestConfig): AxiosPromise<UserFull>;

    /**
     * Change Ui Guide Progress
     * @summary Change Ui Guide Progress
     * @param {ChangeUserUiGuideProgressRequest} changeUserUiGuideProgressRequest 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    putUserUiGuideProgress(changeUserUiGuideProgressRequest: ChangeUserUiGuideProgressRequest, userId: number, id: number, options?: AxiosRequestConfig): AxiosPromise<UserUiGuideProgress>;

    /**
     * Reset User Password
     * @summary Reset User Password
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    resetPassword(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns 200 if the invitation was sent successfully
     * @summary Send invitation
     * @param {InviteUserRequest} inviteUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    sendInvitation(inviteUserRequest: InviteUserRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns the updated user
     * @summary Update user by ID
     * @param {ChangeUserRequest} changeUserRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    updateUserById(changeUserRequest: ChangeUserRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<User>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * Create Ui Guide Progress
     * @summary Create Ui Guide Progress
     * @param {RestCreateUserUiGuideProgressRequest} restCreateUserUiGuideProgressRequest 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserUiGuideProgress(restCreateUserUiGuideProgressRequest: RestCreateUserUiGuideProgressRequest, userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserUiGuideProgress(restCreateUserUiGuideProgressRequest, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Evict user permission cache
     * @summary Evict permission cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public evictUserCache(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).evictUserCache(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns User by ID
     * @summary Get User by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns User and Financial Institution information
     * @summary Get User info by Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserMe(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change Ui Guide Progress
     * @summary Change Ui Guide Progress
     * @param {ChangeUserUiGuideProgressRequest} changeUserUiGuideProgressRequest 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserUiGuideProgress(changeUserUiGuideProgressRequest: ChangeUserUiGuideProgressRequest, userId: number, id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).putUserUiGuideProgress(changeUserUiGuideProgressRequest, userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset User Password
     * @summary Reset User Password
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPassword(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).resetPassword(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns 200 if the invitation was sent successfully
     * @summary Send invitation
     * @param {InviteUserRequest} inviteUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendInvitation(inviteUserRequest: InviteUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).sendInvitation(inviteUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the updated user
     * @summary Update user by ID
     * @param {ChangeUserRequest} changeUserRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserById(changeUserRequest: ChangeUserRequest, id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserById(changeUserRequest, id, options).then((request) => request(this.axios, this.basePath));
    }
}
