import { Space, Typography } from "antd";

export const ModalWarning = ({ externalCode, tenantsSet }: { externalCode: string; tenantsSet: Set<string> }) => {
  if (tenantsSet.has(externalCode))
    return (
      <Space direction="vertical" size="small">
        <Typography.Paragraph type="danger">Tenant already imported!</Typography.Paragraph>
        <Typography.Text>
          Re-importing will override unsent changes to UAT. are you sure you want to continue?
        </Typography.Text>
      </Space>
    );
  return null;
};
