import React, { FC } from "react";
import { Link } from "react-router-dom";

import { CardView } from "../../CardView";

import styles from "./style.module.scss";

interface CardDisplayCellProperties {
  cardImage: string | undefined;
  productName: string;
  prodIps: string | undefined;
  tenantId: number;
  productId: number;
  finished: boolean;
}

export const CardDisplayCell: FC<CardDisplayCellProperties> = ({
  cardImage,
  productName,
  productId,
  tenantId,
  prodIps,
  finished,
}) => {
  return (
    <div className={styles["card-display-cell"]}>
      {finished ? (
        <Link to={`/tenant/${tenantId}/product/${productId || ""}/product-details`}>
          <CardView cardImage={cardImage} prodIps={prodIps} cardSize="small" />
        </Link>
      ) : (
        <CardView cardImage={cardImage} prodIps={prodIps} cardSize="small" />
      )}
      <p className={styles["product-name"]}>{productName}</p>
    </div>
  );
};
