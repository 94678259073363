import { ReactNode } from "react";

import { FieldValue } from "@ni/common/constants";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface DropdownOptions extends Options {
  options?: FieldValue[];
}

export const templateNameOption: Options = {
  code: "displayName",
  name: "Insurance template name",
  default: "Insurance program name",
};

export const companyNameOption: Options = {
  code: "insuranceCompanyName",
  name: "Insurance company name",
};

export const currencyOption: DropdownOptions = {
  code: "currency",
  name: "Insurance template currency",
  options: [
    { displayed: "AED", value: "AED" },
    { displayed: "SAR", value: "SAR" },
    { displayed: "USD", value: "USD" },
    { displayed: "ZAR", value: "ZAR" },
    { displayed: "RWF", value: "RWF" },
  ],
};
