export const additionalLanguages: { label: string; value: string }[] = [
  {
    label: "Arabic",
    value: "Arabic",
  },
  {
    label: "French",
    value: "French",
  },
  {
    label: "German",
    value: "German",
  },
  {
    label: "Portuguese",
    value: "Portuguese",
  },
  {
    label: "Russian",
    value: "Russian",
  },
];
