import { FC, useState } from "react";
import { Button, Drawer, Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { User } from "@ni/sdk/models";

import UserEdit from "../UserEdit";

import styles from "./styles.module.scss";

interface UserDrawerProps {
  opened: boolean;
  user: User | undefined;
  closeDrawer: () => void;
}

const UserDrawer: FC<UserDrawerProps> = ({ opened, closeDrawer, user }) => {
  const [isLoading] = useReduxState<boolean>("isLoading");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const [form] = Form.useForm();

  const onValueChange = () => {
    const userPhone = form.getFieldValue("phone") as string;
    const isFieldsFilled =
      form.getFieldValue("firstName")?.length > 0 &&
      form.getFieldValue("lastName")?.length > 0 &&
      form.getFieldValue("email")?.length > 0 &&
      userPhone.substring(1).length > 0 &&
      userPhone.substring(1).length <= 19;
    setIsEnabled(isFieldsFilled);
  };

  const onFinish = () => {
    alert("Work in progress");
  };

  return (
    <Drawer
      className={styles["user-drawer"]}
      title="Edit user profile"
      placement="right"
      open={opened}
      width="99%"
      onClose={closeDrawer}
      keyboard={false}
      maskClosable={false}
      extra={
        <div className={styles["buttons"]}>
          <Button
            loading={isLoading}
            className="page-save-button"
            htmlType="submit"
            type="primary"
            size="large"
            onClick={form.submit}
            disabled={!isEnabled}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        className="page-editing-container"
        layout="vertical"
        onValuesChange={onValueChange}
      >
        <UserEdit form={form} user={user} />
      </Form>
    </Drawer>
  );
};

export default UserDrawer;
