/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosRequestConfig } from "axios";
import Keycloak, { KeycloakFlow, KeycloakOnLoad, KeycloakPkceMethod } from "keycloak-js";

const keycloakInstance = new Keycloak({
  url: process.env.REACT_APP_AUTH_URL ? process.env.REACT_APP_AUTH_URL : "/",
  realm: process.env.REACT_APP_AUTH_REALM ? process.env.REACT_APP_AUTH_REALM : "Network",
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ? process.env.REACT_APP_AUTH_CLIENT_ID : "network-tenant-portal",
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  keycloakInstance
    .init({
      onLoad: (process.env.REACT_APP_AUTH_ON_LOAD
        ? process.env.REACT_APP_AUTH_ON_LOAD
        : "login-required") as KeycloakOnLoad,
      pkceMethod: (process.env.REACT_APP_AUTH_PKCE_METHOD
        ? process.env.REACT_APP_AUTH_PKCE_METHOD
        : "S256") as KeycloakPkceMethod,
      flow: (process.env.REACT_APP_AUTH_FLOW ? process.env.REACT_APP_AUTH_FLOW : "standard") as KeycloakFlow,
    })
    .then(() => {
      onAuthenticatedCallback();
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
};

const doLogin = keycloakInstance.login;

const doLogout = keycloakInstance.logout;

const getToken = () => keycloakInstance.token ?? "";

const isLoggedIn = () => !!keycloakInstance.token;

const updateToken = (successCallback?: () => Promise<AxiosRequestConfig>) =>
  keycloakInstance
    .updateToken(
      process.env.REACT_APP_AUTH_REFRESH_TOKEN_TIMEOUT_IN_MINUTES
        ? Number.parseInt(process.env.REACT_APP_AUTH_REFRESH_TOKEN_TIMEOUT_IN_MINUTES, 10) || 1
        : 1,
    )
    .then(successCallback)
    .catch(doLogin);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const getUsername = () => keycloakInstance.tokenParsed?.preferred_username;

const hasRole = (roles: string[]) => roles.some((role: string) => keycloakInstance.hasRealmRole(role));

export const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};
