import { ReactNode } from "react";

import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

interface CheckBoxOptions extends Options {
  options: Pick<SelectOption, "tooltip" | "label" | "value">[];
}

export const getContributingBalances = (mode: string, formula: string): string =>
  ({
    FEEINT_FULL: `DD Amount = ${mode === "S" ? "(" : ""}Principle amount${mode === "S" ? " + Overlimit)" : ""} *`,
    FEEINT_INS_PERC: `DD Amount = (Principle amount + Interest + Insurance + Fees${
      mode === "S" ? " + Overlimit" : ""
    }) *`,
    FEEINT_PERC: `DD Amount = (Principle amount + Interest + Fees${mode === "S" ? " + Overlimit" : ""}) *`,
  })[formula as "FEEINT_FULL" | "FEEINT_INS_PERC" | "FEEINT_PERC"];

export const getFullyContributingBalances = (mode: string, formula: string): string =>
  ({
    FEEINT_FULL: `+ Interest + IPP + Insurance + Fees + VAT${mode === "O" ? " + Overlimit" : ""}`,
    FEEINT_INS_PERC: `+ IPP + VAT${mode === "O" ? " + Overlimit" : ""}`,
    FEEINT_PERC: `+ IPP + VAT + Insurance${mode === "O" ? " + Overlimit" : ""}`,
  })[formula as "FEEINT_FULL" | "FEEINT_INS_PERC" | "FEEINT_PERC"];

export const directDebitDayBaseOption: RadioOptions = {
  code: "nic-dd-date-mode",
  name: "Direct debit day base",
  default: "DUE",
  options: [
    {
      label: "Due date",
      value: "DUE",
    },
    {
      label: "Billing day",
      value: "BILLING",
    },
  ],
};

export const directDebitDayOption: Omit<Options, "name" | "tooltip"> & {
  name: Record<string, string>;
  tooltip: Record<string, string>;
} = {
  code: "nic-dd-date-day",
  name: {
    DUE: "Direct debit day (number of days before due date)",
    BILLING: "Direct debit day (number of days after billing day)",
  },
  default: 0,
  tooltip: {
    DUE: "Direct debit request will happen on the morning of due date if the parameter is 0. If, for example the parameter is 1, then direct debit will happen on the morning of the day before due date.",
    BILLING:
      "Direct debit request will happen on the morning of first day of the cycle if the parameter is 0. If, for example, it is 2, then 2 days after the billing day morning direct debit request will happen.",
  },
};

export const skipDirectDebitOption: Options = {
  code: "nic-dd-block-activ-cycle",
  name: "Skip direct debit request on activation cycle",
  default: false,
};

export const SmsPeriodOption: Options = {
  code: "nic-sms-dd-request-days",
  name: "Period for sending SMS reminder about direct debit request (number of days after billing day)",
};

export const calculationModeOption: RadioOptions = {
  code: "dd-amount-calc",
  name: "Direct debit amount calculation mode",
  default: "DUE",
  options: [
    {
      label: "Total due amount is requested",
      value: "DUE",
    },
    {
      label: "Amount is calculated based on formula and overlimit mode (defined separately)",
      value: "CALC",
    },
  ],
};

export const amountFormulaOption: RadioOptions = {
  code: "nic-dd-am-formula",
  name: "Direct debit amount formula",
  default: "FEEINT_PERC",
  tooltip:
    "The direct debit percentage for this formula is defined on contract level based on customer request, if the percentage is not provided the system will opt for total due amount",
  options: [
    {
      label: "Direct debit percentage is applied to loan amount only (all other balance components are 100% included)",
      value: "FEEINT_FULL",
    },
    {
      label: "Direct debit percentage is applied to statement balance except IPP and VAT (which are included as 100%)",
      value: "FEEINT_INS_PERC",
    },
    {
      label:
        "Direct debit percentage is applied to statement balance except IPP, VAT and Insurance (which are included as 100%)",
      value: "FEEINT_PERC",
    },
  ],
};

export const overlimitParticipationOption: RadioOptions = {
  code: "nic-dd-am-mode",
  name: "Overlimit component participation",
  default: "S",
  options: [
    {
      label: "Overlimit is included as a direct debit percentage",
      value: "S",
    },
    {
      label: "Overlimit amount is included as 100%",
      value: "O",
    },
  ],
};

export const formulaOption: Options = {
  code: "dd-res-formula",
  name: "Direct debit resulting formula",
};

export const requestOccurrencesOption: RadioOptions = {
  code: "dd-recovery-mode",
  name: "Direct debit request occurrences",
  default: "D",
  options: [
    {
      label: "Only on direct debit date specified",
      value: "D",
    },
    {
      label: "Repeat daily (the mode is defined separately)",
      value: "R",
    },
  ],
};

export const recoveryOption: CheckBoxOptions = {
  code: "dd-daily-recovery-opt",
  name: "Direct debit recovery mode",
  options: [
    {
      label: "Request DD amount on direct debit date and then daily until next billing",
      value: "B",
    },
    {
      label: "Request Past Due amount, if such exists, daily from billing day before the direct debit date",
      value: "Y",
    },
  ],
};

export const directMemberIdOption: Options = {
  code: "nic-dd-mbr",
  name: "Default direct debit member ID",
  tooltip:
    "No needs to specify this parameter for the DD processed within the bank or via UAEDDS. Otherwise, this parameter can specify the default member ID for direct debit request instruction.",
};

export const ActivateOption: Options = {
  code: "nic-dd-flag-def",
  name: "Activate direct debit feature by default for all accounts of the product",
  default: false,
  tooltip:
    "The activation of the Direct Debit feature can be executed as a default procedure for all accounts upon their creation or initiated manually by a user. Conversely, deactivating this feature is always a user-initiated operation.",
};
