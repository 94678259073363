/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, useState } from "react";
import { Menu, Typography } from "antd";

import styles from "./styles.module.scss";

const { Link } = Typography;

interface ListProps {
  namesList?: string[];
}

export const InsuranceProgramsList: FC<ListProps> = ({ namesList }: ListProps) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const { prefNamesList, moreNamesList } = useMemo(() => {
    const prefNamesList: string[][] = [[], []];
    const moreNamesList: string[] = [];
    namesList?.forEach(name => {
      if (prefNamesList[0].length < 2) {
        prefNamesList[0].push(name);
      } else if (prefNamesList[1].length < 2) prefNamesList[1].push(name);
      else moreNamesList.push(name);
    });
    return { prefNamesList, moreNamesList };
  }, [namesList]);

  const handleMouseLeave = () => {
    setTimeout(() => setToggleMenu(false), 500);
  };

  return (
    <>
      {prefNamesList.map((nameList, index) =>
        nameList[0] ? (
          <div className={styles["text-names-container"]} key={`${nameList[0]}, ${nameList[1]}`}>
            <span>{nameList[0]},</span>
            <span>{nameList[1] ?? ""}</span>
          </div>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${nameList[0]}, ${nameList[1]} + ${index}`} />
        ),
      )}
      {!!moreNamesList.length && (
        <>
          <Link onClick={() => setToggleMenu(!toggleMenu)}>View all</Link>
          <div className={styles["link-container"]}>
            {toggleMenu && (
              <Menu
                items={moreNamesList.map(name => ({ type: "group", label: name }))}
                className={styles["names-container"]}
                onMouseLeave={handleMouseLeave}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
