import { SelectOption } from "@ni/common/types";

export const amfAccMoment: SelectOption[] = [
  {
    key: "S",
    value: "Statement (billing) day",
    tooltip: "Charge AMF on billing day depending on the event of Card activation or Card opening",
  },
  { key: "A", value: "Card activation end of day" },
  { key: "O", value: "Account open end of day" },
];

export const amfAccMomentEvent = [
  {
    value: "Card activation",
    key: "A",
    tooltip: "Charge AMF on billing day depending on the event of Card activation or Card opening",
  },
  {
    value: "Account opening",
    key: "O",
    tooltip: "",
  },
] as const;
