import { Options } from "@ni/common/types";

export const mapOptionsValue = (fieldOptions: Options, value?: string[]) => {
  if (!value) value = [];
  const mappedValue = { [fieldOptions.code]: value.length === fieldOptions.options.length };
  fieldOptions.options.forEach(option => {
    mappedValue[option.value] = value!.includes(option.value);
  });
  return mappedValue;
};
