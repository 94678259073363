import { useState } from "react";
import { Button, Form, Input, Modal } from "antd";

import { InviteUserRequest } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface InviteModalProps {
  isOpened: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: (values: InviteUserRequest) => void;
  title: string;
  description?: string;
}

export const InviteModal = ({
  isOpened,
  isLoading,
  onCancel,
  onConfirm,
  title,
  description = "",
}: InviteModalProps) => {
  const [form] = Form.useForm();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const onInviteChange = () => {
    if (!isValidEmail(form.getFieldValue("email") as string)) {
      setIsEnabled(false);
    } else {
      const isFieldsFilled = form.getFieldValue("name")?.length > 0 && form.getFieldValue("email")?.length > 0;
      setIsEnabled(isFieldsFilled);
    }
  };

  const onInviteCancel = () => {
    form.setFieldValue("name", "");
    form.setFieldValue("email", "");
    onCancel();
  };

  const onInviteConfirm = () => {
    onConfirm({
      name: form.getFieldValue("name") as string,
      email: form.getFieldValue("email") as string,
    } as InviteUserRequest);
    onInviteCancel();
  };

  return (
    <Modal
      className={styles["modal-invite"]}
      title={title}
      open={isOpened}
      width="800px"
      onCancel={onInviteCancel}
      footer={[
        <div>
          <div className={styles["modal-invite-footer"]}>
            <Button className={styles["modal-invite-button"]} key="back" onClick={onInviteCancel}>
              Cancel
            </Button>
            <Button
              className={styles["modal-invite-button"]}
              disabled={!isEnabled}
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={form.submit}
            >
              Confirm
            </Button>
          </div>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onInviteConfirm} onChange={onInviteChange}>
        <Form.Item>{description}</Form.Item>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
