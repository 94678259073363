import { FC, useEffect, useState } from "react";
import { Button, Drawer, Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { onConditionChangeValidation } from "@ni/common/utils";
import { ConfigurationApi } from "@ni/sdk/apis";
import {
  CreateEventRequest,
  CreateEventSubgroupRequest,
  EventGroup,
  EventSubgroup,
  Variable,
  VariableGroup,
} from "@ni/sdk/models";

import EventSubGroupEdit from "../EventSubGroupEdit";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();
interface EventSubGroupDrawerProps {
  opened: boolean;
  newEventSubgroup: boolean;
  eventSubgroup: EventSubgroup;
  closeDrawer: () => void;
  templateId: number;
  getEventSubGroups: () => void;
  eventSubGroupItems: EventSubgroup[];
  smsTemplates: EventGroup[];
  templateVariableGroups?: VariableGroup[];
}

interface EventSubGroupDrawerForm {
  eventGroup: number;
  name: string;
  applyConditions: string;
  messageContent: string;
  events: CreateEventRequest[];
}

const EventSubGroupDrawer: FC<EventSubGroupDrawerProps> = ({
  opened,
  closeDrawer,
  newEventSubgroup,
  eventSubgroup,
  templateId,
  getEventSubGroups,
  eventSubGroupItems,
  smsTemplates,
  templateVariableGroups,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewEnabled, setIsNewEnabled] = useState<boolean>(false);
  const [, setIsSubGroupEnabled] = useReduxState<boolean>("isSubGroupEnabled", false);
  const [smsVariableList, setSmsVariableList] = useState<Variable[]>([]);
  const [variableGroupsOptions] = useReduxState<VariableGroup[]>("variableGroupsOptions", []);
  const [, setIsSubGroupTemplateEnabled] = useReduxState<boolean>("isSubGroupTemplateEnabled", false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (newEventSubgroup) {
      form.setFieldsValue({
        name: "",
        applyConditions: "",
        eventGroup: templateId,
        messageContent: "",
      });
    }
  }, [newEventSubgroup]);

  const onFinish = (values: EventSubGroupDrawerForm) => {
    setIsLoading(true);

    const requestBody: CreateEventSubgroupRequest = {
      applyConditions: values.applyConditions,
      events: values.events,
      name: values.name,
      order: (eventSubGroupItems?.length || 0) + 1,
      template: { code: "GENERAL", fieldCode: "nic-sms-t-fi-str1", fieldValue: values.messageContent },
    };

    configurationServiceApi
      .createEventSubgroup(requestBody, values.eventGroup)
      .then(response => {
        const defaultEventRequest: CreateEventRequest = {
          code: `${response.data.id}_GEN`,
          isGeneral: true,
          eventName: "General template for the group",
        };
        configurationServiceApi.createEvent(defaultEventRequest, values.eventGroup, response.data.id).catch(ex => {
          throw ex;
        });
        setIsLoading(false);
        getEventSubGroups();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const merge = (arrays: (Variable[] | undefined)[]) => {
    const newArray: Variable[] = [];
    for (let i = 0; i < arrays.length; i += 1) {
      newArray.push(...arrays[i]);
    }

    return newArray;
  };

  const onValuesChange = (): void => {
    const isSomeGroupFieldsChange =
      eventSubgroup.name !== form.getFieldValue("name") ||
      eventSubgroup.applyConditions !== form.getFieldValue("applyConditions") ||
      eventSubgroup.eventGroupId !== form.getFieldValue("eventGroup");

    const isFieldsFilled = form.getFieldValue("name")?.length > 0 && form.getFieldValue("messageContent")?.length > 0;

    setIsNewEnabled(form.getFieldValue("name")?.length > 0);
    setIsSubGroupTemplateEnabled(eventSubgroup.template?.fieldValue !== form.getFieldValue("messageContent"));
    setIsSubGroupEnabled(
      isFieldsFilled &&
        isSomeGroupFieldsChange &&
        ((form.getFieldValue("applyConditions")?.length > 0 &&
          onConditionChangeValidation(String(form.getFieldValue("applyConditions")))) ||
          form.getFieldValue("applyConditions") === undefined ||
          form.getFieldValue("applyConditions")?.length === 0),
    );

    const variablesFromSelectedVariableGroupsArray = variableGroupsOptions
      .filter(item =>
        templateVariableGroups
          ?.map(element => {
            return element.id;
          })
          ?.includes(item.id),
      )
      ?.map(item => {
        return item.variables;
      });

    const variablesFromSelectedVariableGroups = merge(variablesFromSelectedVariableGroupsArray);
    setSmsVariableList(variablesFromSelectedVariableGroups);
  };

  const closeEventSubGroupDrawer = () => {
    if (newEventSubgroup) {
      form.setFieldsValue({
        name: "",
        applyConditions: "",
        messageContent: "",
      });
    }
    setIsNewEnabled(false);
    closeDrawer();
  };

  return (
    <Drawer
      title={!newEventSubgroup ? `Edit "${eventSubgroup?.name || ""}" Subgroup` : `Add Event SubGroup`}
      placement="right"
      open={opened}
      width="99%"
      onClose={closeEventSubGroupDrawer}
      keyboard={false}
      maskClosable={false}
      extra={
        newEventSubgroup && (
          <div className={styles["buttons"]}>
            <Button
              loading={isLoading}
              disabled={!isNewEnabled}
              className={styles["event-sub-group-save-button"]}
              type="primary"
              size="large"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </div>
        )
      }
    >
      <Form
        form={form}
        className={styles["event-sub-group-editing-container"]}
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        initialValues={{ defaultLanguage: "English", eventGroup: templateId }}
      >
        <EventSubGroupEdit
          form={form}
          eventSubgroup={eventSubgroup}
          templateId={templateId}
          getEventSubGroups={getEventSubGroups}
          newEventSubgroup={newEventSubgroup}
          smsTemplates={smsTemplates}
          eventSubGroupItems={eventSubGroupItems}
          smsVariableList={smsVariableList}
        />
      </Form>
    </Drawer>
  );
};

export default EventSubGroupDrawer;
