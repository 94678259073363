import { useCallback, useState } from "react";

import { useReduxState } from "@ni/common/hooks";
import { ProductApi } from "@ni/sdk/apis";
import { Deployment } from "@ni/sdk/models";

const productApi = new ProductApi();

export const useDeploymentDetails = () => {
  const [deployment, setDeployment] = useState<Deployment>({});
  const [isLoading, setIsLoadin] = useReduxState<boolean>("isLoading");

  const fetchDepeloymentById = useCallback(
    (id: number) => {
      setIsLoadin(true);
      productApi
        .getDeploymentById(id)
        .then(res => {
          if (res) {
            setDeployment(res.data);
            setIsLoadin(false);
          }
        })
        .catch(() => {
          setIsLoadin(false);
        });
    },
    [setIsLoadin],
  );

  return { fetchDepeloymentById, deployment, isLoading };
};
