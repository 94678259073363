/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeFeatureRequest } from '../model';
// @ts-ignore
import { CreateFeatureRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { Feature } from '../model';
// @ts-ignore
import { PageDataDashboardFeatures } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
/**
 * FeatureApi - axios parameter creator
 * @export
 */
export const FeatureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the feature created
         * @summary Create Feature
         * @param {CreateFeatureRequest} createFeatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature: async (createFeatureRequest: CreateFeatureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFeatureRequest' is not null or undefined
            assertParamExists('createFeature', 'createFeatureRequest', createFeatureRequest)
            const localVarPath = `/api/v1/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFeatureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Feature
         * @summary Delete Feature by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeature', 'id', id)
            const localVarPath = `/api/v1/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Feature by Code
         * @summary Get Feature by Code
         * @param {string} featureCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureByCode: async (featureCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureCode' is not null or undefined
            assertParamExists('getFeatureByCode', 'featureCode', featureCode)
            const localVarPath = `/api/v1/features/code/{featureCode}`
                .replace(`{${"featureCode"}}`, encodeURIComponent(String(featureCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Feature by ID
         * @summary Get Feature by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureById', 'id', id)
            const localVarPath = `/api/v1/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Feature page for given page number, page size and sorting / filtering criteria
         * @summary Get Feature Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getFeatures', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/features/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates Feature and returns result of operation
         * @summary Edit Feature by ID
         * @param {ChangeFeatureRequest} changeFeatureRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (changeFeatureRequest: ChangeFeatureRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeFeatureRequest' is not null or undefined
            assertParamExists('updateFeature', 'changeFeatureRequest', changeFeatureRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeature', 'id', id)
            const localVarPath = `/api/v1/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeFeatureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureApi - functional programming interface
 * @export
 */
export const FeatureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the feature created
         * @summary Create Feature
         * @param {CreateFeatureRequest} createFeatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeature(createFeatureRequest: CreateFeatureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeature(createFeatureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Feature
         * @summary Delete Feature by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeature(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeature(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Feature by Code
         * @summary Get Feature by Code
         * @param {string} featureCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureByCode(featureCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureByCode(featureCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Feature by ID
         * @summary Get Feature by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Feature page for given page number, page size and sorting / filtering criteria
         * @summary Get Feature Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatures(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataDashboardFeatures>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatures(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates Feature and returns result of operation
         * @summary Edit Feature by ID
         * @param {ChangeFeatureRequest} changeFeatureRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(changeFeatureRequest: ChangeFeatureRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(changeFeatureRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureApi - factory interface
 * @export
 */
export const FeatureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureApiFp(configuration)
    return {
        /**
         * Returns the feature created
         * @summary Create Feature
         * @param {CreateFeatureRequest} createFeatureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature(createFeatureRequest: CreateFeatureRequest, options?: any): AxiosPromise<Feature> {
            return localVarFp.createFeature(createFeatureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Feature
         * @summary Delete Feature by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFeature(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Feature by Code
         * @summary Get Feature by Code
         * @param {string} featureCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureByCode(featureCode: string, options?: any): AxiosPromise<Feature> {
            return localVarFp.getFeatureByCode(featureCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Feature by ID
         * @summary Get Feature by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById(id: number, options?: any): AxiosPromise<Feature> {
            return localVarFp.getFeatureById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Feature page for given page number, page size and sorting / filtering criteria
         * @summary Get Feature Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<PageDataDashboardFeatures> {
            return localVarFp.getFeatures(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates Feature and returns result of operation
         * @summary Edit Feature by ID
         * @param {ChangeFeatureRequest} changeFeatureRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(changeFeatureRequest: ChangeFeatureRequest, id: number, options?: any): AxiosPromise<Feature> {
            return localVarFp.updateFeature(changeFeatureRequest, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureApi - interface
 * @export
 * @interface FeatureApi
 */
export interface FeatureApiInterface {
    /**
     * Returns the feature created
     * @summary Create Feature
     * @param {CreateFeatureRequest} createFeatureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApiInterface
     */
    createFeature(createFeatureRequest: CreateFeatureRequest, options?: AxiosRequestConfig): AxiosPromise<Feature>;

    /**
     * Deletes Feature
     * @summary Delete Feature by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApiInterface
     */
    deleteFeature(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns Feature by Code
     * @summary Get Feature by Code
     * @param {string} featureCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApiInterface
     */
    getFeatureByCode(featureCode: string, options?: AxiosRequestConfig): AxiosPromise<Feature>;

    /**
     * Returns Feature by ID
     * @summary Get Feature by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApiInterface
     */
    getFeatureById(id: number, options?: AxiosRequestConfig): AxiosPromise<Feature>;

    /**
     * Returns Feature page for given page number, page size and sorting / filtering criteria
     * @summary Get Feature Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApiInterface
     */
    getFeatures(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<PageDataDashboardFeatures>;

    /**
     * Updates Feature and returns result of operation
     * @summary Edit Feature by ID
     * @param {ChangeFeatureRequest} changeFeatureRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApiInterface
     */
    updateFeature(changeFeatureRequest: ChangeFeatureRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Feature>;

}

/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
export class FeatureApi extends BaseAPI implements FeatureApiInterface {
    /**
     * Returns the feature created
     * @summary Create Feature
     * @param {CreateFeatureRequest} createFeatureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public createFeature(createFeatureRequest: CreateFeatureRequest, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).createFeature(createFeatureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Feature
     * @summary Delete Feature by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public deleteFeature(id: number, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).deleteFeature(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Feature by Code
     * @summary Get Feature by Code
     * @param {string} featureCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureByCode(featureCode: string, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeatureByCode(featureCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Feature by ID
     * @summary Get Feature by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureById(id: number, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeatureById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Feature page for given page number, page size and sorting / filtering criteria
     * @summary Get Feature Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatures(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeatures(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates Feature and returns result of operation
     * @summary Edit Feature by ID
     * @param {ChangeFeatureRequest} changeFeatureRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public updateFeature(changeFeatureRequest: ChangeFeatureRequest, id: number, options?: AxiosRequestConfig) {
        return FeatureApiFp(this.configuration).updateFeature(changeFeatureRequest, id, options).then((request) => request(this.axios, this.basePath));
    }
}
