import { useEffect } from "react";
import { Form, notification, Typography } from "antd";
import { useParams } from "react-router-dom";

import { JF_ACC_ENABLED, NICE_JF_ACC_VAL, SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { getErrorInstance, getFormValueFromProductValues, removeGapsFromStringNumeric } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

export const JoiningFeePage = () => {
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm();
  const isEnabled = Form.useWatch<boolean>(JF_ACC_ENABLED, form);
  const feeValue = Form.useWatch<string>(NICE_JF_ACC_VAL, form);

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      [JF_ACC_ENABLED]: getFormValueFromProductValues(pct.pctProductValues, JF_ACC_ENABLED, "boolean") as boolean,

      [NICE_JF_ACC_VAL]: getFormValueFromProductValues(pct.pctProductValues, NICE_JF_ACC_VAL, "string"),
    });
  }, [form, pct.pctProductValues]);

  const switchClick = () => {
    if (isEnabled && feeValue.length === 0) {
      form.setFields([
        {
          name: NICE_JF_ACC_VAL,
          errors: [],
        },
      ]);
    }
  };

  const onFinish = async (values: FormValues) => {
    try {
      const body = {
        ...values,
        [NICE_JF_ACC_VAL]: isEnabled ? removeGapsFromStringNumeric(feeValue) : "",
      };

      await onSavePct(body as unknown as FormValues);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Joining Fee"
      pageSubtitle="Joining fee is a one-time fee that is charged to customer upon account opening regardless of card activation status or number of cards under account."
      submitHandler={onFinish}
      size="md"
      formSize="md"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Switch
        onChange={switchClick}
        formItemOptions={{
          name: JF_ACC_ENABLED,
          valuePropName: "checked",
          initialValue: true,
          label: <Typography.Text strong={true}>Enable joining fee on your product</Typography.Text>,
          tooltip:
            "Joining fee will be charged on account once the account is boarded on the system, regardless of the number of cards",
        }}
      />

      {isEnabled && (
        <NetworkForm.Number
          min={0}
          precision={2}
          addonAfter={productCurrency}
          disabled={!isEnabled}
          formItemOptions={{
            name: NICE_JF_ACC_VAL,
            label: "Joining fee amount",
            rules: [
              {
                validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                message: "Minimum fee amount cannot be 0",
              },
              {
                required: isEnabled,
                message: "Joining fee amount is required",
              },
            ],
          }}
        />
      )}
    </CustomFormWrapper>
  );
};
