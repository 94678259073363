import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="34" viewBox="0 0 40 34" fill="none">
    <path
      d="M36 17C36 20.3143 35.0171 23.3543 33.3257 25.8915C30.8571 29.5486 26.9486 32.1314 22.4 32.7943C21.6229 32.9314 20.8229 33 20 33C19.1771 33 18.3771 32.9314 17.6 32.7943C13.0514 32.1314 9.14286 29.5486 6.67429 25.8915C4.98286 23.3543 4 20.3143 4 17C4 8.15429 11.1543 1 20 1C28.8457 1 36 8.15429 36 17Z"
      fill="#FFEDEF"
    />
    <path
      d="M6.9957 15.0065L3.98922 12L1 15.0065"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.0176 19L36.0241 22.0065L39.0306 19"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0062 21.0087V17.0001C36.0062 8.15339 28.8356 1 20.0062 1C14.9608 1 10.451 3.34995 7.51367 6.99575"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.00684 12.9922V17.0009C4.00684 25.8475 11.1775 33.0009 20.0069 33.0009C25.0523 33.0009 29.562 30.651 32.4994 27.0052"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6087 23.416C16.292 22.6827 17.3337 22.741 17.9337 23.541L18.7753 24.666C19.4503 25.5577 20.542 25.5577 21.217 24.666L22.0587 23.541C22.6587 22.741 23.7003 22.6827 24.3837 23.416C25.867 24.9994 27.0753 24.4744 27.0753 22.2577V12.866C27.0837 9.50768 26.3003 8.66602 23.1503 8.66602H16.8503C13.7003 8.66602 12.917 9.50768 12.917 12.866V22.2493C12.917 24.4744 14.1337 24.991 15.6087 23.416Z"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.667 12.834H23.3337" stroke="#E64360" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.5 16.166H22.5" stroke="#E64360" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const InstallmentRedIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
