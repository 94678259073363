import { useMemo } from "react";

import { WizardPage, WizardPageMock, WizardPlacement } from "@ni/common/types";
import { checkIfWizardPageIsCompleted, getPhaseAndPageOrder, isNotUndefined } from "@ni/common/utils";
import { Phase } from "@ni/sdk/models";

interface UsePrepareWizardPagesProps {
  pageQuery: string;
  wizardPages: WizardPageMock[];
  lastSubmittedStep: WizardPlacement;
  phases: Phase[];
}

export const usePrepareWizardPages = ({
  pageQuery,
  wizardPages,
  lastSubmittedStep,
  phases,
}: UsePrepareWizardPagesProps) => {
  const pages = useMemo(() => {
    const pageComponents: WizardPage = {};

    wizardPages.forEach(({ pageKey, component: Component }) => {
      const phaseOrder = getPhaseAndPageOrder(phases, pageKey)?.phaseOrder;
      const isFormDisabled = checkIfWizardPageIsCompleted(
        { phase: isNotUndefined(phaseOrder) ? phaseOrder : lastSubmittedStep.phase, pageKey },
        lastSubmittedStep,
        phases,
      );

      pageComponents[pageKey] = <Component formDisabled={isFormDisabled} />;
    });

    return pageComponents;
  }, [lastSubmittedStep, phases, wizardPages]);

  const doesPageExistQuery = useMemo(
    () =>
      pageQuery
        ? wizardPages.some(page => page.pageKey === pageQuery && getPhaseAndPageOrder(phases, page.pageKey))
        : false,
    [pageQuery, phases, wizardPages],
  );

  return {
    pages,
    doesPageExistQuery,
  };
};
