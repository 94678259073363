import { FC } from "react";
import { Button, Typography } from "antd";
import cn from "classnames";
import { Link } from "react-router-dom";

import { useReduxState } from "../../../../../hooks/src";
import { BackIcon } from "../../../../../icons/src";
import { Breadcrumbs } from "../Breadcrumbs";

import { AfterWizardEditButton } from "./AfterWizardEditButton";

import styles from "./styles.module.scss";

interface AfterWizardEditPageTitleProps {
  title: string;
  text?: string;
  page?: string;
  link?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  btnExists?: boolean;
}

export const AfterWizardEditPageTitle: FC<AfterWizardEditPageTitleProps> = ({
  title,
  text,
  page = "Dashboard",
  link = "/",
  buttonText = "Add phase",
  onButtonClick = () => {},
  btnExists = false,
}) => {
  const [isPrintMode] = useReduxState<boolean>("isPrintMode", false);

  return (
    <>
      <Breadcrumbs />
      <div className={styles["after-wizard-edit-page-title-container"]}>
        <Typography.Title level={2}>{title}</Typography.Title>
        <div className={styles["right-column"]}>
          {btnExists && (
            <Button type="primary" onClick={onButtonClick}>
              {buttonText}
            </Button>
          )}
          {!isPrintMode && (
            <Link to={link || "/"} className={styles["back-to-dashboard-link"]}>
              Back to {page || "Dashboard"}
              <BackIcon className={cn(styles["tenant-back-icon"], "icon-vertical-middle")} />
            </Link>
          )}
        </div>
      </div>
      {text && <Typography.Text className={styles["after-wizard-edit-page-text"]}>{text}</Typography.Text>}
      {!isPrintMode && <AfterWizardEditButton />}
    </>
  );
};
