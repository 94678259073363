export interface CountryModel {
  countryCode: string;
  countryName: string;
}

export const countryList: CountryModel[] = [
  { countryCode: "AE", countryName: "United Arab Emirates" },
  { countryCode: "SA", countryName: "Kingdom of Saudi Arabia" },
  { countryCode: "LB", countryName: "Lebanon" },
  { countryCode: "KW", countryName: "Kuwait" },
];
