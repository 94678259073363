import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";

import { useInsuranceProgramPctApi } from "../../../hooks";

import { pagesRoute } from "./route";

import styles from "../../../Style.module.scss";

export const InsuranceEditPCT = () => {
  const navigate = useNavigate();

  const {
    id: tenantId,
    productId,
    pctId,
    insuranceId,
  } = useParams<{ id: string; productId: string; pctId: string; insuranceId: string }>();

  const { currentInsuranceProgram, insurancePrograms } = useInsuranceProgramPctApi(
    parseInt(insuranceId ?? "0", 10),
    parseInt(productId ?? "0", 10),
    parseInt(tenantId ?? "0", 10),
  );

  const handleInsuranceChange = useCallback(
    (id: number): void => {
      navigate(`/tenant/${tenantId}/product/${productId}/pct/${pctId}/insurance-edit/${id}/insurance-premium-settings`);
    },
    [navigate, pctId, productId, tenantId],
  );

  return !!currentInsuranceProgram?.id && !!insurancePrograms.length && !!handleInsuranceChange ? (
    <RoutesHandler
      routePrefix="/"
      routesList={pagesRoute}
      ParentComponent={({ navItems, children }) => (
        <div className={styles["ni-program"]}>
          <Sidenav items={navItems} disabledItemsViewType="hidden">
            <SidenavEntitySelect
              value={currentInsuranceProgram?.id}
              entities={insurancePrograms}
              onChange={handleInsuranceChange}
            />
          </Sidenav>
          <div className={styles["ni-program-content"]}>{children}</div>
        </div>
      )}
      deps={[currentInsuranceProgram?.id, handleInsuranceChange]}
    />
  ) : null;
};
