import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useTenant } from "@ni/common/hooks";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";

import { usePlanApi } from "../../../hooks";

import { pagesRoute } from "./route";

import styles from "../../../Style.module.scss";

export const InstallmentPaymentPlansEditPCT = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    id: tenantId,
    productId,
    pctId,
    planId,
  } = useParams<{ id: string; productId: string; pctId: string; planId: string }>();

  const { tenant } = useTenant({ tenantId: parseInt(tenantId ?? "0", 10) });
  const { currentPlan } = usePlanApi(parseInt(planId ?? "0", 10));

  const handleIppChange = useCallback(
    (id: number): void => {
      navigate(
        `/tenant/${tenantId}/product/${productId}/pct/${pctId}/installment-payment-plans/${id}/${getLastRoute(
          location.pathname,
        )}`,
      );
    },
    [location.pathname, navigate, pctId, productId, tenantId],
  );

  const activePlans = useMemo(() => {
    return tenant?.plans?.filter(plan => plan.state === "ACTIVE") ?? [];
  }, [tenant?.plans]);

  return currentPlan?.id && activePlans.length && handleIppChange ? (
    <RoutesHandler
      routePrefix="/"
      routesList={pagesRoute}
      deps={[currentPlan?.id, handleIppChange]}
      ParentComponent={({ navItems, children }) => (
        <div className={styles["ni-program"]}>
          <Sidenav items={navItems} disabledItemsViewType="hidden">
            <SidenavEntitySelect value={currentPlan?.id} entities={activePlans} onChange={handleIppChange} />
          </Sidenav>
          <div className={styles["ni-program-content"]}>{children}</div>
        </div>
      )}
    />
  ) : null;
};
