/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { DependencyList, ReactNode, useMemo, useState } from "react";
import { Routes } from "react-router-dom";

import { NavbarItem, RoutesTreeItem } from "@ni/common/types";
import { mapRoutesTree, RenderRoutesTree } from "@ni/common/utils";

import { useToggleFeature } from "../../../../../hooks/src/routes/useToggleFeature";
import { Spin } from "../../components/Spin";

const potentialMiliseconds = 1275;

export const RoutesHandler = ({
  routesList,
  alternativeRouteList,
  ParentComponent = () => <div />,
  routePrefix,
  deps = [],
}: {
  ParentComponent: (props: { navItems: NavbarItem[]; children: ReactNode }) => ReactNode;
  routesList: RoutesTreeItem[];
  alternativeRouteList?: RoutesTreeItem[];
  routePrefix?: string;
  deps?: DependencyList;
}) => {
  const { isInitiated } = useToggleFeature();
  const [canRender, setCanRender] = useState(false);
  const { navItems, gaurdedRoutes, Parent } = useMemo(() => {
    if (isInitiated) {
      const navItems = mapRoutesTree(routesList);
      const gaurdedRoutes = alternativeRouteList
        ? RenderRoutesTree(alternativeRouteList, routePrefix)
        : RenderRoutesTree(routesList, routePrefix);
      setTimeout(() => {
        setCanRender(true);
      }, potentialMiliseconds);
      return {
        navItems,
        gaurdedRoutes,
        Parent: ParentComponent,
      };
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, isInitiated]);

  return isInitiated && navItems && gaurdedRoutes && canRender ? (
    <Parent navItems={navItems}>
      <Routes>{gaurdedRoutes}</Routes>
    </Parent>
  ) : (
    <Spin fullscreen={true} />
  );
};
