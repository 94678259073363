import { Key, ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Flex, Form, Input, Modal, Space } from "antd";
import { useNavigate } from "react-router-dom";

import { SearchOutlined } from "@ant-design/icons";
import { initialQpPagination, useDebaunce, useQpApi, useReduxState } from "@ni/common/hooks";
import { FormValues, IJiraCommitForm } from "@ni/common/types";
import { CollapsedLinks, FilterControls, JiraCommitForm, PageHeading } from "@ni/common/ui";
import { updateFilters } from "@ni/common/utils";
import { DeploymentsApi } from "@ni/sdk/apis";
import { QpDashboard } from "@ni/sdk/models";

import { QPEditTable } from "../../../components";
import { useQpConfirmFilters } from "../../../hooks";

import styles from "../styles.module.scss";

const defaultModalOptions: { shouldShow: boolean; message: string; action: () => void; children?: ReactNode } = {
  message: "",
  shouldShow: false,
  action: () => {},
};

const deploymentsApi = new DeploymentsApi();

export const QuestionnaireProcessingDataConfirmation = () => {
  const [form] = Form.useForm<FormValues>();
  const [searchForm] = Form.useForm<FormValues>();
  const [jiraForm] = Form.useForm<IJiraCommitForm>();
  const [tenantRowkey] = useReduxState<string | undefined>("tenantRowkey");
  const [isLoading] = useReduxState<boolean>("isLoading", false);
  const searchQuery = Form.useWatch<string>("search", searchForm);
  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });

  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [modalOption, setModalOption] = useState(defaultModalOptions);

  const { qpInputFilters, currentRowkey, qpRowkeys } = useQpConfirmFilters({ form });

  const {
    qpConfirmData,
    revertConfirmedQpData,
    setQpData,
    saveQpData,
    setFilters,
    pagination,
    setPagination,
    saveConfirmedQpData,
    filters,
  } = useQpApi({
    isFetchEnabled: true,
    isConfirmPage: true,
    currentRowkey,
  });

  const filterKeys = useMemo(() => qpInputFilters.map(x => x.name), [qpInputFilters]);

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setFilters(filters => {
        return updateFilters(filterKeys, filters, undefined, debouncedQuery);
      });

      setPagination(initialQpPagination);
    }
  }, [debouncedQuery, setPagination, setFilters, filterKeys]);

  const handleValuesChange = (changedValues: FormValues) => {
    const newValues = Object.keys(changedValues ?? {}).reduce((acc, key) => {
      const value = changedValues ? changedValues[key] : undefined;

      if (Array.isArray(value)) {
        acc[key] = value.join(",");
      } else {
        switch (key) {
          case "rowkey.product":
            acc["rowkey.rowkey"] = value !== undefined ? value : (form.getFieldValue("rowkey.rowkey") as string);
            break;

          case "rowkey.pct":
            acc["rowkey.rowkey"] = value !== undefined ? value : (form.getFieldValue("rowkey.product") as string);
            break;

          default:
            acc[key] = value !== undefined ? value : "";
            break;
        }
      }

      return acc;
    }, {} as FormValues);

    setFilters(filters => {
      return updateFilters(filterKeys, filters, newValues, debouncedQuery ?? "");
    });

    setPagination(initialQpPagination);
  };

  const hideModal = () => {
    setModalOption(defaultModalOptions);
  };

  const handleRevert = () => {
    setModalOption({
      action: () => {
        void revertConfirmedQpData(selectedRowKeys as number[], tenantRowkey).finally(() => {
          hideModal();
          setSelectedRowKeys([]);
        });
      },
      message: "Are you sure you want to revert selected configuration?",
      shouldShow: true,
    });
  };

  const handleConfirm = () => {
    setModalOption({
      action: () => {
        void jiraForm
          .validateFields()
          .then(formValues => {
            void deploymentsApi
              .put1({
                state: "IN_PROGRESS",
                jiraTicket: formValues.jiraTicketId,
                additionalNotes: formValues.commitComment,
                tenantExternalCode: tenantRowkey,
                branchName: formValues?.destinationBranch,
                originBranchName: formValues?.originBranch,
                featureBranchName: formValues?.sourceBranch,
                deploymentDetails: [],
              })
              .then(jiraResponse => {
                if (jiraResponse?.data) {
                  void saveConfirmedQpData().finally(() => {
                    hideModal();
                    navigate("/admin/deployments");
                  });
                }
              })
              .catch(() => {});
          })
          .catch(() => {});
      },
      message:
        "You are about to apply changes for this FI set up! A valid Jira tickets ID are required to proceed, ready to continue?",
      shouldShow: true,
      children: <JiraCommitForm hideFiInput={true} form={jiraForm} />,
    });
  };

  useEffect(() => {
    if (!currentRowkey) navigate("/admin/questionnaire-processing-data");
  }, [currentRowkey, navigate]);

  return (
    <Space direction="vertical" className={styles["qp-layout"]}>
      <PageHeading
        text="QP Data - Updates Confirmation"
        extraElements={[
          <Flex key="qp-search" flex={1}>
            <Form form={searchForm} className={styles["qp-form-search"]}>
              <Form.Item name="search">
                <Input placeholder="Search" prefix={<SearchOutlined />} className={styles["qp-search"]} />
              </Form.Item>
            </Form>
          </Flex>,
        ]}
      />

      <FilterControls
        form={form}
        inputs={qpInputFilters}
        inputWidth={160}
        fullWidth={true}
        onValuesChange={handleValuesChange}
        className="p-l-40 p-r-40"
      />

      <Space direction="vertical" size={32} className={styles["qp-content"]}>
        <QPEditTable
          data={(qpConfirmData?.content as QpDashboard[]) ?? []}
          setData={setQpData}
          saveQpData={saveQpData}
          pagination={pagination}
          qpRowkeys={qpRowkeys}
          currentRowkey={currentRowkey}
          setPagination={setPagination}
          setFilters={setFilters}
          isLoading={isLoading}
          reviewMode={true}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <Space direction="horizontal">
          <Button
            size="large"
            type="primary"
            htmlType="button"
            loading={isLoading}
            className={styles["qp-button"]}
            onClick={handleRevert}
            disabled={selectedRowKeys?.length <= 0}
          >
            Revert selected changes
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="button"
            loading={isLoading}
            className={styles["qp-button"]}
            onClick={handleConfirm}
            disabled={
              !qpConfirmData?.content?.length ||
              (filters?.filter && Object.keys(filters.filter)?.length > 0) ||
              !!filters?.search?.toString() ||
              selectedRowKeys?.length > 0
            }
          >
            Confirm and test in sandbox
          </Button>
        </Space>
        <Modal
          title={modalOption.message}
          open={modalOption.shouldShow}
          width="568px"
          onCancel={hideModal}
          footer={[
            <Button key="back" disabled={isLoading} onClick={hideModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={modalOption.action}>
              Confirm
            </Button>,
          ]}
        >
          {modalOption?.children}
        </Modal>
        <CollapsedLinks
          list={[
            {
              href: `/admin/questionnaire-processing-data?rowKey=${currentRowkey}`,
              label: "Back to QP Data Dashboard",
            },
          ]}
        />
      </Space>
    </Space>
  );
};
