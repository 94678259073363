import { DynamicSwitchRule } from "@ni/common/ui";

export const cashRules: DynamicSwitchRule[] = [
  {
    name: "nic-c-d-cash-enable",
    label: "All",
    tooltip: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-d-cash-max-nr",
    maxAmount: "nic-c-d-cash-max-am",
    maxSingleAmount: "nic-c-d-cash-max-single",
  },
  {
    name: "nic-c-d-cash-atm-enable",
    label: "ATM",
    tooltip: "This limiter counts all ATM transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-d-cash-atm-max-nr",
    maxAmount: "nic-c-d-cash-atm-max-am",
    maxSingleAmount: "nic-c-d-cash-atm-max-single",
  },
  {
    name: "nic-c-d-cash-pos-enable",
    label: "POS",
    tooltip:
      "This limiter counts all cash transactions done at POS terminals. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-d-cash-pos-max-nr",
    maxAmount: "nic-c-d-cash-pos-max-am",
    maxSingleAmount: "nic-c-d-cash-pos-max-single",
  },
];

export const retailRules: DynamicSwitchRule[] = [
  {
    name: "nic-c-d-retail-enable",
    label: "All",
    tooltip: "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-d-retail-max-nr",
    maxAmount: "nic-c-d-retail-max-am",
    maxSingleAmount: "nic-c-d-retail-max-single",
  },
  {
    name: "nic-c-d-ecomm-enable",
    label: "Ecommerce",
    tooltip:
      "This limiter includes retail transactions over an electronic network, primarily the internet. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-d-ecomm-max-nr",
    maxAmount: "nic-c-d-ecomm-max-am",
    maxSingleAmount: "nic-c-d-ecomm-max-single",
  },
];

export const totalRules: DynamicSwitchRule[] = [
  {
    name: "nic-c-d-total-enable",
    label: "All",
    tooltip:
      "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-d-total-max-nr",
    maxAmount: "nic-c-d-total-max-am",
    maxSingleAmount: "nic-c-d-total-max-single",
  },
];
