import RegistrationContent from "./components/RegistrationContent";

import styles from "./styles.module.scss";

export const FinancialInstitutionRegistration = () => {
  return (
    <div className={styles["container"]}>
      <div className={styles["image"]} />
      <div className={styles["content"]}>
        <RegistrationContent />
      </div>
    </div>
  );
};
