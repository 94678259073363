import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Select } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { VariablesApi } from "@ni/sdk/apis";
import { CreateVariableRequest, PatchVariableRequest, Variable, VariableGroup } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface PageDrawerProps {
  opened: boolean;
  closeDrawer: () => void;
  variableGroupId: number;
  item?: Variable;
  setVariableList: Dispatch<SetStateAction<Variable[]>>;
}

const variablesApi = new VariablesApi();

interface VariablesModalForm {
  code: Variable["code"];
  description: Variable["description"];
  example: Variable["example"];
  variableGroupId: Variable["varGroupId"];
}

export const VariableModal: FC<PageDrawerProps> = props => {
  const { opened, closeDrawer, variableGroupId: propsVariableGroupId, item, setVariableList } = props;
  const [isLoading, setIsLoading] = useReduxState<boolean>("isVariableLoading");
  const [variableGroups] = useReduxState<VariableGroup[]>("variableGroups");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSaveCodeWithPercent = (value: string) => {
    if (value.at(0) !== "%" && value.at(-1) !== "%") {
      return `%${value}%`;
    }
    if (value.at(0) !== "%") {
      return `%${value}`;
    }
    if (value.at(-1) !== "%") {
      return `${value}%`;
    }
    return value;
  };

  useEffect(() => {
    if (opened) {
      form.setFieldsValue({
        variableGroup: propsVariableGroupId,
        description: item?.description || "",
        code: item?.code || "",
        example: item?.example || "",
      });
    }
  }, [opened]);

  const formVariableGroupId = Form.useWatch("variableGroup", form) as number;

  const onFinish = (values: VariablesModalForm) => {
    setIsLoading(true);

    const variableGroupId = formVariableGroupId || propsVariableGroupId;

    const requestBodyPatch: PatchVariableRequest = {
      code: values.code,
      description: values.description,
      example: values.example,
      varGroupId: variableGroupId,
    };

    const requestBodyPost: CreateVariableRequest = {
      code: onSaveCodeWithPercent(values.code),
      description: values.description,
      example: values.example,
    };

    if (item) {
      variablesApi
        .patchVariable(requestBodyPatch, variableGroupId, item.id)
        .then(i => {
          closeDrawer();
          setIsLoading(false);
          setVariableList(prevState => [...prevState.map(ii => (ii.id === item.id ? i.data : ii))]);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      variablesApi
        .createVariable(requestBodyPost, variableGroupId)
        .then(i => {
          closeDrawer();
          setIsLoading(false);
          setVariableList(prevState => [...prevState, i.data]);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const onValueChange = (): void => {
    const isFieldsFilled =
      form.getFieldValue("code")?.length > 0 &&
      form.getFieldValue("description")?.length > 0 &&
      form.getFieldValue("example")?.length > 0;

    if (item) {
      const isSomethingChange =
        item.code !== String(form.getFieldValue("code")) ||
        item.description !== String(form.getFieldValue("description")) ||
        item.example !== String(form.getFieldValue("example")) ||
        item.varGroupId !== Number(form.getFieldValue("variableGroup"));
      setIsEnabled(isFieldsFilled && !!isSomethingChange);
    } else {
      setIsEnabled(isFieldsFilled);
    }
  };

  return (
    <Drawer
      title={`${item ? "Edit" : "New"} variable`}
      placement="right"
      open={opened}
      width="99%"
      onClose={closeDrawer}
      keyboard={false}
      maskClosable={false}
      extra={
        <div className={styles["buttons"]}>
          <Button
            loading={isLoading}
            disabled={!isEnabled}
            className="page-save-button"
            type="primary"
            size="large"
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        className={styles["variables-dictionary-modal"]}
        layout="vertical"
        onValuesChange={onValueChange}
        onFinish={onFinish}
      >
        <Form.Item name="variableGroup" label="Variable group" required={true}>
          <Select>
            {variableGroups
              ?.map(i => ({ value: i.id, label: i.name }))
              .map(i => (
                <Select.Option key={i.value} value={i.value}>
                  {i.label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Name (business description)" required={true}>
          <Input placeholder="Financial Institution name" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          required={true}
          tooltip={{
            title:
              'Add here only variable code without "%" (e.g. "CONTRACT_CURR"). String will be saved with "%" symbols automatically (e.g. "%CONTRACT_CURR%")',
            icon: <QuestionCircleFilled />,
          }}
        >
          <Input placeholder="CONTRACT_F_I" />
        </Form.Item>
        <Form.Item name="example" label="Example text" required={true}>
          <Input placeholder="ABC Bank" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
