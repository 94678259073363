import { FC } from "react";
import { Form } from "antd";

import { useGetCurrencyList, useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { ChangePlanRequestCurrency } from "@ni/sdk/models";

import { usePlanApi } from "../../../../hooks";

import {
  currencyOption,
  descriptionOption,
  groupOption,
  nameOption,
  rolloverPlanOption,
  tenorOption,
} from "./constants";

export const IppDetailsPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentPlan, editPlan } = usePlanApi();
  const { currencyList } = useGetCurrencyList("plan-currency");

  const hydratedValues = useHydrateForm({
    form,
    entityFields: currentPlan?.planValues
      ? [
          ...(currentPlan?.planValues ?? []),
          ...[
            { fieldCode: nameOption.code, value: currentPlan?.name },
            { fieldCode: groupOption.code, value: currentPlan?.group },
            { fieldCode: currencyOption.code, value: currentPlan?.currency?.toString() },
            { fieldCode: descriptionOption.code, value: currentPlan?.description },
          ],
        ]
      : [],
    keys: {
      strings: [
        nameOption.code,
        groupOption.code,
        currencyOption.code,
        tenorOption.code,
        descriptionOption.code,
        rolloverPlanOption.code,
      ],
    },
    allowParse: false,
  });

  const onFinish = async (values: FormValues) => {
    if (
      values[descriptionOption.code] === hydratedValues[descriptionOption.code] &&
      values[descriptionOption.code]?.toString()?.includes("- Interest")
    ) {
      const planGroup = groupOption?.options?.find(x => x.value === values[groupOption.code])?.label ?? "No plan group";
      const planTenor = values[tenorOption.code]?.toString() ?? "0";
      const planInterest =
        currentPlan?.planValues?.find(x => x.fieldCode === "nic-ipp-inst-int-plan-rate")?.value ?? "0";

      values[descriptionOption.code] = `${planGroup} - ${planTenor} Months - Interest ${planInterest}%`;
    }
    await editPlan({
      name: values[nameOption.code]?.toString(),
      group: values[groupOption.code]?.toString(),
      currency: values[currencyOption.code]?.toString() as ChangePlanRequestCurrency,
      description: values[descriptionOption.code]?.toString(),
      planValues: [
        { fieldCode: rolloverPlanOption.code, value: values[rolloverPlanOption.code]?.toString() },
        { fieldCode: tenorOption.code, value: values[tenorOption.code]?.toString() },
      ],
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Installment Payment Plan Details"
      pageSubtitle=""
      level="tenant"
      size="md"
      formSize="md"
      gap={24}
      submitLabel="Save"
      linkTitle="Plan List"
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <NetworkForm.String
        formItemOptions={{
          name: nameOption.code,
          label: nameOption.name,
          tooltip: nameOption.tooltip,
          rules: [{ required: true, message: `${nameOption.name} is required` }],
        }}
        maxLength={24}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: groupOption.code,
          label: groupOption.name,
          tooltip: groupOption.tooltip,
          rules: [{ required: true, message: `${groupOption.name} is required` }],
        }}
        radioList={groupOption.options}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: currencyOption.code,
          label: currencyOption.name,
          tooltip: currencyOption.tooltip,
          rules: [{ required: true, message: `${currencyOption.name} is required` }],
        }}
        optionList={currencyList.map(x => ({ label: x.displayValue, value: x.value }))}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: tenorOption.code,
          label: tenorOption.name,
          tooltip: tenorOption.tooltip,
          rules: [{ required: true, message: `${tenorOption.name} is required` }],
        }}
        min={tenorOption.min}
        max={tenorOption.max}
        precision={tenorOption.decimalsNumber}
      />

      <NetworkForm.String
        formItemOptions={{
          name: descriptionOption.code,
          label: descriptionOption.name,
          tooltip: descriptionOption.tooltip,
        }}
        maxLength={100}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: rolloverPlanOption.code,
          label: rolloverPlanOption.name,
          tooltip: rolloverPlanOption.tooltip,
        }}
        optionList={rolloverPlanOption.options?.map(x => ({ label: x.displayed, value: x.value }))}
      />
    </CustomFormWrapper>
  );
};
