/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeParameterTableRequest } from '../model';
// @ts-ignore
import { CopyParameterTableRequest } from '../model';
// @ts-ignore
import { CreateParameterTableRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { GenericId } from '../model';
// @ts-ignore
import { ParameterTable } from '../model';
/**
 * ParametersTableApi - axios parameter creator
 * @export
 */
export const ParametersTableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Copies Parameters Table by ProductId and TableId with new DisplayName and returns created one
         * @summary Copy Parameters Table
         * @param {CopyParameterTableRequest} copyParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyParameterTable: async (copyParameterTableRequest: CopyParameterTableRequest, productId: number, tableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyParameterTableRequest' is not null or undefined
            assertParamExists('copyParameterTable', 'copyParameterTableRequest', copyParameterTableRequest)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('copyParameterTable', 'productId', productId)
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('copyParameterTable', 'tableId', tableId)
            const localVarPath = `/api/v1/products/{productId}/parameters-table/{tableId}/copy`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyParameterTableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates Parameters Table
         * @summary Create Parameters Table
         * @param {CreateParameterTableRequest} createParameterTableRequest 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParameterTable: async (createParameterTableRequest: CreateParameterTableRequest, productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createParameterTableRequest' is not null or undefined
            assertParamExists('createParameterTable', 'createParameterTableRequest', createParameterTableRequest)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createParameterTable', 'productId', productId)
            const localVarPath = `/api/v1/products/{productId}/parameters-table`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createParameterTableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes non-boarded parameter table by id
         * @summary Delete Parameter Table by ID
         * @param {number} productId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameterTable: async (productId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteParameterTable', 'productId', productId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteParameterTable', 'id', id)
            const localVarPath = `/api/v1/products/{productId}/parameters-table/{id}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Parameters Table (Patch Method)
         * @param {ChangeParameterTableRequest} changeParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchParameterTable: async (changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeParameterTableRequest' is not null or undefined
            assertParamExists('patchParameterTable', 'changeParameterTableRequest', changeParameterTableRequest)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('patchParameterTable', 'productId', productId)
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('patchParameterTable', 'tableId', tableId)
            const localVarPath = `/api/v1/products/{productId}/parameters-table/{tableId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeParameterTableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Parameters Table (Put Method)
         * @param {ChangeParameterTableRequest} changeParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParameterTable: async (changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeParameterTableRequest' is not null or undefined
            assertParamExists('putParameterTable', 'changeParameterTableRequest', changeParameterTableRequest)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('putParameterTable', 'productId', productId)
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('putParameterTable', 'tableId', tableId)
            const localVarPath = `/api/v1/products/{productId}/parameters-table/{tableId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeParameterTableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParametersTableApi - functional programming interface
 * @export
 */
export const ParametersTableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParametersTableApiAxiosParamCreator(configuration)
    return {
        /**
         * Copies Parameters Table by ProductId and TableId with new DisplayName and returns created one
         * @summary Copy Parameters Table
         * @param {CopyParameterTableRequest} copyParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyParameterTable(copyParameterTableRequest: CopyParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyParameterTable(copyParameterTableRequest, productId, tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates Parameters Table
         * @summary Create Parameters Table
         * @param {CreateParameterTableRequest} createParameterTableRequest 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParameterTable(createParameterTableRequest: CreateParameterTableRequest, productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParameterTable(createParameterTableRequest, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes non-boarded parameter table by id
         * @summary Delete Parameter Table by ID
         * @param {number} productId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParameterTable(productId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParameterTable(productId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Parameters Table (Patch Method)
         * @param {ChangeParameterTableRequest} changeParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchParameterTable(changeParameterTableRequest, productId, tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Parameters Table (Put Method)
         * @param {ChangeParameterTableRequest} changeParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParameterTable(changeParameterTableRequest, productId, tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParametersTableApi - factory interface
 * @export
 */
export const ParametersTableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParametersTableApiFp(configuration)
    return {
        /**
         * Copies Parameters Table by ProductId and TableId with new DisplayName and returns created one
         * @summary Copy Parameters Table
         * @param {CopyParameterTableRequest} copyParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyParameterTable(copyParameterTableRequest: CopyParameterTableRequest, productId: number, tableId: number, options?: any): AxiosPromise<ParameterTable> {
            return localVarFp.copyParameterTable(copyParameterTableRequest, productId, tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates Parameters Table
         * @summary Create Parameters Table
         * @param {CreateParameterTableRequest} createParameterTableRequest 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParameterTable(createParameterTableRequest: CreateParameterTableRequest, productId: number, options?: any): AxiosPromise<ParameterTable> {
            return localVarFp.createParameterTable(createParameterTableRequest, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes non-boarded parameter table by id
         * @summary Delete Parameter Table by ID
         * @param {number} productId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameterTable(productId: number, id: number, options?: any): AxiosPromise<GenericId> {
            return localVarFp.deleteParameterTable(productId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Parameters Table (Patch Method)
         * @param {ChangeParameterTableRequest} changeParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: any): AxiosPromise<ParameterTable> {
            return localVarFp.patchParameterTable(changeParameterTableRequest, productId, tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Parameters Table (Put Method)
         * @param {ChangeParameterTableRequest} changeParameterTableRequest 
         * @param {number} productId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: any): AxiosPromise<ParameterTable> {
            return localVarFp.putParameterTable(changeParameterTableRequest, productId, tableId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParametersTableApi - interface
 * @export
 * @interface ParametersTableApi
 */
export interface ParametersTableApiInterface {
    /**
     * Copies Parameters Table by ProductId and TableId with new DisplayName and returns created one
     * @summary Copy Parameters Table
     * @param {CopyParameterTableRequest} copyParameterTableRequest 
     * @param {number} productId 
     * @param {number} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApiInterface
     */
    copyParameterTable(copyParameterTableRequest: CopyParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig): AxiosPromise<ParameterTable>;

    /**
     * Creates Parameters Table
     * @summary Create Parameters Table
     * @param {CreateParameterTableRequest} createParameterTableRequest 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApiInterface
     */
    createParameterTable(createParameterTableRequest: CreateParameterTableRequest, productId: number, options?: AxiosRequestConfig): AxiosPromise<ParameterTable>;

    /**
     * Deletes non-boarded parameter table by id
     * @summary Delete Parameter Table by ID
     * @param {number} productId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApiInterface
     */
    deleteParameterTable(productId: number, id: number, options?: AxiosRequestConfig): AxiosPromise<GenericId>;

    /**
     * 
     * @summary Edit Parameters Table (Patch Method)
     * @param {ChangeParameterTableRequest} changeParameterTableRequest 
     * @param {number} productId 
     * @param {number} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApiInterface
     */
    patchParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig): AxiosPromise<ParameterTable>;

    /**
     * 
     * @summary Edit Parameters Table (Put Method)
     * @param {ChangeParameterTableRequest} changeParameterTableRequest 
     * @param {number} productId 
     * @param {number} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApiInterface
     */
    putParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig): AxiosPromise<ParameterTable>;

}

/**
 * ParametersTableApi - object-oriented interface
 * @export
 * @class ParametersTableApi
 * @extends {BaseAPI}
 */
export class ParametersTableApi extends BaseAPI implements ParametersTableApiInterface {
    /**
     * Copies Parameters Table by ProductId and TableId with new DisplayName and returns created one
     * @summary Copy Parameters Table
     * @param {CopyParameterTableRequest} copyParameterTableRequest 
     * @param {number} productId 
     * @param {number} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApi
     */
    public copyParameterTable(copyParameterTableRequest: CopyParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig) {
        return ParametersTableApiFp(this.configuration).copyParameterTable(copyParameterTableRequest, productId, tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates Parameters Table
     * @summary Create Parameters Table
     * @param {CreateParameterTableRequest} createParameterTableRequest 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApi
     */
    public createParameterTable(createParameterTableRequest: CreateParameterTableRequest, productId: number, options?: AxiosRequestConfig) {
        return ParametersTableApiFp(this.configuration).createParameterTable(createParameterTableRequest, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes non-boarded parameter table by id
     * @summary Delete Parameter Table by ID
     * @param {number} productId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApi
     */
    public deleteParameterTable(productId: number, id: number, options?: AxiosRequestConfig) {
        return ParametersTableApiFp(this.configuration).deleteParameterTable(productId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Parameters Table (Patch Method)
     * @param {ChangeParameterTableRequest} changeParameterTableRequest 
     * @param {number} productId 
     * @param {number} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApi
     */
    public patchParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig) {
        return ParametersTableApiFp(this.configuration).patchParameterTable(changeParameterTableRequest, productId, tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Parameters Table (Put Method)
     * @param {ChangeParameterTableRequest} changeParameterTableRequest 
     * @param {number} productId 
     * @param {number} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersTableApi
     */
    public putParameterTable(changeParameterTableRequest: ChangeParameterTableRequest, productId: number, tableId: number, options?: AxiosRequestConfig) {
        return ParametersTableApiFp(this.configuration).putParameterTable(changeParameterTableRequest, productId, tableId, options).then((request) => request(this.axios, this.basePath));
    }
}
