import { useParamsList, usePrepareWizardPages, useWizardController } from "@ni/common/hooks";
import { WizardProcess } from "@ni/common/ui";

import { WIZARD_PAGES } from "./constants";
import { useIPPWizard } from "./hooks";

export const IPPWizard = () => {
  const {
    params: { tenantId: tenantIdQuery, planId: planIdQuery, page: pageQuery },
  } = useParamsList(["tenantId", "planId", "phase", "page"]);

  const { phases, lastSubmittedStep, processWizardRequest } = useIPPWizard();

  const { pages, doesPageExistQuery } = usePrepareWizardPages({
    pageQuery: pageQuery ?? "",
    wizardPages: WIZARD_PAGES,
    lastSubmittedStep,
    phases,
  });

  const [wizardPlacement, setWizardPlacement] = useWizardController({
    phases,
    wizardType: "ipp",
    lastSubmittedStep,
    tenantIdQuery,
    planIdQuery,
    pageQuery,
    doesPageExist: doesPageExistQuery,
    processWizardRequest: processWizardRequest as (tenantId?: string, entityId?: string) => Promise<void>,
  });

  return (
    <WizardProcess
      lastSubmittedStep={lastSubmittedStep}
      wizardPlacementState={[wizardPlacement, setWizardPlacement]}
      wizardPhases={phases}
      wizardPagesList={pages}
    />
  );
};
