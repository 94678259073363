import { FC, useEffect } from "react";
import { Button, Drawer, Form, Input } from "antd";
import { StoreValue } from "rc-field-form/lib/interface";

import { ChangeFeatureRequest, Feature } from "@ni/sdk/models";

import styles from "./styles.module.scss";

export interface FeatureDrawerProps {
  selectedFeature: Feature | undefined;
  closeDrawer: (update: boolean, changeFeatureRequest?: ChangeFeatureRequest) => void;
  isOpen: boolean;
}

export const FeatureDrawer: FC<FeatureDrawerProps> = ({ selectedFeature, closeDrawer, isOpen }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedFeature) {
      form.setFieldsValue({
        name: selectedFeature.name,
        code: selectedFeature.code,
        description: selectedFeature.description,
      });
    }
  }, [form, selectedFeature]);

  const onSave = () => {
    const changeFeatureRequest: ChangeFeatureRequest = {
      name: form.getFieldValue("name") as string,
      code: form.getFieldValue("code") as string,
      description: form.getFieldValue("description") as string,
    };
    closeDrawer(true, changeFeatureRequest);
  };

  const validateFeatureName = (value: StoreValue): Promise<string | void> => {
    if (value && String(value).length > 120) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Feature name is limited to 120 symbols");
    }
    return Promise.resolve();
  };

  const validateFeatureDescription = (value: StoreValue): Promise<string | void> => {
    if (value && String(value).length > 5000) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Feature description is limited to 5000 symbols");
    }
    return Promise.resolve();
  };

  return (
    <Drawer
      title={selectedFeature ? "Edit feature" : "Add feature"}
      placement="right"
      open={isOpen}
      width="99%"
      onClose={() => closeDrawer(false)}
      keyboard={false}
      maskClosable={false}
      extra={
        <Button type="primary" size="large" className={styles["save-button"]} onClick={onSave}>
          Save
        </Button>
      }
    >
      <Form form={form} className={styles["form-container"]} layout="vertical">
        <Form.Item
          name="name"
          label="Feature name"
          rules={[
            { required: true, message: "Feature name is required" },
            () => ({
              validator(_, value) {
                return validateFeatureName(value);
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="code" label="Code" rules={[{ required: true, message: "Code is required" }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: "Description is required" },
            () => ({
              validator(_, value) {
                return validateFeatureDescription(value);
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
