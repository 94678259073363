import React, { FC, useEffect, useMemo } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm, DynamicSwitchRule } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import {
  checkboxFieldsPage11,
  checkHeader,
  getCardMonthlyLimitsPreparedValues,
  switchHeader11,
} from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionCardLimitsMountlyPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      "nic-c-m-cash-for-enable": true,
      "nic-c-m-cash-for-max-am": formDisabledFn("nic-c-m-cash-for-max-am", "500000"),
      "nic-c-m-cash-for-max-nr": formDisabledFn("nic-c-m-cash-for-max-nr", "500"),
      "nic-c-m-cash-for-max-single": formDisabledFn("nic-c-m-cash-for-max-single", "500000"),
      "nic-c-m-cash-enable": true,
      "nic-c-m-cash-max-am": formDisabledFn("nic-c-m-cash-max-am", "500000"),
      "nic-c-m-cash-max-nr": formDisabledFn("nic-c-m-cash-max-nr", "500"),
      "nic-c-m-cash-max-single": formDisabledFn("nic-c-m-cash-max-single", "500000"),
      "nic-c-m-retail-for-enable": true,
      "nic-c-m-retail-for-max-am": formDisabledFn("nic-c-m-retail-for-max-am", "500000"),
      "nic-c-m-retail-for-max-nr": formDisabledFn("nic-c-m-retail-for-max-nr", "500"),
      "nic-c-m-retail-for-max-single": formDisabledFn("nic-c-m-retail-for-max-single", "500000"),
      "nic-c-m-retail-enable": true,
      "nic-c-m-retail-max-am": formDisabledFn("nic-c-m-retail-max-am", "500000"),
      "nic-c-m-retail-max-nr": formDisabledFn("nic-c-m-retail-max-nr", "500"),
      "nic-c-m-retail-max-single": formDisabledFn("nic-c-m-retail-max-single", "500000"),
      "nic-c-m-total-enable": true,
      "nic-c-m-total-max-am": formDisabledFn("nic-c-m-total-max-am", "500000"),
      "nic-c-m-total-max-nr": formDisabledFn("nic-c-m-total-max-nr", "501"),
      "nic-c-m-total-max-single": formDisabledFn("nic-c-m-total-max-single", "500000"),
    });

    Object.keys(checkboxFieldsPage11).forEach(key => {
      checkHeader(key, checkboxFieldsPage11, form);
    });

    checkHeader("card-monthly-all-cash-enable", switchHeader11, form);
    checkHeader("card-monthly-all-retail-enable", switchHeader11, form);
    checkHeader("card-monthly-all-total-enable", switchHeader11, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const { pctValues, showCash, showRetail, showTotal } = useMemo(() => {
    const pctValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues;

    return {
      pctValues,
      showCash: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("C"),
      showRetail: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("R"),
      showTotal: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("T"),
    };
  }, [wizardResponse?.product?.parameterTables]);

  const rules = useMemo(() => {
    const rules: Record<"cash" | "retail" | "total", DynamicSwitchRule[]> = {
      cash: [
        {
          name: "nic-c-m-cash-enable",
          label: "All",
          tooltip: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-m-cash-max-nr",
          maxAmount: "nic-c-m-cash-max-am",
          maxSingleAmount: "nic-c-m-cash-max-single",
        },
      ],
      retail: [
        {
          name: "nic-c-m-retail-enable",
          label: "All",
          tooltip: "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-m-retail-max-nr",
          maxAmount: "nic-c-m-retail-max-am",
          maxSingleAmount: "nic-c-m-retail-max-single",
        },
      ],
      total: [
        {
          name: "nic-c-m-total-enable",
          label: "All",
          tooltip:
            "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-m-total-max-nr",
          maxAmount: "nic-c-m-total-max-am",
          maxSingleAmount: "nic-c-m-total-max-single",
        },
      ],
    };

    if (pctValues?.find(x => x.fieldCode === "trans-lim-dom-for")?.value) {
      rules.cash.push({
        name: "nic-c-m-cash-for-enable",
        label: "Foreign",
        tooltip: "This limiter counts all foreign cash transactions. Turn it off, if restrictions are not intended.",
        maxNumber: "nic-c-m-cash-for-max-nr",
        maxAmount: "nic-c-m-cash-for-max-am",
        maxSingleAmount: "nic-c-m-cash-for-max-single",
      });

      rules.retail.push({
        name: "nic-c-m-retail-for-enable",
        label: "Foreign",
        tooltip: "This limiter counts foreign retail transactions. Turn it off, if restrictions are not intended.",
        maxNumber: "nic-c-m-retail-for-max-nr",
        maxAmount: "nic-c-m-retail-for-max-am",
        maxSingleAmount: "nic-c-m-retail-for-max-single",
      });
    }

    return rules;
  }, [pctValues]);

  const onFinish = (value: FormValues): void => {
    let actualValues: { [key: string]: string } = {};

    actualValues = {
      ...actualValues,
      ...getCardMonthlyLimitsPreparedValues(value as { [key: string]: string | boolean }),
    };

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: actualValues,
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Card Limits - Monthly"
      pageSubtitle="Card monthly limits are applied in conjunction with card daily and account daily, which ever limit is
      reached first."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      {showCash && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-monthly-all-cash-enable"
          title="Cash"
          tooltip="Turns off all cash limits."
          rules={rules.cash}
          disabled={formDisabled}
        />
      )}

      {showRetail && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-monthly-all-retail-enable"
          title="Retail"
          tooltip="Turns off all retail transactions."
          rules={rules.retail}
          disabled={formDisabled}
        />
      )}

      {showTotal && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-monthly-all-total-enable"
          title="Total"
          tooltip="Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended."
          rules={rules.total}
          disabled={formDisabled}
        />
      )}
    </CustomFormWrapper>
  );
};
