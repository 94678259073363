/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PutQpChangeRequest } from '../model';
// @ts-ignore
import { QpChange } from '../model';
// @ts-ignore
import { QpChangePageData } from '../model';
// @ts-ignore
import { QpDashboardPageData } from '../model';
// @ts-ignore
import { QpParmCode } from '../model';
// @ts-ignore
import { QpParmType } from '../model';
// @ts-ignore
import { QpQuest } from '../model';
// @ts-ignore
import { QpRowkey } from '../model';
// @ts-ignore
import { RevertQpChangesRequest } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
// @ts-ignore
import { TenantExport } from '../model';
/**
 * QpApi - axios parameter creator
 * @export
 */
export const QpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns tenant info from w4
         * @summary Export tenant by external code
         * @param {string} externalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export: async (externalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalCode' is not null or undefined
            assertParamExists('_export', 'externalCode', externalCode)
            const localVarPath = `/api/v1/qp/exports/tenants/{externalCode}`
                .replace(`{${"externalCode"}}`, encodeURIComponent(String(externalCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirms qp changes related to specified fiCode into w4 
         * @summary Confirms qp changes into w4
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirm: async (fiCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiCode' is not null or undefined
            assertParamExists('confirm', 'fiCode', fiCode)
            const localVarPath = `/api/v1/qp/{fiCode}/confirm`
                .replace(`{${"fiCode"}}`, encodeURIComponent(String(fiCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns QP changes on specified fiCode matching with specified filters
         * @summary Gets Qp changes on specified fiCode
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChanges: async (sortedFilteredPageRequest: SortedFilteredPageRequest, fiCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getChanges', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'fiCode' is not null or undefined
            assertParamExists('getChanges', 'fiCode', fiCode)
            const localVarPath = `/api/v1/qp/{fiCode}/changes`
                .replace(`{${"fiCode"}}`, encodeURIComponent(String(fiCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all existing QP Parameter Codes
         * @summary Gets QP Parameter Codes
         * @param {string} fiCode 
         * @param {string} parmType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParmCodes: async (fiCode: string, parmType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiCode' is not null or undefined
            assertParamExists('getParmCodes', 'fiCode', fiCode)
            // verify required parameter 'parmType' is not null or undefined
            assertParamExists('getParmCodes', 'parmType', parmType)
            const localVarPath = `/api/v1/qp/parm-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fiCode !== undefined) {
                localVarQueryParameter['fiCode'] = fiCode;
            }

            if (parmType !== undefined) {
                localVarQueryParameter['parmType'] = parmType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all existing QP Parameter Types
         * @summary Gets QP Parameter Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParmTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/qp/parm-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns QP data for dashboard matching with specified filters
         * @summary Gets QP data for dashboard
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQpDashboard: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getQpDashboard', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/qp/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all existing QP Questionnaires
         * @summary Gets QP Questionnaires
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaires: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/qp/quests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all existing QP Rowkeys
         * @summary Gets QP Rowkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRowkey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/qp/rowkeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edits Qp change or adds it if not exists
         * @summary Edits Qp change
         * @param {PutQpChangeRequest} putQpChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put: async (putQpChangeRequest: PutQpChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putQpChangeRequest' is not null or undefined
            assertParamExists('put', 'putQpChangeRequest', putQpChangeRequest)
            const localVarPath = `/api/v1/qp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putQpChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reverts non-confirmed qp changes specified in body
         * @summary Reverts non-confirmed qp changes
         * @param {RevertQpChangesRequest} revertQpChangesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revert: async (revertQpChangesRequest: RevertQpChangesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revertQpChangesRequest' is not null or undefined
            assertParamExists('revert', 'revertQpChangesRequest', revertQpChangesRequest)
            const localVarPath = `/api/v1/qp/revert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revertQpChangesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QpApi - functional programming interface
 * @export
 */
export const QpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QpApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns tenant info from w4
         * @summary Export tenant by external code
         * @param {string} externalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _export(externalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._export(externalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirms qp changes related to specified fiCode into w4 
         * @summary Confirms qp changes into w4
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirm(fiCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QpChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirm(fiCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns QP changes on specified fiCode matching with specified filters
         * @summary Gets Qp changes on specified fiCode
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChanges(sortedFilteredPageRequest: SortedFilteredPageRequest, fiCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QpChangePageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChanges(sortedFilteredPageRequest, fiCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all existing QP Parameter Codes
         * @summary Gets QP Parameter Codes
         * @param {string} fiCode 
         * @param {string} parmType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParmCodes(fiCode: string, parmType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QpParmCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParmCodes(fiCode, parmType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all existing QP Parameter Types
         * @summary Gets QP Parameter Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParmTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QpParmType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParmTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns QP data for dashboard matching with specified filters
         * @summary Gets QP data for dashboard
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQpDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QpDashboardPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQpDashboard(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all existing QP Questionnaires
         * @summary Gets QP Questionnaires
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionnaires(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QpQuest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionnaires(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all existing QP Rowkeys
         * @summary Gets QP Rowkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRowkey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QpRowkey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRowkey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edits Qp change or adds it if not exists
         * @summary Edits Qp change
         * @param {PutQpChangeRequest} putQpChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async put(putQpChangeRequest: PutQpChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QpChange>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.put(putQpChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reverts non-confirmed qp changes specified in body
         * @summary Reverts non-confirmed qp changes
         * @param {RevertQpChangesRequest} revertQpChangesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revert(revertQpChangesRequest: RevertQpChangesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QpChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revert(revertQpChangesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QpApi - factory interface
 * @export
 */
export const QpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QpApiFp(configuration)
    return {
        /**
         * Returns tenant info from w4
         * @summary Export tenant by external code
         * @param {string} externalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export(externalCode: string, options?: any): AxiosPromise<TenantExport> {
            return localVarFp._export(externalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirms qp changes related to specified fiCode into w4 
         * @summary Confirms qp changes into w4
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirm(fiCode: string, options?: any): AxiosPromise<Array<QpChange>> {
            return localVarFp.confirm(fiCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns QP changes on specified fiCode matching with specified filters
         * @summary Gets Qp changes on specified fiCode
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {string} fiCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChanges(sortedFilteredPageRequest: SortedFilteredPageRequest, fiCode: string, options?: any): AxiosPromise<QpChangePageData> {
            return localVarFp.getChanges(sortedFilteredPageRequest, fiCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all existing QP Parameter Codes
         * @summary Gets QP Parameter Codes
         * @param {string} fiCode 
         * @param {string} parmType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParmCodes(fiCode: string, parmType: string, options?: any): AxiosPromise<Array<QpParmCode>> {
            return localVarFp.getParmCodes(fiCode, parmType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all existing QP Parameter Types
         * @summary Gets QP Parameter Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParmTypes(options?: any): AxiosPromise<Array<QpParmType>> {
            return localVarFp.getParmTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns QP data for dashboard matching with specified filters
         * @summary Gets QP data for dashboard
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQpDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<QpDashboardPageData> {
            return localVarFp.getQpDashboard(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all existing QP Questionnaires
         * @summary Gets QP Questionnaires
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionnaires(options?: any): AxiosPromise<Array<QpQuest>> {
            return localVarFp.getQuestionnaires(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all existing QP Rowkeys
         * @summary Gets QP Rowkeys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRowkey(options?: any): AxiosPromise<Array<QpRowkey>> {
            return localVarFp.getRowkey(options).then((request) => request(axios, basePath));
        },
        /**
         * Edits Qp change or adds it if not exists
         * @summary Edits Qp change
         * @param {PutQpChangeRequest} putQpChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put(putQpChangeRequest: PutQpChangeRequest, options?: any): AxiosPromise<QpChange> {
            return localVarFp.put(putQpChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Reverts non-confirmed qp changes specified in body
         * @summary Reverts non-confirmed qp changes
         * @param {RevertQpChangesRequest} revertQpChangesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revert(revertQpChangesRequest: RevertQpChangesRequest, options?: any): AxiosPromise<Array<QpChange>> {
            return localVarFp.revert(revertQpChangesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QpApi - interface
 * @export
 * @interface QpApi
 */
export interface QpApiInterface {
    /**
     * Returns tenant info from w4
     * @summary Export tenant by external code
     * @param {string} externalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    _export(externalCode: string, options?: AxiosRequestConfig): AxiosPromise<TenantExport>;

    /**
     * Confirms qp changes related to specified fiCode into w4 
     * @summary Confirms qp changes into w4
     * @param {string} fiCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    confirm(fiCode: string, options?: AxiosRequestConfig): AxiosPromise<Array<QpChange>>;

    /**
     * Returns QP changes on specified fiCode matching with specified filters
     * @summary Gets Qp changes on specified fiCode
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {string} fiCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    getChanges(sortedFilteredPageRequest: SortedFilteredPageRequest, fiCode: string, options?: AxiosRequestConfig): AxiosPromise<QpChangePageData>;

    /**
     * Returns all existing QP Parameter Codes
     * @summary Gets QP Parameter Codes
     * @param {string} fiCode 
     * @param {string} parmType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    getParmCodes(fiCode: string, parmType: string, options?: AxiosRequestConfig): AxiosPromise<Array<QpParmCode>>;

    /**
     * Returns all existing QP Parameter Types
     * @summary Gets QP Parameter Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    getParmTypes(options?: AxiosRequestConfig): AxiosPromise<Array<QpParmType>>;

    /**
     * Returns QP data for dashboard matching with specified filters
     * @summary Gets QP data for dashboard
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    getQpDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<QpDashboardPageData>;

    /**
     * Returns all existing QP Questionnaires
     * @summary Gets QP Questionnaires
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    getQuestionnaires(options?: AxiosRequestConfig): AxiosPromise<Array<QpQuest>>;

    /**
     * Returns all existing QP Rowkeys
     * @summary Gets QP Rowkeys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    getRowkey(options?: AxiosRequestConfig): AxiosPromise<Array<QpRowkey>>;

    /**
     * Edits Qp change or adds it if not exists
     * @summary Edits Qp change
     * @param {PutQpChangeRequest} putQpChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    put(putQpChangeRequest: PutQpChangeRequest, options?: AxiosRequestConfig): AxiosPromise<QpChange>;

    /**
     * Reverts non-confirmed qp changes specified in body
     * @summary Reverts non-confirmed qp changes
     * @param {RevertQpChangesRequest} revertQpChangesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApiInterface
     */
    revert(revertQpChangesRequest: RevertQpChangesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<QpChange>>;

}

/**
 * QpApi - object-oriented interface
 * @export
 * @class QpApi
 * @extends {BaseAPI}
 */
export class QpApi extends BaseAPI implements QpApiInterface {
    /**
     * Returns tenant info from w4
     * @summary Export tenant by external code
     * @param {string} externalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public _export(externalCode: string, options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration)._export(externalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirms qp changes related to specified fiCode into w4 
     * @summary Confirms qp changes into w4
     * @param {string} fiCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public confirm(fiCode: string, options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).confirm(fiCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns QP changes on specified fiCode matching with specified filters
     * @summary Gets Qp changes on specified fiCode
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {string} fiCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public getChanges(sortedFilteredPageRequest: SortedFilteredPageRequest, fiCode: string, options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).getChanges(sortedFilteredPageRequest, fiCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all existing QP Parameter Codes
     * @summary Gets QP Parameter Codes
     * @param {string} fiCode 
     * @param {string} parmType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public getParmCodes(fiCode: string, parmType: string, options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).getParmCodes(fiCode, parmType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all existing QP Parameter Types
     * @summary Gets QP Parameter Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public getParmTypes(options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).getParmTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns QP data for dashboard matching with specified filters
     * @summary Gets QP data for dashboard
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public getQpDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).getQpDashboard(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all existing QP Questionnaires
     * @summary Gets QP Questionnaires
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public getQuestionnaires(options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).getQuestionnaires(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all existing QP Rowkeys
     * @summary Gets QP Rowkeys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public getRowkey(options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).getRowkey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edits Qp change or adds it if not exists
     * @summary Edits Qp change
     * @param {PutQpChangeRequest} putQpChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public put(putQpChangeRequest: PutQpChangeRequest, options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).put(putQpChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reverts non-confirmed qp changes specified in body
     * @summary Reverts non-confirmed qp changes
     * @param {RevertQpChangesRequest} revertQpChangesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QpApi
     */
    public revert(revertQpChangesRequest: RevertQpChangesRequest, options?: AxiosRequestConfig) {
        return QpApiFp(this.configuration).revert(revertQpChangesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
