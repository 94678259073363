import { FC, useEffect, useMemo, useState } from "react";
import { Button, Collapse, Divider, Form, Input, Mentions, Select } from "antd";
import { Option } from "antd/es/mentions";
import { FormInstance } from "antd/lib/form/hooks/useForm";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { defaultLanguageListOptions } from "@ni/common/mocks";
import { SmsVariablePreview } from "@ni/common/ui";
import { displayReadableSmsVariables } from "@ni/common/utils";
import { ConfigurationApi } from "@ni/sdk/apis";
import {
  Event,
  EventGroup,
  EventSubgroup,
  PatchEventSubgroupRequest,
  PatchTemplateRequest,
  Variable,
} from "@ni/sdk/models";

import EventCard from "../EventCard";

import styles from "./styles.module.scss";

const { Panel } = Collapse;
const configurationServiceApi = new ConfigurationApi();

interface EventSubGroupEditProps {
  eventSubgroup: EventSubgroup;
  form: FormInstance;
  templateId: number;
  getEventSubGroups: () => void;
  newEventSubgroup: boolean;
  smsTemplates: EventGroup[];
  eventSubGroupItems: EventSubgroup[];
  smsVariableList: Variable[];
}

const emptyEventConfig = {
  applyConditions: "",
  code: "",
  eventName: "",
  eventSubgroupId: undefined,
  isGeneral: false,
  id: undefined,
};

const EventSubGroupEdit: FC<EventSubGroupEditProps> = ({
  form,
  eventSubgroup,
  templateId,
  getEventSubGroups,
  newEventSubgroup,
  smsTemplates,
  eventSubGroupItems,
  smsVariableList,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventItems, setEventItems] = useReduxState<Event[]>("eventItems", []);
  const [isSubGroupEnabled, setIsSubGroupEnabled] = useReduxState<boolean>("isSubGroupEnabled", false);
  const [isSubGroupTemplateEnabled, setIsSubGroupTemplateEnabled] = useReduxState<boolean>(
    "isSubGroupTemplateEnabled",
    false,
  );
  const text = Form.useWatch<string>("messageContent", form);

  const [, setSelectedEventSubGroup] = useReduxState<EventSubgroup>("selectedEventSubGroup", {} as EventSubgroup);

  useEffect(() => {
    if (!newEventSubgroup) {
      form.setFieldsValue({
        eventGroup: templateId,
        name: eventSubgroup?.name,
        applyConditions: eventSubgroup?.applyConditions,
        order: eventSubgroup?.order,
        id: eventSubgroup?.id,
        eventGroupId: eventSubgroup?.eventGroupId,
        messageContent: eventSubgroup?.template?.fieldValue,
      });
    } else {
      form.setFieldsValue({
        eventGroup: templateId,
        name: "",
        applyConditions: "",
        order: undefined,
        id: undefined,
        eventGroupId: undefined,
        messageContent: "",
      });
    }
  }, [eventSubgroup?.applyConditions, eventSubgroup?.name, form, newEventSubgroup, templateId]);

  useEffect(() => {
    setEventItems(eventSubgroup?.events || []);
  }, [eventSubgroup?.events, setEventItems]);

  const onAddElement = () => {
    setEventItems([
      ...eventItems,
      {
        ...emptyEventConfig,
        eventName: "New Event",
        eventSubgroupId: eventSubgroup.id,
        id: undefined,
      },
    ]);
  };

  const onEditEventSubGroup = () => {
    setIsLoading(true);

    const eventSubGroupItem: PatchEventSubgroupRequest = {
      applyConditions: form.getFieldValue("applyConditions") as string,
      eventGroupId: form.getFieldValue("eventGroup") as number,
      events: eventSubgroup.events as Event[],
      name: form.getFieldValue("name") as string,
      order: form.getFieldValue("order") as number,
    };

    configurationServiceApi
      .patchEventSubgroup(eventSubGroupItem, eventSubgroup.eventGroupId, eventSubgroup?.id)
      .then(response => {
        setSelectedEventSubGroup(response.data);
        setIsLoading(false);
        setIsSubGroupEnabled(false);
        getEventSubGroups();
      })
      .catch(() => {
        setIsLoading(false);
        setIsSubGroupEnabled(false);
      });
  };

  const onChangeValue = (value: string) => {
    form?.setFieldsValue({
      messageContent: value.replace("%%", "%"),
    });
  };

  const onSaveTemplate = () => {
    setIsLoading(true);

    const requestBody: PatchTemplateRequest = {
      code: "GENERAL",
      fieldCode: "nic-sms-t-fi-str1",
      fieldValue: String(form.getFieldValue("messageContent")),
    };

    configurationServiceApi
      .patchTemplate(
        requestBody,
        form.getFieldValue("eventGroup") as number,
        eventSubgroup.id,
        Number(eventSubgroup.template?.id),
      )
      .then(() => {
        setIsLoading(false);
        setIsSubGroupTemplateEnabled(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsSubGroupTemplateEnabled(false);
      });
  };

  const newEventSubGroupForm = () => {
    return (
      <div className={styles["page-settings-wrapper"]}>
        <div className={styles["title"]}>SUBGroup settings</div>
        <Form.Item name="eventGroup" label="Add to event group" required={true}>
          <Select>
            {smsTemplates.map((item: EventGroup) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="name" label="Subgroup name" required={true}>
          <Input />
        </Form.Item>
        <Form.Item name="applyConditions" label="Apply conditions">
          <Input />
        </Form.Item>
        <div className={styles["title"]}>general SMS template</div>
        <Form.Item
          className={styles["form-item"]}
          name="messageContent"
          label="Message content"
          tooltip={{
            title: "Message text",
            icon: <QuestionCircleFilled />,
          }}
        >
          <Mentions rows={6} prefix="%" onChange={onChangeValue}>
            {smsVariableList?.map(smsVariable => (
              <Option value={smsVariable.code} key={String(smsVariable.id)}>
                {smsVariable.description}
              </Option>
            ))}
          </Mentions>
        </Form.Item>
      </div>
    );
  };

  const extraHeader = () => <div className={styles["extra-header"]}>Template</div>;

  const { decodedText, maxLength } = useMemo(() => {
    const decodedText = displayReadableSmsVariables(text ?? "", smsVariableList);
    return { decodedText, maxLength: decodedText?.length >= 512 ? decodedText.length : undefined };
  }, [text, smsVariableList]);

  if (newEventSubgroup) {
    return newEventSubGroupForm();
  }

  return (
    <div className={styles["page-settings-wrapper-edit"]}>
      <div className={styles["one-line"]}>
        <Form.Item name="id" hidden={true} />
        <Form.Item name="order" hidden={true} />
        <Form.Item name="eventGroup" label="Add to event group" required={true}>
          <Select>
            {smsTemplates.map((item: EventGroup) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="name" label="Name" required={true}>
          <Input />
        </Form.Item>
      </div>
      <Form.Item name="applyConditions" label="Apply conditions">
        <Input />
      </Form.Item>
      <Button
        loading={isLoading}
        disabled={!isSubGroupEnabled}
        className={styles["event-sub-group-save-button"]}
        type="primary"
        size="large"
        onClick={onEditEventSubGroup}
      >
        Save
      </Button>
      <div className={styles["template"]}>
        <Collapse defaultActiveKey={["1"]} bordered={false}>
          <Panel header={extraHeader()} key="1">
            <Form.Item name="defaultLanguage" label="Default language">
              <Select disabled={true}>
                {defaultLanguageListOptions.map(item => (
                  <Select.Option key={item.value as string} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles["form-item"]}
              name="messageContent"
              label="Message content"
              tooltip={{
                title:
                  "Specify the text of SMS. If you want to add a variable, start typing % and select it from drop-down list",
                icon: <QuestionCircleFilled />,
              }}
            >
              <Mentions
                rows={6}
                prefix="%"
                maxLength={maxLength}
                onChange={onChangeValue}
                options={smsVariableList?.map(smsVariable => ({
                  key: smsVariable?.code,
                  label: smsVariable.description,
                  value: smsVariable?.code,
                }))}
              />
            </Form.Item>
            <Form.Item dependencies={["fieldValue"]}>
              {() => (
                <Form.Item
                  className={styles["form-item"]}
                  name="messageDisplayedText"
                  label="Message displayed text"
                  tooltip={{ title: "Message displayed text", icon: <QuestionCircleFilled /> }}
                >
                  <SmsVariablePreview text={decodedText} />
                </Form.Item>
              )}
            </Form.Item>
            <Button
              loading={isLoading}
              disabled={!isSubGroupTemplateEnabled}
              className={styles["event-sub-group-save-button"]}
              type="primary"
              size="large"
              onClick={onSaveTemplate}
            >
              Save template
            </Button>
          </Panel>
        </Collapse>
      </div>
      <Divider />
      <div className={styles["event"]}>
        <div className={styles["event-header-block"]}>
          <div className={styles["title"]}>EVENTS</div>
          <div className={styles["event-details-form-buttons"]}>
            <Button
              className={styles["button"]}
              type="primary"
              size="large"
              htmlType="button"
              onClick={onAddElement}
              loading={isLoading}
            >
              Add event
            </Button>
          </div>
        </div>
        <div className={styles["event-list"]}>
          {eventItems.map(event => (
            <EventCard
              key={event.id ?? `new-${event.eventName ?? "Event"}`}
              event={event}
              id={String(event.id)}
              eventSubGroupItems={eventSubGroupItems}
              templateId={templateId}
              eventSubGroupId={eventSubgroup?.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventSubGroupEdit;
