/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeFinancialInstitutionRequest } from '../model';
// @ts-ignore
import { CreateFinancialInstitutionRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { FinancialInstitution } from '../model';
// @ts-ignore
import { FinancialInstitutionPageDataResponse } from '../model';
// @ts-ignore
import { PageDataFullDashboardProduct } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
// @ts-ignore
import { UserPageDataResponse } from '../model';
/**
 * FinancialInstitutionApi - axios parameter creator
 * @export
 */
export const FinancialInstitutionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates Financial Institution and returns created one with location
         * @summary Create Financial Institution
         * @param {CreateFinancialInstitutionRequest} createFinancialInstitutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createFinancialInstitutionRequest: CreateFinancialInstitutionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFinancialInstitutionRequest' is not null or undefined
            assertParamExists('create', 'createFinancialInstitutionRequest', createFinancialInstitutionRequest)
            const localVarPath = `/api/v1/financial-institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFinancialInstitutionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates Financial Institution and returns updated one
         * @summary Edit Financial Institution
         * @param {ChangeFinancialInstitutionRequest} changeFinancialInstitutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit: async (changeFinancialInstitutionRequest: ChangeFinancialInstitutionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeFinancialInstitutionRequest' is not null or undefined
            assertParamExists('edit', 'changeFinancialInstitutionRequest', changeFinancialInstitutionRequest)
            const localVarPath = `/api/v1/financial-institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeFinancialInstitutionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Financial Institutions
         * @summary Get Financial Institutions
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (pageSize: number, pageNumber: number, search?: string, sortDirection?: 'ASC' | 'DESC', sortProperty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('get', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('get', 'pageNumber', pageNumber)
            const localVarPath = `/api/v1/financial-institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sortProperty'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Financial Institution by ID
         * @summary Get Financial Institution by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById4: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById4', 'id', id)
            const localVarPath = `/api/v1/financial-institutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Products Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} financialInstitutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardProducts1: async (sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getDashboardProducts1', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'financialInstitutionId' is not null or undefined
            assertParamExists('getDashboardProducts1', 'financialInstitutionId', financialInstitutionId)
            const localVarPath = `/api/v1/financial-institutions/{financialInstitutionId}/products/dashboard`
                .replace(`{${"financialInstitutionId"}}`, encodeURIComponent(String(financialInstitutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Users fetching by Financial Institution Id with specified request criteria
         * @summary Get Users info by Financial Institution Id
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} financialInstitutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByFinancialInstitutionId: async (sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getUsersByFinancialInstitutionId', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            // verify required parameter 'financialInstitutionId' is not null or undefined
            assertParamExists('getUsersByFinancialInstitutionId', 'financialInstitutionId', financialInstitutionId)
            const localVarPath = `/api/v1/financial-institutions/{financialInstitutionId}/users/dashboard`
                .replace(`{${"financialInstitutionId"}}`, encodeURIComponent(String(financialInstitutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialInstitutionApi - functional programming interface
 * @export
 */
export const FinancialInstitutionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialInstitutionApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates Financial Institution and returns created one with location
         * @summary Create Financial Institution
         * @param {CreateFinancialInstitutionRequest} createFinancialInstitutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createFinancialInstitutionRequest: CreateFinancialInstitutionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialInstitution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createFinancialInstitutionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates Financial Institution and returns updated one
         * @summary Edit Financial Institution
         * @param {ChangeFinancialInstitutionRequest} changeFinancialInstitutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit(changeFinancialInstitutionRequest: ChangeFinancialInstitutionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialInstitution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit(changeFinancialInstitutionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Financial Institutions
         * @summary Get Financial Institutions
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(pageSize: number, pageNumber: number, search?: string, sortDirection?: 'ASC' | 'DESC', sortProperty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialInstitutionPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(pageSize, pageNumber, search, sortDirection, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Financial Institution by ID
         * @summary Get Financial Institution by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById4(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancialInstitution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById4(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Products Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} financialInstitutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardProducts1(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataFullDashboardProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardProducts1(sortedFilteredPageRequest, financialInstitutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Users fetching by Financial Institution Id with specified request criteria
         * @summary Get Users info by Financial Institution Id
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} financialInstitutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByFinancialInstitutionId(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPageDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByFinancialInstitutionId(sortedFilteredPageRequest, financialInstitutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinancialInstitutionApi - factory interface
 * @export
 */
export const FinancialInstitutionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialInstitutionApiFp(configuration)
    return {
        /**
         * Creates Financial Institution and returns created one with location
         * @summary Create Financial Institution
         * @param {CreateFinancialInstitutionRequest} createFinancialInstitutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createFinancialInstitutionRequest: CreateFinancialInstitutionRequest, options?: any): AxiosPromise<FinancialInstitution> {
            return localVarFp.create(createFinancialInstitutionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates Financial Institution and returns updated one
         * @summary Edit Financial Institution
         * @param {ChangeFinancialInstitutionRequest} changeFinancialInstitutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit(changeFinancialInstitutionRequest: ChangeFinancialInstitutionRequest, options?: any): AxiosPromise<FinancialInstitution> {
            return localVarFp.edit(changeFinancialInstitutionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Financial Institutions
         * @summary Get Financial Institutions
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} [search] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [sortProperty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(pageSize: number, pageNumber: number, search?: string, sortDirection?: 'ASC' | 'DESC', sortProperty?: string, options?: any): AxiosPromise<FinancialInstitutionPageDataResponse> {
            return localVarFp.get(pageSize, pageNumber, search, sortDirection, sortProperty, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Financial Institution by ID
         * @summary Get Financial Institution by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById4(id: number, options?: any): AxiosPromise<FinancialInstitution> {
            return localVarFp.getById4(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
         * @summary Get Dashboard Products Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} financialInstitutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardProducts1(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: any): AxiosPromise<PageDataFullDashboardProduct> {
            return localVarFp.getDashboardProducts1(sortedFilteredPageRequest, financialInstitutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Users fetching by Financial Institution Id with specified request criteria
         * @summary Get Users info by Financial Institution Id
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {number} financialInstitutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByFinancialInstitutionId(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: any): AxiosPromise<UserPageDataResponse> {
            return localVarFp.getUsersByFinancialInstitutionId(sortedFilteredPageRequest, financialInstitutionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinancialInstitutionApi - interface
 * @export
 * @interface FinancialInstitutionApi
 */
export interface FinancialInstitutionApiInterface {
    /**
     * Creates Financial Institution and returns created one with location
     * @summary Create Financial Institution
     * @param {CreateFinancialInstitutionRequest} createFinancialInstitutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApiInterface
     */
    create(createFinancialInstitutionRequest: CreateFinancialInstitutionRequest, options?: AxiosRequestConfig): AxiosPromise<FinancialInstitution>;

    /**
     * Updates Financial Institution and returns updated one
     * @summary Edit Financial Institution
     * @param {ChangeFinancialInstitutionRequest} changeFinancialInstitutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApiInterface
     */
    edit(changeFinancialInstitutionRequest: ChangeFinancialInstitutionRequest, options?: AxiosRequestConfig): AxiosPromise<FinancialInstitution>;

    /**
     * Returns Financial Institutions
     * @summary Get Financial Institutions
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApiInterface
     */
    get(pageSize: number, pageNumber: number, search?: string, sortDirection?: 'ASC' | 'DESC', sortProperty?: string, options?: AxiosRequestConfig): AxiosPromise<FinancialInstitutionPageDataResponse>;

    /**
     * Returns Financial Institution by ID
     * @summary Get Financial Institution by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApiInterface
     */
    getById4(id: number, options?: AxiosRequestConfig): AxiosPromise<FinancialInstitution>;

    /**
     * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Products Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} financialInstitutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApiInterface
     */
    getDashboardProducts1(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: AxiosRequestConfig): AxiosPromise<PageDataFullDashboardProduct>;

    /**
     * Returns Users fetching by Financial Institution Id with specified request criteria
     * @summary Get Users info by Financial Institution Id
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} financialInstitutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApiInterface
     */
    getUsersByFinancialInstitutionId(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: AxiosRequestConfig): AxiosPromise<UserPageDataResponse>;

}

/**
 * FinancialInstitutionApi - object-oriented interface
 * @export
 * @class FinancialInstitutionApi
 * @extends {BaseAPI}
 */
export class FinancialInstitutionApi extends BaseAPI implements FinancialInstitutionApiInterface {
    /**
     * Creates Financial Institution and returns created one with location
     * @summary Create Financial Institution
     * @param {CreateFinancialInstitutionRequest} createFinancialInstitutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApi
     */
    public create(createFinancialInstitutionRequest: CreateFinancialInstitutionRequest, options?: AxiosRequestConfig) {
        return FinancialInstitutionApiFp(this.configuration).create(createFinancialInstitutionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates Financial Institution and returns updated one
     * @summary Edit Financial Institution
     * @param {ChangeFinancialInstitutionRequest} changeFinancialInstitutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApi
     */
    public edit(changeFinancialInstitutionRequest: ChangeFinancialInstitutionRequest, options?: AxiosRequestConfig) {
        return FinancialInstitutionApiFp(this.configuration).edit(changeFinancialInstitutionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Financial Institutions
     * @summary Get Financial Institutions
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} [search] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [sortProperty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApi
     */
    public get(pageSize: number, pageNumber: number, search?: string, sortDirection?: 'ASC' | 'DESC', sortProperty?: string, options?: AxiosRequestConfig) {
        return FinancialInstitutionApiFp(this.configuration).get(pageSize, pageNumber, search, sortDirection, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Financial Institution by ID
     * @summary Get Financial Institution by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApi
     */
    public getById4(id: number, options?: AxiosRequestConfig) {
        return FinancialInstitutionApiFp(this.configuration).getById4(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns page of Dashboard Products for given page number, page size and sorting / filtering criteria
     * @summary Get Dashboard Products Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} financialInstitutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApi
     */
    public getDashboardProducts1(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: AxiosRequestConfig) {
        return FinancialInstitutionApiFp(this.configuration).getDashboardProducts1(sortedFilteredPageRequest, financialInstitutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Users fetching by Financial Institution Id with specified request criteria
     * @summary Get Users info by Financial Institution Id
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {number} financialInstitutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialInstitutionApi
     */
    public getUsersByFinancialInstitutionId(sortedFilteredPageRequest: SortedFilteredPageRequest, financialInstitutionId: number, options?: AxiosRequestConfig) {
        return FinancialInstitutionApiFp(this.configuration).getUsersByFinancialInstitutionId(sortedFilteredPageRequest, financialInstitutionId, options).then((request) => request(this.axios, this.basePath));
    }
}
