import { FC, ReactNode } from "react";
import { Space, Typography } from "antd";
import cn from "classnames";

import styles from "./styles.module.scss";

interface SectionProps {
  type?: "primary" | "secondary" | "default";
  title: ReactNode | string;
  description?: ReactNode | string;
  gap?: number;
  children: ReactNode | string;
}

const titleType = {
  primary: styles["type-primary"],
  secondary: styles["type-secondary"],
  default: styles["type-default"],
};

export const Section: FC<SectionProps> = ({ type = "secondary", title, description, gap = 40, children }) => {
  return (
    <Space direction="vertical" size={gap}>
      <Space direction="vertical" size={16}>
        <Typography.Title className={cn(styles["section-title"], titleType[type])} level={4}>
          {title}
        </Typography.Title>

        {description && <Typography.Text>{description}</Typography.Text>}
      </Space>

      {children}
    </Space>
  );
};
