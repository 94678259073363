/* eslint-disable no-control-regex */
import { FC, useEffect, useMemo, useState } from "react";
import { Button, Drawer, Form, Input, notification } from "antd";
import { useParams } from "react-router-dom";

import { SearchOutlined } from "@ant-design/icons";
import { ControlCharactersExceptEnterRegex } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { AfterWizardEditPageTitle, PageItemLayoutGeneral, TooltipInfo } from "@ni/common/ui";
import { ConfigurationApi, TenantApi, VariablesApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Event, EventGroup, EventSubgroup, Sms, Tenant, Variable } from "@ni/sdk/models";

import { EventGroupList, TemplateDetails } from "../../components";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();
const configurationServiceApi = new ConfigurationApi();
const variableServiceApi = new VariablesApi();

export const SmsTemplates: FC = () => {
  const [drawerForm] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [variablesList, setVariablesList] = useReduxState<(Variable | undefined)[]>("combinedVariablesDictionary", []);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const [search, setSearch] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [selectedSubgroup, setSelectedSubgroup] = useState<EventSubgroup>();

  const [, setGroupId] = useState<number>();

  const [selectedSms, setSelectedSms] = useState<Partial<Sms> | undefined>();

  const [visible, setVisible] = useState(false);

  const [eventGroupList, setEventGroupList] = useReduxState<EventGroup[]>("eventGroupList", []);

  const getEventName = (eventName: string) => {
    if (eventName !== undefined && eventName.includes("Block code")) {
      const bcCode = eventName.replace(/.*'(.)'.*/, "$1");
      const bcName = tenant.blockCodes?.find(bc => {
        return bc.code === bcCode && bc.fieldCode === "nic-bc-name";
      });
      return eventName.replace(/\(.*?\)/, bcName?.value || "");
    }
    return eventName;
  };

  useEffect(() => {
    setIsLoading(true);
    configurationServiceApi
      .getEventGroupListByTenantId(tenant.id!)
      .then(eventGroups => {
        setEventGroupList(
          eventGroups.data?.sort((group1, group2) => {
            return group1.order - group2.order;
          }),
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [setEventGroupList, setIsLoading, tenant.id]);

  useEffect(() => {
    if (!visible) {
      drawerForm.resetFields();
    }
  }, [drawerForm, visible]);

  useEffect(() => {
    if (selectedSms) {
      drawerForm.setFieldsValue({
        "default-lang": "English",
        fieldCode: selectedSms.fieldCode,
        fieldValue: selectedSms.fieldValue?.replace(`%%`, "%")?.replace(ControlCharactersExceptEnterRegex, ""),
        isActive: selectedSms.isActive,
      });
    }
  }, [drawerForm, selectedSms]);

  const onEditClickHandler = (event: Event, _groupId: number, smsDetails: Sms) => {
    setSelectedEvent(event);
    const group = eventGroupList?.filter(eventGroups => {
      return eventGroups.eventSubgroups?.some(eventSubgroups => eventSubgroups.id === event.eventSubgroupId);
    })[0];

    const subgroup = group?.eventSubgroups?.filter(eventSubgroups => eventSubgroups.id === event.eventSubgroupId)[0];
    setSelectedSubgroup(subgroup);

    if (smsDetails && Object.keys(smsDetails).length) {
      setSelectedSms(smsDetails);
    } else {
      setSelectedSms({
        isActive: event.isGeneral || false,
        fieldValue:
          subgroup?.template?.fieldValue?.replace(`%%`, "%")?.replace(ControlCharactersExceptEnterRegex, "") ?? "",
      });
    }

    setVisible(true);
  };

  const closeDrawerHandler = () => {
    setSelectedEvent(undefined);
    setGroupId(undefined);
    setSelectedSms(undefined);
    setVisible(false);
    setSelectedSubgroup(undefined);
  };

  const updateTenant = (requestBody: ChangeTenantRequest, tenantId: number) => {
    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        if (res?.data && requestBody?.sms) {
          setTenant({ ...tenant, sms: requestBody?.sms });
        } else {
          notification?.error({ message: "Update failed", description: "Something went wrong, please try again" });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const saveEmptySms = (event: Event, smsDetails: Sms) => {
    setIsLoading(true);

    const existingSMSs = tenant.sms?.slice() || [];
    const existingSmsIndex = existingSMSs?.findIndex(smsDetails1 => smsDetails1.id === smsDetails?.id);

    let requestBody: ChangeTenantRequest;

    if (existingSmsIndex && existingSmsIndex !== -1) {
      existingSMSs[existingSmsIndex] = {
        ...existingSMSs[existingSmsIndex],
        fieldCode: "nic-sms-t-fi-str1",
        fieldValue: smsDetails?.fieldValue?.replace(`%%`, "%")?.replace(ControlCharactersExceptEnterRegex, "") ?? "",
        isActive: smsDetails.isActive,
      };

      requestBody = {
        sms: existingSMSs,
      };
    } else {
      const newSms: Sms = {
        code: event?.code ?? "",
        fieldCode: "nic-sms-t-fi-str1",
        fieldValue: smsDetails?.fieldValue?.replace(`%%`, "%")?.replace(ControlCharactersExceptEnterRegex, "") ?? "",
        isActive: smsDetails.isActive,
        smsEvent: {
          id: event?.id,
          eventSubgroupId: event?.eventSubgroupId,
          code: event?.code,
          eventName: event?.eventName,
          applyConditions: event?.applyConditions,
          isGeneral: event?.isGeneral,
        } as Event,
      };
      requestBody = {
        sms: [...(tenant.sms || []), newSms],
      };
    }

    updateTenant(requestBody, parseInt(id ?? "0", 10));
  };

  const saveTenantSms = () => {
    setIsLoading(true);

    const existingSMSs = tenant.sms?.slice() || [];
    const existingSmsIndex = existingSMSs?.findIndex(smsDetails => smsDetails.id === selectedSms?.id);

    let requestBody: ChangeTenantRequest;
    if (existingSmsIndex && existingSmsIndex !== -1) {
      existingSMSs[existingSmsIndex] = {
        ...existingSMSs[existingSmsIndex],
        fieldCode: "nic-sms-t-fi-str1",
        fieldValue: String(drawerForm.getFieldValue("fieldValue"))
          .replace(`%%`, "%")
          .replace(ControlCharactersExceptEnterRegex, ""),
        isActive: drawerForm.getFieldValue("isActive") === true,
      };

      requestBody = {
        sms: existingSMSs,
      };
    } else {
      const smsEvent: Event = {
        id: selectedEvent?.id,
        eventSubgroupId: selectedEvent?.eventSubgroupId,
        code: selectedEvent?.code,
        eventName: selectedEvent?.eventName,
        applyConditions: selectedEvent?.applyConditions,
        isGeneral: selectedEvent?.isGeneral,
      };

      const newSms: Sms = {
        code: smsEvent.code ?? "",
        fieldCode: "nic-sms-t-fi-str1",
        fieldValue: String(drawerForm.getFieldValue("fieldValue"))
          .replace(`%%`, "%")
          .replace(ControlCharactersExceptEnterRegex, ""),
        isActive: drawerForm.getFieldValue("isActive") === true,
        smsEvent,
      };

      requestBody = {
        sms: [...(tenant.sms || []), newSms],
      };
    }

    updateTenant(requestBody, parseInt(id ?? "0", 10));
  };

  const GroupList = useMemo(() => {
    if (!search?.trim()) return eventGroupList;
    const idsSet = new Set();
    const idToSearchedStringRecored: Record<number, string> = {};
    const flatEventList = eventGroupList.flatMap(x =>
      x.eventSubgroups?.flatMap(y => ({
        codes: [...(y.events?.map(z => ` ${z.code} ${z.eventName} `) ?? []), ` ${y.name} `],
        id: y.eventGroupId,
      })),
    );

    flatEventList.forEach(item => {
      idToSearchedStringRecored[item?.id ?? 0] = [
        idToSearchedStringRecored[item?.id ?? 0] ?? "",
        ...(item?.codes ?? []),
      ]
        .join(" ")
        .toLowerCase();
    });

    Object.keys(idToSearchedStringRecored).forEach(groupId => {
      if (
        idToSearchedStringRecored[Number(groupId)].includes(search.trim().replace("(NIC-sms-mt).", "").toLowerCase())
      ) {
        idsSet.add(groupId);
      }
    });

    return eventGroupList.filter(group => idsSet.has(group.id.toString()));
  }, [search, eventGroupList]);

  useEffect(() => {
    void variableServiceApi.getVariableGroups().then(response => {
      if (response?.data?.length > 0) {
        setVariablesList(response?.data?.flatMap(x => x?.variables));
      }
    });
  }, [setVariablesList]);

  return (
    <div className={styles["sms-templates"]}>
      <PageItemLayoutGeneral>
        <div className={styles["page-title"]}>
          <AfterWizardEditPageTitle title="SMS Templates" />
        </div>

        <div className={styles["search-input-container"]}>
          <Input
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            placeholder="Search"
            prefix={<SearchOutlined />}
            className={styles["fc-filter-search"]}
          />
          <TooltipInfo
            tooltipProps={{
              title:
                "You can search by subgroup name or code and event name or code, search does not apply on main group names.",
            }}
          />
        </div>

        <div className="sms-templates-container">
          <EventGroupList
            items={GroupList}
            search={search?.toLowerCase()?.trim()?.replace("(nic-sms-mt).", "")}
            onEditClick={onEditClickHandler}
            onSwitchClick={saveEmptySms}
            smsList={tenant.sms}
            variablesList={variablesList}
            tenant={tenant}
          />
        </div>
      </PageItemLayoutGeneral>
      <div className="block-codes-drawer-wrapper">
        <Drawer
          title={`${selectedEvent?.isGeneral ? "General " : ""}Template for "${
            selectedEvent?.isGeneral ? selectedSubgroup?.name || "" : getEventName(selectedEvent?.eventName || "")
          }" ${selectedEvent?.isGeneral ? "group" : "event"}`}
          placement="right"
          open={visible}
          width="40%"
          onClose={closeDrawerHandler}
          keyboard={false}
          maskClosable={false}
          extra={
            <Button
              key={selectedEvent?.id}
              loading={isLoading}
              className={styles["block-codes-editing-submit-button"]}
              type="primary"
              size="large"
              onClick={saveTenantSms}
            >
              Save
            </Button>
          }
        >
          <TemplateDetails drawerForm={drawerForm} subgroupId={selectedSubgroup?.id} key={selectedEvent?.id} />
        </Drawer>
      </div>
    </div>
  );
};
