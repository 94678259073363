import { useEffect, useState } from "react";

import { checkIfOneProductHasFieldCodeValue } from "@ni/common/utils";
import { Tenant } from "@ni/sdk/models";

interface IShowFields {
  "nic-phys-restr-for-virt-use": boolean;
}

export const useShowFields = (tenant: Tenant, isLoading: boolean) => {
  const [showFields, setShowFields] = useState<IShowFields>({
    "nic-phys-restr-for-virt-use": false,
  });

  useEffect(() => {
    if (!isLoading && tenant) {
      setShowFields(prev => ({
        ...prev,
        "nic-phys-restr-for-virt-use": checkIfOneProductHasFieldCodeValue(tenant, "reiss-phys", "true"),
      }));
    }
  }, [tenant, isLoading]);

  return [showFields];
};
