type InputNumberInfo = {
  userTyping: boolean;
  input: string;
};

const regex = /\B(?=(\d{3})+(?!\d))/g;

export const numberFormatter = (
  value: string | number | undefined,
  info: InputNumberInfo,
  precision: number = 0,
  isBlurred?: boolean,
): string => {
  if (!value) return "";

  if (typeof value === "string") {
    value = value.replace(/^0+(?=\d)/, "");

    if (info.userTyping) {
      const parts = value.split(".");
      const integerPart = parts[0].replace(regex, " ");
      const decimalPart = parts[1] ? `.${parts[1]}` : "";
      return `${integerPart}${decimalPart}`;
    }

    if (value.includes(".")) {
      const [wholePart, decimalPart] = value.split(".");
      const formattedWholePart = wholePart.replace(regex, " ");
      const paddedDecimalPart = decimalPart.padEnd(precision, "0");
      return `${formattedWholePart}.${paddedDecimalPart}`;
    }

    const formattedWholePart = value.replace(regex, " ");

    if (precision > 0 && isBlurred) {
      return `${formattedWholePart}.`.padEnd(formattedWholePart.length + precision + 1, "0");
    }

    return formattedWholePart;
  }

  return value.toString().replace(regex, " ");
};

export const numberParser = (value: string | undefined) => value?.replace(/\$\s?|( *)/g, "") || "";
