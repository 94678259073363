import { DynamicSwitchRule } from "@ni/common/ui";

export const cashRules: DynamicSwitchRule[] = [
  {
    name: "c-b-cash-atm-enable",
    label: "ATM",
    tooltip: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-b-cash-atm-max-nr",
    maxAmount: "nic-c-b-cash-atm-max-am",
    maxSingleAmount: "nic-c-b-cash-atm-max-single",
  },
];

export const retailRules: DynamicSwitchRule[] = [
  {
    name: "c-b-retail-enable",
    label: "All",
    tooltip: "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-c-b-retail-max-nr",
    maxAmount: "nic-c-b-retail-max-am",
    maxSingleAmount: "nic-c-b-retail-max-single",
  },
];
