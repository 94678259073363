import { ProductValue } from "@ni/sdk/models";

import { isNotUndefined } from "./isNotUndefined";

export type FormValueReturnType = "string" | "boolean" | "number";

export const getFormValueFromProductValues = (
  productValues: ProductValue[] | undefined,
  field: ProductValue["fieldCode"],
  returnType: FormValueReturnType = "string",
): string | boolean | number => {
  if (!productValues) {
    return "";
  }
  const value = productValues.find(productValue => productValue.fieldCode === field)?.value;

  if (!isNotUndefined(value)) return "";

  switch (returnType) {
    case "string":
      return value;
    case "boolean":
      return value === "true";
    case "number":
      return parseInt(value, 10);
    default:
      return value;
  }
};
