import { FC } from "react";

import styles from "./styles.module.scss";

interface PurgeProps {
  isAfterWizard: boolean;
}
export const Purge: FC<PurgeProps> = ({ isAfterWizard }) => {
  return (
    <div className={isAfterWizard ? styles["after-wizard-form-smaller-width"] : ""}>
      Contract closure of any type will eventually lead to account purging process, which is also based upon the
      parameter &quot;Pre-purge period&quot;. Once the defined period concludes, the contract status will change to
      purged and will be excluded from any normal processing events and cannot be resumed again.
    </div>
  );
};
