interface OptionModel {
  value: string;
  label: string;
}

export const elementGroupOptions: OptionModel[] = [
  { label: "Simple inputs", value: "simple_inputs" },
  { label: "Visual only elements", value: "visual_only_elements" },
  { label: "Complex elements", value: "complex_elements" },
];

export const editableProductStates: OptionModel[] = [
  { label: "DRAFT", value: "DRAFT" },
  { label: "LOCKED", value: "LOCKED" },
  { label: "UAT_PENDING", value: "UAT_PENDING" },
  { label: "REJECTED", value: "REJECTED" },
  { label: "UAT", value: "UAT" },
  { label: "SUSPENDED", value: "SUSPENDED" },
  { label: "DEACTIVATED", value: "DEACTIVATED" },
  { label: "PRODUCTION_PENDING", value: "PRODUCTION_PENDING" },
  { label: "PRODUCTION", value: "PRODUCTION" },
  { label: "CHANGES_PENDING", value: "CHANGES_PENDING" },
  { label: "CHANGES_REJECTED", value: "CHANGES_REJECTED" },
];

export const validationTypes: OptionModel[] = [
  { label: "NOT_NULL", value: "NOT_NULL" },
  { label: "NULL", value: "NULL" },
  { label: "EQUALS", value: "EQUALS" },
  { label: "LESS", value: "LESS" },
  { label: "MORE", value: "MORE" },
  { label: "EQUALS_OR_LESS", value: "EQUALS_OR_LESS" },
  { label: "EQUALS_OR_MORE", value: "EQUALS_OR_MORE" },
  { label: "REGEX", value: "REGEX" },
  { label: "XQUERY", value: "XQUERY" },
];
