import { useMemo } from "react";
import { Form, Typography } from "antd";
import { checkEveryPctValue } from "libs/product-settings/src/lib/utils";
import { useParams } from "react-router-dom";

import { NIC_STMT_FEE_VAL, STMT_FEE_ENABLED } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TooltipInfo } from "@ni/common/ui";

import { usePct } from "../../../../hooks";

export const StatementFeePage = () => {
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [form] = Form.useForm();
  const isEnabled = Form.useWatch<boolean>(STMT_FEE_ENABLED, form);
  const feeValue = Form.useWatch<string>(NIC_STMT_FEE_VAL, form);

  const relatedLinks = useMemo(
    () => [
      {
        href: `/tenant/${tenantId}/product/${currentProduct.id || ""}/fees-settings`,
        label: `Fee Settings - ${currentProduct?.displayName || ""}`,
      },
      {
        href: `/tenant/${tenantId}/fees-tenant-configuration`,
        label: "Fee Settings - Global",
      },
    ],
    [currentProduct?.displayName, currentProduct.id, tenantId],
  );

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [STMT_FEE_ENABLED, NIC_STMT_FEE_VAL],
    },
  });

  const onFinish = async (): Promise<void> => {
    const productValues: FormValues = {};

    const values = isEnabled
      ? {
          [STMT_FEE_ENABLED]: String(isEnabled),
          [NIC_STMT_FEE_VAL]: String(feeValue)?.replace(/ /g, ""),
        }
      : { [STMT_FEE_ENABLED]: String(isEnabled), [NIC_STMT_FEE_VAL]: null };

    const isDisabledOnAllPcts = checkEveryPctValue({
      pctId,
      parameterTables: currentProduct.parameterTables,
      fieldCode: STMT_FEE_ENABLED,
      fieldValue: "false",
      newFieldValue: values[STMT_FEE_ENABLED],
    });

    if (isDisabledOnAllPcts) {
      productValues["nic-stmt-fee-flag"] = "";
    }

    await onUpdateProduct(productValues, undefined, {
      id: Number(pctId),
      values,
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Statement Fee"
      pageSubtitle={
        <div>
          <TooltipInfo
            largeLabel="Statement fee is the fixed amount to charge to the customer with the following statement flags: E-mail
        statement, Physical statement, or Both."
            tooltipProps={{
              title:
                "You can configure client statement flags which are applicable for the fee charging. For this purpose, visit product settings (Fees Product Configuration page)",
            }}
          />
          The statement fee will be posted on billing date.
        </div>
      }
      submitHandler={onFinish}
      size="md"
      formSize="md"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: STMT_FEE_ENABLED,
          label: <Typography.Text strong={true}>Enable Statement fee for your product</Typography.Text>,
          initialValue: false,
          valuePropName: "checked",
        }}
      />

      {isEnabled && (
        <NetworkForm.Number
          formItemOptions={{
            name: NIC_STMT_FEE_VAL,
            label: "Statement fee amount",
            rules: [
              {
                validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                message: "Minimum fee amount cannot be 0",
              },
              { required: isEnabled, message: "Statement fee amount is required" },
            ],
          }}
          precision={2}
          addonAfter={productCurrency}
          disabled={!isEnabled}
        />
      )}
    </CustomFormWrapper>
  );
};
