import { FC, ReactElement } from "react";
import { Typography } from "antd";

import styles from "./styles.module.scss";

interface PageHeadingProps {
  text: string;
  extraElements?: ReactElement[];
}

export const PageHeading: FC<PageHeadingProps> = ({ text, extraElements }) => {
  return (
    <div className={styles["heading-bar"]}>
      <Typography.Title level={2}>{text}</Typography.Title>
      {extraElements}
    </div>
  );
};
