import { useState } from "react";
import { useParams } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { DashboardFilters, DashboardTenantUI } from "@ni/common/types";
import { UserService } from "@ni/common/utils";
import { FinancialInstitutionApi, ProductApi } from "@ni/sdk/apis";

import { DashboardContentType } from "../constants";
import { prepareTenants } from "../utils";

const productServiceApi = new ProductApi();
const financialInstitutionServiceApi = new FinancialInstitutionApi();

type ViewType = "cards" | "table";

const checkIfUserDoesNotHaveTenants = (tenantsLength: number, dashboardFilter: DashboardFilters) => {
  const isInitialFilterEmpty = Object.keys(dashboardFilter).length === 0;
  return tenantsLength === 0 && isInitialFilterEmpty;
};

export const useDashboard = () => {
  const { id } = useParams<{ id: string }>();

  const [viewType, setViewType] = useReduxState<ViewType>("viewType", "cards");
  const [dashboardCards, setDasboardCards] = useReduxState<DashboardTenantUI[]>("dashboardCards", []);

  const [dashboardContentType, setDashboardContentType] = useState(DashboardContentType.initial);
  const [filters, setFilters] = useState<DashboardFilters>({});

  const getFIDashboard = async (fanInstid: string, filter: DashboardFilters) => {
    if (dashboardContentType !== DashboardContentType.initial) {
      setDashboardContentType(DashboardContentType.loading);
    }

    try {
      const response = await financialInstitutionServiceApi.getDashboardProducts1(
        {
          pageLimits: {
            number: 0,
            size: 100,
          },
          search: filter.search,
          filter: filter.filter,
        },
        Number(fanInstid),
      );

      setDasboardCards(prepareTenants(response.data.content));

      if (checkIfUserDoesNotHaveTenants(response.data?.content?.length ?? 0, filter)) {
        setDashboardContentType(DashboardContentType.welcome);
      } else {
        setDashboardContentType(DashboardContentType.table);
      }

      void UserService.updateToken();
    } catch (error) {
      setDashboardContentType(DashboardContentType.error);
    }
  };

  const getCardTenants = async (filter: DashboardFilters) => {
    if (dashboardContentType !== DashboardContentType.initial) {
      setDashboardContentType(DashboardContentType.loading);
    }

    try {
      const response = await productServiceApi.getDashboardProducts({
        pageLimits: {
          number: 0,
          size: 100,
        },
        search: filter.search,
        filter: filter.filter,
      });

      setDasboardCards(prepareTenants(response.data.content));

      if (checkIfUserDoesNotHaveTenants(response.data?.content?.length ?? 0, filter)) {
        setDashboardContentType(DashboardContentType.welcome);
      } else {
        setDashboardContentType(DashboardContentType.table);
      }
    } catch (error) {
      setDashboardContentType(DashboardContentType.error);
    }
  };

  return {
    id,
    dashboardContentType,
    dashboardCards,
    viewType,
    filters,
    isLoading: dashboardContentType === DashboardContentType.loading,
    getFIDashboard,
    getCardTenants,
    setFilters,
    setViewType,
  };
};
