import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.4619 5.82342L26.4581 5.73676L25.4619 5.82342L25.7308 8.91538C25.8078 9.79986 26.2725 10.6048 27 11.1137L29.5433 12.8926C29.9961 13.2094 30.1062 13.8335 29.789 14.286L28.0075 16.8275C27.4979 17.5545 27.3365 18.4699 27.5667 19.3273L28.3715 22.3248C28.5148 22.8586 28.198 23.4074 27.664 23.5502L24.6658 24.352C23.8081 24.5813 23.0961 25.1788 22.7212 25.9836L21.411 28.7971C21.1777 29.2981 20.5822 29.5149 20.0814 29.281L17.2692 27.968C16.4648 27.5924 15.5353 27.5924 14.7308 27.968L11.9186 29.281C11.4178 29.5149 10.8223 29.2981 10.589 28.7971L9.27876 25.9836C8.90395 25.1788 8.19191 24.5813 7.33423 24.352L4.33596 23.5502C3.80205 23.4074 3.48519 22.8586 3.6285 22.3248L4.43326 19.3273C4.66347 18.4699 4.50206 17.5545 3.99247 16.8275L2.21104 14.286C1.89382 13.8335 2.00386 13.2094 2.45674 12.8926L4.99997 11.1137C5.72748 10.6048 6.19223 9.79986 6.26917 8.91538L6.53814 5.82342C6.58603 5.27283 7.07148 4.86549 7.62202 4.91393L10.7137 5.18596C11.5981 5.26378 12.4716 4.94587 13.099 4.31778L15.2925 2.12209C15.6831 1.7311 16.3169 1.7311 16.7075 2.12209L18.901 4.31778C19.5284 4.94587 20.4019 5.26378 21.2863 5.18596L24.378 4.91393C24.9285 4.86549 25.414 5.27283 25.4619 5.82342Z"
      fill="#FFEDEF"
      stroke="#E64360"
      strokeWidth="2"
    />
    <path
      d="M19.3333 19.999C19.5101 19.999 19.6797 19.9288 19.8047 19.8038C19.9298 19.6787 20 19.5092 20 19.3324C20 19.1555 19.9298 18.986 19.8047 18.861C19.6797 18.7359 19.5101 18.6657 19.3333 18.6657C19.1565 18.6657 18.987 18.7359 18.8619 18.861C18.7369 18.986 18.6667 19.1555 18.6667 19.3324C18.6667 19.5092 18.7369 19.6787 18.8619 19.8038C18.987 19.9288 19.1565 19.999 19.3333 19.999ZM12.6667 13.3324C12.8435 13.3324 13.013 13.2621 13.1381 13.1371C13.2631 13.0121 13.3333 12.8425 13.3333 12.6657C13.3333 12.4889 13.2631 12.3193 13.1381 12.1943C13.013 12.0693 12.8435 11.999 12.6667 11.999C12.4899 11.999 12.3203 12.0693 12.1953 12.1943C12.0702 12.3193 12 12.4889 12 12.6657C12 12.8425 12.0702 13.0121 12.1953 13.1371C12.3203 13.2621 12.4899 13.3324 12.6667 13.3324Z"
      fill="#E64360"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20 11.999L12 19.999" stroke="#E64360" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const PercentRedIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
