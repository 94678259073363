import { NavbarItem } from "@ni/common/types";

export const newWorkflowSettingsItems: NavbarItem[] = [
  {
    name: "Workflow settings",
    route: "workflow-settings",
  },
];

export const existingWorkflowSettingsItems: NavbarItem[] = [
  {
    name: "Workflow settings",
    route: "",
  },
  {
    name: "Phases",
    route: "phases",
  },
];
