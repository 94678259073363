/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useCallback, useEffect, useState } from "react";
import { TablePaginationConfig } from "antd";

import { FormValues } from "@ni/common/types";
import { TenantApi } from "@ni/sdk/apis";
import {
  BatchChangeTenantSdmElementsRequest,
  SdmElementDashboardPageData,
  SortedFilteredPageRequest,
  TenantSdmElement,
} from "@ni/sdk/models";

import { useReduxState } from "../store";

import { initialDataFramework, initialDataFrameworkPagination } from "./constants";

const tenantServiceApi = new TenantApi();

interface Props {
  tenantId: number;
}

const useDataFramework = ({ tenantId }: Props) => {
  const [dataFramework, setDataFramework] = useReduxState<SdmElementDashboardPageData>(
    "dataFramework",
    initialDataFramework,
  );
  const [pagination, setPagination] = useReduxState<TablePaginationConfig>(
    "dataFrameworkPagination",
    initialDataFrameworkPagination,
  );
  const [filters, setFilters] = useState<SortedFilteredPageRequest>({});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const getDataFramework = useCallback(
    async (tenantId: number, currentPage: number, filters: SortedFilteredPageRequest = {}) => {
      setIsLoading(true);
      try {
        const { data } = await tenantServiceApi.getSdmDataDashboard(
          {
            ...filters,
            pageLimits: {
              number: currentPage - 1,
              size: pagination.pageSize,
            },
          },
          tenantId,
        );

        setPagination(pagination => ({ ...pagination, total: data.totalElements }));

        setDataFramework(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [pagination.pageSize, setDataFramework, setIsLoading, setPagination],
  );

  const transformData = (formData: FormValues) => {
    const elements: { [parmCode: string]: TenantSdmElement } = {};

    Object.entries(formData).forEach(([key, value]) => {
      const [parmCode, fieldType] = key.split("@");
      if (!elements[parmCode]) {
        elements[parmCode] = { enabled: false, mandatory: false, minLength: undefined, maxLength: undefined, parmCode };
      }
      elements[parmCode][fieldType] = value;
    });

    return Object.values(elements);
  };

  const updateDataFramework = async (formData: FormValues) => {
    setIsLoading(true);
    try {
      const payload: BatchChangeTenantSdmElementsRequest = {
        elements: transformData(formData),
      };

      const { data } = await tenantServiceApi.patchSdmData(payload, tenantId);

      setDataFramework(prev => ({
        ...prev,
        content: prev.content?.map(x => {
          const element = data.find(y => y.parmCode === x.element.parmCode);

          if (element) {
            return {
              ...x,
              element: {
                ...x.element,
                defaultEnabled: element.enabled,
                defaultMandatory: element.mandatory,
                minLength: element.minLength,
                maxLength: element.maxLength,
              },
            };
          }

          return x;
        }),
      }));

      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!dataFramework.content?.length || pagination || Object.keys(filters).length) {
      void getDataFramework(tenantId, pagination.current ?? 1, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataFramework, tenantId, pagination.current, filters]);

  return { dataFramework, setDataFramework, updateDataFramework, setFilters, setPagination, pagination };
};

export { useDataFramework };
