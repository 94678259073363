import { ReactNode } from "react";

import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

export const interestRate: NumericOptions = {
  code: "nic-ipp-inst-int-plan-rate",
  name: "Annual interest rate",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  tooltip:
    "This parameter indicates the interest rate that will be applied to the IPP amount.  This rate can be redefined for a specific pricing control table of the credit product.",
};

export const interestAmountOption: RadioOptions = {
  code: "ipp-int-opt1",
  name: "Calculate interest amount on:",
  default: "W",
  options: [
    {
      label: "The whole deal amount (original balance)",
      value: "W",
    },
    {
      label: "The actual remaining principal amount",
      value: "A",
    },
  ],
};

export const dailyInterestOption: RadioOptions = {
  code: "ipp-int-opt2",
  name: "Calculate daily interest rate:",
  default: "Y",
  options: [
    {
      label: "In the same manner for each day and use the actual number of days in the year (365/366)",
      value: "Y",
      tooltip:
        "Daily interest for a billing cycle is calculated as follows: (Account balance * annual rate)/ number of days in a year",
    },
    {
      label: "In accordance with the billing cycle weight in the year, depending on the number of days in the cycle",
      value: "B",
      tooltip:
        "Daily interest for a billing cycle is calculated as follows: (Account balance * annual rate)/ number of days in a billing cycle * 12",
    },
  ],
};
