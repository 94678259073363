import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { notification } from "antd";

import { useReduxState, useTenant } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { getErrorInstance } from "@ni/common/utils";
import { InsuranceProgramApi, ProductApi } from "@ni/sdk/apis";
import { ChangeInsuranceProgramRequest, CopyInsuranceProgramRequest, InsuranceProgram, Product } from "@ni/sdk/models";

const productApi = new ProductApi();
const insuranceProgramApi = new InsuranceProgramApi();

export const useInsurance = (tenantId: number, productId: number) => {
  const { tenant: currentTenant } = useTenant({ tenantId });
  const [insurancePrograms, setPrograms] = useReduxState<InsuranceProgram[]>("FocusedProductInsurancePrograms", []);
  const [currentProduct] = useReduxState<Product>("currentProduct", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading", false);

  const setInsurancePrograms = useCallback(
    (programs: InsuranceProgram[]) => {
      setIsLoading(true);
      const compareActivity = (a: InsuranceProgram, b: InsuranceProgram) => {
        if (a.state === "ACTIVE" && b.state === "ACTIVE") return 0;
        if (a.state === "ACTIVE" && b.state !== "ACTIVE") return -1;
        if (a.state !== "ACTIVE" && b.state === "ACTIVE") return 1;
        return 0;
      };

      const compareCode = (a: InsuranceProgram, b: InsuranceProgram) =>
        parseInt(a?.code ?? "0", 10) - parseInt(b?.code ?? "0", 10);
      programs.sort(compareActivity);
      const activePrograms = programs.filter(x => x.state === "ACTIVE");
      const inactivePrograms = programs.filter(x => x.state !== "ACTIVE");
      activePrograms.sort(compareCode);
      setPrograms([...activePrograms, ...inactivePrograms]);
      setIsLoading(false);
    },
    [setIsLoading],
  );

  const productCurrency = useMemo(
    () => currentProduct?.productValues?.find(y => y.fieldCode === "nic-ccy")?.value ?? "",
    [currentProduct?.productValues],
  );

  const insuranceTemplates = useMemo(
    () => currentTenant?.insuranceProgramTemplates?.filter(x => x.state === "ACTIVE") ?? [],
    [currentTenant?.insuranceProgramTemplates],
  );

  const getProductInsurancePrograms = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await insuranceProgramApi.getInsuranceProgramsByProductId(productId);
      if (response?.data) setInsurancePrograms(response.data);
    } catch {
      setInsurancePrograms([]);
    } finally {
      setIsLoading(false);
    }
  }, [productId, setInsurancePrograms, setIsLoading]);

  const applyProductInsurancePrograms = useCallback(
    async (ids: number[], errorMessage: ReactNode) => {
      if (ids) {
        if (insurancePrograms.length + ids.length <= 12) {
          const response = await productApi.applyInsuranceProgramTemplates(
            { insuranceProgramTemplateIds: ids as unknown as Set<number> },
            productId,
          );
          if (response && response?.data) setInsurancePrograms(response.data);
        } else
          notification.error({
            placement: "topRight",
            duration: 5,
            message: errorMessage,
          });
      }
    },
    [insurancePrograms.length, productId, setInsurancePrograms],
  );

  const duplicateInsuranceProgram = useCallback(
    async (value: FormValues, id: number, errorMessage: ReactNode) => {
      if (id && insurancePrograms?.length < 12) {
        setIsLoading(true);
        try {
          const response = await insuranceProgramApi.copy2(value as CopyInsuranceProgramRequest, id);
          if (response?.data) setInsurancePrograms([...insurancePrograms, response?.data]);
        } catch (error) {
          const errorInstance = getErrorInstance(error);
          notification.error({
            placement: "topRight",
            duration: 3,
            message: errorInstance.response.data.errorMessage,
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        notification.error({
          placement: "topRight",
          duration: 5,
          message: errorMessage,
        });
      }
    },
    [insurancePrograms, setInsurancePrograms, setIsLoading],
  );

  const renameInsuranceProgram = useCallback(
    async (value: FormValues, id: number) => {
      if (id) {
        setIsLoading(true);
        try {
          const updatedProgram = (await insuranceProgramApi.edit3(value as ChangeInsuranceProgramRequest, id)).data;
          const indexToReplace = insurancePrograms.findIndex(x => x.id === id);
          insurancePrograms[indexToReplace] = updatedProgram;
          setInsurancePrograms([...insurancePrograms]);
        } catch (error) {
          const errorInstance = getErrorInstance(error);
          notification.error({
            placement: "topRight",
            duration: 3,
            message: errorInstance.response.data.errorMessage,
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [insurancePrograms, setInsurancePrograms, setIsLoading],
  );

  const toggleInsuranceProgram = useCallback(
    async (state: string, id: number) => {
      if (id) {
        setIsLoading(true);
        try {
          const updatedProgram = (
            await insuranceProgramApi.edit3({ state: state === "ACTIVE" ? "INACTIVE" : "ACTIVE" }, id)
          )?.data;
          if (updatedProgram) await getProductInsurancePrograms();
        } catch (error) {
          const errorInstance = getErrorInstance(error);
          notification.error({
            placement: "topRight",
            duration: 3,
            message: errorInstance.response.data.errorMessage,
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [getProductInsurancePrograms, setIsLoading],
  );

  useEffect(() => {
    if (productId) void getProductInsurancePrograms();
  }, [getProductInsurancePrograms, productId, setIsLoading]);

  return {
    insurancePrograms,
    insuranceTemplates,
    applyProductInsurancePrograms,
    duplicateInsuranceProgram,
    renameInsuranceProgram,
    toggleInsuranceProgram,
    productCurrency,
  };
};
