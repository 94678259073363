import { useCallback, useEffect } from "react";

import { SetReduxState, useReduxState } from "@ni/common/hooks";
import { TenantApi } from "@ni/sdk/apis";
import { BaseLoyaltyProgramDashboard } from "@ni/sdk/models";

const tenantApi = new TenantApi();

interface UseLoyaltyTemplatesProps {
  tenantId: number;
}

export const useLoyaltyTemplates = ({ tenantId }: UseLoyaltyTemplatesProps) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [loyaltyProgramTemplates, setLoyaltyProgramTemplates] = useReduxState<BaseLoyaltyProgramDashboard[]>(
    "LoyaltyProgramTemplates",
    [],
  );

  const fetchLoyaltyPrograms = useCallback(
    async (tenantId: number) => {
      setIsLoading(true);
      try {
        const tenantResponse = await tenantApi.getBaseLoyaltyProgramsByTenantId(
          { filter: { state: "ACTIVE" } },
          tenantId,
        );

        setLoyaltyProgramTemplates(tenantResponse.data.content ?? []);
      } catch (err) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setLoyaltyProgramTemplates],
  );

  useEffect(() => void fetchLoyaltyPrograms(tenantId), [fetchLoyaltyPrograms, tenantId]);

  return [loyaltyProgramTemplates, setLoyaltyProgramTemplates] as [
    BaseLoyaltyProgramDashboard[],
    SetReduxState<BaseLoyaltyProgramDashboard[]>,
  ];
};
