import React, { FC, useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";

import { colors } from "@ni/common/constants";
import { useTopBarLoader } from "@ni/common/hooks";

type LoadBarMethods = {
  add: (value: number) => void;
  decrease: (value: number) => void;
  continuousStart: (startingValue?: number, refreshRate?: number) => void;
  staticStart: (startingValue?: number) => void;
  complete: () => void;
};

export const TopBarLoader: FC = () => {
  const { isLoading } = useTopBarLoader();
  const ref = useRef<LoadBarMethods>(null);

  const [isStarted, setStarted] = useState(false);
  useEffect(() => {
    if (isLoading && !isStarted) {
      ref.current?.continuousStart();
      setStarted(true);
    }
    if (!isLoading && isStarted) {
      ref.current?.complete();
      setStarted(false);
    }
  }, [isLoading, isStarted]);

  return <LoadingBar ref={ref} color={colors.primaryColor} height={3} />;
};
