import { MCC_LIST, PctValueParams } from "@ni/common/constants";
import { FormValues, SliderRanges, Value } from "@ni/common/types";
import { ParameterTableValue } from "@ni/sdk/models";

export const prepareActualPctValues = (values: FormValues): ParameterTableValue[] =>
  Object.entries(values as { [x: string]: Exclude<Value, SliderRanges> }).map(([key, value]) => {
    if (key === (PctValueParams.eCommerceList as string)) {
      return {
        fieldCode: key,
        value: (value as string[]).map(selected => MCC_LIST.find(mcc => mcc.value === selected)?.key).join(","),
      };
    }
    return {
      fieldCode: key,
      value,
    };
  }) as ParameterTableValue[];
