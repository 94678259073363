import { FC, useMemo, useState } from "react";
import { Mentions, Space } from "antd";

import { ControlCharactersExceptEnterRegex } from "@ni/common/constants";
import { displayReadableSmsVariables } from "@ni/common/utils";
import { Variable } from "@ni/sdk/models";

interface EditableCellProps {
  text?: string;
  onSubmit: (value: string) => void;
  variables: (Variable | undefined)[];
}

export const EditableCell: FC<EditableCellProps> = ({ text, variables, onSubmit }) => {
  const [value, setValue] = useState(text);
  const [isEditable, setIsEditable] = useState(false);

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const options = useMemo(
    () =>
      variables?.map(variable => ({
        key: `${variable?.code} ${variable?.id}`,
        label: variable?.description,
        value: variable?.code,
      })),
    [variables],
  );

  const result = useMemo(
    () =>
      variables?.filter(variable => variable)?.length > 0
        ? displayReadableSmsVariables(value ?? "N/A", variables as Variable[])
        : "N/A",
    [value, variables],
  );

  const handleChange = (text: string) => {
    const filteredText = text?.replace("%%", "%")?.replace(ControlCharactersExceptEnterRegex, "");
    setValue(filteredText);
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event?.key && event.key.toLocaleLowerCase() === "s" && event.ctrlKey) || event.key === "Escape") {
      event.preventDefault();
      if (onSubmit && event.key.toLocaleLowerCase() === "s" && value?.trim() !== text?.trim()) {
        onSubmit(value ?? "");
      }
      toggleEdit();
    }
  };

  return isEditable ? (
    <Mentions
      value={value ?? ""}
      prefix="%"
      onChange={handleChange}
      onKeyDown={handleKeyDownEvent}
      options={options}
      autoSize={true}
      variant="borderless"
      maxLength={result?.length >= 512 ? 512 : 1000}
    />
  ) : (
    <Space className="w-p-100" onClick={toggleEdit}>
      {value ?? "N/A"}
    </Space>
  );
};
