import { FC, useMemo, useState } from "react";
import { Form, Select } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { PERSO, REISS_PHYS, SDE, VIRT_PHYS } from "@ni/common/constants";
import { useFormButtonDisabled, useGetPerso, useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, RadioGroup } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { PERSO_LIST, persoSelectValue, REISS_PHYS_LIST, VIRT_PHYS_LIST } from "./constants";

const PERSO_SUBSTANCE = "PERSO_SUBSTANCE";
const wizardServicesApi = new ProductAndTenantWizardApi();

export const CardProductionPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const cardType = Form.useWatch(VIRT_PHYS, form);
  const isCardAllowedForReuse = Form.useWatch(REISS_PHYS, form);
  const thirdPartyAllowed = Form.useWatch(PERSO, form);
  const sameDayEmbossing = Form.useWatch(SDE, form);

  const [fulfilled, setFulfilled] = useState(false);
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const { persoList } = useGetPerso();
  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form });

  const persoSubstanceValue = useMemo(
    () => wizardResponse?.product?.productValues?.find(x => x.fieldCode === PERSO)?.value,
    [wizardResponse?.product?.productValues],
  );

  useHydrateForm({
    form,
    entityFields: wizardResponse?.product?.productValues
      ? [
          ...wizardResponse.product.productValues,
          ...(persoSubstanceValue === PERSO_LIST[1].value
            ? [
                {
                  fieldCode: PERSO_SUBSTANCE,
                  value: "IDEMIA",
                },
                {
                  fieldCode: PERSO,
                  value: persoSubstanceValue,
                },
              ]
            : [
                {
                  fieldCode: PERSO_SUBSTANCE,
                  value: persoSubstanceValue,
                },
                {
                  fieldCode: PERSO,
                  value: persoSelectValue,
                },
              ]),
        ]
      : [],
    keys: {
      strings: [PERSO, SDE, VIRT_PHYS, REISS_PHYS, PERSO_SUBSTANCE],
    },
    allowParse: false,
  });

  const onValidateFields = (values: Partial<FormValues>, allValues: FormValues) => {
    let letContinue = false;
    if (values[VIRT_PHYS] === "V" || allValues[VIRT_PHYS] === "V") {
      form.setFieldsValue({
        [PERSO]: "",
        [REISS_PHYS]: "",
      });
      letContinue = true;
    }

    if (allValues[PERSO] === PERSO_LIST[1].value) {
      form.setFieldValue(SDE, undefined);
    }

    if (allValues[PERSO] === PERSO_LIST[1].value || !allValues[PERSO]) {
      form.setFieldValue(PERSO_SUBSTANCE, "IDEMIA");
    }

    if (allValues[PERSO] && allValues[REISS_PHYS]) {
      letContinue = true;
    }

    setFulfilled(letContinue);
  };

  const isUrgentPersonalizationShown = () =>
    cardType === VIRT_PHYS_LIST[1].value && thirdPartyAllowed === PERSO_LIST[0].value && sameDayEmbossing === "true";

  const isPersonalizationPartnersShown = () =>
    cardType === VIRT_PHYS_LIST[1].value && thirdPartyAllowed === PERSO_LIST[1].value;

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    if (values[PERSO_SUBSTANCE]) {
      values[PERSO] = values[PERSO_SUBSTANCE];
    }
    delete values[PERSO_SUBSTANCE];

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled || !fulfilled}
      pageTitle="Card Production"
      size="md"
      formSize="md"
      gap={40}
      level="root"
      onValuesChange={(values, allValues) => {
        onFormChange();
        onValidateFields(values, allValues);
      }}
      submitHandler={onFinish}
    >
      <Form.Item dependencies={[REISS_PHYS, PERSO, SDE]}>
        {() => (
          <Form.Item
            name={VIRT_PHYS}
            label={<FormItemLabel label="What type of card do you want to issue?" code={VIRT_PHYS} />}
            required={true}
          >
            <RadioGroup radioList={VIRT_PHYS_LIST} />
          </Form.Item>
        )}
      </Form.Item>

      {cardType === VIRT_PHYS_LIST[1].value && (
        <Form.Item
          name={REISS_PHYS}
          label={
            <FormItemLabel
              label="Do you want to allow re-issuing of virtual cards into physical cards?"
              code={REISS_PHYS}
            />
          }
          required={true}
        >
          <RadioGroup radioList={REISS_PHYS_LIST} />
        </Form.Item>
      )}

      {cardType === VIRT_PHYS_LIST[1].value && isCardAllowedForReuse && (
        <Form.Item
          name={PERSO}
          label={
            <FormItemLabel
              label="Network International works with partners to provide card personalization services. Would you like to use our partners or integrate your own third party?"
              code={PERSO}
            />
          }
          required={true}
        >
          <RadioGroup radioList={PERSO_LIST} />
        </Form.Item>
      )}

      {cardType === VIRT_PHYS_LIST[1].value && thirdPartyAllowed && thirdPartyAllowed === PERSO_LIST[0].value && (
        <>
          <Form.Item
            name={PERSO_SUBSTANCE}
            label={<FormItemLabel label="Network International partners (Perso Bureau)" code={PERSO} />}
            rules={[
              {
                required: true,
                message: "Network International partners is required.",
              },
              {
                validator: (_, value) => {
                  if (value === persoSelectValue) return Promise.reject();
                  return Promise.resolve();
                },
                message: "Network International partners is required.",
              },
            ]}
            initialValue="IDEMIA"
          >
            <Select>
              {persoList.map(listEntry => (
                <Select.Option key={listEntry.value} value={listEntry.value}>
                  <FormItemLabel label={listEntry.displayValue} code={listEntry.value} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={SDE}
            label={<FormItemLabel label="Would you like to use Same Day Embossing?" code={SDE} />}
            tooltip={{
              title: "Card is produced at the day of ordering (urgent issuing).",
              icon: <QuestionCircleFilled />,
            }}
            rules={[{ required: true, message: "This field is required. Please choose an option." }]}
          >
            <RadioGroup radioList={REISS_PHYS_LIST} />
          </Form.Item>
        </>
      )}

      {isPersonalizationPartnersShown() && (
        <div className="alert-div">
          Please reach out to your Network International representative to discuss new integrations for your third party
          personalization partners.
        </div>
      )}

      {isUrgentPersonalizationShown() && (
        <div className="alert-div">
          Please reach out to your Network International representative to setup the process of urgent personalization.
        </div>
      )}
    </CustomFormWrapper>
  );
};
