import { DashboardTenantUI } from "@ni/common/types";
import { FullDashboardProduct } from "@ni/sdk/models";

export const prepareTenants = (dashboardProduct?: FullDashboardProduct[]): DashboardTenantUI[] => {
  if (!dashboardProduct) return [];

  const content: DashboardTenantUI[] = [];

  dashboardProduct.forEach(val => {
    const tenant = content.find(item => item.id === val.tenant?.id);
    if (tenant) {
      tenant.products.push(val.product);
    } else {
      content.push({
        ...val.tenant,
        products: [val.product],
      });
    }
  });

  return content;
};
