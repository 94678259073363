import React, { FC } from "react";
import { Table, TableColumnType, TablePaginationConfig, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";

import { CloudUploadOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { ActionsCell } from "@ni/common/ui";
import { dateFormat, durationConvertHandler } from "@ni/common/utils";
import {
  Deployment,
  DeploymentsState,
  DeploymentsState as DeploymentsStateType,
  Order,
  SortedFilteredPageRequest,
} from "@ni/sdk/models";

import { DEPLOYMENT_STATE_COLORS } from "../../constants";

import styles from "./styles.module.scss";

interface DeploymentsTableViewProps {
  deploymentList: Deployment[];
  pagination: TablePaginationConfig;
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setDeploymentsFilters: React.Dispatch<React.SetStateAction<SortedFilteredPageRequest>>;
  fetchDepeloymentById: (id: number) => void;
  reTryDeployment: (deploymentId: number) => void;
}

const { Text } = Typography;

export const DeploymentsTable: FC<DeploymentsTableViewProps> = ({
  deploymentList,
  pagination,
  setPagination,
  setDeploymentsFilters,
  fetchDepeloymentById,
  reTryDeployment,
}) => {
  const navigate = useNavigate();

  const [isLoading] = useReduxState("isLoading", false);

  const onRowHandler = (item: Deployment) => {
    return {
      onDoubleClick: () => navigate(`${item.id}`),
    };
  };

  const handleChange = (
    pagination: TablePaginationConfig,
    _: Record<string, FilterValue | null>,
    sorter: SorterResult<Deployment> | SorterResult<Deployment>[],
  ) => {
    let newSorting: Array<Order> = [];
    if (Object.keys(sorter).length) {
      const sorterArray = Array.isArray(sorter) ? sorter : [sorter];

      newSorting = sorterArray.map(sorterItem => {
        const { order, columnKey } = sorterItem;

        if (!order) {
          return undefined;
        }

        return {
          direction: order === "ascend" ? "ASC" : order === "descend" ? "DESC" : "",
          property: columnKey,
        };
      }) as Array<Order>;
    }

    setPagination(pagination);

    setDeploymentsFilters(existingFilters => ({
      ...existingFilters,
      sorting: newSorting.filter(Boolean),
    }));
  };

  const getActionItems = (deploymentsState: DeploymentsStateType) => {
    const actions = [
      {
        label: "Refresh",
        icon: <RedoOutlined />,
        actionCallBack: (item: Deployment) => {
          fetchDepeloymentById(item.id!);
        },
      },
      {
        label: "View details",
        icon: <SearchOutlined />,
        actionCallBack: (item: Deployment) => {
          navigate(`${item.id}`);
        },
      },
    ];

    if (deploymentsState === DeploymentsState.FAILURE) {
      actions.push({
        label: "Retry deployment",
        icon: <CloudUploadOutlined />,
        actionCallBack: (item: Deployment) => {
          reTryDeployment(Number(item.id));
        },
      });
    }
    return actions;
  };

  const columns: TableColumnType<Deployment>[] = [
    {
      title: "FI Code",
      dataIndex: ["tenantExternalCode"],
      key: "tenantExternalCode",
      width: "5%",
      render: (_: string, { tenantExternalCode }: Deployment) => tenantExternalCode,
    },
    {
      title: "Tenant",
      dataIndex: ["tenantName"],
      key: "tenantName",
      width: "11%",
      ellipsis: true,
      render: (_: string, { tenantName }: Deployment) => tenantName,
    },
    {
      title: "Product",
      dataIndex: ["productName"],
      key: "productName",
      width: "13%",
      ellipsis: true,
      render: (_: string, { productName }: Deployment) => productName,
    },
    {
      title: "Status",
      dataIndex: ["state"],
      key: "state",
      width: "8%",
      ellipsis: true,
      render: (_: string, { state }: Deployment) =>
        state ? <Text type={DEPLOYMENT_STATE_COLORS[state]}>{state}</Text> : EMPTY_DATA_FLAG,
    },

    {
      title: "Start time",
      dataIndex: ["startTime"],
      key: "startTime",
      width: "14%",
      ellipsis: true,
      sorter: (a, b) => Number(a.startTime) - Number(b.startTime),
      render: (_: string, { startTime }: Deployment) => (startTime ? dateFormat(startTime) : EMPTY_DATA_FLAG),
    },
    {
      title: "End time",
      dataIndex: ["endTime"],
      key: "endTime",
      width: "14%",
      ellipsis: true,
      sorter: (a, b) => Number(a.endTime) - Number(b.endTime),
      render: (_: string, { endTime }: Deployment) => (endTime ? dateFormat(endTime) : EMPTY_DATA_FLAG),
    },
    {
      title: "Total time",
      dataIndex: ["totalTime"],
      key: "totalTime",
      width: "7%",
      ellipsis: true,
      sorter: (a, b) => Number(a.totalTime) - Number(b.totalTime),
      render: (_: string, { totalTime }: Deployment) =>
        totalTime ? <>{durationConvertHandler(totalTime as string).toFixed(3)} Sec</> : EMPTY_DATA_FLAG,
    },

    {
      title: "Actions",
      key: "id",
      width: "5%",
      ellipsis: true,
      render: (_: string, item: Deployment) => (
        <ActionsCell items={getActionItems(item.state as DeploymentsStateType)} rowData={item} />
      ),
    },
  ];

  return (
    <Table<Deployment>
      rowKey="id"
      tableLayout="fixed"
      className={styles["ni-table"]}
      columns={columns}
      pagination={pagination}
      dataSource={deploymentList}
      onRow={onRowHandler}
      onChange={handleChange}
      loading={isLoading}
    />
  );
};
