import { FC, useCallback, useEffect, useState } from "react";
import { Table, TableColumnType } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";

import { useReduxState } from "@ni/common/hooks";
import { DashboardFilters } from "@ni/common/types";
import { Spin } from "@ni/common/ui";
import { mapToServerSorting } from "@ni/common/utils";
import { ElementTemplateApi } from "@ni/sdk/apis";
import { ElementTemplate, Order, ParameterTable, SortedFilteredPageRequest } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const elementTemplateServiceApi = new ElementTemplateApi();

interface ElementTemplateModalTableProps {
  filters: DashboardFilters;
}

const ElementTemplateModalTable: FC<ElementTemplateModalTableProps> = ({ filters }) => {
  const [isLoading] = useReduxState<boolean>("isLoading");
  const [selectedElementTemplateRow, setSelectedElementTemplateRow] = useReduxState<ParameterTable>(
    "selectedElementTemplateRow",
    {},
  );

  const [elementTemplates, setElementTemplates] = useReduxState<ElementTemplate[]>("elementTemplates", []);

  const [isETLoading, setIsETLoading] = useState<boolean>(false);

  const [tablePageConfig, setTablePageConfig] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });
  const [dashboardPageConfig, setDashboardPageConfig] = useState<SortedFilteredPageRequest>();

  const getElementTemplates = useCallback((): void => {
    if (dashboardPageConfig && Object.keys(dashboardPageConfig)) {
      setIsETLoading(true);

      elementTemplateServiceApi
        .getElementTemplates(dashboardPageConfig)
        .then(response => {
          setTablePageConfig({
            ...tablePageConfig,
            current: (dashboardPageConfig.pageLimits?.number as number) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setElementTemplates(response.data.content as ElementTemplate[]);
          setIsETLoading(false);
        })
        .catch(() => {
          setIsETLoading(false);
        });
    }
  }, [dashboardPageConfig]);

  useEffect(() => {
    getElementTemplates();
  }, [dashboardPageConfig]);

  useEffect(() => {
    setDashboardPageConfig({
      ...filters,
      sorting: dashboardPageConfig?.sorting as Order[],
      pageLimits: {
        size: tablePageConfig.pageSize,
        number: 0,
      },
    });
  }, [filters]);

  const columns: TableColumnType<ElementTemplate>[] = [
    {
      title: "Code",
      dataIndex: ["code"],
      key: "code",
      sorter: (a, b) => Number(a.code) - Number(b.code),
      render: (_: string, item: ElementTemplate) => (
        <p className={styles["table-view-element-template-name"]}>{item.code}</p>
      ),
    },
    {
      title: "Values dictionary",
      dataIndex: ["valuesDictionary"],
      key: "valuesDictionary",
      sorter: (a, b) => Number(a.elementValues) - Number(b.elementValues),
      render: (_: string, item: ElementTemplate) => <div>{item.elementValues}</div>,
    },
    {
      title: "Element group",
      dataIndex: ["elementGroup"],
      key: "elementGroup",
      sorter: (a, b) => Number(a.elementGroup) - Number(b.elementGroup),
      render: (_: string, item: ElementTemplate) => {
        return item?.elementGroup;
      },
    },
    {
      title: "Element type",
      dataIndex: ["elementType"],
      key: "elementType",
      sorter: (a, b) => Number(a.elementType) - Number(b.elementType),
      render: (_: string, item: ElementTemplate) => {
        return item?.elementType;
      },
    },
    {
      title: "Label",
      dataIndex: ["label"],
      key: "label",
      sorter: (a, b) => (a.label?.length ?? 0) - (b.label?.length ?? 0),
      width: 150,
      render: (_: string, item: ElementTemplate) => {
        return item?.label;
      },
    },
    {
      title: "Feature name",
      dataIndex: ["featureName"],
      key: "featureName",
      render: (_: string, item: ElementTemplate) => {
        return item.feature?.name || "-";
      },
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<ElementTemplate> | SorterResult<ElementTemplate>[],
  ) => {
    let sortArray: Order[] = [];
    if (sorter) {
      if (Array.isArray(sorter)) {
        sortArray = sorter
          .filter(sort => sort.order)
          .map(sortObject => ({
            direction: mapToServerSorting(sortObject.order),
            property: sortObject.columnKey as string,
          }));
      } else if (sorter.order) {
        sortArray = [{ property: sorter.columnKey as string, direction: mapToServerSorting(sorter.order) }];
      }
    }

    setDashboardPageConfig({
      ...dashboardPageConfig,
      pageLimits: {
        size: pagination.pageSize,
        number: (pagination.current as number) - 1,
      },
      sorting: sortArray.length ? sortArray : undefined,
    });
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div className={styles["element-template-modal-table"]}>
      <Table<ElementTemplate>
        className={styles["element-template-modal-table-view"]}
        columns={columns}
        dataSource={elementTemplates}
        rowClassName={record =>
          record.id === selectedElementTemplateRow.id ? styles["table-row-selected"] : styles["table-row"]
        }
        loading={isETLoading}
        rowKey={(item: ElementTemplate) => `${String(item?.id)}` || Math.random()}
        onChange={handleTableChange}
        pagination={tablePageConfig}
        onRow={elementTemplate => {
          return {
            onClick: () => {
              setSelectedElementTemplateRow(elementTemplate);
            },
          };
        }}
      />
    </div>
  );
};

export default ElementTemplateModalTable;
