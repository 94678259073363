import { FC, useEffect, useMemo, useState } from "react";
import { Form, Space, Switch } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { useFormButtonDisabled, useHydrateForm, useReduxState } from "@ni/common/hooks";
import {
  clientContractLevelListOptions,
  transactionCategoryListOptions,
  transactionPeriodListBothOptions,
  transactionPeriodListPerAccountOptions,
  transactionPeriodListPerCardOptions,
} from "@ni/common/mocks";
import { BaseWizardPageProps, FormValues, SelectOption } from "@ni/common/types";
import { Checkbox, CustomFormWrapper, FormItemLabel, RadioGroup } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionLimitsPresetPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [contractLevel, setContractLevel] = useState("");
  const [selectedPeriods, setSelectedPeriods] = useState("");
  const [transactionCategory, setTransactionCategory] = useState("");
  const [transactionsAtmPos, setTransactionsAtmPos] = useState(false);
  const [transactionsEcom, setTransactionsEcom] = useState(false);
  const [transactionsDomFor, setTransactionsDomFor] = useState(false);

  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form });

  const balanceOnUs =
    wizardResponse.product?.productValues?.find(
      (productValue: ProductValue) => productValue.fieldCode === "balance-owner",
    )?.value === "CMS";

  const isSelectedPeriodDisplayed = useMemo(
    () => contractLevel === "B" || (contractLevel === "A" && balanceOnUs) || contractLevel === "C",
    [balanceOnUs, contractLevel],
  );

  const isTransactionCategoryDisplayed = useMemo(
    () =>
      (contractLevel === "A" || contractLevel === "B" || contractLevel === "C") &&
      (selectedPeriods !== "" || (contractLevel === "A" && !balanceOnUs)),
    [balanceOnUs, contractLevel, selectedPeriods],
  );

  const isTransactionAtmPosDisplayed = useMemo(
    () =>
      transactionCategory.includes("C") &&
      !(contractLevel === "C" && selectedPeriods === "M") &&
      selectedPeriods !== "B" &&
      transactionCategory,
    [contractLevel, selectedPeriods, transactionCategory],
  );
  const isTransactionEcomDisplayed = useMemo(
    () => transactionCategory.includes("R") && selectedPeriods.includes("D") && transactionCategory,
    [selectedPeriods, transactionCategory],
  );
  const isTransactionDomForDisplayed = useMemo(
    () =>
      (contractLevel !== "C" || !["B", "D", "B,D"].includes(selectedPeriods)) &&
      contractLevel !== "N" &&
      transactionCategory !== "T" &&
      transactionCategory,
    [contractLevel, selectedPeriods, transactionCategory],
  );

  const transactionPeriodListFiltered = useMemo<SelectOption[]>(() => {
    const transactionList =
      (contractLevel === "A" && transactionPeriodListPerAccountOptions) ||
      (contractLevel === "C" && transactionPeriodListPerCardOptions) ||
      (contractLevel === "B" && transactionPeriodListBothOptions) ||
      [];

    return balanceOnUs ? transactionList : transactionList?.filter(period => period.value !== "B");
  }, [contractLevel, balanceOnUs]);

  const transactionCategoryListFiltered = useMemo(() => {
    const isBothAndBilling = contractLevel === "B" && selectedPeriods === "B";

    return transactionCategoryListOptions
      ?.filter(category => category?.value !== "T" || contractLevel !== "C" || selectedPeriods !== "B")
      .map(transCat =>
        isBothAndBilling && transCat.value === "T"
          ? { ...transCat, label: "Total (not available for card)" }
          : transCat,
      );
  }, [contractLevel, selectedPeriods]);

  useEffect(() => {
    if (formDisabled) {
      const pctProductValues = wizardResponse.product?.parameterTables![0].pctProductValues;

      const periods = pctProductValues?.find(item => item.fieldCode === "trans-lim-periods")?.value || "";
      const transCat = pctProductValues?.find(item => item.fieldCode === "trans-lim-trans-cat")?.value || "";
      const contrLevel = pctProductValues?.find(item => item.fieldCode === "trans-lim-contr-level")?.value || "";
      const atmPos = pctProductValues?.find(item => item.fieldCode === "trans-lim-atm-pos")?.value === "true" || false;
      const ecom = pctProductValues?.find(item => item.fieldCode === "trans-lim-ecom")?.value === "true" || false;
      const domFor = pctProductValues?.find(item => item.fieldCode === "trans-lim-dom-for")?.value === "true" || false;

      form.setFieldValue("trans-lim-contr-level", contrLevel);

      setContractLevel(contrLevel);
      setSelectedPeriods(periods);
      setTransactionCategory(transCat);
      setTransactionsAtmPos(atmPos);
      setTransactionsEcom(ecom);
      setTransactionsDomFor(domFor);
    } else {
      form.setFieldsValue({
        "trans-lim-atm-pos": transactionsAtmPos,
        "trans-lim-ecom": transactionsEcom,
        "trans-lim-dom-for": transactionsDomFor,
      });
    }
  }, [
    form,
    formDisabled,
    transactionsAtmPos,
    transactionsDomFor,
    transactionsEcom,
    wizardResponse.product?.parameterTables,
  ]);

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [
        "trans-lim-periods",
        "trans-lim-trans-cat",
        "trans-lim-contr-level",
        "trans-lim-atm-pos",
        "trans-lim-ecom",
        "trans-lim-dom-for",
      ],
    },
  });

  const handlePeriodChange = (checkedValues: CheckboxValueType[]) => {
    const newValue = checkedValues.sort().join();

    setTransactionCategory("");
    setTransactionsAtmPos(false);
    setTransactionsEcom(false);
    setTransactionsDomFor(false);

    setSelectedPeriods(newValue);
  };

  const handleTransactionCategoryChange = (checkedValues: CheckboxValueType[]) => {
    const newValue = checkedValues.sort().join();

    setTransactionsAtmPos(false);
    setTransactionsEcom(false);
    setTransactionsDomFor(false);

    setTransactionCategory(newValue);
  };

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        "trans-lim-periods": !balanceOnUs && contractLevel === "A" ? "D" : selectedPeriods,
        "trans-lim-trans-cat": transactionCategory,
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled}
      pageTitle="Transaction Limits Pre-set"
      pageSubtitle="Transaction limits provide the feasibility to apply restrictions on frequency and amounts of card transactions. It could protect both issuer and cardholders, mitigate fraud risk, manage budgets, and comply with regulations."
      size="full"
      formSize="sm"
      gap={24}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Form.Item
        name="trans-lim-contr-level"
        label={
          <FormItemLabel
            label="Choose the client's contract level you want to apply limits:"
            code="trans-lim-contr-level"
          />
        }
        rules={[{ required: true, message: "Client's contract level is required!" }]}
      >
        <RadioGroup
          radioList={clientContractLevelListOptions}
          gap={8}
          value={contractLevel}
          onChange={event => {
            setContractLevel(event.target.value as string);
            setSelectedPeriods("");
            setTransactionCategory("");
            form.resetFields(["trans-lim-periods", "trans-lim-trans-cat"]);
          }}
        />
      </Form.Item>

      {isSelectedPeriodDisplayed && (
        <Form.Item
          name="trans-lim-periods"
          label={<FormItemLabel label="Choose the period you want to restrict" code="trans-lim-periods" />}
          rules={[{ required: true, message: "Period is required!" }]}
        >
          <Checkbox
            checkboxes={transactionPeriodListFiltered}
            value={selectedPeriods?.split(",")}
            onChange={handlePeriodChange}
          />
        </Form.Item>
      )}

      {isTransactionCategoryDisplayed && (
        <Form.Item
          name="trans-lim-trans-cat"
          label={<FormItemLabel label="Choose transaction category you want to restrict" code="trans-lim-trans-cat" />}
          rules={[{ required: true, message: "Transaction category is required!" }]}
        >
          <Checkbox
            checkboxes={transactionCategoryListFiltered}
            value={transactionCategory?.split(",")}
            onChange={handleTransactionCategoryChange}
          />
        </Form.Item>
      )}

      {(isTransactionAtmPosDisplayed || isTransactionEcomDisplayed || isTransactionDomForDisplayed) && (
        <Space direction="vertical" size={8}>
          {isTransactionAtmPosDisplayed && (
            <Space direction="horizontal">
              <Form.Item name="trans-lim-atm-pos">
                <Switch checked={transactionsAtmPos} onChange={() => setTransactionsAtmPos(!transactionsAtmPos)} />
              </Form.Item>

              <FormItemLabel label="Segregate “ATM”/ “POS Cash” transactions" code="trans-lim-atm-pos" />
            </Space>
          )}

          {isTransactionEcomDisplayed && (
            <Space direction="horizontal">
              <Form.Item name="trans-lim-ecom">
                <Switch checked={transactionsEcom} onChange={() => setTransactionsEcom(!transactionsEcom)} />
              </Form.Item>

              <FormItemLabel label="Segregate ecommerce transactions" code="trans-lim-ecom" />
            </Space>
          )}

          {isTransactionDomForDisplayed && (
            <Space direction="horizontal">
              <Form.Item name="trans-lim-dom-for">
                <Switch checked={transactionsDomFor} onChange={() => setTransactionsDomFor(!transactionsDomFor)} />
              </Form.Item>

              <FormItemLabel label="Segregate “Foreign“/ “Domestic” transactions" code="trans-lim-dom-for" />
            </Space>
          )}
        </Space>
      )}
    </CustomFormWrapper>
  );
};
