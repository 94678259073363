import { useState } from "react";
import type { RadioChangeEvent } from "antd";
import { Button, Form, Input, Radio, Select, Space } from "antd";

import { countryList } from "@ni/common/mocks";

import RegistrationHeader from "../helpers/RegistrationHeader";

import styles from "./styles.module.scss";

const { TextArea } = Input;

const RegistrationContent: React.FC = () => {
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(Number(e.target.value));
  };

  const onFinish = () => {};

  const onFormChange = () => {};

  return (
    <div className={styles["registration-page"]}>
      <RegistrationHeader description="Now we know about you, please tell us a little about your business." />
      <Form
        className={styles["reg-form"]}
        form={form}
        layout="vertical"
        onValuesChange={onFormChange}
        onFinish={onFinish}
      >
        <Form.Item label="Company Name">
          <Input />
        </Form.Item>
        <Form.Item label="Country of incorporation" tooltip="Country of incorporation">
          <Select>
            {countryList.map(country => (
              <Select.Option key={country.countryCode} value={country.countryCode}>
                {country.countryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Address">
          <Input />
        </Form.Item>
        <Form.Item label="Financial license details" tooltip="Financial license details">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Are you PCI DSS certified?"
          tooltip="Are you PCI DSS certified?"
          className="input-group-label"
        >
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}>Yes</Radio>
              <Radio value={2}>No</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <div>
          If you are not PCI DSS we will not expose full card numbers to you and use our alternative API token in all
          our interfaces, APIs, reports, etc.
        </div>
        <Form.Item>
          <Button className={styles["submit-btn"]} type="primary" htmlType="submit">
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegistrationContent;
