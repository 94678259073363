import { FC } from "react";
import { Form, Space, Switch } from "antd";

import { graceScenariosTableColumns, graceScenariosTabsList } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CollapsedTable, CustomFormWrapper, FormItemLabel, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { INTEREST_GRACE_OPTIONS, ZERO_FEES_OPTIONS, ZERO_INTERESTS_OPTIONS } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const InterestGracePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: [INTEREST_GRACE_OPTIONS.code, ZERO_FEES_OPTIONS.code, ZERO_INTERESTS_OPTIONS.code],
    },
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...(values as { [key: string]: string }),
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Interest Grace Options"
      pageSubtitle="In certain cases, interest charges may be waived. The customer's behavior over at least two billing cycles is used to determine whether interest will be waived or not. During the first cycle, interest may be deferred and during the second cycle, deferred interest may be waived. Please specify the grace options below."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={INTEREST_GRACE_OPTIONS.code}
        label={<FormItemLabel label={INTEREST_GRACE_OPTIONS.name} code={INTEREST_GRACE_OPTIONS.code} />}
        initialValue={INTEREST_GRACE_OPTIONS.default}
      >
        <RadioGroup radioList={INTEREST_GRACE_OPTIONS.options} initialValue={INTEREST_GRACE_OPTIONS.default} />
      </Form.Item>

      <CollapsedTable
        title="What if scenarios"
        tabsList={graceScenariosTabsList}
        columns={graceScenariosTableColumns}
      />

      <Space direction="horizontal">
        <Form.Item name={ZERO_FEES_OPTIONS.code} valuePropName="checked" initialValue={ZERO_FEES_OPTIONS.default}>
          <Switch />
        </Form.Item>
        <TooltipInfo label={ZERO_FEES_OPTIONS.name} code={ZERO_FEES_OPTIONS.code} tooltipProps={{}} />
      </Space>

      <Space direction="horizontal">
        <Form.Item
          name={ZERO_INTERESTS_OPTIONS.code}
          valuePropName="checked"
          initialValue={ZERO_INTERESTS_OPTIONS.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo label={ZERO_INTERESTS_OPTIONS.name} code={ZERO_INTERESTS_OPTIONS.code} tooltipProps={{}} />
      </Space>
    </CustomFormWrapper>
  );
};
