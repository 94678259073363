import React from "react";
import { Affix, Button } from "antd";

import { CloseOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

/* eslint-disable-next-line */
export interface MultipleSelectWindowProps {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  count: number;
}

export function MultipleSelectWindow({ isShow, setIsShow, children, count }: MultipleSelectWindowProps) {
  if (!isShow) return null;

  return (
    <Affix
      offsetBottom={0}
      style={{
        width: "calc(100% - 80px)",
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 1000,
        margin: "0 40px",
      }}
    >
      <div className={styles["multiple-select-window"]}>
        <div className={styles["count-items"]}>{count} items selected</div>
        <div className={styles["action-buttons"]}>
          {children}
          <Button type="link" onClick={() => setIsShow(false)}>
            <CloseOutlined className="button-close" />
          </Button>
        </div>
      </div>
    </Affix>
  );
}
