import { Select, Space, Typography } from "antd";
import { DefaultOptionType } from "antd/lib/select";

import { defaultTenantLogo } from "@ni/common/assets";
import { useReduxState } from "@ni/common/hooks";
import { ShortTenant } from "@ni/common/types";
import { checkIfProductCreatedSuccessfully, getFormValueFromProductValues } from "@ni/common/utils";
import {
  BaseLoyaltyProgramDashboard,
  InsuranceProgram,
  InsuranceProgramTemplate,
  LoyaltyProgram,
  ParameterTable,
  Plan,
  Product,
  ShortLoyaltyProgram,
} from "@ni/sdk/models";

import { CardView } from "../CardView";

import styles from "./styles.module.scss";

const { Title } = Typography;

interface SidebarSelectProps {
  value: number | undefined;
  entities:
    | Product[]
    | ShortTenant[]
    | ParameterTable[]
    | BaseLoyaltyProgramDashboard[]
    | ShortLoyaltyProgram[]
    | LoyaltyProgram[]
    | Plan[]
    | InsuranceProgramTemplate[];
  onChange: ((value: number, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
}

export const SidenavEntitySelect = ({ value, entities, onChange }: SidebarSelectProps) => {
  const [isLoading] = useReduxState("isLoading", false);

  return (
    <Select className={styles["ni-entity-select"]} value={value} onChange={onChange} disabled={isLoading}>
      {(entities ?? []).map(entity => {
        const product = typeof (entity as Product).productState === "string" ? (entity as Product) : undefined;

        const tenant =
          typeof (entity as ShortTenant)?.financialInstitutionId === "number" ? (entity as ShortTenant) : undefined;

        const pct =
          ((entity as ParameterTable).pctProductValues ?? []).length > 0 ? (entity as ParameterTable) : undefined;

        const plan = (entity as Plan)?.state !== "DRAFT" && (entity as Plan)?.planValues ? (entity as Plan) : undefined;

        const insurance =
          (entity as InsuranceProgramTemplate)?.state !== "DRAFT" &&
          (entity as InsuranceProgramTemplate)?.values &&
          ((entity as InsuranceProgramTemplate)?.currency || (entity as InsuranceProgram)?.insuranceProgramTemplateId)
            ? (entity as Plan)
            : undefined;

        const loyaltyTemplate =
          (entity as BaseLoyaltyProgramDashboard)?.state &&
          !(entity as LoyaltyProgram)?.loyaltyCode &&
          (entity as BaseLoyaltyProgramDashboard)?.accrualDisplayDetails
            ? (entity as BaseLoyaltyProgramDashboard)
            : undefined;

        const loyaltyProgram =
          (entity as LoyaltyProgram)?.loyaltyCode && (entity as LoyaltyProgram)?.state
            ? (entity as LoyaltyProgram)
            : undefined;

        if (product && checkIfProductCreatedSuccessfully((entity as Product).lastProcessedPage?.code as string)) {
          return (
            <Select.Option key={product.id} value={product.id}>
              <Space align="center" size={13}>
                <CardView
                  cardSize="small"
                  cardImage={product.cardImage}
                  prodIps={(getFormValueFromProductValues(product.productValues, "nic-prod-ips") as string) || "-"}
                />
                <Title level={5} className={styles["ni-entity-select_label"]} title={product.displayName}>
                  {product.displayName}
                </Title>
              </Space>
            </Select.Option>
          );
        }

        if (tenant) {
          return (
            <Select.Option key={tenant.id} value={tenant.id}>
              <Space align="center" size={13}>
                <img className={styles["ni-entity-select_logo"]} src={defaultTenantLogo} alt="tenant-logo" />
                <Title level={5} className={styles["ni-entity-select_label"]} title={tenant.name}>
                  {tenant.name}
                </Title>
              </Space>
            </Select.Option>
          );
        }

        if (pct) {
          return (
            <Select.Option key={pct.id} value={pct.id}>
              <Space align="center">
                <Title level={5} className={styles["ni-entity-select_label"]} title={pct.displayName}>
                  {pct.displayName}
                </Title>
              </Space>
            </Select.Option>
          );
        }

        if (plan) {
          return (
            <Select.Option key={plan?.id} value={plan?.id}>
              <Space align="center">
                <Title level={5} className={styles["ni-entity-select_label"]} title={plan?.name}>
                  {plan?.name}
                </Title>
              </Space>
            </Select.Option>
          );
        }

        if (insurance) {
          return (
            <Select.Option key={insurance?.id} value={insurance?.id}>
              <Space align="center">
                <Title level={5} className={styles["ni-entity-select_label"]} title={insurance?.name}>
                  {insurance?.name}
                </Title>
              </Space>
            </Select.Option>
          );
        }

        if (loyaltyProgram) {
          return (
            <Select.Option key={loyaltyProgram?.id} value={loyaltyProgram?.id}>
              <Space align="center">
                <Title level={5} className={styles["ni-entity-select_label"]} title={loyaltyProgram?.name}>
                  {loyaltyProgram?.loyaltyCode?.toUpperCase()} {loyaltyProgram?.name}
                </Title>
              </Space>
            </Select.Option>
          );
        }

        if (loyaltyTemplate) {
          return (
            <Select.Option key={loyaltyTemplate.id} value={loyaltyTemplate.id}>
              <Space align="center">
                <Title level={5} className={styles["ni-entity-select_label"]} title={loyaltyTemplate.name}>
                  {loyaltyTemplate.name ?? "Template Name"}
                </Title>
              </Space>
            </Select.Option>
          );
        }

        return null;
      })}
    </Select>
  );
};
