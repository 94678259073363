/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ElementType = {
    ONE_OF: 'ONE_OF',
    MULTIPLE_OF: 'MULTIPLE_OF',
    DROPDOWN: 'DROPDOWN',
    DROPDOWN_MULTISELECT: 'DROPDOWN_MULTISELECT',
    TEXT: 'TEXT',
    DATE_PICKER: 'DATE_PICKER',
    DATE_TIME_PICKER: 'DATE_TIME_PICKER',
    TIME_PICKER: 'TIME_PICKER',
    BOOLEAN_CHECKBOX: 'BOOLEAN_CHECKBOX',
    BOOLEAN_SWITCH: 'BOOLEAN_SWITCH',
    BLOCK_HEADER: 'BLOCK_HEADER',
    TAB: 'TAB',
    SUB_TAB: 'SUB_TAB',
    TABLE_HEADER: 'TABLE_HEADER',
    TABLE_LABELS: 'TABLE_LABELS',
    TABLE_ROW_OF_2: 'TABLE_ROW_OF_2',
    TABLE_ROW_OF_3: 'TABLE_ROW_OF_3',
    TABLE_ROW_OF_4: 'TABLE_ROW_OF_4',
    TABLE_ROW_OF_5: 'TABLE_ROW_OF_5'
} as const;

export type ElementType = typeof ElementType[keyof typeof ElementType];



