import { useCallback } from "react";

import { useReduxState } from "@ni/common/hooks";
import { IPPWizardApi } from "@ni/sdk/apis";
import {
  InstallmentPaymentPlanWizardRequest,
  InstallmentPaymentPlanWizardResponse,
  Tenant,
  Workflow,
} from "@ni/sdk/models";

const ippWizardApi = new IPPWizardApi();

export const useIPPWizardApi = () => {
  const [, setWizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  const initWizard = useCallback(async (tenantId: string) => {
    try {
      const response = await ippWizardApi.initWizard1({ tenantId: parseInt(tenantId, 10) });

      return {
        pageId: response.data.pageId,
        workflow: response.data.workflow,
        tenant: response.data.tenant,
      };
    } catch (error) {
      return {
        pageId: 0,
        workflow: {} as Workflow,
        tenant: {} as Tenant,
      };
    }
  }, []);

  const processWizardRequest = useCallback(
    async (ippWizardRequest: InstallmentPaymentPlanWizardRequest) => {
      const response = await ippWizardApi.processIPPWizardRequest(ippWizardRequest);
      setWizardResponse(response.data);
    },
    [setWizardResponse],
  );

  return {
    initWizard,
    processWizardRequest,
  };
};
