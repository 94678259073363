import { ReactNode } from "react";
import { Space } from "antd";
import { Rule } from "rc-field-form/lib/interface";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
  rules?: Rule[];
  addonAfter?: ReactNode;
}

export const enableIncentiveFeeOption: Options = {
  code: "ipp-incent-fee-enabled",
  name: "Enable IPP incentive fee",
  default: true,
};

export const feeFixedOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-base",
  name: "Fee fixed",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>This parameter works with Fee % parameter.</div>
      <div>Incentive Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};

export const feePercentageOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-rate",
  name: "Fee %",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  addonAfter: "%",
  tooltip: (
    <Space direction="vertical">
      <div>This parameter works with Fee fIxed parameter.</div>
      <div>Incentive Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};

export const minFeeOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-min",
  name: "Min fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated fee is lower than the Min fee defined as part of this field then Min fee will be charged
        to the customer.
      </div>
      <div>If Min Fee &gt; Fee fixed + Fee % then Min Fee will be considered as Incentive Fee</div>
    </Space>
  ),
};

export const maxFeeOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-max",
  name: "Max fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated fee is greater than the Max fee defined as part of this field then Max fee will be
        calculated.
      </div>
      <div>If Max Fee &lt; Fee fixed + Fee % then Max Fee will be considered as Incentive Fee.</div>
    </Space>
  ),
};
