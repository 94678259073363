import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.86293 2.70021L7.27713 4.11441M9.16276 1.33337V3.33337M12.4626 2.70021L11.0484 4.11441M13.8294 6.00004H11.8294M12.4626 9.29987L11.0484 7.88567M9.16276 10.6667V8.66671M5.86293 9.29987L7.27713 7.88567M4.49609 6.00004H6.49609" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.0256 6.13818L1.01172 14.1521" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const WandIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
