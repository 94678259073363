import { ReactNode } from "react";
import { Space } from "antd";

import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

// tab 1
const fixedOption: NumericOptions = {
  code: "ins-prem-fixed",
  name: "Insurance fixed premium amount",
  decimalsNumber: 2,
  min: 0,
  tooltip:
    "You can customize this parameter later on applied product level or for each pricing control table profile settings",
};
const percentageOption: NumericOptions = {
  code: "ins-prem-rate",
  name: "Insurance premium percentage",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  default: 0.89,
  tooltip: (
    <Space direction="vertical">
      <div>
        You can customize this parameter later on applied product level or for each pricing control table profile
        settings
      </div>
      <div>
        This percentage will be charged on customer outstanding liability in addition to the insurance fixed premium
        amount.
      </div>
    </Space>
  ),
};
const capOption: NumericOptions = {
  code: "ins-prem-min",
  name: "Minimum insurance premium cap",
  decimalsNumber: 2,
  min: 0,
  tooltip: (
    <Space direction="vertical">
      <div>
        You can customize this parameter later on applied product level or for each pricing control table profile
        settings
      </div>
      <div>
        This specifies the minimum amount that will be charged to customer, if the Total Insurance Premium (output of
        the Insurance fixed amount + Insurance premium percentage formula amount) is less
      </div>
    </Space>
  ),
};
const periodOption: NumericOptions = {
  code: "ins-prem-period",
  name: "Specify period before charging the first insurance premium",
  decimalsNumber: 0,
  min: 0,
  tooltip:
    "This parameter specifies the number of months or billing cycles to auto reverse the insurance premium. this depends on selected period type",
};
const periodTypeOption: RadioOptions = {
  code: "ins-prem-period-type",
  name: "Period type",
  default: "M",
  options: [
    {
      label: "Calendar Months",
      value: "M",
    },
    {
      label: "Full Billing cycles",
      value: "B",
      tooltip: "Cycle of boarding is not counted",
    },
  ],
};
export const tab1 = {
  fixedOption,
  percentageOption,
  capOption,
  periodOption,
  periodTypeOption,
} as const;
