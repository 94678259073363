import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8867 0.333313H6.22005C5.48472 0.333313 4.88672 0.931313 4.88672 1.66665V4.33331H2.22005C1.48472 4.33331 0.886719 4.93131 0.886719 5.66665V12.3333C0.886719 13.0686 1.48472 13.6666 2.22005 13.6666H8.88672C9.62205 13.6666 10.2201 13.0686 10.2201 12.3333V9.66665H12.8867C13.6221 9.66665 14.2201 9.06865 14.2201 8.33331V1.66665C14.2201 0.931313 13.6221 0.333313 12.8867 0.333313ZM2.22005 12.3333V5.66665H8.88672L8.88805 12.3333H2.22005ZM12.8867 8.33331H10.2201V5.66665C10.2201 4.93131 9.62205 4.33331 8.88672 4.33331H6.22005V1.66665H12.8867V8.33331Z"
      fill="currentColor"
    />
  </svg>
);

export const CopyIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
