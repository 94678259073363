import { PRODUCT_CURRENCY } from "@ni/common/constants";
import { FilterInputProps } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { InsuranceProgramTemplateState } from "@ni/sdk/models";

export const insuranceFilterInputs: FilterInputProps[] = [
  {
    name: "state",
    type: "select",
    props: {
      placeholder: "State",
      options: [
        { value: "", label: "All States" },
        { value: InsuranceProgramTemplateState.DRAFT, label: "Draft" },
        { value: InsuranceProgramTemplateState.ACTIVE, label: "Enabled" },
        { value: InsuranceProgramTemplateState.INACTIVE, label: "Disabled" },
      ],
    },
  },
  {
    name: "currency",
    type: "dictionary",
    props: {
      code: PRODUCT_CURRENCY,
      showSearch: true,
      placeholder: "Currency",
      options: [{ value: "", label: "All Currencies" }],
      filterOption: filterByDisplayValue,
    },
  },
];
