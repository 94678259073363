import { FC, useCallback } from "react";
import { Select, Steps } from "antd";

import { WizardPlacement } from "@ni/common/types";
import { Page, Phase } from "@ni/sdk/models";

import styles from "./styles.module.scss";

export interface WizardStepsSidebarProps {
  devMode?: boolean;
  wizardPlacement: WizardPlacement;
  lastSubmittedStep: WizardPlacement;
  current: number;
  phaseList: Phase[];
  onChangeStep: (pageKey: string, phase: number) => void;
}

const { Step } = Steps;

export const WizardStepsSidebar: FC<WizardStepsSidebarProps> = ({
  devMode = false,
  current,
  phaseList,
  wizardPlacement,
  lastSubmittedStep,
  onChangeStep,
}) => {
  const renderColor = useCallback(
    (page: Page, phaseOrder: Phase) => {
      const { pageKey: wizardPage, phase: wizardPhase } = wizardPlacement;
      const { pageKey: lastSubmittedPage, phase: lastSubmittedPhase } = lastSubmittedStep;

      const getPageOrder = (code: string) => phaseOrder.pages?.find(currPage => currPage.code === code)?.order || 0;

      if (page.code === wizardPage) {
        return <div key={page.id} className={styles["rectangle-active"]} />;
      }

      if (phaseOrder.order === lastSubmittedPhase) {
        const pageOrder = getPageOrder(page.code!);
        const currentPageOrder = getPageOrder(lastSubmittedPage);

        if (pageOrder <= currentPageOrder) {
          return (
            <div
              role="presentation"
              key={page.id}
              className={styles["rectangle-blue"]}
              onClick={() => onChangeStep(page.code!, wizardPlacement.phase)}
            />
          );
        }
        return <div key={page.id} className={styles["rectangle"]} />;
      }

      if (phaseOrder.order === wizardPhase) {
        const pageOrder = getPageOrder(page.code!);
        const currentPageOrder = getPageOrder(wizardPage);

        if (pageOrder < currentPageOrder) {
          return (
            <div
              role="presentation"
              key={page.id}
              className={styles["rectangle-done"]}
              onClick={() => onChangeStep(page.code!, wizardPlacement.phase)}
            />
          );
        }
        if (pageOrder === currentPageOrder) {
          return (
            <div
              role="presentation"
              key={page.id}
              className={styles["rectangle-active"]}
              onClick={() => onChangeStep(page.code!, wizardPlacement.phase)}
            />
          );
        }
        return <div key={page.id} className={styles["rectangle"]} />;
      }

      return phaseOrder.order! < lastSubmittedPhase ? (
        <div
          role="presentation"
          key={page.id}
          className={styles["rectangle-done"]}
          onClick={() => onChangeStep(page.code!, wizardPlacement.phase)}
        />
      ) : (
        <div key={page.id} className={styles["rectangle"]} />
      );
    },
    [lastSubmittedStep, onChangeStep, wizardPlacement],
  );

  return (
    <div className={styles["progress-bar"]}>
      <Steps size="small" direction="vertical" current={current}>
        {phaseList?.map(phase => (
          <Step
            key={phase.order}
            title={phase.name}
            description={phase.pages
              ?.slice()
              .sort((a, b) => (a.order || 0) - (b.order || 0))
              .map(page => renderColor(page, phase))}
          />
        ))}
      </Steps>
      {devMode && (
        <Select
          placeholder="Select a branch"
          style={{ marginBottom: "16px" }}
          onChange={(stringifiedPage: string) => {
            const el = JSON.parse(stringifiedPage) as WizardPlacement;
            onChangeStep(el?.pageKey ?? "", el?.phase ?? 0);
          }}
        >
          {phaseList
            .map(phase => phase.pages?.map(page => ({ page: page.id, phase: phase.order, name: page.header })))
            .flat(1)
            .map(el => (
              <Select.Option key={el?.page} value={JSON.stringify({ page: el?.page ?? 0, phase: el?.phase ?? 0 })}>
                {el?.page}-{el?.name}
              </Select.Option>
            ))}
        </Select>
      )}
    </div>
  );
};
