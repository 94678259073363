import { useCallback, useMemo } from "react";

import { ConfigurationApi } from "@ni/sdk/apis";

export const useConfigurationApi = () => {
  const api = useMemo(() => new ConfigurationApi(), []);

  const getDictionariesByCode = useCallback(
    async (code: string) => {
      try {
        const response = await api.getDictionariesByCode(code);
        return { code: response.data.code, dictionaries: response.data.dictionaries };
      } catch {
        return { code: null, dictionaries: null };
      }
    },
    [api],
  );

  return { getDictionariesByCode };
};
