import { useCallback, useEffect, useState } from "react";

import { useReduxState } from "@ni/common/hooks";
import { TenantApi } from "@ni/sdk/apis";
import { Product, Tenant } from "@ni/sdk/models";

const tenantServiceApi = new TenantApi();

interface UseProductsProps {
  tenantId: number;
}

export const useProducts = ({ tenantId = 0 }: UseProductsProps) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [, setTenant] = useReduxState<Tenant>("tenant", {});

  const [products, setProducts] = useState<Product[]>([]);

  const fetchTenant = useCallback(
    async (tenantId: number) => {
      setIsLoading(true);

      try {
        const tenantResponse = await tenantServiceApi.getTenantById(tenantId);

        setTenant(tenantResponse.data || {});
        setProducts(tenantResponse?.data.products || []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setIsLoading, setTenant],
  );

  useEffect(() => {
    void fetchTenant(tenantId);
  }, [fetchTenant, tenantId]);

  return [products, setProducts] as [Product[], React.Dispatch<React.SetStateAction<Product[]>>];
};
