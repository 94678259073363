import { FC } from "react";
import { Form, notification, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import { useGetProductsToApply } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, ProductsTable } from "@ni/common/ui";

import { useInsuranceApi } from "../../../../hooks";

import { productsOption } from "./constants";

export const InsuranceApplyingProducts: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const navigate = useNavigate();
  const { currentInsurance, applyToProducts } = useInsuranceApi();

  const { products, refreshProducts } = useGetProductsToApply(currentInsurance.tenantId, "INSURANCE", {
    insurance_program_template_id: currentInsurance?.id?.toString() ?? "0",
  });

  const onFinish = async (values: FormValues) => {
    if (values[productsOption.code] && (values[productsOption.code] as unknown[])?.length > 0) {
      try {
        const response = await applyToProducts(values[productsOption.code] as unknown as Set<number>);
        if (response?.data?.length > 0) {
          const selectedIds = values[productsOption.code] as number[];
          const productInsurnacePrograms = response?.data ?? [];
          refreshProducts();
          notification.success({
            placement: "topRight",
            duration: null,
            message: (
              <Space direction="vertical">
                <Typography.Text strong={true}>Success</Typography.Text>
                <Typography.Text>Programs have been created successfully</Typography.Text>
                <Space direction="vertical" size="middle">
                  {selectedIds.map(id => (
                    <Typography.Text key={id}>
                      For product {products?.find(x => x.product.id === id)?.product.name} insurance program
                      <Typography.Link
                        color="info"
                        onClick={() => {
                          navigate(`/tenant/${currentInsurance.tenantId}/product/${id}/insurance-programs`);
                        }}
                      >
                        {` ${
                          productInsurnacePrograms
                            .reverse()
                            .find(x => x.insuranceProgramTemplateId === currentInsurance.id)?.name ?? ""
                        } `}
                      </Typography.Link>
                      has been created
                    </Typography.Text>
                  ))}
                </Space>
              </Space>
            ),
          });
        } else {
          throw new Error();
        }
      } catch (error) {
        notification.error({
          placement: "topRight",
          duration: 5,
          message:
            "One or more of selected products have reached the maximum of 12 applied insurance programs, please unselect and try again",
        });
      } finally {
        form.resetFields();
      }
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Applying to Products"
      pageSubtitle="Select the product to apply your template on to create programs. You can modify created programs on each product independently after wizard. You can also customize some of the parameters for each pricing control profile under your product for specific cardholder groups. Modifications on existing template will not impact previously created programs settings."
      level="tenant"
      size="md"
      formSize="full"
      gap={40}
      submitLabel="Save"
      linkTitle="Insurance Templates"
      onValuesChange={form.validateFields}
      submitHandler={onFinish}
      additionalRoute="./insurance-program-templates"
      relatedLinks={products && products?.length > 0 ? [] : [{ href: "/", label: "Products Dashboard" }]}
      buttonDisabled={!products || !products?.length}
    >
      <Form.Item name={productsOption.code}>
        <ProductsTable
          products={products}
          hasCheckAll={true}
          noDataMessage="No eligible products were found for this template. Please make sure you have created a draft credit card product under template currency"
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
