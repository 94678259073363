import { FC } from "react";
import { Form } from "antd";

import {
  LTY_WELC_BONUS_ENABLE,
  NIC_LTY_BONUS_W_TAR_BASE,
  NIC_LTY_BONUS_W_TIMER_FROM,
  NIC_LTY_BONUS_W_TIMER_PER,
  NIC_LTY_BONUS_W_TIMER_PER_TYPE,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { isNumeric, removeGapsFromStringNumeric } from "@ni/common/utils";
import { LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

import { RADIO_GROUP_TIMER_FROM, RADIO_GROUP_TIMER_PER_TYPE } from "./constants";

export const WelcomeBonus: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const isBonusEnable = Form.useWatch<boolean>(LTY_WELC_BONUS_ENABLE, form);

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programValues ?? [],
    keys: {
      strings: [
        LTY_WELC_BONUS_ENABLE,
        NIC_LTY_BONUS_W_TAR_BASE,
        NIC_LTY_BONUS_W_TIMER_FROM,
        NIC_LTY_BONUS_W_TIMER_PER,
        NIC_LTY_BONUS_W_TIMER_PER_TYPE,
      ],
    },
  });

  const onFinish = async (values: FormValues) => {
    const formValues: FormValues = Object.keys(values).reduce((newValues, fieldName) => {
      const value = values[fieldName];
      newValues[fieldName] = isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value;
      return newValues;
    }, {} as FormValues);

    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: formValues as {
          [key: string]: string;
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Welcome Bonus"
      pageSubtitle="New customers could receive a certain number of bonus points upon signing up for the loyalty program or after a fixed period of time."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: LTY_WELC_BONUS_ENABLE,
          label: "Enable welcome bonus on your loyalty program",
          initialValue: "true",
        }}
        checked={isBonusEnable}
      />

      {isBonusEnable && (
        <>
          <NetworkForm.Number
            formItemOptions={{
              name: NIC_LTY_BONUS_W_TAR_BASE,
              label: "Welcome bonus amount (number of points)",
              rules: [{ required: isBonusEnable }],
            }}
            min={0}
            precision={2}
            stringMode={true}
          />

          <NetworkForm.Radio
            formItemOptions={{
              name: NIC_LTY_BONUS_W_TIMER_FROM,
              label: "Welcome bonus timer trigger (count down starts from)",
              initialValue: "START_CONTRACT",
            }}
            radioList={RADIO_GROUP_TIMER_FROM}
            initialValue="START_CONTRACT"
          />

          <NetworkForm.Radio
            formItemOptions={{
              name: NIC_LTY_BONUS_W_TIMER_PER_TYPE,
              label: "Welcome bonus count down timer period",
              initialValue: "",
            }}
            radioList={RADIO_GROUP_TIMER_PER_TYPE}
            initialValue=""
          />

          <NetworkForm.Number
            formItemOptions={{
              name: NIC_LTY_BONUS_W_TIMER_PER,
              label: "Welcome Bonus count down timer (number of periods)",
              initialValue: 0,
            }}
            min={0}
            precision={0}
            stringMode={true}
          />
        </>
      )}
    </CustomFormWrapper>
  );
};
