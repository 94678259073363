import { FC, useEffect, useMemo, useState } from "react";
import { Form, FormInstance, Mentions, Select, Space, Switch } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { ControlCharactersExceptEnterRegex } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { SmsVariablePreview } from "@ni/common/ui";
import { displayReadableSmsVariables } from "@ni/common/utils";
import { VariablesApi } from "@ni/sdk/apis";
import { Variable } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface TemplateDetailsProps {
  drawerForm?: FormInstance<FormValues>;
  subgroupId: number | undefined;
}

const variablesServiceApi = new VariablesApi();

export const TemplateDetails: FC<TemplateDetailsProps> = props => {
  const { subgroupId, drawerForm } = props;
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const text = Form.useWatch("fieldValue", drawerForm);

  const [smsVariables, setSmsVariables] = useState<Variable[]>([]);
  useEffect(() => {
    if (smsVariables?.length === 0) {
      setIsLoading(true);
      variablesServiceApi
        .getVariablesBySubgroupId(subgroupId!)
        .then(res => {
          setSmsVariables(res.data);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [setIsLoading, smsVariables?.length, subgroupId]);

  const onChangeValue = (value: string) => {
    drawerForm?.setFieldsValue({
      fieldValue: value.replace("%%", "%")?.replace(ControlCharactersExceptEnterRegex, ""),
    });
  };

  const { decodedText, maxLength } = useMemo(() => {
    const decodedText = displayReadableSmsVariables((text ?? "") as string, smsVariables);
    return { decodedText, maxLength: decodedText?.length > 512 ? decodedText.length : 1000 };
  }, [text, smsVariables]);

  return (
    <div className={styles["template-details"]}>
      <Form form={drawerForm} layout="vertical">
        <Space direction="vertical" size={32} className="w-p-100">
          <Space direction="horizontal">
            <Form.Item name="isActive" valuePropName="checked" className="m-b-0">
              <Switch />
            </Form.Item>
            Enable
          </Space>

          <Form.Item name="default-lang" label="Default language" className="m-b-0">
            <Select disabled={true}>
              <Select.Option key="English" value="English">
                English
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="fieldValue"
            label="Message content"
            tooltip={{
              title:
                "Specify the text of SMS. If you want to add a variable, start typing % and select it from drop-down list",
              icon: <QuestionCircleFilled />,
            }}
            className="m-b-0"
            rules={[{ max: maxLength, message: "Maximum allowed characters is 512" }]}
          >
            <Mentions
              rows={6}
              prefix="%"
              onChange={onChangeValue}
              maxLength={maxLength}
              options={smsVariables?.map(smsVariable => ({
                key: smsVariable?.code,
                label: smsVariable.description,
                value: smsVariable?.code,
              }))}
            />
          </Form.Item>

          <Form.Item dependencies={["fieldValue"]} className="m-b-0">
            {() => (
              <Form.Item name="fieldValue" label="Message displayed text">
                <SmsVariablePreview text={decodedText} />
              </Form.Item>
            )}
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};
