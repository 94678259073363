import { useMemo } from "react";
import { Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { NIC_CCY, NIC_SMS_DUE_FLAG } from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import { agingDayOptions, dueDateOptions, mtpMinimumOptions, mtpOptions, smsDueReminderOptions } from "./constants";

export const PaymentDuePage = () => {
  const { id, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();

  const [form] = Form.useForm<FormValues>();
  const { currentProduct, pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const hasSmsDueReminder = getFormValueFromProductValues(currentProduct?.productValues, NIC_SMS_DUE_FLAG, "boolean");

  const currency = useMemo(
    () => currentProduct.productValues?.find(x => x.fieldCode === NIC_CCY)?.value,
    [currentProduct.productValues],
  );

  useHydrateForm({
    form,
    entityFields: [...(currentProduct?.productValues ?? []), ...(pct?.pctProductValues ?? [])],
    keys: {
      strings: [
        agingDayOptions.code,
        dueDateOptions.code,
        mtpMinimumOptions.code,
        mtpOptions.code,
        smsDueReminderOptions.code,
      ],
    },
    allowParse: true,
  });

  const onFinish = onSavePct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Payment Due"
      pageSubtitle={
        <Space direction="vertical">
          <div>
            At the closing of each billing cycle, Minimum to Pay (MTP) amount is calculated. The customer is expected to
            make a payment equal to or greater than the minimum to pay amount by the due date, to avoid late payment
            fees and being delinquent.
          </div>
          <div>
            MTP amount is calculated from the balance components, which are included as MTP percentage% or fully (100%).
          </div>
        </Space>
      }
      size="md"
      formSize="md"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={[
        {
          href: `/tenant/${id}/product/${productId}/minimum-to-pay-formula`,
          label: `${currentProduct.displayName} - Minimum to Pay Formula`,
        },
        { href: `/tenant/${id}/sms-templates`, label: `SMS templates - Global` },
      ]}
    >
      <NetworkForm.Number
        precision={dueDateOptions.decimalsNumber}
        min={dueDateOptions.min}
        max={dueDateOptions.max}
        formItemOptions={{
          name: dueDateOptions.code,
          label: dueDateOptions.name,
          tooltip: dueDateOptions.tooltip,
        }}
      />

      {hasSmsDueReminder && (
        <NetworkForm.Number
          precision={smsDueReminderOptions.decimalsNumber}
          min={smsDueReminderOptions.min}
          max={smsDueReminderOptions.max}
          formItemOptions={{
            name: smsDueReminderOptions.code,
            label: smsDueReminderOptions.name,
            tooltip: smsDueReminderOptions.tooltip,
            rules: [{ required: true, message: `SMS due reminder is required!` }],
          }}
        />
      )}

      <NetworkForm.Number
        precision={agingDayOptions.decimalsNumber}
        min={agingDayOptions.min}
        max={agingDayOptions.max}
        formItemOptions={{
          name: agingDayOptions.code,
          label: agingDayOptions.name,
          tooltip: agingDayOptions.tooltip,
        }}
      />

      <NetworkForm.Number
        addonAfter="%"
        precision={mtpOptions.decimalsNumber}
        min={mtpOptions.min}
        max={mtpOptions.max}
        formItemOptions={{
          name: mtpOptions.code,
          label: mtpOptions.name,
          tooltip: mtpOptions.tooltip,
        }}
      />

      <NetworkForm.Number
        addonAfter={currency}
        precision={mtpMinimumOptions.decimalsNumber}
        min={mtpMinimumOptions.min}
        max={mtpMinimumOptions.max}
        formItemOptions={{
          name: mtpMinimumOptions.code,
          label: mtpMinimumOptions.name,
          tooltip: mtpMinimumOptions.tooltip,
        }}
      />
    </CustomFormWrapper>
  );
};
