import { FC } from "react";
import { Form, Space, Switch, Typography } from "antd";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, NetworkForm } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { murabahaFeeRate, murabahaInterestRate, murabahaProfitRate } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

const stringsKeys = [murabahaProfitRate.code, murabahaFeeRate.code, murabahaInterestRate.code];

export const IslamicCreditMurabahaPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: stringsKeys,
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && wizardResponse?.product?.productValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Murabaha Profit Settings"
      pageSubtitle="Murabaha, also referred to as cost-plus financing, is an Islamic financing structure in which the seller provides the cost and profit margin of an asset. Murabaha is not an interest-bearing loan (qardh ribawi) but is an acceptable form of credit sale under Islamic law. For Islamic covered Murabaha cards, two transactions are posted to the cardholder on the statement day, If a complete and timely payment is made, all profit on the retail balance will be forgiven."
      size="md"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={murabahaProfitRate.code}
        label={<FormItemLabel label={murabahaProfitRate.label} code={murabahaProfitRate.code} />}
        tooltip={{
          icon: <QuestionCircleFilled />,
          title: murabahaProfitRate.tooltip,
        }}
        rules={[{ required: true, message: "Murabaha profit rate is required" }]}
      >
        <NetworkForm.Number {...murabahaProfitRate.props} />
      </Form.Item>

      <Space direction="vertical" size={16}>
        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={murabahaFeeRate.code}>
            <Switch disabled={formDisabled} />
          </Form.Item>
          <FormItemLabel code={murabahaFeeRate.code}>
            <Typography.Text>{murabahaFeeRate.label}</Typography.Text>
          </FormItemLabel>
        </Space>

        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={murabahaInterestRate.code}>
            <Switch disabled={formDisabled} />
          </Form.Item>
          <FormItemLabel code={murabahaInterestRate.code}>
            <Typography.Text>{murabahaInterestRate.label}</Typography.Text>
          </FormItemLabel>
        </Space>
      </Space>
    </CustomFormWrapper>
  );
};
