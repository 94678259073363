/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { blockCodeValuesAndLabels, ProductBcParams, tableCellValues } from "@ni/common/constants";
import { SelectOption } from "@ni/common/types";
import { BlockCode } from "@ni/sdk/models";

export const codes = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "_",
];

export type BlockInput = { type: "string" } | { type: "number" } | { type: "select"; options: SelectOption[] };

export type BlockItem = {
  isCategory?: false;
  fieldCode: string;
  label: string;
  tooltip?: string;
  input: BlockInput;
};

export type BlockCategory = {
  isCategory: true;
  name: string;
  tooltip?: string;
  items: (BlockItem | BlockCategory)[];
};

export type BlockCodes = BlockCategory | BlockItem;

export type BlockCodeObject = {
  code: string;
  name: string;
  priority: string;
  blockCodeValues: BlockCode[];
};

export const fieldCodesToMap: string[] = [
  "bc-a1-tib1",
  "bc-a2-tib1",
  "bc-c1-tib1",
  "bc-a1-tib2",
  "bc-a2-tib2",
  "bc-c1-tib2",
  "bc-a1-fa",
  "bc-a2-fa",
  "bc-c1-fa",
  "bc-a1-sa",
  "bc-a2-sa",
  "bc-c1-sa",
  "bc-a1-man",
  "bc-a2-man",
  "bc-c1-man",
];

export const booleanFields: string[] = [
  "nic-bc-a1-allow",
  "nic-bc-a2-allow",
  "nic-bc-c1-allow",
  "nic-bc-a1-from-noncompl",
  "nic-bc-c1-from-noncompl_c",
  "nic-dlq_perm",
  "nic-r_card_act",
  "nic-r_card_autorepl",
  "nic-fc_ncon",
];

export const fieldCodeLabels: BlockCodes[] = [
  // Tab 1 - Code settings
  {
    isCategory: true,
    name: "Code Settings",
    items: [
      {
        fieldCode: "nic-bc-name",
        label: "Name",
        tooltip: "Status Management name is used to define the significance of a specific status management code.",
        input: {
          type: "string",
        },
      },
      {
        fieldCode: "nic-bc-prior",
        label: "Priority",
        tooltip:
          "This parameter specifies the priority of the Status Management Code. Each block code is linked to a priority ranging from 00 to 99. A lower number indicates a lower priority. Blocks with lower priority cannot be overridden by block codes with higher priority.",
        input: {
          type: "number",
        },
      },
      {
        fieldCode: "nic-fc_auth",
        label: "Authorization response",
        tooltip:
          "Authorization Response will be either approved or declined based on the “Status Management Code” provided. The response for the authorization will be determined accordingly.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.authorisation)?.map(({ value, displayed }) => ({
              label: `${value} ${displayed}`,
              value,
            })) ?? [],
        },
      },
      {
        isCategory: true,
        name: "Could be applied on",
        tooltip:
          "There are three specifiers for status management codes; Account level (Automatic and User defined) and (Card Level), The User Should selects a specifier then determine which channel the “Status management Code” will be implemented (Unspecify if the channel is not used)",
        items: [
          {
            fieldCode: "nic-bc-a1-allow",
            label: "Account automatically",
            input: {
              type: "select",
              options: [
                { label: "Enabled", value: "true" },
                { label: "Disabled", value: "false" },
              ],
            },
          },
          {
            fieldCode: "nic-bc-a2-allow",
            label: "Account manually",
            input: {
              type: "select",
              options: [
                { label: "Enabled", value: "true" },
                { label: "Disabled", value: "false" },
              ],
            },
          },
          {
            fieldCode: "nic-bc-c1-allow",
            label: "Card",
            input: {
              type: "select",
              options: [
                { label: "Enabled", value: "true" },
                { label: "Disabled", value: "false" },
              ],
            },
          },
          {
            isCategory: true,
            name: "Could be applied via",
            items: [
              {
                isCategory: true,
                name: "Customer support screen",
                items: [
                  {
                    fieldCode: "bc-a1-man",
                    label: "Account automatically",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-a2-man",
                    label: "Account manually",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-c1-man",
                    label: "Card",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                ],
              },
              {
                isCategory: true,
                name: "API by Client-driven application",
                items: [
                  {
                    fieldCode: "bc-a1-tib1",
                    label: "Account automatically",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-a2-tib1",
                    label: "Account manually",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-c1-tib1",
                    label: "Card",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                ],
              },
              {
                isCategory: true,
                name: "API by Issuer-driven application",
                items: [
                  {
                    fieldCode: "bc-a1-tib2",
                    label: "Account automatically",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-a2-tib2",
                    label: "Account manually",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-c1-tib2",
                    label: "Card",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                ],
              },
              {
                isCategory: true,
                name: "File application",
                items: [
                  {
                    fieldCode: "bc-a1-fa",
                    label: "Account automatically",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-a2-fa",
                    label: "Account manually",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-c1-fa",
                    label: "Card",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                ],
              },
              {
                isCategory: true,
                name: "Screen application",
                items: [
                  {
                    fieldCode: "bc-a1-sa",
                    label: "Account automatically",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-a2-sa",
                    label: "Account manually",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                  {
                    fieldCode: "bc-c1-sa",
                    label: "Card",
                    input: {
                      type: "select",
                      options: tableCellValues?.map(({ value, displayed }) => ({ label: displayed, value })),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  // Tab 2- Assignment conditions
  {
    isCategory: true,
    name: "Assignment Conditions",
    tooltip:
      "Some factors may automatically assign Status Management Code to a card or an account. These factors can be specified here.",
    items: [
      {
        fieldCode: "nic-bc-a1-from-noncompl",
        label: "Assigned due to primary client ID expiration (account holder)",
        input: {
          type: "select",
          options: [
            { label: "Enabled", value: "true" },
            { label: "Disabled", value: "false" },
          ],
        },
      },
      {
        fieldCode: "nic-bc-c1-from-noncompl_c",
        label: "Assigned due to client ID expiration (card holder)",
        input: {
          type: "select",
          options: [
            { label: "Enabled", value: "true" },
            { label: "Disabled", value: "false" },
          ],
        },
      },
      {
        fieldCode: "nic-bc-c1-from-card_st",
        label: "Assigned by card status",
        tooltip: "Determines how card status can influence this Status Management Code.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.cardStatus)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-bc-c1-from-falcon_block",
        label: "Assigned by falcon block",
        tooltip:
          "Falcon is the Fraud monitoring system, and it can send block, unblock requests to apply on the account based on rules defined in the falcon system.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.falconBlock)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-dlq_lev",
        label: "Assigned due to delinquency level",
        tooltip:
          "This is the automated delinquency block. Once the account reaches the delinquency level defined in this parameter then corresponding Status Management Code value will be applied on account automatically by the system based on block priority.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.delinquencyLevel)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-dlq_perm",
        label: "Delinquency status management code is permanent",
        tooltip:
          "This parameter determines if the delinquency block is permanent or removable. A permanent block remains in place even if the delinquency decreases. It can be overridden by a higher delinquency automatic status management code or manually removed.",
        input: {
          type: "select",
          options: [
            { label: "Enabled", value: "true" },
            { label: "Disabled", value: "false" },
          ],
        },
      },
    ],
  },

  // Tab 3 - Affected features
  {
    isCategory: true,
    name: "Affected Features",
    items: [
      {
        fieldCode: "nic-fc_reiss",
        label: "Card issue/reissue",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.reissue)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-r_card_act",
        label: "Card activation allowed",
        input: {
          type: "select",
          options: [
            { label: "Enabled", value: "true" },
            { label: "Disabled", value: "false" },
          ],
        },
      },
      {
        fieldCode: "nic-r_card_autorepl",
        label: "Card auto-replacement triggered",
        input: {
          type: "select",
          options: [
            { label: "Enabled", value: "true" },
            { label: "Disabled", value: "false" },
          ],
        },
      },
      {
        fieldCode: "nic-fc_ncon",
        label: "Card control enrollment",
        input: {
          type: "select",
          options: [
            { label: "Enabled", value: "true" },
            { label: "Disabled", value: "false" },
          ],
        },
      },
      {
        fieldCode: "nic-fc_tokens",
        label: "Token action",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.token)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_mtp",
        label: "Due aging and calculation",
        tooltip: "The feature controls the Minimum To Pay (MTP) calculation and aging for credit cards.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.agingCalculation)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_cb_rep",
        label: "Credit bureau reporting",
        tooltip:
          "This feature determines whether to report the account to a credit bureau as part of credit bureau reports based on the application of a specific status management code.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.bureauReporting)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_dd",
        label: "Direct debit",
        tooltip:
          "This feature enables the management of Direct Debit functionality when a specific status management code is implemented.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.directDebit)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_loy_accr",
        label: "Loyalty points accrual",
        tooltip:
          "This feature manages the accrual of loyalty points or requests their write-off upon the application of specific status management code.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.pointsAccrual)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_loy_redm",
        label: "Loyalty points redemption",
        tooltip:
          "This feature defines the redemption of loyalty points once certain status management code is applied.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.pointsRedemption)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_ins",
        label: "Insurance programs",
        tooltip: "This feature contains the insurance functionality behavior incase certain Status Management Code.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.insurancePrograms)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_agrm",
        label: "Cardholder agreement",
        tooltip:
          "This feature specifies the Status of Cardholder agreement specifically for cancelled cards reports and cancellation charge.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.agreement)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_posting",
        label: "Financial transactions posting",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.fcPosting)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_sms",
        label: "SMS notification",
        tooltip: "This feature defines the blocking of SMS notifications for certain Status Management Code.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.sms)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_stmt",
        label: "Statement generation",
        tooltip:
          "Generation of statement depends on this feature. Based on statement generation parameter setup, generation of statement can be blocked for the corresponding Status Management Code.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.fcStmt)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_report",
        label: "Block account report",
        tooltip: "This feature specifies accounts which are blocked should be part of reports or not.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.fcReport)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_abu_status",
        label: "ABU status",
        tooltip:
          "This feature defines the type of message that would be sent to ABU (Automatic Billing Updater) for certain Status Management Code.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.abuStatus)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
    ],
  },

  // Tab 4 - Waivers
  {
    isCategory: true,
    name: "Waivers",
    items: [
      {
        fieldCode: "nic-fc_finch",
        label: "Finance charges",
        tooltip:
          "This feature specifies the Finance charges can be waived or not in case customer eligible to charge finance charges and Status Management Code is applied.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.financeCharges)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_mf",
        label: "Membership fee",
        tooltip:
          "This feature specifies the Membership fees can be waived or not in case customer eligible to charge membership fees and Status Management Code is applied.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.fcMf)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_lpf",
        label: "Late payment fee",
        tooltip:
          "This feature specifies the late payment fee can be waived or not in case customer is eligible to charge late payment fee and certain Status Management Code is applied.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.paymentsFee)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_ovl",
        label: "Overlimit fee",
        tooltip:
          "This feature specifies the overlimit fee can be waived or not in case customer is in overlimit and Status Management Code is applied.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.paymentsFee)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_stmt_fee",
        label: "Statement fee",
        tooltip:
          "This feature specifies the Statement fees can be waived or not if certain Status Management Code is applied.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.fcStmtFee)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
      {
        fieldCode: "nic-fc_inact_fee",
        label: "Inactivity fee",
        tooltip:
          "This feature specifies the Inactivity fee can be waived or not if certain Status Management Code is applied.",
        input: {
          type: "select",
          options:
            blockCodeValuesAndLabels.get(ProductBcParams.fcInactFee)?.map(({ value, displayed }) => ({
              label: displayed,
              value,
            })) ?? [],
        },
      },
    ],
  },
];
