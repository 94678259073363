/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchChangePhasesRequest } from '../model';
// @ts-ignore
import { ChangePhaseRequest } from '../model';
// @ts-ignore
import { CopyPhaseRequest } from '../model';
// @ts-ignore
import { CreatePhaseRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { Phase } from '../model';
// @ts-ignore
import { PlainPhase } from '../model';
/**
 * PhaseApi - axios parameter creator
 * @export
 */
export const PhaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Copies Phase by ID returns created one
         * @summary Copy phase by id
         * @param {CopyPhaseRequest} copyPhaseRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPhase: async (copyPhaseRequest: CopyPhaseRequest, workflowId: number, phaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyPhaseRequest' is not null or undefined
            assertParamExists('copyPhase', 'copyPhaseRequest', copyPhaseRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('copyPhase', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('copyPhase', 'phaseId', phaseId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/copy`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyPhaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a phase
         * @summary Create Phase
         * @param {CreatePhaseRequest} createPhaseRequest 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPhase: async (createPhaseRequest: CreatePhaseRequest, workflowId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPhaseRequest' is not null or undefined
            assertParamExists('createPhase', 'createPhaseRequest', createPhaseRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createPhase', 'workflowId', workflowId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPhaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Phase
         * @summary Delete Phase by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhase: async (workflowId: number, phaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('deletePhase', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('deletePhase', 'phaseId', phaseId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edits a phase
         * @summary Edit Phase
         * @param {ChangePhaseRequest} changePhaseRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhase: async (changePhaseRequest: ChangePhaseRequest, workflowId: number, phaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePhaseRequest' is not null or undefined
            assertParamExists('editPhase', 'changePhaseRequest', changePhaseRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('editPhase', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('editPhase', 'phaseId', phaseId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePhaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Phases by workflowId
         * @summary Get Phases
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhases: async (workflowId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getPhases', 'workflowId', workflowId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Batch Patching of Phases
         * @summary Batch Patching of Phases
         * @param {BatchChangePhasesRequest} batchChangePhasesRequest 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPhases: async (batchChangePhasesRequest: BatchChangePhasesRequest, workflowId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchChangePhasesRequest' is not null or undefined
            assertParamExists('putPhases', 'batchChangePhasesRequest', batchChangePhasesRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('putPhases', 'workflowId', workflowId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchChangePhasesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhaseApi - functional programming interface
 * @export
 */
export const PhaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhaseApiAxiosParamCreator(configuration)
    return {
        /**
         * Copies Phase by ID returns created one
         * @summary Copy phase by id
         * @param {CopyPhaseRequest} copyPhaseRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyPhase(copyPhaseRequest: CopyPhaseRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Phase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyPhase(copyPhaseRequest, workflowId, phaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a phase
         * @summary Create Phase
         * @param {CreatePhaseRequest} createPhaseRequest 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPhase(createPhaseRequest: CreatePhaseRequest, workflowId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Phase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPhase(createPhaseRequest, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Phase
         * @summary Delete Phase by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePhase(workflowId: number, phaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePhase(workflowId, phaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edits a phase
         * @summary Edit Phase
         * @param {ChangePhaseRequest} changePhaseRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPhase(changePhaseRequest: ChangePhaseRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Phase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editPhase(changePhaseRequest, workflowId, phaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Phases by workflowId
         * @summary Get Phases
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhases(workflowId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Phase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhases(workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Batch Patching of Phases
         * @summary Batch Patching of Phases
         * @param {BatchChangePhasesRequest} batchChangePhasesRequest 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPhases(batchChangePhasesRequest: BatchChangePhasesRequest, workflowId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlainPhase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPhases(batchChangePhasesRequest, workflowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhaseApi - factory interface
 * @export
 */
export const PhaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhaseApiFp(configuration)
    return {
        /**
         * Copies Phase by ID returns created one
         * @summary Copy phase by id
         * @param {CopyPhaseRequest} copyPhaseRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPhase(copyPhaseRequest: CopyPhaseRequest, workflowId: number, phaseId: number, options?: any): AxiosPromise<Phase> {
            return localVarFp.copyPhase(copyPhaseRequest, workflowId, phaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a phase
         * @summary Create Phase
         * @param {CreatePhaseRequest} createPhaseRequest 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPhase(createPhaseRequest: CreatePhaseRequest, workflowId: number, options?: any): AxiosPromise<Phase> {
            return localVarFp.createPhase(createPhaseRequest, workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Phase
         * @summary Delete Phase by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhase(workflowId: number, phaseId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePhase(workflowId, phaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edits a phase
         * @summary Edit Phase
         * @param {ChangePhaseRequest} changePhaseRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPhase(changePhaseRequest: ChangePhaseRequest, workflowId: number, phaseId: number, options?: any): AxiosPromise<Phase> {
            return localVarFp.editPhase(changePhaseRequest, workflowId, phaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Phases by workflowId
         * @summary Get Phases
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhases(workflowId: number, options?: any): AxiosPromise<Array<Phase>> {
            return localVarFp.getPhases(workflowId, options).then((request) => request(axios, basePath));
        },
        /**
         * Batch Patching of Phases
         * @summary Batch Patching of Phases
         * @param {BatchChangePhasesRequest} batchChangePhasesRequest 
         * @param {number} workflowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPhases(batchChangePhasesRequest: BatchChangePhasesRequest, workflowId: number, options?: any): AxiosPromise<Array<PlainPhase>> {
            return localVarFp.putPhases(batchChangePhasesRequest, workflowId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhaseApi - interface
 * @export
 * @interface PhaseApi
 */
export interface PhaseApiInterface {
    /**
     * Copies Phase by ID returns created one
     * @summary Copy phase by id
     * @param {CopyPhaseRequest} copyPhaseRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApiInterface
     */
    copyPhase(copyPhaseRequest: CopyPhaseRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): AxiosPromise<Phase>;

    /**
     * Creates a phase
     * @summary Create Phase
     * @param {CreatePhaseRequest} createPhaseRequest 
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApiInterface
     */
    createPhase(createPhaseRequest: CreatePhaseRequest, workflowId: number, options?: AxiosRequestConfig): AxiosPromise<Phase>;

    /**
     * Deletes Phase
     * @summary Delete Phase by Id
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApiInterface
     */
    deletePhase(workflowId: number, phaseId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Edits a phase
     * @summary Edit Phase
     * @param {ChangePhaseRequest} changePhaseRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApiInterface
     */
    editPhase(changePhaseRequest: ChangePhaseRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): AxiosPromise<Phase>;

    /**
     * Get Phases by workflowId
     * @summary Get Phases
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApiInterface
     */
    getPhases(workflowId: number, options?: AxiosRequestConfig): AxiosPromise<Array<Phase>>;

    /**
     * Batch Patching of Phases
     * @summary Batch Patching of Phases
     * @param {BatchChangePhasesRequest} batchChangePhasesRequest 
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApiInterface
     */
    putPhases(batchChangePhasesRequest: BatchChangePhasesRequest, workflowId: number, options?: AxiosRequestConfig): AxiosPromise<Array<PlainPhase>>;

}

/**
 * PhaseApi - object-oriented interface
 * @export
 * @class PhaseApi
 * @extends {BaseAPI}
 */
export class PhaseApi extends BaseAPI implements PhaseApiInterface {
    /**
     * Copies Phase by ID returns created one
     * @summary Copy phase by id
     * @param {CopyPhaseRequest} copyPhaseRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public copyPhase(copyPhaseRequest: CopyPhaseRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).copyPhase(copyPhaseRequest, workflowId, phaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a phase
     * @summary Create Phase
     * @param {CreatePhaseRequest} createPhaseRequest 
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public createPhase(createPhaseRequest: CreatePhaseRequest, workflowId: number, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).createPhase(createPhaseRequest, workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Phase
     * @summary Delete Phase by Id
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public deletePhase(workflowId: number, phaseId: number, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).deletePhase(workflowId, phaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edits a phase
     * @summary Edit Phase
     * @param {ChangePhaseRequest} changePhaseRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public editPhase(changePhaseRequest: ChangePhaseRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).editPhase(changePhaseRequest, workflowId, phaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Phases by workflowId
     * @summary Get Phases
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public getPhases(workflowId: number, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).getPhases(workflowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Batch Patching of Phases
     * @summary Batch Patching of Phases
     * @param {BatchChangePhasesRequest} batchChangePhasesRequest 
     * @param {number} workflowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhaseApi
     */
    public putPhases(batchChangePhasesRequest: BatchChangePhasesRequest, workflowId: number, options?: AxiosRequestConfig) {
        return PhaseApiFp(this.configuration).putPhases(batchChangePhasesRequest, workflowId, options).then((request) => request(this.axios, this.basePath));
    }
}
