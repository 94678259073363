import { FC } from "react";
import { Form, Typography } from "antd";
import { checkEveryPctValue } from "libs/product-settings/src/lib/utils";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { usePct } from "@ni/product-settings";

import { enableOverlimitFee, OverlimitFeeOptions, OverlimitTolerancePercentageOptions } from "./constants";

export const OverlimitFeePage: FC = () => {
  const { productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [form] = Form.useForm<FormValues>();

  const {
    currentProduct,
    productCurrency: currencyType,
    onUpdateProduct,
  } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [enableOverlimitFee.code, OverlimitFeeOptions.code, OverlimitTolerancePercentageOptions.code],
    },
    allowParse: true,
  });

  const isOverlimitEnabled = Form.useWatch<boolean>(enableOverlimitFee.code, form);

  const onFinish = async (values: FormValues) => {
    const productValues: FormValues = {};

    const isDisabledOnAllPcts = checkEveryPctValue({
      pctId,
      parameterTables: currentProduct.parameterTables,
      fieldCode: enableOverlimitFee.code,
      fieldValue: "false",
      newFieldValue: values[enableOverlimitFee.code],
    });

    if (isDisabledOnAllPcts) {
      productValues["nic-ovl-fee-repeat-mode"] = "";
    }

    await onUpdateProduct(productValues, undefined, {
      id: Number(pctId),
      values: { [OverlimitFeeOptions.code]: "", [OverlimitTolerancePercentageOptions.code]: "", ...values },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Over-limit Fee"
      pageSubtitle="Overlimit fee is charged when the utilization of a card exceeds the allowed credit limit."
      size="md"
      formSize="full"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: enableOverlimitFee.code,
          label: <Typography.Text strong={true}>{enableOverlimitFee.name}</Typography.Text>,
          initialValue: enableOverlimitFee.default,
          valuePropName: "checked",
        }}
      />
      {isOverlimitEnabled && (
        <>
          <NetworkForm.Number
            formItemOptions={{
              name: OverlimitFeeOptions.code,
              label: OverlimitFeeOptions.name,
              rules: [
                {
                  validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                  message: "Minimum fee amount cannot be 0",
                },
                { required: true, message: "Overlimit Fee value is required!" },
              ],
            }}
            addonAfter={currencyType}
            min={OverlimitFeeOptions.min}
            precision={OverlimitFeeOptions.decimalsNumber}
          />

          <NetworkForm.Number
            formItemOptions={{
              name: OverlimitTolerancePercentageOptions.code,
              label: OverlimitTolerancePercentageOptions.name,
              tooltip: OverlimitTolerancePercentageOptions.tooltip,
            }}
            addonAfter="%"
            min={OverlimitTolerancePercentageOptions.min}
            precision={OverlimitTolerancePercentageOptions.decimalsNumber}
          />
        </>
      )}
    </CustomFormWrapper>
  );
};
