import { FC } from "react";
import { Button } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { extractNumberFromString } from "@ni/common/utils";

import { Rule, RuleKeys, RuleListItem, RuleListItemProps } from "./RuleListItem";

import styles from "../styles.module.scss";

interface RulesListProps {
  rules: Rule<RuleKeys>[];
  setRules: React.Dispatch<React.SetStateAction<Rule<RuleKeys>[]>>;
}

const getRuleKeyWithOrdinalNumber = (ordinalNumber: number): RuleKeys =>
  `nic-usage-paym-u0${ordinalNumber}-rules` as RuleKeys;

const getInitialRule = (key: RuleKeys): Rule<RuleKeys> => {
  return {
    key,
    value: "",
    p2p: {
      isEnabled: false,
      prefix: "",
      value: "",
    },
    primarySupplementary: {
      isEnabled: false,
      value: "",
    },
  };
};

export const RulesList: FC<RulesListProps> = ({ rules, setRules }) => {
  const onAddRule = () => {
    setRules([...rules, getInitialRule(getRuleKeyWithOrdinalNumber(rules.length + 1))]);
  };

  const onChangeRule: RuleListItemProps["onChangeRule"] = ({ type, ruleKey, value }) => {
    if (type === "checkbox") {
      setRules(
        rules.map(rule => {
          if (rule.key === ruleKey) {
            return { ...rule, ...value };
          }

          return rule;
        }),
      );
    }

    if (type === "input") {
      setRules(
        rules.map(rule => {
          if (rule.key === ruleKey) {
            return { ...rule, ...value };
          }
          return rule;
        }),
      );
    }
  };

  const onRemoveRule = (key: RuleKeys) => {
    setRules(
      [...rules.filter(rule => rule.key !== key)].map((rule, index) => ({
        ...rule,
        key: getRuleKeyWithOrdinalNumber(index + 1),
      })),
    );
  };

  return (
    <>
      <ul className={styles["rules-list"]}>
        {rules
          .sort((a, b) => (extractNumberFromString(a.key) ?? 0) - (extractNumberFromString(b.key) ?? 0))
          .map(rule => {
            return <RuleListItem key={rule.key} rule={rule} onChangeRule={onChangeRule} onRemoveRule={onRemoveRule} />;
          })}
      </ul>

      {rules.length <= 2 && (
        <Button type="link" icon={<PlusOutlined />} onClick={onAddRule}>
          Add rule
        </Button>
      )}
    </>
  );
};
