import { FC, useEffect, useState } from "react";
import { Form, Space, Typography } from "antd";
import { useParams } from "react-router-dom";

import { NIC_CCY } from "@ni/common/constants";
import { usePrepareMultiCurrency, useProductStateFormDisabled, useReduxState } from "@ni/common/hooks";
import { FormValues, GetProductWithId } from "@ni/common/types";
import { CustomFormWrapper, MultiCurrencyTable, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductApi } from "@ni/sdk/apis";
import { ChangeProductRequest, ProductValue } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const productServiceApi = new ProductApi();

export const MultiCurrency: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();

  const [currentProduct, setCurrentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading", false);

  const isDisabled = useProductStateFormDisabled();

  const { dataSource, currenciesDictionary, currenciesMaxThreshold, setDataSource } =
    usePrepareMultiCurrency(currentProduct);

  const isPrepaidProduct = getFormValueFromProductValues(currentProduct.productValues, "product-type") === "Prepaid";
  const isPrepaidOrCreditProduct =
    isPrepaidProduct || getFormValueFromProductValues(currentProduct.productValues, "product-type") === "Credit";

  const [isMultiCurSelected, setIsMultiCurSelected] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setIsMultiCurSelected(getFormValueFromProductValues(currentProduct.productValues, "curr-setup") === "mult");
    }
  }, [isLoading, currentProduct.productValues]);

  useEffect(() => {
    if (!isMultiCurSelected) {
      form.setFieldValue(NIC_CCY, getFormValueFromProductValues(currentProduct.productValues, NIC_CCY));
    }
  }, [currentProduct.productValues, form, isMultiCurSelected]);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const currentBaseCurrency = isMultiCurSelected
      ? dataSource.find(currency => currency.baseCurrency)?.currencyCode
      : (values[NIC_CCY] as string);

    const currentCurrencyProductValues = currentProduct?.productValues?.filter(
      (productValue: ProductValue) =>
        productValue.fieldCode.startsWith("nic-prod-ppm-w") && productValue.fieldCode.endsWith("-ccy"),
    );

    const valuesToSave: ProductValue[] = [];

    if (isMultiCurSelected) {
      const baseCurrencyObject = dataSource.find(item => item.baseCurrency);

      valuesToSave.push({
        fieldCode: "nic-prod-ppm-w00-prior",
        value: String(baseCurrencyObject?.priority),
      });

      dataSource
        .filter(item => item.baseCurrency === false)
        .forEach((item, index) => {
          const fieldCode = `nic-prod-ppm-w${(index + 1).toString().padStart(2, "0")}`;

          const currencyKey = `${fieldCode}-ccy`;
          const autoKey = `${fieldCode}-to-autoopen`;
          const priority = `${fieldCode}-prior`;

          valuesToSave.push({ fieldCode: currencyKey, value: item.currencyCode });
          valuesToSave.push({ fieldCode: autoKey, value: item.autoOpening.toString() });
          valuesToSave.push({ fieldCode: priority, value: item.priority.toString() });
        });

      const effectiveLength = dataSource.length - 1;

      if (
        currentCurrencyProductValues &&
        currentCurrencyProductValues[0] &&
        effectiveLength < currentCurrencyProductValues.length
      ) {
        for (let i = effectiveLength; i < currentCurrencyProductValues.length - 1; i++) {
          const currencyKey = `nic-prod-ppm-w${String(i + 1).padStart(2, "0")}-ccy`;
          const autoKey = `nic-prod-ppm-w${String(i + 1).padStart(2, "0")}-to-autoopen`;
          const priority = `nic-prod-ppm-w${String(i + 1).padStart(2, "0")}-prior`;

          valuesToSave.push({
            fieldCode: currencyKey,
            value: undefined,
          } as ProductValue);

          valuesToSave.push({
            fieldCode: autoKey,
            value: undefined,
          } as ProductValue);

          valuesToSave.push({
            fieldCode: priority,
            value: undefined,
          } as ProductValue);
        }
      }
    } else if (currentCurrencyProductValues && currentCurrencyProductValues[0]) {
      currentCurrencyProductValues.forEach(item => {
        const currencyKey = `nic-prod-ppm-w${item.fieldCode.substring(15, 16).padStart(2, "0")}-ccy`;
        const autoKey = `nic-prod-ppm-w${item.fieldCode.substring(15, 16).padStart(2, "0")}-to-autoopen`;
        const priority = `nic-prod-ppm-w${item.fieldCode.substring(15, 16).padStart(2, "0")}-prior`;

        valuesToSave.push({
          fieldCode: currencyKey,
          value: undefined,
        } as ProductValue);

        valuesToSave.push({
          fieldCode: autoKey,
          value: undefined,
        } as ProductValue);

        valuesToSave.push({
          fieldCode: priority,
          value: undefined,
        } as ProductValue);
      });
    }

    if (!isMultiCurSelected) {
      valuesToSave.push({
        fieldCode: "nic-prod-ppm-w00-prior",
        value: undefined,
      });
    }

    valuesToSave.push({
      fieldCode: "curr-setup",
      value: isMultiCurSelected ? "mult" : "single",
    });

    valuesToSave.push({
      fieldCode: NIC_CCY,
      value: currentBaseCurrency,
    });

    const newDisplayName = `${
      currentProduct?.productValues?.filter(i => i.fieldCode === "nic-prod-ips")[0]?.value || ""
    } ${currentProduct?.productValues?.filter(i => i.fieldCode === "co-badged-name")[0]?.value || ""} ${
      currentProduct?.productValues?.filter(i => i.fieldCode === "nic-prod-core-name")[0]?.value || ""
    } ${currentBaseCurrency} ${isMultiCurSelected ? "Multi-currency" : ""}`.replace(/\s+/g, " ");

    const requestBody: ChangeProductRequest = {
      productValues: valuesToSave,
      displayName: newDisplayName,
    };

    productServiceApi
      .editProduct(requestBody, productId ? +productId : -1)
      .then(res => {
        setCurrentProduct(res.data as GetProductWithId);
        setIsLoading(false);

        if (!isMultiCurSelected) {
          setDataSource(
            dataSource.filter(source => source.baseCurrency).map(currency => ({ ...currency, priority: 500 })),
          );
        }
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Currency Setup"
      submitHandler={onFinish}
      buttonDisabled={!isPrepaidOrCreditProduct}
      size="full"
      formSize="full"
      submitLabel="Save"
      gap={24}
    >
      <Space direction="horizontal">
        <NetworkForm.Switch
          checked={isMultiCurSelected}
          onChange={() => setIsMultiCurSelected(!isMultiCurSelected)}
          disabled={isDisabled || !isPrepaidProduct}
          customLabel={
            <TooltipInfo label="Enable multi-currency" code="curr-setup, mult or single" tooltipProps={{}} />
          }
        />
      </Space>

      {isMultiCurSelected && (
        <Typography.Text>
          <TooltipInfo
            largeLabel={`Set base currency and select up to ${currenciesMaxThreshold} additional wallets. Define their priority.`}
            tooltipProps={{
              title:
                "A wallet corresponding to a transaction currency will be debited first. In case of insufficient funds, the system will automatically debit the wallets in order of priority. To change the priority, simply drag and drop the rows.",
              overlayStyle: { maxWidth: "350px" },
            }}
          />
        </Typography.Text>
      )}

      {!isMultiCurSelected &&
        getFormValueFromProductValues(currentProduct.productValues, "balance-owner") === "CMS" && (
          <NetworkForm.Select
            formItemOptions={{ name: NIC_CCY, label: "Select product currency" }}
            optionList={currenciesDictionary.map(currency => ({
              label: currency.currencyName,
              value: currency.currencyCode,
            }))}
            className={styles["currency-select"]}
          />
        )}

      {isPrepaidProduct && isMultiCurSelected && (
        <MultiCurrencyTable
          formDisabled={!isPrepaidProduct}
          isLoading={isLoading}
          countriesDictionary={currenciesDictionary}
          setDataSource={setDataSource}
          currenciesMaxThreshold={currenciesMaxThreshold}
          dataSource={dataSource}
        />
      )}
    </CustomFormWrapper>
  );
};
