import { useCallback, useEffect, useRef, useState } from "react";
import { TablePaginationConfig } from "antd";

import { FormValues } from "@ni/common/types";
import { LoyaltyProgramTemplateApi, TenantApi } from "@ni/sdk/apis";
import {
  BaseLoyaltyProgramDashboard,
  PageDataFullBaseLoyaltyProgramDashboard,
  SortedFilteredPageRequest,
} from "@ni/sdk/models";

import { useReduxState } from "../store";

import { initialLoyaltyData, initialLoyaltyPagination } from "./constants";

interface UseLoyaltyProgramTemplatesProps {
  tenantId: number;
  isFetchEnabled?: boolean;
}

const tenantServiceApi = new TenantApi();
const loyaltyServiceApi = new LoyaltyProgramTemplateApi();

export const useLoyaltyProgramTemplates = ({ tenantId, isFetchEnabled = true }: UseLoyaltyProgramTemplatesProps) => {
  const [loyaltyProgramTemplates, setLoyaltyProgramTemplates] = useReduxState<PageDataFullBaseLoyaltyProgramDashboard>(
    "loyaltyProgramTemplates",
    initialLoyaltyData,
  );
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [pageHasContent, setPageHasContent] = useState<boolean>(false);
  const [filters, setFilters] = useState<SortedFilteredPageRequest>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialLoyaltyPagination);

  const initialLoadCompleteRef = useRef<boolean>(false);

  const getLoyaltyProgramTemplates = useCallback(
    async (tenantId: number, currentPage: number, size: number, payload: SortedFilteredPageRequest = {}) => {
      setIsLoading(true);

      try {
        const { data } = await tenantServiceApi.getBaseLoyaltyProgramsByTenantId(
          {
            ...payload,
            pageLimits: {
              number: currentPage - 1,
              size,
            },
          },
          tenantId,
        );

        setPagination(pagination => ({ ...pagination, total: data.totalElements }));
        setLoyaltyProgramTemplates(data ?? []);

        if (!initialLoadCompleteRef.current) {
          setPageHasContent(data.hasContent);
          initialLoadCompleteRef.current = true;
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setIsLoading, setLoyaltyProgramTemplates],
  );

  const copyLoyaltyProgramTemplate = async (newDisplayName: string, templateId: number) => {
    setIsLoading(true);
    try {
      const { data } = await loyaltyServiceApi.copy1({ newDisplayName }, templateId);

      setLoyaltyProgramTemplates(prev => {
        const updatedContent = prev.content ? [data, ...prev.content] : [data];

        if (updatedContent.length > (pagination?.pageSize ?? 6)) updatedContent.pop();

        return {
          ...prev,
          content: updatedContent,
        };
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const editLoyaltyProgramTemplate = async (values: FormValues, templateId: number) => {
    setIsLoading(true);
    try {
      const { data } = await loyaltyServiceApi.edit2(values, templateId);

      const { accrualType, accrualDisplayDetails, name, state }: BaseLoyaltyProgramDashboard = data;

      setLoyaltyProgramTemplates(prev => ({
        ...prev,
        content: prev.content?.map(item => {
          if (item.id === data.id) {
            return {
              ...item,
              accrualType,
              accrualDisplayDetails,
              name,
              state,
            };
          }
          return item;
        }),
      }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFetchEnabled)
      void getLoyaltyProgramTemplates(tenantId, pagination.current ?? 1, pagination.pageSize ?? 6, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchEnabled, getLoyaltyProgramTemplates, tenantId, pagination.current, pagination.pageSize, filters]);

  return {
    loyaltyProgramTemplates,
    setLoyaltyProgramTemplates,
    copyLoyaltyProgramTemplate,
    editLoyaltyProgramTemplate,
    pageHasContent,
    pagination,
    setPagination,
    filters,
    setFilters,
  };
};
