import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Col, Form, FormInstance, Row, Space, Switch } from "antd";
import classNames from "classnames";
import { Rule } from "rc-field-form/lib/interface";

import { FormValues } from "@ni/common/types";

import { NetworkForm } from "../FormInput";
import { FormItemLabel } from "../Labels";
import { TooltipInfo } from "../TooltipInfo";

import styles from "./style.module.scss";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
  rules?: Rule[];
  controls?: boolean;
  addonAfter?: ReactNode;
  isDisabled?: boolean;
  type?: "switch" | "integer" | "number";
  suffix?: ReactNode;
  prefix?: ReactNode;
  formatter?: (value: number | string | undefined, info: { userTyping: boolean; input: string }) => string;
  parser?: (value: string | undefined) => string | number;
}

interface SwitchedInputsBoxProps {
  form: FormInstance<FormValues>;
  formDisabled?: boolean;
  switchOptions: Options;
  inputsOptionsList: NumericOptions[][];
  shouldClearOnDisabled?: boolean;
}

export const SwitchedInputsBox: FC<SwitchedInputsBoxProps> = ({
  form,
  formDisabled,
  switchOptions,
  inputsOptionsList,
  shouldClearOnDisabled,
}: SwitchedInputsBoxProps) => {
  const [changeListener, setChangeListener] = useState(inputsOptionsList?.length ?? 1);
  const isEnabled = Form.useWatch<string>(switchOptions.code, form)?.toString() === "true";
  const spanValue = useMemo(() => {
    if (!inputsOptionsList || !inputsOptionsList[0]?.length || inputsOptionsList[0]?.length < 3) return 12;
    if (inputsOptionsList[0].length > 8) return 2;
    switch (inputsOptionsList[0].length) {
      case 3:
        return 8;
      case 4:
        return 6;
      case 5:
        return 4;
      case 6:
        return 4;
      case 7:
        return 3;
      case 8:
        return 3;
      default:
        return 12;
    }
  }, [inputsOptionsList]);

  const rowToggle: boolean[] = useMemo(() => {
    if (isEnabled && changeListener)
      return inputsOptionsList?.map(inputsRow => {
        const shouldDisable =
          (form.getFieldValue(inputsRow[0]?.code) as string)?.toString() === "false" && inputsRow[0]?.type === "switch";
        if (shouldDisable) {
          const clearedValues = inputsRow
            .map(item => ({ [item.code]: item.type === "switch" ? false : "" }))
            .reduce((previous, value) => ({ ...previous, ...value }), {});
          form.setFieldsValue(clearedValues);
        }
        return shouldDisable;
      });
    return [];
  }, [changeListener, form, inputsOptionsList, isEnabled]);

  useEffect(() => {
    if (!isEnabled && !form.getFieldValue(switchOptions.code) && inputsOptionsList?.length) {
      if (!shouldClearOnDisabled) {
        form.resetFields(
          inputsOptionsList.reduce((previous, current) => [...previous, ...current], []).map(x => x.code),
        );
      } else {
        const clearedValues = inputsOptionsList
          .reduce((previous, current) => [...previous, ...current], [])
          .map(item => ({ [item.code]: item.type === "switch" ? false : "" }))
          .reduce((previous, value) => ({ ...previous, ...value }), {});
        form.setFieldsValue(clearedValues);
      }
    }
  }, [form, inputsOptionsList, isEnabled, shouldClearOnDisabled]);

  return (
    <div className={classNames("limits-block", styles["switched-inputs"])}>
      <div className="limits-block-header">
        <Form.Item
          valuePropName="checked"
          name={switchOptions.code}
          className="header-switch"
          initialValue={switchOptions?.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={switchOptions?.name}
          code={switchOptions?.code}
          tooltipProps={
            switchOptions?.tooltip
              ? {
                  title: switchOptions.tooltip,
                }
              : {}
          }
        />
      </div>

      <div className="limits-block-body">
        {inputsOptionsList[0]?.length && (
          <Row>
            {inputsOptionsList[0].map((inputObject, index) => (
              <Col
                key={inputObject.code}
                span={spanValue}
                className="col-header"
                style={{ visibility: inputsOptionsList[0][0].type === "switch" && index === 0 ? "hidden" : "visible" }}
              >
                <TooltipInfo
                  label={inputObject?.name}
                  tooltipProps={
                    inputObject?.tooltip
                      ? {
                          title: inputObject?.tooltip,
                        }
                      : {}
                  }
                />
              </Col>
            ))}
          </Row>
        )}
        {inputsOptionsList.map((inputsOptions, index) => {
          return (
            !!inputsOptions?.length && (
              <Row key={inputsOptions[0]?.code}>
                {inputsOptions.length > 0 &&
                  inputsOptions.map(inputObject =>
                    inputObject?.type === "switch" ? (
                      <Col key={`${inputObject.code}${inputObject.name}`} span={spanValue}>
                        <Space direction="horizontal" size={2}>
                          <Form.Item
                            valuePropName="checked"
                            name={inputObject.code}
                            className="header-switch"
                            initialValue={inputObject?.default}
                          >
                            <Switch onClick={() => setChangeListener(changeListener + 1)} />
                          </Form.Item>
                          <div className={classNames("switch-title-container")}>
                            <TooltipInfo
                              label={inputObject?.name}
                              code={inputObject?.code}
                              tooltipProps={
                                inputObject?.tooltip
                                  ? {
                                      title: inputObject?.tooltip,
                                    }
                                  : {}
                              }
                            />
                          </div>
                        </Space>
                      </Col>
                    ) : (
                      <Col key={`${inputObject.code}${inputObject.name}`} span={spanValue}>
                        <FormItemLabel code={inputObject?.code}>
                          <Form.Item
                            name={inputObject.code}
                            initialValue={inputObject?.default}
                            rules={inputObject?.rules}
                          >
                            <NetworkForm.Number
                              suffix={inputObject?.suffix}
                              prefix={inputObject?.prefix}
                              addonAfter={inputObject.addonAfter}
                              min={inputObject.min}
                              max={inputObject.max}
                              precision={inputObject.decimalsNumber}
                              controls={inputObject.controls}
                              disabled={
                                formDisabled ||
                                inputObject?.isDisabled ||
                                !isEnabled ||
                                (inputsOptions[0].type === "switch" && rowToggle[index])
                              }
                              formatter={inputObject?.formatter}
                              parser={inputObject?.parser}
                              className="w-p-100"
                            />
                          </Form.Item>
                        </FormItemLabel>
                      </Col>
                    ),
                  )}
              </Row>
            )
          );
        })}
      </div>
    </div>
  );
};
