import { FC, ReactNode, useRef, useState } from "react";
import { Card, Tooltip } from "antd";
import LinesEllipsis from "react-lines-ellipsis";
import { Link, useLocation } from "react-router-dom";

import { getLastRoute } from "@ni/common/utils";

import styles from "./styles.module.scss";

interface SettingsOverviewCardProps {
  title: string;
  text: string;
  href: string;
}

export const SettingsOverviewCard: FC<SettingsOverviewCardProps> = ({ title, text, href }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const location = useLocation();

  const handleReflow = ({ clamped }: { clamped: boolean }) => {
    setIsOverflowing(clamped);
  };

  const CardTooltip = ({ children }: { children: ReactNode }) =>
    isOverflowing ? (
      <Tooltip
        title={text}
        color="#E5EAEF"
        placement="bottom"
        overlayClassName={styles["tooltip"]}
        overlayInnerStyle={{ width: `${cardRef.current?.clientWidth}px` }}
      >
        {children}
      </Tooltip>
    ) : (
      children
    );

  return (
    <Link
      to={location.pathname.replace(getLastRoute(location.pathname), href)}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <CardTooltip>
        <Card ref={cardRef} title={title} className={styles["card"]}>
          <LinesEllipsis
            text={text}
            maxLine="4"
            ellipsis="..."
            trimRight={true}
            basedOn="letters"
            onReflow={handleReflow}
          />
        </Card>
      </CardTooltip>
    </Link>
  );
};
