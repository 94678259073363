import { FC } from "react";

import { NIC_LTY_BONUS_W_TAR0_BASE } from "@ni/common/constants";
import { FormContent, NetworkForm } from "@ni/common/ui";

export const WelcomeBonus: FC = () => {
  return (
    <FormContent gap={40}>
      <div>
        New customers could receive a certain number of bonus points upon signing up for the loyalty program or after a
        fixed period of time.
      </div>

      <NetworkForm.Number
        formItemOptions={{
          name: NIC_LTY_BONUS_W_TAR0_BASE,
          label: "Welcome bonus amount (number of points)",
        }}
        min={0}
        precision={2}
        className="w-p-100"
      />
    </FormContent>
  );
};
