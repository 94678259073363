import { Form, FormInstance, Space, Typography } from "antd";
import { TabKeysCTF } from "libs/product-settings/src/lib/types/CardTransactionFees";

import { FormValues } from "@ni/common/types";
import { Alert, FeesValuesRow, HeadingsRow, NetworkForm } from "@ni/common/ui";
import { ParameterTable } from "@ni/sdk/models";

import { CTF_HEADINGS } from "./constants";
import { useCardTransactionFees } from "./useCardTransactionFees";

import styles from "../../styles.module.scss";

interface Props {
  tab: string;
  form: FormInstance<FormValues>;
  currency: string;
  pct: ParameterTable;
}

const CTFTab = ({ tab, form, currency, pct }: Props) => {
  const regEnabled = Form.useWatch<boolean>(`${tab}-reg-enable`, form);
  const interaregEnabled = Form.useWatch<boolean>(`${tab}-intrareg-enable`, form);
  const amountEnabled = Form.useWatch<boolean>(`${tab}-amount-enable`, form);

  const ctfKeys: Record<string, TabKeysCTF> = {
    atm: "atm",
    cash: "pos",
    retail: "retail",
    refund: "credit",
    "p2p-deb": "ch-debit",
    "p2p-paym": "ch-paym",
    quasi: "retail-qsi",
  };

  const { fields, withAmountSwitch } = useCardTransactionFees(ctfKeys[tab], {
    reg: regEnabled,
    amount: amountEnabled,
    intrareg: interaregEnabled,
    productValues: pct.pctProductValues ?? [],
  });

  const description: Record<string, string> = {
    atm: "ATM withdrawal fee is charged to the customers for the ATM withdrawal transactions",
    cash: "Cash POS fee is charged to the customers for the cash withdrawal transactions at the POS machines that are not considered as retail purchases",
    retail:
      "Retail transaction fee is charged to a customer when a purchase is done on their card at a Point-Of-Sale terminal at a merchant location",
    refund:
      "Refund fee is charged to a customer when a retail purchase made on the customer’s card is refunded via Point-Of-Sale terminal at a merchant location",
    "p2p-deb": "P2P debit fee is charged to the sender for the P2P transfer transaction",
    "p2p-paym": "P2P debit fee is charged to the receiver for the P2P transfer transaction",
    quasi:
      "System identifies a transaction as Quasi cash transaction based on the transaction Merchant category code (MCC). E.g. ‘6050 - Quasi Cash, fin Inst’, ‘6051 - Foreign Currency, Money, TC’s'. List of MCC is configurable, please follow ‘Fee settings - Global’ in Related links section",
  };

  return (
    <Space direction="vertical" size={32}>
      <Typography.Text>{description[tab]}</Typography.Text>

      <Space direction="vertical" size={0}>
        <NetworkForm.Switch
          formItemOptions={{
            name: `${tab}-reg-enable`,
            label: "Differentiate fee for Onus & domestic",
            tooltip:
              "Tooltip: This parameter enables segregating fees for onus and domestic transaction types, domestic transaction countries are identified in FI level fee settings",
          }}
        />
        <NetworkForm.Switch
          formItemOptions={{
            name: `${tab}-intrareg-enable`,
            label: "Differentiate fee for intraregional area",
            tooltip:
              "Tooltip: This parameter enables segregating fees for transactions originating from intraregional region as identified in FI level fee settings",
          }}
        />
        {withAmountSwitch && (
          <NetworkForm.Switch
            formItemOptions={{
              name: `${tab}-amount-enable`,
              label: "Differentiate the fee by transaction amount",
              tooltip:
                "It is possible to charge the separate fee for the transaction amount falling in a particular tier. For example: If the transaction amount is between 0 and 1000 AED, then 3% of transaction amount is charged on the account. If the transaction amount is above 1000 AED, then 1% of transaction amount is charged on the account.",
            }}
          />
        )}
      </Space>

      <div className={styles["pct-editing-box"]}>
        <HeadingsRow firstColSpan={4} headings={amountEnabled ? CTF_HEADINGS : CTF_HEADINGS.slice(2, 6)} />

        {fields.additionalFee && (
          <FeesValuesRow
            form={form}
            fields={fields.additionalFee}
            productCurrency={currency}
            rowLabel="Additional tier fee"
            rowTooltip="The fee is applied to the transaction amount between values defined in Min transaction amount (including) and Max transaction amount (not including). "
          />
        )}

        {fields.defaultFee && (
          <FeesValuesRow
            form={form}
            fields={fields.defaultFee}
            productCurrency={currency}
            rowLabel={amountEnabled ? "Default tier fee" : "Default fee"}
            rowTooltip={
              regEnabled && amountEnabled
                ? "The fee is applied to any transaction amount beyond the range that is defined in additional tier, unless it is redefined by values below for specific cases (OnUs/ Domestic transactions)"
                : amountEnabled
                  ? "The fee is applied to any transaction amount beyond the range that is defined in additional tier"
                  : "The fee is applied to the transaction type unless it is redefined by values below for specific cases (OnUs / Domestic)"
            }
          />
        )}

        {fields.onUsAdditionalFee && (
          <FeesValuesRow
            form={form}
            fields={fields.onUsAdditionalFee}
            productCurrency={currency}
            rowLabel="OnUs additional tier fee"
            rowTooltip="The fee is applied to the OnUs transactions with amount between values defined in Min transaction amount (including) and Max transaction amount (not including)"
          />
        )}

        {fields.onUsFee && (
          <FeesValuesRow
            form={form}
            fields={fields.onUsFee}
            productCurrency={currency}
            rowLabel={amountEnabled ? "OnUs default tier fee" : "OnUs fee"}
            rowTooltip="For transactions done through an issuer device network (card issuer and the acquirer are the same bank)"
          />
        )}

        {fields.domesticAdditionalFee && (
          <FeesValuesRow
            form={form}
            fields={fields.domesticAdditionalFee}
            productCurrency={currency}
            rowLabel="Domestic additional tier fee"
            rowTooltip="The fee is applied to the domestic transaction with amount between values defined in Min transaction amount (including) and Max transaction amount (not including)"
          />
        )}

        {fields.domesticFee && (
          <FeesValuesRow
            form={form}
            fields={fields.domesticFee}
            productCurrency={currency}
            rowLabel={amountEnabled ? "Domestic default tier fee" : "Domestic fee"}
            rowTooltip="For transaction done through device located on the domestic country (excluding OnUs transactions, if specified)"
          />
        )}

        {fields.intraregionalFee && (
          <FeesValuesRow
            form={form}
            fields={fields.intraregionalFee}
            productCurrency={currency}
            rowLabel={amountEnabled ? "Intraregional default tier fee" : "Intraregional fee"}
          />
        )}

        {fields.intraregionalAdditionalFee && (
          <FeesValuesRow
            form={form}
            fields={fields.intraregionalAdditionalFee}
            productCurrency={currency}
            rowLabel="Additional intraregional tier fee"
          />
        )}

        {regEnabled && (
          <Alert>
            Please reach out to your Network International representative for providing your acquiring process details
            to segregate OnUs transactions
          </Alert>
        )}
      </div>
    </Space>
  );
};

export { CTFTab };
