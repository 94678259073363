import { escapeRegExp } from "lodash";

import { Variable } from "@ni/sdk/models";

export const displayReadableSmsVariables = (text: string, variables: Variable[]) => {
  let currentText = text;

  variables.forEach(smsVariable => {
    if (currentText?.includes(smsVariable.code)) {
      currentText = currentText.replace(new RegExp(escapeRegExp(smsVariable.code), "g"), smsVariable.example || "");
    }
  });

  return currentText;
};
