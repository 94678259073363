import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 9" fill="none">
    <path
      d="M9.81752 2.77811L18.9006 9.88704C19.0398 9.99686 19.2461 9.89776 19.2461 9.71829V7.64776C19.2461 7.51651 19.1845 7.39061 19.0827 7.31026L10.3452 0.471864C10.1943 0.353432 10.008 0.289063 9.81618 0.289063C9.62435 0.289062 9.43807 0.353432 9.28716 0.471864L0.55234 7.31026C0.447876 7.39061 0.388948 7.51651 0.388948 7.64776V9.71829C0.388948 9.89776 0.595198 9.99686 0.734484 9.88704L9.81752 2.77811Z"
      fill="currentColor"
    />
  </svg>
);

export const ChevronUpAltIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
