import { FC } from "react";
import { Form } from "antd";
import { parseInt } from "lodash";
import { StoreValue } from "rc-field-form/lib/interface";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { nicGeneralCardRelatedParamsOptions } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGeneral, PageItemLayoutGroup } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import { useShowFields } from "../../hooks";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

export interface GeneralCardRelatedParametersForm {
  "nic-plast-exp-same-before": number;
  "nic-plast-exp-prev-after": number;
  "nic-plast-exp-prev-before": number;
  "max-pin-tries": number;
  "nic-locked-card-rc": string;
  "nic-phys-restr-for-virt-use": boolean;
  "nic-pin-tries-autoreset": boolean;
  "nic-pin-tries-drive-block-factor": boolean;
}

const stringsKeys = [
  "nic-plast-exp-same-before",
  "nic-plast-exp-prev-after",
  "nic-plast-exp-prev-before",
  "max-pin-tries",
  "nic-locked-card-rc",
  "nic-phys-restr-for-virt-use",
  "nic-pin-tries-autoreset",
  "nic-pin-tries-drive-block-factor",
];

export const GeneralCardRelatedParameters: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [showFields] = useShowFields(tenant, isLoading);

  useHydrateForm(
    {
      form,
      entityFields: tenant.tenantValues ?? [],
      keys: {
        strings: stringsKeys,
        unparsedStrings: ["nic-locked-card-rc"],
      },
    },
    [tenant],
  );

  const validateNumberInput = (value: StoreValue): Promise<string | void> => {
    if (value && (value < 0 || value > 13))
      return Promise.reject(new Error("Number of days must be equals to or lower than 13."));
    return Promise.resolve();
  };

  const onFinish = (value: GeneralCardRelatedParametersForm) => {
    setIsLoading(true);

    const tenantId = parseInt(id ?? "0", 10);
    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys(value).map((val: string) => {
        if (
          val === "nic-plast-exp-same-before" ||
          val === "nic-plast-exp-prev-after" ||
          val === "nic-plast-exp-prev-before" ||
          val === "max-pin-tries" ||
          val === "nic-locked-card-rc"
        ) {
          return {
            fieldCode: val,
            value: (value[val as keyof GeneralCardRelatedParametersForm] as string) || "",
          };
        }

        return {
          fieldCode: val,
          value: value[val as keyof GeneralCardRelatedParametersForm] ? "true" : "false",
        };
      }),
    };

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Card and PIN Maintenance"
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
    >
      <PageItemLayoutGeneral>
        <PageItemLayoutGroup className={styles["general-cr-parameters-container"]}>
          <div className="text-blue-h3">Renew/Reissue plastic expiry date</div>
          <NetworkForm.Number
            className={styles["general-cr-parameters-input-item"]}
            formItemOptions={{
              name: "nic-plast-exp-same-before",
              label: "Generate Same - Months Since Open",
              rules: [
                () => ({
                  validator(_, value) {
                    return validateNumberInput(value);
                  },
                }),
              ],
              tooltip: {
                icon: <QuestionCircleFilled />,
                title: (
                  <div>
                    <div className="m-b-8">For example:</div>
                    <ul>
                      <li>Card open date is 01 Jan 2021 and expiry date Jan 2026 (if card term months 60 months)</li>
                      <li>This parameter value is 3</li>
                      <li>Card was renewed or reissued in Feb 2021</li>
                    </ul>
                    <div>
                      Since reissuing occurs less then in 3 month after open date then expiry date will be same as
                      existing plastic expiry date.
                    </div>
                  </div>
                ),
              },
            }}
          />

          <NetworkForm.Number
            className={styles["general-cr-parameters-input-item"]}
            formItemOptions={{
              name: "nic-plast-exp-prev-before",
              label: "Increment from Previous - Months Before",
              rules: [
                () => ({
                  validator(_, value) {
                    return validateNumberInput(value);
                  },
                }),
              ],
              tooltip: {
                icon: <QuestionCircleFilled />,
                title: (
                  <div>
                    <div className="m-b-8">For example:</div>
                    <ul>
                      <li>Card expiry date Jan 2021</li>
                      <li>This parameter value is 3</li>
                      <li>Card was renewed or reissued in Dec 2020.</li>
                    </ul>
                    <div>
                      Since new reissuing occurred less than 3 months before old card expiry date then new expiry date
                      will be calculated from old card expiry date not from current date.
                      <br />
                      <br />
                      So it will be Jan 2026 (if card term months 60 months) not Feb 2026.
                    </div>
                  </div>
                ),
              },
            }}
          />

          <NetworkForm.Number
            className={styles["general-cr-parameters-input-item"]}
            formItemOptions={{
              name: "nic-plast-exp-prev-after",
              label: "Increment from Previous - Months After",
              rules: [
                () => ({
                  validator(_, value) {
                    return validateNumberInput(value);
                  },
                }),
              ],
              tooltip: {
                icon: <QuestionCircleFilled />,
                title: (
                  <div>
                    <div className="m-b-8">For example:</div>
                    <ul>
                      <li>Card expiry date Jan 2021</li>
                      <li>This parameter value is 3</li>
                      <li>Card was renewed or reissued in Feb 2021</li>
                    </ul>
                    <div>
                      Since new reissuing occurred less than in 3 months after old card expiry date then new expiry date
                      will be calculated from old card expiry date not from current date.
                      <br />
                      <br />
                      So it will be Jan 2026 (if card term months 60 months) not Feb 2026.
                    </div>
                  </div>
                ),
              },
            }}
          />
        </PageItemLayoutGroup>

        <PageItemLayoutGroup className={styles["general-cr-parameters-container"]}>
          <div className="text-blue-h3">PIN tries setup</div>

          <NetworkForm.Number
            min={1}
            className={styles["general-cr-parameters-input-item"]}
            formItemOptions={{
              name: "max-pin-tries",
              label: "Maximal PIN-tries",
              tooltip: {
                icon: <QuestionCircleFilled />,
                title: "In case of PIN-tries exceeded, PIN-based transactions will be blocked",
              },
              initialValue: 3,
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "nic-pin-tries-drive-block-factor",
              label: "Block “non-PIN-based” transactions in case of PIN-tries exceeded",
              tooltip:
                "In case of PIN-tries exceeded, non-PIN-based transactions will be blocked, if the parameter is enabled (e.g. e-commerce transactions)",
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              initialValue: true,
              name: "nic-pin-tries-autoreset",
              label: 'Reset the "Exceeded PIN-tries" block every End of Day',
              tooltip:
                "Customer is allowed to re try the PIN up to several times as per the configuration. Once the defined limit is exceeded then block will be applied on the card. If this parameter is set then as part of End of Of Day (EOD) processing, block will be removed which is applied due to incorrect pin.",
            }}
          />
        </PageItemLayoutGroup>

        <PageItemLayoutGroup className={styles["general-cr-parameters-container"]}>
          <div className="text-blue-h3">Others</div>
          <NetworkForm.Select
            optionList={nicGeneralCardRelatedParamsOptions}
            className={styles["general-cr-parameters-input-item"]}
            formItemOptions={{
              className: "general-cr-parameters-select-item",
              name: "nic-locked-card-rc",
              label: "Response code when attempting to authorize a locked card",
            }}
          />

          {showFields["nic-phys-restr-for-virt-use"] && (
            <NetworkForm.Switch
              formItemOptions={{
                valuePropName: "checked",
                name: "nic-phys-restr-for-virt-use",
                label: "Restrict Physical Transactions for Virtual Cards",
                tooltip: (
                  <div>
                    Some of the virtual cards can be used as a temporary solution. They are issued without card
                    production and are marked as &quot;virtual&quot; using a classifier. In the same time, or at a later
                    stage, the card can be reissued as physical, with the same card number and expiry date.
                    <br />
                    <br />
                    The restriction is applied to prevent that physical card from being used before the cardholder
                    received it (for example, by the courier). When the physical card is received by the cardholder, the
                    virtual card indicator is changed to &quot;physical&quot; and the restriction is removed.
                  </div>
                ),
              }}
            />
          )}
        </PageItemLayoutGroup>
      </PageItemLayoutGeneral>
    </CustomFormWrapper>
  );
};
