import { NavbarItem } from "@ni/common/types";

export const NAVBAR_ITEMS: NavbarItem[] = [
  {
    name: "Financial Institutions",
    route: "financial-institutions",
    dependency: "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='FI')]",
    dependencyType: "USER",
  },
  {
    name: "Questionnaire Processing Data",
    route: "/admin/questionnaire-processing-data",
  },
  {
    name: "Deployments",
    dependency: "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='DICTIONARY')]",
    dependencyType: "USER",
    route: "/admin/deployments",
  },
  {
    name: "Business Configurations",
    dependency: "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='DICTIONARY')]",
    nestedItems: [
      {
        name: "SMS Templates",
        route: "sms-templates",
        dependency:
          "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='SMS_TEMPLATE')] OR $..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='TEMPLATE')]",
        dependencyType: "USER",
      },
      {
        name: "Variables for SMS Templates",
        route: "sms-variables-dictionary",
        dependency:
          "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='VARIABLE')] OR $..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='DICTIONARY')]",
        dependencyType: "USER",
      },
      {
        name: "Dictionaries",
        route: "dictionary-values",
        dependency:
          "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='DICTIONARY')] OR $..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='DICTIONARY')]",
        dependencyType: "USER",
      },
      {
        name: "Features",
        route: "features",
        dependency:
          "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='FEATURE')] OR $..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='DICTIONARY')]",
        dependencyType: "USER",
      },
    ],
  },
  {
    name: "Workflow Configurations",
    dependency: "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='WORKFLOW')]",
    dependencyType: "USER",
    nestedItems: [
      {
        name: "Workflows",
        route: "workflows",
        dependency: "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='WORKFLOW')]",
        dependencyType: "USER",
      },
      {
        name: "Element Templates",
        route: "element-templates",
        dependency:
          "$..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='TEMPLATE')] OR $..userRoles..roleRestrictions[?(@.restrictionsObjectLevel=='TEMPLATE')]",
        dependencyType: "USER",
      },
    ],
  },
];
