import { useMemo } from "react";
import { Form, notification, Typography } from "antd";
import { useParams } from "react-router-dom";

import { IPS_TRANS_FEE_ENABLED, SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, Tabs } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import { CTF_DEPENDED_VALUES, EMTY_CTF_VALUES } from "./constants";
import { CTFTab } from "./CTFTab";

import styles from "../../styles.module.scss";

export const CardTransactionFeesPage = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const ipsTransFeeEnabled = Form.useWatch<string>(IPS_TRANS_FEE_ENABLED, form);

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: pct?.pctProductValues ?? [],
      keys: {
        strings: Object.keys(EMTY_CTF_VALUES),
      },
    },
    [form, pct?.pctProductValues],
  );

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      [...Object.keys(EMTY_CTF_VALUES)].forEach(key => {
        if (!values[key]?.toString() && pct?.pctProductValues?.find(item => item.fieldCode === key)) {
          values[key] = "";
        }
      });

      const collectedValues = ipsTransFeeEnabled ? values : EMTY_CTF_VALUES;

      await onSavePct(collectedValues as FormValues).then(() => {
        form.resetFields(
          pct.pctProductValues
            ?.filter(pctValue => pctValue.fieldCode.includes("def-tier-min"))
            .map(field => field.fieldCode),
        );

        CTF_DEPENDED_VALUES.forEach(values => {
          form.setFieldValue(values.child, form.getFieldValue(values.parent));
        });
      });

      form.setFields(
        Object.entries(form.getFieldsValue()).map(field => ({
          name: field[0],
          value: field[1],
          touched: false,
        })),
      );
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  const pagesList = useMemo(
    () => [
      {
        key: "atm" as const,
        label: <div title="ATM">ATM</div>,
        children: <CTFTab tab="atm" form={form} currency={productCurrency} pct={pct} />,
      },
      {
        key: "cash" as const,
        label: <div title="Cash POS">Cash POS</div>,
        children: <CTFTab tab="cash" form={form} currency={productCurrency} pct={pct} />,
      },
      {
        key: "retail" as const,
        label: <div title="Retail">Retail</div>,
        children: <CTFTab tab="retail" form={form} currency={productCurrency} pct={pct} />,
      },
      {
        key: "refund" as const,
        label: <div title="Refund">Refund</div>,
        children: <CTFTab tab="refund" form={form} currency={productCurrency} pct={pct} />,
      },
      {
        key: "p2p-deb" as const,
        label: <div title="P2P Debit">P2P Debit</div>,
        children: <CTFTab tab="p2p-deb" form={form} currency={productCurrency} pct={pct} />,
      },
      {
        key: "p2p-paym" as const,
        label: <div title="P2P Payment">P2P Payment</div>,
        children: <CTFTab tab="p2p-paym" form={form} currency={productCurrency} pct={pct} />,
      },
      {
        key: "quasi" as const,
        label: <div title="Quasi Cash">Quasi Cash</div>,
        children: <CTFTab tab="quasi" form={form} currency={productCurrency} pct={pct} />,
      },
    ],
    [form, pct, productCurrency],
  );

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Card Transaction Fees"
      pageSubtitle="Transaction Based Fees are charged to an account only when certain types of transactions occur on an account."
      submitHandler={onFinish}
      size="full"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      onValuesChange={form.validateFields}
    >
      <div className={styles["fee-switch-group"]}>
        <NetworkForm.Switch
          formItemOptions={{
            name: "ips-trans-fee-enabled",
            label: <Typography.Text strong={true}>Enable card transaction fees on your product</Typography.Text>,
            valuePropName: "checked",
          }}
        />

        {ipsTransFeeEnabled && (
          <NetworkForm.Switch
            formItemOptions={{
              name: "nic-ips-fees-fin-only",
              label: <Typography.Text strong={true}>Do not block fee amount on authorization</Typography.Text>,
              valuePropName: "checked",
            }}
          />
        )}
      </div>

      {ipsTransFeeEnabled && (
        <Tabs
          form={form}
          onSave={onFinish}
          pagesList={pagesList}
          initialValues={initialValues}
          isCheckEnabled={true}
          discardAfterChangeTab={true}
          excludedFieldsListFromCheck={["ips-trans-fee-enabled", "nic-ips-fees-fin-only"]}
        />
      )}
    </CustomFormWrapper>
  );
};
