import { FC } from "react";
import { Form, Space, Switch } from "antd";

import { TooltipInfo } from "@ni/common/ui";

interface RestictionRuleSwitchProps {
  name: string;
  initialValue: boolean;
  title: string;
  tooltip: string;
  onChange: (checked: boolean) => void;
}

export const RestictionRuleSwitch: FC<RestictionRuleSwitchProps> = ({
  name,
  initialValue,
  title,
  tooltip,
  onChange,
}) => {
  return (
    <Space direction="horizontal">
      <Form.Item name={name} valuePropName="checked" className="col-switch" initialValue={initialValue}>
        <Switch onChange={onChange} />
      </Form.Item>
      <div>{title}</div>
      <TooltipInfo tooltipProps={{ title: tooltip }} />
    </Space>
  );
};
