import { PageItemLayoutGroup } from "@ni/common/ui";
import { ParameterTable } from "@ni/sdk/models";

import { PctListItem } from "../PctListItem";

import styles from "./styles.module.scss";

interface PctListProps {
  pctList: ParameterTable[];
  isLoading: boolean;
  onNavigateToEditPct: (pctId: number) => void;
  renameClickHandler: (pctId: number) => void;
  duplicateClickHandler: (pctId: number) => void;
  deleteClickHandler: (pctId: number) => void;
}

export const PctList = ({
  pctList,
  isLoading,
  onNavigateToEditPct,
  renameClickHandler,
  duplicateClickHandler,
  deleteClickHandler,
}: PctListProps) => {
  return (
    <PageItemLayoutGroup className={styles["list"]}>
      {pctList.map((item, index) => {
        return (
          <PctListItem
            key={Math.random()}
            rowId={(index + 1).toString()}
            externalCode={item.externalCode}
            pctId={item.id || 0}
            displayedName={item.displayName}
            isLoading={isLoading}
            editPctHandler={onNavigateToEditPct}
            renamePctHandler={renameClickHandler}
            duplicateClickHandler={duplicateClickHandler}
            deletePctHandler={deleteClickHandler}
          />
        );
      })}
    </PageItemLayoutGroup>
  );
};
