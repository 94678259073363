import { Form, Space, Typography } from "antd";
import { checkEveryPctValue } from "libs/product-settings/src/lib/utils";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  MEM_FEE_TYPE,
  NIC_AMF_ACC_EX_MONTHS,
  NIC_AMF_ACC_VAL,
  NIC_MMF_ACC_VAL,
  WAIVE_AMF_SPEND,
  WAIVE_AMF_SPEND_AMOUNT,
} from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { feeIntervalList } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutElements } from "@ni/common/ui";

import { FeeEditingBox } from "../../../../components";
import { usePct } from "../../../../hooks";

import {
  AMF_SUPPL_ENABLED,
  NIC_AMF_CARD_VAL,
  NIC_AMF_EX_MONTHS,
  NIC_MMF_CARD_ENABLED_PCT,
  NIC_MMF_CARD_VAL,
} from "./constants";

import styles from "../../styles.module.scss";

const stringsKeys = [
  AMF_SUPPL_ENABLED,
  NIC_AMF_CARD_VAL,
  NIC_AMF_EX_MONTHS,
  NIC_MMF_CARD_ENABLED_PCT,
  NIC_MMF_CARD_VAL,
  MEM_FEE_TYPE,
  NIC_AMF_ACC_EX_MONTHS,
  WAIVE_AMF_SPEND_AMOUNT,
  NIC_AMF_ACC_VAL,
  NIC_MMF_ACC_VAL,
  WAIVE_AMF_SPEND,
];

export const MembershipFeesPage = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const memFeeType = Form.useWatch<string>(MEM_FEE_TYPE, form);
  const amfSupplEnabled = Form.useWatch<boolean>(AMF_SUPPL_ENABLED, form);
  const niceMmfCardEnabledPct = Form.useWatch<boolean>(NIC_MMF_CARD_ENABLED_PCT, form);
  const waiveFeeEnabled = Form.useWatch(WAIVE_AMF_SPEND, form);

  useHydrateForm(
    {
      form,
      entityFields: pct.pctProductValues ?? [],
      keys: {
        strings: stringsKeys,
      },
    },
    [pct.pctProductValues],
  );

  const onFinish = async (values: FormValues) => {
    const productValues: FormValues = {};

    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && pct.pctProductValues?.find(item => item.fieldCode === key)) {
        values[key] = "";
      }
    });

    const isDisabledOnAllPcts = checkEveryPctValue({
      pctId,
      parameterTables: currentProduct.parameterTables,
      fieldCode: MEM_FEE_TYPE,
      fieldValue: "N",
      newFieldValue: values[MEM_FEE_TYPE],
    });

    if (isDisabledOnAllPcts) {
      productValues["nic-amf-acc-start-mode"] = "";
      productValues["nic-amf-card-start-mode"] = "";
      productValues["amf-acc-moment"] = "";
      productValues["amf-card-moment"] = "";
      productValues["nic-amf-card-waive-unexp"] = "";
      productValues["nic-mmf-card-waive-unexp"] = "";
    }

    await onUpdateProduct(productValues, undefined, {
      id: Number(pctId),
      values,
    });
  };

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id}/fees-settings`,
      label: `Fee Settings - ${currentProduct.displayName}`,
    },
  ];

  const renderYearlyContent = () => {
    return (
      <PageItemLayoutElements>
        <FeeEditingBox
          title="Account annual membership parameters"
          subtitle="Annual Membership fee is a flat amount levied to the customer on each anniversary of the account open date
          or on another day based on setup"
          tooltipText="You can change charging day, visit Fees product configuration at Product settings."
        >
          <Space direction="vertical" size={24}>
            <NetworkForm.Number
              precision={2}
              controls={false}
              addonAfter={productCurrency}
              formItemOptions={{
                name: NIC_AMF_ACC_VAL,
                label: <div>Annual membership fee amount</div>,
                rules: [
                  {
                    validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                    message: "Minimum fee amount can not be 0",
                  },
                  { required: true, message: "Annual membership fee amount is required" },
                ],
              }}
            />

            <NetworkForm.Number
              min={0}
              max={120}
              precision={0}
              step={1}
              formItemOptions={{
                name: NIC_AMF_ACC_EX_MONTHS,
                label: "Delay first charge by (months)",
              }}
            />

            <NetworkForm.Switch
              formItemOptions={{
                name: WAIVE_AMF_SPEND,
                label: <Typography.Text strong={true}>Waive fee on eligible net spend</Typography.Text>,
                tooltip:
                  "Specify a net spend limit to waive annual membership fee based on amount spent on the year between charging occurrences",
                initialValue: false,
                valuePropName: "checked",
              }}
            />

            {waiveFeeEnabled && (
              <NetworkForm.Number
                precision={2}
                controls={false}
                addonAfter={productCurrency}
                formItemOptions={{
                  name: WAIVE_AMF_SPEND_AMOUNT,
                  label: "Net spend amount",
                  tooltip: {
                    icon: <QuestionCircleFilled />,
                    title: "If net spend is equal to or higher than this amount, fee will be waived",
                  },
                  rules: [
                    {
                      validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                      message: "Minimum fee amount can not be 0",
                    },
                    { required: true, message: "Net spend amount is required" },
                  ],
                }}
              />
            )}
          </Space>
        </FeeEditingBox>

        <FeeEditingBox
          title={
            <NetworkForm.Switch
              formItemOptions={{
                name: AMF_SUPPL_ENABLED,
                label: "Supplementary card annual membership parameters",
                valuePropName: "checked",
              }}
            />
          }
        >
          {amfSupplEnabled && (
            <div>
              <div>This fee will be charged on card holder statement issuance day by default.</div>
              <NetworkForm.Number
                precision={2}
                controls={false}
                addonAfter={productCurrency}
                formItemOptions={{
                  name: NIC_AMF_CARD_VAL,
                  label: "Supplementary card annual membership fee amount",
                  tooltip: "You can change charging day, visit Fees product configuration at Product settings.",
                  rules: [
                    {
                      validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                      message: "Minimum fee amount can not be 0",
                    },
                    { required: true, message: "Annual membership fee amount is required" },
                  ],
                  className: styles["pct-editing-box-input"],
                }}
              />

              <NetworkForm.Number
                min={0}
                max={120}
                precision={0}
                step={1}
                formItemOptions={{
                  name: NIC_AMF_EX_MONTHS,
                  label: "Delay first charge by (months)",
                  className: styles["pct-editing-box-input"],
                }}
              />
            </div>
          )}
        </FeeEditingBox>
      </PageItemLayoutElements>
    );
  };

  const renderMonthlyContent = () => {
    return (
      <PageItemLayoutElements>
        <FeeEditingBox
          title="Account monthly membership parameters"
          subtitle="This fee will be charged on card holder statement issuance day by default."
          tooltipText="You can change charging day, visit Fees product configuration at Product settings."
        >
          <NetworkForm.Number
            min={0}
            precision={2}
            controls={false}
            addonAfter={productCurrency}
            formItemOptions={{
              name: NIC_MMF_ACC_VAL,
              label: "Monthly membership fee amount",
              rules: [
                {
                  validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                  message: "Minimum fee amount can not be 0",
                },
                { required: true, message: "Monthly membership fee amount is required" },
              ],
              className: styles["pct-editing-box-input"],
            }}
          />
        </FeeEditingBox>

        <FeeEditingBox
          title={
            <NetworkForm.Switch
              formItemOptions={{
                name: NIC_MMF_CARD_ENABLED_PCT,
                valuePropName: "checked",
                label: "Supplementary card monthly membership parameters",
                className: "col-switch",
              }}
            />
          }
        >
          {niceMmfCardEnabledPct && (
            <div>
              <div>Supplementary card monthly membership parameters</div>
              <NetworkForm.Number
                formItemOptions={{
                  name: NIC_MMF_CARD_VAL,
                  label: "Supplementary card monthly membership fee amount",
                  tooltip: "You can change charging day, visit Fees product configuration at Product settings.",
                  rules: [
                    {
                      validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                      message: "Minimum fee amount can not be 0",
                    },
                    {
                      required: true,
                      message: "Supplementary card monthly membership fee amount is required",
                    },
                  ],
                  className: styles["pct-editing-box-input"],
                }}
                precision={2}
                controls={false}
                addonAfter={productCurrency}
              />
            </div>
          )}
        </FeeEditingBox>
      </PageItemLayoutElements>
    );
  };

  const renderContent = (value: string) => {
    switch (value) {
      case "Y":
        return renderYearlyContent();
      case "M":
        return renderMonthlyContent();
      case "N":
        return null;
      default:
        return renderYearlyContent();
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Membership Fees"
      pageSubtitle="Membership fees are charged to the account holder over the life cycle of cards, when account is initially boarded an annual membership fee can be charged based upon the product set up. Similarly, you can choose to charge your membership fees on monthly basis."
      submitHandler={onFinish}
      size="md"
      formSize="md"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Radio
        radioList={feeIntervalList}
        formItemOptions={{
          name: MEM_FEE_TYPE,
          label: "Membership fee interval",
          initialValue: "Y",
        }}
      />

      {renderContent(memFeeType)}
    </CustomFormWrapper>
  );
};
