import { FC } from "react";
import { Form } from "antd";

import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import { useInsuranceApi } from "../../../../hooks";

import { ageOption, autoOption, manualOption } from "./constants";

export const CancellationSettings: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentInsurance, editInsurance, formValuesConverter } = useInsuranceApi();

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [ageOption.code, autoOption.code, manualOption.code],
    },
    allowParse: false,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Cancellation Settings"
      pageSubtitle="Insurance can be cancelled for an account at any time manually by the customer, or automatically by system, based upon certain factors like account delinquency level or customer age. You can customize the age parameter on either product level settings or a specific pricing control table level settings, from related screens after wizard."
      level="tenant"
      size="md"
      formSize="full"
      gap={40}
      submitLabel="Save"
      linkTitle="Insurance Templates"
      onValuesChange={form.validateFields}
      submitHandler={onFinish}
      additionalRoute="./insurance-program-templates"
    >
      <NetworkForm.Select
        formItemOptions={{
          name: autoOption.code,
          label: autoOption.name,
          tooltip: autoOption.tooltip,
          initialValue: autoOption.default,
          rules: [
            {
              validator: (_, value) =>
                value >= form.getFieldValue(manualOption.code) ? Promise.reject() : Promise.resolve(),
              message:
                "Delinquency level for auto re-instantiation can not be greater than or equal to selected manual re-instantiation level",
            },
          ],
        }}
        optionList={autoOption.options?.map(x => ({ label: x.displayed, value: x.value }))}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: manualOption.code,
          label: manualOption.name,
          tooltip: manualOption.tooltip,
          initialValue: manualOption.default,
          rules: [
            {
              validator: (_, value) =>
                value <= form.getFieldValue(autoOption.code) ? Promise.reject() : Promise.resolve(),
              message:
                "Delinquency level for manual re-instantiation cannot be less than selected for automated re-instantiation",
            },
          ],
        }}
        optionList={manualOption.options?.map(x => ({ label: x.displayed, value: x.value }))}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: ageOption.code,
          label: ageOption.name,
          tooltip: ageOption.tooltip,
          initialValue: ageOption.default,
        }}
        addonAfter="years"
        min={ageOption.min}
        max={ageOption.max}
        precision={ageOption.decimalsNumber}
      />
    </CustomFormWrapper>
  );
};
