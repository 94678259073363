import { memo, useState } from "react";
import { Collapse } from "antd";
import cn from "classnames";
import type { FC } from "react";

import { DeleteOutlined, UpOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { ConfirmModal } from "@ni/common/ui";
import { ConfigurationApi } from "@ni/sdk/apis";
import { Event, EventSubgroup } from "@ni/sdk/models";

import EventForm from "../EventForm";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();
const { Panel } = Collapse;

interface CardProps {
  event: Event;
  id: string;
  eventSubGroupItems: EventSubgroup[];
  templateId: number;
  eventSubGroupId: number;
}

const CardFunc: FC<CardProps> = props => {
  const { event, id, eventSubGroupItems, templateId, eventSubGroupId } = props;

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [, setIsLoading] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useReduxState<Event>("selectedEvent");
  const [eventItems, setEventItems] = useReduxState<Event[]>("eventItems", []);

  const onChangeCollapse = () => {
    setIsActive(!isActive);
  };

  const onCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const extraHeader = () => (
    <div className={styles["event-collapse-header"]}>
      <div className={styles["title"]}>
        <div className={styles["name"]}>
          {event?.eventName ?? "Event"}
          {isActive ? (
            <UpOutlined className={styles["arrow"]} />
          ) : (
            <UpOutlined className={cn(styles["arrow"], styles["open"])} />
          )}
        </div>
      </div>
    </div>
  );

  const extraPanel = () => (
    <div className={styles["event-collapse-header-extra"]}>
      <DeleteOutlined
        className={styles["button"]}
        onClick={() => {
          setIsDeleteModalOpen(true);
          setSelectedEvent(event);
        }}
      />
    </div>
  );

  const removeNewEventWithOrder = (eventName: string, eventSubgroupId: number) => {
    const newEventItemsList = eventItems.filter(
      obj => obj.eventName === eventName && obj.eventSubgroupId === eventSubgroupId && !obj.id,
    );
    setEventItems(newEventItemsList);
  };

  const getEventItems = () => {
    setIsLoading(true);

    configurationServiceApi
      .getEvents(templateId, eventSubGroupId)
      .then(response => {
        setEventItems(response.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const onDeleteEvent = () => {
    setIsLoading(true);
    if (event.id) {
      configurationServiceApi
        .deleteEvent(templateId, event.eventSubgroupId!, selectedEvent.id!)
        .then(() => {
          onCancelDeleteModal();
          getEventItems();
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    } else {
      onCancelDeleteModal();
      removeNewEventWithOrder(event.eventName!, event.eventSubgroupId!);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles["event"]}>
      <Collapse className={styles["event-collapse"]} collapsible="header" onChange={onChangeCollapse}>
        <Panel header={extraHeader()} key={id} showArrow={false} extra={extraPanel()}>
          <EventForm
            event={event}
            templateId={templateId}
            eventSubGroupId={eventSubGroupId}
            eventSubGroupItems={eventSubGroupItems}
            getEventItems={getEventItems}
          />
        </Panel>
      </Collapse>
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the Event. Are you sure?"
        isOpened={isDeleteModalOpen}
        onCancel={onCancelDeleteModal}
        onConfirm={onDeleteEvent}
        isLoading={false}
        type="delete"
      />
    </div>
  );
};

const EventCard: FC<CardProps> = memo(CardFunc);
export default EventCard;
