import { FC } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DocumentationList, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { reportsWizardPage } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ReportsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const reportList = reportsWizardPage;

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const onFinish = (): void => {
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {},
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Reports"
      pageSubtitle={
        <TooltipInfo
          largeLabel="Reports are the integral part of the system which provides various information on the activities happened or
            carried out by user or through interfaces."
          tooltipProps={{
            title: (
              <>
                <span>Reports are usually generated for the following reasons:</span>
                <ul>
                  <li>
                    Success and Reject reports corresponding to incoming interface like account boarding, payment file,
                    etc.
                  </li>
                  <li>User activities done on the accounts including both financial and demographic maintenance.</li>
                  <li>Standard reports showing the information like transactions processed in the last batch, etc.</li>
                </ul>
              </>
            ),
          }}
        />
      }
      size="full"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <DocumentationList documentationList={reportList} data={wizardResponse} />
    </CustomFormWrapper>
  );
};
