import { FC, useEffect, useMemo } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm, DynamicSwitchRule } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import {
  checkboxFieldsPage9,
  checkHeader,
  getAccountDailyLimitsPreparedValues,
  switchHeader9,
} from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionAccountLimitsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      "nic-a-d-cash-atm-enable": true,
      "nic-a-d-cash-atm-max-am": formDisabledFn("nic-a-d-cash-atm-max-am", "1000000"),
      "nic-a-d-cash-atm-max-nr": formDisabledFn("nic-a-d-cash-atm-max-nr", "20"),
      "nic-a-d-cash-atm-max-single": formDisabledFn("nic-a-d-cash-atm-max-single", "1000000"),
      "nic-a-d-cash-dom-enable": true,
      "nic-a-d-cash-dom-max-am": formDisabledFn("nic-a-d-cash-dom-max-am", "1000000"),
      "nic-a-d-cash-dom-max-nr": formDisabledFn("nic-a-d-cash-dom-max-nr", "20"),
      "nic-a-d-cash-dom-max-single": formDisabledFn("nic-a-d-cash-dom-max-single", "1000000"),
      "nic-a-d-cash-enable": true,
      "nic-a-d-cash-for-enable": true,
      "nic-a-d-cash-for-max-am": formDisabledFn("nic-a-d-cash-for-max-am", "1000000"),
      "nic-a-d-cash-for-max-nr": formDisabledFn("nic-a-d-cash-for-max-nr", "20"),
      "nic-a-d-cash-for-max-single": formDisabledFn("nic-a-d-cash-for-max-single", "1000000"),
      "nic-a-d-cash-max-am": formDisabledFn("nic-a-d-cash-max-am", "1000000"),
      "nic-a-d-cash-max-nr": formDisabledFn("nic-a-d-cash-max-nr", "20"),
      "nic-a-d-cash-max-single": formDisabledFn("nic-a-d-cash-max-single", "1000000"),
      "nic-a-d-cash-pos-enable": true,
      "nic-a-d-cash-pos-max-am": formDisabledFn("nic-a-d-cash-pos-max-am", "1000000"),
      "nic-a-d-cash-pos-max-nr": formDisabledFn("nic-a-d-cash-pos-max-nr", "20"),
      "nic-a-d-cash-pos-max-single": formDisabledFn("nic-a-d-cash-pos-max-single", "1000000"),
      "nic-a-d-ecomm-enable": true,
      "nic-a-d-ecomm-max-am": formDisabledFn("nic-a-d-ecomm-max-am", "1000000"),
      "nic-a-d-ecomm-max-nr": formDisabledFn("nic-a-d-ecomm-max-nr", "20"),
      "nic-a-d-ecomm-max-single": formDisabledFn("nic-a-d-ecomm-max-single", "1000000"),
      "nic-a-d-retail-dom-enable": true,
      "nic-a-d-retail-dom-max-am": formDisabledFn("nic-a-d-retail-dom-max-am", "1000000"),
      "nic-a-d-retail-dom-max-nr": formDisabledFn("nic-a-d-retail-dom-max-nr", "20"),
      "nic-a-d-retail-dom-max-single": formDisabledFn("nic-a-d-retail-dom-max-single", "1000000"),
      "nic-a-d-retail-enable": true,
      "nic-a-d-retail-for-enable": true,
      "nic-a-d-retail-for-max-am": formDisabledFn("nic-a-d-retail-for-max-am", "1000000"),
      "nic-a-d-retail-for-max-nr": formDisabledFn("nic-a-d-retail-for-max-nr", "20"),
      "nic-a-d-retail-for-max-single": formDisabledFn("nic-a-d-retail-for-max-single", "1000000"),
      "nic-a-d-retail-max-am": formDisabledFn("nic-a-d-retail-max-am", "1000000"),
      "nic-a-d-retail-max-nr": formDisabledFn("nic-a-d-retail-max-nr", "20"),
      "nic-a-d-retail-max-single": formDisabledFn("nic-a-d-retail-max-single", "1000000"),
      "nic-a-d-total-enable": true,
      "nic-a-d-total-max-am": formDisabledFn("nic-a-d-total-max-am", "1000000"),
      "nic-a-d-total-max-nr": formDisabledFn("nic-a-d-total-max-nr", "21"),
      "nic-a-d-total-max-single": formDisabledFn("nic-a-d-total-max-single", "1000000"),
    });

    Object.keys(checkboxFieldsPage9).forEach(key => {
      checkHeader(key, checkboxFieldsPage9, form);
    });

    checkHeader("acc-daily-all-cash-enable", switchHeader9, form);
    checkHeader("acc-daily-all-retail-enable", switchHeader9, form);
    checkHeader("acc-daily-all-total-enable", switchHeader9, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const { pctValues, showCash, showRetail, showTotal } = useMemo(() => {
    const pctValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues;

    return {
      pctValues,
      showCash: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("C"),
      showRetail: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("R"),
      showTotal: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("T"),
    };
  }, [wizardResponse?.product?.parameterTables]);

  const rules = useMemo(() => {
    const rules: Record<"cash" | "retail" | "total", DynamicSwitchRule[]> = {
      cash: [
        {
          name: "nic-a-d-cash-enable",
          label: "All",
          tooltip: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-a-d-cash-max-nr",
          maxAmount: "nic-a-d-cash-max-am",
          maxSingleAmount: "nic-a-d-cash-max-single",
        },
      ],
      retail: [
        {
          name: "nic-a-d-retail-enable",
          label: "All",
          tooltip: "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-a-d-retail-max-nr",
          maxAmount: "nic-a-d-retail-max-am",
          maxSingleAmount: "nic-a-d-retail-max-single",
        },
      ],
      total: [
        {
          name: "nic-a-d-total-enable",
          label: "All",
          tooltip:
            "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
          maxNumber: "nic-a-d-total-max-nr",
          maxAmount: "nic-a-d-total-max-am",
          maxSingleAmount: "nic-a-d-total-max-single",
        },
      ],
    };

    if (pctValues?.find(x => x.fieldCode === "trans-lim-atm-pos")?.value) {
      rules.cash.push(
        ...[
          {
            name: "nic-a-d-cash-atm-enable",
            label: "ATM",
            tooltip: "This limiter counts all ATM transactions. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-a-d-cash-atm-max-nr",
            maxAmount: "nic-a-d-cash-atm-max-am",
            maxSingleAmount: "nic-a-d-cash-atm-max-single",
          },
          {
            name: "nic-a-d-cash-pos-enable",
            label: "POS",
            tooltip:
              "This limiter counts all cash transactions done at POS terminals. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-a-d-cash-pos-max-nr",
            maxAmount: "nic-a-d-cash-pos-max-am",
            maxSingleAmount: "nic-a-d-cash-pos-max-single",
          },
        ],
      );
    }

    if (pctValues?.find(x => x.fieldCode === "trans-lim-dom-for")?.value) {
      rules.cash.push(
        ...[
          {
            name: "nic-a-d-cash-dom-enable",
            label: "Domestic",
            tooltip:
              "This limiter counts all domestic cash transactions. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-a-d-cash-dom-max-nr",
            maxAmount: "nic-a-d-cash-dom-max-am",
            maxSingleAmount: "nic-a-d-cash-dom-max-single",
          },
          {
            name: "nic-a-d-cash-for-enable",
            label: "Foreign",
            tooltip:
              "This limiter counts all foreign cash transactions. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-a-d-cash-for-max-nr",
            maxAmount: "nic-a-d-cash-for-max-am",
            maxSingleAmount: "nic-a-d-cash-for-max-single",
          },
        ],
      );

      rules.retail.push(
        ...[
          {
            name: "nic-a-d-retail-dom-enable",
            label: "Domestic",
            tooltip:
              "This limiter counts all domestic retail transactions. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-a-d-retail-dom-max-nr",
            maxAmount: "nic-a-d-retail-dom-max-am",
            maxSingleAmount: "nic-a-d-retail-dom-max-single",
          },
          {
            name: "nic-a-d-retail-for-enable",
            label: "Foreign",
            tooltip: "This limiter counts foreign retail transactions. Turn it off, if restrictions are not intended.",
            maxNumber: "nic-a-d-retail-for-max-nr",
            maxAmount: "nic-a-d-retail-for-max-am",
            maxSingleAmount: "nic-a-d-retail-for-max-single",
          },
        ],
      );
    }

    if (pctValues?.find(x => x.fieldCode === "trans-lim-ecom")?.value) {
      rules.retail.push({
        name: "nic-a-d-ecomm-enable",
        label: "Ecommerce",
        tooltip:
          "This limiter includes retail transactions over an electronic network, primarily the internet. Turn it off, if restrictions are not intended.",
        maxNumber: "nic-a-d-ecomm-max-nr",
        maxAmount: "nic-a-d-ecomm-max-am",
        maxSingleAmount: "nic-a-d-ecomm-max-single",
      });
    }

    return rules;
  }, [pctValues]);

  const onFinish = (value: FormValues): void => {
    let actualValues: { [key: string]: string } = {};

    actualValues = {
      ...actualValues,
      ...getAccountDailyLimitsPreparedValues(value as { [key: string]: string | boolean }),
    };

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: actualValues,
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Account Limits - Daily"
      pageSubtitle="Transaction limits provide the feasibility to apply restrictions on frequency and amounts of card transactions. It could protect both issuer and cardholders, mitigate fraud risk, manage budgets, and comply with regulations."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      {showCash && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="acc-daily-all-cash-enable"
          title="Cash"
          tooltip="Turns off all cash limits."
          rules={rules.cash}
          disabled={formDisabled}
        />
      )}

      {showRetail && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="acc-daily-all-retail-enable"
          title="Retail"
          tooltip="Turns off all retail limits."
          rules={rules.retail}
          disabled={formDisabled}
        />
      )}

      {showTotal && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="acc-daily-all-total-enable"
          title="Total"
          tooltip="Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended."
          rules={rules.total}
          disabled={formDisabled}
        />
      )}
    </CustomFormWrapper>
  );
};
