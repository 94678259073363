import { FC, forwardRef, Ref, useMemo } from "react";
import { Checkbox, Empty, Space, SpaceProps, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import classnames from "classnames";

import { FullDashboardProduct } from "@ni/sdk/models";

import { ProductCard } from "../ProductCard";

import styles from "./styles.module.scss";

interface ProductListProps {
  products?: Array<FullDashboardProduct>;
  hasCheckAll?: boolean;
  direction?: SpaceProps["direction"];
  size?: SpaceProps["size"];
  className?: string;
  value?: Array<string>;
  onChange?: (values: CheckboxValueType[]) => void;
}

export const ProductCardList: FC<ProductListProps> = forwardRef(
  (
    { products, hasCheckAll, direction = "vertical", size = 16, className = "", value, onChange }: ProductListProps,
    ref: Ref<HTMLDivElement> | undefined,
  ) => {
    const productsId = useMemo(() => products?.map(x => x?.product.id) ?? [], [products]);

    const handleChange = (checkedValues: CheckboxValueType[]) => {
      if (onChange) {
        onChange(checkedValues);
      }
    };

    const handleAllcheck = ({ target: { checked } }: CheckboxChangeEvent) => handleChange(checked ? productsId : []);

    return products ? (
      products?.length ? (
        <div className={styles["mainContainer"]}>
          {hasCheckAll && productsId && (
            <Checkbox
              checked={productsId?.length === value?.length}
              onChange={handleAllcheck}
              className={classnames(styles["checkbox"], styles["coloredLabel"])}
            >
              Select all products
            </Checkbox>
          )}
          <Checkbox.Group ref={ref} value={value} onChange={handleChange} className={className}>
            <Space direction={direction} size={size} className="w-p-100">
              {products?.map<JSX.Element>(product => (
                <ProductCard
                  key={product.product.id}
                  product={product}
                  renderLeft={<Checkbox value={product.product.id} className={styles["checkbox"]} />}
                />
              ))}
            </Space>
          </Checkbox.Group>
        </div>
      ) : (
        <div className={styles["spinContainer"]}>
          <Empty />
        </div>
      )
    ) : (
      <div className={styles["spinContainer"]}>
        <Spin />
      </div>
    );
  },
);
