// Top Up Limits Rules Keys
export const NIC_USAGE_PAYM_U01_RULES = "nic-usage-paym-u01-rules";
export const NIC_USAGE_PAYM_U02_RULES = "nic-usage-paym-u02-rules";
export const NIC_USAGE_PAYM_U03_RULES = "nic-usage-paym-u03-rules";

// Card and PIN Maintenance keys

export const VIRT_PHYS = "virt-phys";
export const PERSO = "perso";
export const NIC_CARD_SUBTYPE_EXP_MONTHS = "nic-card-subtype-exp-months";
export const NIC_CARD_SUBTYPE_AUTORENEW_DAYS = "nic-card-subtype-autorenew-days";
export const NIC_CARD_CARR_ADDRESS_LEV_PRIM = "nic-card-carr-address-lev-prim";
export const NIC_CARD_CARR_ADDRESS_LEV_SUPPL = "nic-card-carr-address-lev-suppl";
export const REISS_PHYS = "reiss-phys";
export const NIC_PREEMB_AUTO_ACTIVATE = "nic-preemb-auto-activate";
export const NIC_CARD_SUBTYPE_PINSET_ALLOWED = "nic-card-subtype-pinset-allowed";
export const NIC_CARD_SUBTYPE_PINSET_ALLOWED_FOR_LOCKED = "nic-card-subtype-pinset-allowed-for-locked";
export const SDE = "sde";
