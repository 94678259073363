import React, { FC, ReactNode } from "react";
import { Space } from "antd";
import cn from "classnames";

import styles from "./styles.module.scss";

interface TenantCardProps {
  inputId: string;
  title: ReactNode;
  text?: ReactNode;
  imageSrc: string;
  disabled: boolean;
  children: ReactNode;
}

export const TenantCard: FC<TenantCardProps> = ({ inputId, title, text, imageSrc, disabled, children }) => (
  <label htmlFor={inputId}>
    <Space direction="horizontal" size={24} className={styles["wizard-checkbox-card"]}>
      <Space direction="vertical" size={12} className={styles["wizard-checkbox-card-content"]}>
        <Space direction="horizontal">
          {children}

          <div className={styles["wizard-checkbox-card-title"]}>{title}</div>
        </Space>
        {text && <div className={styles["wizard-checkbox-card-text"]}>{text}</div>}
      </Space>
      <div className={cn(styles["wizard-checkbox-card-image"], disabled && styles["disabled"])}>
        <img src={imageSrc} alt="falcon" />
      </div>
    </Space>
  </label>
);
