import { FC } from "react";
import { Form } from "antd";

import {
  CHECKBOX_GROUP_ELIGIBILITY_CRITERIA,
  LTY_RULE_MCC,
  LTY_RULE_MCC_LIST,
  LTY_RULES_CRITERIA,
  MCC_LIST,
  RADIO_GROUP,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useFieldMandatory, useLoyaltyWizardApi } from "../../hooks";

export const TransactionMerchantCategory: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const isMCCFilled = Form.useWatch<string>(LTY_RULE_MCC_LIST, form);
  const isMCCMandatory = useFieldMandatory({
    values: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    checkAgainst: LTY_RULES_CRITERIA,
    keyToInclude: CHECKBOX_GROUP_ELIGIBILITY_CRITERIA[1].value,
  });

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      lists: [LTY_RULE_MCC_LIST],
      strings: [LTY_RULE_MCC],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: {
          ...values,
          [LTY_RULE_MCC_LIST]: (values[LTY_RULE_MCC_LIST] as string[]).join(","),
        } as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!isMCCFilled}
      pageTitle="Transaction Merchant Category"
      pageSubtitle="Points will be earned for transactions occurred with specific merchant category, e.g. Airlines, or Hotels, etc. Or some merchant categories can be excluded from point earning."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Radio
        formItemOptions={{
          name: LTY_RULE_MCC,
          label: "Select merchant category code (MCC)",
          initialValue: RADIO_GROUP[0].value,
        }}
        radioList={RADIO_GROUP}
        initialValue={RADIO_GROUP[0].value}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: LTY_RULE_MCC_LIST,
          label: "Merchant category code list",
          rules: [
            {
              required: isMCCMandatory,
              validator: (_, value: string[]) => {
                if (!isMCCMandatory || (value && value.length > 0)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please fill out merchant category code (MCC) list."));
              },
            },
          ],
        }}
        mode="multiple"
        optionFilterProp="label"
        optionList={MCC_LIST.map(mcc => ({
          value: mcc.key ?? "",
          label: mcc.value as string,
        }))}
      />
    </CustomFormWrapper>
  );
};
