/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchPatchEventGroupRequest } from '../model';
// @ts-ignore
import { BatchPatchEventSubgroupRequest } from '../model';
// @ts-ignore
import { CreateDictionariesRequest } from '../model';
// @ts-ignore
import { CreateEventGroupRequest } from '../model';
// @ts-ignore
import { CreateEventRequest } from '../model';
// @ts-ignore
import { CreateEventSubgroupRequest } from '../model';
// @ts-ignore
import { Dictionaries } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { Event } from '../model';
// @ts-ignore
import { EventGroup } from '../model';
// @ts-ignore
import { EventSubgroup } from '../model';
// @ts-ignore
import { PageDataRestDictionaries } from '../model';
// @ts-ignore
import { PatchEventGroupRequest } from '../model';
// @ts-ignore
import { PatchEventRequest } from '../model';
// @ts-ignore
import { PatchEventSubgroupRequest } from '../model';
// @ts-ignore
import { PatchTemplateRequest } from '../model';
// @ts-ignore
import { PutDictionariesRequest } from '../model';
// @ts-ignore
import { SdmElementDashboardPageData } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
// @ts-ignore
import { SyncWithWay4Request } from '../model';
// @ts-ignore
import { SyncWithWay4Response } from '../model';
// @ts-ignore
import { Template } from '../model';
/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Batch Patch EventGroup
         * @summary Batch Patch EventGroup
         * @param {BatchPatchEventGroupRequest} batchPatchEventGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchEventGroup: async (batchPatchEventGroupRequest: BatchPatchEventGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchPatchEventGroupRequest' is not null or undefined
            assertParamExists('batchPatchEventGroup', 'batchPatchEventGroupRequest', batchPatchEventGroupRequest)
            const localVarPath = `/api/v1/configurations/event-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchPatchEventGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Batch Patch EventSubgroup
         * @summary Batch Patch EventSubgroup
         * @param {BatchPatchEventSubgroupRequest} batchPatchEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchEventSubgroup: async (batchPatchEventSubgroupRequest: BatchPatchEventSubgroupRequest, eventGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchPatchEventSubgroupRequest' is not null or undefined
            assertParamExists('batchPatchEventSubgroup', 'batchPatchEventSubgroupRequest', batchPatchEventSubgroupRequest)
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('batchPatchEventSubgroup', 'eventGroupId', eventGroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchPatchEventSubgroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Dictionaries
         * @summary Create Dictionaries
         * @param {CreateDictionariesRequest} createDictionariesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDictionaries: async (createDictionariesRequest: CreateDictionariesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDictionariesRequest' is not null or undefined
            assertParamExists('createDictionaries', 'createDictionariesRequest', createDictionariesRequest)
            const localVarPath = `/api/v1/configurations/dictionaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDictionariesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Event
         * @summary Create Event
         * @param {CreateEventRequest} createEventRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent: async (createEventRequest: CreateEventRequest, eventGroupId: number, eventSubgroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventRequest' is not null or undefined
            assertParamExists('createEvent', 'createEventRequest', createEventRequest)
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('createEvent', 'eventGroupId', eventGroupId)
            // verify required parameter 'eventSubgroupId' is not null or undefined
            assertParamExists('createEvent', 'eventSubgroupId', eventSubgroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup/{eventSubgroupId}/event`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)))
                .replace(`{${"eventSubgroupId"}}`, encodeURIComponent(String(eventSubgroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create EventGroup
         * @summary Create EventGroup
         * @param {CreateEventGroupRequest} createEventGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventGroup: async (createEventGroupRequest: CreateEventGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventGroupRequest' is not null or undefined
            assertParamExists('createEventGroup', 'createEventGroupRequest', createEventGroupRequest)
            const localVarPath = `/api/v1/configurations/event-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create EventSubgroup
         * @summary Create EventSubgroup
         * @param {CreateEventSubgroupRequest} createEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventSubgroup: async (createEventSubgroupRequest: CreateEventSubgroupRequest, eventGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventSubgroupRequest' is not null or undefined
            assertParamExists('createEventSubgroup', 'createEventSubgroupRequest', createEventSubgroupRequest)
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('createEventSubgroup', 'eventGroupId', eventGroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventSubgroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Dictionaries by code
         * @summary Delete Dictionaries by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDictionariesByCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deleteDictionariesByCode', 'code', code)
            const localVarPath = `/api/v1/configurations/dictionaries/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Dictionaries by code and id
         * @summary Delete Dictionaries by code and id
         * @param {string} code 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDictionariesByCode1: async (code: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('deleteDictionariesByCode1', 'code', code)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDictionariesByCode1', 'id', id)
            const localVarPath = `/api/v1/configurations/dictionaries/{code}/{id}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Event
         * @summary Delete Event
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent: async (eventGroupId: number, eventSubgroupId: number, eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('deleteEvent', 'eventGroupId', eventGroupId)
            // verify required parameter 'eventSubgroupId' is not null or undefined
            assertParamExists('deleteEvent', 'eventSubgroupId', eventSubgroupId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteEvent', 'eventId', eventId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup/{eventSubgroupId}/event/{eventId}`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)))
                .replace(`{${"eventSubgroupId"}}`, encodeURIComponent(String(eventSubgroupId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete EventGroup
         * @summary Delete EventGroup
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventGroup: async (eventGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('deleteEventGroup', 'eventGroupId', eventGroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete EventSubgroup
         * @summary Delete EventSubgroup
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventSubgroup: async (eventGroupId: number, eventSubgroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('deleteEventSubgroup', 'eventGroupId', eventGroupId)
            // verify required parameter 'eventSubgroupId' is not null or undefined
            assertParamExists('deleteEventSubgroup', 'eventSubgroupId', eventSubgroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup/{eventSubgroupId}`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)))
                .replace(`{${"eventSubgroupId"}}`, encodeURIComponent(String(eventSubgroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Dictionaries by Code
         * @summary Get Dictionaries by Code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionariesByCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getDictionariesByCode', 'code', code)
            const localVarPath = `/api/v1/configurations/dictionaries/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Dictionaries paginated and filtered by code and value
         * @summary Get Dictionaries
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionariesList: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getDictionariesList', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/configurations/dictionaries/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns EventGroup List by Tenant Id
         * @summary Get EventGroup List by Tenant Id
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventGroupListByTenantId: async (tenantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getEventGroupListByTenantId', 'tenantId', tenantId)
            const localVarPath = `/api/v1/configurations/event-groups/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get EventGroups
         * @summary Get EventGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/configurations/event-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get EventSubgroup
         * @summary Get EventSubgroup
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSubgroups: async (eventGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('getEventSubgroups', 'eventGroupId', eventGroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroups`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Events
         * @summary Get Events
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (eventGroupId: number, eventSubgroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('getEvents', 'eventGroupId', eventGroupId)
            // verify required parameter 'eventSubgroupId' is not null or undefined
            assertParamExists('getEvents', 'eventSubgroupId', eventSubgroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup/{eventSubgroupId}/events`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)))
                .replace(`{${"eventSubgroupId"}}`, encodeURIComponent(String(eventSubgroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Sdm data for dashboard matching with specified filters
         * @summary Gets Sdm data for dashboard
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSdmDashboard: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getSdmDashboard', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/configurations/sdm/elements/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch Event
         * @summary Patch Event
         * @param {PatchEventRequest} patchEventRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEvent: async (patchEventRequest: PatchEventRequest, eventGroupId: number, eventSubgroupId: number, eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchEventRequest' is not null or undefined
            assertParamExists('patchEvent', 'patchEventRequest', patchEventRequest)
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('patchEvent', 'eventGroupId', eventGroupId)
            // verify required parameter 'eventSubgroupId' is not null or undefined
            assertParamExists('patchEvent', 'eventSubgroupId', eventSubgroupId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('patchEvent', 'eventId', eventId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup/{eventSubgroupId}/event/{eventId}`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)))
                .replace(`{${"eventSubgroupId"}}`, encodeURIComponent(String(eventSubgroupId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch EventGroup
         * @summary Patch EventGroup
         * @param {PatchEventGroupRequest} patchEventGroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEventGroup: async (patchEventGroupRequest: PatchEventGroupRequest, eventGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchEventGroupRequest' is not null or undefined
            assertParamExists('patchEventGroup', 'patchEventGroupRequest', patchEventGroupRequest)
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('patchEventGroup', 'eventGroupId', eventGroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchEventGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch EventSubgroup
         * @summary Patch EventSubgroup
         * @param {PatchEventSubgroupRequest} patchEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEventSubgroup: async (patchEventSubgroupRequest: PatchEventSubgroupRequest, eventGroupId: number, eventSubgroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchEventSubgroupRequest' is not null or undefined
            assertParamExists('patchEventSubgroup', 'patchEventSubgroupRequest', patchEventSubgroupRequest)
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('patchEventSubgroup', 'eventGroupId', eventGroupId)
            // verify required parameter 'eventSubgroupId' is not null or undefined
            assertParamExists('patchEventSubgroup', 'eventSubgroupId', eventSubgroupId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup/{eventSubgroupId}`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)))
                .replace(`{${"eventSubgroupId"}}`, encodeURIComponent(String(eventSubgroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchEventSubgroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch Template
         * @summary Patch Template
         * @param {PatchTemplateRequest} patchTemplateRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTemplate: async (patchTemplateRequest: PatchTemplateRequest, eventGroupId: number, eventSubgroupId: number, templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchTemplateRequest' is not null or undefined
            assertParamExists('patchTemplate', 'patchTemplateRequest', patchTemplateRequest)
            // verify required parameter 'eventGroupId' is not null or undefined
            assertParamExists('patchTemplate', 'eventGroupId', eventGroupId)
            // verify required parameter 'eventSubgroupId' is not null or undefined
            assertParamExists('patchTemplate', 'eventSubgroupId', eventSubgroupId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('patchTemplate', 'templateId', templateId)
            const localVarPath = `/api/v1/configurations/event-group/{eventGroupId}/event-subgroup/{eventSubgroupId}/template/{templateId}`
                .replace(`{${"eventGroupId"}}`, encodeURIComponent(String(eventGroupId)))
                .replace(`{${"eventSubgroupId"}}`, encodeURIComponent(String(eventSubgroupId)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Put Dictionaries
         * @summary Put Dictionaries
         * @param {PutDictionariesRequest} putDictionariesRequest 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDictionaries: async (putDictionariesRequest: PutDictionariesRequest, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putDictionariesRequest' is not null or undefined
            assertParamExists('putDictionaries', 'putDictionariesRequest', putDictionariesRequest)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('putDictionaries', 'code', code)
            const localVarPath = `/api/v1/configurations/dictionaries/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putDictionariesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sync Dictionaries with way4
         * @summary Sync Dictionaries with way4
         * @param {SyncWithWay4Request} syncWithWay4Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithWay4: async (syncWithWay4Request: SyncWithWay4Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'syncWithWay4Request' is not null or undefined
            assertParamExists('syncWithWay4', 'syncWithWay4Request', syncWithWay4Request)
            const localVarPath = `/api/v1/configurations/dictionaries/sync-with-way4`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncWithWay4Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * Batch Patch EventGroup
         * @summary Batch Patch EventGroup
         * @param {BatchPatchEventGroupRequest} batchPatchEventGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchPatchEventGroup(batchPatchEventGroupRequest: BatchPatchEventGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchPatchEventGroup(batchPatchEventGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Batch Patch EventSubgroup
         * @summary Batch Patch EventSubgroup
         * @param {BatchPatchEventSubgroupRequest} batchPatchEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchPatchEventSubgroup(batchPatchEventSubgroupRequest: BatchPatchEventSubgroupRequest, eventGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventSubgroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchPatchEventSubgroup(batchPatchEventSubgroupRequest, eventGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Dictionaries
         * @summary Create Dictionaries
         * @param {CreateDictionariesRequest} createDictionariesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDictionaries(createDictionariesRequest: CreateDictionariesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dictionaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDictionaries(createDictionariesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Event
         * @summary Create Event
         * @param {CreateEventRequest} createEventRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvent(createEventRequest: CreateEventRequest, eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(createEventRequest, eventGroupId, eventSubgroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create EventGroup
         * @summary Create EventGroup
         * @param {CreateEventGroupRequest} createEventGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventGroup(createEventGroupRequest: CreateEventGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventGroup(createEventGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create EventSubgroup
         * @summary Create EventSubgroup
         * @param {CreateEventSubgroupRequest} createEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventSubgroup(createEventSubgroupRequest: CreateEventSubgroupRequest, eventGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventSubgroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventSubgroup(createEventSubgroupRequest, eventGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Dictionaries by code
         * @summary Delete Dictionaries by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDictionariesByCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDictionariesByCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Dictionaries by code and id
         * @summary Delete Dictionaries by code and id
         * @param {string} code 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDictionariesByCode1(code: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDictionariesByCode1(code, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Event
         * @summary Delete Event
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvent(eventGroupId: number, eventSubgroupId: number, eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvent(eventGroupId, eventSubgroupId, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete EventGroup
         * @summary Delete EventGroup
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEventGroup(eventGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventGroup(eventGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete EventSubgroup
         * @summary Delete EventSubgroup
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEventSubgroup(eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventSubgroup(eventGroupId, eventSubgroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Dictionaries by Code
         * @summary Get Dictionaries by Code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionariesByCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dictionaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionariesByCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Dictionaries paginated and filtered by code and value
         * @summary Get Dictionaries
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionariesList(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataRestDictionaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionariesList(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns EventGroup List by Tenant Id
         * @summary Get EventGroup List by Tenant Id
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventGroupListByTenantId(tenantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventGroupListByTenantId(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get EventGroups
         * @summary Get EventGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get EventSubgroup
         * @summary Get EventSubgroup
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventSubgroups(eventGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventSubgroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventSubgroups(eventGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Events
         * @summary Get Events
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Event>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvents(eventGroupId, eventSubgroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Sdm data for dashboard matching with specified filters
         * @summary Gets Sdm data for dashboard
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSdmDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SdmElementDashboardPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSdmDashboard(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch Event
         * @summary Patch Event
         * @param {PatchEventRequest} patchEventRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchEvent(patchEventRequest: PatchEventRequest, eventGroupId: number, eventSubgroupId: number, eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchEvent(patchEventRequest, eventGroupId, eventSubgroupId, eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch EventGroup
         * @summary Patch EventGroup
         * @param {PatchEventGroupRequest} patchEventGroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchEventGroup(patchEventGroupRequest: PatchEventGroupRequest, eventGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchEventGroup(patchEventGroupRequest, eventGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch EventSubgroup
         * @summary Patch EventSubgroup
         * @param {PatchEventSubgroupRequest} patchEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchEventSubgroup(patchEventSubgroupRequest: PatchEventSubgroupRequest, eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventSubgroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchEventSubgroup(patchEventSubgroupRequest, eventGroupId, eventSubgroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch Template
         * @summary Patch Template
         * @param {PatchTemplateRequest} patchTemplateRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTemplate(patchTemplateRequest: PatchTemplateRequest, eventGroupId: number, eventSubgroupId: number, templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTemplate(patchTemplateRequest, eventGroupId, eventSubgroupId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Put Dictionaries
         * @summary Put Dictionaries
         * @param {PutDictionariesRequest} putDictionariesRequest 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDictionaries(putDictionariesRequest: PutDictionariesRequest, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dictionaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDictionaries(putDictionariesRequest, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sync Dictionaries with way4
         * @summary Sync Dictionaries with way4
         * @param {SyncWithWay4Request} syncWithWay4Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncWithWay4(syncWithWay4Request: SyncWithWay4Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncWithWay4Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncWithWay4(syncWithWay4Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * Batch Patch EventGroup
         * @summary Batch Patch EventGroup
         * @param {BatchPatchEventGroupRequest} batchPatchEventGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchEventGroup(batchPatchEventGroupRequest: BatchPatchEventGroupRequest, options?: any): AxiosPromise<Array<EventGroup>> {
            return localVarFp.batchPatchEventGroup(batchPatchEventGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Batch Patch EventSubgroup
         * @summary Batch Patch EventSubgroup
         * @param {BatchPatchEventSubgroupRequest} batchPatchEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchPatchEventSubgroup(batchPatchEventSubgroupRequest: BatchPatchEventSubgroupRequest, eventGroupId: number, options?: any): AxiosPromise<Array<EventSubgroup>> {
            return localVarFp.batchPatchEventSubgroup(batchPatchEventSubgroupRequest, eventGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Dictionaries
         * @summary Create Dictionaries
         * @param {CreateDictionariesRequest} createDictionariesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDictionaries(createDictionariesRequest: CreateDictionariesRequest, options?: any): AxiosPromise<Dictionaries> {
            return localVarFp.createDictionaries(createDictionariesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Event
         * @summary Create Event
         * @param {CreateEventRequest} createEventRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(createEventRequest: CreateEventRequest, eventGroupId: number, eventSubgroupId: number, options?: any): AxiosPromise<Event> {
            return localVarFp.createEvent(createEventRequest, eventGroupId, eventSubgroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create EventGroup
         * @summary Create EventGroup
         * @param {CreateEventGroupRequest} createEventGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventGroup(createEventGroupRequest: CreateEventGroupRequest, options?: any): AxiosPromise<EventGroup> {
            return localVarFp.createEventGroup(createEventGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create EventSubgroup
         * @summary Create EventSubgroup
         * @param {CreateEventSubgroupRequest} createEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventSubgroup(createEventSubgroupRequest: CreateEventSubgroupRequest, eventGroupId: number, options?: any): AxiosPromise<EventSubgroup> {
            return localVarFp.createEventSubgroup(createEventSubgroupRequest, eventGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Dictionaries by code
         * @summary Delete Dictionaries by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDictionariesByCode(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDictionariesByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Dictionaries by code and id
         * @summary Delete Dictionaries by code and id
         * @param {string} code 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDictionariesByCode1(code: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDictionariesByCode1(code, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Event
         * @summary Delete Event
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(eventGroupId: number, eventSubgroupId: number, eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEvent(eventGroupId, eventSubgroupId, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete EventGroup
         * @summary Delete EventGroup
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventGroup(eventGroupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEventGroup(eventGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete EventSubgroup
         * @summary Delete EventSubgroup
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventSubgroup(eventGroupId: number, eventSubgroupId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEventSubgroup(eventGroupId, eventSubgroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Dictionaries by Code
         * @summary Get Dictionaries by Code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionariesByCode(code: string, options?: any): AxiosPromise<Dictionaries> {
            return localVarFp.getDictionariesByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Dictionaries paginated and filtered by code and value
         * @summary Get Dictionaries
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionariesList(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<PageDataRestDictionaries> {
            return localVarFp.getDictionariesList(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns EventGroup List by Tenant Id
         * @summary Get EventGroup List by Tenant Id
         * @param {number} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventGroupListByTenantId(tenantId: number, options?: any): AxiosPromise<Array<EventGroup>> {
            return localVarFp.getEventGroupListByTenantId(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get EventGroups
         * @summary Get EventGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventGroups(options?: any): AxiosPromise<Array<EventGroup>> {
            return localVarFp.getEventGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * Get EventSubgroup
         * @summary Get EventSubgroup
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventSubgroups(eventGroupId: number, options?: any): AxiosPromise<Array<EventSubgroup>> {
            return localVarFp.getEventSubgroups(eventGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Events
         * @summary Get Events
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(eventGroupId: number, eventSubgroupId: number, options?: any): AxiosPromise<Array<Event>> {
            return localVarFp.getEvents(eventGroupId, eventSubgroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Sdm data for dashboard matching with specified filters
         * @summary Gets Sdm data for dashboard
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSdmDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<SdmElementDashboardPageData> {
            return localVarFp.getSdmDashboard(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch Event
         * @summary Patch Event
         * @param {PatchEventRequest} patchEventRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEvent(patchEventRequest: PatchEventRequest, eventGroupId: number, eventSubgroupId: number, eventId: number, options?: any): AxiosPromise<Event> {
            return localVarFp.patchEvent(patchEventRequest, eventGroupId, eventSubgroupId, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch EventGroup
         * @summary Patch EventGroup
         * @param {PatchEventGroupRequest} patchEventGroupRequest 
         * @param {number} eventGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEventGroup(patchEventGroupRequest: PatchEventGroupRequest, eventGroupId: number, options?: any): AxiosPromise<EventGroup> {
            return localVarFp.patchEventGroup(patchEventGroupRequest, eventGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch EventSubgroup
         * @summary Patch EventSubgroup
         * @param {PatchEventSubgroupRequest} patchEventSubgroupRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEventSubgroup(patchEventSubgroupRequest: PatchEventSubgroupRequest, eventGroupId: number, eventSubgroupId: number, options?: any): AxiosPromise<EventSubgroup> {
            return localVarFp.patchEventSubgroup(patchEventSubgroupRequest, eventGroupId, eventSubgroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch Template
         * @summary Patch Template
         * @param {PatchTemplateRequest} patchTemplateRequest 
         * @param {number} eventGroupId 
         * @param {number} eventSubgroupId 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTemplate(patchTemplateRequest: PatchTemplateRequest, eventGroupId: number, eventSubgroupId: number, templateId: number, options?: any): AxiosPromise<Template> {
            return localVarFp.patchTemplate(patchTemplateRequest, eventGroupId, eventSubgroupId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Put Dictionaries
         * @summary Put Dictionaries
         * @param {PutDictionariesRequest} putDictionariesRequest 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDictionaries(putDictionariesRequest: PutDictionariesRequest, code: string, options?: any): AxiosPromise<Dictionaries> {
            return localVarFp.putDictionaries(putDictionariesRequest, code, options).then((request) => request(axios, basePath));
        },
        /**
         * Sync Dictionaries with way4
         * @summary Sync Dictionaries with way4
         * @param {SyncWithWay4Request} syncWithWay4Request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithWay4(syncWithWay4Request: SyncWithWay4Request, options?: any): AxiosPromise<SyncWithWay4Response> {
            return localVarFp.syncWithWay4(syncWithWay4Request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - interface
 * @export
 * @interface ConfigurationApi
 */
export interface ConfigurationApiInterface {
    /**
     * Batch Patch EventGroup
     * @summary Batch Patch EventGroup
     * @param {BatchPatchEventGroupRequest} batchPatchEventGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    batchPatchEventGroup(batchPatchEventGroupRequest: BatchPatchEventGroupRequest, options?: AxiosRequestConfig): AxiosPromise<Array<EventGroup>>;

    /**
     * Batch Patch EventSubgroup
     * @summary Batch Patch EventSubgroup
     * @param {BatchPatchEventSubgroupRequest} batchPatchEventSubgroupRequest 
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    batchPatchEventSubgroup(batchPatchEventSubgroupRequest: BatchPatchEventSubgroupRequest, eventGroupId: number, options?: AxiosRequestConfig): AxiosPromise<Array<EventSubgroup>>;

    /**
     * Create Dictionaries
     * @summary Create Dictionaries
     * @param {CreateDictionariesRequest} createDictionariesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    createDictionaries(createDictionariesRequest: CreateDictionariesRequest, options?: AxiosRequestConfig): AxiosPromise<Dictionaries>;

    /**
     * Create Event
     * @summary Create Event
     * @param {CreateEventRequest} createEventRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    createEvent(createEventRequest: CreateEventRequest, eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): AxiosPromise<Event>;

    /**
     * Create EventGroup
     * @summary Create EventGroup
     * @param {CreateEventGroupRequest} createEventGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    createEventGroup(createEventGroupRequest: CreateEventGroupRequest, options?: AxiosRequestConfig): AxiosPromise<EventGroup>;

    /**
     * Create EventSubgroup
     * @summary Create EventSubgroup
     * @param {CreateEventSubgroupRequest} createEventSubgroupRequest 
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    createEventSubgroup(createEventSubgroupRequest: CreateEventSubgroupRequest, eventGroupId: number, options?: AxiosRequestConfig): AxiosPromise<EventSubgroup>;

    /**
     * Delete Dictionaries by code
     * @summary Delete Dictionaries by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    deleteDictionariesByCode(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete Dictionaries by code and id
     * @summary Delete Dictionaries by code and id
     * @param {string} code 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    deleteDictionariesByCode1(code: string, id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete Event
     * @summary Delete Event
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    deleteEvent(eventGroupId: number, eventSubgroupId: number, eventId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete EventGroup
     * @summary Delete EventGroup
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    deleteEventGroup(eventGroupId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete EventSubgroup
     * @summary Delete EventSubgroup
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    deleteEventSubgroup(eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get Dictionaries by Code
     * @summary Get Dictionaries by Code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    getDictionariesByCode(code: string, options?: AxiosRequestConfig): AxiosPromise<Dictionaries>;

    /**
     * Get Dictionaries paginated and filtered by code and value
     * @summary Get Dictionaries
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    getDictionariesList(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<PageDataRestDictionaries>;

    /**
     * Returns EventGroup List by Tenant Id
     * @summary Get EventGroup List by Tenant Id
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    getEventGroupListByTenantId(tenantId: number, options?: AxiosRequestConfig): AxiosPromise<Array<EventGroup>>;

    /**
     * Get EventGroups
     * @summary Get EventGroups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    getEventGroups(options?: AxiosRequestConfig): AxiosPromise<Array<EventGroup>>;

    /**
     * Get EventSubgroup
     * @summary Get EventSubgroup
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    getEventSubgroups(eventGroupId: number, options?: AxiosRequestConfig): AxiosPromise<Array<EventSubgroup>>;

    /**
     * Get Events
     * @summary Get Events
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    getEvents(eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): AxiosPromise<Array<Event>>;

    /**
     * Returns Sdm data for dashboard matching with specified filters
     * @summary Gets Sdm data for dashboard
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    getSdmDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<SdmElementDashboardPageData>;

    /**
     * Patch Event
     * @summary Patch Event
     * @param {PatchEventRequest} patchEventRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    patchEvent(patchEventRequest: PatchEventRequest, eventGroupId: number, eventSubgroupId: number, eventId: number, options?: AxiosRequestConfig): AxiosPromise<Event>;

    /**
     * Patch EventGroup
     * @summary Patch EventGroup
     * @param {PatchEventGroupRequest} patchEventGroupRequest 
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    patchEventGroup(patchEventGroupRequest: PatchEventGroupRequest, eventGroupId: number, options?: AxiosRequestConfig): AxiosPromise<EventGroup>;

    /**
     * Patch EventSubgroup
     * @summary Patch EventSubgroup
     * @param {PatchEventSubgroupRequest} patchEventSubgroupRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    patchEventSubgroup(patchEventSubgroupRequest: PatchEventSubgroupRequest, eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig): AxiosPromise<EventSubgroup>;

    /**
     * Patch Template
     * @summary Patch Template
     * @param {PatchTemplateRequest} patchTemplateRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    patchTemplate(patchTemplateRequest: PatchTemplateRequest, eventGroupId: number, eventSubgroupId: number, templateId: number, options?: AxiosRequestConfig): AxiosPromise<Template>;

    /**
     * Put Dictionaries
     * @summary Put Dictionaries
     * @param {PutDictionariesRequest} putDictionariesRequest 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    putDictionaries(putDictionariesRequest: PutDictionariesRequest, code: string, options?: AxiosRequestConfig): AxiosPromise<Dictionaries>;

    /**
     * Sync Dictionaries with way4
     * @summary Sync Dictionaries with way4
     * @param {SyncWithWay4Request} syncWithWay4Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApiInterface
     */
    syncWithWay4(syncWithWay4Request: SyncWithWay4Request, options?: AxiosRequestConfig): AxiosPromise<SyncWithWay4Response>;

}

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI implements ConfigurationApiInterface {
    /**
     * Batch Patch EventGroup
     * @summary Batch Patch EventGroup
     * @param {BatchPatchEventGroupRequest} batchPatchEventGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public batchPatchEventGroup(batchPatchEventGroupRequest: BatchPatchEventGroupRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).batchPatchEventGroup(batchPatchEventGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Batch Patch EventSubgroup
     * @summary Batch Patch EventSubgroup
     * @param {BatchPatchEventSubgroupRequest} batchPatchEventSubgroupRequest 
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public batchPatchEventSubgroup(batchPatchEventSubgroupRequest: BatchPatchEventSubgroupRequest, eventGroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).batchPatchEventSubgroup(batchPatchEventSubgroupRequest, eventGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Dictionaries
     * @summary Create Dictionaries
     * @param {CreateDictionariesRequest} createDictionariesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public createDictionaries(createDictionariesRequest: CreateDictionariesRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).createDictionaries(createDictionariesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Event
     * @summary Create Event
     * @param {CreateEventRequest} createEventRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public createEvent(createEventRequest: CreateEventRequest, eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).createEvent(createEventRequest, eventGroupId, eventSubgroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create EventGroup
     * @summary Create EventGroup
     * @param {CreateEventGroupRequest} createEventGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public createEventGroup(createEventGroupRequest: CreateEventGroupRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).createEventGroup(createEventGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create EventSubgroup
     * @summary Create EventSubgroup
     * @param {CreateEventSubgroupRequest} createEventSubgroupRequest 
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public createEventSubgroup(createEventSubgroupRequest: CreateEventSubgroupRequest, eventGroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).createEventSubgroup(createEventSubgroupRequest, eventGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Dictionaries by code
     * @summary Delete Dictionaries by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public deleteDictionariesByCode(code: string, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).deleteDictionariesByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Dictionaries by code and id
     * @summary Delete Dictionaries by code and id
     * @param {string} code 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public deleteDictionariesByCode1(code: string, id: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).deleteDictionariesByCode1(code, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Event
     * @summary Delete Event
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public deleteEvent(eventGroupId: number, eventSubgroupId: number, eventId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).deleteEvent(eventGroupId, eventSubgroupId, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete EventGroup
     * @summary Delete EventGroup
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public deleteEventGroup(eventGroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).deleteEventGroup(eventGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete EventSubgroup
     * @summary Delete EventSubgroup
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public deleteEventSubgroup(eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).deleteEventSubgroup(eventGroupId, eventSubgroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Dictionaries by Code
     * @summary Get Dictionaries by Code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getDictionariesByCode(code: string, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getDictionariesByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Dictionaries paginated and filtered by code and value
     * @summary Get Dictionaries
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getDictionariesList(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getDictionariesList(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns EventGroup List by Tenant Id
     * @summary Get EventGroup List by Tenant Id
     * @param {number} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getEventGroupListByTenantId(tenantId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getEventGroupListByTenantId(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get EventGroups
     * @summary Get EventGroups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getEventGroups(options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getEventGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get EventSubgroup
     * @summary Get EventSubgroup
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getEventSubgroups(eventGroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getEventSubgroups(eventGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Events
     * @summary Get Events
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getEvents(eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getEvents(eventGroupId, eventSubgroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Sdm data for dashboard matching with specified filters
     * @summary Gets Sdm data for dashboard
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getSdmDashboard(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getSdmDashboard(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch Event
     * @summary Patch Event
     * @param {PatchEventRequest} patchEventRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public patchEvent(patchEventRequest: PatchEventRequest, eventGroupId: number, eventSubgroupId: number, eventId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).patchEvent(patchEventRequest, eventGroupId, eventSubgroupId, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch EventGroup
     * @summary Patch EventGroup
     * @param {PatchEventGroupRequest} patchEventGroupRequest 
     * @param {number} eventGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public patchEventGroup(patchEventGroupRequest: PatchEventGroupRequest, eventGroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).patchEventGroup(patchEventGroupRequest, eventGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch EventSubgroup
     * @summary Patch EventSubgroup
     * @param {PatchEventSubgroupRequest} patchEventSubgroupRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public patchEventSubgroup(patchEventSubgroupRequest: PatchEventSubgroupRequest, eventGroupId: number, eventSubgroupId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).patchEventSubgroup(patchEventSubgroupRequest, eventGroupId, eventSubgroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch Template
     * @summary Patch Template
     * @param {PatchTemplateRequest} patchTemplateRequest 
     * @param {number} eventGroupId 
     * @param {number} eventSubgroupId 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public patchTemplate(patchTemplateRequest: PatchTemplateRequest, eventGroupId: number, eventSubgroupId: number, templateId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).patchTemplate(patchTemplateRequest, eventGroupId, eventSubgroupId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Put Dictionaries
     * @summary Put Dictionaries
     * @param {PutDictionariesRequest} putDictionariesRequest 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public putDictionaries(putDictionariesRequest: PutDictionariesRequest, code: string, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).putDictionaries(putDictionariesRequest, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sync Dictionaries with way4
     * @summary Sync Dictionaries with way4
     * @param {SyncWithWay4Request} syncWithWay4Request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public syncWithWay4(syncWithWay4Request: SyncWithWay4Request, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).syncWithWay4(syncWithWay4Request, options).then((request) => request(this.axios, this.basePath));
    }
}
