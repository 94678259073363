import { MouseEventHandler } from "react";
import { Button, Modal } from "antd";

import styles from "./styles.module.scss";

interface WarningModalProps {
  isOpened: boolean;
  onCancel: () => void;
  onConfirm: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>;
  title: string;
}

export const WarningModal = ({ isOpened, onCancel, onConfirm, title }: WarningModalProps) => {
  return (
    <Modal
      className={styles["modal-warning"]}
      title={title}
      open={isOpened}
      width="800px"
      onCancel={onCancel}
      footer={[
        <div className={styles["modal-warning-footer"]}>
          <Button className={styles["modal-warning-button"]} key="back" onClick={onConfirm}>
            Ok
          </Button>
        </div>,
      ]}
    />
  );
};
