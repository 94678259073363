import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Button, Form, notification, Tabs, Typography } from "antd";
import { useParams } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";
import { CP_FEES_ENABLED, SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useProductSettings, useTabs } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGeneral, UnsavedChangesModal } from "@ni/common/ui";
import {
  getErrorInstance,
  getFormValueFromProductValues,
  parseBooleanOrNumber,
  transformArrayToObject,
  validateJsonPath,
} from "@ni/common/utils";

import { CARD_PRODUCTION_FEES_PREFIX, RestrictionRule } from "../../../../components";
import { usePct } from "../../../../hooks";
import { RestrictionRulesKeys, TabKeysCPF } from "../../../../types";

import { CARD_PRODUCTION_FEES_TABS } from "./constants";
import { useCardProductionFees } from "./useCardProductionFees";

import styles from "../../styles.module.scss";

const getMaxFeesCount = (obj: RestrictionRulesKeys): number => {
  const keyCount = Object.keys(obj).length;

  if (keyCount === 1) {
    return 1;
  }
  if (keyCount === 2) {
    return 3;
  }
  if (keyCount >= 3) {
    return 8;
  }
  return 0;
};

export const CardProductionFeesPage = () => {
  const [initialForm] = Form.useForm<FormValues>();
  const [hasError, setHasError] = useState(false);

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const initialValues = useRef<FormValues>({});

  const [tabs, activeTab, onChangeActiveTab, changesChecker] = useTabs<TabKeysCPF>(
    CARD_PRODUCTION_FEES_TABS.filter(tab => validateJsonPath(currentProduct, tab.dependency)),
    {
      isCheckEnabled: true,
      form: initialForm,
      excludedFieldsListFromCheck: [CP_FEES_ENABLED],
      initialValues: initialValues.current,
    },
  );

  const {
    nextPage = {
      name: "",
      key: "",
    },
    isOpen,
    onDiscardChanges = (key: TabKeysCPF) => {
      return key;
    },
    onCloseModal,
  } = changesChecker || {};

  const {
    form,
    feesConfigurationKeys,
    restrictionRules,
    onAddNewRestictionRule,
    onUpdateRuleFeeKey,
    onUpdateRuleFeeAmount,
    onRemoveRestrictionRule,
    onChangeRulesSwitch,
    handlePresetProductionFees,
    prepareProductionFees,
  } = useCardProductionFees({
    activeKey: activeTab.key as Exclude<TabKeysCPF, "">,
    currentProduct,
    pctProductValues: pct.pctProductValues ?? [],
    initialForm,
  });

  const [addOptEnabledCode, setAddOptEnabledCode] = useState(`${activeTab.key}-add-opt-enabled`);
  const [additionOptionName, setAdditionOptionName] = useState(`${CARD_PRODUCTION_FEES_PREFIX}-${activeTab.key}`);

  const isCPFeesEnabled = Form.useWatch<boolean>(CP_FEES_ENABLED, form);
  const isAddOptEnabled = Form.useWatch<boolean>(addOptEnabledCode, form);

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  useLayoutEffect(() => {
    const isCPFeesEnabled = parseBooleanOrNumber(
      getFormValueFromProductValues(pct.pctProductValues, CP_FEES_ENABLED) as string,
    );

    if (isCPFeesEnabled) {
      form.setFieldValue(CP_FEES_ENABLED, isCPFeesEnabled);

      handlePresetProductionFees();
    }

    initialValues.current = form.getFieldsValue(true) as FormValues;
  }, [form, handlePresetProductionFees, pct.pctProductValues]);

  const onChangeTab = useCallback(
    (key: TabKeysCPF) => {
      const isChanged = onChangeActiveTab(key);

      if (isChanged) {
        setAddOptEnabledCode(`${key}-add-opt-enabled`);
        setAdditionOptionName(`${CARD_PRODUCTION_FEES_PREFIX}-${key}`);
      }
    },
    [onChangeActiveTab],
  );

  const onFinish = async (values: FormValues, isControlledFromModal = false) => {
    try {
      let data;

      if (isCPFeesEnabled) {
        data = prepareProductionFees(values);
      } else {
        const currentSectionValues = pct.pctProductValues?.filter(
          item => item.fieldCode.includes(CARD_PRODUCTION_FEES_PREFIX) || item.fieldCode.includes("add-opt-enabled"),
        );

        data = {
          [CP_FEES_ENABLED]: false,
          ...transformArrayToObject(
            currentSectionValues?.map(section => ({
              [section.fieldCode]: null,
            })) as Record<string, null>[],
          ),
        };
      }

      await onSavePct(data as FormValues);

      if (isControlledFromModal) {
        setAddOptEnabledCode(`${nextPage.key}-add-opt-enabled`);
        setAdditionOptionName(`${CARD_PRODUCTION_FEES_PREFIX}-${nextPage.key}`);
        onDiscardChanges(nextPage.key, false);
      }

      if (!isControlledFromModal) {
        form.setFields(
          Object.entries(form.getFieldsValue()).map(field => ({
            name: field[0],
            value: field[1],
            touched: false,
          })),
        );
      }
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance.response?.status} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  const renderContent = useMemo(() => {
    const rules = restrictionRules.filter(item => item.tabKey === activeTab.key);
    const isRulesAvailableToAdd = rules.length < getMaxFeesCount(feesConfigurationKeys);

    return (
      <>
        <div className="ni-tabs">
          <Tabs
            defaultActiveKey={tabs[0].key}
            activeKey={activeTab.key}
            items={tabs}
            onChange={key => onChangeTab(key as TabKeysCPF)}
          />
        </div>

        <NetworkForm.Switch
          onChange={checked => onChangeRulesSwitch(checked)}
          formItemOptions={{
            label: "Differentiate the fee by additional production options",
            tooltip:
              "It is possible to set different fee amount for card production depending on additional options included. Switch it on if you want to have this variety. If not, leave it switching off. In this case the defined amount is applied for the event in spite of included additional options.",
            name: addOptEnabledCode,
            valuePropName: "checked",
            initialValue: false,
          }}
        />

        <NetworkForm.Number
          min={0}
          precision={2}
          addonAfter={productCurrency}
          className={styles["pct-select-item"]}
          formItemOptions={{
            name: `${additionOptionName}${activeTab.key === "plre" ? "-pink" : ""}`,
            label: "Default fee amount",
            tooltip:
              "If you do not cover some additional options combination, we will apply this default fee value to those combinations.",
            rules: [
              {
                validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                message: "Minimum fee amount cannot be 0",
              },
              {
                required: true,
                message: "Default Fee amount is required",
              },
            ],
          }}
        />

        {isAddOptEnabled &&
          rules.map(item => {
            return (
              <RestrictionRule
                key={`${additionOptionName}-${item.id}`}
                rule={item}
                rules={restrictionRules}
                label={activeTab.label}
                currentTabKey={activeTab.key}
                productCurrency={productCurrency}
                onRemoveRule={onRemoveRestrictionRule}
                onUpdateRuleFeeKey={onUpdateRuleFeeKey}
                onUpdateFeeAmount={onUpdateRuleFeeAmount}
              />
            );
          })}

        {isAddOptEnabled && isRulesAvailableToAdd && (
          <Button type="link" icon={<PlusOutlined />} onClick={onAddNewRestictionRule}>
            Add fee for additional production options
          </Button>
        )}
      </>
    );
  }, [
    activeTab.key,
    activeTab.label,
    addOptEnabledCode,
    additionOptionName,
    feesConfigurationKeys,
    isAddOptEnabled,
    onAddNewRestictionRule,
    onChangeRulesSwitch,
    onChangeTab,
    onRemoveRestrictionRule,
    onUpdateRuleFeeAmount,
    onUpdateRuleFeeKey,
    productCurrency,
    restrictionRules,
    tabs,
  ]);

  const handleOpenModal = async (isOpen: boolean) => {
    if (isOpen && form) {
      try {
        await form.validateFields({ validateOnly: true });
        setHasError(false);
      } catch (error) {
        setHasError(true);
      }
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Card Production Fees"
      pageSubtitle="When production event is triggered i.e. whenever card is produced, renewed, replaced, reissued a fixed amount of fee can be charged to cardholders."
      submitHandler={onFinish}
      size="full"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <div className={styles["pct-editing-wrapper"]}>
        <PageItemLayoutGeneral>
          <NetworkForm.Switch
            onChange={checked => onChangeRulesSwitch(checked)}
            formItemOptions={{
              label: <Typography.Text strong={true}>Enable card production fees on your product</Typography.Text>,
              name: CP_FEES_ENABLED,
              valuePropName: "checked",
              initialValue: false,
            }}
          />

          {isCPFeesEnabled && renderContent}
        </PageItemLayoutGeneral>
      </div>

      <UnsavedChangesModal
        isOpen={!!isOpen}
        afterOpenChange={handleOpenModal}
        footer={[
          <Button
            key="discard"
            onClick={() => {
              onDiscardChanges(nextPage.key, true);
              setAddOptEnabledCode(`${nextPage.key}-add-opt-enabled`);
              setAdditionOptionName(`${CARD_PRODUCTION_FEES_PREFIX}-${nextPage.key}`);
            }}
          >
            Discard
          </Button>,
          <Button key="stay" onClick={onCloseModal}>
            Stay
          </Button>,
          <Button
            key="Save"
            danger={true}
            type="primary"
            onClick={() => onFinish(form.getFieldsValue(), true)}
            disabled={hasError}
          >
            Save
          </Button>,
        ]}
        disabled={true}
      />
    </CustomFormWrapper>
  );
};
