import { FC } from "react";
import { Form, Space, Typography } from "antd";

import {
  murabahaScenariosColumns,
  murabahaScenariosData,
  tawarruqScenariosColumns,
  tawarruqScenariosData,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CollapsedTable, CustomFormWrapper, FormItemLabel, RadioGroup } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { creditProfitStructure, murabahaProfitRate } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

const stringsKeys = [creditProfitStructure.code, murabahaProfitRate.code];

export const IslamicCreditProfitPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const islamicCreditType = Form.useWatch<string>(creditProfitStructure.code, form);

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: stringsKeys,
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Islamic Credit Profit"
      pageSubtitle={
        <Space direction="vertical" size={8}>
          <Typography.Text>
            Islamic credit card can be based on Murabaha or Tawarruq. Murabaha computation is done through two
            transactions, Monthly Murabaha Finance amount payment due; which gets debited to account holder on statement
            date. And the Special Deposit Profit and Bonus; which represents the monthly earned profit payment credited
            to accountholder.
          </Typography.Text>
          <Typography.Text>
            Tawarruq profit is calculated on the full payment date based on the remaining Tawarruq balance - includes
            all billed amounts, fees, charges & profits- and is posted to accountholder on full payment date, since
            profit is calculated on the full payment date, it is charged on that date and cannot be held until billing
            date.
          </Typography.Text>
        </Space>
      }
      size="md"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={creditProfitStructure.code}
        label={<FormItemLabel label={creditProfitStructure.label} code={creditProfitStructure.code} />}
        initialValue={creditProfitStructure.default}
      >
        <RadioGroup radioList={creditProfitStructure.options} gap={8} disabled={formDisabled} />
      </Form.Item>

      <CollapsedTable
        title="What if scenarios"
        tabsList={islamicCreditType === "Murabaha" ? murabahaScenariosData : tawarruqScenariosData}
        columns={islamicCreditType === "Murabaha" ? murabahaScenariosColumns : tawarruqScenariosColumns}
      />
    </CustomFormWrapper>
  );
};
