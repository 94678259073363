import { Table, TableColumnType, TablePaginationConfig } from "antd";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { Dictionaries, Dictionary } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface DictionariesTableProps {
  dataSource: Dictionaries[];
  pagination: TablePaginationConfig;
  onChange: (pagination: TablePaginationConfig) => void;
  openDrawer: (enumToEdit?: Dictionaries) => void;
  openDeleteModal: (elemToDelete: string) => void;
}

export const DictionariesTable = ({
  dataSource,
  pagination,
  onChange,
  openDrawer,
  openDeleteModal,
}: DictionariesTableProps) => {
  const [isLoading] = useReduxState<boolean>("isLoading");

  const columns: TableColumnType<Dictionaries>[] = [
    {
      dataIndex: ["code"],
      key: "code",
      render: (_: string, item: Dictionaries) => <div className={styles["code-name"]}>{item.code}</div>,
    },
    {
      title: "",
      dataIndex: ["comment"],
      key: "comment",
      width: 24,
      render: (_: string, item: Dictionaries) => {
        return (
          <EditOutlined
            className={styles["icons"]}
            onClick={e => {
              e.stopPropagation();
              openDrawer(item);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: ["comment"],
      key: "comment",
      width: 24,
      render: (_: string, item: Dictionaries) => {
        return (
          <DeleteOutlined
            className={styles["icons"]}
            onClick={e => {
              e.stopPropagation();
              openDeleteModal(item.code);
            }}
          />
        );
      },
    },
  ];

  const dictionaryColumns: TableColumnType<Dictionary>[] = [
    {
      title: "Value",
      dataIndex: ["value"],
      key: "value",
      render: (_: string, item: Dictionary) => <div>{item.value}</div>,
    },
    {
      title: "Display value",
      dataIndex: ["displayValue"],
      key: "displayValue",
      render: (_: string, item: Dictionary) => <div>{item.displayValue}</div>,
    },
  ];

  const DictionariesList = (elements: Dictionary[]) => {
    return (
      <div>
        <Table columns={dictionaryColumns} dataSource={elements} pagination={false} />
      </div>
    );
  };

  return (
    <Table<Dictionaries>
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      loading={isLoading}
      rowKey={(item: Dictionaries) => {
        return item?.code;
      }}
      onChange={onChange}
      expandable={{
        expandedRowRender: record => DictionariesList(record.dictionaries),
      }}
    />
  );
};
