import { FC, useCallback, useEffect } from "react";
import { Collapse } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { Spin } from "@ni/common/ui";
import { VariablesApi } from "@ni/sdk/apis";
import { VariableGroup } from "@ni/sdk/models";

import { VariableGroupItem } from "../VariablesGroupItem";

import styles from "./styles.module.scss";

const variablesApi = new VariablesApi();

export const VariablesGroupList: FC = () => {
  const [variableGroups, setVariableGroups] = useReduxState<VariableGroup[]>("variableGroups", []);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const getVariableGroupsList = useCallback((): void => {
    setVariableGroups([]);
    setIsLoading(true);

    variablesApi
      .getVariableGroups()
      .then(response => {
        setVariableGroups(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getVariableGroupsList();
  }, []);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Collapse ghost={true} bordered={false} className={styles["collapsible-list"]}>
      {[...variableGroups]
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map(prop => (
          <VariableGroupItem
            key={`${prop.id}-templateItem`}
            eventGroups={prop.eventGroups}
            name={prop.name}
            groupId={prop.id}
            getSMSVariablesDictionary={getVariableGroupsList}
            header={prop.name}
          />
        ))}
    </Collapse>
  );
};
