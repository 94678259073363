import { Space } from "antd";

import { commercial, consumer } from "@ni/common/assets";

export const CLIENT_TYPE_SERVICES_CARDS = [
  {
    name: "Consumers",
    title: "Consumer clients",
    imageSrc: consumer,
    disabled: false,
  },
  {
    name: "Commercials",
    title: (
      <Space direction="horizontal" size={16}>
        Commercial clients
        <div className="alert-div center">
          <span>Coming</span>
          <span>soon</span>
        </div>
      </Space>
    ),
    imageSrc: commercial,
    disabled: true,
  },
];
