import { FC, ReactNode } from "react";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface ListPanelProps {
  title?: string;
  titleTooltip?: string;
  children?: ReactNode;
}

export const ListPanel: FC<ListPanelProps> = ({ children, title = "", titleTooltip = "" }) => {
  return (
    <div className={styles["envelop"]}>
      {title && (
        <h3 className={styles["text-blue-h3"]}>
          <TooltipInfo label={title} tooltipProps={{ className: "warning-sign", title: titleTooltip }} />
        </h3>
      )}
      <div className={styles["envelop-switches"]}>{children}</div>
    </div>
  );
};
