import { FC, useState } from "react";
import { Button, Form } from "antd";
import Search from "antd/lib/transfer/search";

import { PageHeading } from "@ni/common/ui";

import { VariablesGroupList } from "./components/VariablesGroupList";
import { VariablesGroupModal } from "./components/VariablesGroupModal";

import styles from "./styles.module.scss";

export const SmsVariablesDictionary: FC = () => {
  const [isNewGroupOpened, setIsNewGroupOpened] = useState<boolean>(false);

  const extra = (
    <div className={styles["extra-container"]} key={Math.random()}>
      <div className={styles["top-section"]}>
        <Button
          className={styles["new-workflow-button"]}
          type="primary"
          htmlType="button"
          onClick={() => {
            setIsNewGroupOpened(true);
          }}
        >
          New variable group
        </Button>
        <Form.Item name="searchValue">
          <Search disabled={true} placeholder="Search" />
        </Form.Item>
      </div>
    </div>
  );

  return (
    <>
      <div className={styles["sms-variables-dictionary"]}>
        <PageHeading text="Variables for SMS Templates" extraElements={[extra]} />
        <div className="sms-templates-container">
          <VariablesGroupList />
        </div>
      </div>
      <VariablesGroupModal opened={isNewGroupOpened} closeDrawer={() => setIsNewGroupOpened(false)} />
    </>
  );
};
