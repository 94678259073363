import { useCallback, useMemo } from "react";
import { Form, notification, Space } from "antd";
import { useParams } from "react-router-dom";

import {
  ALPHA2_COUNTRIES_LIST,
  LTY_CHECK_RULES,
  LTY_PREF_MAX,
  LTY_PREF_MIN,
  LTY_RULE_CNTR,
  LTY_RULE_CNTR_LIST,
  LTY_RULE_MCC,
  LTY_RULE_MCC_LIST,
  LTY_RULE_MERCH,
  LTY_RULE_MERCH_LIST,
  LTY_RULE_TERM_LIST,
  LTY_RULES_CRITERIA,
  MCC_LIST,
  RADIO_GROUP,
  RADIO_GROUP_ELIGIBLE,
  VALIDATION_MIN_MAX_MESSAGE,
} from "@ni/common/constants";
import { useHydrateForm, useLoyaltyProgramApi, useParamsList, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, Tabs } from "@ni/common/ui";
import { removeGapsFromStringNumeric, validateNumberInput } from "@ni/common/utils";

import { TAB_TITLES } from "./constants";

type KeyTab = "AMN" | "CNTR" | "TERM" | "MERCH" | "MCC";

const stringsKeys = [LTY_PREF_MIN, LTY_PREF_MAX, LTY_CHECK_RULES, LTY_RULE_MERCH, LTY_RULE_MCC, LTY_RULE_CNTR];
const listsKeys = [LTY_RULES_CRITERIA, LTY_RULE_TERM_LIST, LTY_RULE_MERCH_LIST, LTY_RULE_MCC_LIST, LTY_RULE_CNTR_LIST];

const MIN_MAX_VALIDATION_MESSAGE = "Please fill min or max amount";

export const LoyaltyTransactionEligibility = () => {
  const [form] = Form.useForm<FormValues>();
  const {
    params: { tab },
  } = useParamsList(["tab"]);

  const { productId, pctId, programId } = useParams<{ productId: string; pctId: string; programId: string }>();

  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { loyaltyProgram, editLoyaltyProgram } = useLoyaltyProgramApi({
    pctId: parseInt(pctId ?? "0", 10),
    loyaltyProgramId: parseInt(programId ?? "0", 10),
  });

  const initialValues = useHydrateForm({
    form,
    entityFields: loyaltyProgram?.programPctValues ?? [],
    keys: {
      strings: stringsKeys,
      lists: listsKeys,
    },
  });

  const isSpecificRetail = Form.useWatch<string>(LTY_CHECK_RULES, form) === "Y";
  const selectedCriteria = Form.useWatch<string[]>(LTY_RULES_CRITERIA, form);

  const satisfiesMinimumCriterias = useCallback(() => {
    if (isSpecificRetail && (!selectedCriteria || selectedCriteria.length === 0)) {
      notification.error({
        message: "Invalid",
        description: "Please select at least one criteria",
      });
      return false;
    }
    return true;
  }, [isSpecificRetail, selectedCriteria]);

  const pagesList = useMemo(() => {
    return [
      {
        key: "AMN" as KeyTab,
        label: <div title="Transaction Amount">Transaction Amount</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["AMN"]}</p>
            <NetworkForm.Switch
              formItemOptions={{
                name: LTY_RULES_CRITERIA,
                label: "Enable Transaction amount criteria",
                className: "horizontal-space",
              }}
              checked={selectedCriteria?.includes("AMN")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "AMN"] : selectedCriteria.filter(x => x !== "AMN"),
                );

                if (!isChecked) {
                  form.setFieldsValue({ [LTY_PREF_MIN]: "", [LTY_PREF_MAX]: "" });
                }
              }}
            />

            {selectedCriteria?.includes("AMN") && (
              <>
                <NetworkForm.Number
                  formItemOptions={{
                    name: LTY_PREF_MIN,
                    label: "Minimum amount",
                    className: "w-p-50",
                    rules: [
                      () => ({
                        validator(_, value: string) {
                          const maxValue = form.getFieldValue(LTY_PREF_MAX) as string;
                          if (maxValue) {
                            if (+removeGapsFromStringNumeric(maxValue) < +removeGapsFromStringNumeric(value)) {
                              return Promise.reject(new Error(VALIDATION_MIN_MAX_MESSAGE));
                            }
                            return Promise.resolve();
                          }
                          return validateNumberInput(parseInt(value, 10), true, MIN_MAX_VALIDATION_MESSAGE);
                        },
                      }),
                    ],
                  }}
                  min={0}
                  precision={2}
                  addonAfter={productCurrency}
                />

                <NetworkForm.Number
                  formItemOptions={{
                    name: LTY_PREF_MAX,
                    label: "Maximum amount",
                    className: "w-p-50",
                    rules: [
                      () => ({
                        validator(_, value: string) {
                          const minValue = form.getFieldValue(LTY_PREF_MIN) as string;
                          if (minValue) {
                            if (+removeGapsFromStringNumeric(minValue) > +removeGapsFromStringNumeric(value)) {
                              return Promise.reject(new Error(VALIDATION_MIN_MAX_MESSAGE));
                            }
                            return Promise.resolve();
                          }
                          return validateNumberInput(parseInt(value, 10), true, MIN_MAX_VALIDATION_MESSAGE);
                        },
                      }),
                    ],
                  }}
                  min={0}
                  precision={2}
                  addonAfter={productCurrency}
                />
              </>
            )}
          </Space>
        ),
        fields: [LTY_PREF_MIN, LTY_PREF_MAX],
      },
      {
        key: "CNTR" as KeyTab,
        label: <div title="Country">Country</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["CNTR"]}</p>
            <NetworkForm.Switch
              formItemOptions={{
                name: LTY_RULES_CRITERIA,
                label: "Enable country criteria",
              }}
              checked={selectedCriteria?.includes("CNTR")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "CNTR"] : selectedCriteria.filter(x => x !== "CNTR"),
                );

                if (!isChecked) {
                  form.setFieldsValue({ [LTY_RULE_CNTR]: "", [LTY_RULE_CNTR_LIST]: "" });
                }
              }}
            />

            {selectedCriteria?.includes("CNTR") && (
              <>
                <NetworkForm.Radio
                  formItemOptions={{
                    name: LTY_RULE_CNTR,
                    label: "Select countries",
                    initialValue: RADIO_GROUP[0].value,
                  }}
                  radioList={RADIO_GROUP}
                />
                <NetworkForm.Select
                  formItemOptions={{
                    name: LTY_RULE_CNTR_LIST,
                    label: "Country list",
                    rules: [
                      {
                        required: true,
                        validator: (_, value: string[]) => {
                          if (value && value.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Please fill out country list."));
                        },
                      },
                    ],
                    className: "w-p-50",
                  }}
                  mode="multiple"
                  optionFilterProp="children"
                  optionList={ALPHA2_COUNTRIES_LIST.map(x => ({ label: x.name, value: x.code }))}
                />
              </>
            )}
          </Space>
        ),
        fields: [LTY_RULE_CNTR, LTY_RULE_CNTR_LIST],
      },
      {
        key: "MCC" as KeyTab,
        label: <div title="Merchant Category">Merchant Category</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["MCC"]}</p>
            <NetworkForm.Switch
              formItemOptions={{
                name: LTY_RULES_CRITERIA,
                label: "Enable merchant category criteria",
              }}
              checked={selectedCriteria?.includes("MCC")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "MCC"] : selectedCriteria.filter(x => x !== "MCC"),
                );

                if (!isChecked) {
                  form.setFieldsValue({ [LTY_RULE_MCC]: "", [LTY_RULE_MCC_LIST]: "" });
                }
              }}
            />

            {selectedCriteria?.includes("MCC") && (
              <>
                <NetworkForm.Radio
                  formItemOptions={{
                    name: LTY_RULE_MCC,
                    label: "Select merchant category code (MCC)",
                    initialValue: RADIO_GROUP[0].value,
                  }}
                  radioList={RADIO_GROUP}
                />

                <NetworkForm.Select
                  formItemOptions={{
                    name: LTY_RULE_MCC_LIST,
                    label: "Merchant category code list",
                    rules: [
                      {
                        required: true,
                        validator: (_, value: string[]) => {
                          if (value && value.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Please fill out merchant category code (MCC) list."));
                        },
                      },
                    ],
                    initialValue: [],
                    className: "w-p-50",
                  }}
                  mode="multiple"
                  optionFilterProp="children"
                  optionList={MCC_LIST.map(x => ({ label: x.value?.toString(), value: x.key! }))}
                />
              </>
            )}
          </Space>
        ),
        fields: [LTY_RULE_MCC, LTY_RULE_MCC_LIST],
      },
      {
        key: "MERCH" as KeyTab,
        label: <div title="Merchant ID">Merchant ID</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["MERCH"]}</p>
            <NetworkForm.Switch
              formItemOptions={{
                name: LTY_RULES_CRITERIA,
                label: "Enable merchant id criteria",
              }}
              checked={selectedCriteria?.includes("MERCH")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "MERCH"] : selectedCriteria.filter(x => x !== "MERCH"),
                );

                if (!isChecked) {
                  form.setFieldsValue({ [LTY_RULE_MERCH]: "", [LTY_RULE_MERCH_LIST]: "" });
                }
              }}
            />

            {selectedCriteria?.includes("MERCH") && (
              <>
                <NetworkForm.Radio
                  formItemOptions={{
                    name: LTY_RULE_MERCH,
                    label: "Select merchants",
                    initialValue: RADIO_GROUP[0].value,
                  }}
                  radioList={RADIO_GROUP}
                />
                <NetworkForm.Select
                  formItemOptions={{
                    name: LTY_RULE_MERCH_LIST,
                    label: "Specify merchant ID list",
                    rules: [
                      {
                        required: true,
                        validator: (_, value: string[]) => {
                          if (value && value.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Please fill out Id list."));
                        },
                      },
                    ],
                    initialValue: [],
                    className: "w-p-50",
                  }}
                  mode="tags"
                />
              </>
            )}
          </Space>
        ),
        fields: [LTY_RULE_MERCH, LTY_RULE_MERCH_LIST],
      },
      {
        key: "TERM" as KeyTab,
        label: <div title="Terminal ID">Terminal ID</div>,
        children: (
          <Space direction="vertical" size="large">
            <p>{TAB_TITLES["TERM"]}</p>
            <NetworkForm.Switch
              formItemOptions={{
                name: LTY_RULES_CRITERIA,
                label: "Enable terminal id criteria",
              }}
              checked={selectedCriteria?.includes("TERM")}
              onChange={isChecked => {
                form.setFieldValue(
                  LTY_RULES_CRITERIA,
                  isChecked ? [...(selectedCriteria ?? []), "TERM"] : selectedCriteria.filter(x => x !== "TERM"),
                );

                if (!isChecked) {
                  form.setFieldsValue({ [LTY_RULE_TERM_LIST]: "" });
                }
              }}
            />

            {selectedCriteria?.includes("TERM") && (
              <NetworkForm.Select
                formItemOptions={{
                  name: LTY_RULE_TERM_LIST,
                  label: "Specify merchant terminal ID list",
                  rules: [
                    {
                      required: true,
                      validator: (_, value: string[]) => {
                        if (value && value.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Please fill out Id list."));
                      },
                    },
                  ],
                  initialValue: [],
                  className: "w-p-50",
                }}
                mode="tags"
              />
            )}
          </Space>
        ),
        fields: [LTY_RULE_TERM_LIST],
      },
    ];
  }, [form, productCurrency, selectedCriteria]);

  const onFinish = async (values: FormValues) => {
    if (!satisfiesMinimumCriterias()) return;

    const stringifiedValues = { ...values };

    let fields: string[] = [];

    if (isSpecificRetail) {
      fields = pagesList.find(page => page.key === tab)?.fields ?? [];
    }

    if (!isSpecificRetail) {
      fields = [...stringsKeys, ...listsKeys];
    }

    fields.forEach((key: keyof FormValues) => {
      if (!stringifiedValues[key] && loyaltyProgram?.programPctValues?.find(item => item.code === key)) {
        stringifiedValues[key] = "";
      } else if (Array.isArray(stringifiedValues[key])) {
        stringifiedValues[key] = (stringifiedValues[key] as string[]).join(",");
      }
    });

    await editLoyaltyProgram(stringifiedValues);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Transaction Eligibility"
      pageSubtitle="Specify transaction eligibility to participate in loyalty program:"
      size="md"
      formSize="md"
      level="pct-loyalty"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <NetworkForm.Radio
        formItemOptions={{
          name: LTY_CHECK_RULES,
          label: "Transaction eligibility mode",
          initialValue: "N",
        }}
        radioList={RADIO_GROUP_ELIGIBLE}
      />

      {isSpecificRetail && (
        <Tabs
          form={form}
          onSave={onFinish}
          pagesList={pagesList}
          initialValues={initialValues}
          isCheckEnabled={!(isSpecificRetail && (!selectedCriteria || !selectedCriteria?.length))}
          discardAfterChangeTab={true}
          excludedFieldsListFromCheck={[LTY_CHECK_RULES]}
        />
      )}
    </CustomFormWrapper>
  );
};
