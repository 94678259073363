import { RoutesTreeItem } from "@ni/common/types";

import {
  ProductCancellationSettings,
  ProductCoverageSettings,
  ProductIdentification,
  ProductPremiumSettings,
  ProductWaivingSettings,
} from "../../../pages";

export const pagesRoute: RoutesTreeItem[] = [
  { name: "Main Insurance Program Settings", route: "main-insurance-settings", element: <ProductIdentification /> },
  { name: "Insurance Coverage Settings", route: "coverage-settings", element: <ProductCoverageSettings /> },
  { name: "Premium Settings", route: "premium-settings", element: <ProductPremiumSettings /> },
  { name: "Waiving Settings", route: "waiving-settings", element: <ProductWaivingSettings /> },
  { name: "Cancellation Settings", route: "cancellation-settings", element: <ProductCancellationSettings /> },
];
