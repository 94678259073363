export enum FileTypeEnum {
  CSV = "CSV",
  JSON = "JSON",
  TXT = "TXT",
  XML = "XML",
  HTML = "HTML",
}

export enum FileCardDurationEnum {
  Anytime = "Anytime",
  Daily = "Daily",
  Monthly = "Monthly",
  DailyAndMonthly = "Daily & Monthly",
  Quarterly = "Quarterly",
}

export enum FileCardSourceEnum {
  Client = "Client",
  VendorClient = "Vendor/Client",
  FinanceTeam = "Finance team",
  FalconSystem = "Falcon system",
  CardControlSystem = "Card Control System ",
  FileBased = "File Based",
}

export interface CardDisabled {
  disabled: boolean;
  tooltip: string | JSX.Element;
}

export interface CardDependsOn {
  fieldLocation: string[];
  fieldCode: string;
  disabledIfValueEquals: string | boolean;
  defaultDisabled: boolean;
  searchForFieldInArray: boolean;
  disabledTooltip?: string;
  fieldLocationIsArray?: {
    isArray: boolean;
    arrayLocation: string[];
  };
  disabledContent?: string | JSX.Element;
  hide?: boolean;
}

export interface CardDependsOnOptional {
  showIf?: string;
  showIfValueEquals?: string;
}

export interface CardDependsOnFunction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...args: any) => boolean;
  fieldCode: string;
  value: string;
  disabledContent?: string | JSX.Element;
  hide?: boolean;
}

export interface FileCardModel {
  tag: string;
  description: string | JSX.Element;
  duration: FileCardDurationEnum;
  fileType: FileTypeEnum;
  title: string;
  source: FileCardSourceEnum;
  exportLink: string;
  tryItOutLink?: string;
  dependsOn?: CardDependsOn;
  dependsOnFunction?: CardDependsOnFunction;
  dependsOnOptional?: CardDependsOnOptional;
}

export interface FileSection {
  tag: string;
  files: FileCardModel[];
  title?: string;
  dependsOn?: CardDependsOn;
  dependsOnFunction?: CardDependsOnFunction;
  dependsOnOptional?: CardDependsOnOptional;
}
