import { FC, ReactNode, useEffect } from "react";
import { Form } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, SwitchedInputsBox } from "@ni/common/ui";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { useIPPWizardApi } from "../../hooks";

import {
  enableforeclosureFeeOption,
  feeFixedOption,
  feePercentageOption,
  maxFeeOption,
  minFeeOption,
} from "./constants";

export const ForeclosureFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { processWizardRequest } = useIPPWizardApi();
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  const isEnabled = Form.useWatch<boolean>(enableforeclosureFeeOption.code, form);
  const feeFixed = Form.useWatch<string>(feeFixedOption.code, form)?.toString();
  const feePercentage = Form.useWatch<string>(feePercentageOption.code, form)?.toString();

  const minFee = +(Form.useWatch<string>(minFeeOption.code, form) as unknown as number);
  const maxFee = +(Form.useWatch<string>(maxFeeOption.code, form) as unknown as number);

  useHydrateForm({
    form,
    entityFields: wizardResponse?.plan?.planValues ?? [],
    keys: {
      strings: [
        enableforeclosureFeeOption.code,
        feeFixedOption.code,
        feePercentageOption.code,
        maxFeeOption.code,
        minFeeOption.code,
      ],
    },
    allowParse: true,
  });

  useEffect(() => {
    if (!formDisabled && !feePercentage) {
      form.resetFields([minFeeOption.code, maxFeeOption.code]);
    }
  }, [form, formDisabled, feePercentage]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="IPP Foreclosure Fee"
      pageSubtitle="IPP Foreclosure is the process of closing the IPP Plan by the system or by the request from the customer. When the IPP plan is foreclosed, the IPP balance is moved to the retail balance and also system allows to charge foreclosure fees."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      buttonDisabled={isEnabled && !feeFixed && !feePercentage}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <SwitchedInputsBox
        form={form}
        formDisabled={formDisabled}
        switchOptions={enableforeclosureFeeOption}
        inputsOptionsList={[
          [
            { ...feeFixedOption, addonAfter: wizardResponse?.plan?.currency as ReactNode },
            feePercentageOption,
            {
              ...minFeeOption,
              isDisabled: !feePercentage,
              max: (maxFee ?? Infinity) - 0.01,
              addonAfter: wizardResponse?.plan?.currency as ReactNode,
            },
            {
              ...maxFeeOption,
              isDisabled: !feePercentage,
              min: (minFee ?? 0) + 0.01,
              addonAfter: wizardResponse?.plan?.currency as ReactNode,
            },
          ],
        ]}
      />
    </CustomFormWrapper>
  );
};
