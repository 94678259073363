import { FC, useCallback, useEffect, useState } from "react";
import { Button, Collapse, Divider, Form, Input, Select, Space, Switch, Typography } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import { AxiosResponse } from "axios";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { WorkflowFilters } from "@ni/common/types";
import { SelectOptionModal, TooltipInfo } from "@ni/common/ui";
import { onConditionChangeValidation } from "@ni/common/utils";
import { ElementTemplateModalLayout } from "@ni/element-templates";
import { ElementApi, PageApi } from "@ni/sdk/apis";
import {
  BatchChangeElementsRequest,
  ChangePageRequest,
  CreateElementRequest,
  Dictionary,
  Element,
  ElementTemplate,
  ElementType,
  Feature,
  Page,
  ParameterTable,
  Phase,
  PlainElement,
  ProductState,
  State,
  Validation,
} from "@ni/sdk/models";

import ElementCard from "../ElementCard";

import styles from "./styles.module.scss";

const pageServiceApi = new PageApi();

interface PageDrawerForm {
  id: number | undefined;
  header: string;
  description: string;
  code: string;
  hiddenConditions: string;
  order: number;
  phase: number;
  state: State;
}

const elementServiceApi = new ElementApi();

const { TextArea } = Input;

const { Panel } = Collapse;

interface PageEditProps {
  page: Page;
  form: FormInstance;
  workflowId: number;
  phaseId: number;
  getPhases: () => void;
  newPage: boolean;
  opened: boolean;
  pageItems: Page[];
}

const PageEdit: FC<PageEditProps> = ({ pageItems, form, page, workflowId, phaseId, getPhases, newPage, opened }) => {
  const [isPageLoading] = useReduxState<boolean>("isPageLoading", false);
  const [, setIsElementLoading] = useReduxState<boolean>("isElementLoading", false);
  const [, setIsLoading] = useState<boolean>(false);

  const [phaseItems] = useReduxState<Phase[]>("phaseItems", []);
  const [elementItems, setElementItems] = useReduxState<Element[]>("elementItems", []);
  const [, setMinOrderValue] = useState<number>(0);
  const [pageState, setPageState] = useReduxState<State | undefined>("pageState", State.ENABLED);
  const [, setSelectedPhaseId] = useReduxState<number>("selectedPhaseId");
  const [isSelectTemplateModalOpened, setIsSelectTemplateModalOpened] = useState<boolean>(false);
  const [selectedElementTemplateRow, setSelectedElementTemplateRow] = useReduxState<ParameterTable>(
    "selectedElementTemplateRow",
    {},
  );
  const [elementTemplates] = useReduxState<ElementTemplate[]>("elementTemplates", []);
  const [, setPageInfo] = useReduxState<Page>("pageInfo");
  const [maxOrderValue, setMaxOrderValue] = useState<number>(-1);
  const [selectedPhaseId] = useReduxState<number>("selectedPhaseId");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [, setPageItems] = useReduxState<Page[]>(`${phaseId}-pageItems`, []);

  const [, setFilters] = useReduxState<WorkflowFilters>("selectElementTemplateFilter", {});

  const [selectedElementTemplateItem, setSelectedElementTemplateItem] = useReduxState<ElementTemplate>(
    "selectedElementTemplateItem",
    {},
  );

  useEffect(() => {
    if (phaseItems.length < 1) {
      getPhases();
    }
  }, [getPhases, phaseItems.length]);

  useEffect(() => {
    if (pageItems?.length) {
      const maxOrder = pageItems.reduce((max, obj) => (max.order! > obj.order! ? max : obj));
      setMaxOrderValue(maxOrder.order ?? 0);
    }
  }, [pageItems]);

  useEffect(() => {
    if (page && !newPage && opened) {
      form.setFieldsValue({
        id: page.id,
        header: page.header,
        description: page.description,
        code: page.code,
        hiddenConditions: page.hiddenConditions,
        order: page.order,
        state: page.state,
        phase: phaseItems.find(item => item.id === phaseId)?.name,
      });
      setPageState(page.state === State.ENABLED ? State.ENABLED : State.DISABLED);
      setElementItems(((page.elements as Element[]) || []).filter(item => item.state !== "DELETED"));
      setSelectedPhaseId(phaseId);
    } else if (newPage && opened) {
      setPageState(State.ENABLED);
      form.setFieldsValue({
        id: "",
        header: "",
        description: "",
        code: "",
        hiddenConditions: "",
        order: "",
        state: "",
        phase: phaseItems.find(item => item.id === phaseId)?.name,
      });
      setElementItems([]);
    }
  }, [opened, form, page, phaseId, phaseItems]);

  const getElements = useCallback((): void => {
    setIsElementLoading(true);

    elementServiceApi
      .getElements(workflowId, phaseId, Number(page.id))
      .then((response: AxiosResponse<Element[]>) => {
        setElementItems(response.data);
        setIsElementLoading(false);
      })
      .catch(() => {
        setIsElementLoading(false);
      });
  }, [setIsElementLoading, workflowId, phaseId, page.id, setElementItems]);

  const onUpdateElementsOrder = (items: Element[]) => {
    setIsElementLoading(true);

    const requestBody: PlainElement[] = items.map((item, index) => {
      return {
        code: item.code || "",
        defaultValue: item.defaultValue || "",
        disabledConditions: item.disabledConditions || "",
        editableProductState: item.editableProductState || undefined,
        editableTenantState: item.editableTenantState || undefined,
        elementGroup: item.elementGroup || "",
        elementType: item.elementType || undefined,
        elementValueCode: item.elementValueCode || "",
        hiddenConditions: item.hiddenConditions || "",
        hint: item.hint || "",
        id: Number(item.id),
        label: item.label || "",
        order: index,
        state: item.state || State.ENABLED,
        suggestedValue: item.suggestedValue || "",
      };
    });

    const elementList: BatchChangeElementsRequest = {
      elements: requestBody,
    };

    elementServiceApi
      .putElements(elementList, workflowId, phaseId, Number(page.id))
      .then(response => {
        setElementItems(response.data as Element[]);
        getElements();
      })
      .catch(() => {
        setIsElementLoading(false);
      });
  };

  useEffect(() => {
    if (elementItems.length > 0) {
      const minOrder = elementItems.reduce((max, obj) => (max.order > obj.order ? max : obj));
      setMinOrderValue(minOrder.order);
    }
  }, [elementItems]);

  const onAddElement = () => {
    setIsSelectTemplateModalOpened(true);
    setSelectedElementTemplateRow({});
  };
  const onCancelSelectTemplateModal = (): void => {
    setIsSelectTemplateModalOpened(false);
    setSelectedElementTemplateRow({});
  };

  useEffect(() => {
    if (selectedElementTemplateRow) {
      const selectedET = elementTemplates.find(item => item.id === selectedElementTemplateRow.id);
      setSelectedElementTemplateItem(selectedET ?? {});
    }
  }, [selectedElementTemplateRow]);

  const onCreateNewElement = () => {
    setIsLoading(true);
    const requestBody: CreateElementRequest = {
      code: selectedElementTemplateItem.code ?? "",
      defaultValue: selectedElementTemplateItem.defaultValue,
      disabledConditions: selectedElementTemplateItem.disabledConditions,
      editableProductState: selectedElementTemplateItem.editableProductState,
      editableTenantState: selectedElementTemplateItem.editableTenantState,
      elementGroup: selectedElementTemplateItem.elementGroup ?? "",
      elementTemplateId: selectedElementTemplateRow.id!,
      elementType: selectedElementTemplateItem.elementType ?? ElementType.TEXT,
      elementValueCode: selectedElementTemplateItem.elementValueCode,
      featureId: Number(selectedElementTemplateItem.feature?.code),
      hiddenConditions: selectedElementTemplateItem.hiddenConditions,
      hint: selectedElementTemplateItem.hint,
      label: selectedElementTemplateItem.label,
      order: (elementItems?.length ?? 0) + 1,
      state: selectedElementTemplateItem.currentState ?? State.ENABLED,
      suggestedValue: selectedElementTemplateItem.suggestedValue,
      validations: selectedElementTemplateItem.validations,
    };

    elementServiceApi
      .createElement({ ...requestBody, featureId: 1 }, workflowId, phaseId, page.id!)
      .then(() => {
        setIsLoading(false);
        getElements();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleConfirmSelectTemplate = () => {
    onCreateNewElement();
    setIsSelectTemplateModalOpened(false);
  };

  const header = (text: string) => {
    return (
      <div className={styles["page-settings-panel-header"]}>
        <TooltipInfo tooltipProps={{ placement: "right", title: text }}>
          {text}
          <QuestionCircleFilled />
        </TooltipInfo>
      </div>
    );
  };

  const findCard = useCallback(
    (id: string) => {
      const card = elementItems?.filter(c => c.id === Number(id))[0] as {
        code: string;
        defaultValue: string;
        disabledConditions: string;
        editableProductState: ProductState;
        editableTenantState: ProductState;
        elementGroup: string;
        elementType: ElementType;
        elementValueCode: string;
        elementValues: Array<Dictionary>;
        feature: Feature;
        hiddenConditions: string;
        hint: string;
        id: number;
        label: string;
        order: number;
        state: State;
        suggestedValue: string;
        validations: Array<Validation>;
      };
      return {
        card,
        index: elementItems?.indexOf(card),
      };
    },
    [elementItems],
  );

  const moveCard = useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findCard(id);
      setElementItems(
        update(elementItems, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },
    [elementItems, findCard, setElementItems],
  );

  const onFinish = (values: PageDrawerForm) => {
    setIsLoading(true);

    const requestBody: ChangePageRequest = {
      header: values.header,
      description: values.description,
      code: values.code,
      hiddenConditions: values.hiddenConditions,
      order: maxOrderValue + 1,
      state: pageState,
      newPhaseId: selectedPhaseId,
    };

    if (values.id) {
      pageServiceApi
        .editPage(requestBody, workflowId, phaseId, values.id)
        .then(res => {
          setPageInfo(res.data);
          setIsLoading(false);
          if (selectedPhaseId !== phaseId) {
            setPageItems(pageItems.filter(p => p.id !== values.id));
          } else {
            setPageItems([...pageItems, res.data]);
          }
          setIsEnabled(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsEnabled(false);
        });
    } else {
      pageServiceApi
        .createPage(requestBody, workflowId, phaseId)
        .then(res => {
          setPageInfo(res.data);
          setIsLoading(false);
          setPageItems([...pageItems, res.data]);
          setIsEnabled(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsEnabled(false);
        });
    }
  };

  const onValuesChange = (): void => {
    const isFieldsFilled = form.getFieldValue("code")?.length > 0 && form.getFieldValue("header")?.length > 0;

    setIsEnabled(
      isFieldsFilled &&
        ((form.getFieldValue("hiddenConditions")?.length > 0 &&
          onConditionChangeValidation(String(form.getFieldValue("hiddenConditions")))) ||
          form.getFieldValue("hiddenConditions") === undefined ||
          form.getFieldValue("hiddenConditions")?.length === 0),
    );
  };

  const onFormChange = (currentValue: { [key: string]: string }, value: { [key: string]: string }) => {
    const filter: WorkflowFilters = Object.keys(value).reduce((acc, key) => {
      if (key === "searchValue" && value[key]) {
        return { ...acc, search: value[key] };
      }
      if (value[key] && value[key] !== "all") {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: value[key],
          },
        };
      }
      if (value[key] && value[key] === "all") {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: "",
          },
        };
      }

      return acc;
    }, {} as WorkflowFilters);
    setFilters(filter);
  };

  return (
    <div className={styles["page-settings-wrapper"]}>
      <Form
        form={form}
        className="page-editing-container"
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <div>
          <Collapse className="page-settings-collapse" defaultActiveKey={["1"]} ghost={true}>
            <Panel header={header("Page settings")} key="1">
              <div className={styles["page-settings-form-switch"]}>
                <Form.Item hidden={true} name="id" />
                <Space direction="horizontal">
                  <Form.Item className="form-item" name="state">
                    <Switch
                      checked={pageState === State.ENABLED}
                      onChange={() => {
                        if (pageState === State.ENABLED) {
                          setPageState(State.DISABLED);
                        } else {
                          setPageState(State.ENABLED);
                        }
                      }}
                    />
                  </Form.Item>
                  <Typography.Text strong={true}>Enable</Typography.Text>
                </Space>
              </div>
              <div className={styles["settings-group"]}>
                <Form.Item
                  className="form-item"
                  name="phase"
                  label="Phase"
                  tooltip={{ title: "phase", icon: <QuestionCircleFilled /> }}
                >
                  <Select onChange={(value: number) => setSelectedPhaseId(value)}>
                    {phaseItems.map(phase => (
                      <Select.Option key={phase.id} value={phase.id}>
                        {phase.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="form-item"
                  name="code"
                  label="Code"
                  tooltip={{ title: "Code", icon: <QuestionCircleFilled /> }}
                  rules={[{ required: true, message: "Code is required" }]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Form.Item
                className="form-item phase"
                name="hiddenConditions"
                label="Hiding conditions"
                tooltip={{ title: "Hiding conditions", icon: <QuestionCircleFilled /> }}
              >
                <Input />
              </Form.Item>
            </Panel>
          </Collapse>
          <Divider />
          <Collapse className="page-settings-collapse" defaultActiveKey={["2"]} ghost={true}>
            <Panel header={header("Page content")} key="2">
              <div className={styles["settings-group"]}>
                <Form.Item
                  className="form-item"
                  label="Page header"
                  name="header"
                  tooltip={{ title: "Page header", icon: <QuestionCircleFilled /> }}
                  rules={[{ required: true, message: "Page header is required" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="form-item"
                  label="Description"
                  name="description"
                  tooltip={{ title: "Description", icon: <QuestionCircleFilled /> }}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </div>
            </Panel>
          </Collapse>
          <Button
            loading={isPageLoading}
            className={styles["page-save-button"]}
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!isEnabled}
          >
            Save
          </Button>
        </div>
      </Form>
      <Divider />
      {page.id && (
        <>
          <div className={styles["elements-header-block"]}>
            <div className={styles["block-title"]}>Elements</div>
            <div className={styles["element-details-form-buttons"]}>
              <Button
                className={styles["button"]}
                type="primary"
                size="large"
                htmlType="button"
                onClick={onAddElement}
                loading={isPageLoading}
              >
                Add element
              </Button>
            </div>
          </div>
          <DndProvider backend={HTML5Backend} key={page.id}>
            <div className={styles["element-list"]}>
              {elementItems.length ? (
                elementItems.map((element, index) => (
                  <ElementCard
                    key={element.id ?? `new-${element.order ?? "element"}`}
                    element={element}
                    id={String(element.id)}
                    moveCard={moveCard}
                    findCard={findCard}
                    workflowId={workflowId}
                    phaseId={phaseId}
                    pageId={Number(page.id)}
                    index={index}
                    onUpdateElementsOrder={onUpdateElementsOrder}
                    getElements={getElements}
                  />
                ))
              ) : (
                <div className={styles["empty-description"]}>You do not have any elements yet.</div>
              )}
            </div>
          </DndProvider>
        </>
      )}
      <SelectOptionModal
        isOpened={isSelectTemplateModalOpened}
        onCancel={onCancelSelectTemplateModal}
        onConfirm={handleConfirmSelectTemplate}
        isLoading={false}
        onChange={onFormChange}
      >
        <ElementTemplateModalLayout />
      </SelectOptionModal>
    </div>
  );
};

export default PageEdit;
