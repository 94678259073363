import { SelectOption } from "@ni/common/types";

export const periodRadioOptions: SelectOption[] = [
  { value: "D", label: "Day" },
  { value: "M", label: "Month" },
];

export const autoClousureOptions: SelectOption[] = [
  { value: "by_active_cards", label: "By dormancy due to card status" },
  { value: "by_trans_and_bal", label: "By dormancy due to Inactivity" },
];
