/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangePlanRequest } from '../model';
// @ts-ignore
import { CopyPlanRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { Plan } from '../model';
/**
 * PlanApi - axios parameter creator
 * @export
 */
export const PlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Copies a Plan
         * @summary Copy Plan
         * @param {CopyPlanRequest} copyPlanRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPlan: async (copyPlanRequest: CopyPlanRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyPlanRequest' is not null or undefined
            assertParamExists('copyPlan', 'copyPlanRequest', copyPlanRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyPlan', 'id', id)
            const localVarPath = `/api/v1/plans/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Plan by ID
         * @param {Plan} plan 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPlan: async (plan: Plan, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('editPlan', 'plan', plan)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editPlan', 'id', id)
            const localVarPath = `/api/v1/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(plan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Plan by ID
         * @summary Get Plan by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPlanById', 'id', id)
            const localVarPath = `/api/v1/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlanApi - functional programming interface
 * @export
 */
export const PlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlanApiAxiosParamCreator(configuration)
    return {
        /**
         * Copies a Plan
         * @summary Copy Plan
         * @param {CopyPlanRequest} copyPlanRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyPlan(copyPlanRequest: CopyPlanRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyPlan(copyPlanRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Plan by ID
         * @param {Plan} plan 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPlan(plan: Plan, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePlanRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editPlan(plan, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Plan by ID
         * @summary Get Plan by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlanById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlanById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlanApi - factory interface
 * @export
 */
export const PlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlanApiFp(configuration)
    return {
        /**
         * Copies a Plan
         * @summary Copy Plan
         * @param {CopyPlanRequest} copyPlanRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPlan(copyPlanRequest: CopyPlanRequest, id: number, options?: any): AxiosPromise<Plan> {
            return localVarFp.copyPlan(copyPlanRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Plan by ID
         * @param {Plan} plan 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPlan(plan: Plan, id: number, options?: any): AxiosPromise<ChangePlanRequest> {
            return localVarFp.editPlan(plan, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Plan by ID
         * @summary Get Plan by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlanById(id: number, options?: any): AxiosPromise<Plan> {
            return localVarFp.getPlanById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlanApi - interface
 * @export
 * @interface PlanApi
 */
export interface PlanApiInterface {
    /**
     * Copies a Plan
     * @summary Copy Plan
     * @param {CopyPlanRequest} copyPlanRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApiInterface
     */
    copyPlan(copyPlanRequest: CopyPlanRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Plan>;

    /**
     * 
     * @summary Edit Plan by ID
     * @param {Plan} plan 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApiInterface
     */
    editPlan(plan: Plan, id: number, options?: AxiosRequestConfig): AxiosPromise<ChangePlanRequest>;

    /**
     * Returns Plan by ID
     * @summary Get Plan by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApiInterface
     */
    getPlanById(id: number, options?: AxiosRequestConfig): AxiosPromise<Plan>;

}

/**
 * PlanApi - object-oriented interface
 * @export
 * @class PlanApi
 * @extends {BaseAPI}
 */
export class PlanApi extends BaseAPI implements PlanApiInterface {
    /**
     * Copies a Plan
     * @summary Copy Plan
     * @param {CopyPlanRequest} copyPlanRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public copyPlan(copyPlanRequest: CopyPlanRequest, id: number, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).copyPlan(copyPlanRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Plan by ID
     * @param {Plan} plan 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public editPlan(plan: Plan, id: number, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).editPlan(plan, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Plan by ID
     * @summary Get Plan by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlanApi
     */
    public getPlanById(id: number, options?: AxiosRequestConfig) {
        return PlanApiFp(this.configuration).getPlanById(id, options).then((request) => request(this.axios, this.basePath));
    }
}
