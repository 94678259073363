import { DynamicSwitchRule } from "@ni/common/ui";

export const cashRules: DynamicSwitchRule[] = [
  {
    name: "a-b-cash-enable",
    label: "All",
    tooltip: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-a-b-cash-max-nr",
    maxAmount: "nic-a-b-cash-max-am",
    maxSingleAmount: "nic-a-b-cash-max-single",
  },
  {
    name: "a-b-cash-for-enable",
    label: "Foreign",
    tooltip: "This limiter counts all foreign cash transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-a-b-cash-for-max-nr",
    maxAmount: "nic-a-b-cash-for-max-am",
    maxSingleAmount: "nic-a-b-cash-for-max-single",
  },
];

export const retailRules: DynamicSwitchRule[] = [
  {
    name: "a-b-retail-enable",
    label: "All",
    tooltip: "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-a-b-retail-max-nr",
    maxAmount: "nic-a-b-retail-max-am",
    maxSingleAmount: "nic-a-b-retail-max-single",
  },
  {
    name: "a-b-retail-for-enable",
    label: "Foreign",
    tooltip: "This limiter counts foreign retail transactions. Turn it off, if restrictions are not intended.",
    maxNumber: "nic-a-b-retail-for-max-nr",
    maxAmount: "nic-a-b-retail-for-max-am",
    maxSingleAmount: "nic-a-b-retail-for-max-single",
  },
];

export const totalRules: DynamicSwitchRule[] = [
  {
    name: "a-b-total-enable",
    label: "All",
    tooltip:
      "Total limiter that includes all transactions (Cash and Retail). Turn it off, if restrictions are not intended.",
    maxNumber: "nic-a-b-total-max-nr",
    maxAmount: "nic-a-b-total-max-am",
    maxSingleAmount: "nic-a-b-total-max-single",
  },
];
