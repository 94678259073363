import { useState } from "react";
import { FormInstance } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { FormValues } from "@ni/common/types";

interface UseFormButtonDisabledProps {
  form: FormInstance<FormValues>;
  initialState?: boolean;
}

export const useFormButtonDisabled = ({ form, initialState = true }: UseFormButtonDisabledProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(initialState);

  const onFormChange = () => {
    form
      .validateFields()
      .then(() => {
        setIsButtonDisabled(false);
      })
      .catch((errors: ValidateErrorEntity) => {
        if (errors.errorFields.length > 0) {
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }
      });
  };

  return [isButtonDisabled, onFormChange] as [boolean, () => void];
};
