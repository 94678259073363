import { FC, useEffect } from "react";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { useIPPWizardApi } from "../../hooks";

import { planDescriptionOption, planNameOption, planNumberOption } from "./constants";

export const IdentificationPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormValues>();
  const { processWizardRequest } = useIPPWizardApi();
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  useEffect(() => {
    if (wizardResponse?.plan) {
      form.setFieldsValue({
        [planNumberOption.code]: wizardResponse?.plan?.code,
        [planNameOption.code]: wizardResponse?.plan?.name,
        [planDescriptionOption.code]: wizardResponse?.plan?.description,
      });
    }
  }, [form, wizardResponse?.plan]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
      if (wizardResponse?.tenant?.id) navigate(`/tenant/${wizardResponse?.tenant?.id}/installment-payment-plans`);
      else navigate("/");
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="IPP Identification"
      pageSubtitle="Installment Payment Plan was created successfully! The following fields will help to identify the Installment Payment Plan on the screens and documents."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      submitLabel="Complete IPP setup"
      additionalRoute="./installment-payment-plans"
    >
      <NetworkForm.Number
        formItemOptions={{
          name: planNumberOption.code,
          label: planNumberOption.name,
          rules: [{ required: true, message: "Plan number is required" }],
        }}
        min={planNumberOption.min}
        max={planNumberOption.max}
        precision={planNumberOption.decimalsNumber}
        disabled={true}
      />

      <NetworkForm.String
        formItemOptions={{
          name: planNameOption.code,
          label: planNameOption.name,
          rules: [{ required: true, message: "Plan name is required" }],
          tooltip: planNameOption.tooltip,
        }}
        maxLength={24}
      />

      <NetworkForm.String
        formItemOptions={{
          name: planDescriptionOption.code,
          label: planDescriptionOption.name,
          tooltip: planDescriptionOption.tooltip,
        }}
      />
    </CustomFormWrapper>
  );
};
