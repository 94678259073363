export const RADIO_GROUP = [
  { value: "Inc", label: "To include" },
  { value: "Exc", label: "To exclude" },
];

export const LTY_POINT_MODE_RADIO_GROUP = [
  { value: "PN2", label: "Points" },
  { value: "CONTR", label: "Contract currency" },
];

export const LTY_ACCR_YEARLY_RADIO_GROUP = [
  { value: "A", label: "From account opening" },
  { value: "O", label: "From first card activation" },
];

export const LTY_CASHBACK_INTERVAL_RADIO_GROUP = [
  { label: "Billing cycle", value: "Billing", tooltip: "Last day of cycle, after the cumulative accrual" },
  { label: "Quarter", value: "Quarterly", tooltip: "Morning of Jan. 1st, Apr. 1st, Jul. 1st, Oct. 1st" },
  { label: "Year", value: "Annual", tooltip: "Morning of Jan. 1st" },
];

export const RADIO_GROUP_ELIGIBLE = [
  { value: "N", label: "Any retail transaction is eligible to participate in loyalty program" },
  { value: "Y", label: "Only specific retail transaction will participate" },
];

export const RADIO_GROUP_LTY_REDEEM_TYPE = [
  {
    label: "Cashback",
    value: "C",
    tooltip: "Points can be redeemed, and credit (cashback) transaction is posted to customer account",
  },
  {
    label: "External voucher",
    value: "E",
    tooltip: "Points can be redeemed and used to obtain vouchers or discounts at partner or affiliated businesses",
  },
];
