import { useMemo } from "react";

import { Product, ProductState } from "@ni/sdk/models";

import { useReduxState } from "../store";

const useProductStateFormDisabled = () => {
  const [currentProduct] = useReduxState<Product>("currentProduct");

  const isDisabled = useMemo(() => {
    if (!currentProduct || !Object.keys(currentProduct).length) return false;

    const isProductLocked =
      currentProduct.productState === ProductState.LIVE ||
      currentProduct.productState === ProductState.REJECTED ||
      currentProduct.productState === ProductState.UAT ||
      currentProduct.productState === ProductState.UAT_PENDING;

    return isProductLocked;
  }, [currentProduct]);

  return isDisabled;
};

export { useProductStateFormDisabled };
