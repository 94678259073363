import { FC } from "react";
import { Input } from "antd";

import styles from "./styles.module.scss";

const { TextArea } = Input;

interface SmsVariablePreviewProps {
  text: string;
}

export const SmsVariablePreview: FC<SmsVariablePreviewProps> = ({ text = "" }) => (
  <TextArea
    className={styles["sms-variable-preview"]}
    rows={4}
    variant="outlined"
    showCount={{
      formatter: () => `${text?.length || 0}/512`,
    }}
    value={text}
    disabled={false}
    onChange={() => {}}
  />
);
