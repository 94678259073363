/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ProductState = {
    DRAFT: 'DRAFT',
    UAT_PENDING: 'UAT_PENDING',
    REJECTED: 'REJECTED',
    UAT: 'UAT',
    DELETED: 'DELETED',
    PENDING_CHANGES: 'PENDING_CHANGES',
    LIVE: 'LIVE'
} as const;

export type ProductState = typeof ProductState[keyof typeof ProductState];



