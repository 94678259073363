import { Form, notification, Typography } from "antd";
import { useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, Tabs } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";

import { usePct } from "../../../../hooks";
import {
  DCCTransactionFields,
  FX_FEE_ENABLED,
  FXMarkupFields,
  NIC_IPS_DCC_FEES_FIN_ONLY_RETAIL,
  NIC_IPS_MARKUP_FEES_FIN_ONLY_RETAIL,
  TabKeys,
} from "../TransactionFees.constants";

import { DCCTransactionTabContent } from "./DCCTransactionTabContent";
import { FXMarkupTabContent } from "./FXMarkupTabContent";

export const CurrencyConversionFeesPage = () => {
  const [form] = Form.useForm<FormValues>();
  const fxFeeEnabled = Form.useWatch<boolean>(FX_FEE_ENABLED, form);

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/fees-tenant-configuration`,
      label: "Fee Settings - Global",
    },
  ];

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: pct.pctProductValues ?? [],
      keys: {
        strings: [
          ...[...DCCTransactionFields.rows, ...FXMarkupFields.rows]
            .map(row => row.fields.map(field => field.name))
            .flat(2),
          FX_FEE_ENABLED,
          NIC_IPS_MARKUP_FEES_FIN_ONLY_RETAIL,
          NIC_IPS_DCC_FEES_FIN_ONLY_RETAIL,
        ],
      },
    },
    [form, pct.pctProductValues],
  );

  const onFinish = async (values: FormValues) => {
    try {
      await onSavePct(values);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Currency conversion fees"
      pageSubtitle="Currency conversion occurs when contract currency and transaction currency are not the same"
      submitHandler={onFinish}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: FX_FEE_ENABLED,
          label: <Typography.Text strong={true}>Enable currency conversion fees for your product</Typography.Text>,
          valuePropName: "checked",
          className: "col-switch",
        }}
      />

      {fxFeeEnabled && (
        <Tabs<"" | TabKeys.FX_MARKUP_KEY | TabKeys.DCC_TRANSACTION_KEY>
          pagesList={[
            {
              key: TabKeys.FX_MARKUP_KEY as const,
              children: (
                <FXMarkupTabContent
                  activeTabKey={TabKeys.FX_MARKUP_KEY}
                  form={form}
                  productCurrency={productCurrency}
                  description={FXMarkupFields.description}
                  rows={FXMarkupFields.rows}
                />
              ),
              label: <div>FX markup</div>,
            },
            {
              key: TabKeys.DCC_TRANSACTION_KEY as const,
              children: (
                <DCCTransactionTabContent
                  activeTabKey={TabKeys.DCC_TRANSACTION_KEY}
                  form={form}
                  productCurrency={productCurrency}
                  description={DCCTransactionFields.description}
                  rows={DCCTransactionFields.rows}
                />
              ),
              label: <div>DCC transaction</div>,
            },
          ]}
          isCheckEnabled={true}
          form={form}
          excludedFieldsListFromCheck={[FX_FEE_ENABLED]}
          initialValues={initialValues}
          discardAfterChangeTab={false}
          onSave={onFinish}
        />
      )}
    </CustomFormWrapper>
  );
};
