import { SelectOption } from "@ni/common/types";

export const PLANS_RADIO_GROUP: SelectOption[] = [
  {
    value: "IPP_BC",
    label: "Balance conversion",
    tooltip:
      "Balance conversion allows customers to convert outstanding retail balance -consisting of multiple transactions- into monthly instalments",
  },
  { value: "IPP_TC", label: "Transaction conversion", tooltip: "A single retail transaction is converted into IPP" },
  {
    value: "IPP_NB",
    label: "New balance",
    tooltip: "new transaction will be posted to cardholder’s account and converted to IPP “Easy Cash or Loan on call”",
  },
];
