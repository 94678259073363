import { Form } from "antd";
import { useParams } from "react-router-dom";

import { LTY_POINT_EXPIRY, LTY_POINT_VALIDITY_PERIOD, LTY_POINT_WRITE_OFF } from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { minMaxValidator } from "@ni/common/utils";

import { useLoyaltyProgramTemplateApi } from "../../../../hooks/tenant/useLoyaltyProgramTemplateApi";

const stringsKeys = [LTY_POINT_EXPIRY, LTY_POINT_VALIDITY_PERIOD, LTY_POINT_WRITE_OFF];

export const PointExpiry = () => {
  const [form] = Form.useForm<FormValues>();

  const { templateId } = useParams<{ templateId: string }>();

  const { loyaltyProgramTemplate, editLoyaltyProgramTemplate } = useLoyaltyProgramTemplateApi({
    loyaltyTemplateId: parseInt(templateId ?? "0", 10),
  });

  const isPointsExpiryEnabled = Form.useWatch<string>(LTY_POINT_EXPIRY, form);

  useHydrateForm(
    {
      form,
      entityFields: [
        ...(loyaltyProgramTemplate?.programValues ?? []),
        ...(loyaltyProgramTemplate?.programPctValues ?? []),
      ],
      keys: {
        strings: stringsKeys,
      },
    },
    [loyaltyProgramTemplate],
  );

  const onFinish = async (values: FormValues) => {
    [...stringsKeys].forEach(key => {
      if (!values[key]?.toString() && loyaltyProgramTemplate?.programPctValues?.find(item => item.code === key)) {
        values[key] = "";
      }
    });
    await editLoyaltyProgramTemplate(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Point Expiry"
      pageSubtitle="Expiration period can be setup for accrued loyalty points. Points can not be redeemed for a value after expiration."
      size="md"
      level="tenant"
      submitLabel="Save"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: LTY_POINT_EXPIRY,
          label: "Enable points expiry",
          tooltip:
            'This parameter specifies whether "Points Expiry" feature is enabled or not for a product. If the feature is enabled, then points under a program are expired after defined period.',
          initialValue: false,
          valuePropName: "checked",
        }}
      />

      {isPointsExpiryEnabled && (
        <>
          <NetworkForm.Number
            formItemOptions={{
              name: LTY_POINT_VALIDITY_PERIOD,
              label: "Points validity period",
              tooltip:
                "This parameter is used to define the number of cycles after accrual after which points are moved to expired bucket for the program.",
              rules: [
                { required: true, message: "Points validity period is required" },
                () => ({
                  validator(_, value: number) {
                    return minMaxValidator(value, 0, 999);
                  },
                }),
              ],
            }}
            controls={false}
            precision={0}
          />

          <NetworkForm.Number
            formItemOptions={{
              name: LTY_POINT_WRITE_OFF,
              label: "Points write off period",
              tooltip:
                "This parameter is used to define the number of cycles after accrual after which the points are going to be written-off from expired bucket for the program.",
              rules: [
                { required: true, message: "Points write off period is required" },
                () => ({
                  validator(_, value: number) {
                    return minMaxValidator(value, 0, 999);
                  },
                }),
              ],
            }}
            controls={false}
            precision={0}
          />
        </>
      )}
    </CustomFormWrapper>
  );
};
