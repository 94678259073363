import { FC, useEffect, useState } from "react";
import { Button, Flex, Form, Space } from "antd";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { ArrowRightOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { MCC_LIST } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGroup, TooltipInfo } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant, TenantValue } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const groupNameCode = "grp-name-";
const groupCodesCode = "grp-codes-";
const mccTooltip =
  "Create Groups with the MCC codes you need to control only. Use Comma as a separator when adding multiple MCC codes. Try to Narrow your MCC groups as much as possible to have more precise control on it.";

const tenantsServiceApi = new TenantApi();

interface AdditionServiceDetailsForm extends FormValues {
  "nic-falcon-enable-notif": boolean;
  "nic-falcon-fi-id": string;
  "falcon-managed-by": string;
  isCardControlSelected: boolean;
  isFalconSelected: boolean;
}

export const AdditionalServicesDetails: FC = () => {
  const [form] = Form.useForm();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [groups, setGroups] = useState<{ id: number; isDeleted?: boolean }[]>([]);

  const isCardControl = Form.useWatch<boolean>("isCardControlSelected", form);

  const addGroup = () => {
    setGroups([...groups, { id: groups.length }]);
    form.setFieldValue(`${groupCodesCode}${groups.length}`, []);
  };

  const deleteGroup = (deletedId: number) => {
    const deletedIndex = groups.findIndex(({ id }) => id === deletedId);
    const newList = [...groups];
    newList[deletedIndex].isDeleted = true;
    form.setFieldValue(`${groupCodesCode}${deletedId}`, "");
    form.setFieldValue(`${groupNameCode}${deletedId}`, "");
    setGroups(newList);
  };

  const getCardControlValues = (values: FormValues): TenantValue[] => {
    let indexPointer = 0;
    const valuesHolder: FormValues = {};
    setIsLoading(true);

    groups.forEach(group => {
      if (!group?.isDeleted) {
        valuesHolder[`${groupCodesCode}${indexPointer}`] = (values[`${groupCodesCode}${group.id}`] as string[])?.join(
          ",",
        );
        valuesHolder[`${groupNameCode}${indexPointer}`] = values[`${groupNameCode}${group.id}`] as string;
        indexPointer += 1;
      }
      values[`${groupCodesCode}${group.id}`] = "";
      values[`${groupNameCode}${group.id}`] = "";
    });

    return Object.keys({ ...values, ...valuesHolder }).map((val: string) => {
      return {
        fieldCode: val,
        value: (valuesHolder[val] ?? values[val]) as string,
      };
    });
  };

  const { id } = useParams<{ id: string }>();

  const handleMainFalconSwitch = (checked: boolean) => {
    if (!checked) {
      form.setFieldsValue({
        "nic-falcon-enable-notif": false,
        "nic-falcon-fi-id": "",
        "falcon-managed-by": "",
      });
    } else {
      form.setFieldValue("falcon-managed-by", "SELF");
    }
  };

  useEffect(() => {
    if (tenant?.tenantValues) {
      let groupCodesList: { id: number }[] = [];
      const tenantValues = tenant.tenantValues?.reduce((previous, value) => {
        if (value?.fieldCode) {
          if (value?.fieldCode.includes(groupCodesCode)) {
            const groupIndex = Number(value.fieldCode.charAt(value.fieldCode.length - 1));

            if (!Number.isNaN(groupIndex)) {
              groupCodesList[groupIndex] = { id: groupIndex };
              previous.set(value.fieldCode, value?.value?.split(","));
            }
          } else if (value.fieldCode === "nic-falcon-enable-notif") {
            previous.set(value.fieldCode, tenant?.isFalconSelected?.toString() === "true");
          } else previous.set(value.fieldCode, value.value);
        }
        return previous;
      }, new Map<string, unknown>());

      if (!tenantValues.has("nic-falcon-enable-notif")) {
        tenantValues.set("nic-falcon-enable-notif", tenant?.isFalconSelected?.toString() === "true");
      }
      tenantValues.set("isFalconSelected", tenant?.isFalconSelected?.toString() === "true");
      tenantValues.set("isCardControlSelected", tenant?.isCardControlSelected?.toString() === "true");

      groupCodesList = groupCodesList.filter(item => tenantValues.get(`${groupNameCode}${item.id}`));

      setGroups(groupCodesList);
      const formValues = form.getFieldsValue() as FormValues;
      groupCodesList.forEach(({ id }) => {
        formValues[`${groupNameCode}${id}`] = "";
        formValues[`${groupCodesCode}${id}`] = null;
      });
      Object.keys(formValues).forEach(key => {
        formValues[key] = tenantValues?.get(key) as string | string[];
      });
      form.setFieldsValue(formValues);
    }
  }, [form, tenant?.isCardControlSelected, tenant?.isFalconSelected, tenant?.tenantValues]);

  const onFinish = (value: AdditionServiceDetailsForm) => {
    setIsLoading(true);
    const tenantId = parseInt(id ?? "0", 10);
    const tenantValues: Partial<AdditionServiceDetailsForm> = { ...value };

    delete tenantValues.isCardControlSelected;
    delete tenantValues.isFalconSelected;

    const requestBody: ChangeTenantRequest = {
      isCardControlSelected: value.isCardControlSelected,
      isFalconSelected: value.isFalconSelected,
      tenantValues: [
        ...Object.keys(tenantValues).map(val => ({
          fieldCode: val,
          value: value[val as keyof AdditionServiceDetailsForm] ? "true" : "false",
        })),
        ...getCardControlValues(value),
      ],
    };

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Additional services details"
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
    >
      <div
        className={cn(
          styles["additional-services-details-container"],
          styles["additional-services-details-card-section"],
        )}
      >
        <div className={styles["additional-services-details-card-header"]}>
          <NetworkForm.Switch
            onChange={handleMainFalconSwitch}
            formItemOptions={{
              valuePropName: "checked",
              name: "isFalconSelected",
              label: "Falcon fraud monitoring",
            }}
          />
        </div>

        <div className={styles["additional-services-details-card-body"]}>
          <PageItemLayoutGroup>
            <div>
              Falcon is Network International’s peripheral system which provides fraud monitoring facilities. {"  "}
              <a
                href="https://www.network.ae/en/contents/listing/fraud-solutions#book-1"
                target="_blank"
                rel="noreferrer"
              >
                <span>Read more </span>
                <ArrowRightOutlined />
              </a>
            </div>
            <Form.Item dependencies={["isFalconSelected"]}>
              {() => (
                <Space direction="vertical" size={24}>
                  <NetworkForm.Switch
                    disabled={!form.getFieldValue("isFalconSelected")}
                    formItemOptions={{
                      className: styles["additional-services-details-form-item"],
                      valuePropName: "checked",
                      name: "nic-falcon-enable-notif",
                      label: "Enable Notifications",
                    }}
                  />

                  <NetworkForm.String
                    disabled={!form.getFieldValue("isFalconSelected")}
                    formItemOptions={{
                      name: "nic-falcon-fi-id",
                      label: "Falcon Financial Institution ID",
                    }}
                  />

                  <NetworkForm.Select
                    disabled={!form.getFieldValue("isFalconSelected")}
                    formItemOptions={{
                      name: "falcon-managed-by",
                      label: "Falcon Managed By",
                      initialValue: "SELF",
                    }}
                    options={[
                      {
                        label: "Self-managed",
                        value: "SELF",
                      },
                      { label: "Network International", value: "NI" },
                    ]}
                  />
                </Space>
              )}
            </Form.Item>
          </PageItemLayoutGroup>
        </div>
      </div>

      <div
        className={cn(
          styles["additional-services-details-container"],
          styles["additional-services-details-card-section"],
        )}
      >
        <div className={styles["additional-services-details-card-header"]}>
          <NetworkForm.Switch
            formItemOptions={{
              className: styles["additional-services-details-form-item"],
              valuePropName: "checked",
              name: "isCardControlSelected",
              label: "Card Control",
            }}
          />
        </div>

        <div className={styles["additional-services-details-card-body"]}>
          <PageItemLayoutGroup>
            <Space direction="vertical">
              <p>
                Card control is Network International’s peripheral system which provides certain transactional level
                controls to the client and as well as to the customer. {"  "}
                <a
                  href="https://www.network.ae/en/contents/listing/addonsolutions#mbook-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Read more </span>
                  <ArrowRightOutlined />
                </a>
              </p>
            </Space>

            <Space direction="vertical" size={10}>
              <div className="text-black-h3">
                <TooltipInfo
                  label="Marchant Categories Groups Codes"
                  tooltipProps={{
                    title: mccTooltip,
                  }}
                />
              </div>

              <p>
                Mange MCC (Merchant Category Code) groups at the tenant level. Customer can define and configure the
                merchant categories that are permitted or restricted for transactions. Customers have the ability to
                view, modify, or add new MCC groups and codes as needed, providing them with precise control over card
                transaction policies. This ensures that businesses can tailor their card usage settings to meet specific
                operational, security, and compliance requirements.
              </p>

              {groups
                .filter(item => !item?.isDeleted)
                .map(group => (
                  <Flex key={group.id} justify="space-between" className={styles["mcc-card"]}>
                    <Space direction="vertical">
                      <NetworkForm.String
                        formItemOptions={{
                          name: `${groupNameCode}${group.id}`,
                          label: "Group Name",
                          rules: [
                            { required: true, message: "Group name can't be empty" },
                            { pattern: /^((?!(?<!\.)\s+).)*$/, message: "Group name can't include spaces" },
                          ],
                        }}
                        className="w-p-75"
                        disabled={!isCardControl}
                      />
                      <NetworkForm.Select
                        formItemOptions={{
                          name: `${groupCodesCode}${group.id}`,
                          label: "Group Values",
                          initialValue: [],
                          className: "w-p-75",
                        }}
                        options={MCC_LIST.map(x => ({ label: x.value?.toString(), value: x.key?.toString() }))}
                        showSearch={true}
                        mode="multiple"
                        allowClear={true}
                        disabled={!isCardControl}
                      />
                    </Space>
                    <Button
                      type="text"
                      danger={true}
                      onClick={() => {
                        deleteGroup(group.id);
                      }}
                      disabled={!isCardControl}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Flex>
                ))}

              <Button type="link" disabled={!isCardControl} icon={<PlusOutlined />} onClick={addGroup}>
                Add group
              </Button>
            </Space>
          </PageItemLayoutGroup>
        </div>
      </div>
    </CustomFormWrapper>
  );
};
