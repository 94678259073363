import { FC } from "react";
import { Form } from "antd";

import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import { useInsuranceApi } from "../../../../hooks";

import { actionOption, coverageOption, insuranceCoverOption } from "./constants";

export const CoverageSettings: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentInsurance, editInsurance, formValuesConverter } = useInsuranceApi();

  useHydrateForm({
    form,
    entityFields: currentInsurance?.values ?? [],
    keys: {
      strings: [actionOption.code, coverageOption.code, insuranceCoverOption.code],
    },
    allowParse: false,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({ values: formValuesConverter(values) });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Insurance Coverage Settings"
      pageSubtitle="Specify the insured balances' eligible components, you can also identify maximum coverage amount which will impact the truncation indicator and the premium amount calculation."
      level="tenant"
      size="md"
      formSize="full"
      gap={40}
      submitLabel="Save"
      linkTitle="Insurance Templates"
      submitHandler={onFinish}
      additionalRoute="./insurance-program-templates"
    >
      <NetworkForm.Number
        formItemOptions={{
          name: coverageOption.code,
          label: coverageOption.name,
          tooltip: coverageOption.tooltip,
        }}
        addonAfter={currentInsurance.currency as string}
        min={coverageOption.min}
        precision={coverageOption.decimalsNumber}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: insuranceCoverOption.code,
          label: insuranceCoverOption.name,
          initialValue: insuranceCoverOption.default,
        }}
        radioList={insuranceCoverOption.options}
        initialValue={insuranceCoverOption.default as string}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: actionOption.code,
          label: actionOption.name,
          initialValue: actionOption.default,
          tooltip: actionOption.tooltip,
        }}
        radioList={actionOption.options}
        initialValue={actionOption.default as string}
      />
    </CustomFormWrapper>
  );
};
