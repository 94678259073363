export const checkRuleKeyMatch = (str: string, minNumber: number, maxNumber: number): boolean => {
  const regex = /^nic-usage-paym-u(\d+)-rules$/;
  const match = str.match(regex);

  if (match) {
    const number = parseInt(match[1], 10);
    return number >= minNumber && number <= maxNumber;
  }

  return false;
};
