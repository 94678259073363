import React, { FC } from "react";
import { Space } from "antd";
import { SpaceProps } from "antd/lib/space";
import cn from "classnames";

import styles from "./PageItemLayoutElements.module.scss";

export const PageItemLayoutGeneral: FC<SpaceProps> = ({ children, className, direction, ...props }) => {
  return (
    <Space direction={direction || "vertical"} size={40} className={cn(styles["space"], className)} {...props}>
      {children}
    </Space>
  );
};
