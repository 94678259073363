import { WizardPageMock } from "@ni/common/types";

import {
  AccountLimitsPaymentPage,
  AdditionalServicesPage,
  ApiPage,
  BalanceInquiryFeePage,
  CardLimitsPaymentPage,
  CardNumberingPage,
  CardProductionFeesPage,
  CardProductionPage,
  CardTransactionFeesPage,
  ClientTypeSelectionPage,
  CompleteProductBoardingPage,
  ContractAutoClosurePage,
  ContractPurgePage,
  CreditLimit,
  CreditLimitModificationFeesPage,
  CurrencyConversionsFeesPage,
  DeclineFeePage,
  FinishCardProductionPage,
  GeneralFeesPresetPage,
  InactivityFeesPage,
  InterestCalculation,
  InterestGracePage,
  InterfacesPage,
  IslamicCreditMurabahaPage,
  IslamicCreditProfitPage,
  IslamicCreditTawarruqPage,
  JoiningFeePage,
  LatePaymentFeePage,
  ManualClosurePage,
  MembershipFeesPage,
  MinimumToPayFormulaPage,
  MultiCurrencyPage,
  OverlimitFeePage,
  PaymentDuePage,
  PaymentFeesPage,
  PaymentLimitsPresetPage,
  ProductDetailsPage,
  ProductTypeSelectionPage,
  ReportsPage,
  StatementFeePage,
  TenantRegistrationPage,
  TopupAccounLimitsPage,
  TopupCardLimitsPage,
  TopupFeesPage,
  TopupLimitsPresetPage,
  TransactionAccountLimitsBillingPage,
  TransactionAccountLimitsPage,
  TransactionCardLimitsBillingPage,
  TransactionCardLimitsMountlyPage,
  TransactionCardLimitsPage,
  TransactionFeesPresetPage,
  TransactionLimitsPresetPage,
  TransactionRestrictionsPage,
} from "../pages";

export const WIZARD_PAGES: WizardPageMock[] = [
  { pageKey: "TEN_BASE", component: TenantRegistrationPage },
  { pageKey: "ADD_SERV", component: AdditionalServicesPage },
  { pageKey: "CLIENT_TYPE", component: ClientTypeSelectionPage },
  { pageKey: "PROD_TYPE", component: ProductTypeSelectionPage },
  { pageKey: "PROD_BASE", component: ProductDetailsPage },
  { pageKey: "PROD_MULTI_D", component: MultiCurrencyPage },
  { pageKey: "CARD_NUMB", component: CardNumberingPage },
  { pageKey: "MTP_PERC", component: PaymentDuePage },
  { pageKey: "CR_LIM", component: CreditLimit },
  { pageKey: "INT_RET_CASH", component: InterestCalculation },
  { pageKey: "CARD_PROD_INIT", component: CardProductionPage },
  { pageKey: "CARD_PROD", component: FinishCardProductionPage },
  { pageKey: "MTP", component: MinimumToPayFormulaPage },
  { pageKey: "OVL_FEE", component: OverlimitFeePage },
  { pageKey: "CRD_L_M_FEE", component: CreditLimitModificationFeesPage },
  { pageKey: "INT_GRACE", component: InterestGracePage },
  { pageKey: "ISLM_CRED", component: IslamicCreditProfitPage },
  { pageKey: "ISLM_PROFIT_MURAB", component: IslamicCreditMurabahaPage },
  { pageKey: "ISLM_PROFIT_TAWAR", component: IslamicCreditTawarruqPage },
  { pageKey: "LATPYMT_FEE", component: LatePaymentFeePage },
  { pageKey: "TRANS_RESTR", component: TransactionRestrictionsPage },
  { pageKey: "TRANS_LIM_PRESET", component: TransactionLimitsPresetPage },
  { pageKey: "TRANS_ACC_D_LIM", component: TransactionAccountLimitsPage },
  { pageKey: "TRANS_ACC_B_LIM", component: TransactionAccountLimitsBillingPage },
  { pageKey: "TRANS_CARD_D_LIM", component: TransactionCardLimitsPage },
  { pageKey: "TRANS_CARD_M_LIM", component: TransactionCardLimitsMountlyPage },
  { pageKey: "TRANS_CARD_B_LIM", component: TransactionCardLimitsBillingPage },
  { pageKey: "PAYM_LIM_PRESET", component: TopupLimitsPresetPage },
  { pageKey: "ACC_PAYM_LIM", component: TopupAccounLimitsPage },
  { pageKey: "CARD_PAYM_LIM", component: TopupCardLimitsPage },
  { pageKey: "GEN_FEE_PRESET", component: GeneralFeesPresetPage },
  { pageKey: "MEMB_FEE", component: MembershipFeesPage },
  { pageKey: "JOIN_FEE", component: JoiningFeePage },
  { pageKey: "CP_FEE", component: CardProductionFeesPage },
  { pageKey: "STMT_FEE", component: StatementFeePage },
  { pageKey: "INACT_FEE", component: InactivityFeesPage },
  { pageKey: "TRANS_FEE_PRESET", component: TransactionFeesPresetPage },
  { pageKey: "TRANS_FEE", component: CardTransactionFeesPage },
  { pageKey: "LOAD_FEE", component: TopupFeesPage },
  { pageKey: "FX_FEE", component: CurrencyConversionsFeesPage },
  { pageKey: "BALANCE_FEE", component: BalanceInquiryFeePage },
  { pageKey: "PAYM_FEE", component: PaymentFeesPage },
  { pageKey: "DECL_FEE", component: DeclineFeePage },
  { pageKey: "MAN_CLOS", component: ManualClosurePage },
  { pageKey: "AUTO_CLOS", component: ContractAutoClosurePage },
  { pageKey: "PURGE", component: ContractPurgePage },
  { pageKey: "API", component: ApiPage },
  { pageKey: "INTERF", component: InterfacesPage },
  { pageKey: "REP", component: ReportsPage },
  { pageKey: "8_SUCCESS_PAGE", component: CompleteProductBoardingPage },
  { pageKey: "PAYM_LIM_CRED_PRESET", component: PaymentLimitsPresetPage },
  { pageKey: "ACC_PAYM_CRED_LIM", component: AccountLimitsPaymentPage },
  { pageKey: "CARD_PAYM_CRED_LIM", component: CardLimitsPaymentPage },
];
