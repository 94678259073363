const defaults = {
  // craco-config
  borderRadiusBase: 8,
  borderRadiusSm: 6,
  fontFamily: `Gotham, -apple-system, BlinkMacSystemFont, 'Nunito Sans', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji'`,
  heightSm: 24,
  heightBase: 32,
  heightLg: 48,
  // other
  siderTheme: "light",
  paddingBase: 16,
  checkboxBorderRadius: 2,
  formVerticalSpacing: 24,
  formHorizontalSpacing: 20,
  formInputWidth: 340,
  formButtonWidth: 160,
  breakpoints: {
    xs: 414,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1440,
  },
};

module.exports = defaults;
