import React, { ReactNode, useMemo, useState } from "react";
import { Space, Table, TableColumnType, Tabs, TabsProps, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { BaseType } from "antd/es/typography/Base";

import { Tab } from "@ni/common/types";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

type TabKey = "a" | "b" | "c" | "d";

interface InnerTableData {
  mainDescription: string;
  scenarioDescription: string;
  cycles: {
    data: DataType[];
    summary: SummaryType;
  }[];
}
export interface TableData {
  rates?: { label: string; value: string }[];
  scenarios: Partial<TabsProps["items"] & InnerTableData & { key: string; label: string }>[];
}

interface DataType {
  [x: string]: ReactNode;
}

interface SummaryType {
  title?: string;
  tooltip?: string;
  data: {
    type: string;
    value: string;
  }[];
}

const { Text } = Typography;

const Summary = ({ data, columns }: { data: SummaryType; columns: TableColumnType<DataType>[] }) => {
  return (
    data &&
    data.data?.length > 0 && (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>
          <Text strong={true}>
            <TooltipInfo
              label={data.title ?? ""}
              tooltipProps={{ className: "tooltip-title", title: data.tooltip ?? "" }}
            />
          </Text>
        </Table.Summary.Cell>
        {columns
          .filter(col => col.dataIndex !== "subTitle")
          .map((value, index) => (
            <Table.Summary.Cell key={value.dataIndex as string} index={index}>
              <Text strong={true} type={data.data[index]?.type as BaseType}>
                {data.data[index]?.value}
              </Text>
            </Table.Summary.Cell>
          ))}
      </Table.Summary.Row>
    )
  );
};

export const ScenariosTable = React.memo(
  ({ tableData, columns }: { tableData: TableData; columns: TableColumnType<DataType>[] }) => {
    const [activeTab, setActiveTab] = useState<TabKey>("a");

    const currentTab = useMemo(
      () => tableData.scenarios.find(x => x.key === activeTab),
      [activeTab, tableData.scenarios],
    );

    const { columns1 }: { columns1: ColumnsType<DataType> } = useMemo(() => {
      return {
        columns1: [
          {
            title: (
              <div className={styles["table-header-section"]}>
                <div className={styles["row-main-description-table"]}>{currentTab?.mainDescription}</div>
                {tableData.rates && (
                  <Space direction="horizontal" split={<div />} className={styles["table-header-section-rate"]}>
                    {tableData.rates.map(rate => (
                      <span>
                        {rate.label} = {rate.value}
                      </span>
                    ))}
                    <span>
                      <TooltipInfo
                        tooltipProps={{
                          title: "The values provided are solely for the purpose of the scenarios demonstration",
                          className: "tooltip-title",
                        }}
                      />
                    </span>
                  </Space>
                )}
              </div>
            ),
            children: [
              {
                title: (
                  <div id="scenario-tabs-container" className="ni-tabs">
                    <Tabs
                      inputMode="text"
                      defaultActiveKey="a"
                      activeKey={activeTab}
                      onChange={key => setActiveTab(key as TabKey)}
                      items={tableData?.scenarios as unknown as Tab<TabKey>[]}
                      className={styles["table-tabs-section"]}
                    />
                    <div className={styles["table-tabs-section-description"]}>
                      <span>{currentTab?.scenarioDescription}</span>
                    </div>
                  </div>
                ),
                children: [
                  {
                    title: <div className={styles["row-title-table"]}>Billing Cycle 1</div>,
                    children: columns,
                  },
                ],
              },
            ],
          },
        ],
      };
    }, [
      activeTab,
      columns,
      currentTab?.mainDescription,
      currentTab?.scenarioDescription,
      tableData?.rates,
      tableData.scenarios,
    ]);

    return (
      currentTab?.cycles &&
      currentTab?.cycles?.length > 0 && (
        <Table
          rowKey="subTitle"
          columns={columns1}
          dataSource={currentTab.cycles?.[0].data}
          pagination={false}
          size="large"
          bordered={true}
          scroll={{ x: true }}
          summary={() => (
            <>
              <Summary data={currentTab?.cycles?.[0].summary ?? ({} as SummaryType)} columns={columns} />
              {currentTab.cycles?.slice(1).map((cycle, index) => (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={6} colSpan={columns.length} className={styles["extended-Table-Container"]}>
                    <Table
                      rowKey="subTitle"
                      columns={[
                        {
                          title: <div className={styles["row-title-table"]}>Billing Cycle {index + 2}</div>,
                          children: columns,
                        },
                      ]}
                      dataSource={cycle?.data}
                      pagination={false}
                      size="large"
                      bordered={true}
                      summary={() => <Summary data={cycle.summary ?? {}} columns={columns} />}
                    />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              ))}
            </>
          )}
        />
      )
    );
  },
);
