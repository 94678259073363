/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyInsuranceProgramTemplateRequest } from '../model';
// @ts-ignore
import { ChangeInsuranceProgramTemplateRequest } from '../model';
// @ts-ignore
import { CopyLoyaltyProgramTemplateRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InsuranceProgram } from '../model';
// @ts-ignore
import { InsuranceProgramTemplate } from '../model';
/**
 * InsuranceTemplateApi - axios parameter creator
 * @export
 */
export const InsuranceTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Applies Insurance Template to selected products
         * @summary Apply Insurance Template for selected products
         * @param {ApplyInsuranceProgramTemplateRequest} applyInsuranceProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToProducts1: async (applyInsuranceProgramTemplateRequest: ApplyInsuranceProgramTemplateRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyInsuranceProgramTemplateRequest' is not null or undefined
            assertParamExists('applyToProducts1', 'applyInsuranceProgramTemplateRequest', applyInsuranceProgramTemplateRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applyToProducts1', 'id', id)
            const localVarPath = `/api/v1/insurance-program-templates/{id}/insurance-programs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyInsuranceProgramTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies Insurance Template by ID with new DisplayName and returns created one
         * @summary Copy Insurance Template by OriginalId
         * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy3: async (copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyLoyaltyProgramTemplateRequest' is not null or undefined
            assertParamExists('copy3', 'copyLoyaltyProgramTemplateRequest', copyLoyaltyProgramTemplateRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copy3', 'id', id)
            const localVarPath = `/api/v1/insurance-program-templates/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyLoyaltyProgramTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Insurance Template by ID
         * @param {ChangeInsuranceProgramTemplateRequest} changeInsuranceProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit4: async (changeInsuranceProgramTemplateRequest: ChangeInsuranceProgramTemplateRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeInsuranceProgramTemplateRequest' is not null or undefined
            assertParamExists('edit4', 'changeInsuranceProgramTemplateRequest', changeInsuranceProgramTemplateRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('edit4', 'id', id)
            const localVarPath = `/api/v1/insurance-program-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeInsuranceProgramTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Insurance Template by ID
         * @summary Get Insurance Template by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById3: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById3', 'id', id)
            const localVarPath = `/api/v1/insurance-program-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceTemplateApi - functional programming interface
 * @export
 */
export const InsuranceTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Applies Insurance Template to selected products
         * @summary Apply Insurance Template for selected products
         * @param {ApplyInsuranceProgramTemplateRequest} applyInsuranceProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyToProducts1(applyInsuranceProgramTemplateRequest: ApplyInsuranceProgramTemplateRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuranceProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyToProducts1(applyInsuranceProgramTemplateRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copies Insurance Template by ID with new DisplayName and returns created one
         * @summary Copy Insurance Template by OriginalId
         * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copy3(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProgramTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copy3(copyLoyaltyProgramTemplateRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Insurance Template by ID
         * @param {ChangeInsuranceProgramTemplateRequest} changeInsuranceProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit4(changeInsuranceProgramTemplateRequest: ChangeInsuranceProgramTemplateRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProgramTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit4(changeInsuranceProgramTemplateRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Insurance Template by ID
         * @summary Get Insurance Template by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById3(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProgramTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById3(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsuranceTemplateApi - factory interface
 * @export
 */
export const InsuranceTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceTemplateApiFp(configuration)
    return {
        /**
         * Applies Insurance Template to selected products
         * @summary Apply Insurance Template for selected products
         * @param {ApplyInsuranceProgramTemplateRequest} applyInsuranceProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToProducts1(applyInsuranceProgramTemplateRequest: ApplyInsuranceProgramTemplateRequest, id: number, options?: any): AxiosPromise<Array<InsuranceProgram>> {
            return localVarFp.applyToProducts1(applyInsuranceProgramTemplateRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies Insurance Template by ID with new DisplayName and returns created one
         * @summary Copy Insurance Template by OriginalId
         * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy3(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: any): AxiosPromise<InsuranceProgramTemplate> {
            return localVarFp.copy3(copyLoyaltyProgramTemplateRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Insurance Template by ID
         * @param {ChangeInsuranceProgramTemplateRequest} changeInsuranceProgramTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit4(changeInsuranceProgramTemplateRequest: ChangeInsuranceProgramTemplateRequest, id: number, options?: any): AxiosPromise<InsuranceProgramTemplate> {
            return localVarFp.edit4(changeInsuranceProgramTemplateRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Insurance Template by ID
         * @summary Get Insurance Template by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById3(id: number, options?: any): AxiosPromise<InsuranceProgramTemplate> {
            return localVarFp.getById3(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceTemplateApi - interface
 * @export
 * @interface InsuranceTemplateApi
 */
export interface InsuranceTemplateApiInterface {
    /**
     * Applies Insurance Template to selected products
     * @summary Apply Insurance Template for selected products
     * @param {ApplyInsuranceProgramTemplateRequest} applyInsuranceProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApiInterface
     */
    applyToProducts1(applyInsuranceProgramTemplateRequest: ApplyInsuranceProgramTemplateRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Array<InsuranceProgram>>;

    /**
     * Copies Insurance Template by ID with new DisplayName and returns created one
     * @summary Copy Insurance Template by OriginalId
     * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApiInterface
     */
    copy3(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<InsuranceProgramTemplate>;

    /**
     * 
     * @summary Edit Insurance Template by ID
     * @param {ChangeInsuranceProgramTemplateRequest} changeInsuranceProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApiInterface
     */
    edit4(changeInsuranceProgramTemplateRequest: ChangeInsuranceProgramTemplateRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<InsuranceProgramTemplate>;

    /**
     * Returns Insurance Template by ID
     * @summary Get Insurance Template by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApiInterface
     */
    getById3(id: number, options?: AxiosRequestConfig): AxiosPromise<InsuranceProgramTemplate>;

}

/**
 * InsuranceTemplateApi - object-oriented interface
 * @export
 * @class InsuranceTemplateApi
 * @extends {BaseAPI}
 */
export class InsuranceTemplateApi extends BaseAPI implements InsuranceTemplateApiInterface {
    /**
     * Applies Insurance Template to selected products
     * @summary Apply Insurance Template for selected products
     * @param {ApplyInsuranceProgramTemplateRequest} applyInsuranceProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApi
     */
    public applyToProducts1(applyInsuranceProgramTemplateRequest: ApplyInsuranceProgramTemplateRequest, id: number, options?: AxiosRequestConfig) {
        return InsuranceTemplateApiFp(this.configuration).applyToProducts1(applyInsuranceProgramTemplateRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies Insurance Template by ID with new DisplayName and returns created one
     * @summary Copy Insurance Template by OriginalId
     * @param {CopyLoyaltyProgramTemplateRequest} copyLoyaltyProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApi
     */
    public copy3(copyLoyaltyProgramTemplateRequest: CopyLoyaltyProgramTemplateRequest, id: number, options?: AxiosRequestConfig) {
        return InsuranceTemplateApiFp(this.configuration).copy3(copyLoyaltyProgramTemplateRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Insurance Template by ID
     * @param {ChangeInsuranceProgramTemplateRequest} changeInsuranceProgramTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApi
     */
    public edit4(changeInsuranceProgramTemplateRequest: ChangeInsuranceProgramTemplateRequest, id: number, options?: AxiosRequestConfig) {
        return InsuranceTemplateApiFp(this.configuration).edit4(changeInsuranceProgramTemplateRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Insurance Template by ID
     * @summary Get Insurance Template by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceTemplateApi
     */
    public getById3(id: number, options?: AxiosRequestConfig) {
        return InsuranceTemplateApiFp(this.configuration).getById3(id, options).then((request) => request(this.axios, this.basePath));
    }
}
