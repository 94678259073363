import React, { FC, useMemo } from "react";

import { amex, defaultScheme, mc, upi, visa } from "@ni/common/assets";
import { paymentMethodKeyToValueMapper } from "@ni/common/mocks";

import styles from "./style.module.scss";

interface PaymentSchemeCellProps {
  ips: string | undefined;
  paymentScheme: string | undefined;
}

export const PaymentSchemeCell: FC<PaymentSchemeCellProps> = ({ ips, paymentScheme }) => {
  const paymentMethodsMapper = paymentMethodKeyToValueMapper as { [key: string]: string };

  const getProductMiniIps = useMemo((): string => {
    if (ips?.startsWith("Visa")) {
      return visa;
    }
    if (ips === "MC") {
      return mc;
    }
    if (ips === "Union Pay") {
      return upi;
    }
    if (ips === "American Express") {
      return amex;
    }
    return defaultScheme;
  }, [ips]);

  return (
    <div className={styles["payment-scheme-cell"]}>
      <img alt="mini-ips" src={getProductMiniIps} />
      <p className={styles["payment-scheme-name"]}>{paymentMethodsMapper[paymentScheme as string] || ""}</p>
    </div>
  );
};
