import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { workflowDashboardPageConfig } from "@ni/common/mocks";
import { Sidenav } from "@ni/common/ui";
import { Phase } from "@ni/phase";
import { WorkflowApi } from "@ni/sdk/apis";
import { DashboardWorkflow, Workflow } from "@ni/sdk/models";

import WorkflowDetails from "../../components/WorkflowDetails";
import { existingWorkflowSettingsItems, newWorkflowSettingsItems } from "../../constants";

import styles from "./styles.module.scss";

const workflowServiceApi = new WorkflowApi();

export const WorkflowSettings: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [workflows, setWorkflows] = useReduxState<DashboardWorkflow[]>("workflows", []);

  const [, setWorkflowsLoading] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const getWorkflows = useCallback((): void => {
    setWorkflowsLoading(true);

    workflowServiceApi
      .getWorkflows(workflowDashboardPageConfig)
      .then(response => {
        setWorkflows(response.data.content as DashboardWorkflow[]);
        setWorkflowsLoading(false);
      })
      .catch(() => {
        setWorkflowsLoading(false);
      });
  }, [setWorkflows, setWorkflowsLoading]);

  useEffect(() => {
    getWorkflows();
  }, [getWorkflows]);

  const onChangeWorkflow = (workflowId: number): void => {
    if (location.pathname.includes("/phases")) {
      navigate(`/admin/workflows/${workflowId}/phases`);
    } else {
      navigate(`/admin/workflows/${workflowId}`);
    }
  };

  const isShowPhases = useMemo(() => {
    if (id) {
      return true;
    }

    return false;
  }, [id]);

  return (
    <div className={styles["workflow-setting-layout-wrapper"]}>
      <div className={styles["workflow-sidenav"]}>
        <Sidenav
          disabled={!isShowPhases}
          items={isShowPhases ? existingWorkflowSettingsItems : newWorkflowSettingsItems}
        >
          <Select onChange={onChangeWorkflow} value={Number(id) || null} className={styles["workflow-select"]}>
            {workflows.map((t: Workflow) => (
              <Select.Option key={t.id} value={t.id} className="selected-option">
                <div className={styles["select-wrapper"]}>
                  <div className={styles["select-label"]} title={t.code}>
                    {t.id}
                  </div>
                  <div className={styles["select-label-code"]} title={t.code}>
                    {t.code}
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Sidenav>
      </div>
      <div className={styles["page-content"]}>
        <Routes>
          <Route path="/phases" element={<Phase />} />
          <Route path="/" element={<WorkflowDetails existing={true} workflows={workflows} />} />
          <Route path="/create" element={<WorkflowDetails existing={false} workflows={workflows} />} />
        </Routes>
      </div>
    </div>
  );
};
