import { ChangeEvent, FC, forwardRef, useLayoutEffect } from "react";
import { Input } from "antd";
import { TextAreaProps, TextAreaRef } from "antd/es/input/TextArea";

import styles from "./styles.module.scss";

const { TextArea: AntdTextArea } = Input;

interface FormulaTextAreaProps extends TextAreaProps {
  balance?: string;
  mode?: string;
  percentage?: string;
  isIslamicCredit?: boolean;
  stringGenerator?: () => string;
}

const getBalancesContributingPercentage = (balance: string | undefined, isIslamicCredit: boolean | undefined) => {
  const interestType = isIslamicCredit ? "Finance Profits" : "Interest";

  const BALANCES_CONTRIBUTING_PERCENTAGE: Record<string, string> = {
    FEEINT_INS_PERC: `MTP Amount = (Principle Amount + ${interestType} + Fees + Insurance) *`,
    FEEINT_PERC: `MTP Amount = (Principle Amount + ${interestType} + Fees) *`,
    ALL_FULL: `MTP Amount = (Principle Amount + ${interestType}) *`,
    FEEINT_FULL: "MTP Amount = Principle Amount *",
  };

  return BALANCES_CONTRIBUTING_PERCENTAGE[balance ?? ""];
};

const getBalancesContributingFully = (balance: string | undefined) => {
  const BALANCES_CONTRIBUTING_FULLY: Record<string, string> = {
    FEEINT_INS_PERC: "+ IPP + VAT",
    FEEINT_PERC: "+ IPP + VAT + Insurance",
    ALL_FULL: "+ IPP + VAT + Insurance + Fees",
    FEEINT_FULL: `+ All balance components except loan`,
  };

  return BALANCES_CONTRIBUTING_FULLY[balance ?? ""];
};

export const FormulaTextArea: FC<FormulaTextAreaProps & React.RefAttributes<TextAreaRef>> = forwardRef(
  ({ balance, mode, percentage, isIslamicCredit, onChange, stringGenerator, ...props }: FormulaTextAreaProps, ref) => {
    useLayoutEffect(() => {
      const formula = stringGenerator
        ? stringGenerator()
        : `${getBalancesContributingPercentage(balance, isIslamicCredit)}${
            balance !== "ALL_FULL" ? ` ${percentage}% ` : " "
          }${getBalancesContributingFully(balance)}${mode === "S" ? " + Overlimit" : ""}`;
      if (formula && onChange) onChange(formula as unknown as ChangeEvent<HTMLTextAreaElement>);
    }, [balance, isIslamicCredit, mode, onChange, percentage, stringGenerator]);

    return (
      <div className={styles["text-area-container"]}>
        <AntdTextArea
          ref={ref}
          {...props}
          className={styles["text-area-component"]}
          autoSize={{ minRows: 3 }}
          disabled={true}
        />
      </div>
    );
  },
);
