import { FC } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { usePct } from "@ni/product-settings";

import {
  canChargeWhenIncreasingPermanentLimit,
  canChargeWhenTemporaryExceedsPermanent,
  permanentLimitIncreaseFeeAmount,
  temporaryLimitFeeAmount,
} from "./constants";

export const CreditLimitModificationFeesPage: FC = () => {
  const { productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [form] = Form.useForm<FormValues>();

  const { productCurrency: currencyType } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  useHydrateForm({
    form,
    entityFields: pct.pctProductValues ?? [],
    keys: {
      strings: [
        canChargeWhenIncreasingPermanentLimit.code,
        canChargeWhenTemporaryExceedsPermanent.code,
        permanentLimitIncreaseFeeAmount.code,
        temporaryLimitFeeAmount.code,
      ],
    },
    allowParse: true,
  });

  const hasChargeWhenTemporaryExceed = Form.useWatch<boolean>(canChargeWhenTemporaryExceedsPermanent.code, form);
  const hasChargeWhenIncreasingPermanent = Form.useWatch<boolean>(canChargeWhenIncreasingPermanentLimit.code, form);

  const onFinish = (values: FormValues) => {
    void onSavePct({
      [temporaryLimitFeeAmount.code]: "",
      [permanentLimitIncreaseFeeAmount.code]: "",
      ...values,
    } as FormValues);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Credit Limit Modification Fees"
      pageSubtitle="Credit limit modification fee can be charged when a client requests an increase or decrease in their permanent or temporary credit limit."
      size="md"
      formSize="full"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: canChargeWhenTemporaryExceedsPermanent.code,
          label: canChargeWhenTemporaryExceedsPermanent.name,
          tooltip: canChargeWhenTemporaryExceedsPermanent.tooltip,
          initialValue: canChargeWhenTemporaryExceedsPermanent.default,
          valuePropName: "checked",
        }}
      />

      {hasChargeWhenTemporaryExceed && (
        <NetworkForm.Number
          addonAfter={currencyType}
          min={temporaryLimitFeeAmount.min}
          precision={temporaryLimitFeeAmount.decimalsNumber}
          formItemOptions={{
            name: temporaryLimitFeeAmount.code,
            label: temporaryLimitFeeAmount.name,
            rules: [
              {
                validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                message: "Minimum fee amount can not be 0",
              },
              { required: true, message: "Temporary Limit Fee Amount is required!" },
            ],
          }}
        />
      )}

      <NetworkForm.Switch
        formItemOptions={{
          name: canChargeWhenIncreasingPermanentLimit.code,
          label: canChargeWhenIncreasingPermanentLimit.name,
          tooltip: canChargeWhenIncreasingPermanentLimit.tooltip,
          initialValue: canChargeWhenIncreasingPermanentLimit.default,
          valuePropName: "checked",
        }}
      />

      {hasChargeWhenIncreasingPermanent && (
        <NetworkForm.Number
          addonAfter={currencyType}
          min={permanentLimitIncreaseFeeAmount.min}
          precision={permanentLimitIncreaseFeeAmount.decimalsNumber}
          formItemOptions={{
            name: permanentLimitIncreaseFeeAmount.code,
            label: permanentLimitIncreaseFeeAmount.name,
            rules: [
              {
                validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                message: "Minimum fee amount can not be 0",
              },
              { required: true, message: "Permanent Limit Increase Fee Amount is required!" },
            ],
          }}
        />
      )}
    </CustomFormWrapper>
  );
};
