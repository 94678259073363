import { ReactNode } from "react";
import { Button, Form, Modal } from "antd";

import styles from "./styles.module.scss";

interface SelectOptionModalProps {
  isOpened: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  children: ReactNode;
  onChange: (currentValue: { [key: string]: string }, value: { [key: string]: string }) => void;
}

export const SelectOptionModal = ({
  isOpened,
  isLoading,
  onCancel,
  onConfirm,
  children,
  onChange,
}: SelectOptionModalProps) => {
  const [form] = Form.useForm();

  return (
    <Modal
      className={styles["modal-select-option"]}
      title="Select Element Template"
      open={isOpened}
      closable={true}
      width={1000}
      onCancel={onCancel}
      footer={[
        <div className={styles["modal-select-option-footer"]}>
          <Button className={styles["modal-select-option-button"]} key="back" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className={styles["modal-select-option-button"]}
            disabled={false}
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={form.submit}
          >
            Select
          </Button>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onConfirm} onValuesChange={onChange}>
        {children}
      </Form>
    </Modal>
  );
};
