import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { LastWiazrdSubmittedStep, WizardPlacement } from "@ni/common/types";
import { getPhaseAndPageOrder } from "@ni/common/utils";
import { Phase } from "@ni/sdk/models";

import { useReduxState } from "../store/useReduxState";

type WizardType = "product" | "loyalty" | "ipp" | "insurance";

type QueryWithCurrentEntityId = {
  loyaltyTemplateId: string;
  productId: string;
  planId: string;
  insuranceTemplateId: string;
};

interface UseWizardControllerProps {
  devMode?: boolean;
  wizardType: WizardType;
  lastSubmittedStep: LastWiazrdSubmittedStep;
  phases: Phase[];
  tenantIdQuery?: string;
  pageQuery?: string;
  productIdQuery?: string;
  loyaltyTemplateIdQuery?: string;
  planIdQuery?: string;
  insuranceTemplateIdQuery?: string;
  doesPageExist: boolean;
  processWizardRequest?: (tenantId?: string, entityId?: string) => Promise<void>;
}

export const useWizardController = ({
  devMode = false,
  wizardType,
  phases,
  lastSubmittedStep,
  tenantIdQuery,
  pageQuery,
  productIdQuery,
  loyaltyTemplateIdQuery,
  insuranceTemplateIdQuery,
  planIdQuery,
  doesPageExist,
  processWizardRequest,
}: UseWizardControllerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [wizardInitialized, setWizardInitialized] = useState(false);

  const [wizardPlacement, setWizardPlacement] = useState<WizardPlacement>({
    pageKey: pageQuery ?? "",
    phase: -1,
  });

  const prevLastSubmittedStep = useRef(lastSubmittedStep);

  const queryWithCurrentEntityId: QueryWithCurrentEntityId = useMemo(() => {
    const { currentEntityId } = lastSubmittedStep;
    switch (wizardType) {
      case "loyalty":
        if (!currentEntityId) return {} as QueryWithCurrentEntityId;

        return { loyaltyTemplateId: String(currentEntityId) } as QueryWithCurrentEntityId;

      case "product":
        if (!currentEntityId) return {} as QueryWithCurrentEntityId;

        return { productId: String(currentEntityId) } as QueryWithCurrentEntityId;

      case "ipp":
        if (!currentEntityId) return {} as QueryWithCurrentEntityId;

        return { planId: String(currentEntityId) } as QueryWithCurrentEntityId;

      case "insurance":
        if (!currentEntityId) return {} as QueryWithCurrentEntityId;

        return { insuranceTemplateId: String(currentEntityId) } as QueryWithCurrentEntityId;

      default:
        return {} as QueryWithCurrentEntityId;
    }
  }, [lastSubmittedStep, wizardType]);

  useEffect(() => {
    const initWizard = async () => {
      if (wizardInitialized || !processWizardRequest) return;

      try {
        setIsLoading(true);
        await processWizardRequest(
          tenantIdQuery,
          productIdQuery || loyaltyTemplateIdQuery || planIdQuery || insuranceTemplateIdQuery,
        );
        setWizardInitialized(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    void initWizard();
  }, [
    wizardInitialized,
    processWizardRequest,
    tenantIdQuery,
    setIsLoading,
    productIdQuery,
    loyaltyTemplateIdQuery,
    planIdQuery,
    insuranceTemplateIdQuery,
  ]);

  useEffect(() => {
    if (wizardInitialized && !devMode) {
      const { pageKey, phase } = lastSubmittedStep;
      const currentPhaseOrder = getPhaseAndPageOrder(phases, pageQuery ?? "")?.phaseOrder;
      const currentPageOrder = getPhaseAndPageOrder(phases, pageQuery ?? "")?.pageOrder;

      const isCurrentPhaseOrderLessOrEqual = typeof currentPhaseOrder === "number" && currentPhaseOrder <= phase;

      const isOnCurrentPhase = isCurrentPhaseOrderLessOrEqual && currentPhaseOrder === phase;

      const isOrderValid = isOnCurrentPhase
        ? currentPageOrder! <= getPhaseAndPageOrder(phases, pageKey)!.pageOrder
        : true;

      const isQueryValid = doesPageExist && isCurrentPhaseOrderLessOrEqual && isOrderValid;

      setWizardPlacement({
        pageKey: isQueryValid ? pageQuery! : pageKey,
        phase,
      });

      if (!isQueryValid) {
        const queries = new URLSearchParams({
          tenantId: String(tenantIdQuery),
          ...queryWithCurrentEntityId,
          page: pageKey,
        });

        navigate({
          pathname: location.pathname,
          search: queries.toString(),
        });
      }
    }
  }, [
    doesPageExist,
    lastSubmittedStep,
    location.pathname,
    navigate,
    pageQuery,
    phases,
    productIdQuery,
    tenantIdQuery,
    devMode,
    wizardInitialized,
    loyaltyTemplateIdQuery,
    planIdQuery,
    queryWithCurrentEntityId,
  ]);

  useEffect(() => {
    if (
      (prevLastSubmittedStep.current.pageKey === "" || prevLastSubmittedStep.current.phase === -1) &&
      (lastSubmittedStep.pageKey !== "" || lastSubmittedStep.phase !== -1)
    ) {
      prevLastSubmittedStep.current = lastSubmittedStep;
    }
  }, [lastSubmittedStep]);

  useEffect(() => {
    if (wizardInitialized) {
      if (
        prevLastSubmittedStep.current.pageKey !== "" &&
        prevLastSubmittedStep.current.phase !== -1 &&
        lastSubmittedStep.pageKey !== "" &&
        lastSubmittedStep.phase !== -1 &&
        !_.isEqual(prevLastSubmittedStep.current, lastSubmittedStep)
      ) {
        setWizardPlacement({
          pageKey: lastSubmittedStep.pageKey,
          phase: lastSubmittedStep.phase,
        });

        const queries = new URLSearchParams({
          tenantId: String(tenantIdQuery),
          ...queryWithCurrentEntityId,
          page: String(lastSubmittedStep.pageKey),
        });

        navigate({
          pathname: location.pathname,
          search: queries.toString(),
        });

        prevLastSubmittedStep.current = lastSubmittedStep;
      }
    }
  }, [lastSubmittedStep, location.pathname, navigate, queryWithCurrentEntityId, tenantIdQuery, wizardInitialized]);

  return [wizardPlacement, setWizardPlacement] as [
    WizardPlacement,
    React.Dispatch<React.SetStateAction<WizardPlacement>>,
  ];
};
