import { StoreValue } from "rc-field-form/lib/interface";

export const checkValueBetweenRangeValidator = (
  value: StoreValue,
  label: string,
  min: number,
  max: number,
): Promise<string | void> => {
  if (value && (value < min || value > max)) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject<string>(`${label} must be between ${min} and ${max}.`);
  }
  return Promise.resolve();
};
