import { FC, useEffect, useState } from "react";
import { Button, Drawer, Form } from "antd";
import cn from "classnames";

import { useReduxState } from "@ni/common/hooks";
import { onConditionChangeValidation } from "@ni/common/utils";
import { ElementTemplate } from "@ni/sdk/models";

import ElementTemplateEdit from "../ElementTemplateEdit";

import styles from "./styles.module.scss";

interface ElementTemplateDrawerProps {
  opened: boolean;
  closeDrawer: () => void;
  newElementTemplate: boolean;
  elementTemplate: ElementTemplate;
  isElementTypeDataCapture: boolean;
  getElementTemplates?: () => void;
}

const ElementTemplateDrawer: FC<ElementTemplateDrawerProps> = ({
  opened,
  closeDrawer,
  newElementTemplate,
  elementTemplate,
  isElementTypeDataCapture,
  getElementTemplates = () => {},
}) => {
  const [isLoading] = useReduxState<boolean>("isLoading");
  const [, setIsEnabled] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    setIsEnabled(newElementTemplate);
  }, [newElementTemplate]);

  const onSave = () => {};

  const onValueChange = (): void => {
    setIsEnabled(
      ((form.getFieldValue("hiddenConditions")?.length > 0 &&
        onConditionChangeValidation(String(form.getFieldValue("hiddenConditions")))) ||
        form.getFieldValue("hiddenConditions") === undefined ||
        form.getFieldValue("hiddenConditions")?.length === 0) &&
        ((form.getFieldValue("disabledConditions")?.length > 0 &&
          onConditionChangeValidation(String(form.getFieldValue("disabledConditions")))) ||
          form.getFieldValue("disabledConditions") === undefined ||
          form.getFieldValue("disabledConditions")?.length === 0),
    );
  };

  const newElementText = () => {
    if (isElementTypeDataCapture) {
      return "New Data Capture Element";
    }
    return "New Visual Element";
  };

  return (
    <Drawer
      title={!newElementTemplate ? `Edit Element Template "${elementTemplate?.label || ""}"` : newElementText()}
      placement="right"
      open={opened}
      width="99%"
      onClose={closeDrawer}
      keyboard={false}
      maskClosable={false}
      extra={
        <div className={styles["buttons"]}>
          <div className={cn(styles["coming-soon"], "alert-div")}>Coming soon</div>
          <Button
            disabled={true}
            loading={isLoading}
            className="page-save-button"
            type="primary"
            size="large"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onValuesChange={onValueChange}>
        <ElementTemplateEdit
          form={form}
          elementTemplate={elementTemplate}
          isElementTypeDataCapture={isElementTypeDataCapture}
          getElementTemplates={getElementTemplates}
        />
      </Form>
    </Drawer>
  );
};

export default ElementTemplateDrawer;
