import { FC, useEffect, useMemo, useState } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import {
  D_PAYM_C_TOTAL_ENABLED,
  D365_PAYM_C_TOTAL_ENABLED,
  M_PAYM_C_TOTAL_ENABLED,
  NIC_D_PAYM_C_TOTAL_AM,
  NIC_D_PAYM_C_TOTAL_NR,
  NIC_D_PAYM_C_TOTAL_SINGLE,
  NIC_D365_PAYM_C_TOTAL_AM,
  NIC_D365_PAYM_C_TOTAL_NR,
  NIC_D365_PAYM_C_TOTAL_SINGLE,
  NIC_M_PAYM_C_TOTAL_AM,
  NIC_M_PAYM_C_TOTAL_NR,
  NIC_M_PAYM_C_TOTAL_SINGLE,
  PAYM_B_ENABLED,
  PAYM_D_ENABLED,
  PAYM_D365_ENABLED,
  SERVICE_UNAVAILABLE,
} from "@ni/common/constants";
import { useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm } from "@ni/common/ui";
import { extractNumberFromString, getErrorInstance, parseBooleanOrNumber } from "@ni/common/utils";

import { RuleKeys } from "../../../../components";
import { usePct } from "../../../../hooks";
import { checkRuleKeyMatch, filterDisabledValues } from "../../../../utils";

interface CardLimitPageProps {
  isCredit: boolean;
}

export const CardLimitPage: FC<CardLimitPageProps> = ({ isCredit }) => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [existedRules, setExistedRules] = useState<RuleKeys[]>([]);

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id ?? 0}/pct/${pct.id ?? 0}/additional-rules`,
      label: `Additional rules configuration - ${pct?.displayName ?? ""} PCT`,
    },
  ];

  useEffect(() => {
    if (pct.pctProductValues) {
      const sortedExistedRules = (pct.pctProductValues ?? [])
        .filter(pctValue => checkRuleKeyMatch(pctValue.fieldCode, 1, 3))
        .map(pctValue => pctValue.fieldCode as RuleKeys)
        .sort((a, b) => (extractNumberFromString(a) ?? 0) - (extractNumberFromString(b) ?? 0));

      setExistedRules(sortedExistedRules);
    }
  }, [pct.pctProductValues]);

  useEffect(() => {
    pct.pctProductValues?.forEach(pctValue => {
      if (pctValue.fieldCode.includes("paym-c")) {
        form.setFieldsValue({
          [pctValue.fieldCode]: parseBooleanOrNumber(pctValue.value ?? ""),
        });
      }
    });
  }, [form, pct.pctProductValues]);

  const onFinish = async (values: FormValues): Promise<void> => {
    try {
      await onSavePct(filterDisabledValues(values, form));
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  const { perDayRules, perMonthRules, per365Rules } = useMemo(
    () => ({
      perDayRules: [
        {
          name: D_PAYM_C_TOTAL_ENABLED,
          label: "Total",
          maxNumber: NIC_D_PAYM_C_TOTAL_NR,
          maxAmount: NIC_D_PAYM_C_TOTAL_AM,
          maxSingleAmount: NIC_D_PAYM_C_TOTAL_SINGLE,
        },
        ...existedRules.map((rule, index) => ({
          name: `d-paym-c-u0${extractNumberFromString(rule) ?? 0}-enabled`,
          label: `Additional Rule ${extractNumberFromString(rule) ?? index + 1}`,
          maxNumber: `nic-d-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-nr`,
          maxAmount: `nic-d-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-am`,
          maxSingleAmount: `nic-d-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-single`,
        })),
      ],
      perMonthRules: [
        {
          name: M_PAYM_C_TOTAL_ENABLED,
          label: "Total",
          maxNumber: NIC_M_PAYM_C_TOTAL_NR,
          maxAmount: NIC_M_PAYM_C_TOTAL_AM,
          maxSingleAmount: NIC_M_PAYM_C_TOTAL_SINGLE,
        },
        ...existedRules.map((rule, index) => ({
          name: `m-paym-c-u0${extractNumberFromString(rule) ?? 0}-enabled`,
          label: `Additional Rule ${extractNumberFromString(rule) ?? index + 1}`,
          maxNumber: `nic-m-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-nr`,
          maxAmount: `nic-m-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-am`,
          maxSingleAmount: `nic-m-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-single`,
        })),
      ],
      per365Rules: [
        {
          name: D365_PAYM_C_TOTAL_ENABLED,
          label: "Total",
          maxNumber: NIC_D365_PAYM_C_TOTAL_NR,
          maxAmount: NIC_D365_PAYM_C_TOTAL_AM,
          maxSingleAmount: NIC_D365_PAYM_C_TOTAL_SINGLE,
        },
        ...existedRules.map((rule, index) => ({
          name: `d365-paym-c-u0${extractNumberFromString(rule) ?? 0}-enabled`,
          label: `Additional Rule ${extractNumberFromString(rule) ?? index + 1}`,
          maxNumber: `nic-d365-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-nr`,
          maxAmount: `nic-d365-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-am`,
          maxSingleAmount: `nic-d365-paym-c-u0${extractNumberFromString(rule) ?? index + 1}-single`,
        })),
      ],
    }),
    [existedRules],
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle={`Card Limits – ${isCredit ? "Payment" : "Top-up"}`}
      pageSubtitle="Card limits control payments to the particular card."
      submitHandler={onFinish}
      size="md"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      gap={24}
    >
      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name={PAYM_D_ENABLED}
        title="Per Day"
        tooltip="Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59)."
        rules={perDayRules}
      />

      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name={PAYM_B_ENABLED}
        title="Per Month"
        tooltip="Switch it off if restrictions are not intended per calendar month (from the 1st till the last day of the month)"
        rules={perMonthRules}
      />

      <DynamicSwitchForm
        form={form}
        currency={productCurrency}
        name={PAYM_D365_ENABLED}
        title="Per 365 Days"
        tooltip="365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended."
        rules={per365Rules}
      />
    </CustomFormWrapper>
  );
};
