import { FC } from "react";
import { Button, Drawer, Form, Typography } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { Page } from "@ni/sdk/models";

import PageEdit from "../PageEdit";

import styles from "./styles.module.scss";

const { Text } = Typography;

interface PageDrawerProps {
  opened: boolean;
  newPage: boolean;
  page: Page;
  closeDrawer: () => void;
  workflowId: number;
  phaseId: number;
  getPhases: () => void;
  pageItems: Page[];
}

const PageDrawer: FC<PageDrawerProps> = ({
  opened,
  closeDrawer,
  newPage,
  page,
  workflowId,
  phaseId,
  getPhases,
  pageItems,
}) => {
  const [isLoading] = useReduxState<boolean>("isLoading");

  const [form] = Form.useForm();

  const extraTitle = () => {
    return (
      <Text className={styles["page-name"]} ellipsis={{ tooltip: page?.header ?? "Page" }}>
        {!newPage ? `Edit "${page?.header || " "}" page` : `Add new page`}
      </Text>
    );
  };

  return (
    <Drawer
      title={extraTitle()}
      placement="right"
      open={opened}
      width="99%"
      onClose={closeDrawer}
      keyboard={false}
      maskClosable={false}
      extra={
        <div className={styles["buttons"]}>
          <Button
            loading={isLoading}
            disabled={true}
            className="page-save-button"
            size="large"
            onClick={() => alert("Work in progress")}
          >
            Page preview
          </Button>
        </div>
      }
    >
      <PageEdit
        opened={opened}
        form={form}
        page={page}
        workflowId={workflowId}
        phaseId={phaseId}
        getPhases={getPhases}
        newPage={newPage}
        pageItems={pageItems}
      />
    </Drawer>
  );
};

export default PageDrawer;
