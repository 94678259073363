import { forwardRef, ReactNode } from "react";
import { Form, Input, InputProps, InputRef, TooltipProps } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";

import { TooltipInfo } from "../../TooltipInfo";

export type NetworkStringProps = { formItemOptions?: NetWorkFormItemProps } & InputProps;

export const String = forwardRef<InputRef, NetworkStringProps>(({ formItemOptions, ...props }, ref) => {
  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        tooltip={undefined}
        label={
          <TooltipInfo
            label={formItemOptions?.label as string}
            code={formItemOptions?.name as string}
            tooltipProps={
              (formItemOptions?.tooltip
                ? typeof formItemOptions.tooltip === "string"
                  ? { title: formItemOptions?.tooltip }
                  : typeof formItemOptions.tooltip === "object" &&
                      (formItemOptions.tooltip as { title: ReactNode })?.title
                    ? { title: (formItemOptions.tooltip as { title: ReactNode }).title as ReactNode }
                    : formItemOptions.tooltip
                : {}) as TooltipProps
            }
          />
        }
      >
        <Input ref={ref} {...props} />
      </Form.Item>
    );
  }
  return <Input ref={ref} {...props} />;
});
