export const CHECKBOX_GROUP_TRANSACTION_FEES_PRESET = [
  {
    value: "C",
    label: "Card transaction fees",
    tooltip:
      "It is a charge for processing certain types of transactions to cover the costs include transaction authorization, clearing, settlement, network fees, and other operational expenses. This fee is applied on a per-transaction basis.",
  },
  {
    value: "T",
    label: "Top-up fees",
    tooltip:
      "It is charged when adding funds or reloading a prepaid card or account. It is a fee applied to the process of increasing the balance or value of the card or account.",
  },
  {
    value: "P",
    label: "Payment fees",
    tooltip:
      "It is charged for transactions which are made by the customers to clear the card outstanding balances. The payment amount could be any amount which reduces the current balance and increases the available amount.",
  },
  {
    value: "F",
    label: "Currency conversion fees",
    tooltip:
      "It is also known as a foreign exchange fee or forex fee, it is charged when a transaction involves converting one currency into another. It applies when a card is used to make a purchase or withdraw cash in a currency different from the card currency.",
  },
  {
    value: "B",
    label: "Balance inquiry fee",
    tooltip:
      "It is a fixed amount that can be charged to a cardholder in case of account balance inquiry through ATMs or POS or other designated channels.",
  },
  {
    value: "D",
    label: "Decline fee",
    tooltip:
      "It is charged when transaction is not approved by the issuer due to different reasons such as insufficient funds, incorrect PIN etc.",
  },
];
