import { useCallback, useEffect, useMemo } from "react";

import { ImportApi, QpApi } from "@ni/sdk/apis";
import { QpRowkey } from "@ni/sdk/models";

import { useReduxState } from "../store";

const qpApi = new QpApi();
const importApi = new ImportApi();

export const useImport = (shouldGetData = true) => {
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [qpRowkeys, setQpRowkeys] = useReduxState<QpRowkey[]>("importQpRowkeys", []);

  const tenants = useMemo(() => {
    return qpRowkeys.map(rowkey => ({
      value: rowkey.rowkey,
      label: `${rowkey.rowkey} - ${rowkey.name}`,
    }));
  }, [qpRowkeys]);

  const importFiApi = useCallback(
    async (externalCode: string) => {
      try {
        setIsLoading(true);
        if (externalCode) {
          // eslint-disable-next-line no-underscore-dangle
          await importApi.importOne(externalCode);
        } else {
          throw new Error();
        }
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    },
    [setIsLoading],
  );

  useEffect(() => {
    if (!qpRowkeys?.length && shouldGetData)
      void qpApi.getRowkey().then(response => setQpRowkeys(response?.data ?? []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldGetData]);

  return { isLoading, importFiApi, tenants };
};
