const blue = {
  50: "#F7FAFD",
  100: "#F2F5F7",
  200: "#E5EAEF",
  300: "#C8DBEA",
  400: "#AFCCE1",
  500: "#96BCD9",
  600: "#7DADD1",
  700: "#649EC9",
  800: "#4B8EC1",
  900: "#327FB8",
  1000: "#196FB0",
  1100: "#0060A8",
};
const red = {
  50: "#FCF7F8",
  100: "#FCF4F5",
  200: "#F8E8EB",
  300: "#F6D5DB",
  400: "#F4C3CC",
  500: "#F2B1BC",
  600: "#F09FAD",
  700: "#EE8C9E",
  800: "#EC7A8E",
  900: "#EA687F",
  1000: "#E8556F",
  1100: "#E64360",
};
const grey = {
  50: "#F3F3F4",
  100: "#EFEFEF",
  200: "#E4E4E4",
  300: "#CDCDCE",
  400: "#B7B7B9",
  500: "#A1A1A3",
  600: "#8A8A8D",
  700: "#747477",
  800: "#5E5E61",
  900: "#48484C",
  1000: "#313136",
  1100: "#1B1B20",
};

const defaults = {
  // craco-config
  primaryColor: red[1100],
  layoutBodyBackground: "#FFFFFF",
  componentBackground: "#FFFFFF",
  textColor: grey[1100],
  headingColor: grey[1100],
  successColor: "#53C4BB",
  infoColor: "#1890ff",
  warningColor: "#FA8701",
  errorColor: "#F5222D",
  borderColorBase: grey[300],
  inputHoverBorderColor: "#ABC8E9",
  tabsCardActiveColor: blue[1100],
  tabsInkBarColor: blue[600],
  tabsHighlightColor: blue[1100],
  tabsHoverColor: blue[600],
  tabsActiveColor: blue[600],
  // other
  disabledBlueBackground: blue[200],
  disabledBlueTextColor: grey[600],
  tableHighRiskTagText: red[1000],
  tableHighRiskTagBackground: red[200],
  tableNormalTagText: blue[800],
  tableNormalTagBackground: "#E6F7FF",
  agentStatusBlue: "#096DD9",
  agentStatusOrange: "#FA8701",
  agentStatusPurple: "#9254DE",
  agentStatusGreen: "#52C41A",
  agentStatusDark: "#404040",
};

module.exports = defaults;
