import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { PctParams } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues, GetProductWithId } from "@ni/common/types";
import { getRouteAfterProvidedRoute } from "@ni/common/utils";
import { ParametersTableApi } from "@ni/sdk/apis";
import {
  ChangeParameterTableRequest,
  CopyParameterTableRequest,
  ParameterTable,
  ParameterTableValue,
  Tenant,
} from "@ni/sdk/models";

import { prepareActualPctValues } from "../utils";

interface ObjectValue {
  fieldCode: string;
  value: string;
}

interface UsePctProps {
  pctId: number;
}

const parameterTableServicesApi = new ParametersTableApi();

const removePreviousValues = (objects: ObjectValue[]): ObjectValue[] => {
  const latestValuesMap = new Map<string, ObjectValue>();

  objects.forEach(obj => {
    latestValuesMap.set(obj.fieldCode, obj);
  });

  return Array.from(latestValuesMap.values());
};

export const usePct = ({ pctId }: UsePctProps) => {
  const location = useLocation();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant");
  const [currentProduct, setCurrentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);
  const [, setIsLoading] = useReduxState<boolean>("isLoading", false);

  const [selectedPctId, setSelectedPctId] = useState(0);

  const pct = useMemo(() => {
    const existingPctId = pctId || getRouteAfterProvidedRoute(location.pathname, "pct");
    return currentProduct.parameterTables?.find(productPct => productPct.id === +existingPctId) ?? {};
  }, [currentProduct.parameterTables, location.pathname, pctId]);

  const getValueFromPct = useCallback(
    (field: string) => pct.pctProductValues?.find(item => item.fieldCode === field)?.value || false,
    [pct.pctProductValues],
  );

  const onCreatePct = async (value: FormValues) => {
    setIsLoading(true);
    try {
      const defaultPct = currentProduct.parameterTables?.slice().sort((a, b) => (a.id ?? 0) - (b.id ?? 0))[0] ?? {};

      const body = {
        ...value,
        pctProductValues: defaultPct.pctProductValues,
      };

      const response = await parameterTableServicesApi.createParameterTable(body, currentProduct.id);

      setCurrentProduct({
        ...currentProduct,
        parameterTables: [...(currentProduct.parameterTables as ParameterTable[]), response.data],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onRenamePct = async (value: FormValues) => {
    setIsLoading(true);
    try {
      const response = await parameterTableServicesApi.putParameterTable(value, currentProduct.id, selectedPctId);
      const currentTempProduct = {
        ...currentProduct,
        parameterTables: currentProduct.parameterTables?.map((item: ParameterTable) => {
          if (item.id === response.data.id) {
            return response.data;
          }

          return item;
        }) as ParameterTable[],
      };

      setCurrentProduct(currentTempProduct);

      if (currentProduct && response?.data?.id && tenant) {
        setTenant({
          ...tenant,
          products: [...(tenant?.products?.filter(x => x.id !== currentProduct.id) ?? []), currentTempProduct],
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDuplicatePct = async (value: { [PctParams.displayName]: string }) => {
    setIsLoading(true);
    try {
      const productId = currentProduct.id || 0;
      const request: CopyParameterTableRequest = {
        newDisplayName: value[PctParams.displayName],
      };

      const response = await parameterTableServicesApi.copyParameterTable(request, productId, selectedPctId);

      setCurrentProduct({
        ...currentProduct,
        parameterTables: [...(currentProduct?.parameterTables || []), response.data],
      });
      setSelectedPctId(0);
    } finally {
      setIsLoading(false);
    }
  };

  const onSavePct = async (values: FormValues) => {
    setIsLoading(true);
    try {
      const actualValues = prepareActualPctValues(values);

      const body: ChangeParameterTableRequest = {
        pctProductValues: actualValues,
      };

      const response = await parameterTableServicesApi.patchParameterTable(body, currentProduct.id, pct.id as number);
      setCurrentProduct({
        ...currentProduct,
        parameterTables: currentProduct.parameterTables?.map((item: ParameterTable) => {
          if (item.id === response.data.id) {
            return response.data;
          }

          return item;
        }) as ParameterTable[],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onHardSavePct = async (values: FormValues | ParameterTableValue[], transformToParameterTableValue = true) => {
    setIsLoading(true);
    try {
      const actualValues = transformToParameterTableValue
        ? removePreviousValues([
            ...(pct.pctProductValues ?? []),
            ...prepareActualPctValues(values as FormValues),
          ] as ObjectValue[])
        : (values as ParameterTableValue[]);

      const body: ChangeParameterTableRequest = {
        pctProductValues: actualValues,
      };

      const response = await parameterTableServicesApi.putParameterTable(body, currentProduct.id, pct.id as number);
      setCurrentProduct({
        ...currentProduct,
        parameterTables: currentProduct.parameterTables?.map((item: ParameterTable) => {
          if (item.id === response.data.id) {
            return response.data;
          }

          return item;
        }) as ParameterTable[],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onDeletePct = async (pctId?: number, productId?: number) => {
    try {
      setIsLoading(true);

      const response = await parameterTableServicesApi.deleteParameterTable(
        productId ?? currentProduct.id,
        pctId ?? selectedPctId,
      );
      if (response?.data?.id) {
        const newProductValue = { ...currentProduct };
        newProductValue.parameterTables = newProductValue.parameterTables?.filter(pct => pct.id !== response.data.id);
        setCurrentProduct(newProductValue);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    selectedPctId,
    pct,
    currentProduct,
    getValueFromPct,
    setSelectedPctId,
    onCreatePct,
    onRenamePct,
    onDuplicatePct,
    onSavePct,
    onHardSavePct,
    onDeletePct,
  };
};
