import lodash from "lodash";

const defaultsDeep = (targetObj, defaultObj) => {
  const result = {};
  Object.keys(defaultObj).forEach(key => {
    const targetValue = targetObj?.[key];
    const defaultValue = defaultObj[key];
    if (lodash.isPlainObject(defaultValue)) {
      result[key] = defaultsDeep(targetValue, defaultValue);
    } else {
      result[key] =
        targetValue === undefined
          ? defaultValue
          : Array.isArray(targetValue)
          ? targetValue
          : typeof targetValue === "boolean"
          ? targetValue
          : targetValue || defaultValue;
    }
  });
  return JSON.parse(JSON.stringify(result));
};

export default defaultsDeep;
