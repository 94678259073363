import { WizardPageMock } from "@ni/common/types";

import {
  ForeclosureFeePage,
  IdentificationPage,
  IncentiveFeePage,
  InstallmentRolloverPage,
  InterestCalculationOptionsPage,
  IPPInitiationPage,
  IPPTenorPage,
  IPPTransactionEligibilityPage,
  ProcessingFeePage,
  ProfitPage,
} from "../pages";

export const WIZARD_PAGES: WizardPageMock[] = [
  { pageKey: "IPP_INIT", component: IPPInitiationPage },
  { pageKey: "IPP_ELGBL", component: IPPTransactionEligibilityPage },
  { pageKey: "IPP_PROFIT", component: ProfitPage },
  { pageKey: "IPP_INT_OPT", component: InterestCalculationOptionsPage },
  { pageKey: "IPP_INCENT_FEE", component: IncentiveFeePage },
  { pageKey: "IPP_PROC_FEE", component: ProcessingFeePage },
  { pageKey: "IPP_FORECLOS_FEE", component: ForeclosureFeePage },
  { pageKey: "IPP_ROLLOVER", component: InstallmentRolloverPage },
  { pageKey: "IPP_TENOR", component: IPPTenorPage },
  { pageKey: "IPP_IDENT", component: IdentificationPage },
];
