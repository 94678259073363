import { useCallback, useEffect } from "react";

import { FormValues } from "@ni/common/types";
import { parseDataAsProgramValues } from "@ni/common/utils";
import { LoyaltyProgramApi } from "@ni/sdk/apis";
import { LoyaltyProgram, LoyaltyProgramTemplateType } from "@ni/sdk/models";

import { useReduxState } from "../store/useReduxState";

interface UseLoyaltyProgramApiProps {
  loyaltyProgramId: number;
  pctId?: number;
  isFetchEnabled?: boolean;
}

const loyaltyProgramApi = new LoyaltyProgramApi();

export const useLoyaltyProgramApi = ({ loyaltyProgramId, pctId, isFetchEnabled = true }: UseLoyaltyProgramApiProps) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [loyaltyProgram, setLoyaltyProgram] = useReduxState<LoyaltyProgram>("currentLoyaltyProgram");

  const getLoyaltyProgramById = useCallback(
    async (loyaltyProgramId: number) => {
      setIsLoading(true);
      try {
        const { data: program } = await loyaltyProgramApi.getById(loyaltyProgramId);

        if (pctId) {
          setLoyaltyProgram({
            ...program,
            programPctValues: program.programPctValues?.filter(value => value.pctId === pctId),
          });
        } else {
          setLoyaltyProgram(program);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [pctId, setIsLoading, setLoyaltyProgram],
  );

  const editLoyaltyProgram = async (data: FormValues) => {
    setIsLoading(true);
    try {
      const { data: programPctValues } = await loyaltyProgramApi.editLoyaltyProgramPctValues(
        {
          programPctValues: parseDataAsProgramValues(data).map(values => ({
            ...values,
            pctId,
          })),
        },
        loyaltyProgramId,
        pctId ?? 0,
      );

      if (pctId) {
        setLoyaltyProgram({
          ...loyaltyProgram,
          programPctValues: programPctValues?.filter(value => value.pctId === pctId),
        });
      } else {
        setLoyaltyProgram({ ...loyaltyProgram, programPctValues });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const editProductLoyaltyProgram = async (
    data: FormValues | { values: FormValues; pctValues: FormValues; type: LoyaltyProgramTemplateType },
  ) => {
    setIsLoading(true);
    try {
      const { data: program } = await loyaltyProgramApi.edit1(
        {
          type: (data?.type as LoyaltyProgramTemplateType) ?? loyaltyProgram.type,
          programValues: parseDataAsProgramValues((data?.values as FormValues) ?? data),
        },
        loyaltyProgramId,
      );
      setLoyaltyProgram(program);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFetchEnabled) void getLoyaltyProgramById(loyaltyProgramId);
  }, [getLoyaltyProgramById, isFetchEnabled, loyaltyProgramId]);

  return {
    loyaltyProgram,
    setLoyaltyProgram,
    editLoyaltyProgram,
    editProductLoyaltyProgram,
  };
};
