import { FC } from "react";
import { Button, Form, Space, Switch, Tag } from "antd";

import { DeleteOutlined, EditOutlined, MessageOutlined } from "@ant-design/icons";
import { TooltipInfo } from "@ni/common/ui";
import { Feature } from "@ni/sdk/models";

import styles from "./feature-element.module.scss";

export interface FeatureElementProps {
  feature: Feature;
  onOpenDrawer: (feat: Feature) => void;
  onDeleteFeature: (feature: Feature) => void;
  onToggleFeature: (state: boolean, feature: Feature) => void;
}

export const FeatureElement: FC<FeatureElementProps> = ({
  feature,
  onDeleteFeature,
  onOpenDrawer,
  onToggleFeature,
}) => {
  return (
    <div className={styles["features-container"]}>
      <Space direction="vertical" size={16}>
        <div className={styles["title"]}>{feature.name}</div>
        <Space size={1}>
          <Tag className={styles["code"]}>{feature.code}</Tag>
          {feature.description && (
            <TooltipInfo tooltipProps={{ title: feature.description }}>
              <Button type="text" icon={<MessageOutlined />} className={styles["description"]} />
            </TooltipInfo>
          )}
        </Space>
      </Space>

      <Space direction="horizontal" className={styles["actions"]}>
        <Button type="text" icon={<EditOutlined />} onClick={() => onOpenDrawer(feature)} />
        <Button type="text" icon={<DeleteOutlined />} onClick={() => onDeleteFeature(feature)} />

        <Form
          initialValues={{ state: feature.state === "ENABLED" }}
          onValuesChange={changedValues => onToggleFeature(changedValues.state as boolean, feature)}
        >
          <Form.Item valuePropName="checked" name="state" className="m-b-0">
            <Switch />
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};
