import * as jp from "jsonpath";

const checkIfBracketsBalanced = (value: string) => {
  if (value === "") return true;
  const right = /\(/gi;
  const left = /\)/gi;
  let numLeft = value.match(left);
  let numRight = value.match(right);
  if (!numLeft || !numRight || numLeft.length !== numRight.length) return false;
  right.lastIndex = 0;
  left.lastIndex = 0;
  const { length } = value;
  const firstHalf = value.substr(0, length / 2);
  const secondHalf = value.substr(length / 2);
  numLeft = firstHalf.match(right);
  numRight = secondHalf.match(left);
  if (!numLeft || !numRight || numLeft.length > numRight.length) return false;
  return true;
};

const isValidJsonPath = (value: string) => {
  const test = {
    firstName: "John",
  };

  try {
    jp.query(test, value);
    return true;
  } catch (e) {
    return false;
  }
};

const checkIfManyBrackets = (value: string) => {
  let count = 0;
  for (let i = 0; i < value.length; i += 1) if (value[i] === "(") count += 1;
  if (count > 1) {
    return true;
  }
  return false;
};

export const onConditionChangeValidation = (val: string) => {
  let isValidEval;
  if (val !== undefined) {
    if (val.includes("(") || val.includes(")")) {
      if (checkIfBracketsBalanced(val)) {
        const removeExclamationMark = val.replace(/!/g, "");

        if (checkIfManyBrackets(removeExclamationMark)) {
          const arrayOfInternals = removeExclamationMark.match(/\((.*?)\)/g)?.map(b => b.replace(/\(|(.*?)\)/g, "$1"));
          arrayOfInternals?.map(i => {
            if (isValidJsonPath(i)) {
              isValidEval = true;
              return true;
            }
            isValidEval = false;
            return false;
          });
        } else {
          if (isValidJsonPath(removeExclamationMark)) {
            isValidEval = true;
            return true;
          }
          isValidEval = false;
          return false;
        }
      } else {
        isValidEval = false;
        return false;
      }
    } else {
      const removeExclamationMark = val.replace(/!/g, "");
      isValidEval = isValidJsonPath(removeExclamationMark);
      return isValidJsonPath(removeExclamationMark);
    }
  }
  return isValidEval;
};
