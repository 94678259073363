import { useEffect, useMemo } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { NIC_DUE_DATE_DAYS } from "@ni/common/constants";
import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { mapOptionsValue, reverseOptionsValue } from "@ni/common/utils";

import { usePct } from "../../../../hooks";

import {
  cashRateOptions,
  chargeOptions,
  feesOption,
  fullPaymentDateOptions,
  insOption,
  intOption,
  postingMaximumOptions,
  retailRateOptions,
} from "./constants";

export const InterestCalculationPage = () => {
  const { pctId, productId, id } = useParams<{ id: string; productId: string; pctId: string }>();

  const [form] = Form.useForm<FormValues>();
  const canSkipMaxDays = Form.useWatch<string>(chargeOptions.code, form) === "P";
  const { currentProduct, pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const { isAllInterest, isAllFees } = useMemo(
    () => ({
      isAllInterest:
        currentProduct?.productValues?.find(x => x.fieldCode === "nic-int-rate-zero-all-int")?.value === "true",
      isAllFees:
        currentProduct?.productValues?.find(x => x.fieldCode === "nic-int-rate-zero-all-fees")?.value === "true",
    }),
    [currentProduct?.productValues],
  );

  const hydratedValues = useHydrateForm({
    form,
    entityFields: [...(currentProduct?.productValues ?? []), ...(pct?.pctProductValues ?? [])],
    keys: {
      strings: [
        cashRateOptions.code,
        chargeOptions.code,
        postingMaximumOptions.code,
        retailRateOptions.code,
        fullPaymentDateOptions.code,
        NIC_DUE_DATE_DAYS,
      ],
    },
    allowParse: true,
  });

  useEffect(() => {
    form.setFieldsValue({
      [intOption.code]: reverseOptionsValue(intOption, pct?.pctProductValues),
      [feesOption.code]: reverseOptionsValue(feesOption, pct?.pctProductValues),
      [insOption.code]: reverseOptionsValue(insOption, pct?.pctProductValues),
    });
  }, [form, pct?.pctProductValues]);

  const onFinish = (values: FormValues) => {
    void onSavePct({
      [postingMaximumOptions.code]: "",
      ...values,
      ...mapOptionsValue(feesOption, values[feesOption.code] as string[]),
      ...mapOptionsValue(intOption, values[intOption.code] as string[]),
      ...mapOptionsValue(insOption, values[insOption.code] as string[]),
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Interest Calculation"
      pageSubtitle="The interest calculation is done based on Daily Balance algorithm which calculates the interest on daily balances and post the interest at the end of billing cycle."
      size="md"
      formSize="md"
      gap={30}
      level="pct"
      submitHandler={onFinish}
      submitLabel="Save"
      relatedLinks={[
        {
          href: `/tenant/${id}/credit-products-configuration`,
          label: `Interest Calculation - Global`,
        },
        {
          href: `/tenant/${id}/product/${productId}/interest-grace-options`,
          label: `${currentProduct.displayName} - Interest Grace Options`,
        },
      ]}
    >
      <NetworkForm.Number
        {...retailRateOptions.props}
        addonAfter="%"
        formItemOptions={{
          name: retailRateOptions.code,
          label: retailRateOptions.label,
        }}
      />

      <NetworkForm.Number
        {...cashRateOptions.props}
        addonAfter="%"
        formItemOptions={{
          name: cashRateOptions.code,
          label: cashRateOptions.label,
        }}
      />

      <NetworkForm.Radio
        radioList={chargeOptions.options}
        initialValue={chargeOptions.default as string}
        formItemOptions={{
          name: chargeOptions.code,
          label: chargeOptions.label,
          initialValue: chargeOptions.default,
        }}
      />

      {!canSkipMaxDays && (
        <NetworkForm.Number
          {...postingMaximumOptions.props}
          formItemOptions={{
            name: postingMaximumOptions.code,
            label: postingMaximumOptions.label,
            tooltip: postingMaximumOptions.tooltip,
          }}
        />
      )}

      <NetworkForm.Number
        {...fullPaymentDateOptions.props}
        formItemOptions={{
          name: fullPaymentDateOptions.code,
          label: fullPaymentDateOptions.label,
          tooltip: fullPaymentDateOptions.tooltip,
          rules: [
            {
              type: "number",
              max: 30 - (+(hydratedValues[NIC_DUE_DATE_DAYS] as unknown as number) || 0),
              message: "Full payment date cannot exceed remaining cycle days after the due date",
            },
          ],
        }}
      />

      {!isAllInterest && (
        <NetworkForm.CheckBox
          selectAllTitle={intOption.label}
          checkboxes={intOption.options}
          formItemOptions={{
            name: intOption.code,
          }}
        />
      )}

      {!isAllFees && (
        <NetworkForm.CheckBox
          selectAllTitle={feesOption.label}
          checkboxes={feesOption.options}
          formItemOptions={{
            name: feesOption.code,
          }}
        />
      )}

      <NetworkForm.CheckBox
        selectAllTitle={insOption.label}
        checkboxes={insOption.options}
        formItemOptions={{
          name: insOption.code,
        }}
      />
    </CustomFormWrapper>
  );
};
