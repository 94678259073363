import { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Modal, Space, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { AxiosResponse } from "axios";
import InputMask from "react-input-mask";
import { useParams } from "react-router-dom";

import { PlusOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { AfterWizardEditPageTitle, Spin } from "@ni/common/ui";
import { UserService } from "@ni/common/utils";
import { FinancialInstitutionApi } from "@ni/sdk/apis";
import { ChangeFinancialInstitutionRequest, FinancialInstitution } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface FiDetailsForm {
  address: string;
  id: number;
  logo: string;
  name: string;
  phone: string;
}

const financialInstitutionServiceApi = new FinancialInstitutionApi();

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const FinancialInstitutionDetails: FC = () => {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [, setFinancialInstitution] = useReduxState<FinancialInstitution>("financialInstitution");
  const { id } = useParams<{ id: string }>();

  const getFinancialInstitution = useCallback((): void => {
    setIsLoading(true);
    financialInstitutionServiceApi
      .getById4(parseInt(id ?? "0", 10))
      .then((response: AxiosResponse<FinancialInstitution>) => {
        setFinancialInstitution(response.data);
        form.setFieldsValue({
          id: response.data.id,
          name: response.data.name,
          address: response.data.address,
          phone: response.data.phone,
        });
        UserService.updateToken()
          .then(() => {})
          .catch(() => {});
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [form, id, setFinancialInstitution, setIsLoading]);

  useEffect(() => {
    if (id) getFinancialInstitution();
  }, [getFinancialInstitution, id]);

  const onFinish = (values: FiDetailsForm) => {
    setIsLoading(true);

    const requestBody: ChangeFinancialInstitutionRequest = {
      address: values.address,
      id: values.id,
      logo: values.logo,
      name: values.name,
      phone: values.phone,
    };

    financialInstitutionServiceApi
      .edit(requestBody)
      .then((response: AxiosResponse<FinancialInstitution>) => {
        setFinancialInstitution(response.data);
        UserService.updateToken()
          .then(() => {})
          .catch(() => {});
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onFormChange = () => {
    const fiPhone = form.getFieldValue("phone") as string;
    const isFieldsFilled =
      form.getFieldValue("name")?.length > 0 &&
      form.getFieldValue("address")?.length > 0 &&
      fiPhone.substring(1).length > 0;
    setIsEnabled(isFieldsFilled);
  };

  if (isLoading) {
    return <Spin />;
  }

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    return (
      setPreviewImage(file.url || (file.preview as string)),
      setPreviewOpen(true),
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1))
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className={styles["fi-details"]}>
      <AfterWizardEditPageTitle title="Financial Institution Profile" page="Dashboard" />
      <Form
        className={styles["fi-details-form"]}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <Form.Item name="id" label="Financial Institution logo">
          <>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={() => handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        </Form.Item>

        <Form.Item name="id" label="Financial Institution ID">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          name="name"
          label="Financial Institution name"
          rules={[
            { max: 255, message: "Financial Institution name must have maximum 255 characters." },
            { whitespace: true },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Financial Institution name contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
            {
              required: true,
              message: "Please input Financial Institution name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            { max: 255, message: "Address must have maximum 255 characters." },
            { whitespace: true },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Address contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
            {
              required: true,
              message: "Please input address",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            {
              required: true,
              message: "Please input phone number",
            },
          ]}
        >
          <InputMask className={styles["phone-number-input"]} mask="+999 999 999 999 999" maskChar={null} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              loading={isLoading}
              className="save-button"
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!isEnabled}
            >
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FinancialInstitutionDetails;
