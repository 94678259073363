export enum AccountBillingFormNames {
  cashEnable = "a-b-cash-enable",
  cashMaxNr = "nic-a-b-cash-max-nr",
  cashMaxAm = "nic-a-b-cash-max-am",
  cashMaxSingle = "nic-a-b-cash-max-single",

  cashForEnable = "a-b-cash-for-enable",
  cashForMaxNr = "nic-a-b-cash-for-max-nr",
  cashForMaxAm = "nic-a-b-cash-for-max-am",
  cashForMaxSingle = "nic-a-b-cash-for-max-single",

  retailEnable = "a-b-retail-enable",
  retailMaxNr = "nic-a-b-retail-max-nr",
  retailMaxAm = "nic-a-b-retail-max-am",
  retailMaxSingle = "nic-a-b-retail-max-single",

  retailForEnable = "a-b-retail-for-enable",
  retailForMaxNr = "nic-a-b-retail-for-max-nr",
  retailForMaxAm = "nic-a-b-retail-for-max-am",
  retailForMaxSingle = "nic-a-b-retail-for-max-single",

  totalEnable = "a-b-total-enable",
  totalMaxNr = "nic-a-b-total-max-nr",
  totalMaxAm = "nic-a-b-total-max-am",
  totalMaxSingle = "nic-a-b-total-max-single",
}

export enum AccountBillingSwitchHeaders {
  allCashEnable = "card-billing-all-cash-enable",
  allRetailEnable = "card-billing-all-retail-enable",
  allTotalEnable = "card-billing-all-total-enable",
}

export const checkboxFieldsPage34: { [key: string]: string[] } = {
  [AccountBillingFormNames.cashEnable]: [
    AccountBillingFormNames.cashMaxAm,
    AccountBillingFormNames.cashMaxNr,
    AccountBillingFormNames.cashMaxSingle,
  ],
  [AccountBillingFormNames.cashForEnable]: [
    AccountBillingFormNames.cashForMaxAm,
    AccountBillingFormNames.cashForMaxNr,
    AccountBillingFormNames.cashForMaxSingle,
  ],
  [AccountBillingFormNames.retailEnable]: [
    AccountBillingFormNames.retailMaxAm,
    AccountBillingFormNames.retailMaxNr,
    AccountBillingFormNames.retailMaxSingle,
  ],
  [AccountBillingFormNames.retailForEnable]: [
    AccountBillingFormNames.retailForMaxAm,
    AccountBillingFormNames.retailForMaxNr,
    AccountBillingFormNames.retailForMaxSingle,
  ],
  [AccountBillingFormNames.totalEnable]: [
    AccountBillingFormNames.totalMaxAm,
    AccountBillingFormNames.totalMaxNr,
    AccountBillingFormNames.totalMaxSingle,
  ],
};

export const switchHeader34: { [key: string]: string[] } = {
  [AccountBillingSwitchHeaders.allCashEnable]: [
    AccountBillingFormNames.cashEnable,
    AccountBillingFormNames.cashForEnable,
  ],
  [AccountBillingSwitchHeaders.allRetailEnable]: [
    AccountBillingFormNames.retailEnable,
    AccountBillingFormNames.retailForEnable,
  ],
  [AccountBillingSwitchHeaders.allTotalEnable]: [AccountBillingFormNames.totalEnable],
};
