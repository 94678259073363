import _ from "lodash";

import { InsuranceProgram, Tenant } from "@ni/sdk/models";

export enum Level {
  Tenant,
  Product,
  Pct,
  Other,
}

export interface Breadcrumb {
  name: string;
  path?: string;
}

const uniqueRouteNames: Record<string, string> = {
  api: "API",
  "card-and-pin-maintenance": "Card and PIN Maintenance",
  "sms-notification": "SMS Notification & Statement Generation",
  "fees-tenant-configuration": "Fee Settings",
  "sms-templates": "Templates Settings",
  "sms-general-settings": "General Settings",
  "payment-and-delinquency-settings": "Payment and Delinquency",
};

export const getPathLevel = (path: string): Level => {
  if (!path) return Level.Other;

  const tenantPathnameRegex = /^\/tenant\/[\d]{1,}\//;
  const productPathnameRegex = /^\/tenant\/\d*\/product\/[\d]{1,}\//;
  const pctPathnameRegex = /^\/tenant\/\d*\/product\/\d*\/pct\/[\d]{1,}\//;

  if (pctPathnameRegex.test(path)) return Level.Pct;
  if (productPathnameRegex.test(path)) return Level.Product;
  if (tenantPathnameRegex.test(path)) return Level.Tenant;
  return Level.Other;
};

const isEditEntity = (path: string) => {
  return path.match(/\/edit-.*\/[\d]{1,}\//);
};

const isPctEditEntity = (path: string) => {
  const isIppEdit = path.match(/\/installment-payment-plans\/[\d]{1,}\//);
  const isInsuranceEdit = path.match(/\/insurance-edit\/[\d]{1,}\//);
  const isLoyaltyEdit = path.match(/\/loyalty-program\/[\d]{1,}\//);
  return {
    isEdit: isIppEdit || isInsuranceEdit || isLoyaltyEdit,
    type: isIppEdit ? "IPP" : isLoyaltyEdit ? "LYL" : isInsuranceEdit ? "INS" : "",
  };
};

const getPathIdentities = (path: string) => {
  return path.split("/").filter(x => x && Number.isInteger(+(x as unknown as number)));
};

const generateLastRouteName = (path: string) => {
  const splittedPath = path.split("/");
  const lastRoute: string = splittedPath[splittedPath.length - 1];
  if (splittedPath[splittedPath.length - 2] === "pct") return "PCT";
  return uniqueRouteNames[lastRoute] ?? _.startCase(_.toLower(lastRoute.replace("-", " ")));
};

const getDashboardBreadcrum = (
  type: string,
  level: Level,
  tenantId?: string,
  productId?: string,
  pctId?: string,
): Breadcrumb => {
  switch (level) {
    case Level.Pct:
      if (type === "LYL")
        return {
          name: "Loyalty Programs",
          path: `/tenant/${tenantId}/product/${productId}/pct/${pctId}/loyalty-programs`,
        };
      if (type === "IPP")
        return {
          name: "IPP",
          path: `/tenant/${tenantId}/product/${productId}/pct/${pctId}/installment-payment-plans`,
        };
      if (type === "INS")
        return {
          name: "Insurance Programs",
          path: `/tenant/${tenantId}/product/${productId}/pct/${pctId}/insurance-programs`,
        };
      return { name: "Unknown" };
    case Level.Product:
      if (type === "LYL")
        return {
          name: "Loyalty Programs",
          path: `/tenant/${tenantId}/product/${productId}/loyalty-programs`,
        };
      if (type === "INS")
        return {
          name: "Insurance Programs",
          path: `/tenant/${tenantId}/product/${productId}/insurance-programs`,
        };
      return { name: "Unknown" };
    case Level.Tenant:
      if (type === "LYL")
        return {
          name: "Loyalty Program Templates",
          path: `/tenant/${tenantId}/loyalty-program-templates`,
        };
      if (type === "IPP")
        return {
          name: "IPP",
          path: `/tenant/${tenantId}/installment-payment-plans`,
        };
      if (type === "INS")
        return {
          name: "Insurance Program Templates",
          path: `/tenant/${tenantId}/insurance-program-templates`,
        };
      return { name: "Unknown" };
    default:
      return { name: "Unknown" };
  }
};

const getGeneratedName = (
  name: string | undefined,
  checker: "tenant" | "product" | "pct" | "template" | "plan" | "program",
): string =>
  name ? (!name?.toLowerCase()?.includes(checker) ? (checker === "pct" ? "PCT" : _.startCase(checker)) : "") : "";

const getTenantName = (tenantId: string, tenant: Tenant | null): string => {
  return tenant ? `${getGeneratedName(tenant?.name, "tenant")} ${tenant?.name ?? tenantId}` : `Tenant ${tenantId}`;
};

const getProductName = (productId: string, tenant: Tenant | null) => {
  if (!tenant) return productId;
  const productName = tenant?.products?.find(x => x?.id?.toString() === productId)?.displayName ?? productId;
  return `${getGeneratedName(productName, "product")} ${productName}`;
};
const getPctName = (productId: string, pctId: string, tenant: Tenant | null) => {
  if (!tenant) return pctId;
  const pctName =
    tenant?.products
      ?.find(x => x?.id?.toString() === productId)
      ?.parameterTables?.find(x => x?.id?.toString() === pctId)?.displayName ?? pctId;
  return `${getGeneratedName(pctName, "pct")} ${pctName}`;
};

const getEntityNameOnTenantLevel = (type: string, entityId: string, tenant: Tenant | null): string => {
  switch (type) {
    case "LYL": {
      const templateName = tenant?.loyaltyProgramTemplates?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(templateName, "template")} ${templateName}`;
    }
    case "INS": {
      const templateName =
        tenant?.insuranceProgramTemplates?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(templateName, "template")} ${templateName}`;
    }
    case "IPP": {
      const planName = tenant?.plans?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(planName, "plan")} ${planName}`;
    }
    default:
      return entityId;
  }
};

const getEntityNameOnProductLevel = (
  type: string,
  productId: string,
  entityId: string,
  tenant: Tenant | null,
  productInsurancePrograms: InsuranceProgram[] | null,
): string => {
  switch (type) {
    case "LYL": {
      const programName =
        tenant?.products
          ?.find(x => x?.id?.toString() === productId)
          ?.loyaltyPrograms?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(programName, "program")} ${programName}`;
    }
    case "INS": {
      const programName = productInsurancePrograms?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(programName, "program")} ${programName}`;
    }
    default:
      return entityId;
  }
};

const getEntityNameOnPctLevel = (
  type: string,
  productId: string,
  entityId: string,
  tenant: Tenant | null,
  productInsurancePrograms: InsuranceProgram[] | null,
): string => {
  switch (type) {
    case "LYL": {
      const programName =
        tenant?.products
          ?.find(x => x?.id?.toString() === productId)
          ?.loyaltyPrograms?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(programName, "program")} ${programName}`;
    }
    case "INS": {
      const programName = productInsurancePrograms?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(programName, "program")} ${programName}`;
    }
    case "IPP": {
      const planName = tenant?.plans?.find(x => x?.id?.toString() === entityId)?.name ?? entityId;
      return `${getGeneratedName(planName, "plan")} ${planName}`;
    }
    default:
      return entityId;
  }
};

export const getTenantBreadcrumbs = (path: string, tenant: Tenant | null): Breadcrumb[] => {
  if (!path) return [];

  if (isEditEntity(path)) {
    const [tenantId, entityId] = getPathIdentities(path);
    const type = path.includes("edit-insurance") ? "INS" : path.includes("edit-template") ? "LYL" : "IPP";
    return [
      { name: getTenantName(tenantId, tenant), path: `/tenant/${tenantId}/*` },
      getDashboardBreadcrum(type, Level.Tenant, tenantId),
      { name: getEntityNameOnTenantLevel(type, entityId, tenant) },
      { name: generateLastRouteName(path) },
    ];
  }
  const [tenantId] = getPathIdentities(path);
  return [
    { name: getTenantName(tenantId, tenant), path: `/tenant/${tenantId}/details` },
    { name: generateLastRouteName(path) },
  ];
};

export const getProductBreadcrumbs = (
  path: string,
  tenant: Tenant | null,
  productInsurancePrograms: InsuranceProgram[] | null,
): Breadcrumb[] => {
  if (!path) return [];

  if (isEditEntity(path)) {
    const [tenantId, productId, entityId] = getPathIdentities(path);
    const type = path.includes("edit-insurance") ? "INS" : "LYL";
    return [
      { name: getTenantName(tenantId, tenant), path: `/tenant/${tenantId}/*` },
      { name: getProductName(productId, tenant), path: `/tenant/${tenantId}/product/${productId}/*` },
      getDashboardBreadcrum(type, Level.Product, tenantId, productId),
      { name: getEntityNameOnProductLevel(type, productId, entityId, tenant, productInsurancePrograms) },
      { name: generateLastRouteName(path) },
    ];
  }
  const [tenantId, productId] = getPathIdentities(path);
  return [
    { name: getTenantName(tenantId, tenant), path: `/tenant/${tenantId}/*` },
    { name: getProductName(productId, tenant), path: `/tenant/${tenantId}/product/${productId}/product-details` },
    { name: generateLastRouteName(path) },
  ];
};

export const getPctBreadcrumbs = (
  path: string,
  tenant: Tenant | null,
  productInsurancePrograms: InsuranceProgram[] | null,
): Breadcrumb[] => {
  if (!path) return [];

  const { isEdit, type } = isPctEditEntity(path);

  if (isEdit) {
    const [tenantId, productId, pctId, entityId] = getPathIdentities(path);
    return [
      { name: getTenantName(tenantId, tenant), path: `/tenant/${tenantId}/*` },
      { name: getProductName(productId, tenant), path: `/tenant/${tenantId}/product/${productId}/*` },
      { name: "PCT", path: `/tenant/${tenantId}/product/${productId}/pct/` },
      { name: getPctName(productId, pctId, tenant), path: `/tenant/${tenantId}/product/${productId}/pct/${pctId}/*` },
      getDashboardBreadcrum(type, Level.Pct, tenantId, productId, pctId),
      { name: getEntityNameOnPctLevel(type, productId, entityId, tenant, productInsurancePrograms) },
      { name: generateLastRouteName(path) },
    ];
  }
  const [tenantId, productId, pctId] = getPathIdentities(path);
  return [
    { name: getTenantName(tenantId, tenant), path: `/tenant/${tenantId}/*` },
    { name: getProductName(productId, tenant), path: `/tenant/${tenantId}/product/${productId}/*` },
    { name: "PCT", path: `/tenant/${tenantId}/product/${productId}/pct/` },
    {
      name: getPctName(productId, pctId, tenant),
      path: `/tenant/${tenantId}/product/${productId}/pct/${pctId}/settings-overview`,
    },
    { name: generateLastRouteName(path) },
  ];
};
