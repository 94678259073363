import { FC } from "react";
import { Col, Row } from "antd";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface Heading {
  label: string;
  tooltip?: string;
}

export interface HeadingsRowProps {
  headings: Heading[];
  firstColSpan: number;
  dividend?: number;
}

export const HeadingsRow: FC<HeadingsRowProps> = ({ headings, firstColSpan, dividend = 24 }) => {
  return (
    <Row>
      <Col span={firstColSpan} />
      <Col span={24 - firstColSpan}>
        <Row className={styles["heading-label"]}>
          {headings.map(heading => (
            <Col key={heading.label} span={Math.round(dividend / headings.length)} className="col-header">
              <div className="ant-form-vertical ant-form-item-label">
                <TooltipInfo
                  label={heading.label}
                  tooltipProps={heading?.tooltip ? { title: heading.tooltip ?? "" } : {}}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
