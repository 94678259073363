import { FC, ReactNode } from "react";
import { Flex, Form, Space, Switch, Tooltip, Typography } from "antd";
import cn from "classnames";

import { FormValues } from "@ni/common/types";

import styles from "./styles.module.scss";

type DetailsType = {
  field: string;
  value: string;
};

interface CardPanelProps {
  title: ReactNode;
  details: DetailsType[];
  actions?: ReactNode;
  vertical?: boolean;
  toggleState: boolean;
  toggleDisabled?: boolean;
  onToggle?: (values: FormValues) => void;
}

export const CardPanel: FC<CardPanelProps> = ({
  title,
  details,
  actions,
  vertical = true,
  toggleState,
  toggleDisabled = false,
  onToggle,
}) => (
  <div className={styles["card-panel"]}>
    <Flex justify="space-between" align="center" gap={15} className={styles["card-panel-header"]}>
      <Typography.Title level={4} ellipsis={true}>
        {title}
      </Typography.Title>
      <Form disabled={toggleDisabled} onValuesChange={onToggle}>
        <Form.Item name="state" valuePropName="checked" initialValue={toggleState}>
          {toggleDisabled ? (
            <Tooltip title="You must complete program creation first to be able to toggle." placement="top">
              <Switch />
            </Tooltip>
          ) : (
            <Switch />
          )}
        </Form.Item>
      </Form>
    </Flex>

    <Space direction="vertical" size={8} className={cn(styles["card-panel-content"], "w-p-100")}>
      {details.map(obj => (
        <Flex key={obj.field + obj.value} justify="flex-start" vertical={vertical}>
          <div className={cn(styles["card-panel-content-container-1"], !vertical && styles["is-vertical"])}>
            <Typography.Text strong={true}>{obj.field}</Typography.Text>
          </div>

          <div className={styles["card-panel-content-container-2"]}>
            <Typography.Text
              ellipsis={vertical}
              className={cn(styles["card-panel-content-value"], !vertical && styles["is-vertical"])}
            >
              {obj.value && obj.value.length >= 48 ? (
                <Tooltip title={obj.value} placement="top">
                  {obj.value}
                </Tooltip>
              ) : (
                <span>{obj.value}</span>
              )}
            </Typography.Text>
          </div>
        </Flex>
      ))}
    </Space>

    {actions && (
      <Flex justify="center" align="center" className={styles["card-panel-footer"]}>
        {actions}
      </Flex>
    )}
  </div>
);
