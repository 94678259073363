import { FC, ReactNode } from "react";
import { Space, Typography } from "antd";

import styles from "./styles.module.scss";

interface SimpleCardProps {
  contentList: { title?: ReactNode; description?: ReactNode }[];
}

export const SimpleCardList: FC<SimpleCardProps> = ({ contentList }) => {
  return (
    <Space direction="horizontal" size={40} wrap={true}>
      {contentList?.map(({ title = "_", description = "_" }) => (
        <div key={String(title) + String(description)} className={styles["wizard-success-details"]}>
          {typeof title === "string" ? <Typography.Title level={3}>{title}</Typography.Title> : title}
          {typeof description === "string" ? <Typography.Text>{description}</Typography.Text> : description}
        </div>
      ))}
    </Space>
  );
};
