import FinancialInstitutionsLayout from "./components/FinancialInstitutionsLayout";

import styles from "./styles.module.scss";

export function FinancialInstitutions() {
  return (
    <div className={styles["container"]}>
      <FinancialInstitutionsLayout />
    </div>
  );
}

export default FinancialInstitutions;
