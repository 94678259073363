import React, { FC, useState } from "react";
import { Button, Flex, Form, Input, Modal, Switch, Table, TableColumnType, TablePaginationConfig, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

import { SettingOutlined } from "@ant-design/icons";
import { SetReduxState, useReduxState } from "@ni/common/hooks";
import { CopyIcon, WandIcon } from "@ni/common/icons";
import { FormValues } from "@ni/common/types";
import { PlanApi } from "@ni/sdk/apis";
import { PageDataFullPlan, Plan, PlanState } from "@ni/sdk/models";

interface IPPTableProps {
  planList: PageDataFullPlan;
  tenantId: string;
  pagination: TablePaginationConfig;
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setInstallmentPlans: SetReduxState<PageDataFullPlan>;
}

const planApi = new PlanApi();

export const IPPTable: FC<IPPTableProps> = ({ planList, tenantId, pagination, setPagination, setInstallmentPlans }) => {
  const [modalForm] = Form.useForm<FormValues>();

  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>({});

  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading", false);

  const navigate = useNavigate();

  const handleContinueClick = (record: Plan) => {
    const queries = new URLSearchParams({ tenantId, planId: record.id as unknown as string });

    navigate({
      pathname: "/create-installment-payment-plan",
      search: queries.toString(),
    });
  };

  const handleEditClick = (record: Plan) => {
    navigate(`/tenant/${record.tenantId}/edit-ipp/${record.id}/details`);
  };

  const handleDuplicateClick = (record: Plan) => {
    modalForm.setFieldsValue({
      newDisplayName: `${record.name} - copy`,
    });
    setSelectedPlan(record);
    setModalOpen(true);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  const togglePlan = async (planId: number, values: FormValues) => {
    const { state } = values;

    setIsLoading(true);
    try {
      const { data }: { data: Plan } = await planApi.editPlan(
        { state: state ? PlanState.ACTIVE : PlanState.INACTIVE },
        planId,
      );

      setInstallmentPlans(prev => ({
        ...prev,
        content: prev.content?.map(obj => (obj.id === data.id ? data : obj)),
      }));

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const duplicatePlan = async (values: FormValues) => {
    setModalLoading(true);
    try {
      const { data } = await planApi.copyPlan(values, selectedPlan.id as number);

      setInstallmentPlans(prev => {
        const updatedContent = prev.content ? [data, ...prev.content] : [data];

        if (updatedContent.length > 6) updatedContent.pop();

        return {
          ...prev,
          content: updatedContent,
        };
      });

      setModalLoading(false);
      setModalOpen(false);
    } catch (err) {
      setModalLoading(false);
      setModalOpen(false);
    }
  };

  const groupMap: Record<string, string> = {
    IPP_BC: "Balance conversion",
    IPP_TC: "Transaction conversion",
    IPP_NB: "New balance",
  };

  const columns: TableColumnType<Plan>[] = [
    {
      key: "switchHandler",
      width: "5%",
      render: (_: string, record: Plan) => {
        return (
          <Form
            disabled={record.state === PlanState.DRAFT}
            initialValues={{ state: record.state === PlanState.ACTIVE }}
            onValuesChange={(changedValues: FormValues) => togglePlan(record.id!, changedValues)}
          >
            <Form.Item name="state" valuePropName="checked" className="m-b-0">
              <Switch />
            </Form.Item>
          </Form>
        );
      },
    },
    {
      title: "Number",
      dataIndex: ["code"],
      key: "ippNumber",
      width: "6%",
    },
    {
      title: "Group",
      dataIndex: ["group"],
      key: "ippGroup",
      width: "12%",
      render: (text: string) => (text ? groupMap[text] : ""),
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "ippName",
      width: "11%",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: ["description"],
      key: "ippDescription",
      width: "17%",
      ellipsis: true,
    },
    {
      title: "Currency",
      dataIndex: ["currency"],
      key: "ippCurrency",
      width: "6%",
    },
    {
      key: "ippActions",
      width: "7%",
      render: (_: string, record: Plan) => (
        <Flex justify="start" gap={8} className="w-p-100">
          {record.state === PlanState.DRAFT ? (
            <Tooltip title="Continue Creating">
              <Button
                type="text"
                htmlType="button"
                icon={<WandIcon />}
                onClick={() => handleContinueClick(record)}
                className="text-primary"
              />
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Edit">
                <Button
                  type="text"
                  htmlType="button"
                  icon={<SettingOutlined />}
                  onClick={() => handleEditClick(record)}
                  disabled={record.state === PlanState.INACTIVE}
                  className="text-primary"
                />
              </Tooltip>
              <Tooltip title="Duplicate">
                <Button
                  type="text"
                  htmlType="button"
                  icon={<CopyIcon />}
                  onClick={() => handleDuplicateClick(record)}
                  disabled={record.state === PlanState.INACTIVE}
                  className="text-primary"
                />
              </Tooltip>
            </>
          )}
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Table<Plan>
        rowKey="id"
        columns={columns}
        dataSource={planList.content}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
      />

      <Modal
        title="Duplicate Installment Payment Plan"
        open={modalOpen}
        width="500px"
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="back" disabled={modalLoading} onClick={() => setModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={modalLoading} onClick={modalForm.submit}>
            Duplicate
          </Button>,
        ]}
      >
        <Form form={modalForm} autoComplete="off" layout="vertical" disabled={modalLoading} onFinish={duplicatePlan}>
          <Form.Item
            name="newDisplayName"
            label="Name"
            rules={[{ type: "string", required: true, max: 24, message: "Cannot be empty. Max length is 24 letters." }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
