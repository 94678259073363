import { FC, useMemo } from "react";
import { Form } from "antd";

import { PAYM_LIM_PERIODS } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm, DynamicSwitchRule } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { useProductCurrencyType } from "../../hooks";

import {
  d365TotalAmOption,
  d365TotalNrOption,
  d365TotalSingleOption,
  dTotalAmOption,
  dTotalNrOption,
  dTotalSingleOption,
  enableCTotal,
  enableD365TotalOption,
  enableDTotalOption,
  enableMTotalOption,
  initialValues,
  mTotalAmOption,
  mTotalNrOption,
  mTotalSingleOption,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const CardLimitsPaymentPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currencyType = useProductCurrencyType(wizardResponse.product?.productValues);

  const { showPerDay, showPerMonth, showPer365 } = useMemo(() => {
    const pctValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues;

    return {
      pctValues,
      showPerDay: pctValues?.find(x => x.fieldCode === PAYM_LIM_PERIODS)?.value?.includes("D"),
      showPerMonth: pctValues?.find(x => x.fieldCode === PAYM_LIM_PERIODS)?.value?.includes("B"),
      showPer365: pctValues?.find(x => x.fieldCode === PAYM_LIM_PERIODS)?.value?.includes("Y"),
    };
  }, [wizardResponse?.product?.parameterTables]);

  const rules = useMemo(() => {
    const rules: DynamicSwitchRule[] = [];

    if (showPerDay) {
      rules.push({
        name: "d-paym-c-total-enabled",
        label: "Per Day",
        tooltip: "Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59).",
        maxNumber: "nic-d-paym-c-total-nr",
        maxAmount: "nic-d-paym-c-total-am",
        maxSingleAmount: "nic-d-paym-c-total-single",
      });
    }

    if (showPerMonth) {
      rules.push({
        name: "m-paym-c-total-enabled",
        label: "Per Month",
        tooltip:
          "Switch it off if restrictions are not intended per calendar month (from the 1st till the last day of the month).",
        maxNumber: "nic-m-paym-c-total-nr",
        maxAmount: "nic-m-paym-c-total-am",
        maxSingleAmount: "nic-m-paym-c-total-single",
      });
    }

    if (showPer365) {
      rules.push({
        name: "d365-paym-c-total-enabled",
        label: "Per 365 Days",
        tooltip:
          "365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended.",
        maxNumber: "nic-d365-paym-c-total-nr",
        maxAmount: "nic-d365-paym-c-total-am",
        maxSingleAmount: "nic-d365-paym-c-total-single",
      });
    }
    return rules;
  }, [showPer365, showPerDay, showPerMonth]);

  useHydrateForm({
    form,
    entityFields: [...initialValues, ...(wizardResponse.product?.parameterTables?.[0].pctProductValues ?? [])],
    keys: {
      strings: [
        enableCTotal,
        enableDTotalOption,
        dTotalNrOption,
        dTotalAmOption,
        dTotalSingleOption,
        enableMTotalOption,
        mTotalNrOption,
        mTotalAmOption,
        mTotalSingleOption,
        enableD365TotalOption,
        d365TotalNrOption,
        d365TotalAmOption,
        d365TotalSingleOption,
      ],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Card Limits - Payment"
      pageSubtitle="Card limits control payments to the particular card"
      size="md"
      formSize="lg"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <DynamicSwitchForm
        form={form}
        currency={currencyType}
        name="paym-c-total-enabled"
        title="Card Limits"
        rules={rules}
        disabled={formDisabled}
      />
    </CustomFormWrapper>
  );
};
