/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeElementTemplateRequest } from '../model';
// @ts-ignore
import { CopyElementTemplateRequest } from '../model';
// @ts-ignore
import { CreateElementTemplateRequest } from '../model';
// @ts-ignore
import { ElementTemplate } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PageDataDashboardElementTemplates } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
/**
 * ElementTemplateApi - axios parameter creator
 * @export
 */
export const ElementTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Copies an Element Template
         * @summary Copy Element Template
         * @param {CopyElementTemplateRequest} copyElementTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyElementTemplate: async (copyElementTemplateRequest: CopyElementTemplateRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyElementTemplateRequest' is not null or undefined
            assertParamExists('copyElementTemplate', 'copyElementTemplateRequest', copyElementTemplateRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyElementTemplate', 'id', id)
            const localVarPath = `/api/v1/element-templates/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyElementTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the element template created
         * @summary Create Element Template
         * @param {CreateElementTemplateRequest} createElementTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElementTemplate: async (createElementTemplateRequest: CreateElementTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createElementTemplateRequest' is not null or undefined
            assertParamExists('createElementTemplate', 'createElementTemplateRequest', createElementTemplateRequest)
            const localVarPath = `/api/v1/element-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createElementTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Element Template
         * @summary Delete Element Template by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElementTemplate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteElementTemplate', 'id', id)
            const localVarPath = `/api/v1/element-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch for the Element and Put for the Validations
         * @summary Edit an element template
         * @param {ChangeElementTemplateRequest} changeElementTemplateRequest 
         * @param {number} elementTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editElementTemplate: async (changeElementTemplateRequest: ChangeElementTemplateRequest, elementTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeElementTemplateRequest' is not null or undefined
            assertParamExists('editElementTemplate', 'changeElementTemplateRequest', changeElementTemplateRequest)
            // verify required parameter 'elementTemplateId' is not null or undefined
            assertParamExists('editElementTemplate', 'elementTemplateId', elementTemplateId)
            const localVarPath = `/api/v1/element-templates/{elementTemplateId}`
                .replace(`{${"elementTemplateId"}}`, encodeURIComponent(String(elementTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeElementTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets Element Template
         * @summary Get Element Template by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementTemplate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getElementTemplate', 'id', id)
            const localVarPath = `/api/v1/element-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Element Template page for given page number, page size and sorting / filtering criteria
         * @summary Get Element Template Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementTemplates: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getElementTemplates', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/element-templates/element-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElementTemplateApi - functional programming interface
 * @export
 */
export const ElementTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElementTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Copies an Element Template
         * @summary Copy Element Template
         * @param {CopyElementTemplateRequest} copyElementTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyElementTemplate(copyElementTemplateRequest: CopyElementTemplateRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyElementTemplate(copyElementTemplateRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the element template created
         * @summary Create Element Template
         * @param {CreateElementTemplateRequest} createElementTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createElementTemplate(createElementTemplateRequest: CreateElementTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createElementTemplate(createElementTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Element Template
         * @summary Delete Element Template by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteElementTemplate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteElementTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch for the Element and Put for the Validations
         * @summary Edit an element template
         * @param {ChangeElementTemplateRequest} changeElementTemplateRequest 
         * @param {number} elementTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editElementTemplate(changeElementTemplateRequest: ChangeElementTemplateRequest, elementTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editElementTemplate(changeElementTemplateRequest, elementTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets Element Template
         * @summary Get Element Template by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElementTemplate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ElementTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElementTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Element Template page for given page number, page size and sorting / filtering criteria
         * @summary Get Element Template Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElementTemplates(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataDashboardElementTemplates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElementTemplates(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ElementTemplateApi - factory interface
 * @export
 */
export const ElementTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElementTemplateApiFp(configuration)
    return {
        /**
         * Copies an Element Template
         * @summary Copy Element Template
         * @param {CopyElementTemplateRequest} copyElementTemplateRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyElementTemplate(copyElementTemplateRequest: CopyElementTemplateRequest, id: number, options?: any): AxiosPromise<ElementTemplate> {
            return localVarFp.copyElementTemplate(copyElementTemplateRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the element template created
         * @summary Create Element Template
         * @param {CreateElementTemplateRequest} createElementTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElementTemplate(createElementTemplateRequest: CreateElementTemplateRequest, options?: any): AxiosPromise<ElementTemplate> {
            return localVarFp.createElementTemplate(createElementTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Element Template
         * @summary Delete Element Template by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElementTemplate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteElementTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch for the Element and Put for the Validations
         * @summary Edit an element template
         * @param {ChangeElementTemplateRequest} changeElementTemplateRequest 
         * @param {number} elementTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editElementTemplate(changeElementTemplateRequest: ChangeElementTemplateRequest, elementTemplateId: number, options?: any): AxiosPromise<ElementTemplate> {
            return localVarFp.editElementTemplate(changeElementTemplateRequest, elementTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets Element Template
         * @summary Get Element Template by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementTemplate(id: number, options?: any): AxiosPromise<ElementTemplate> {
            return localVarFp.getElementTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Element Template page for given page number, page size and sorting / filtering criteria
         * @summary Get Element Template Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementTemplates(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<PageDataDashboardElementTemplates> {
            return localVarFp.getElementTemplates(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ElementTemplateApi - interface
 * @export
 * @interface ElementTemplateApi
 */
export interface ElementTemplateApiInterface {
    /**
     * Copies an Element Template
     * @summary Copy Element Template
     * @param {CopyElementTemplateRequest} copyElementTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApiInterface
     */
    copyElementTemplate(copyElementTemplateRequest: CopyElementTemplateRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<ElementTemplate>;

    /**
     * Returns the element template created
     * @summary Create Element Template
     * @param {CreateElementTemplateRequest} createElementTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApiInterface
     */
    createElementTemplate(createElementTemplateRequest: CreateElementTemplateRequest, options?: AxiosRequestConfig): AxiosPromise<ElementTemplate>;

    /**
     * Deletes Element Template
     * @summary Delete Element Template by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApiInterface
     */
    deleteElementTemplate(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Patch for the Element and Put for the Validations
     * @summary Edit an element template
     * @param {ChangeElementTemplateRequest} changeElementTemplateRequest 
     * @param {number} elementTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApiInterface
     */
    editElementTemplate(changeElementTemplateRequest: ChangeElementTemplateRequest, elementTemplateId: number, options?: AxiosRequestConfig): AxiosPromise<ElementTemplate>;

    /**
     * Gets Element Template
     * @summary Get Element Template by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApiInterface
     */
    getElementTemplate(id: number, options?: AxiosRequestConfig): AxiosPromise<ElementTemplate>;

    /**
     * Returns Element Template page for given page number, page size and sorting / filtering criteria
     * @summary Get Element Template Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApiInterface
     */
    getElementTemplates(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<PageDataDashboardElementTemplates>;

}

/**
 * ElementTemplateApi - object-oriented interface
 * @export
 * @class ElementTemplateApi
 * @extends {BaseAPI}
 */
export class ElementTemplateApi extends BaseAPI implements ElementTemplateApiInterface {
    /**
     * Copies an Element Template
     * @summary Copy Element Template
     * @param {CopyElementTemplateRequest} copyElementTemplateRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApi
     */
    public copyElementTemplate(copyElementTemplateRequest: CopyElementTemplateRequest, id: number, options?: AxiosRequestConfig) {
        return ElementTemplateApiFp(this.configuration).copyElementTemplate(copyElementTemplateRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the element template created
     * @summary Create Element Template
     * @param {CreateElementTemplateRequest} createElementTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApi
     */
    public createElementTemplate(createElementTemplateRequest: CreateElementTemplateRequest, options?: AxiosRequestConfig) {
        return ElementTemplateApiFp(this.configuration).createElementTemplate(createElementTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Element Template
     * @summary Delete Element Template by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApi
     */
    public deleteElementTemplate(id: number, options?: AxiosRequestConfig) {
        return ElementTemplateApiFp(this.configuration).deleteElementTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch for the Element and Put for the Validations
     * @summary Edit an element template
     * @param {ChangeElementTemplateRequest} changeElementTemplateRequest 
     * @param {number} elementTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApi
     */
    public editElementTemplate(changeElementTemplateRequest: ChangeElementTemplateRequest, elementTemplateId: number, options?: AxiosRequestConfig) {
        return ElementTemplateApiFp(this.configuration).editElementTemplate(changeElementTemplateRequest, elementTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets Element Template
     * @summary Get Element Template by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApi
     */
    public getElementTemplate(id: number, options?: AxiosRequestConfig) {
        return ElementTemplateApiFp(this.configuration).getElementTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Element Template page for given page number, page size and sorting / filtering criteria
     * @summary Get Element Template Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementTemplateApi
     */
    public getElementTemplates(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return ElementTemplateApiFp(this.configuration).getElementTemplates(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
