import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 10" fill="none">
    <path
      d="M9.81749 7.51095L0.734452 0.40202C0.595166 0.292198 0.388916 0.391305 0.388916 0.570769L0.388916 2.6413C0.388916 2.77255 0.450523 2.89845 0.552309 2.9788L9.28981 9.8172C9.44072 9.93563 9.627 10 9.81883 10C10.0107 10 10.1969 9.93563 10.3478 9.8172L19.0827 2.9788C19.1871 2.89845 19.2461 2.77255 19.2461 2.6413V0.570769C19.2461 0.391305 19.0398 0.292198 18.9005 0.40202L9.81749 7.51095Z"
      fill="currentColor"
    />
  </svg>
);

export const ChevronDownAltIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
