import { WizardPlacement } from "@ni/common/types";
import { Phase } from "@ni/sdk/models";

interface CurrentPlacement {
  phase: number;
  pageKey: string;
}

const getPageOrder = (phaseOrder: number, pageKey: string, phases: Phase[]): number | undefined => {
  const phase = phases.find(p => p.order === phaseOrder);
  const page = (phase?.pages ?? []).find(p => p.code === pageKey);
  return page?.order;
};

export const checkIfWizardPageIsCompleted = (
  currentPlacement: CurrentPlacement,
  lastSubmittedStep: WizardPlacement,
  phases: Phase[],
): boolean => {
  if (currentPlacement.phase < lastSubmittedStep.phase) {
    return true;
  }

  const pageOrder = getPageOrder(currentPlacement.phase, currentPlacement.pageKey, phases) ?? 0;
  const currentPageOrder = getPageOrder(lastSubmittedStep.phase, lastSubmittedStep.pageKey, phases);

  return currentPlacement.phase === lastSubmittedStep.phase && pageOrder !== undefined && pageOrder < currentPageOrder!;
};
