import { FC } from "react";
import { Form } from "antd";

import { LTY_ACCR_CAP_CTD, LTY_ACCR_RATE } from "@ni/common/constants";
import { useHydrateForm, useNumberOfTotalPointesCapDecimals, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

export const PointAccrualCalculation: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);
  const { processWizardRequest } = useLoyaltyWizardApi();
  const decimalScale = useNumberOfTotalPointesCapDecimals(wizardResponse?.tenant);

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      strings: [LTY_ACCR_RATE, LTY_ACCR_CAP_CTD],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      const collectedValues: LoyaltyWizardRequest["collectedValues"] = {};
      Object.keys(values).forEach((key: string) => {
        collectedValues[key] = values[key]?.toString().replace(/\s/g, "") ?? "";
      });

      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={false}
      pageTitle="Point Accrual Calculation"
      pageSubtitle="Points are earned as a percentage of eligible transactions, capped at the defined upper threshold"
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Number
        formItemOptions={{
          name: LTY_ACCR_RATE,
          required: true,
          rules: [{ required: true }],
          label: "Points accrual percentage rate",
          tooltip:
            "This parameter specifies the rate at which points are accrued for the transaction made by the customer. This field is percentage value,  for example if it is set as 1% then points are accrued at the rate of 1% for transactions.",
        }}
        min={0}
        precision={2}
        addonAfter="%"
        stringMode={true}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: LTY_ACCR_CAP_CTD,
          label: "Total points cap per cycle",
          tooltip:
            "This parameter specifies about the maximum points that can be earned under one program in the single billing cycle. For example, if maximum cap is defined as 500, then 500 is the maximum points that can be earned on cycle to date transactions even though total accrued points are more than 500.",
        }}
        min={0}
        precision={decimalScale}
        stringMode={true}
      />
    </CustomFormWrapper>
  );
};
