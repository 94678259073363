import { CSSProperties, FC, HTMLAttributes, Key, useEffect, useState } from "react";
import { Button, Flex, Form, Input, Modal, notification, Space, Switch, Table, TableColumnType, Tooltip } from "antd";
import { arrayMoveImmutable } from "array-move";
import { useNavigate } from "react-router-dom";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";

import { CloseCircleOutlined, EditOutlined, HolderOutlined, SettingOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { CopyIcon } from "@ni/common/icons";
import { FormValues } from "@ni/common/types";
import { getErrorInstance, sortLoyaltyPrograms } from "@ni/common/utils";
import { LoyaltyProgramApi } from "@ni/sdk/apis";
import { LoyaltyProgram, LoyaltyProgramState, LoyaltyProgramType, Product } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface DraggableBodyRowProps {
  className: string;
  style: CSSProperties;
  "data-row-key": Key;
  dataSource: { index: Key }[];
}

interface LoyaltyTableProps {
  productId: number;
}

const loyaltyProgramApi = new LoyaltyProgramApi();

const DraggableIcon = () => <HolderOutlined style={{ cursor: "move", color: "#8A8A8D", fontSize: "1.25rem" }} />;
const UndraggableIcon = () => <CloseCircleOutlined style={{ color: "#d93737", fontSize: "1.25rem" }} />;

const SortableItem = SortableElement((props: HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
const SortableBody = SortableContainer((props: HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);
const SortableHandler = SortableHandle((props: HTMLAttributes<HTMLTableSectionElement>) => <div {...props} />);

export const LoyaltyTable: FC<LoyaltyTableProps> = ({ productId }) => {
  const [modalForm] = Form.useForm<FormValues>();
  const [modalDuplicateForm] = Form.useForm<FormValues>();

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<LoyaltyProgram>({});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading", false);
  const [, setCurrentProduct] = useReduxState<Product>("currentProduct", {});
  const [loyaltyPrograms, setLoyaltyPrograms] = useReduxState<LoyaltyProgram[]>("loyaltyPrograms", []);

  const dragIcon = (state: LoyaltyProgramState | undefined) => {
    return (
      <SortableHandler>
        {state === LoyaltyProgramState.INACTIVE ? <UndraggableIcon /> : <DraggableIcon />}
      </SortableHandler>
    );
  };

  const DraggableBodyRow: FC<DraggableBodyRowProps> = ({ className, style, ...restProps }) => {
    const index = loyaltyPrograms.findIndex(x => x.id === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const handleRenameClick = (object: LoyaltyProgram) => {
    modalForm.setFieldsValue({
      name: object.name,
    });
    setSelectedRow(object);
    setModalOpen(true);
  };

  const handleEditClick = (object: LoyaltyProgram) => {
    navigate(`../edit-program/${object.id}/point-accrual-setup`);
  };

  const handleDuplicateClick = (object: LoyaltyProgram) => {
    modalDuplicateForm.setFieldsValue({
      newDisplayName: `${object.name} - copy`,
    });
    setSelectedRow(object);
    setDuplicateModalOpen(true);
  };

  const onSortEnd = async ({ oldIndex, newIndex }: SortEnd) => {
    const draggedItem = loyaltyPrograms[oldIndex];
    const targetItem = loyaltyPrograms[newIndex];

    if (
      draggedItem.state === LoyaltyProgramState.ACTIVE &&
      targetItem.state === LoyaltyProgramState.ACTIVE &&
      oldIndex !== newIndex &&
      draggedItem.type === targetItem.type
    ) {
      const newData = arrayMoveImmutable(loyaltyPrograms.slice(), oldIndex, newIndex).filter(el => !!el);

      const apiData = newData.map((item, index) => ({
        loyaltyId: item.id!,
        newOrderNumber: index + 1,
      }));

      const newLoyaltyCodes = await loyaltyProgramApi.changeLoyaltyProgramOrder({
        loyaltyIdToLoyaltyOrder: apiData,
      });

      setLoyaltyPrograms(prevData =>
        sortLoyaltyPrograms(
          prevData.map(item => {
            const updatedItem = newLoyaltyCodes?.data?.loyaltyIdToLoyaltyCodes.find(
              updated => updated.loyaltyId === item.id,
            );

            if (updatedItem) {
              return { ...item, loyaltyCode: updatedItem.newLoyaltyCode };
            }
            return item;
          }),
        ),
      );
    } else if (draggedItem.state !== targetItem?.state) {
      notification.error({
        message: "Invalid Order",
        description: "Inactive programs can't be listed before active programs",
      });
    } else if (draggedItem.type !== targetItem.type && draggedItem.state === LoyaltyProgramState.ACTIVE) {
      notification.error({
        message: "Invalid Order",
        description: "The order of the transaction-based programs can't be lower programs of cumulative type",
      });
    }
  };

  useEffect(() => {
    setCurrentProduct(product => ({
      ...product,
      shortLoyaltyPrograms: loyaltyPrograms.map(program => ({
        ...program,
        programPctValues: [],
        programValues: [],
      })),
    }));
  }, [loyaltyPrograms, setCurrentProduct]);

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle={true}
      disableAutoscroll={true}
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const capitalizeFirstLetter = (input: string) => {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  };

  const toggleLoyaltyProgram = async (changedValues: FormValues, object: LoyaltyProgram) => {
    setIsLoading(true);
    try {
      await loyaltyProgramApi.edit1(
        { state: changedValues["state"] ? LoyaltyProgramState.ACTIVE : LoyaltyProgramState.INACTIVE },
        object.id as number,
      );

      const programsResponse = await loyaltyProgramApi.getLoyaltyProgramsByProductId(productId);
      setLoyaltyPrograms(sortLoyaltyPrograms(programsResponse.data));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errorInstance = getErrorInstance(err);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response.status ?? 400} <br />
            {errorInstance.response.data.errorMessage}
          </div>
        ),
      });
    }
  };

  const renameLoyaltyProgram = async (values: FormValues) => {
    setModalIsLoading(true);
    try {
      const { data } = await loyaltyProgramApi.edit1(values, selectedRow.id as number);
      setLoyaltyPrograms(prevState => [...prevState.map(obj => (obj.id === data.id ? data : obj))]);
    } catch (err) {
      // ..
    } finally {
      setModalIsLoading(false);
      setModalOpen(false);
    }
  };

  const duplicateLoyaltyProgram = async (values: FormValues) => {
    setModalIsLoading(true);
    try {
      const { data } = await loyaltyProgramApi.copy(values, selectedRow.id as number);
      setLoyaltyPrograms(prevState => sortLoyaltyPrograms([...prevState, data]));
    } catch (err) {
      const errorInstance = getErrorInstance(err);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response.status ?? 400} <br />
            {errorInstance.response.data.errorMessage}
          </div>
        ),
      });
    } finally {
      setModalIsLoading(false);
      setDuplicateModalOpen(false);
    }
  };

  const columns: TableColumnType<LoyaltyProgram>[] = [
    {
      key: "switchHandler",
      width: "8%",
      render: (_: string, object: LoyaltyProgram) => {
        const isNormalSwitchDisabled =
          loyaltyPrograms.filter(
            program => program.state === LoyaltyProgramState.ACTIVE && program.type === LoyaltyProgramType.NORMAL,
          ).length >= 5;

        const isCumulativeSwitchDisabled =
          loyaltyPrograms.filter(
            program => program.state === LoyaltyProgramState.ACTIVE && program.type === LoyaltyProgramType.CUMULATIVE,
          ).length >= 5;

        return (
          <Space direction="horizontal" size="middle">
            {dragIcon(object?.state)}
            <Form
              disabled={
                (object.state === LoyaltyProgramState.INACTIVE &&
                  object.type === LoyaltyProgramType.NORMAL &&
                  isNormalSwitchDisabled) ||
                (object.state === LoyaltyProgramState.INACTIVE &&
                  object.type === LoyaltyProgramType.CUMULATIVE &&
                  isCumulativeSwitchDisabled)
              }
              initialValues={{ state: object.state === LoyaltyProgramState.ACTIVE }}
              onValuesChange={changedValues => toggleLoyaltyProgram(changedValues as FormValues, object)}
            >
              <Form.Item name="state" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Form>
          </Space>
        );
      },
    },
    {
      title: "Program name",
      dataIndex: ["name"],
      key: "programName",
      width: "22%",
      ellipsis: true,
    },
    {
      title: "Accrual type",
      dataIndex: ["type"],
      key: "accrualType",
      width: "18%",
      render: (_: string, { type }: LoyaltyProgram) =>
        capitalizeFirstLetter(type === LoyaltyProgramType.NORMAL ? "Transaction based" : "Cumulative" ?? ""),
    },
    {
      key: "loyaltyActions",
      width: "10%",
      render: (_: string, object: LoyaltyProgram) => (
        <Flex justify="start" gap={8} className="w-p-100">
          <Tooltip title="Rename">
            <Button
              type="text"
              htmlType="button"
              icon={<EditOutlined />}
              className={styles["lty-action-button"]}
              onClick={() => handleRenameClick(object)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="text"
              htmlType="button"
              icon={<SettingOutlined />}
              className={styles["lty-action-button"]}
              onClick={() => handleEditClick(object)}
            />
          </Tooltip>
          <Tooltip title="Duplicate">
            <Button
              type="text"
              htmlType="button"
              icon={<CopyIcon />}
              className={styles["lty-action-button"]}
              onClick={() => handleDuplicateClick(object)}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex>
        <Flex flex={0.15}>
          <Table
            className={styles["consistant-part-table"]}
            rowKey="id"
            columns={
              [
                {
                  title: "Program Code",
                  dataIndex: ["loyaltyCode"],
                  key: "loyaltyCode",
                  width: 300,
                  render: (_: string, { loyaltyCode }) => (
                    <Space direction="horizontal">
                      {loyaltyCode?.toUpperCase() ?? ""}
                      <Button
                        type="text"
                        htmlType="button"
                        icon={<CopyIcon />}
                        className={styles["lty-action-button-spacing"]}
                      />
                    </Space>
                  ),
                },
              ] as TableColumnType<LoyaltyProgram>[]
            }
            dataSource={loyaltyPrograms}
            pagination={false}
          />
        </Flex>
        <Flex flex={0.85}>
          <Table
            className={styles["dragable-part-table"]}
            rowKey="id"
            columns={columns}
            dataSource={loyaltyPrograms}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            pagination={false}
            loading={isLoading}
          />
        </Flex>
      </Flex>

      <Modal
        title="Rename Loyalty Program Template"
        open={modalOpen}
        width="500px"
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="back" disabled={modalIsLoading} onClick={() => setModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={modalIsLoading} onClick={modalForm.submit}>
            Save
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          autoComplete="off"
          layout="vertical"
          disabled={modalIsLoading}
          onFinish={renameLoyaltyProgram}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ type: "string", required: true, max: 64, message: "Cannot be empty. Max length is 64 letters" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Duplicate Loyalty Program Template"
        open={duplicateModalOpen}
        width="500px"
        onCancel={() => setDuplicateModalOpen(false)}
        footer={[
          <Button key="back" disabled={modalIsLoading} onClick={() => setDuplicateModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={modalIsLoading} onClick={modalDuplicateForm.submit}>
            Duplicate
          </Button>,
        ]}
      >
        <Form
          form={modalDuplicateForm}
          autoComplete="off"
          layout="vertical"
          disabled={modalIsLoading}
          onFinish={duplicateLoyaltyProgram}
        >
          <Form.Item
            name="newDisplayName"
            label="Name"
            rules={[{ type: "string", required: true, max: 64, message: "Cannot be empty. Max length is 64 letters" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
