import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6 0H2.4C1.76348 0 1.15303 0.252853 0.702944 0.702934C0.252856 1.15302 0 1.76346 0 2.39997V10.3999C0 11.0364 0.252856 11.6468 0.702944 12.0969C1.15303 12.547 1.76348 12.7998 2.4 12.7998H11.672L14.632 15.7678C14.7068 15.8419 14.7954 15.9006 14.8929 15.9404C14.9903 15.9802 15.0947 16.0004 15.2 15.9998C15.3049 16.0025 15.4091 15.9806 15.504 15.9358C15.6501 15.8758 15.7752 15.7738 15.8634 15.6429C15.9517 15.5119 15.9992 15.3577 16 15.1998V2.39997C16 1.76346 15.7471 1.15302 15.2971 0.702934C14.847 0.252853 14.2365 0 13.6 0ZM14.4 13.2718L12.568 11.4318C12.4932 11.3577 12.4046 11.299 12.3071 11.2592C12.2097 11.2194 12.1053 11.1992 12 11.1998H2.4C2.18783 11.1998 1.98434 11.1156 1.83431 10.9655C1.68429 10.8155 1.6 10.612 1.6 10.3999V2.39997C1.6 2.1878 1.68429 1.98432 1.83431 1.83429C1.98434 1.68426 2.18783 1.59998 2.4 1.59998H13.6C13.8122 1.59998 14.0157 1.68426 14.1657 1.83429C14.3157 1.98432 14.4 2.1878 14.4 2.39997V13.2718ZM8 7.99989C7.84177 7.99989 7.6871 8.04681 7.55554 8.13471C7.42398 8.22262 7.32145 8.34756 7.2609 8.49373C7.20035 8.63991 7.1845 8.80077 7.21537 8.95595C7.24624 9.11113 7.32243 9.25367 7.43431 9.36555C7.5462 9.47744 7.68874 9.55363 7.84393 9.58449C7.99911 9.61536 8.15997 9.59952 8.30615 9.53897C8.45233 9.47842 8.57727 9.37589 8.66518 9.24433C8.75308 9.11277 8.8 8.9581 8.8 8.79988C8.8 8.58771 8.71571 8.38423 8.56569 8.2342C8.41566 8.08417 8.21217 7.99989 8 7.99989ZM8 3.19996C7.78783 3.19996 7.58434 3.28424 7.43431 3.43427C7.28429 3.58429 7.2 3.78777 7.2 3.99994V6.39991C7.2 6.61208 7.28429 6.81556 7.43431 6.96559C7.58434 7.11562 7.78783 7.1999 8 7.1999C8.21217 7.1999 8.41566 7.11562 8.56569 6.96559C8.71571 6.81556 8.8 6.61208 8.8 6.39991V3.99994C8.8 3.78777 8.71571 3.58429 8.56569 3.43427C8.41566 3.28424 8.21217 3.19996 8 3.19996Z"
      fill="#8A8A8D"
    />
  </svg>
);

export const MessageIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
