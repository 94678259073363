export enum CardBillingFormNames {
  cashAtmEnable = "c-b-cash-atm-enable",
  cashAtmMaxNr = "nic-c-b-cash-atm-max-nr",
  cashAtmMaxAm = "nic-c-b-cash-atm-max-am",
  cashAtmMaxSingle = "nic-c-b-cash-atm-max-single",

  retailEnable = "c-b-retail-enable",
  retailMaxNr = "nic-c-b-retail-max-nr",
  retailMaxAm = "nic-c-b-retail-max-am",
  retailMaxSingle = "nic-c-b-retail-max-single",
}

export enum CardBillingSwitchHeaders {
  allCashAtmEnable = "card-monthly-all-cash-enable",
  allRetailEnable = "card-monthly-all-retail-enable",
}

export const checkboxFieldsPage35: { [key: string]: string[] } = {
  [CardBillingFormNames.cashAtmEnable]: [
    CardBillingFormNames.cashAtmMaxNr,
    CardBillingFormNames.cashAtmMaxAm,
    CardBillingFormNames.cashAtmMaxSingle,
  ],
  [CardBillingFormNames.retailEnable]: [
    CardBillingFormNames.retailMaxNr,
    CardBillingFormNames.retailMaxAm,
    CardBillingFormNames.retailMaxSingle,
  ],
};

export const switchHeader35: { [key: string]: string[] } = {
  [CardBillingSwitchHeaders.allCashAtmEnable]: [CardBillingFormNames.cashAtmEnable],
  [CardBillingSwitchHeaders.allRetailEnable]: [CardBillingFormNames.retailEnable],
};
