/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { FC, Fragment, useMemo } from "react";
import { Space, Typography } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { breadcrumbArrow } from "@ni/common/assets";
import { InsuranceProgram, Tenant } from "@ni/sdk/models";

import { useReduxState } from "../../../../../hooks/src";

import {
  Breadcrumb,
  getPathLevel,
  getPctBreadcrumbs,
  getProductBreadcrumbs,
  getTenantBreadcrumbs,
  Level,
} from "./constants";

import styles from "./styles.module.scss";

const Seperator = () => <img id="breadcrumb-arrow-id" alt="breadcrumb-arrow" src={breadcrumbArrow} />;

export const Breadcrumbs: FC = ({ rootName = "Home" }: { rootName?: string }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [currentTenant] = useReduxState<Tenant>("tenant");
  const [backupTenant] = useReduxState<Tenant | undefined>("currentTenant");
  const [insurancePrograms] = useReduxState<InsuranceProgram[]>("FocusedProductInsurancePrograms", []);

  const { tenant, productInsurancePrograms } = useMemo(() => {
    const currentTenantId = +(id as unknown as number);
    if (currentTenantId) {
      if (currentTenant && currentTenant?.id === currentTenantId)
        return { tenant: currentTenant, productInsurancePrograms: insurancePrograms };
      if (backupTenant && backupTenant?.id === currentTenantId)
        return { tenant: backupTenant, productInsurancePrograms: insurancePrograms };
    }
    return { tenant: null, productInsurancePrograms: null };
  }, [backupTenant, currentTenant, id, insurancePrograms]);

  const shouldRender = useMemo(() => getPathLevel(pathname) !== Level.Other, [pathname]);

  const { breadcrumbs, hasRoot } = useMemo(() => {
    let breadcrumbs: Breadcrumb[] = [];
    let hasRoot: boolean = false;
    if (getPathLevel(pathname) === Level.Tenant) {
      hasRoot = true;
      breadcrumbs = getTenantBreadcrumbs(pathname, tenant);
    } else if (getPathLevel(pathname) === Level.Product) {
      hasRoot = true;
      breadcrumbs = getProductBreadcrumbs(pathname, tenant, productInsurancePrograms);
    } else if (getPathLevel(pathname) === Level.Pct) {
      hasRoot = true;
      breadcrumbs = getPctBreadcrumbs(pathname, tenant, productInsurancePrograms);
    }

    return { breadcrumbs, hasRoot };
  }, [pathname, productInsurancePrograms, tenant]);

  const handleRootNavigation = () => {
    navigate("/");
  };

  return (
    shouldRender && (
      <Space direction="horizontal" className={styles["main-container"]} wrap={true}>
        {hasRoot && (
          <Typography.Link className={styles["previous-page-link"]} onClick={handleRootNavigation}>
            {rootName}
          </Typography.Link>
        )}
        {breadcrumbs.map((x, index) => (
          <Fragment key={x.name + (x?.path ?? index)}>
            {hasRoot ? <Seperator /> : index > 0 ? <Seperator /> : null}
            <Typography.Link
              className={index + 1 === breadcrumbs.length ? styles["active-page-link"] : styles["previous-page-link"]}
              onClick={() => {
                if (x?.path) navigate(x.path);
              }}
            >
              {x.name}
            </Typography.Link>
          </Fragment>
        ))}
      </Space>
    )
  );
};
