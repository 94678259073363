import { SelectOption } from "@ni/common/types";

export const NIC_INT_BASE_RADIO: SelectOption[] = [
  { value: "365", label: "Actual number days in a year 365/366" },
  { value: "Fixed 365", label: "Fixed 365" },
  { value: "Fixed 366", label: "Fixed 366" },
  {
    value: "360 with USE_MONTH_WEIGHT=N",
    label: "360 per year / actual days per month",
    tooltip: "Daily interest for a day of any month is calculated as follows: Account balance * annual rate / 360",
  },
  {
    value: "-360",
    label: "360 per year / 30 per month",
    tooltip:
      "Daily interest for a day of any month is calculated as follows: Account balance * annual rate / 360, but: No interest is accrued for the 31st day of a month. An interest amount for the last day of February is calculated as follows: If February consists of 28 days, interest for three days is accrued on 28 February. If February consists of 29 days, interest for two days is accrued on 29 February.",
  },
  {
    value: "360 with USE_MONTH_WEIGHT=Y",
    label: "360 per year where each month has equal weight 1/12 of a year",
    tooltip:
      "For example, higher interest is accrued for a day in February than is accrued for the same amount for a day in January. E.g. for a day in February (28 days) is calculated as follows: Account balance * annual rate / (12*28) For a day in January (31 days) is calculated as follows: Account balance * annual rate / (12*31)",
  },
];

export const NIC_DD_WR_ASK100_SELECT: SelectOption[] = [
  { value: "N", label: "- Not specified -" },
  { value: "2", label: "Level 2 (1-29 days)" },
  { value: "3", label: "Level 3 (30-59 days)" },
  { value: "4", label: "Level 4 (60-89 days)" },
  { value: "5", label: "Level 5 (90-119 days)" },
  { value: "6", label: "Level 6 (120-149 days)" },
  { value: "7", label: "Level 7 (150-179 days)" },
  { value: "8", label: "Level 8 (180-209 days)" },
  { value: "9", label: "Level 9 (210+ days)" },
];

export const NIC_DLQ_LEVEL_TO_FREEZE_SELECT: SelectOption[] = [
  { value: "N", label: "Do not freeze" },
  { value: "07", label: "Level 7 (150+ days)" },
  { value: "08", label: "Level 8 (180+ days)" },
  { value: "09", label: "Level 9 (210+ days)" },
];

export const NIC_PAST_DUE_DAYS_CALC_MODE_SELECT: SelectOption[] = [
  { value: "CONV", label: "Normal" },
  { value: "CONV_TRIMMED", label: "Trimmed" },
];
