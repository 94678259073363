import { FullDashboardProduct } from "@ni/sdk/models";

interface Options {
  name?: string;
  code: string;
  default?: boolean | string | number;
  products?: FullDashboardProduct[] | undefined;
}

export const productsOption: Options = {
  code: "productIds",
};
