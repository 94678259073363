import React from "react";

import { FileCardDurationEnum, FileCardModel, FileCardSourceEnum, FileSection, FileTypeEnum } from "@ni/common/types";

export const reportsList: FileCardModel[] = [
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Customer Name And Address Maintenance Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Customer  level maintenance details. This will help the bank to identify and validate all the changes happened day before at the Customer demographic level.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.JSON,
    title: "Bulk Demographic Update & Response Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.FileBased,
    description:
      "Response report for application boarding file/demographic update file. This will help in reconciling and identifying the rejected records.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "New Accounts Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "New accounts boarded into the system on previous day.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Account Maintenance Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Account level maintenance details. This will help the bank to identify and validate all the changes happened day before at the contract level.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Card Maintenance Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Card level maintenance details. This will help the bank to identify and validate all the changes happened day before at the card level.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Card Production Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Daily report of all the cards which are issued of previous day. This will help in identifying list of cards which are produced on previous day.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Card Activation Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Activated cards report in particular day.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Card Status Change Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Daily report of all the cards for which the status is changed on previous day. This will help in identifying list of cards whose status is changed as of previous day.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Card Renewal Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Renewed cards details.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "Card Expiry Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "Contains cards details which are about to expire.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.JSON,
    title: "Card Carrier File",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.VendorClient,
    description:
      "The card carrier extract holds the address details and other demographic information required by the embossing team to mail the actual plastic card to the customer.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Embosser details report specs",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "This report is generated based on the generated embossing files to be provided for the bank",
    exportLink: "https://network.ae/",
  },
];

export const reportsTokenizationList: FileCardModel[] = [
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.HTML,
    title: "Monthly DPAN usage frequency",
    duration: FileCardDurationEnum.Monthly,
    source: FileCardSourceEnum.Client,
    description:
      "This report is to provide the usage frequency and percentage of DPANS (Token Number) used in the current month tokenized transactions.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.HTML,
    title: "Monthly issuer metrics report",
    duration: FileCardDurationEnum.Monthly,
    source: FileCardSourceEnum.Client,
    description:
      "This report is to provide the Activities of Tokens & various Net total details of Tokenized transactions successfully posted in a month.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.HTML,
    title: "Monthly decline metrics",
    duration: FileCardDurationEnum.Monthly,
    source: FileCardSourceEnum.Client,
    description: "It contains all the declined transaction metrics for Apple pay.",
    exportLink: "https://network.ae/",
  },

  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.HTML,
    title: "Monthly merchant report",
    duration: FileCardDurationEnum.Monthly,
    source: FileCardSourceEnum.Client,
    description:
      "This will be generated monthly based on the apple pay DPAN spend on merchant. Top 100 merchants are listed in the report where maximum amount is spent by Issuers apple pay DPAN users.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "TLCM API report",
    duration: FileCardDurationEnum.DailyAndMonthly,
    source: FileCardSourceEnum.Client,
    description: "This daily & Monthly report contains all API requests sent during the day.",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.HTML,
    title: "Quarterly active DPAN report",
    duration: FileCardDurationEnum.Quarterly,
    source: FileCardSourceEnum.Client,
    description: "Report contains active card on Apple Pay throughout the last quarter.",
    exportLink: "https://network.ae/",
  },
];

export const reportsTransactionsList: FileCardModel[] = [
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Outstanding Authorization",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "The report lists all the authorizations which are not posted to cardholder as transaction in the outstanding auth file and once a matching transaction record during the settlement process the same record is dropped from the outstanding auth file",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Transaction Journal",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "This report lists all of today’s transactions. The totals include all posted credits, debits",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Generated Transaction Journal",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "This report lists all of the system-generated transactions",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Trial Balance",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "This report contains data on the accounts with current balances, credit limit and etc. It also reports the total at account level. In the total it specifies the total number of active, dormant, p/l accounts, total credit limit, current balance, CD, RD levels and the total due for the level",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Forex Markup",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "The report contains details on Mark-Up Fees as well as information on transaction amount currency, settlement amount in USD, Mark-up percentage, billing amount in USD",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Activity Recap",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "Activity recap Report provides summary totals for all transaction message types with activity during the day or month or year",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Rejected Transaction",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "The report contains data on all rejected transactions of previous day",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.TXT,
    title: "Late Presentment Transactions",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description:
      "This report list the transaction for which clearing record is received late from the scheme. Transaction is reported as per below criteria\n" +
      "Posted transaction for a transaction has happened on 10 days before for cash\n" +
      "Posted transaction for a transaction has happened on 30 days before for retail",
    exportLink: "https://network.ae/",
  },
];

export const reportsOthersList: FileCardModel[] = [
  {
    tag: "wizard_reports_",
    fileType: FileTypeEnum.CSV,
    title: "User Maintenance Report",
    duration: FileCardDurationEnum.Daily,
    source: FileCardSourceEnum.Client,
    description: "This report contains addition/modification or deletion of system user.",
    exportLink: "https://network.ae/",
  },
];

export const reportsWizardPage: FileSection[] = [
  {
    tag: "wizard_reports_reports_list_",
    title: "CUSTOMER, ACCOUNT AND CARD",
    files: reportsList,
  },
  {
    tag: "wizard_reports_tokenization_list_",
    title: "TOKENIZATION",
    files: reportsTokenizationList,
    dependsOn: {
      fieldLocation: ["product", "productValues"],
      fieldCode: "nic-card-subtype-token-used",
      searchForFieldInArray: true,
      disabledIfValueEquals: "false",
      defaultDisabled: true,
      disabledContent: React.createElement(
        "div",
        {
          key: "div_wizard_tokenization_disabled",
          className: "alert-div",
        },
        [
          React.createElement("span", { key: "div_wizard_tokenization_span" }, [
            "The reports are not applicable because ",
            React.createElement("strong", { key: "wizard_tokenization_strong" }, ["Tokenization"]),
            " option was not selected.",
          ]),
        ],
      ),
    },
  },
  {
    tag: "wizard_reports_transactions_list_",
    title: "TRANSACTIONS",
    files: reportsTransactionsList,
    dependsOnOptional: {
      showIf: "balance-owner",
      showIfValueEquals: "CMS",
    },
  },
  {
    tag: "wizard_reports_others_list_",
    title: "OTHERS",
    files: reportsOthersList,
  },
];
