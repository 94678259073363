import { useEffect, useState } from "react";

import { FeeField, FormValues } from "@ni/common/types";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductValue } from "@ni/sdk/models";

import { TabKeysCTF } from "../../../../types";

import {
  CTF_ATM_EMPTY_AMOUNT_VALUES,
  CTF_ATM_EMPTY_REG_VALUES,
  CTF_CASH_EMPTY_AMOUNT_VALUES,
  CTF_CASH_EMPTY_REG_VALUES,
  CTF_INTRAREG_EMPTY,
  CTF_P2P_DEBIT_EMPTY_REG_VALUES,
  CTF_P2P_PAYM_EMPTY_REG_VALUES,
  CTF_QUASI_EMPTY_REG_VALUES,
  CTF_REFUND_EMPTY_REG_VALUES,
  CTF_RETAIL_EMPTY_REG_VALUES,
} from "./constants";

interface DynamicFields {
  defaultFee: FeeField[];
  onUsFee?: FeeField[];
  domesticFee?: FeeField[];
  additionalFee?: FeeField[];
  onUsAdditionalFee?: FeeField[];
  domesticAdditionalFee?: FeeField[];
  intraregionalFee?: FeeField[];
  intraregionalAdditionalFee?: FeeField[];
}

interface FieldsDependency {
  reg: boolean;
  amount: boolean;
  intrareg: boolean;
  productValues: ProductValue[];
}

const withAmountSwitch = ["atm", "pos"];

export const createKey = (key: TabKeysCTF, withCash?: boolean, alternative?: boolean) => {
  const keyList: Record<TabKeysCTF, string> = {
    pos: withCash ? "cash-pos" : alternative ? "cash" : "pos",
    credit: alternative ? "refund" : "credit",
    "ch-debit": alternative ? "p2p-deb" : "ch-debit",
    "ch-paym": alternative ? "p2p-paym" : "ch-paym",
    "retail-qsi": alternative ? "quasi" : "retail-qsi",
    atm: withCash ? "cash-atm" : "atm",
    retail: "retail",
    "": "",
  };

  return keyList[key];
};

const getFields = (key: TabKeysCTF, deps: FieldsDependency): DynamicFields => {
  const keyWithCash = createKey(key, true);

  const initialFields: DynamicFields = {
    defaultFee: [
      {
        name: key === "retail-qsi" ? `nic-ips-${keyWithCash}-bf-base` : `nic-ips-${keyWithCash}-def-bf-base`,
        type: "default",
      },
      {
        name: key === "retail-qsi" ? `nic-ips-${keyWithCash}-bf-fee-rate` : `nic-ips-${keyWithCash}-def-bf-fee-rate`,
        type: "percentage",
      },
      {
        name: key === "retail-qsi" ? `nic-ips-${keyWithCash}-bf-min` : `nic-ips-${keyWithCash}-def-bf-min`,
        type: "min",
      },
      {
        name: key === "retail-qsi" ? `nic-ips-${keyWithCash}-bf-max` : `nic-ips-${keyWithCash}-def-bf-max`,
        type: "max",
      },
    ] as FeeField[],
  };

  let fields: DynamicFields = initialFields;

  if (deps.reg) {
    fields = {
      ...fields,

      onUsFee: [
        {
          name: `nic-ips-${keyWithCash}-onus-bf-base`,
          type: "default",
        },
        {
          name: `nic-ips-${keyWithCash}-onus-bf-fee-rate`,
          type: "percentage",
        },
        {
          name: `nic-ips-${keyWithCash}-onus-bf-min`,
          type: "min",
        },
        {
          name: `nic-ips-${keyWithCash}-onus-bf-max`,
          type: "max",
        },
      ] as FeeField[],

      domesticFee: [
        {
          name: `nic-ips-${keyWithCash}-dom-bf-base`,
          type: "default",
        },
        {
          name: `nic-ips-${keyWithCash}-dom-bf-fee-rate`,
          type: "percentage",
        },
        {
          name: `nic-ips-${keyWithCash}-dom-bf-min`,
          type: "min",
        },
        {
          name: `nic-ips-${keyWithCash}-dom-bf-max`,
          type: "max",
        },
      ] as FeeField[],
    };
  }

  if (deps.amount) {
    fields = {
      ...fields,

      defaultFee: [
        {
          //   name: `def-tier-min-${createKey(key, false, true)}`,
          name: `nic-ips-cash-${key}-def-t1-max`,
          type: "default",
          props: {
            value:
              (getFormValueFromProductValues(
                deps.productValues,
                `nic-ips-cash-${key}-def-t1-max`,
                "number",
              ) as number) ?? 0,
            disabled: true,
          },
        },
        {
          name: `def-tier-max-${createKey(key, false, true)}`,
          type: "default",
          props: {
            value: "9999999999.99",
            disabled: true,
          },
        },
        ...fields.defaultFee,
      ],

      additionalFee: [
        {
          name: `nic-ips-cash-${key}-def-t1-min`,
          type: "default",
          props: {
            value: 0,
            disabled: true,
          },
        },
        {
          name: `nic-ips-cash-${key}-def-t1-max`,
          type: "default",
          props: {
            value: 0,
          },
        },
        {
          name: `nic-ips-cash-${key}-def-bf-t1-base`,
          type: "default",
        },
        {
          name: `nic-ips-cash-${key}-def-bf-t1-fee-rate`,
          type: "percentage",
        },
        {
          name: `nic-ips-cash-${key}-def-bf-t1-min`,
          type: "min",
        },
        {
          name: `nic-ips-cash-${key}-def-bf-t1-max`,
          type: "max",
        },
      ],
    };
  }

  if (deps.intrareg) {
    fields = {
      ...fields,
      intraregionalFee: [
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-base`,
          type: "default",
        },
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-fee-rate`,
          type: "percentage",
        },
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-min`,
          type: "min",
        },
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-max`,
          type: "max",
        },
      ],
    };
  }

  if (deps.reg && deps.amount) {
    fields = {
      ...fields,

      onUsAdditionalFee: [
        {
          name: `nic-ips-cash-${key}-onus-t1-min`,
          type: "default",
          props: {
            value: "0.00",
            disabled: true,
          },
        },
        {
          name: `nic-ips-cash-${key}-onus-t1-max`,
          type: "default",
          props: {
            value: "0.00",
          },
        },
        {
          name: `nic-ips-cash-${key}-onus-bf-t1-base`,
          type: "default",
        },
        {
          name: `nic-ips-cash-${key}-onus-bf-t1-fee-rate`,
          type: "percentage",
        },
        {
          name: `nic-ips-cash-${key}-onus-bf-t1-min`,
          type: "min",
        },
        {
          name: `nic-ips-cash-${key}-onus-bf-t1-max`,
          type: "max",
        },
      ],

      onUsFee: [
        {
          name: `nic-ips-cash-${key}-onus-t1-max`,
          type: "default",
          props: {
            value:
              (getFormValueFromProductValues(
                deps.productValues,
                `nic-ips-cash-${key}-onus-t1-max`,
                "number",
              ) as number) ?? 0,
            disabled: true,
          },
        },
        {
          name: `def-tier-max-${createKey(key, false, true)}-onus`,
          type: "default",
          props: {
            value: "9999999999.99",
            disabled: true,
          },
        },
        ...(fields.onUsFee as FeeField[]),
      ],

      domesticAdditionalFee: [
        {
          name: `nic-ips-cash-${key}-dom-t1-min`,
          type: "default",
          props: {
            value: "0.00",
            disabled: true,
          },
        },
        {
          name: `nic-ips-cash-${key}-dom-t1-max`,
          type: "default",
          props: {
            value: "0.00",
          },
        },
        {
          name: `nic-ips-cash-${key}-dom-bf-t1-base`,
          type: "default",
        },
        {
          name: `nic-ips-cash-${key}-dom-bf-t1-fee-rate`,
          type: "percentage",
        },
        {
          name: `nic-ips-cash-${key}-dom-bf-t1-min`,
          type: "min",
        },
        {
          name: `nic-ips-cash-${key}-dom-bf-t1-max`,
          type: "max",
        },
      ],

      domesticFee: [
        {
          name: `nic-ips-cash-${key}-dom-t1-max`,
          type: "default",
          props: {
            value:
              (getFormValueFromProductValues(
                deps.productValues,
                `nic-ips-cash-${key}-dom-t1-max`,
                "number",
              ) as number) ?? 0,
            disabled: true,
          },
        },
        {
          name: `def-tier-max-${createKey(key, false, true)}-dom`,
          type: "default",
          props: {
            value: "9999999999.99",
            disabled: true,
          },
        },
        ...(fields.domesticFee as FeeField[]),
      ],
    };
  }

  if (deps.amount && deps.intrareg) {
    fields = {
      ...fields,
      intraregionalFee: [
        {
          name: `def-tier-min-${key}-intrareg`,
          type: "default",
          props: {
            value: (getFormValueFromProductValues(
              deps.productValues,
              `nic-ips-cash-${key}-intrareg-t1-max`,
              "number",
            ) as number)
              ? (getFormValueFromProductValues(
                  deps.productValues,
                  `nic-ips-cash-${key}-intrareg-t1-max`,
                  "number",
                ) as number)
              : 0,
            disabled: true,
          },
        },
        {
          name: `def-tier-max-${key}-intrareg`,
          type: "default",
          props: {
            value: "9999999999.99",
            disabled: true,
          },
        },
        ...(fields.intraregionalFee ?? []),
      ],

      intraregionalAdditionalFee: [
        {
          name: `nic-ips-cash-${key}-intrareg-t1-min`,
          type: "default",
          props: {
            value: (getFormValueFromProductValues(
              deps.productValues,
              `nic-ips-cash-${key}-intrareg-t1-max`,
              "number",
            ) as number)
              ? (getFormValueFromProductValues(
                  deps.productValues,
                  `nic-ips-cash-${key}-intrareg-t1-max`,
                  "number",
                ) as number)
              : 0,
            disabled: true,
          },
        },
        {
          name: `nic-ips-cash-${key}-intrareg-t1-max`,
          type: "default",
          props: {
            value: "9999999999.99",
            disabled: true,
          },
        },
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-t1-base`,
          type: "default",
        },
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-t1-fee-rate`,
          type: "percentage",
        },
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-t1-min`,
          type: "min",
        },
        {
          name: `nic-ips-${keyWithCash}-intrareg-bf-t1-max`,
          type: "max",
        },
      ],
    };
  }

  if (!(key === "pos" || key === "atm")) delete fields.intraregionalAdditionalFee;

  return fields;
};

export const useCardTransactionFees = (key: TabKeysCTF, deps: FieldsDependency) => {
  const [fields, setFields] = useState<DynamicFields>(getFields(key, deps));

  useEffect(() => {
    setFields(getFields(key, deps));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps.reg, deps.amount, deps.intrareg, key]);

  const getEmptyValuesByKey = (key: TabKeysCTF, type: "reg" | "amount" | "intrareg"): FormValues => {
    if (key === "atm") {
      if (type === "amount") {
        return CTF_ATM_EMPTY_AMOUNT_VALUES;
      }

      if (type === "reg") {
        return CTF_ATM_EMPTY_REG_VALUES;
      }

      if (type === "intrareg") {
        return CTF_INTRAREG_EMPTY[key] ?? {};
      }

      return {};
    }

    if (key === "pos") {
      if (type === "amount") {
        return CTF_CASH_EMPTY_AMOUNT_VALUES;
      }

      if (type === "reg") {
        return CTF_CASH_EMPTY_REG_VALUES;
      }

      if (type === "intrareg") {
        return CTF_INTRAREG_EMPTY[key] ?? {};
      }
      return {};
    }

    if (key === "retail") {
      if (type === "reg") {
        return CTF_RETAIL_EMPTY_REG_VALUES;
      }
      if (type === "intrareg") {
        return CTF_INTRAREG_EMPTY[key] ?? {};
      }
      return {};
    }

    if (key === "credit") {
      if (type === "reg") {
        return CTF_REFUND_EMPTY_REG_VALUES;
      }
      if (type === "intrareg") {
        return CTF_INTRAREG_EMPTY[key] ?? {};
      }
      return {};
    }

    if (key === "ch-debit") {
      if (type === "reg") {
        return CTF_P2P_DEBIT_EMPTY_REG_VALUES;
      }
      if (type === "intrareg") {
        return CTF_INTRAREG_EMPTY[key] ?? {};
      }
      return {};
    }

    if (key === "ch-paym") {
      if (type === "reg") {
        return CTF_P2P_PAYM_EMPTY_REG_VALUES;
      }
      if (type === "intrareg") {
        return CTF_INTRAREG_EMPTY[key] ?? {};
      }
      return {};
    }

    if (key === "retail-qsi") {
      if (type === "reg") {
        return CTF_QUASI_EMPTY_REG_VALUES;
      }
      if (type === "intrareg") {
        return CTF_INTRAREG_EMPTY[key] ?? {};
      }
      return {};
    }

    return {};
  };

  return {
    fields,
    withAmountSwitch: withAmountSwitch.includes(key),
    getEmptyValuesByKey,
  };
};
