import { Options } from "@ni/common/types";
import { ParameterTableValue } from "@ni/sdk/models";

export const reverseOptionsValue = (fieldOptions: Options, valuesList?: ParameterTableValue[]) => {
  const mappedValue: string[] = [];
  if (valuesList) {
    fieldOptions.options.forEach(option => {
      if (valuesList.find(x => x.fieldCode === option.value)?.value === "true") {
        mappedValue.push(option.value);
      }
    });
  }
  return mappedValue;
};
