import { useCallback, useEffect, useRef, useState } from "react";
import { TablePaginationConfig } from "antd";

import { FormValues } from "@ni/common/types";
import { InsuranceTemplateApi, TenantApi } from "@ni/sdk/apis";
import { PageDataFullInsuranceProgramTemplateDashboard, SortedFilteredPageRequest } from "@ni/sdk/models";

import { useReduxState } from "../store";

import { initialInsuranceData, initialInsurancePagination } from "./constants";

interface UseInsuranceProgramTemplatesProps {
  tenantId: number;
  isFetchEnabled?: boolean;
  payload?: SortedFilteredPageRequest;
}

const tenantServiceApi = new TenantApi();
const insuranceServiceApi = new InsuranceTemplateApi();

export const useInsuranceProgramTemplates = ({
  tenantId,
  isFetchEnabled = true,
  payload = {},
}: UseInsuranceProgramTemplatesProps) => {
  const [insuranceProgramTemplates, setInsuranceProgramTemplates] =
    useReduxState<PageDataFullInsuranceProgramTemplateDashboard>("insuranceProgramTemplates", initialInsuranceData);
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [pageHasContent, setPageHasContent] = useState<boolean>(false);
  const [filters, setFilters] = useState<SortedFilteredPageRequest>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialInsurancePagination);

  const initialLoadCompleteRef = useRef<boolean>(false);

  const getInsuranceProgramTemplates = useCallback(
    async (tenantId: number, currentPage: number, size: number, payload: SortedFilteredPageRequest = {}) => {
      setIsLoading(true);

      try {
        const { data } = await tenantServiceApi.getBaseInsuranceProgramsByTenantId(
          {
            ...payload,
            pageLimits: {
              number: currentPage - 1,
              size,
            },
          },
          tenantId,
        );

        setPagination(pagination => ({ ...pagination, total: data.totalElements }));
        setInsuranceProgramTemplates(data ?? []);

        if (!initialLoadCompleteRef.current) {
          setPageHasContent(data.hasContent);
          initialLoadCompleteRef.current = true;
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setIsLoading, setInsuranceProgramTemplates],
  );

  const copyInsuranceProgramTemplate = async (newDisplayName: string, insuranceId: number) => {
    setIsLoading(true);
    try {
      const { data } = await insuranceServiceApi.copy3({ newDisplayName }, insuranceId);

      setInsuranceProgramTemplates(prev => {
        const updatedContent = prev.content ? [data, ...prev.content] : [data];

        if (updatedContent.length > (pagination?.pageSize ?? 6)) updatedContent.pop();

        return {
          ...prev,
          content: updatedContent,
        };
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const editInsuranceProgramTemplate = async (values: FormValues, insuranceId: number) => {
    setIsLoading(true);
    try {
      const { data } = await insuranceServiceApi.edit4(values, insuranceId);

      const { name, insuranceCompanyName, state } = data;

      setInsuranceProgramTemplates(prev => ({
        ...prev,
        content: prev.content?.map(item => {
          if (item.id === data.id) {
            return {
              ...item,
              name,
              insuranceCompanyName,
              state,
            };
          }
          return item;
        }),
      }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFetchEnabled)
      void getInsuranceProgramTemplates(
        tenantId,
        pagination.current ?? 1,
        pagination.pageSize ?? 6,
        Object.keys(filters).length === 0 ? payload : filters,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchEnabled, getInsuranceProgramTemplates, tenantId, pagination.current, pagination.pageSize, filters]);

  return {
    insuranceProgramTemplates,
    setInsuranceProgramTemplates,
    copyInsuranceProgramTemplate,
    editInsuranceProgramTemplate,
    pageHasContent,
    pagination,
    setPagination,
    filters,
    setFilters,
  };
};
