import { FC, useEffect } from "react";
import { Form, Input, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { TENANT_COUNTRY, TENANT_CURRENCY } from "@ni/common/constants";
import { useFormButtonDisabled, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DictionarySelect, FormItemLabel, NetworkForm } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse, UserFull } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TenantRegistrationPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm<FormValues>();
  const countryCode = Form.useWatch("countryCode", form);
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [user] = useReduxState<UserFull>("user");

  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form });

  useEffect(() => {
    if (wizardResponse?.tenant?.id) {
      const tenantName = wizardResponse.tenant.name;
      const tenantCurrency = wizardResponse.tenant.defaultCurrency;
      const tenantCountry = wizardResponse.tenant.countryCode;
      const shortName =
        wizardResponse.tenant.tenantValues?.find(({ fieldCode }) => fieldCode === "short-name")?.value ?? "";

      form.setFieldValue("name", tenantName);
      form.setFieldValue("defaultCurrency", tenantCurrency);
      form.setFieldValue("countryCode", tenantCountry);
      form.setFieldValue("short-name", shortName);
    }
  }, [
    form,
    wizardResponse.tenant?.countryCode,
    wizardResponse.tenant?.defaultCurrency,
    wizardResponse.tenant?.id,
    wizardResponse.tenant?.name,
    wizardResponse.tenant?.tenantValues,
  ]);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        financialInstitutionId: user.financialInstitutionId?.toString(),
        "nic-otm-used": "Y",
      } as {
        [key: string]: string;
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);

        const existingQueries = new URLSearchParams(location.search);
        existingQueries.set("tenantId", (response.data.tenant?.id ?? 0).toString());

        navigate({
          pathname: location.pathname,
          search: existingQueries.toString(),
        });

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled}
      pageTitle="Tenant Registration"
      size="sm"
      gap={16}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Form.Item
        name="countryCode"
        label={<FormItemLabel label="Which country do you want to operate?" code="countryCode" />}
        rules={[{ required: true, message: "Country is required!" }]}
        shouldUpdate={true}
      >
        <DictionarySelect
          placeholder="Select country"
          disabled={formDisabled}
          filterOption={filterByDisplayValue}
          code={TENANT_COUNTRY}
          showSearch={true}
        />
      </Form.Item>
      <Form.Item
        name="name"
        label={<FormItemLabel label="Tenant name" code="name" />}
        rules={[
          { required: true, message: "Tenant Name is required" },
          { max: 32, message: "Tenant Name must have maximum 32 characters." },
          {
            pattern: /^[^\t\r{}]*$/,
            message: "Tenant Name contains not permitted characters as: “TAB”, `{', '}` ”.",
          },
        ]}
      >
        <Input placeholder="Tenant name" />
      </Form.Item>

      <NetworkForm.String
        formItemOptions={{
          name: "short-name",
          label: "Tenant short name",
          rules: [
            { required: true, message: "Tenant short name is required" },
            { max: 5, message: "Tenant short name can only have 5 maximum characters." },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Tenant short name contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
          ],
        }}
      />

      <Form.Item
        name="defaultCurrency"
        label={<FormItemLabel label="Select currency" code="defaultCurrency" />}
        rules={[{ required: true, message: "Currency is required!" }]}
      >
        <DictionarySelect
          placeholder="Select currency"
          disabled={formDisabled}
          filterOption={filterByDisplayValue}
          code={TENANT_CURRENCY}
          showSearch={true}
        />
      </Form.Item>
      {countryCode === "SA" && (
        <div className="alert-div">
          <Space direction="vertical" size="small">
            Our products for the Kingdom of Saudi Arabia come with build in support for the following regulatory
            requirements:
            <ul>
              <li>Automatic card blocking on IQAMA ID or Passport Number ID expiry.</li>
              <li>
                Expiration date for Prepaid cards must be at least 3 years after the date on which the prepaid account
                funds were first loaded.
              </li>
              <li>Cards and PINs must only be delivered to the primary account holder for supplementary cards.</li>
              <li>Client accounts are closed after a configurable period of inactivity.</li>
              <li>SMS notifications for card transactions.</li>
            </ul>
          </Space>
        </div>
      )}
    </CustomFormWrapper>
  );
};
