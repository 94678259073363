import { FC, useState } from "react";
import { ConfigProvider, Form, Select, Spin } from "antd";
import Search from "antd/lib/transfer/search";

import { useReduxState } from "@ni/common/hooks";
import { elementTypeListOptions, mockElementGroupOptions, productTypeOptions } from "@ni/common/mocks";
import { WorkflowFilters } from "@ni/common/types";

import ElementTemplateModalTable from "../ElementTemplateModalTable";

import styles from "./styles.module.scss";

const { Option } = Select;

const ElementTemplateModalLayout: FC = () => {
  const [isLoading] = useState<boolean>(false);
  const [filters] = useReduxState<WorkflowFilters>("selectElementTemplateFilter", {});

  if (isLoading) {
    return <Spin size="large" className={styles["loading-element-template"]} />;
  }

  return (
    <ConfigProvider componentSize="middle">
      <div className={styles["element-template-modal-layout"]}>
        <div className={styles["extra"]} key="extraDiv">
          <Form.Item name="searchValue">
            <Search placeholder="Search" />
          </Form.Item>
        </div>
        <div className={styles["filters-bar"]}>
          <Form.Item name="region.code">
            <Select placeholder="Element group">
              {mockElementGroupOptions.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="clientType">
            <Select placeholder="Element type">
              {elementTypeListOptions.map(option => (
                <Option key={option.value + option.label} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="productType">
            <Select placeholder="Feature name">
              {productTypeOptions.map(option => (
                <Option key={option.value + option.label} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <ElementTemplateModalTable filters={filters} />
    </ConfigProvider>
  );
};

export default ElementTemplateModalLayout;
