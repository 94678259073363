import { FC, forwardRef } from "react";
import { Radio, RadioChangeEvent, Space } from "antd";
import classNames from "classnames";

import { SelectOption } from "@ni/common/types";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

export interface RadioGroupProps {
  radioList: SelectOption[];
  direction?: "vertical" | "horizontal";
  gap?: number;
  initialValue?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: RadioChangeEvent) => void;
}

export const RadioGroup: FC<RadioGroupProps> = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ radioList, direction = "vertical", gap = 16, initialValue = "", value, disabled, onChange }, ref) => {
    const handleRadioChange = (e: RadioChangeEvent) => {
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <Radio.Group
        ref={ref}
        onChange={handleRadioChange}
        value={value === undefined ? initialValue : value}
        defaultValue={initialValue}
        disabled={disabled}
      >
        <Space direction={direction} size={gap}>
          {(radioList ?? []).map(item => (
            <Radio
              key={item.value}
              value={item.value}
              checked={value === item.value}
              defaultChecked={initialValue === item.value}
              disabled={item?.disabled}
            >
              <div
                className={classNames("flex", "flex-align-center", {
                  [styles["activeWhenDisabled"]]: item?.disabled && item?.hasActiveStyleWhenDisabled,
                })}
              >
                {item.label && (
                  <TooltipInfo
                    label={item.label}
                    code={item?.key ?? item?.value}
                    tooltipProps={{ title: item.tooltip }}
                  />
                )}
                {item.extra && item.extra}
              </div>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    );
  },
);
