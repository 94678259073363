import { useEffect } from "react";
import { Form } from "antd";
import { useLoyaltyProgramTemplateApi } from "libs/programs-edit/src/lib/hooks";
import { useParams } from "react-router-dom";

import { PRODUCT_IDS } from "@ni/common/constants";
import { useGetProductsToApply } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, ProductCardList } from "@ni/common/ui";

export const LoyaltyApplyingToProducts = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, templateId } = useParams<{ id: string; templateId: string }>();

  const { loyaltyProgramTemplate, applyToProductsApi } = useLoyaltyProgramTemplateApi({
    loyaltyTemplateId: parseInt(templateId ?? "0", 10),
  });

  const { products: productsList } = useGetProductsToApply(
    parseInt(tenantId ?? "0", 10),
    loyaltyProgramTemplate?.type === "NORMAL" ? "N" : "C",
  );

  const hydrateList = () => {
    const programValues = [
      ...(loyaltyProgramTemplate?.programValues ?? []),
      ...(loyaltyProgramTemplate?.programPctValues ?? []),
    ];
    if (programValues.length) {
      const programValueIndex: number | undefined = programValues.findIndex(
        programValue => programValue.code === PRODUCT_IDS,
      );

      if (typeof programValueIndex === "number" && programValueIndex >= 0) {
        const storedProducts = programValues[programValueIndex]?.value?.split(",")?.map(x => +(x as unknown as number));
        form.setFieldValue(PRODUCT_IDS, storedProducts);
        return;
      }
    }
    const defaultProductList = productsList?.map(x => x.product.id);
    form.setFieldValue(PRODUCT_IDS, defaultProductList);
  };

  useEffect(hydrateList, [productsList, loyaltyProgramTemplate, form]);

  const onFinish = (values: FormValues) => {
    void applyToProductsApi(values[PRODUCT_IDS] as number[]);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Loyalty Applying to Products"
      size="md"
      formSize="md"
      level="tenant"
      submitLabel="Save"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <Form.Item name={PRODUCT_IDS}>
        <ProductCardList products={productsList} hasCheckAll={true} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
