import { useMemo } from "react";

import { NIC_LTY_ACCR_ROUNDING_DECIMALS } from "@ni/common/constants";
import { Tenant } from "@ni/sdk/models";

export const useNumberOfTotalPointesCapDecimals = (tenant?: Tenant) => {
  return useMemo(() => {
    const numberOfDecimals = tenant?.tenantValues?.find(value => value.fieldCode === NIC_LTY_ACCR_ROUNDING_DECIMALS)
      ?.value;

    if (!numberOfDecimals) return 2;

    const integerNumberOfDecimals: number = +(numberOfDecimals as unknown as number);
    if (Number.isNaN(integerNumberOfDecimals)) return 2;
    if (integerNumberOfDecimals < 0) return 0;
    return integerNumberOfDecimals;
  }, [tenant]);
};
