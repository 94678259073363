import { SelectOption } from "@ni/common/types";
import { ProductState, ProductType } from "@ni/sdk/models";

export const tenantOptions: SelectOption[] = [{ value: "ALL", label: "All Tenants" }];

export const productTypeOptions: SelectOption[] = [
  { value: "ALL", label: "All Card Types" },
  { value: ProductType.CREDIT, label: "Credit Card" },
  { value: ProductType.DEBIT, label: "Debit Card" },
  { value: ProductType.PREPAID, label: "Prepaid" },
];

export const paymentSchemeOptions: SelectOption[] = [
  { value: "ALL", label: "All Schemes" },
  { value: "Visa", label: "Visa" },
  { value: "MC", label: "MasterCard" },
  { value: "Local", label: "Local" },
];

export const productStateOptions: SelectOption[] = [
  { value: "ALL", label: "All States" },
  { value: ProductState.DRAFT, label: "Draft" },
  { value: ProductState.UAT_PENDING, label: "UAT Pending" },
  { value: ProductState.UAT, label: "UAT" },
  { value: ProductState.PRODUCTION, label: "Production" },
  { value: ProductState.REJECTED, label: "Rejected" },
];
