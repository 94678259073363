import { ReactNode } from "react";
import { Route } from "react-router-dom";

import { RoutesTreeItem } from "@ni/common/types";

const isVisualNestedRoute = (route: RoutesTreeItem): boolean =>
  !!(!route?.element && !route?.route && route?.children?.length);

const processRoutes = (routes: RoutesTreeItem[]): RoutesTreeItem[] => {
  const processedRoutes: RoutesTreeItem[] = routes.filter(route => !route?.guard);
  const listOfEscalatedRoutes: { index: number; items: RoutesTreeItem[] }[] = [];
  processedRoutes?.forEach((route, index) => {
    if (isVisualNestedRoute(route) && route?.children) {
      listOfEscalatedRoutes.push({ index, items: route.children });
    }
  });
  if (listOfEscalatedRoutes.length) {
    listOfEscalatedRoutes.forEach(flatRoutesItem => {
      processedRoutes.splice(flatRoutesItem.index, 0, ...flatRoutesItem.items);
    });
  }
  return processedRoutes.filter(route => !isVisualNestedRoute(route) || !route?.guard);
};

export const RenderRoutesTree = (routes: RoutesTreeItem[], routePrefix?: string): ReactNode =>
  processRoutes(routes).map((route, index) => {
    return route?.isIndex ? (
      <Route index={true} element={route.element} key={route.route ?? index} />
    ) : (
      <Route path={`${routePrefix ?? ""}${route.route}`} element={route.element} key={route.route ?? index}>
        {route?.children ? RenderRoutesTree(route.children) : null}
      </Route>
    );
  });
