import { FC, useEffect } from "react";
import { Form } from "antd";
import _ from "lodash";
import { useParams } from "react-router-dom";

import { defaultTenantLogo } from "@ni/common/assets";
import { TENANT_COUNTRY, TENANT_CURRENCY, TIMEZONES } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues, SelectOption } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant, TenantCountryCodeEnum, TenantValue } from "@ni/sdk/models";

import styles from "./style.module.scss";

const tenantsServiceApi = new TenantApi();

interface TenantDetailsForm {
  tenantName: string;
  tenantCountry: TenantCountryCodeEnum;
  tenantCurrency: string;
  domesticAreas: string[];
  foreignAreas: string[];
  Time_zone: string;
  "short-name": string;
}

export const TenantDetails: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [tenants, setTenants] = useReduxState<Tenant[]>("tenants");
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  useEffect(() => {
    if (!isLoading) {
      const domesticAreas = tenant?.tenantValues
        ?.find(val => val.fieldCode === "nic-domestic-country-rules")
        ?.value?.split(",") || [tenant.countryCode as string];
      const foreignAreas = tenant?.tenantValues
        ?.find(val => val.fieldCode === "nic-foreign-country-rules")
        ?.value?.split(",") || [tenant.countryCode as string];
      const timezone = tenant?.tenantValues?.find(val => val.fieldCode === "Time_zone")?.value?.toString() ?? "";
      const shortName = tenant?.tenantValues?.find(({ fieldCode }) => fieldCode === "short-name")?.value ?? "";

      form.setFieldsValue({
        tenantName: tenant?.name || "",
        tenantCountry: tenant?.countryCode || "",
        tenantCurrency: tenant?.defaultCurrency || "",
        domesticAreas,
        foreignAreas,
        Time_zone: timezone,
        "short-name": shortName,
      });
    }
  }, [tenant, isLoading, form]);

  const onFinish = (values: TenantDetailsForm) => {
    setIsLoading(true);
    const tenantId = parseInt(id ?? "0", 10);
    const tenantValues: TenantValue[] = [
      {
        fieldCode: "nic-domestic-country-rules",
        value: _.map(values.domesticAreas).join(","),
      },
      {
        fieldCode: "nic-foreign-country-rules",
        value: _.map(values.foreignAreas).join(","),
      },
      {
        fieldCode: "Time_zone",
        value: values.Time_zone,
      },
      {
        fieldCode: "short-name",
        value: values["short-name"],
      },
    ];
    const requestBody: ChangeTenantRequest = {
      name: values.tenantName,
      defaultCurrency: values.tenantCurrency,
      countryCode: values.tenantCountry,
      tenantValues,
    };

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setTenant(res.data);
        setTenants(
          tenants.map(t => {
            if (res.data?.id === t.id) {
              return res.data;
            }

            return t;
          }),
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const tenantEmptyExtCode = !!tenant.externalCode;

  return (
    <CustomFormWrapper
      pageTitle="Tenant Details"
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
      gap={24}
    >
      <div className={styles["logo-wrapper"]}>
        <p className={styles["logo-label"]}>Tenant logo</p>
        <img className={styles["tenant-logo"]} src={defaultTenantLogo} alt="tenant-logo" />
      </div>
      <NetworkForm.String
        formItemOptions={{
          name: "tenantName",
          label: "Tenant name",
          rules: [
            { required: true, message: "Tenant name is required" },
            { max: 32, message: "Tenant Name must have maximum 32 characters." },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Tenant Name contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
          ],
        }}
      />

      <NetworkForm.String
        formItemOptions={{
          name: "short-name",
          label: "Tenant short name",
          rules: [
            { required: true, message: "Tenant short name is required" },
            { max: 5, message: "Tenant short name can only have 5 maximum characters." },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Tenant short name contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
          ],
        }}
      />

      <NetworkForm.DictionarySelect
        placeholder="Tenant country"
        disabled={tenantEmptyExtCode}
        filterOption={filterByDisplayValue}
        code={TENANT_COUNTRY}
        showSearch={true}
        formItemOptions={{ name: "tenantCountry", label: "Tenant country" }}
      />

      <NetworkForm.DictionarySelect
        placeholder="Tenant currency"
        disabled={tenantEmptyExtCode}
        filterOption={filterByDisplayValue}
        code={TENANT_CURRENCY}
        showSearch={true}
        formItemOptions={{ name: "tenantCurrency", label: "Local currency" }}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: "Time_zone",
          label: "FI Time Zone",
          tooltip:
            "Select the tenant's timezone to ensure correct local time for events, reminders, and accurate monthly limits/counters.",
        }}
        placeholder="Timezone"
        showSearch={true}
        optionList={TIMEZONES as SelectOption[]}
      />
    </CustomFormWrapper>
  );
};
