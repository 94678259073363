import cn from "classnames";
import type { FC } from "react";

import { DeleteOutlined, HolderOutlined, UpOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

interface ExtraHeaderProps {
  itemName: string;
  index: number;
  isActive?: boolean;
  isShowArrow: boolean;
}

interface ExtraPanelProps {
  onDeleteModalClick: () => void;
}

export const extraHeader: FC<ExtraHeaderProps> = props => {
  const { itemName, index, isActive, isShowArrow } = props;

  return (
    <div className={styles[`collapse-header`]}>
      <HolderOutlined style={{ fontSize: "150%" }} />
      <div className={styles["order"]}>{index + 1 ?? "-"}</div>
      <div className={styles["title"]}>
        <div className={styles["name"]}>
          {itemName}
          {isShowArrow && isActive ? (
            <UpOutlined className={styles["arrow"]} />
          ) : (
            <UpOutlined className={cn(styles["arrow"], styles["open"])} />
          )}
        </div>
      </div>
    </div>
  );
};

export const extraPanel: FC<ExtraPanelProps> = props => {
  const { onDeleteModalClick } = props;

  return (
    <div className={styles["collapse-header-extra"]}>
      <DeleteOutlined className={styles["button"]} onClick={onDeleteModalClick} />
    </div>
  );
};
