import { InputNumberProps } from "antd";

import { FormField, Options } from "@ni/common/types";

export const retailRateOptions: FormField<InputNumberProps> = {
  code: "nic-int-rate-retail",
  label: "Annual interest rate on retail balance",
  props: {
    precision: 2,
    min: 0,
    max: 100,
  },
};

export const cashRateOptions: FormField<InputNumberProps> = {
  code: "nic-int-rate-cash",
  label: "Annual interest rate on cash balance",
  props: {
    precision: 2,
    min: 0,
    max: 100,
  },
};

export const chargeOptions: Options = {
  code: "int-start",
  label: "Charge interest for transaction starting from:",
  default: "P",
  options: [
    {
      label: "Posting date",
      value: "P",
    },
    {
      label: "Transaction date",
      value: "T",
    },
  ],
};

export const postingMaximumOptions: FormField<InputNumberProps> = {
  code: "int-start-numb",
  label: "Maximum days between the transaction date and the posting date",
  tooltip: "If the number of days exceeds, then interests will be charged starting from the posting date",
  props: {
    precision: 0,
    min: 0,
  },
};

export const fullPaymentDateOptions: FormField<InputNumberProps> = {
  code: "nic-fp-date-days",
  label: "Full payment date (number of days from payment due date)",
  tooltip:
    "The full payment date is the deadline by which the entire payment must be made to meet interest grace option conditions. More details are on the Interest Grace Options page, check the link below.",
  props: {
    precision: 0,
    min: 0,
  },
};

export const intOption: Options = {
  code: "zero-int-int",
  label: "Zero interest rate on all interests",
  options: [
    {
      label: "Zero interest rate on Retail Interest",
      value: "nic-int-rate-zero-retail-int",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied",
    },
    {
      label: "Zero interest rate on Cash Interest",
      value: "nic-int-rate-zero-cash-int",
      tooltip: "If the option is unselected, then interest rate on cash balance will be applied",
    },
  ],
};

export const feesOption: Options = {
  code: "zero-int-fees",
  label: "Zero interest rate on all fees",
  options: [
    {
      label: "Zero interest rate on Membership Fees",
      value: "nic-int-rate-zero-retail-amf",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied",
    },
    {
      label: "Zero interest rate on Late Payment Fee",
      value: "nic-int-rate-zero-retail-lpf",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied",
    },
    {
      label: "Zero interest rate on Overlimit Fee",
      value: "nic-int-rate-zero-retail-ovl",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied",
    },
    {
      label: "Zero interest rate on Not Sufficient Funds Fee",
      value: "nic-int-rate-zero-retail-nsf",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied",
    },
  ],
};

export const insOption: Options = {
  code: "zero-int-ins",
  label: "Zero interest rate on all insurance",
  options: [
    {
      label: "Zero interest rate on Retail Insurance",
      value: "nic-int-rate-zero-retail-ins",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied",
    },
    {
      label: "Zero interest rate on Cash Insurance",
      value: "nic-int-rate-zero-cash-ins",
      tooltip: "If the option is unselected, then interest rate on cash balance will be applied",
    },
  ],
};
