import { SelectOption } from "@ni/common/types";

export const RADIO_GROUP_ACCRUAL_TYPE: SelectOption[] = [
  { label: "Transaction based (earn points for each eligible transaction)", value: "NORMAL" },
  { label: "Cumulative (earn points based on net spending during a specified period)", value: "CUMULATIVE" },
];

export const RADIO_GROUP_ACCRUAL_FREQ: SelectOption[] = [
  { label: "Billing cycle", value: "B" },
  { label: "Year", value: "Y" },
  {
    label: "Both (allowing customer to select preferred option)",
    tooltip: "Yearly accrual may offer higher accrual rates than rates per billing cycle",
    value: "B-Y",
  },
];

export const RADIO_GROUP_ACCRUAL_MODE: SelectOption[] = [
  { label: "Net amount of transactions", value: "AMOUNT" },
  { label: "Net count of transactions", value: "COUNT" },
];
