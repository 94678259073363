import { useCallback, useEffect, useRef } from "react";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { getState, setStateAction, setStateWithCallbackAction } from "@ni/common/utils";

export type SetReduxState<T> = (arg: T | ((prev: T) => T)) => void;

export const useReduxState = <T = unknown>(key: string, initialState?: T): [T, SetReduxState<T>] => {
  const dispatch = useDispatch();
  const isSetInitialState = useRef(false);

  const state = useSelector(getState<T>(key));

  const resultState = isSetInitialState.current || state ? state : (initialState as T);

  const setReduxStateCallback: SetReduxState<T> = arg => {
    if (typeof arg === "function") {
      const callback = arg as (prev: unknown) => unknown;
      dispatch(setStateWithCallbackAction({ key, callback }));
    } else {
      dispatch(setStateAction({ key, data: arg }));
    }
  };

  const setReduxStateRef = useRef<SetReduxState<T>>();

  setReduxStateRef.current = setReduxStateCallback;

  const setState: SetReduxState<T> = useCallback(
    arg => {
      if (setReduxStateRef.current) {
        setReduxStateRef.current(arg);
      }
    },
    [setReduxStateRef],
  );

  useEffect(() => {
    if (!isSetInitialState.current && (state !== undefined || isEqual(state, initialState))) {
      isSetInitialState.current = true;
    }
  }, [state, initialState]);

  useEffect(() => {
    if (initialState !== undefined && !isSetInitialState.current && setState) {
      setState(initialState);
    }
  }, [initialState, setState]);

  return [resultState, setState];
};
