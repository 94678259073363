export const productWizardDefaults = {
  TEN_BASE: {
    countryCode: "AE",
    name: "tenant-test",
    defaultCurrency: "AED",
  },
  ADD_SERV: {},
  CLIENT_TYPE: {
    "client-type": "Consumers",
  },
  PROD_TYPE: {
    "balance-owner": "CBS",
    "product-type": "Debit",
  },
  PROD_BASE: {
    "nic-prod-ips": "Visa",
    "nic-prod-core-name": "product-test",
    "nic-ccy": "AED",
    displayName: "Visa product-test AED",
  },
  CARD_NUMB: {
    "use-test-bin": true,
    "bin-length": 6,
    "nic-card-subtype-pan-bin": null,
    sliderRanges: {
      "nic-card-subtype-pan-full-min": "0000000000",
      "nic-card-subtype-pan-full-max": "9999999999",
    },
    "rep-bin-visa": "",
  },
  CARD_PROD_INIT: {
    "virt-phys": "V",
  },
  CARD_PROD: {
    "nic-card-subtype-exp-months": 60,
    "nic-card-subtype-autorenew-days": 30,
    "nic-card-subtype-pinset-allowed": true,
    "nic-card-subtype-pinset-allowed-for-locked": true,
    "nic-card-subtype-abu-active": true,
    "nic-card-subtype-token-used": true,
  },
  TRANS_RESTR: {},
  TRANS_LIM_PRESET: {
    "trans-lim-contr-level": "N",
  },
  MAN_CLOS: {
    "nic-acc-st-man-close-days": 33,
    "nic-card-st-man-close-days": 33,
  },
  AUTO_CLOS: {
    "nic-acc-st-auto-close-per-type": "M",
    "nic-acc-st-auto-close-per": 3,
  },
  PURGE: {
    "nic-acc-st-auto-purge-per-type": "M",
    "nic-acc-st-auto-purge-per": 3,
    "nic-card-st-auto-purge-per-type": "M",
    "nic-card-st-auto-purge-per": 3,
  },
  "8_SUCCESS_PAGE": {},
};
