import { FC, useEffect, useState } from "react";
import { Form } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";

import { useReduxState } from "@ni/common/hooks";
import {
  contractLevelList,
  periodRestrictListBoth,
  periodRestrictListPerAccount,
  periodRestrictListPerCard,
} from "@ni/common/mocks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { Checkbox, CustomFormWrapper, FormItemLabel, RadioGroup } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TopupLimitsPresetPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [selectedPaymLimContrLevel, setSelectedPaymLimContrLevel] = useState<string>("");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [selectedPeriods, setSelectedPeriods] = useState<CheckboxValueType[]>(["D"]);
  const [isShowParameters, setIsShowParameters] = useState<boolean>(true);

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    setSelectedPeriods((formDisabledFn("paym-lim-periods", "") as string).split(","));

    form.setFieldsValue({
      "paym-lim-contr-level": formDisabledFn("paym-lim-contr-level", ""),
      "paym-lim-periods": (formDisabledFn("paym-lim-periods", "") as string).split(","),
    });

    if (
      (!formDisabled && form.getFieldValue("paym-lim-contr-level") === "N") ||
      String(form.getFieldValue("paym-lim-contr-level")).length === 0
    ) {
      setIsShowParameters(false);
    } else if (formDisabled && formDisabledFn("paym-lim-contr-level", "") === "N") {
      setIsShowParameters(false);
    } else {
      setIsShowParameters(true);
    }
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const paymLimPeriodsList = () => {
    if (
      (selectedPaymLimContrLevel === "A" && !formDisabled) ||
      (formDisabled &&
        wizardResponse.product?.parameterTables![0].pctProductValues?.find(
          item => item.fieldCode === "paym-lim-contr-level",
        )?.value === "A")
    ) {
      return periodRestrictListPerAccount;
    }
    if (
      (selectedPaymLimContrLevel === "C" && !formDisabled) ||
      (formDisabled &&
        wizardResponse.product?.parameterTables![0].pctProductValues?.find(
          item => item.fieldCode === "paym-lim-contr-level",
        )?.value === "C")
    ) {
      return periodRestrictListPerCard;
    }
    if (
      (selectedPaymLimContrLevel === "B" && !formDisabled) ||
      (formDisabled &&
        wizardResponse.product?.parameterTables![0].pctProductValues?.find(
          item => item.fieldCode === "paym-lim-contr-level",
        )?.value === "B")
    ) {
      return periodRestrictListBoth;
    }

    return periodRestrictListPerAccount;
  };

  const onLevelChange = (value: string) => {
    setSelectedPeriods([]);
    setIsEnabled(value === "N");

    setIsShowParameters(value !== "N");
  };

  const onPeriodChange = (values: CheckboxValueType[]) => {
    if (form.getFieldValue("paym-lim-contr-level") !== "N") {
      setIsEnabled(!!values?.length);
    } else {
      setIsEnabled(true);
    }
  };

  const onFinish = () => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        "paym-lim-contr-level": String(form.getFieldValue("paym-lim-contr-level")),
        "paym-lim-periods": String(selectedPeriods),
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!isEnabled}
      pageTitle="Top-up Limits Pre-set"
      pageSubtitle="Top-up limits provide the feasibility to apply restrictions on frequency and amounts of payments to
      account/card"
      size="full"
      formSize="sm"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name="paym-lim-contr-level"
        label={
          <FormItemLabel
            label="Choose the client's contract level you want to apply limits:"
            code="paym-lim-contr-level"
          />
        }
        rules={[{ required: true, message: "Contract level is required!" }]}
      >
        <RadioGroup
          radioList={contractLevelList}
          gap={8}
          value={selectedPaymLimContrLevel}
          onChange={event => {
            setSelectedPaymLimContrLevel(String(event.target.value));
            setSelectedPeriods([]);
            onLevelChange(String(event.target.value));
            form.resetFields(["paym-lim-periods"]);
          }}
        />
      </Form.Item>
      {isShowParameters && (
        <Form.Item
          name="paym-lim-periods"
          label={<FormItemLabel label="Choose the period you want to restrict" code="paym-lim-periods" />}
          rules={[{ required: !!paymLimPeriodsList(), message: "Period is required!" }]}
        >
          <Checkbox
            checkboxes={paymLimPeriodsList()}
            value={selectedPeriods}
            onChange={checkedValues => {
              setSelectedPeriods(checkedValues);
              onPeriodChange(checkedValues);
            }}
          />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
