export const TENANT_PAGES_CODES = {
  details: {
    labelMap: {
      details: "Tenant Details",
      tenantName: "Tenant name",
      tenantCountry: "Tenant country",
      tenantCurrency: "Local currency",
      "nic-domestic-country-rules": "",
      "nic-foreign-country-rules": "",
    },
    hydrationKeys: {
      strings: ["tenantName", "tenantCountry", "tenantCurrency"],
      lists: ["nic-domestic-country-rules", "nic-foreign-country-rules"],
    },
  },
  "credit-products-configuration": {
    labelMap: {
      "credit-products-configuration": "Credit Products Configuration",
      "nic-int-base": "Interest base",
      "nic-dd-wr-ask100": "Contract delinquency level to request 100% of balance by direct debit",
      "nic-dlq-level-to-freeze": "Contract delinquency level to freeze",
      "nic-past-due-days-calc-mode": "Past due days calculation mode",
    },
    hydrationKeys: {
      strings: ["nic-int-base", "nic-dd-wr-ask100", "nic-dlq-level-to-freeze", "nic-past-due-days-calc-mode"],
    },
  },
  "additional-services-details": {
    labelMap: {
      "additional-services-details": "Additional Services Details",
      "nic-ncontrol-flag-def-new": "Set default Card control enrollment flag as enabled for the product",
      "nic-ncontrol-flag-editable": "Card control enrollment flag is editable for contracts",
      "nic-falcon-enable-notif": "Enable Notifications",
      isCardControlSelected: "Card Control",
      isFalconSelected: "Falcon fraud monitoring",
    },
    hydrationKeys: {},
  },
  "client-identification": {
    labelMap: {
      "client-identification": "Client Identification",
      "nic-unique-client-reg-number": "Impose Uniqueness of the field that stores client ID number",
      "nic-unique-client-number": "Impose Uniqueness of the field that stores CIF number",
      "nic-cs-domain-noncompl": "Enable ID Expire Functionality",
      "nic-noncompl-auth-block-3": "Response code",
      "nic-noncompl_level_a-recalc-client-update": "Enable using External card ID (EXID)",
      "nic-noncompl_level_a-recalc-dec-daily-sa":
        "Enable checking ID renewal state in every morning CDU (Contract Daily Updates) procedure",
      "nic-noncompl_c-auth-block-3": "Response code",
      "nic-noncompl_level_c-suppl-recalc-client-update":
        "Enable checking ID renewal state immediately once the client/contract was updated in the system",
      "nic-noncompl_level_c-suppl-recalc-dec-daily-sa":
        "Enable checking ID renewal state in every morning CDU (Contract Daily Updates) procedure",
      "nic-exid-use": "Enable using External card ID (EXID)",
    },
    hydrationKeys: {
      strings: [
        "nic-unique-client-reg-number",
        "nic-unique-client-number",
        "nic-cs-domain-noncompl",
        "nic-noncompl-auth-block-3",
        "nic-noncompl_level_a-recalc-client-update",
        "nic-noncompl_level_a-recalc-dec-daily-sa",
        "nic-noncompl_c-auth-block-3",
        "nic-noncompl_level_c-suppl-recalc-client-update",
        "nic-noncompl_level_c-suppl-recalc-dec-daily-sa",
        "nic-exid-use",
      ],
    },
  },
  "card-and-pin-maintenance": {
    labelMap: {
      "card-and-pin-maintenance": "Card and PIN Maintenance",
      "nic-plast-exp-same-before": "Generate Same - Months Since Open",
      "nic-plast-exp-prev-after": "Increment from Previous - Months After",
      "nic-plast-exp-prev-before": "Increment from Previous - Months Before",
      "max-pin-tries": "Maximal PIN-tries",
      "nic-locked-card-rc": "Response code when attempting to authorize a locked card",
      "nic-phys-restr-for-virt-use": "Restrict Physical Transactions for Virtual Cards",
      "nic-pin-tries-autoreset": 'Reset the "Exceeded PIN-tries" block every End of Day',
      "nic-pin-tries-drive-block-factor": "Block “non-PIN-based” transactions in case of PIN-tries exceeded",
    },
    hydrationKeys: {
      strings: [
        "nic-plast-exp-same-before",
        "nic-plast-exp-prev-after",
        "nic-plast-exp-prev-before",
        "max-pin-tries",
        "nic-locked-card-rc",
        "nic-phys-restr-for-virt-use",
        "nic-pin-tries-autoreset",
        "nic-pin-tries-drive-block-factor",
      ],
      unparsedStrings: ["nic-locked-card-rc"],
    },
  },
  "payment-schemas-services": {
    labelMap: {
      "payment-schemas-services": "Payment Schemas Services",
      "nic-token-suspend-unexp": "Token suspension options for expired/inactive cards:",
      "nic-token-close-for-closed": "Close the tokens if the account or card is closed",
      "abu-close-stand-rules": "Apply standard set of rules for ABU messaging about card Closure/Replacement",
      "nic-abu-optout-unexp": "Tokenization (token lifecycle management)",
      "nic-abu-visa_iss_id": "ABU Visa Issuer identifier (Visa Segment ID)",
    },
    hydrationKeys: {
      strings: [
        "nic-token-suspend-unexp",
        "nic-token-close-for-closed",
        "abu-close-stand-rules",
        "nic-abu-visa_iss_id",
      ],
    },
  },
  "fees-tenant-configuration": {
    labelMap: {
      "fees-tenant-configuration": "Fee Settings",
      "nic-domestic-country": "Domestic country",
      "vat-fees-apply": "Enable VAT (value added tax) on fees",
      "vat-rate": "Value added tax (VAT) rate, %",
      "vat-membership-apply": "Apply VAT on Membership fees, Joining fee",
      "vat-cardprod-fees-apply": "Apply VAT on Card production fees",
      "vat-trans-fees-apply": "Apply VAT on Transaction fees (Card transactions, Top-up, Payments)",
      "vat-markup-fee-apply": "Apply VAT on FX markup fee",
      "vat-other-fees-apply": "Apply VAT on Statement fee, Inactivity fee, Balance inquiry fee, Decline fee",
      "nic-decl-trans-fee-val-pref-ex_bal_inq": "Exclude declined balance inquiry from decline fee",
      "vat-late-fee-apply": "Apply VAT on Late payment fee",
      "vat-ovl-fee-apply": "Apply VAT on Overlimit fee",
      "vat-nsf-fee-apply": "Apply VAT on Not sufficient funds fee",
      "nic-quasi-cash": "Quasi cash MCC list",
      "nic-decl-trans-fee-val-pref-rc": "Choose reason codes to apply decline fee",
    },
    hydrationKeys: {
      strings: [
        "nic-domestic-country",
        "vat-fees-apply",
        "vat-rate",
        "vat-membership-apply",
        "vat-cardprod-fees-apply",
        "vat-trans-fees-apply",
        "vat-markup-fee-apply",
        "vat-other-fees-apply",
        "nic-decl-trans-fee-val-pref-ex_bal_inq",
        "vat-late-fee-apply",
        "vat-ovl-fee-apply",
        "vat-nsf-fee-apply",
      ],
      lists: ["nic-quasi-cash", "nic-decl-trans-fee-val-pref-rc"],
    },
  },
  "status-management-codes": {
    labelMap: {
      "status-management-codes": "Status Management Codes",
      "nic-fc_posting": "",
      "nic-fc_report": "",
      "nic-fc_mf": "",
      "nic-fc_stmt": "",
      "nic-fc_stmt_fee": "",
      "nic-fc_inact_fee": "",
      "nic-fc_auth": "",
      "nic-fc_agrm": "",
      "nic-fc_sms": "",
      "nic-fc_ncon": "",
      "nic-r_card_act": "",
      "nic-r_card_autorepl": "",
      "nic-fc_tokens": "",
      "nic-fc_abu_status": "",
      "nic-bc-prior": "",
      "nic-bc-name": "",
      "nic-bc-a1-allow": "",
      "nic-bc-a2-allow": "",
      "nic-bc-c1-allow": "",
      "nic-bc-c1-from-card_st": "",
      "nic-bc-c1-from-falcon_block": "",
      "nic-fc_reiss": "",
      "nic-bc-c1-tib2-set": "",
      "nic-bc-a1-tib2-rem": "",
      "nic-bc-a2-tib2-rem": "",
      "nic-bc-c1-tib2-rem": "",
      "nic-bc-a1-fa-set": "",
      "nic-bc-a2-fa-set": "",
      "nic-bc-c1-fa-set": "",
      "nic-bc-a1-fa-rem": "",
      "nic-bc-a2-fa-rem": "",
      "nic-bc-c1-fa-rem": "",
      "nic-bc-a1-sa-set": "",
      "nic-bc-a2-sa-set": "",
      "nic-bc-c1-sa-set": "",
      "nic-bc-a1-sa-rem": "",
      "nic-bc-a2-sa-rem": "",
      "nic-bc-c1-sa-rem": "",
      "nic-fc_finch": "",
      "nic-fc_lpf": "",
      "nic-fc_ovl": "",
      "nic-fc_mtp": "",
      "nic-fc_cb_rep": "",
      "nic-fc_loy_redm": "",
      "nic-bc-a2-man-set": "",
      "nic-bc-a2-man-rem": "",
      "nic-bc-a2-tib2-set": "",
      "nic-dlq_lev": "",
      "nic-bc-a1-man-rem": "",
      "nic-dlq_perm": "",
      "nic-bc-a1-man-set": "",
      "nic-bc-c1-man-set": "",
      "nic-bc-c1-man-rem": "",
      "nic-bc-a2-tib1-set": "",
      "nic-bc-c1-tib1-set": "",
      "nic-bc-a2-tib1-rem": "",
      "nic-bc-c1-tib1-rem": "",
      "nic-bc-a1-tib2-set": "",
      "nic-fc_dd": "",
      "nic-fc_loy_accr": "",
      "nic-fc_ins": "",
      "nic-bc-c1-from-noncompl_c": "",
      "nic-bc-a1-from-noncompl": "",
      "already-edited": "",
      "bc-a1-man": "",
      "bc-a2-man": "",
      "bc-a3-man": "",
      "bc-a1-tib1": "",
      "bc-a2-tib1": "",
      "bc-a3-tib1": "",
      "bc-a1-tib2": "",
      "bc-a2-tib2": "",
      "bc-a3-tib2": "",
      "bc-a1-fa": "",
      "bc-a2-fa": "",
      "bc-a3-fa": "",
      "bc-a1-sa": "",
      "bc-a2-sa": "",
      "bc-a3-sa": "",
      "nic-bc-a1-tib1-set": "",
      "nic-bc-a1-tib1-rem": "",
    },
    hydrationKeys: {},
  },
  "data-framework": {
    labelMap: {
      "data-framework": "Data Framework",
      "(NIC-sdm-de).contract_card_suppl-cn-temp_credit_limit_reason": "Temporary limit Notes",
      "(NIC-sdm-de).contract_card_suppl-cn-temp_credit_limit_date_to": "Temporary credit limit expiry",
      "(NIC-sdm-de).contract_card_suppl-cn-temp_credit_limit_curr": "Temporary credit limit currency",
      "(NIC-sdm-de).contract_card_suppl-cn-temp_credit_limit_am": "Temporary credit limit",
      "(NIC-sdm-de).contract_card_suppl-cn-credit_limit_reason": "Credit limit updates notes",
      "(NIC-sdm-de).contract_card_suppl-cn-credit_limit_curr": "Credit limit currency",
      "(NIC-sdm-de).contract_card_suppl-cn-credit_limit_am": "Credit limit amount",
      "(NIC-sdm-de).contract_card_suppl-cn-cl_pcnt": "Supplementary credit limit percentage",
      "(NIC-sdm-de).contract_card_suppl-cn-emb_name_title": "Title on card",
      "(NIC-sdm-de).contract_card_suppl-cn-emb_name_last": "Last name on card",
      "(NIC-sdm-de).contract_card_suppl-cn-emb_name_first": "First name on card",
      "(NIC-sdm-de).contract_card_suppl-cn-card_design_flag": "Design flag",
      "(NIC-sdm-de).contract_card_suppl-cn-3ds_card_cred_list_outofband":
        "Outbound authentication in list of credential methods",
      "(NIC-sdm-de).contract_card_suppl-cn-3ds_card_cred_list_otp": "Include OTP in list of credential methods",
      "(NIC-sdm-de).contract_card_suppl-cn-3ds_card_cred_list_biometric": "Use biometric data for authentication",
      "(NIC-sdm-de).contract_card_suppl-cn-3ds_card_auth_type_second": "Secondary authorization type",
      "(NIC-sdm-de).contract_card_suppl-cn-3ds_card_auth_type_base": "Base authorization type",
    },
    hydrationKeys: {},
  },
  "sms-general-settings": {
    labelMap: {
      "sms-general-settings": "General Settings",
      "enable-sms-glob": "Enable SMS functionality",
      "nic-sms-t-deliv-ch": "Delivery channel",
      "default-lang": "Default language",
      "nic-sms-t-lang1": "Additional language 1",
      "nic-sms-t-lang2": "Additional language 2",
    },
    hydrationKeys: {},
  },
  "sms-templates": {
    labelMap: {
      "sms-templates": "SMS Templates",
      "17_GT-1": "Account was created",
      "4_START_CONTRACT_IMM_A-1": "Account contract was created",
      "18_GT-1": "Card was created",
      "4_START_CONTRACT_IMM_C-1": "Card contract was created",
      "16_GT-1": "Card Activation",
      "4_RC-BFA_CARD_EVER_ACTIV-Y-1": "Card was activated",
      "4_RC-BFC_CARD_EVER_ACTIV-Y-1": "Card activation confirmation",
      "9_GT-1": "New card was produced",
      "4_SMS_CP_NEW_PREEMB-1": "New card pre-embossed",
      "4_SMS_CP_NEW_PREEMB_NOFEE-1": "New card pre-embossed without fees",
      "4_SMS_CP_NEW_PREEMB_PINS-1": "New card pre-embossed with PINs",
      "4_SMS_CP_NEW_PREEMB_PINS_NOFEE-1": "New card pre-embossed with PINs without fees",
      "4_SMS_CP_NEW_NOFEE-1": "New card without fees",
      "4_SMS_CP_NEW_PINS_NOFEE-1": "New card with PINs without fees",
      "4_SMS_CP_NEW_PINS-1": "New card with PINs",
      "4_SMS_CP_NEW-1": "New card issued",
      "12_GT-1": "Card reissue was successful",
      "4_SMS_CP_REISS_PINS-1": "Card reissued with PINs",
      "4_SMS_CP_REISS_PREEMB-1": "Card reissued pre-embossed",
      "4_SMS_CP_REISS_PREEMB_NOFEE-1": "Card reissued pre-embossed without fees",
      "4_SMS_CP_REISS_PREEMB_PINS-1": "Card reissued pre-embossed with PINs",
      "4_SMS_CP_REISS_PREEMB_PINS_NOFEE-1": "Card reissued pre-embossed with PINs without fees",
      "4_SMS_CP_REISS_PINS_NOFEE-1": "Card reissued with PINs without fees",
      "4_SMS_CP_REISS_NOFEE-1": "Card reissued without fees",
      "4_SMS_CP_REISS-1": "Card reissued",
      "14_GT-1": "Card replacement was successful",
      "4_SMS_CP_REPL_PINS_NOFEE-1": "Card replaced with PINs without fees",
      "4_SMS_CP_REPL_PINS-1": "Card replaced with PINs",
      "4_SMS_CP_REPL_NOFEE-1": "Card replaced without fees",
      "4_SMS_CP_REPL-1": "Card replaced",
      "4_SMS_CP_REPL_PREEMB-1": "Card replaced pre-embossed",
      "4_SMS_CP_REPL_PREEMB_NOFEE-1": "Card replaced pre-embossed without fees",
      "4_SMS_CP_REPL_PREEMB_PINS-1": "Card replaced pre-embossed with PINs",
      "4_SMS_CP_REPL_PREEMB_PINS_NOFEE-1": "Card replaced pre-embossed with PINs without fees",
      "13_GT-1": "Card renewal was successful",
      "4_SMS_CP_RENEXP_PREEMB-1": "Card renewed pre-embossed",
      "4_SMS_CP_RENEXP_PREEMB_NOFEE-1": "Card renewed pre-embossed without fees",
      "4_SMS_CP_RENEXP_PREEMB_PINS-1": "Card renewed pre-embossed with PINs",
      "4_SMS_CP_RENEXP-1": "Card renewed",
      "4_SMS_CP_RENEXP_NOFEE-1": "Card renewed without fees",
      "4_SMS_CP_RENEXP_PINS_NOFEE-1": "Card renewed with PINs without fees",
      "4_SMS_CP_RENEXP_PINS-1": "Card renewed with PINs",
      "15_GT-1": "Card renewal",
      "4_SMS_CP_RNEW_PINS_NOFEE-1": "Card renewal with PINs without fees",
      "4_SMS_CP_RNEW-1": "Card renewal",
      "4_SMS_CP_RNEW_NOFEE-1": "Card renewal without fees",
      "4_SMS_CP_RNEW_PINS-1": "Card renewal with PINs",
      "10_GT-1": "PIN production was successful",
      "4_SMS_CP_PINPR_NOFEE-1": "PIN production without fees",
      "4_SMS_CP_PINPR-1": "PIN production",
      "11_GT-1": "PIN reissue was successful",
      "4_SMS_CP_PINRE_NOFEE-1": "PIN reissue without fees",
      "4_SMS_CP_PINRE-1": "PIN reissue",
      "4_SMS_CP_PLRE_PREEMB_PINK-1": "Pre-embossed PIN reissue",
      "4_SMS_CP_PLRE_PREEMB_PINK_NOFEE-1": "Pre-embossed PIN reissue without fees",
      "4_SMS_CP_PLRE_PINK_NOFEE-1": "PIN reissue without fees",
      "4_SMS_CP_PLRE_PINK-1": "PIN reissue",
      "41_GT-1": "Change PIN request",
      "3_CHANGE_PIN-1": "Change PIN",
      "4_GT-1": "Clear PIN attempts request",
      "4_CLEAR_PIN_ATT-1": "Clear PIN attempts",
      "5_GT-1": "Card auto-renewal was successful",
      "4_SMS_ACTIVATE_CARD-1": "Activate card",
      "6_GT-1": "Auto renewal was successful",
      "4_SMS_ACTIVATE_CARD-AU_NEW_PR-1": "Activate new card (Australia)",
      "4_SMS_ACTIVATE_CARD-AU_REPL_PR-1": "Activate replacement card (Australia)",
      "5_CARD_AUTO_RENEW-1": "Automatic card renewal",
      "7_GT-1": "Credit limit increase",
      "4_CREDIT_LIMIT_DOC_A-1": "Credit limit document (type A)",
      "4_CREDIT_LIMIT_DOC_C-1": "Credit limit document (type C)",
      "4_CREDIT_LIMIT_DOC_TEMP_A-1": "Temporary credit limit document (type A)",
      "42_GT-1": "Credit limit expiration",
      "4_CREDIT_LIMIT_DOC_TEMP_EXP_A-1": "Temporary credit limit document expiry (type A)",
      "43_GT-1": "Installment Payment Plan (IPP) balance",
      "4_SMS_IPP_BAL-1": "Installment Payment Plan (IPP) balance",
      "46_GT-1": "Installment Payment Plan (IPP) transaction",
      "4_SMS_IPP_TRANS-1": "Installment Payment Plan (IPP) transaction",
      "47_GT-1": "Account statement",
      "8_SMS_STATEMENT-1": "Account statement",
      "8_SMS_STATEMENT_ANY-1": "Any account statement",
      "7_SMS_DD_REQUEST-1": "Direct debit request",
      "7_SMS_DUE_REMINDER-1": "Due payment reminder",
      "4_SMS_AQ_EB_FP_DATE-1": "Final payment date",
      "44_GT-1": "Late payment fee charged",
      "6_SMS_LPF_CHARGE-1": "Late payment fee charge",
      "45_GT-1": "Overlimit fee charged",
      "19_GT-1": "Account closure",
      "4_SMS_CLOSE_TIMER_A-1": "Account closure timer",
      "4_RC-BCC-ACC1-V-1": "Reason code for account closure (type V)",
      "1_GT-1": "Card limit decrease",
      "4_RC-BCC-ACC1-W-1": "Reason code for account closure (type W)",
      "4_RC-BCC-ACC1-_-1": "Reason code for account closure",
      "4_RC-BCC-ACC2-B-1": "Reason code for account closure (type B)",
      "4_RC-BCC-ACC2-D-1": "Reason code for account closure (type D)",
      "4_RC-BCC-ACC2-M-1": "Reason code for account closure (type M)",
      "4_RC-BCC-ACC2-T-1": "Reason code for account closure (type T)",
      "4_RC-BCC-ACC2-V-1": "Reason code for account closure (type V)",
      "2_GT-1": "Fraud transaction detected",
      "4_RC-BCC-ACC2-_-1": "Reason code for account closure",
      "4_RC-BCC-ACC2-C-1": "Reason code for account closure (type C)",
      "4_RC-BCC-ACC2-E-1": "Reason code for account closure (type E)",
      "4_RC-BCC-ACC2-G-1": "Reason code for account closure (type G)",
      "4_RC-BCC-ACC2-J-1": "Reason code for account closure (type J)",
      "4_RC-BCC-ACC2-R-1": "Reason code for account closure (type R)",
      "4_RC-BCC-ACC2-U-1": "Reason code for account closure (type U)",
      "4_RC-BCC-ACC2-W-1": "Reason code for account closure (type W)",
      "4_RC-BCC-CARD-B-1": "Card blocked with Reason Code B",
      "4_RC-BCC-CARD-D-1": "Card blocked with Reason Code D",
      "4_RC-BCC-CARD-F-1": "Card blocked with Reason Code F",
      "4_RC-BCC-CARD-M-1": "Card blocked with Reason Code M",
      "4_RC-BCC-CARD-P-1": "Card blocked with Reason Code P",
      "4_RC-BCC-CARD-R-1": "Card blocked with Reason Code R",
      "4_RC-BCC-CARD-T-1": "Card blocked with Reason Code T",
      "3_GT-1": "Card limit increase",
      "4_RC-BCC-CARD-_-1": "Card blocked with unspecified Reason Code",
      "4_RC-BCC-CARD-C-1": "Card blocked with Reason Code C",
      "4_RC-BCC-CARD-H-1": "Card blocked with Reason Code H",
      "4_RC-BCC-CARD-J-1": "Card blocked with Reason Code J",
      "4_RC-BCC-CARD-L-1": "Card blocked with Reason Code L",
      "4_RC-BCC-CARD-N-1": "Card blocked with Reason Code N",
      "4_RC-BCC-CARD-O-1": "Card blocked with Reason Code O",
      "4_RC-BCC-CARD-S-1": "Card blocked with Reason Code S",
      "4_RC-BCC-CARD-U-1": "Card blocked with Reason Code U",
    },
    hydrationKeys: {},
  },
  "loyalty-settings": {
    labelMap: {
      "loyalty-settings": "Loyalty Settings",
      "lty-pnt-mode": "Store loyalty points in:",
      "lty-refund-mode": "Deduct points for refund transactions",
      "nic-lty-accr-rounding-decimals": "Number of decimals to round points",
      "nic-lty-red-rate-cash-rate": "Redemption rate for cash",
      "nic-lty-red-rate-ext-rate": "Redemption rate for external value",
      "lty-welc-bonus-enable": "Enable welcome bonus",
      "nic-lty-bonus_w-timer-from": "Welcome bonus timer trigger (count down starts from)",
      "nic-lty-bonus_w-timer-per-type": "Welcome bonus count down timer (number of periods)",
      "nic-lty-bonus_w-timer-per": "Welcome bonus timer period",
      "nic-lty-bonus_w-tar0-base": "Welcome bonus amount (number of points)",
    },
    hydrationKeys: {
      strings: [
        "lty-pnt-mode",
        "lty-refund-mode",
        "nic-lty-accr-rounding-decimals",
        "nic-lty-red-rate-cash-rate",
        "nic-lty-red-rate-ext-rate",
        "lty-welc-bonus-enable",
        "nic-lty-bonus_w-tar0-base",
        "nic-lty-bonus_w-timer-from",
        "nic-lty-bonus_w-timer-per-type",
        "nic-lty-bonus_w-timer-per",
      ],
    },
  },
};

export const TENANT_EDIT_PAGES_CODES = {
  loyaltyTemplates: {
    "transaction-eligibility": {
      labelMap: {
        "transaction-eligibility": "Transaction Eligibility",
        "lty-check-rules": "Transaction eligibility mode",
        "lty-rules-criteria": "Eligibility criteria",
        "lty-rule-cntr-list": "Country list",
        "lty-rule-term-list": "Specify merchant Terminal ID list",
        "lty-rule-merch-list": "Specify merchant ID list",
        "lty-rule-mcc-list": "Merchant category code list",
        "lty-rule-cntr": "Enable country criteria",
        "lty-rule-merch": "Enable merchant id criteria",
        "lty-rule-mcc": "Enable merchant category criteria",
        "lty-rule-term": "Enable terminal id criteria",
      },
      hydrationKeys: {
        strings: ["lty-check-rules", "lty-rule-merch", "lty-rule-mcc", "lty-rule-cntr"],
        lists: [
          "lty-rules-criteria",
          "lty-rule-term-list",
          "lty-rule-merch-list",
          "lty-rule-mcc-list",
          "lty-rule-cntr-list",
        ],
      },
    },
    "point-accrual-setup": {
      labelMap: {
        "point-accrual-setup": "Point Accrual Setup",
        type: "Loyalty program accrual type",
        "lty-program-cum-freq": "Choose point accrual frequency",
        "lty-program-acr-mode": "Choose cumulative program accrual mode",
        "lty-accr-b-rate": "Points accrual percentage rate",
        "lty-accr-cap-ctd": "Total points cap per cycle",
        "lty-accr-rate": "Points accrual percentage rate",
        "lty-accr-y-rate": "Total points cap per cycle",
      },
      hydrationKeys: {
        strings: [
          "lty-accr-b-rate",
          "lty-accr-cap-ctd",
          "lty-accr-rate",
          "lty-accr-y-rate",
          "lty-program-cum-freq",
          "lty-program-acr-mode",
          "type",
        ],
      },
    },
    "redemption-setup": {
      labelMap: {
        "redemption-setup": "Point Redemption Setup",
        "lty-cashback-auto-redeem": "Enable cashback auto-redemption",
        "lty-cashback-interval": "Choose cashback auto-redemption interval",
        "lty-ext-auto-redeem": "Enable external auto-redemption",
        "lty-redeem-type": "Choose redemption type",
      },
      hydrationKeys: {
        strings: ["lty-cashback-auto-redeem", "lty-cashback-interval", "lty-ext-auto-redeem", "lty-redeem-type"],
      },
    },
    "point-expiry": {
      labelMap: {
        "point-expiry": "Point Expiry",
        "lty-exp-enable": "Enable points expiry",
        "lty-exp-move": "Points validity period",
        "lty-exp-wo": "Points write off period",
      },
      hydrationKeys: {
        strings: ["lty-exp-enable", "lty-exp-move", "lty-exp-wo"],
      },
    },
    "applying-to-products": {
      labelMap: { "applying-to-products": "Applying to Products", productIds: "Apply to Products" },
      hydrationKeys: {},
    },
  },

  planTemplates: {
    details: {
      labelMap: {
        details: "IPP Details",
        name: "Plan Name",
        group: "Select Plan Group",
        currency: "Plan Currency",
        "nic-ipp-inst-scheme-plan-tenor": "Plan Tenor (number of months)",
        description: "Plan Description",
        "nic-plan-rollover-dlq-level-plan": "Plan Rollover Delinquency Level",
      },
      hydrationKeys: {
        strings: [
          "name",
          "group",
          "currency",
          "nic-ipp-inst-scheme-plan-tenor",
          "description",
          "nic-plan-rollover-dlq-level-plan",
        ],
      },
    },
    "transaction-eligibility": {
      labelMap: {
        "transaction-eligibility": "IPP Transaction Eligibility",
        "nic-ipp-inst-scheme-plan-min": "Minimum Amount",
        "nic-ipp-inst-scheme-plan-max": "Maximum Amount",
        "nic-ipp-inst-scheme-plan-max-days": "Maximum Days from Transaction",
      },
      hydrationKeys: {
        strings: ["nic-ipp-inst-scheme-plan-max", "nic-ipp-inst-scheme-plan-max-days", "nic-ipp-inst-scheme-plan-min"],
      },
    },
    profit: {
      labelMap: {
        "ipp-foreclosure-fee-enabled": "Enable IPP foreclosure fee",
        "nic-ipp-fc-fee-plan-base": "IPP Foreclosure fee (fixed)",
        "nic-ipp-fc-fee-plan-rate": "IPP Foreclosure fee (%)",
        "nic-ipp-fc-fee-plan-max": "IPP Foreclosure Max fee",
        "nic-ipp-fc-fee-plan-min": "IPP Foreclosure Min fee",
        "ipp-incent-fee-enabled": "Enable IPP incentive fee",
        "nic-ipp-incentive-fee-plan-base": "IPP Incentive fee (fixed)",
        "nic-ipp-incentive-fee-plan-rate": "IPP Incentive fee (%)",
        "nic-ipp-incentive-fee-plan-max": "IPP Incentive Max fee",
        "nic-ipp-incentive-fee-plan-min": "IPP Incentive Min fee",
        "ipp-int-opt2": "Calculate daily interest rate",
        "ipp-interest-enabled": "Enable interest income",
        "ipp-int-opt1": "Calculate interest amount on",
        "nic-ipp-inst-int-plan-rate": "Annual interest rate",
        "ipp-proc-fee-enabled": "Enable IPP processing fee",
        "nic-ipp-proc-plan-base": "IPP Processing fee (fixed)",
        "nic-ipp-proc-fee-plan-rate": "IPP Processing fee (%)",
        "nic-ipp-proc-plan-max": "IPP Processing Max fee",
        "nic-ipp-proc-plan-min": "IPP Processing Min fee",
      },
      hydrationKeys: {
        strings: [
          "ipp-foreclosure-fee-enabled",
          "nic-ipp-fc-fee-plan-base",
          "nic-ipp-fc-fee-plan-rate",
          "nic-ipp-fc-fee-plan-max",
          "nic-ipp-fc-fee-plan-min",
          "ipp-incent-fee-enabled",
          "nic-ipp-incentive-fee-plan-base",
          "nic-ipp-incentive-fee-plan-rate",
          "nic-ipp-incentive-fee-plan-max",
          "nic-ipp-incentive-fee-plan-min",
          "ipp-int-opt2",
          "ipp-interest-enabled",
          "ipp-int-opt1",
          "nic-ipp-inst-int-plan-rate",
          "ipp-proc-fee-enabled",
          "nic-ipp-proc-plan-base",
          "nic-ipp-proc-fee-plan-rate",
          "nic-ipp-proc-plan-max",
          "nic-ipp-proc-plan-min",
        ],
      },
    },
  },

  insuranceTemplates: {
    "insurance-identification": {
      labelMap: {
        "insurance-identification": "Insurance Template Identification",
        displayName: "Insurance Template Name",
        insuranceCompanyName: "Insurance Company Name",
        currency: "Insurance Template Currency",
      },
      hydrationKeys: {
        strings: ["displayName", "insuranceCompanyName", "currency"],
      },
    },
    "coverage-settings": {
      labelMap: {
        "coverage-settings": "Insurance Coverage Settings",
        "ins-cov-trunc":
          "Specify system action in case account's outstanding balance exceeds the maximum coverage amount",
        "ins-cov-max": "Insurance Maximum Coverage Amount",
        "ins-cov-ipp":
          "Default insurance covers: principal amount, unpaid fees, interests, VAT & due installments. Confirm whether to include unbilled payment plans installments",
      },
      hydrationKeys: {
        strings: ["ins-cov-trunc", "ins-cov-max", "ins-cov-ipp"],
      },
    },
    "premium-settings": {
      labelMap: {
        "premium-settings": "Insurance Premium Settings",
        "ins-prem-min": "Minimum Insurance Premium Cap",
        "ins-prem-fixed": "Insurance Fixed Premium Amount",
        "ins-prem-rate": "Insurance Premium Percentage",
        "ins-prem-period": "Specify Period Before Charging the First Insurance Premium",
        "ins-prem-period-type": "Period Type",
        "ins-prem-waive-zero": "Waive Premium on Zero Coverage Amount",
        "ins-prem-waive": "Waive Premium for Transactor Customer",
      },
      hydrationKeys: {
        strings: [
          "ins-prem-min",
          "ins-prem-fixed",
          "ins-prem-rate",
          "ins-prem-period",
          "ins-prem-period-type",
          "ins-prem-waive-zero",
          "ins-prem-waive",
        ],
      },
    },
    "cancellation-settings": {
      labelMap: {
        "cancellation-settings": "Insurance Cancellation Settings",
        "ins-cncl-age-limit": "Insurance Program Cancellation Age Limit",
        "ins-cncl-delinq-auto": "Specify Delinquency Level to Cancel Insurance with Auto Re-Instantiation",
        "ins-cncl-delinq-man": "Specify Delinquency Level to Cancel Insurance with Manual Re-Instantiation",
      },
      hydrationKeys: {
        strings: ["ins-cncl-age-limit", "ins-cncl-delinq-auto", "ins-cncl-delinq-man"],
      },
    },
    "applying-to-products": {
      labelMap: { "applying-to-products": "Insurance Applying to Products", productIds: "Apply Insurance" },
      hydrationKeys: {},
    },
  },
};
