import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useParamsList = <T>(dependencies: (keyof T)[] = []) => {
  const [search, setParams] = useSearchParams();

  const params: { [key in keyof T]?: string } = useMemo(() => {
    if (!search) {
      return {};
    }

    return dependencies.reduce((obj, dep) => {
      return {
        ...obj,
        [dep]: search.get(dep as string) ?? undefined,
      };
    }, {});
  }, [dependencies, search]);

  return {
    search,
    params,
    setParams,
  };
};

export type UseRouterWithParamsReturn = ReturnType<typeof useParamsList>;
