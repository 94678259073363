import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="1" y="1" width="30" height="30" rx="15" fill="#FFEDEF" />
    <rect x="1" y="1" width="30" height="30" rx="15" stroke="#E64360" strokeWidth="2" />
    <path
      d="M21.8337 13.4993C21.8337 14.7077 21.4753 15.816 20.8587 16.741C19.9587 18.0744 18.5337 19.016 16.8753 19.2577C16.592 19.3077 16.3003 19.3327 16.0003 19.3327C15.7003 19.3327 15.4087 19.3077 15.1253 19.2577C13.467 19.016 12.042 18.0744 11.142 16.741C10.5253 15.816 10.167 14.7077 10.167 13.4993C10.167 10.2743 12.7753 7.66602 16.0003 7.66602C19.2253 7.66602 21.8337 10.2743 21.8337 13.4993Z"
      stroke="#E64360"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7082 21.3917L22.3332 21.7167C22.0249 21.7917 21.7832 22.025 21.7166 22.3333L21.4249 23.5583C21.2666 24.225 20.4166 24.425 19.9749 23.9L15.9999 19.3333L12.0249 23.9083C11.5832 24.4333 10.7332 24.2333 10.5749 23.5667L10.2832 22.3417C10.2082 22.0333 9.96657 21.7917 9.66657 21.725L8.29157 21.4C7.65824 21.25 7.43324 20.4583 7.89157 20L11.1416 16.75C12.0416 18.0833 13.4666 19.025 15.1249 19.2667C15.4082 19.3167 15.6999 19.3417 15.9999 19.3417C16.2999 19.3417 16.5916 19.3167 16.8749 19.2667C18.5332 19.025 19.9582 18.0833 20.8582 16.75L24.1082 20C24.5666 20.45 24.3416 21.2417 23.7082 21.3917Z"
      stroke="#E64360"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4836 10.984L16.9753 11.9673C17.042 12.1006 17.217 12.234 17.3753 12.259L18.267 12.409C18.8336 12.5006 18.967 12.9173 18.5586 13.3256L17.867 14.0173C17.7503 14.134 17.6836 14.359 17.7253 14.5257L17.9253 15.384C18.0836 16.059 17.7253 16.3256 17.1253 15.9673L16.292 15.4756C16.142 15.384 15.892 15.384 15.742 15.4756L14.9086 15.9673C14.3086 16.3173 13.9503 16.059 14.1086 15.384L14.3086 14.5257C14.342 14.3673 14.2836 14.134 14.167 14.0173L13.4753 13.3256C13.067 12.9173 13.2003 12.509 13.767 12.409L14.6586 12.259C14.8086 12.234 14.9836 12.1006 15.0503 11.9673L15.542 10.984C15.7836 10.4507 16.217 10.4507 16.4836 10.984Z"
      stroke="#E64360"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LoyaltyRedIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
