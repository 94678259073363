import { useMemo } from "react";
import { Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { NIC_CASH_LIMIT_ACC_RATE, NIC_OVL_AUTH_VAR } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { getFormValueFromProductValues, minMaxValidator } from "@ni/common/utils";

import {
  NIC_AGRM_CANC_CRLIM_NULLIFY_ACC,
  NIC_AGRM_CANC_CRLIM_NULLIFY_CARD,
  NIC_CASH_LIMIT_CARD_RATE,
  NIC_CRLIM_DEFAULT,
  NIC_CRLIM_MAX,
  NIC_CRLIM_MIN,
  NIC_NULLIFY_CRLIM_ACC_ACC_ST_8,
  NIC_NULLIFY_CRLIM_ACC_ACC_ST_9,
  NIC_NULLIFY_CRLIM_ACC_ACC_ST_Z,
} from "./constants";

export const CreditLimit = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();

  const { currentProduct, onUpdateProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  useHydrateForm({
    form,
    entityFields: [
      ...(currentProduct.productValues ?? []),
      {
        fieldCode: NIC_CASH_LIMIT_CARD_RATE,
        value: (
          getFormValueFromProductValues(currentProduct.productValues, NIC_CASH_LIMIT_CARD_RATE) ||
          getFormValueFromProductValues(currentProduct.productValues, NIC_CASH_LIMIT_ACC_RATE)
        ).toString(),
      },
    ],
    keys: {
      strings: [
        NIC_CRLIM_MIN,
        NIC_CRLIM_MAX,
        NIC_CRLIM_DEFAULT,
        NIC_CASH_LIMIT_ACC_RATE,
        NIC_CASH_LIMIT_CARD_RATE,
        NIC_OVL_AUTH_VAR,
        NIC_AGRM_CANC_CRLIM_NULLIFY_ACC,
        NIC_AGRM_CANC_CRLIM_NULLIFY_CARD,
        NIC_NULLIFY_CRLIM_ACC_ACC_ST_8,
        NIC_NULLIFY_CRLIM_ACC_ACC_ST_9,
        NIC_NULLIFY_CRLIM_ACC_ACC_ST_Z,
      ],
    },
    allowParse: true,
  });

  const onFinish = (values: FormValues) => {
    void onUpdateProduct(values);
  };

  const relatedLinks = useMemo(
    () => [
      {
        label: `${currentProduct?.displayName} - Contract Cancellation`,
        href: "../manual-closure",
      },
      {
        label: `${currentProduct?.displayName} - Contract Auto Closure`,
        href: "../auto-closure",
      },
      {
        label: `${currentProduct?.displayName} - Contract Purge`,
        href: "../purge",
      },
      ...(currentProduct.parameterTables?.map(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/credit-limit`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - Credit Limit`,
      })) || []),
    ],
    [currentProduct.displayName, currentProduct.parameterTables, productId, tenantId],
  );

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Credit Limit"
      pageSubtitle="Credit limit refers to the total line of credit that can be used by an account to make purchases. Fees and interests billed to the account also make up for the total limit available to be used (Open-To-Buy). Credit limit can be set at account or card level. Card level Credit Limit cannot exceed the limit of account level."
      size="md"
      gap={40}
      formSize="md"
      level="root"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      submitHandler={onFinish}
      onValuesChange={form.validateFields}
    >
      <Space direction="vertical" size={24}>
        <NetworkForm.Number
          formItemOptions={{
            name: NIC_CRLIM_MIN,
            label: "Minimum credit limit",
            rules: [
              {
                validator: (_, value) =>
                  value >= form.getFieldValue(NIC_CRLIM_MAX) ? Promise.reject() : Promise.resolve(),
                message: "Minimum credit limit can not be more than or equal to maximum credit limit",
              },
            ],
          }}
          min={0}
          precision={2}
          controls={false}
          addonAfter={productCurrency}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: NIC_CRLIM_MAX,
            label: "Maximum credit limit",
            rules: [
              {
                validator: (_, value) =>
                  value <= form.getFieldValue(NIC_CRLIM_MIN) ? Promise.reject() : Promise.resolve(),
                message: "Maximum credit limit can not be less than or equal to minimum credit limit",
              },
            ],
          }}
          min={0}
          precision={2}
          controls={false}
          addonAfter={productCurrency}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: NIC_CRLIM_DEFAULT,
            label: "Default credit limit",
          }}
          min={0}
          precision={2}
          controls={false}
          addonAfter={productCurrency}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: NIC_CASH_LIMIT_ACC_RATE,
            label: "Cash limit (as a percentage of allowed account limit)",
            initialValue: 50,
            tooltip: "This rate can be redefined for a specific pricing control table of the credit product",
            rules: [
              {
                validator: (_, value: number) => {
                  return minMaxValidator(value, 0, 100);
                },
              },
            ],
          }}
          min={0}
          precision={2}
          controls={false}
          addonAfter="%"
        />

        <NetworkForm.Number
          formItemOptions={{
            name: NIC_CASH_LIMIT_CARD_RATE,
            label: "Cash limit (as a percentage of allowed card limit) for supplementary card",
            initialValue: 50,
            tooltip: "This rate can be redefined for a specific pricing control table of the credit product",
            rules: [
              {
                validator: (_, value: number) => {
                  return minMaxValidator(value, 0, 100);
                },
              },
            ],
          }}
          min={0}
          precision={2}
          controls={false}
          addonAfter="%"
        />

        <NetworkForm.Number
          formItemOptions={{
            name: NIC_OVL_AUTH_VAR,
            label: "Shadow limit (as a percentage of allowed account limit)",
            initialValue: 5,
            tooltip: `System provides the feasibility of giving a certain percentage of extra credit limit on top of the credit limit called as shadow limit. 
              It is possible to charge an overlimit fee in case of transactions amount exceeds the credit limit. 
              E.g., Credit limit is 1000 AED and shadow limit is 5%, then the customer is allowed to do authorization of 1050 AED amount. 
              This rate can be redefined for a specific pricing control table of the credit product.`,
            rules: [
              {
                validator: (_, value: number) => {
                  return minMaxValidator(value, 0, 100);
                },
              },
            ],
          }}
          min={0}
          precision={2}
          controls={false}
          addonAfter="%"
        />
      </Space>

      <Space direction="vertical" size={8}>
        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_AGRM_CANC_CRLIM_NULLIFY_ACC,
            valuePropName: "checked",
            label: "Nullify account credit limit on account cancellation",
            tooltip:
              "Cancellation means appropriate block code was applied to the account: e.g.  “Canceled by Bank“, “Canceled by Customer“",
            initialValue: false,
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_AGRM_CANC_CRLIM_NULLIFY_CARD,
            valuePropName: "checked",
            label: "Nullify card credit limit on card cancellation",
            tooltip:
              "It makes sense when card credit limit is a standalone fixed amount (not a percentage of a main account limit). Cancellation means appropriate block code was applied to the card: e.g.  “Canceled by Bank“, “Canceled by Customer“",
            initialValue: false,
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_NULLIFY_CRLIM_ACC_ACC_ST_8,
            valuePropName: "checked",
            label: "Nullify card credit limit on account closure",
            initialValue: false,
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_NULLIFY_CRLIM_ACC_ACC_ST_9,
            valuePropName: "checked",
            label: "Nullify card credit limit on account purge",
            initialValue: false,
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: NIC_NULLIFY_CRLIM_ACC_ACC_ST_Z,
            valuePropName: "checked",
            label: "Nullify card credit limit on account charge-off",
            initialValue: false,
          }}
        />
      </Space>
    </CustomFormWrapper>
  );
};
