import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useProductSettings } from "@ni/common/hooks";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";

import { pagesRoute } from "./route";

import styles from "../../../Style.module.scss";

export const LoyaltyProgramEdit = () => {
  const navigate = useNavigate();

  const {
    id: tenantId,
    productId,
    pctId,
    programId,
  } = useParams<{ id: string; productId: string; pctId: string; programId: string }>();

  const { currentProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: true,
  });

  const { currentLoyaltyProgram, loyaltyPrograms } = useMemo(
    () => ({
      currentLoyaltyProgram:
        (currentProduct.shortLoyaltyPrograms ?? []).find(lt => lt.id === +(programId ?? "0")) ?? {},
      loyaltyPrograms: currentProduct.shortLoyaltyPrograms,
    }),
    [currentProduct.shortLoyaltyPrograms, programId],
  );

  const handleRouteChange = useCallback(
    (id: number): void => {
      navigate(`/tenant/${tenantId}/product/${productId}/pct/${pctId}/loyalty-program/${id}/transaction-eligibility`);
    },
    [navigate, pctId, productId, tenantId],
  );

  return !!currentLoyaltyProgram?.id && !!loyaltyPrograms?.length && !!handleRouteChange ? (
    <RoutesHandler
      routePrefix="/"
      routesList={pagesRoute}
      ParentComponent={({ navItems, children }) => (
        <div className={styles["ni-program"]}>
          <Sidenav items={navItems} disabledItemsViewType="hidden">
            <SidenavEntitySelect
              value={currentLoyaltyProgram?.id}
              entities={loyaltyPrograms}
              onChange={handleRouteChange}
            />
          </Sidenav>
          <div className={styles["ni-program-content"]}>{children}</div>
        </div>
      )}
      deps={[currentLoyaltyProgram?.id, handleRouteChange]}
    />
  ) : null;
};
