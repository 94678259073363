import { useParamsList, usePrepareWizardPages, useWizardController } from "@ni/common/hooks";
import { WizardProcess } from "@ni/common/ui";

import { useInsuranceWizard } from "./hooks/useInsuranceWizard";
import { WIZARD_PAGES } from "./constants";

export const InsuranceWizard = () => {
  const {
    params: { tenantId: tenantIdQuery, insuranceTemplateId: insuranceTemplateIdQuery, page: pageQuery },
  } = useParamsList(["tenantId", "insuranceTemplateId", "phase", "page"]);

  const { phases, lastSubmittedStep, processWizardRequest } = useInsuranceWizard();

  const { pages, doesPageExistQuery } = usePrepareWizardPages({
    pageQuery: pageQuery ?? "",
    wizardPages: WIZARD_PAGES,
    lastSubmittedStep,
    phases,
  });

  const [wizardPlacement, setWizardPlacement] = useWizardController({
    phases,
    wizardType: "insurance",
    lastSubmittedStep,
    tenantIdQuery,
    insuranceTemplateIdQuery,
    pageQuery,
    doesPageExist: doesPageExistQuery,
    processWizardRequest: processWizardRequest as (tenantId?: string, entityId?: string) => Promise<void>,
  });

  return (
    <WizardProcess
      lastSubmittedStep={lastSubmittedStep}
      wizardPlacementState={[wizardPlacement, setWizardPlacement]}
      wizardPhases={phases}
      wizardPagesList={pages}
    />
  );
};
