import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: string;
}
interface RadioOptions extends Options {
  default?: string;
  options: SelectOption[];
}

export const RETAIL_INTEREST_GRACE_OPTIONS: RadioOptions = {
  name: "Retail interest grace options",
  code: "nic-int-grace-retail",
  default: "N",
  options: [
    {
      label: "No grace option. Always bill interest at the end of the cycle",
      value: "N",
      tooltip: "No waivers, interest is always billed at the end of the statement cycle.",
    },
    {
      label:
        "Grace option D. If full payment is made, then defer interest on new debits, waive deferred interest and interest on residual balance",
      value: "D",
      tooltip:
        "Cycle 1 - If full payment is made, then defer interest on new debits and waive interest on residual balance. \nCycle 2 - If full payment is made, then waive deferred interest from Cycle 1 and interest on residual balance.",
    },
    {
      label:
        "Grace option R. Always bill interest on the residual balance. Always defer interest on new debits. If full payment is made, then waive deferred interest.",
      value: "R",
      tooltip:
        "Cycle 1 - Defer interest on new debits regardless of any conditions. Charge interest on residual balance. Cycle 2 - If full payment is made, then waive deferred interest from Cycle 1 and charge interest on residual balance.",
    },
    {
      label:
        "Grace option G. Always defer interest on new debits. If full payment is made, then waive deferred interest and interest on the residual balance.",
      value: "G",
      tooltip:
        "Cycle 1 - If full payment is made, then waive interest on residual balance. Defer interest on new debits regardless of any conditions. Cycle 2 - If full payment is made, then waive deferred interest from Cycle 1 and interest on residual balance.",
    },
  ],
};

export const CASH_INTEREST_GRACE_OPTIONS: RadioOptions = {
  name: "Cash interest grace options",
  code: "nic-int-grace-cash",
  default: "N",
  options: [
    {
      label: "No grace option. Always bill interest at the end of the cycle",
      value: "N",
      tooltip: "No waivers, interest is always billed at the end of the statement cycle.",
    },
    {
      label:
        "Grace option D. If full payment is made, then defer interest on new debits, waive deferred interest and interest on residual balance",
      value: "D",
      tooltip:
        "Cycle 1 - If full payment is made, then defer interest on new debits and waive interest on residual balance. Cycle 2 - If full payment is made, then waive deferred interest from Cycle 1 and interest on residual balance.",
    },
    {
      label:
        "Grace option R.  Always bill interest on the residual balance. Always defer interest on new debits. If full payment is made, then waive deferred interest.",
      value: "R",
      tooltip:
        "Cycle 1 - Defer interest on new debits regardless of any conditions. Charge interest on residual balance. Cycle 2 - If full payment is made, then waive deferred interest from Cycle 1 and charge interest on residual balance.",
    },
    {
      label:
        "Grace option G.   Always defer interest on new debits. If full payment is made, then waive deferred interest and interest on the residual balance.",
      value: "G",
      tooltip:
        "Cycle 1 - If full payment is made, then waive interest on residual balance. Defer interest on new debits regardless of any conditions. Cycle 2 - If full payment is made, then waive deferred interest from Cycle 1 and interest on residual balance.",
    },
  ],
};

export const ZERO_FEES_OPTIONS: Options = {
  code: "nic-int-rate-zero-all-fees",
  name: "Zero interest rate on all fees",
  default: false,
};
export const ZERO_INTERESTS_OPTIONS: Options = {
  code: "nic-int-rate-zero-all-int",
  name: "Zero interest rate on all interests",
  default: false,
};
