import { ReactNode } from "react";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumaricOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const planNumberOption: NumaricOptions = {
  code: "code",
  name: "Plan number (5 digits)",
  decimalsNumber: 0,
  min: 0,
  max: 99999,
};

export const planNameOption: NumaricOptions = {
  code: "displayName",
  name: "Plan name",
  tooltip: "Specify the short plan name to have a semantic meaning for it",
};

export const planDescriptionOption: NumaricOptions = {
  code: "description",
  name: "Plan description",
  tooltip: "Specify the plan description to have more details about it",
};
