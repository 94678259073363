import { Space } from "antd";
import cn from "classnames";
import { StoreValue } from "rc-field-form/lib/interface";

import {
  amfAccMoment,
  amfAccMomentEvent,
  amfCardMoment,
  nicAmfCardWaiveUnexpOptions,
  nicLockedCardRcOptions,
  nicStmtFeeFlagOptions,
} from "@ni/common/mocks";
import { NetworkForm, TooltipInfo } from "@ni/common/ui";

import styles from "./styles.module.scss";

export type TabKey = "annual" | "monthly" | "statement" | "inactivity" | "overlimit";

const validateNumberInput = (value: StoreValue): Promise<string | void> => {
  if (!value) {
    return Promise.reject(new Error("Inactivity period number should not be null"));
  }
  if (Number(value).toFixed() !== String(value)) {
    return Promise.reject(new Error("Inactivity period number should be an integer number"));
  }
  if (Number(value) < 0) {
    return Promise.reject(new Error("Inactivity period number should not be negative"));
  }
  return Promise.resolve();
};

export const tabsPages = {
  annual: {
    title: <div title="Annual">Annual membership fee</div>,
    page: (accountMoment?: string, cardMoment?: string) => (
      <Space direction="vertical" size="large">
        <p>
          A membership fee is a recurring fee that clients are required to pay annually in order to maintain the
          benefits and privileges associated with the product
        </p>
        <NetworkForm.Radio
          formItemOptions={{
            name: "amf-acc-moment",
            label: "Charge date for primary card (for account)",
            className: "input-group-label",
            tooltip: "Choose the base date to charge this annual fee on this upcoming date anniversaries",
          }}
          radioList={amfAccMoment.map(val => ({
            label: val.value as string,
            value: val.key ?? "",
            tooltip: val.tooltip,
          }))}
        />

        {accountMoment === "S" && (
          <NetworkForm.Radio
            formItemOptions={{
              name: "nic-amf-acc-start-mode",
              label: "On the event of",
              className: "input-group-label",
              initialValue: "A",
            }}
            radioList={amfAccMomentEvent.map(val => ({
              label: val.value,
              value: val.key,
              tooltip: val.tooltip,
            }))}
          />
        )}

        <NetworkForm.Radio
          formItemOptions={{
            name: "amf-card-moment",
            label: "Charge date for supplementary cards",
            className: "input-group-label",
            tooltip: "Choose the base date to charge this annual fee on this upcoming date anniversaries",
          }}
          radioList={amfCardMoment.map(val => ({
            label: val.value as string,
            value: val.key ?? "",
          }))}
        />

        {cardMoment === "S" && (
          <NetworkForm.Radio
            formItemOptions={{
              name: "nic-amf-card-start-mode",
              label: "On the event of",
              className: "input-group-label",
              initialValue: "A",
            }}
            radioList={amfAccMomentEvent.map(val => ({
              label: val.value,
              value: val.key,
              tooltip: val.tooltip,
            }))}
          />
        )}

        <NetworkForm.Radio
          formItemOptions={{
            name: "nic-amf-card-waive-unexp",
            label: "Supplementary card waiving options",
            className: "input-group-label",
            tooltip: "Cards are issued and re-issued inactivated",
          }}
          radioList={nicAmfCardWaiveUnexpOptions.map(val => ({
            label: val.value as string,
            value: val.key ?? "",
          }))}
        />
      </Space>
    ),
  },
  monthly: {
    title: (
      <TooltipInfo
        label="Monthly membership fee"
        tooltipProps={{
          title: "The fee will be charged on statement (billing) day by default",
          className: styles["tab-title-tooltip"],
        }}
      />
    ),
    page: () => (
      <Space direction="vertical" size="large">
        <p>
          A membership fee is a recurring fee that clients are required to pay monthly in order to maintain the benefits
          and privileges associated with the product
        </p>
        <NetworkForm.Radio
          formItemOptions={{
            name: "nic-mmf-card-waive-unexp",
            label: "Supplementary card waiving options",
            className: "input-group-label",
            tooltip: "Cards are issued and re-issued inactivated",
          }}
          radioList={nicAmfCardWaiveUnexpOptions.map(val => ({
            label: val.value as string,
            value: val.key ?? "",
          }))}
        />
      </Space>
    ),
  },
  statement: {
    title: <div title="Statement">Statement fee</div>,
    page: () => (
      <Space direction="vertical" size="large">
        <p>
          Statement fee is the fixed amount to charge to the customer with the following statement flags: E-mail
          statement, Physical statement, or Both. The statement fee will be posted on billing date
        </p>
        <NetworkForm.CheckBox
          formItemOptions={{
            name: "nic-stmt-fee-flag",
            label: "Choose the client’s statement flags to apply your charge",
            className: "input-group-label",
            tooltip:
              "Account holders could select one of the listed flags. For example, if you want to apply the fee for clients with “Physical statement” and “Both” flags, select two options: “Physical statement” and “Both formats”",
          }}
          checkboxes={nicStmtFeeFlagOptions.map(val => ({
            label: val.value as string,
            value: val.key ?? "",
          }))}
        />
      </Space>
    ),
  },
  inactivity: {
    title: <div title="Inactivity">Inactivity fee</div>,
    page: () => (
      <Space direction="vertical" size="large">
        <p>
          Inactivity fee is the fee charged when there is no activity on the account for specific period. The inactivity
          state is defined by absence of financial activities on card excluding fees and charges transactions
        </p>
        <NetworkForm.Select
          formItemOptions={{
            name: "nic-inact-period-unit",
            label: "Inactivity period type",
            className: cn("input-group-label", styles["fees-product-input-width"]),
          }}
          placeholder="Inactivity period type"
          optionList={nicLockedCardRcOptions}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: "nic-inact-period",
            label: "Inactivity period number",
            className: cn("input-group-label", styles["fees-product-input-width"]),
            tooltip:
              "If the parameter is set as 3 months, then inactivity fee will be charged from the 4th month, if there was no financial activity on the account",
            rules: [
              {
                validator: (_, value) => {
                  return validateNumberInput(value);
                },
              },
            ],
          }}
        />
      </Space>
    ),
  },
  overlimit: {
    title: <div title="Overlimit">Overlimit fee</div>,
    page: () => (
      <Space direction="vertical" size="large">
        <p>Overlimit fee is charged when the utilization of a credit card exceeds the allowed credit limit</p>
        <NetworkForm.Radio
          formItemOptions={{
            name: "nic-ovl-fee-repeat-mode",
            label: "Overlimit fee charge options",
            className: cn("input-group-label"),
            tooltip: "In any of the options below, over limit fee is charged only once per cycle",
          }}
          radioList={[
            {
              value: "ONCE",
              label: "Once account is overlimit",
              tooltip:
                "The fee will be charged on the evening of the day account goes over limit, if over limit still exists",
            },
            {
              value: "END_OF_BILLING",
              label: "At end of each billing cycle",
              tooltip: "The fee will be charged at the last evening of each billing cycle, if over limit still exists",
            },
            {
              value: "ONCE_AND_END_OF_BILLING",
              label: "Once account is overlimit and at the last evening of each billing cycle",
              tooltip:
                "The fee will be charged on the evening of the day account goes over limit, if over limit still exists, and at the last evening of each billing cycle, if over limit still exists",
            },
            {
              value: "ONCE_AND_MONTHLY",
              label: "Once account is overlimit and at the first evening of each billing cycle",
              tooltip:
                "The fee will be charged on the evening of the day account goes over limit, if over limit still exists, and at the first evening of each billing cycle, if over limit still exists",
            },
          ]}
        />
      </Space>
    ),
  },
} as const;
