import { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import update from "immutability-helper";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { PlusOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { Spin } from "@ni/common/ui";
import { onConditionChangeValidation } from "@ni/common/utils";
import { ConfigurationApi, VariablesApi } from "@ni/sdk/apis";
import {
  BatchPatchEventSubgroupRequest,
  Event,
  EventGroup,
  EventSubgroup,
  PatchEventGroupRequest,
  Template,
  VariableGroup,
} from "@ni/sdk/models";

import { ItemTypes } from "../../constants";
import EventSubGroupCard from "../EventSubGroupCard";
import EventSubGroupDrawer from "../EventSubGroupDrawer";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();
const variablesServiceApi = new VariablesApi();

interface EventGroupFormProps {
  template: EventGroup;
  newEventGroupOrder: number;
}

interface EventGroupDetailsForm {
  applyConditions: string;
  eventSubgroups: Array<EventSubgroup>;
  id: number;
  name: string;
  order: number;
  variableGroups: Array<VariableGroup>;
}

const EventGroupForm: FC<EventGroupFormProps> = props => {
  const { template, newEventGroupOrder } = props;

  const [eventGroupForm] = Form.useForm();
  const [smsTemplates] = useReduxState<EventGroup[]>("smsTemplates", []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEventSubgroupLoading, setIsEventSubgroupLoading] = useReduxState<boolean>("isEventSubgroupLoading", false);

  const [selectedEventSubGroup, setSelectedEventSubGroup] = useReduxState<EventSubgroup>(
    "selectedEventSubGroup",
    {} as EventSubgroup,
  );
  const [newEventSubgroup, setNewEventSubgroup] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [eventSubGroupItems, setEventSubGroupItems] = useReduxState<EventSubgroup[]>("eventSubGroupItems", []);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [variableGroupsOptions, setVariableGroupsOptions] = useReduxState<VariableGroup[]>("variableGroupsOptions", []);

  useEffect(() => {
    if (!isLoading) {
      eventGroupForm.setFieldsValue({
        id: template.id,
        order: template.order,
        name: template.name,
        applyConditions: template.applyConditions,
        variableGroups: template.variableGroups?.map(item => {
          return item.name;
        }),
      });
      setEventSubGroupItems(template.eventSubgroups || []);
    }
  }, [eventGroupForm, isLoading, template, newEventGroupOrder]);

  const closeDrawerHandler = () => {
    setVisible(false);
  };

  const onEditHandleClick = (value: EventSubgroup) => {
    setNewEventSubgroup(false);
    setVisible(true);
    setSelectedEventSubGroup(value);
  };

  const onSaveEventGroup = (requestBody: PatchEventGroupRequest, eventGroupeId: string) => {
    setIsLoading(true);
    configurationServiceApi
      .patchEventGroup(requestBody, Number(eventGroupeId))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onFinish = (values: EventGroupDetailsForm) => {
    const selectedVariableGroupList = variableGroupsOptions.filter(item => values.variableGroups.includes(item));

    onSaveEventGroup(
      {
        applyConditions: values.applyConditions,
        eventSubgroups: template.eventSubgroups,
        name: values.name,
        order: values.order,
        variableGroups: selectedVariableGroupList.map(v => v.id),
      },
      String(values.id),
    );
  };

  const onCreateBtn = () => {
    setNewEventSubgroup(true);
    setVisible(true);
  };

  const getVariableGroupsOptions = () => {
    setIsLoading(true);

    variablesServiceApi
      .getVariableGroups()
      .then(response => {
        const modifiedList = response.data.map(item => {
          return { id: item.id, name: item.name };
        });

        setVariableGroupsOptions(modifiedList);
        setIsLoading(false);
        return response.data;
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!variableGroupsOptions.length) {
      getVariableGroupsOptions();
    }
  }, [variableGroupsOptions]);

  const onValueChange = (): void => {
    const isFieldsFilled = eventGroupForm.getFieldValue("name")?.length > 0;
    setIsEnabled(
      isFieldsFilled &&
        ((eventGroupForm.getFieldValue("applyConditions")?.length > 0 &&
          onConditionChangeValidation(String(eventGroupForm.getFieldValue("applyConditions")))) ||
          eventGroupForm.getFieldValue("applyConditions") === undefined ||
          eventGroupForm.getFieldValue("applyConditions")?.length === 0),
    );
  };

  const findEventSubGroupCard = useCallback(
    (eventSubgroupId: string) => {
      const card = eventSubGroupItems?.filter(c => c.id === Number(eventSubgroupId))[0] as {
        applyConditions: string;
        eventGroupId: number;
        events: Array<Event>;
        id: number;
        name: string;
        order: number;
        template: Template;
      };
      return {
        card,
        index: eventSubGroupItems?.indexOf(card),
      };
    },
    [eventSubGroupItems],
  );

  const getEventSubGroups = useCallback((): void => {
    setIsEventSubgroupLoading(true);

    configurationServiceApi
      .getEventSubgroups(template.id)
      .then(response => {
        setEventSubGroupItems(response.data);
        setIsEventSubgroupLoading(false);
      })
      .catch(() => {
        setIsEventSubgroupLoading(false);
      });
  }, [template.id]);

  const onUpdateEventSubgroupOrder = (items: EventSubgroup[]) => {
    setIsLoading(true);

    const eventSubGroupList: EventSubgroup[] = items.map((item, index) => {
      return {
        applyConditions: item.applyConditions,
        eventGroupId: item.eventGroupId,
        events: item.events,
        id: item.id,
        name: item.name,
        order: index,
        template: item.template,
      };
    });

    const requestBody: BatchPatchEventSubgroupRequest = {
      eventSubgroups: eventSubGroupList,
    };

    configurationServiceApi
      .batchPatchEventSubgroup(requestBody, template.id)
      .then(() => {
        getEventSubGroups();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const moveEventSubGroupCard = useCallback(
    (templateId: string, atIndex: number) => {
      const { card, index } = findEventSubGroupCard(templateId);
      setEventSubGroupItems(
        update(eventSubGroupItems, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },

    [findEventSubGroupCard, eventSubGroupItems, setEventSubGroupItems],
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.EVENT_SUB_GROUP }));

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Form
      id="eventGroupForm"
      className={styles["phase-details-form"]}
      form={eventGroupForm}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      onValuesChange={onValueChange}
      initialValues={{ id: template.id || undefined, phaseName: template.name || "" }}
      key={template.id ?? template.order}
    >
      <div className={styles["phase-details-form-info"]}>
        <div className={styles["title"]}>Group Settings</div>
        <div className={styles["phase-details-form-inputs"]}>
          <Form.Item hidden={true} name="id" />
          <Form.Item hidden={true} name="order" />
          <Form.Item
            className={styles["phase-input"]}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item className={styles["phase-input"]} label="Apply conditions" name="applyConditions">
            <Input />
          </Form.Item>
        </div>
        <div className={styles["phase-details-form-inputs"]}>
          <Form.Item className={styles["phase-input-long"]} label="Variable groups" name="variableGroups">
            <Select mode="multiple">
              {variableGroupsOptions?.map(type => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Button
          form="eventGroupForm"
          loading={isLoading}
          className={styles["submit-button"]}
          type="primary"
          size="large"
          key="submit"
          htmlType="submit"
          disabled={!isEnabled}
        >
          Save
        </Button>
      </div>
      <div className={styles["phase-details-form-pages"]}>
        <div className={styles["title"]}>Subgroups</div>
        <DndProvider backend={HTML5Backend} key={template.id}>
          {isEventSubgroupLoading ? (
            <Spin />
          ) : (
            <div ref={drop} className={styles["draggable-phases"]}>
              {eventSubGroupItems.length ? (
                eventSubGroupItems.map((eventSubgroupItem, index) => (
                  <EventSubGroupCard
                    eventSubgroup={eventSubgroupItem}
                    key={eventSubgroupItem.id ?? `new-${eventSubgroupItem.order ?? "page"}`}
                    id={String(eventSubgroupItem.id)}
                    templateId={template.id}
                    moveEventSubGroupCard={moveEventSubGroupCard}
                    findEventSubGroupCard={findEventSubGroupCard}
                    index={index}
                    eventSubGroupItems={eventSubGroupItems}
                    onUpdateEventSubgroupOrder={onUpdateEventSubgroupOrder}
                    onEditHandleClick={onEditHandleClick}
                  />
                ))
              ) : (
                <div className={styles["empty-pages"]}>This group does not have subgroups yet.</div>
              )}
            </div>
          )}
        </DndProvider>
        <div className={styles["phase-details-form-buttons"]}>
          <Button className={styles["button"]} type="link" htmlType="button" onClick={onCreateBtn} loading={isLoading}>
            <PlusOutlined />
            Add Subgroup
          </Button>
        </div>
      </div>
      <EventSubGroupDrawer
        opened={visible}
        closeDrawer={closeDrawerHandler}
        eventSubgroup={selectedEventSubGroup}
        newEventSubgroup={newEventSubgroup}
        templateId={template.id}
        getEventSubGroups={getEventSubGroups}
        eventSubGroupItems={eventSubGroupItems}
        smsTemplates={smsTemplates}
        templateVariableGroups={template.variableGroups}
      />
    </Form>
  );
};

export default EventGroupForm;
