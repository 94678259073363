export const CHECKBOX_GROUP_CONTRACT_LEVEL = [
  { label: "Per account", value: "A" },
  {
    label: "Per card",
    value: "C",
    tooltip: "It makes sense, if you want to have separate limits for primary and supplementary cards",
  },
];

export const CHECKBOX_GROUP_PAYM_LIM_PERIODS_A = [
  { value: "D", label: "Day" },
  { value: "B", label: "Billing" },
  { value: "Y", label: "365 sliding days" },
  { value: "F", label: "Forever" },
];

export const CHECKBOX_GROUP_PAYM_LIM_PERIODS_C = [
  { value: "D", label: "Day" },
  { value: "M", label: "Month" },
  { value: "Y", label: "365 sliding days" },
];

export const CHECKBOX_GROUP_PAYM_LIM_PERIODS_A_C = [
  { value: "D", label: "Day" },
  { value: "M", label: "Month (not available for an account)" },
  { value: "B", label: "Billing (not available for cards)" },
  { value: "Y", label: "365 sliding days" },
  { value: "F", label: "Forever (not available for cards)" },
];

export const PAYM_LIM_CONTR_LEVEL_LABEL = "Choose the client's contract level you want to apply limits:";
export const PAYM_LIM_PERIODS_LABEL = "Choose the period you want to restrict";
