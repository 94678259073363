import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 6" fill="none">
    <line x1="0.388916" y1="5" x2="19.3889" y2="5" stroke="currentColor" strokeWidth="2" />
    <line x1="0.388916" y1="1" x2="19.3889" y2="1" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export const DoubleLineIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
