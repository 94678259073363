import { FC, ReactNode } from "react";

import { TooltipInfo } from "@ni/common/ui";

import styles from "./styles.module.scss";

interface FeeEditingBoxProps {
  title: ReactNode;
  subtitle?: string;
  tooltipText?: string;
  children?: ReactNode;
}

export const FeeEditingBox: FC<FeeEditingBoxProps> = ({ title, subtitle = "", tooltipText = "", children }) => {
  return (
    <div className={styles["pct-editing-box"]}>
      <div className={styles["pct-editing-box-title"]}>
        <div className={styles["pct-editing-box-title__switch"]}>{title}</div>
      </div>
      {subtitle && (
        <div className="m-b-16">
          <TooltipInfo largeLabel={subtitle || ""} tooltipProps={{ title: tooltipText }} />
        </div>
      )}

      {children}
    </div>
  );
};
