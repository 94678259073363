import { ProductBcParams } from "./productBcParams";

export enum TableParams {
  a1Man = "bc-a1-man",
  a1Tib1 = "bc-a1-tib1",
  a1Tib2 = "bc-a1-tib2",
  a1Fa = "bc-a1-fa",
  a1Sa = "bc-a1-sa",

  a2Man = "bc-a2-man",
  a2Tib1 = "bc-a2-tib1",
  a2Tib2 = "bc-a2-tib2",
  a2Fa = "bc-a2-fa",
  a2Sa = "bc-a2-sa",

  a3Man = "bc-a3-man",
  a3Tib1 = "bc-a3-tib1",
  a3Tib2 = "bc-a3-tib2",
  a3Fa = "bc-a3-fa",
  a3Sa = "bc-a3-sa",
}

export const evaluationLinks = [
  {
    key: TableParams.a1Man,
    value: [ProductBcParams.a1ManSet, ProductBcParams.a1ManRem],
  },
  {
    key: TableParams.a2Man,
    value: [ProductBcParams.a2ManSet, ProductBcParams.a2ManRem],
  },
  {
    key: TableParams.a3Man,
    value: [ProductBcParams.a3ManSet, ProductBcParams.a3ManRem],
  },

  {
    key: TableParams.a1Tib1,
    value: [ProductBcParams.a1Tib1Set, ProductBcParams.a1Tib1Rem],
  },
  {
    key: TableParams.a2Tib1,
    value: [ProductBcParams.a2Tib1Set, ProductBcParams.a2Tib1Rem],
  },
  {
    key: TableParams.a3Tib1,
    value: [ProductBcParams.a3Tib1Set, ProductBcParams.a3Tib1Rem],
  },

  {
    key: TableParams.a1Tib2,
    value: [ProductBcParams.a1Tib2Set, ProductBcParams.a1Tib2Rem],
  },
  {
    key: TableParams.a2Tib2,
    value: [ProductBcParams.a2Tib2Set, ProductBcParams.a2Tib2Rem],
  },
  {
    key: TableParams.a3Tib2,
    value: [ProductBcParams.a3Tib2Set, ProductBcParams.a3Tib2Rem],
  },

  {
    key: TableParams.a1Fa,
    value: [ProductBcParams.a1FaSet, ProductBcParams.a1FaRem],
  },
  {
    key: TableParams.a2Fa,
    value: [ProductBcParams.a2FaSet, ProductBcParams.a2FaRem],
  },
  {
    key: TableParams.a3Fa,
    value: [ProductBcParams.a3FaSet, ProductBcParams.a3FaRem],
  },

  {
    key: TableParams.a1Sa,
    value: [ProductBcParams.a1SaSet, ProductBcParams.a1SaRem],
  },
  {
    key: TableParams.a2Sa,
    value: [ProductBcParams.a2SaSet, ProductBcParams.a2SaRem],
  },
  {
    key: TableParams.a3Sa,
    value: [ProductBcParams.a3SaSet, ProductBcParams.a3SaRem],
  },
];
