import { FeeRow } from "@ni/common/types";

import { CASH_LABEL } from "../TransactionFees.constants";

export const topUpFeesDescription =
  "Top-ups fees are charged when funds are credited to an account (payment that increases the available amount).";
export const paymentFeesDescription =
  "Payment fees are charged for transactions which are made by the customers to clear the card outstanding balances. The payment amount could be any amount which reduces the current balance and increases the available amount.";

export const checkboxFieldsTopUpFeesPage: { [key: string]: string[] } = {
  "paym-fee-enabled": [
    "nic-paym-online-first-base",
    "nic-paym-online-first-fee-rate",
    "nic-paym-online-first-min",
    "nic-paym-online-first-max",
    "nic-paym-online-subseq-base",
    "nic-paym-online-subseq-fee-rate",
    "nic-paym-online-subseq-min",
    "nic-paym-online-subseq-max",
  ],
};

export const emptyTopUpFeesFieldValues = {
  "paym-fee-enabled": false,
  "nic-paym-online-first-base": null,
  "nic-paym-online-first-fee-rate": null,
  "nic-paym-online-first-min": null,
  "nic-paym-online-first-max": null,
  "nic-paym-online-subseq-base": null,
  "nic-paym-online-subseq-fee-rate": null,
  "nic-paym-online-subseq-min": null,
  "nic-paym-online-subseq-max": null,
};

// Top up fees - PCT

// tab keys

export const onlineKey = "online";
export const inApplicationBoardingFileKey = "inApplicationBoardingFile";
export const internalP2PKey = "internalP2P";
export const externalTopupChannelsKey = "externalTopupChannels";
export const internalDirectDebit = "internalDirectDebit";

// Row labels

export const FIRST_TOP_UP_LABEL = (isCredit: boolean) => (isCredit ? "First payment" : "First top-up");
export const getFirstTooltip = (isCredit: boolean) =>
  isCredit ? "This fee is charged in case first top up or first payment is done on an account/card." : undefined;
export const SEQUENTIAL_TOP_UP_LABEL = (isCredit: boolean) => (isCredit ? "Sequential payments" : "Sequential top-ups");
export const getSequentialTooltip = (isCredit: boolean) =>
  isCredit ? "This fee is charged in case of subsequent payment made on an account/card." : undefined;
export const INTERNAL_P2P_LABEL = "Internal P2P";
export const FEE_P2P_RADIO_TARGET_KEY = "target";
export const FEE_P2P_RADIO_TARGET_LABEL = "The fee is charged from a receiver";
export const FEE_P2P_RADIO_SOURCE_KEY = "source";
export const FEE_P2P_RADIO_SOURCE_LABEL = "The fee is charged from a sender";
export const DEFAULT_FEE_LABEL = "Default fee";
export const SAME_ACCOUT_FEE_LABEL = "Same account fee";
export const SAME_CLIENT_FEE_LABEL = "Same client fee";
export const SAME_FI_FEE_LABEL = "Same FI fee";
export const SAME_CLIENT_LABEL = "Same client";
export const SAME_FI_LABEL = "Same FI";
export const AL_ANSARI_LABEL = "Al Ansari";
export const AL_FARADAN_LABEL = "Al Faradan";
export const ATM_LABEL = "ATM";
export const BANKNET_LABEL = "BankNet";
export const CDM_LABEL = "CDM";
export const CHEQUE_LABEL = "Cheque";
export const COLLECT_LABEL = "Collect";
export const OFFLINE_LABEL = "Offline";
export const SWIFT_LABEL = "Swift";
export const TRANSFER_LABEL = "Transfer";
export const UAE_EXCHANGE_LABEL = "UAE Exchange";
export const UAE_FTS_LABEL = "UAE FTS";

export const PAYM_FEE_ENABLED = "paym-fee-enabled";

// Online
// First top-up

export const NIC_PAYM_ONLINE_FIRST_BASE = "nic-paym-online-first-base";
export const NIC_PAYM_ONLINE_FIRST_FEE_RATE = "nic-paym-online-first-fee-rate";
export const NIC_PAYM_ONLINE_FIRST_MIN = "nic-paym-online-first-min";
export const NIC_PAYM_ONLINE_FIRST_MAX = "nic-paym-online-first-max";

// Sequential top-ups

export const NIC_PAYM_ONLINE_SUBSEQ_BASE = "nic-paym-online-subseq-base";
export const NIC_PAYM_ONLINE_SUBSEQ_FEE_RATE = "nic-paym-online-subseq-fee-rate";
export const NIC_PAYM_ONLINE_SUBSEQ_MIN = "nic-paym-online-subseq-min";
export const NIC_PAYM_ONLINE_SUBSEQ_MAX = "nic-paym-online-subseq-max";

// In application boarding file
// First top-up

export const NIC_PAYM_OUT_DEF_FIRST_BASE = "nic-paym-out-def-first-base";
export const NIC_PAYM_OUT_DEF_FIRST_FEE_RATE = "nic-paym-out-def-first-fee-rate";
export const NIC_PAYM_OUT_DEF_FIRST_MIN = "nic-paym-out-def-first-min";
export const NIC_PAYM_OUT_DEF_FIRST_MAX = "nic-paym-out-def-first-max";

// Sequential top-ups

export const NIC_PAYM_OUT_DEF_SUBSEQ_BASE = "nic-paym-out-def-subseq-base";
export const NIC_PAYM_OUT_DEF_SUBSEQ_FEE_RATE = "nic-paym-out-def-subseq-fee-rate";
export const NIC_PAYM_OUT_DEF_SUBSEQ_MIN = "nic-paym-out-def-subseq-min";
export const NIC_PAYM_OUT_DEF_SUBSEQ_MAX = "nic-paym-out-def-subseq-max";

// Internal P2P

export const FEE_P2P_SOURCE_TARGET = "fee-p2p-source-target";

// From receiver
// Default fee

export const NIC_PAYM_P2P_DEF_BF_BASE = "nic-paym_p2p_def-bf-base";
export const NIC_PAYM_P2P_DEF_BF_FEE_RATE = "nic-paym_p2p_def-bf-fee-rate";
export const NIC_PAYM_P2P_DEF_BF_MIN = "nic-paym_p2p_def-bf-min";
export const NIC_PAYM_P2P_DEF_BF_MAX = "nic-paym_p2p_def-bf-max";

// Same account fee

export const NIC_PAYM_P2P_MAIN_BF_BASE = "nic-paym_p2p_main-bf-base";
export const NIC_PAYM_P2P_MAIN_BF_FEE_RATE = "nic-paym_p2p_main-bf-fee-rate";
export const NIC_PAYM_P2P_MAIN_BF_MIN = "nic-paym_p2p_main-bf-min";
export const NIC_PAYM_P2P_MAIN_BF_MAX = "nic-paym_p2p_main-bf-max";

// Same client fee

export const NIC_PAYM_P2P_CL_BF_BASE = "nic-paym_p2p_cl-bf-base";
export const NIC_PAYM_P2P_CL_BF_FEE_RATE = "nic-paym_p2p_cl-bf-fee-rate";
export const NIC_PAYM_P2P_CL_BF_MIN = "nic-paym_p2p_cl-bf-min";
export const NIC_PAYM_P2P_CL_BF_MAX = "nic-paym_p2p_cl-bf-max";

// Same FI fee

export const NIC_PAYM_P2P_FI_BF_BASE = "nic-paym_p2p_fi-bf-base";
export const NIC_PAYM_P2P_FI_BF_FEE_RATE = "nic-paym_p2p_fi-bf-fee-rate";
export const NIC_PAYM_P2P_FI_BF_MIN = "nic-paym_p2p_fi-bf-min";
export const NIC_PAYM_P2P_FI_BF_MAX = "nic-paym_p2p_fi-bf-max";

// From sender
// Default fee

export const NIC_PAYSRC_P2P_DEF_BF_BASE = "nic-paysrc_p2p_def-bf-base";
export const NIC_PAYSRC_P2P_DEF_BF_FEE_RATE = "nic-paysrc_p2p_def-bf-fee-rate";
export const NIC_PAYSRC_P2P_DEF_BF_MIN = "nic-paysrc_p2p_def-bf-min";
export const NIC_PAYSRC_P2P_DEF_BF_MAX = "nic-paysrc_p2p_def-bf-max";

// Same account fee

export const NIC_PAYSRC_P2P_MAIN_BF_BASE = "nic-paysrc_p2p_main-bf-base";
export const NIC_PAYSRC_P2P_MAIN_BF_FEE_RATE = "nic-paysrc_p2p_main-bf-fee-rate";
export const NIC_PAYSRC_P2P_MAIN_BF_MIN = "nic-paysrc_p2p_main-bf-min";
export const NIC_PAYSRC_P2P_MAIN_BF_MAX = "nic-paysrc_p2p_main-bf-max";

// Same client

export const NIC_PAYSRC_P2P_CL_BF_BASE = "nic-paysrc_p2p_cl-bf-base";
export const NIC_PAYSRC_P2P_CL_BF_FEE_RATE = "nic-paysrc_p2p_cl-bf-fee-rate";
export const NIC_PAYSRC_P2P_CL_BF_MIN = "nic-paysrc_p2p_cl-bf-min";
export const NIC_PAYSRC_P2P_CL_BF_MAX = "nic-paysrc_p2p_cl-bf-max";

// Same FI

export const NIC_PAYSRC_P2P_FI_BF_BASE = "nic-paysrc_p2p_fi-bf-base";
export const NIC_PAYSRC_P2P_FI_BF_FEE_RATE = "nic-paysrc_p2p_fi-bf-fee-rate";
export const NIC_PAYSRC_P2P_FI_BF_MIN = "nic-paysrc_p2p_fi-bf-min";
export const NIC_PAYSRC_P2P_FI_BF_MAX = "nic-paysrc_p2p_fi-bf-max";

// External payments channels
// Al Ansari

export const NIC_PAYM_ALANSARI_BASE = "nic-paym-alansari-base";
export const NIC_PAYM_ALANSARI_FEE_RATE = "nic-paym-alansari-fee-rate";
export const NIC_PAYM_ALANSARI_MIN = "nic-paym-alansari-min";
export const NIC_PAYM_ALANSARI_MAX = "nic-paym-alansari-max";

// Al Faradan

export const NIC_PAYM_ALFARDAN_BASE = "nic-paym-alfardan-base";
export const NIC_PAYM_ALFARDAN_FEE_RATE = "nic-paym-alfardan-fee-rate";
export const NIC_PAYM_ALFARDAN_MIN = "nic-paym-alfardan-min";
export const NIC_PAYM_ALFARDAN_MAX = "nic-paym-alfardan-max";

// ATM

export const NIC_PAYM_ATM_BASE = "nic-paym-atm-base";
export const NIC_PAYM_ATM_FEE_RATE = "nic-paym-atm-fee-rate";
export const NIC_PAYM_ATM_MIN = "nic-paym-atm-min";
export const NIC_PAYM_ATM_MAX = "nic-paym-atm-max";

// BankNet

export const NIC_PAYM_BANKNET_BASE = "nic-paym-banknet-base";
export const NIC_PAYM_BANKNET_FEE_RATE = "nic-paym-banknet-fee-rate";
export const NIC_PAYM_BANKNET_MIN = "nic-paym-banknet-min";
export const NIC_PAYM_BANKNET_MAX = "nic-paym-banknet-max";

// Cash

export const NIC_PAYM_CASH_BASE = "nic-paym-cash-base";
export const NIC_PAYM_CASH_FEE_RATE = "nic-paym-cash-fee-rate";
export const NIC_PAYM_CASH_MIN = "nic-paym-cash-min";
export const NIC_PAYM_CASH_MAX = "nic-paym-cash-max";

// CDM

export const NIC_PAYM_CDM_BASE = "nic-paym-cdm-base";
export const NIC_PAYM_CDM_FEE_RATE = "nic-paym-cdm-fee-rate";
export const NIC_PAYM_CDM_MIN = "nic-paym-cdm-min";
export const NIC_PAYM_CDM_MAX = "nic-paym-cdm-max";

// Cheque

export const NIC_PAYM_CHEQUE_BASE = "nic-paym-cheque-base";
export const NIC_PAYM_CHEQUE_FEE_RATE = "nic-paym-cheque-fee-rate";
export const NIC_PAYM_CHEQUE_MIN = "nic-paym-cheque-min";
export const NIC_PAYM_CHEQUE_MAX = "nic-paym-cheque-max";

// Collect

export const NIC_PAYM_COLLECT_BASE = "nic-paym-collect-base";
export const NIC_PAYM_COLLECT_FEE_RATE = "nic-paym-collect-fee-rate";
export const NIC_PAYM_COLLECT_MIN = "nic-paym-collect-min";
export const NIC_PAYM_COLLECT_MAX = "nic-paym-collect-max";

// Offline

export const NIC_PAYM_OFFLINE_BASE = "nic-paym-offline-base";
export const NIC_PAYM_OFFLINE_FEE_RATE = "nic-paym-offline-fee-rate";
export const NIC_PAYM_OFFLINE_MIN = "nic-paym-offline-min";
export const NIC_PAYM_OFFLINE_MAX = "nic-paym-offline-max";

// Swift

export const NIC_PAYM_SWIFT_BASE = "nic-paym-swift-base";
export const NIC_PAYM_SWIFT_FEE_RATE = "nic-paym-swift-fee-rate";
export const NIC_PAYM_SWIFT_MIN = "nic-paym-swift-min";
export const NIC_PAYM_SWIFT_MAX = "nic-paym-swift-max";

// Transfer

export const NIC_PAYM_TRANSFER_BASE = "nic-paym-transfer-base";
export const NIC_PAYM_TRANSFER_FEE_RATE = "nic-paym-transfer-fee-rate";
export const NIC_PAYM_TRANSFER_MIN = "nic-paym-transfer-min";
export const NIC_PAYM_TRANSFER_MAX = "nic-paym-transfer-max";

// UAE Exchange

export const NIC_PAYM_UAEEXC_BASE = "nic-paym-uaeexc-base";
export const NIC_PAYM_UAEEXC_FEE_RATE = "nic-paym-uaeexc-fee-rate";
export const NIC_PAYM_UAEEXC_MIN = "nic-paym-uaeexc-min";
export const NIC_PAYM_UAEEXC_MAX = "nic-paym-uaeexc-max";

// UAE FTS

export const NIC_PAYM_UAEFTS_BASE = "nic-paym-uaefts-base";
export const NIC_PAYM_UAEFTS_FEE_RATE = "nic-paym-uaefts-fee-rate";
export const NIC_PAYM_UAEFTS_MIN = "nic-paym-uaefts-min";
export const NIC_PAYM_UAEFTS_MAX = "nic-paym-uaefts-max";

export const OnlineFields = (isCredit: boolean) => ({
  description: `${isCredit ? "P" : "Top-up p"}ayments to accounts/cards via online channels like self-service portal`,
  rows: [
    {
      rowLabel: FIRST_TOP_UP_LABEL(isCredit),
      tooltip: getFirstTooltip(isCredit),
      fields: [
        {
          name: NIC_PAYM_ONLINE_FIRST_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_ONLINE_FIRST_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_ONLINE_FIRST_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_ONLINE_FIRST_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SEQUENTIAL_TOP_UP_LABEL(isCredit),
      tooltip: getSequentialTooltip(isCredit),
      fields: [
        {
          name: NIC_PAYM_ONLINE_SUBSEQ_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_ONLINE_SUBSEQ_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_ONLINE_SUBSEQ_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_ONLINE_SUBSEQ_MAX,
          type: "max",
        },
      ],
    },
  ] as FeeRow[],
});
export const InApplicationBoardingFileFields = (isCredit: boolean) => ({
  description: `${
    isCredit ? "P" : "Top-up p"
  }ayments to accounts/cards initiated during account boarding via application file`,
  rows: [
    {
      rowLabel: FIRST_TOP_UP_LABEL(isCredit),
      tooltip: getFirstTooltip(isCredit),
      fields: [
        {
          name: NIC_PAYM_OUT_DEF_FIRST_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_OUT_DEF_FIRST_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_OUT_DEF_FIRST_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_OUT_DEF_FIRST_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SEQUENTIAL_TOP_UP_LABEL(isCredit),
      tooltip: getSequentialTooltip(isCredit),
      fields: [
        {
          name: NIC_PAYM_OUT_DEF_SUBSEQ_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_OUT_DEF_SUBSEQ_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_OUT_DEF_SUBSEQ_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_OUT_DEF_SUBSEQ_MAX,
          type: "max",
        },
      ],
    },
  ] as FeeRow[],
});
export const InternalP2PFieldsTargetFields = (isCredit: boolean) => ({
  description: `${isCredit ? "P" : "Top-up p"}ayments to accounts/cards as a result of internal Peer-to-peer transfers`,
  rows: [
    {
      rowLabel: DEFAULT_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYM_P2P_DEF_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_P2P_DEF_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_P2P_DEF_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_P2P_DEF_BF_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SAME_ACCOUT_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYM_P2P_MAIN_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_P2P_MAIN_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_P2P_MAIN_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_P2P_MAIN_BF_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SAME_CLIENT_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYM_P2P_CL_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_P2P_CL_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_P2P_CL_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_P2P_CL_BF_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SAME_FI_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYM_P2P_FI_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_P2P_FI_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_P2P_FI_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_P2P_FI_BF_MAX,
          type: "max",
        },
      ],
    },
  ] as FeeRow[],
});
export const InternalP2PFieldsSourceFields = (isCredit: boolean) => ({
  description: `${isCredit ? "P" : "Top-up p"}ayments to accounts/cards as a result of internal Peer-to-peer transfers`,
  rows: [
    {
      rowLabel: DEFAULT_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYSRC_P2P_DEF_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYSRC_P2P_DEF_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYSRC_P2P_DEF_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYSRC_P2P_DEF_BF_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SAME_ACCOUT_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYSRC_P2P_MAIN_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYSRC_P2P_MAIN_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYSRC_P2P_MAIN_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYSRC_P2P_MAIN_BF_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SAME_CLIENT_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYSRC_P2P_CL_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYSRC_P2P_CL_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYSRC_P2P_CL_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYSRC_P2P_CL_BF_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SAME_FI_FEE_LABEL,
      fields: [
        {
          name: NIC_PAYSRC_P2P_FI_BF_BASE,
          type: "default",
        },
        {
          name: NIC_PAYSRC_P2P_FI_BF_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYSRC_P2P_FI_BF_MIN,
          type: "min",
        },
        {
          name: NIC_PAYSRC_P2P_FI_BF_MAX,
          type: "max",
        },
      ],
    },
  ] as FeeRow[],
});

export const ExternalTopupChannelsFields = (isCredit: boolean) => ({
  description: `${
    isCredit ? "P" : "Top-up p"
  }ayments to accounts/cards via external payment channels like SWIFT or so. The payment process establishing is a separate subject.`,
  rows: [
    {
      rowLabel: AL_ANSARI_LABEL,
      fields: [
        {
          name: NIC_PAYM_ALANSARI_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_ALANSARI_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_ALANSARI_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_ALANSARI_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: AL_FARADAN_LABEL,
      fields: [
        {
          name: NIC_PAYM_ALFARDAN_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_ALFARDAN_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_ALFARDAN_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_ALFARDAN_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: ATM_LABEL,
      fields: [
        {
          name: NIC_PAYM_ATM_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_ATM_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_ATM_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_ATM_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: BANKNET_LABEL,
      fields: [
        {
          name: NIC_PAYM_BANKNET_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_BANKNET_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_BANKNET_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_BANKNET_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: CASH_LABEL,
      fields: [
        {
          name: NIC_PAYM_CASH_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_CASH_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_CASH_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_CASH_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: CDM_LABEL,
      fields: [
        {
          name: NIC_PAYM_CDM_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_CDM_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_CDM_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_CDM_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: CHEQUE_LABEL,
      fields: [
        {
          name: NIC_PAYM_CHEQUE_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_CHEQUE_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_CHEQUE_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_CHEQUE_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: COLLECT_LABEL,
      fields: [
        {
          name: NIC_PAYM_COLLECT_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_COLLECT_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_COLLECT_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_COLLECT_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: OFFLINE_LABEL,
      fields: [
        {
          name: NIC_PAYM_OFFLINE_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_OFFLINE_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_OFFLINE_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_OFFLINE_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: SWIFT_LABEL,
      fields: [
        {
          name: NIC_PAYM_SWIFT_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_SWIFT_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_SWIFT_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_SWIFT_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: TRANSFER_LABEL,
      fields: [
        {
          name: NIC_PAYM_TRANSFER_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_TRANSFER_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_TRANSFER_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_TRANSFER_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: UAE_EXCHANGE_LABEL,
      fields: [
        {
          name: NIC_PAYM_UAEEXC_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_UAEEXC_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_UAEEXC_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_UAEEXC_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: UAE_FTS_LABEL,
      fields: [
        {
          name: NIC_PAYM_UAEFTS_BASE,
          type: "default",
        },
        {
          name: NIC_PAYM_UAEFTS_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_PAYM_UAEFTS_MIN,
          type: "min",
        },
        {
          name: NIC_PAYM_UAEFTS_MAX,
          type: "max",
        },
      ],
    },
  ] as FeeRow[],
});

export const internalDirectDebitFields = (isCountryUAE: boolean) => ({
  description: `Direct Debit is an arrangement made between a customer’s bank and the card issuing bank to authorize money transfer from a customer’s bank account to credit card account to pay outstanding bills.`,
  rows: [
    {
      rowLabel: "Internal Direct Debit",
      tooltip:
        "Direct Debit is the one of payment method where the payment is made from cardholder's debit account belong to the same credit card bank. In this case the activity of receiving a Direct Debit request and generating a Payment advice becomes an internal activity within the bank.",
      fields: [
        {
          name: "nic-paym-dd-base",
          type: "default",
        },
        {
          name: "nic-paym-dd-fee-rate",
          type: "percentage",
        },
        {
          name: "nic-paym-dd-min",
          type: "min",
        },
        {
          name: "nic-paym-dd-max",
          type: "max",
        },
      ],
    },
    {
      rowLabel: "UAE Direct Debit Network",
      tooltip:
        "UAEDDS is the direct debit system of UAE Central Bank, which facilitates the Direct Debit payments between two different banks inside UAE. This is applicable in scenarios where the cardholder Credit Card and Direct Debit account do not belong to the same bank. ",
      fields: [
        {
          name: "nic-paym-uaedds-base",
          type: "default",
        },
        {
          name: "nic-paym-uaedds-fee-rate",
          type: "percentage",
        },
        {
          name: "nic-paym-uaedds-min",
          type: "min",
        },
        {
          name: "nic-paym-uaedds-max",
          type: "max",
        },
      ],
    },
    {
      rowLabel: "Direct debit insufficient fund rejection",
      tooltip:
        "In case of direct debit request is rejected by the bank due to insufficient funds, then the cardholder's payment will be reversed. In these scenarios insufficient fund fee will be charged to the customer.",
      fields: [
        {
          name: "nic-paymrev-nsf-base",
          type: "default",
        },
        {
          name: "nic-paymrev-nsf-fee-rate",
          type: "percentage",
        },
        {
          name: "nic-paymrev-nsf-min",
          type: "min",
        },
        {
          name: "nic-paymrev-nsf-max",
          type: "max",
        },
      ],
    },
  ].filter(x => x.rowLabel !== "UAE Direct Debit Network" || isCountryUAE) as FeeRow[],
});
