import { RoutesTreeItem } from "@ni/common/types";

import { LoyaltyPointAccrualSetup, LoyaltyPointExpiry, LoyaltyTransactionEligibility } from "../../../pages";

export const pagesRoute: RoutesTreeItem[] = [
  {
    name: "Transaction Eligibility",
    route: "transaction-eligibility",
    element: <LoyaltyTransactionEligibility />,
  },
  {
    name: "Point Accrual Setup",
    route: "point-accrual-setup",
    element: <LoyaltyPointAccrualSetup />,
  },
  {
    name: "Point Expiry",
    route: "point-expiry",
    element: <LoyaltyPointExpiry />,
  },
];
