/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeWorkflowRequest } from '../model';
// @ts-ignore
import { CopyWorkflowRequest } from '../model';
// @ts-ignore
import { CreateWorkflowRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PageDataDashboardWorkflows } from '../model';
// @ts-ignore
import { SortedFilteredPageRequest } from '../model';
// @ts-ignore
import { Workflow } from '../model';
/**
 * WorkflowApi - axios parameter creator
 * @export
 */
export const WorkflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the copied workflow
         * @summary Copy Workflow
         * @param {CopyWorkflowRequest} copyWorkflowRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWorkflow: async (copyWorkflowRequest: CopyWorkflowRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyWorkflowRequest' is not null or undefined
            assertParamExists('copyWorkflow', 'copyWorkflowRequest', copyWorkflowRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyWorkflow', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the workflow created
         * @summary Create Workflow
         * @param {CreateWorkflowRequest} createWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow: async (createWorkflowRequest: CreateWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWorkflowRequest' is not null or undefined
            assertParamExists('createWorkflow', 'createWorkflowRequest', createWorkflowRequest)
            const localVarPath = `/api/v1/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Workflow
         * @summary Delete Workflow by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWorkflow', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Workflow by ID
         * @summary Get Workflow by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWorkflowById', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Workflow page for given page number, page size and sorting / filtering criteria
         * @summary Get Workflow Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows: async (sortedFilteredPageRequest: SortedFilteredPageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sortedFilteredPageRequest' is not null or undefined
            assertParamExists('getWorkflows', 'sortedFilteredPageRequest', sortedFilteredPageRequest)
            const localVarPath = `/api/v1/workflows/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sortedFilteredPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates Workflow and returns result of operation
         * @summary Edit Workflow by ID
         * @param {ChangeWorkflowRequest} changeWorkflowRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow: async (changeWorkflowRequest: ChangeWorkflowRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeWorkflowRequest' is not null or undefined
            assertParamExists('updateWorkflow', 'changeWorkflowRequest', changeWorkflowRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWorkflow', 'id', id)
            const localVarPath = `/api/v1/workflows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkflowApi - functional programming interface
 * @export
 */
export const WorkflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkflowApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the copied workflow
         * @summary Copy Workflow
         * @param {CopyWorkflowRequest} copyWorkflowRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyWorkflow(copyWorkflowRequest: CopyWorkflowRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyWorkflow(copyWorkflowRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the workflow created
         * @summary Create Workflow
         * @param {CreateWorkflowRequest} createWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflow(createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflow(createWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Workflow
         * @summary Delete Workflow by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Workflow by ID
         * @summary Get Workflow by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Workflow page for given page number, page size and sorting / filtering criteria
         * @summary Get Workflow Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflows(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDataDashboardWorkflows>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflows(sortedFilteredPageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates Workflow and returns result of operation
         * @summary Edit Workflow by ID
         * @param {ChangeWorkflowRequest} changeWorkflowRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflow(changeWorkflowRequest: ChangeWorkflowRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflow(changeWorkflowRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkflowApi - factory interface
 * @export
 */
export const WorkflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkflowApiFp(configuration)
    return {
        /**
         * Returns the copied workflow
         * @summary Copy Workflow
         * @param {CopyWorkflowRequest} copyWorkflowRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyWorkflow(copyWorkflowRequest: CopyWorkflowRequest, id: number, options?: any): AxiosPromise<Workflow> {
            return localVarFp.copyWorkflow(copyWorkflowRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the workflow created
         * @summary Create Workflow
         * @param {CreateWorkflowRequest} createWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(createWorkflowRequest: CreateWorkflowRequest, options?: any): AxiosPromise<Workflow> {
            return localVarFp.createWorkflow(createWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Workflow
         * @summary Delete Workflow by Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkflow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Workflow by ID
         * @summary Get Workflow by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowById(id: number, options?: any): AxiosPromise<Workflow> {
            return localVarFp.getWorkflowById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Workflow page for given page number, page size and sorting / filtering criteria
         * @summary Get Workflow Page
         * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: any): AxiosPromise<PageDataDashboardWorkflows> {
            return localVarFp.getWorkflows(sortedFilteredPageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates Workflow and returns result of operation
         * @summary Edit Workflow by ID
         * @param {ChangeWorkflowRequest} changeWorkflowRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow(changeWorkflowRequest: ChangeWorkflowRequest, id: number, options?: any): AxiosPromise<Workflow> {
            return localVarFp.updateWorkflow(changeWorkflowRequest, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkflowApi - interface
 * @export
 * @interface WorkflowApi
 */
export interface WorkflowApiInterface {
    /**
     * Returns the copied workflow
     * @summary Copy Workflow
     * @param {CopyWorkflowRequest} copyWorkflowRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    copyWorkflow(copyWorkflowRequest: CopyWorkflowRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Workflow>;

    /**
     * Returns the workflow created
     * @summary Create Workflow
     * @param {CreateWorkflowRequest} createWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    createWorkflow(createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig): AxiosPromise<Workflow>;

    /**
     * Deletes Workflow
     * @summary Delete Workflow by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    deleteWorkflow(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns Workflow by ID
     * @summary Get Workflow by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    getWorkflowById(id: number, options?: AxiosRequestConfig): AxiosPromise<Workflow>;

    /**
     * Returns Workflow page for given page number, page size and sorting / filtering criteria
     * @summary Get Workflow Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    getWorkflows(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig): AxiosPromise<PageDataDashboardWorkflows>;

    /**
     * Updates Workflow and returns result of operation
     * @summary Edit Workflow by ID
     * @param {ChangeWorkflowRequest} changeWorkflowRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    updateWorkflow(changeWorkflowRequest: ChangeWorkflowRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<Workflow>;

}

/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
export class WorkflowApi extends BaseAPI implements WorkflowApiInterface {
    /**
     * Returns the copied workflow
     * @summary Copy Workflow
     * @param {CopyWorkflowRequest} copyWorkflowRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public copyWorkflow(copyWorkflowRequest: CopyWorkflowRequest, id: number, options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).copyWorkflow(copyWorkflowRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the workflow created
     * @summary Create Workflow
     * @param {CreateWorkflowRequest} createWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public createWorkflow(createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).createWorkflow(createWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Workflow
     * @summary Delete Workflow by Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public deleteWorkflow(id: number, options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).deleteWorkflow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Workflow by ID
     * @summary Get Workflow by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getWorkflowById(id: number, options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).getWorkflowById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Workflow page for given page number, page size and sorting / filtering criteria
     * @summary Get Workflow Page
     * @param {SortedFilteredPageRequest} sortedFilteredPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public getWorkflows(sortedFilteredPageRequest: SortedFilteredPageRequest, options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).getWorkflows(sortedFilteredPageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates Workflow and returns result of operation
     * @summary Edit Workflow by ID
     * @param {ChangeWorkflowRequest} changeWorkflowRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    public updateWorkflow(changeWorkflowRequest: ChangeWorkflowRequest, id: number, options?: AxiosRequestConfig) {
        return WorkflowApiFp(this.configuration).updateWorkflow(changeWorkflowRequest, id, options).then((request) => request(this.axios, this.basePath));
    }
}
