import { Button, Flex } from "antd";
import cn from "classnames";

import { useReduxState } from "@ni/common/hooks";
import { ListViewIcon, TableViewIcon } from "@ni/common/icons";

import styles from "./styles.module.scss";

type SMCViewTypes = "default" | "table";

const SMCViewToggles = () => {
  const [viewMode, setViewMode] = useReduxState<SMCViewTypes>("smcViewMode", "table");

  return (
    <Flex align="center" gap={8}>
      <Button
        icon={<ListViewIcon />}
        className={cn(styles["sort-button"], viewMode === "default" && styles["selected"])}
        onClick={() => setViewMode("default")}
      />
      <Button
        icon={<TableViewIcon />}
        className={cn(styles["sort-button"], viewMode === "table" && styles["selected"])}
        onClick={() => setViewMode("table")}
      />
    </Flex>
  );
};

export { SMCViewToggles };
export type { SMCViewTypes };
