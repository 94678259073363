import { FC, useEffect, useState } from "react";
import { Button, Col, Empty, Flex, Form, Input, Modal, Pagination, Row, Space, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { EditOutlined, SettingOutlined } from "@ant-design/icons";
import { initialInsurancePagination, useDebaunce, useInsuranceProgramTemplates, useReduxState } from "@ni/common/hooks";
import { CopyIcon, WandIcon } from "@ni/common/icons";
import { FormValues } from "@ni/common/types";
import { CardPanel, FilterControls, SettingsPageLayout } from "@ni/common/ui";
import { updateFilters } from "@ni/common/utils";
import { InsuranceProgramState, InsuranceProgramTemplate } from "@ni/sdk/models";

import { insuranceFilterInputs } from "./constants";

const filterKeys = ["state", "currency"];

export const InsuranceProgramTemplatesPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const [modalForm] = Form.useForm<FormValues>();

  const { id: tenantId } = useParams<{ id: string }>();

  const searchQuery = Form.useWatch<string>("search", form);
  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });

  const [renameModalOpen, setRenameModalOpen] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<InsuranceProgramTemplate>({});

  const [isLoading] = useReduxState<boolean>("isLoading");
  const [isPrintMode] = useReduxState<boolean>("isPrintMode");

  const navigate = useNavigate();

  const {
    insuranceProgramTemplates,
    copyInsuranceProgramTemplate,
    editInsuranceProgramTemplate,
    pageHasContent,
    pagination,
    setPagination,
    setFilters,
  } = useInsuranceProgramTemplates({
    tenantId: parseInt(tenantId ?? "0", 10),
    isFetchEnabled: true,
  });

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setFilters(filters => {
        return updateFilters(filterKeys, filters, undefined, debouncedQuery);
      });

      setPagination(initialInsurancePagination);
    }
  }, [debouncedQuery, setPagination, setFilters]);

  const handleValuesChange = (changedValues: FormValues) => {
    const { search } = changedValues;

    if (typeof search === "string") return;

    setFilters(filters => {
      return updateFilters(filterKeys, filters, changedValues, (search as string) || debouncedQuery);
    });

    setPagination(initialInsurancePagination);
  };

  const handlePaginationChange = (page: number) => {
    setPagination(prev => ({ ...prev, current: page }));
  };

  const handleContinueClick = (id: number) => {
    const queries = new URLSearchParams({ tenantId: tenantId!, insuranceTemplateId: id.toString() });

    navigate({
      pathname: "/create-insurance-program-template",
      search: queries.toString(),
    });
  };

  const handleNewTemplateClick = () => {
    const queries = new URLSearchParams({ tenantId: tenantId! });

    navigate({
      pathname: "/create-insurance-program-template",
      search: queries.toString(),
    });
  };

  const handleEditClick = (obj: InsuranceProgramTemplate) => {
    if (tenantId && obj.id) navigate(`/tenant/${tenantId}/edit-insurance/${obj.id}/insurance-identification`);
  };

  const renderSubtitleText = () => (
    <>
      Insurance programs offers customers protection against unforeseen circumstances, also enables the financial
      institution to reduce credit risk while generating additional revenue. You can create insurance templates here and
      apply it to your credit products to create new programs. Templates help you reuse a base with filled information
      instead of starting over every time, you can still change your program details independently under each product
      level after applying your template.
      {!pageHasContent && (
        <>
          <br />
          <br />
          You don&apos;t have any insurance programs yet. Our smart wizard will guide you through the process.
        </>
      )}
    </>
  );

  const handleRenameClick = (obj: InsuranceProgramTemplate) => {
    modalForm.setFieldsValue({
      renameNewDisplayName: obj.name,
    });
    setSelectedTemplate(obj);
    setRenameModalOpen(true);
  };

  const handleDuplicateClick = (obj: InsuranceProgramTemplate) => {
    modalForm.setFieldsValue({
      copyNewDisplayName: `${obj.name} - copy`,
    });
    setSelectedTemplate(obj);
    setDuplicateModalOpen(true);
  };

  const renderActionButtons = (obj: InsuranceProgramTemplate) => {
    return obj.state === InsuranceProgramState.DRAFT
      ? [
          <Button
            key="insuranceWizard"
            type="text"
            htmlType="button"
            className="text-primary"
            icon={<WandIcon />}
            onClick={() => handleContinueClick(obj.id!)}
          >
            Continue Creating
          </Button>,
        ]
      : [
          <Tooltip key="insuranceEdit" title="Rename">
            <Button type="text" htmlType="button" icon={<EditOutlined />} onClick={() => handleRenameClick(obj)} />
          </Tooltip>,
          <Tooltip key="insuranceSettings" title="Edit">
            <Button type="text" htmlType="button" icon={<SettingOutlined />} onClick={() => handleEditClick(obj)} />
          </Tooltip>,
          <Tooltip key="insuranceCopy" title="Duplicate">
            <Button type="text" htmlType="button" icon={<CopyIcon />} onClick={() => handleDuplicateClick(obj)} />
          </Tooltip>,
        ];
  };

  const toggleTemplate = async (values: FormValues, templateId: number) => {
    const { state } = values;
    await editInsuranceProgramTemplate(
      { state: state ? InsuranceProgramState.ACTIVE : InsuranceProgramState.INACTIVE },
      templateId,
    );
  };

  const renameTemplate = async (values: FormValues, templateId: number) => {
    const { renameNewDisplayName } = values;

    await editInsuranceProgramTemplate({ name: renameNewDisplayName }, templateId);
    setRenameModalOpen(false);
  };

  const duplicateTemplate = async (values: FormValues, templateId: number) => {
    const { copyNewDisplayName } = values;

    await copyInsuranceProgramTemplate(copyNewDisplayName as string, templateId);
    setDuplicateModalOpen(false);
  };

  return (
    <SettingsPageLayout pageTitle="Insurance Program Templates" pageSubtitle={renderSubtitleText()}>
      <Space direction="vertical" size={40} className="w-p-100">
        {pageHasContent && (
          <div>
            <FilterControls
              form={form}
              inputs={insuranceFilterInputs}
              onValuesChange={handleValuesChange}
              searchName="search"
            />

            <Space direction="vertical" size={16} className="w-p-100">
              <Row wrap={true} gutter={[16, 16]}>
                {insuranceProgramTemplates?.content?.map(obj => (
                  <Col key={`${obj.id}-${obj.name}`} span={8}>
                    <CardPanel
                      title={obj.name}
                      details={[
                        {
                          field: "Insurance company",
                          value: obj?.insuranceCompanyName ?? "-",
                        },
                        {
                          field: "Template currency",
                          value: obj?.currency ?? "-",
                        },
                      ]}
                      actions={renderActionButtons(obj)}
                      toggleState={obj.state === InsuranceProgramState.ACTIVE}
                      toggleDisabled={obj.state === InsuranceProgramState.DRAFT}
                      onToggle={values => toggleTemplate(values, obj.id!)}
                      vertical={true}
                    />
                  </Col>
                ))}
              </Row>

              <Flex justify="flex-end">
                <Pagination
                  hideOnSinglePage={true}
                  showSizeChanger={false}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  current={pagination.current}
                  onChange={page => handlePaginationChange(page)}
                />
              </Flex>

              {!insuranceProgramTemplates.hasContent && (
                <Flex justify="center" align="center">
                  <Empty />
                </Flex>
              )}
            </Space>
          </div>
        )}

        {!isPrintMode && (
          <Button size="large" type="primary" htmlType="button" loading={isLoading} onClick={handleNewTemplateClick}>
            New insurance program template
          </Button>
        )}
      </Space>

      <Modal
        title="Rename Insurance Program Template"
        width="500px"
        open={renameModalOpen}
        onCancel={() => setRenameModalOpen(false)}
        footer={[
          <Button key="back" disabled={isLoading} onClick={() => setRenameModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={modalForm.submit}>
            Rename
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          autoComplete="off"
          layout="vertical"
          disabled={isLoading}
          onFinish={values => renameTemplate(values, selectedTemplate.id!)}
        >
          <Form.Item
            name="renameNewDisplayName"
            label="Name"
            rules={[
              {
                type: "string",
                required: true,
                max: 64,
                message: "Cannot be empty. Max length is 64 letters",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Duplicate Insurance Program Template"
        width="500px"
        open={duplicateModalOpen}
        onCancel={() => setDuplicateModalOpen(false)}
        footer={[
          <Button key="back" disabled={isLoading} onClick={() => setDuplicateModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={modalForm.submit}>
            Duplicate
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          autoComplete="off"
          layout="vertical"
          disabled={isLoading}
          onFinish={values => duplicateTemplate(values, selectedTemplate.id!)}
        >
          <Form.Item
            name="copyNewDisplayName"
            label="Name"
            rules={[
              {
                type: "string",
                required: true,
                max: 64,
                message: "Cannot be empty. Max length is 64 letters",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </SettingsPageLayout>
  );
};
