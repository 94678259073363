import { FilterInputProps } from "@ni/common/ui";
import { PlanState } from "@ni/sdk/models";

export const ippFilterInputs: FilterInputProps[] = [
  {
    name: "plan.state",
    type: "select",
    props: {
      placeholder: "State",
      options: [
        { value: "", label: "All States" },
        { value: PlanState.DRAFT, label: "Draft" },
        { value: PlanState.ACTIVE, label: "Enabled" },
        { value: PlanState.INACTIVE, label: "Disabled" },
      ],
    },
  },
  {
    name: "plan.group",
    type: "select",
    props: {
      placeholder: "Group",
      options: [
        { value: "", label: "All Groups" },
        { value: "IPP_BC", label: "Balance conversion" },
        { value: "IPP_TC", label: "Transaction conversion" },
        { value: "IPP_NB", label: "New balance" },
      ],
    },
  },
  {
    name: "plan.currency",
    type: "select",
    props: {
      placeholder: "Currency",
      options: [
        { value: "", label: "All Currencies" },
        { value: "AED", label: "AED" },
        { value: "SAR", label: "SAR" },
        { value: "USD", label: "USD" },
        { value: "ZAR", label: "ZAR" },
      ],
    },
  },
];
