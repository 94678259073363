import { FC, useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";

import { onConditionChangeValidation } from "@ni/common/utils";
import { ConfigurationApi } from "@ni/sdk/apis";
import { CreateEventRequest, Event, EventSubgroup, PatchEventRequest } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();

interface EventFormProps {
  event: Event;
  templateId: number;
  eventSubGroupItems: EventSubgroup[];
  eventSubGroupId: number;
  getEventItems: () => void;
}

const EventForm: FC<EventFormProps> = props => {
  const { event, templateId, eventSubGroupItems, eventSubGroupId, getEventItems } = props;
  const [eventForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const onFinish = (values: Event) => {
    setIsLoading(true);
    if (values.id) {
      const eventItem: PatchEventRequest = {
        applyConditions: values.applyConditions,
        code: values.code,
        eventName: values.eventName,
        eventSubgroupId: values.eventSubgroupId,
        isGeneral: values.isGeneral,
      };

      configurationServiceApi
        .patchEvent(eventItem, templateId, eventSubGroupId, values.id)
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    } else {
      const requestBody: CreateEventRequest = {
        applyConditions: values.applyConditions,
        code: values.code || "",
        eventName: values.eventName || "",
        isGeneral: false,
      };
      configurationServiceApi
        .createEvent(requestBody, templateId, eventSubGroupId)
        .then(() => {
          setIsLoading(false);
          getEventItems();
        })
        .catch(() => setIsLoading(false));
    }
  };

  const onValueChange = (): void => {
    const isFieldsFilled =
      eventForm.getFieldValue("eventName")?.length > 0 && eventForm.getFieldValue("code")?.length > 0;
    setIsEnabled(
      isFieldsFilled &&
        ((eventForm.getFieldValue("applyConditions")?.length > 0 &&
          onConditionChangeValidation(String(eventForm.getFieldValue("applyConditions")))) ||
          eventForm.getFieldValue("applyConditions") === undefined ||
          eventForm.getFieldValue("applyConditions")?.length === 0),
    );
  };

  useEffect(() => {
    eventForm.setFieldsValue({
      eventName: event.eventName,
      subgroup: eventSubGroupId,
      code: event.code,
      applyConditions: event.applyConditions,
      id: event.id,
    });
  }, [event, eventForm, eventSubGroupId]);

  return (
    <Form
      id="eventForm"
      className={styles["event-form"]}
      form={eventForm}
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      onValuesChange={onValueChange}
      initialValues={{ id: event?.id || undefined, elementName: event?.eventName || "" }}
    >
      <div className={styles["block"]}>
        <div className={styles["one-line"]}>
          <Form.Item name="id" hidden={true} />
          <Form.Item name="eventName" label="Event name" required={true}>
            <Input />
          </Form.Item>
          <Form.Item name="subgroup" label="Subgroup" required={true}>
            <Select defaultValue={event.eventSubgroupId}>
              {eventSubGroupItems?.map((item: EventSubgroup) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item name="code" label="Code" required={true}>
          <Input />
        </Form.Item>
        <Form.Item name="applyConditions" label="Apply conditions">
          <Input />
        </Form.Item>
      </div>
      <Button
        loading={isLoading}
        disabled={!isEnabled}
        className={styles["event-save-button"]}
        type="primary"
        size="large"
        onClick={() => eventForm.submit()}
      >
        Save
      </Button>
    </Form>
  );
};

export default EventForm;
