import { useMemo } from "react";

import { getFormValueFromLoyaltyValues } from "@ni/common/utils";
import { LoyaltyProgramTemplatePctValue } from "@ni/sdk/models";

interface UseFieldMandatoryProps {
  values: LoyaltyProgramTemplatePctValue[];
  checkAgainst: string;
  keyToInclude: string;
}

export const useFieldMandatory = ({ values, checkAgainst, keyToInclude }: UseFieldMandatoryProps) => {
  return useMemo(() => {
    return (getFormValueFromLoyaltyValues(values, checkAgainst, "string") as string).includes(keyToInclude);
  }, [checkAgainst, keyToInclude, values]);
};
