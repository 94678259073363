import { FilterInputProps } from "@ni/common/ui";
import { LoyaltyProgramTemplateState } from "@ni/sdk/models";

export const loyaltyFilterInputs: FilterInputProps[] = [
  {
    name: "state",
    type: "select",
    props: {
      placeholder: "State",
      options: [
        { value: "", label: "All States" },
        { value: LoyaltyProgramTemplateState.DRAFT, label: "Draft" },
        { value: LoyaltyProgramTemplateState.ACTIVE, label: "Enabled" },
        { value: LoyaltyProgramTemplateState.INACTIVE, label: "Disabled" },
      ],
    },
  },
  {
    name: "loyalty_program_template.type",
    type: "select",
    props: {
      placeholder: "Accrual type",
      options: [
        { value: "", label: "All Accrual Types" },
        { value: "N", label: "Transaction based" },
        { value: "C", label: "Cumulative" },
      ],
    },
  },
  {
    name: "redemption_type",
    type: "select",
    props: {
      placeholder: "Redemption type",
      options: [
        { value: "", label: "All Redemption Types" },
        { value: "C", label: "Cashback" },
        { value: "E", label: "External Voucher" },
      ],
    },
  },
];
