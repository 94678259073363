import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useToggleFeature } from "@ni/common/hooks";
import { FeatureGuard, GetProductWithId, RoutesTreeItem } from "@ni/common/types";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getFormValueFromProductValues, getLastRoute, getRouteAfterProvidedRoute } from "@ni/common/utils";
import { LoyaltyProgram, Product } from "@ni/sdk/models";

import { SidebarViewType } from "./Sidebar.constants";

interface SidebarProps {
  products: Product[];
  currentProduct: GetProductWithId;
  loyaltyPrograms: LoyaltyProgram[];
  getProductPagesRoutes: (guard: FeatureGuard) => RoutesTreeItem[];
  getPctPagesRoutes: (guard: FeatureGuard, isCredit: boolean) => RoutesTreeItem[];
  styles: {
    readonly [key: string]: string;
  };
}

const PAGE_ROUTE_KEY = "pct";
const pctUrlRegex = /^\/tenant\/\d*\/product\/\d*\/pct\/\d*\//;

const prepareChildRoutes = (navItem: RoutesTreeItem[], currentId: string, routeKey: string) => {
  return navItem.map(item => {
    if (item.children) {
      prepareChildRoutes(item.children, currentId, routeKey);
    } else {
      const routeAfterKey = getRouteAfterProvidedRoute(item.route ?? "", routeKey);
      item.route = item.route?.replace(routeAfterKey, currentId);
    }
    return item;
  });
};

export const Sidebar = ({
  products,
  currentProduct,
  loyaltyPrograms,
  styles,
  getPctPagesRoutes,
  getProductPagesRoutes,
}: SidebarProps) => {
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [lastRequestedPath, setLastRequestedPath] = useState<{ viewType: SidebarViewType; path: string }>();
  const [sidebarViewType, setSidebarViewType] = useState(SidebarViewType.None);

  const { checkFeatureDisabled } = useToggleFeature();

  const currentPctId = useMemo(() => getRouteAfterProvidedRoute(pathname, PAGE_ROUTE_KEY), [pathname]);

  const isCredit = useMemo(
    () => (getFormValueFromProductValues(currentProduct?.productValues, "product-type") as string) === "Credit",
    [currentProduct?.productValues],
  );

  const { pctNav, productNav } = useMemo(
    () => ({
      productNav:
        sidebarViewType === SidebarViewType.ProductSettings ? getProductPagesRoutes(checkFeatureDisabled) : [],
      pctNav:
        sidebarViewType === SidebarViewType.Pct
          ? prepareChildRoutes(getPctPagesRoutes(checkFeatureDisabled, isCredit), currentPctId, PAGE_ROUTE_KEY)
          : [],
    }),
    [checkFeatureDisabled, currentPctId, getPctPagesRoutes, getProductPagesRoutes, isCredit, sidebarViewType],
  );

  useEffect(() => {
    const isPctRequested = pctUrlRegex.test(pathname);
    const isProductRequested = !isPctRequested;

    if (isPctRequested && sidebarViewType !== SidebarViewType.Pct) {
      setLastRequestedPath({ path: pathname + (search ?? ""), viewType: SidebarViewType.Pct });
      setSidebarViewType(SidebarViewType.None);
    } else if (isProductRequested && sidebarViewType !== SidebarViewType.ProductSettings) {
      setLastRequestedPath({ path: pathname + (search ?? ""), viewType: SidebarViewType.ProductSettings });
      setSidebarViewType(SidebarViewType.None);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (sidebarViewType === SidebarViewType.None && lastRequestedPath?.viewType) {
      setSidebarViewType(lastRequestedPath.viewType);
      navigate(lastRequestedPath.path);
    }
  }, [lastRequestedPath, navigate, sidebarViewType]);

  const onChangeCurrentProduct = useCallback(
    (productId: number) => {
      if (/\/pct\/$/.test(pathname)) {
        navigate(`/tenant/${tenantId}/product/${productId}/pct/`);
      } else {
        navigate(`/tenant/${tenantId}/product/${productId}/${getLastRoute(pathname)}`);
      }
    },
    [navigate, pathname, tenantId],
  );

  const onChangeCurrentPct = useCallback(
    (pctId: number) => {
      navigate(`/tenant/${tenantId}/product/${productId}/pct/${pctId}/${getLastRoute(pathname)}`);
    },
    [navigate, pathname, productId, tenantId],
  );

  return {
    [SidebarViewType.ProductSettings]: currentProduct.id && products && (
      <RoutesHandler
        key={SidebarViewType.ProductSettings}
        routesList={productNav}
        deps={[currentProduct.id, products, onChangeCurrentProduct]}
        ParentComponent={({ navItems, children }) => (
          <div className={styles["ni-product-settings"]}>
            <Sidenav items={navItems} disabledItemsViewType="hidden">
              <SidenavEntitySelect
                value={currentProduct.id}
                entities={products ?? []}
                onChange={id => onChangeCurrentProduct(id)}
              />
            </Sidenav>
            <div className={styles["ni-product-settings-content"]}>{children}</div>
          </div>
        )}
      />
    ),
    [SidebarViewType.Pct]: currentPctId && currentProduct?.id && (
      <RoutesHandler
        key={SidebarViewType.Pct}
        routePrefix="/"
        routesList={pctNav}
        alternativeRouteList={getPctPagesRoutes(checkFeatureDisabled, isCredit)}
        deps={[currentPctId, currentProduct.id ?? 0, loyaltyPrograms ?? [], onChangeCurrentPct]}
        ParentComponent={({ navItems, children }) => (
          <div className={styles["ni-product-settings"]}>
            <Sidenav items={navItems} disabledItemsViewType="hidden">
              <SidenavEntitySelect
                value={+currentPctId}
                entities={currentProduct?.parameterTables ?? []}
                onChange={onChangeCurrentPct}
              />
            </Sidenav>
            <div className={styles["ni-product-settings-content"]}>{children}</div>
          </div>
        )}
      />
    ),
    [SidebarViewType.None]: null,
  }[sidebarViewType];
};
