import { FC } from "react";
import { Typography } from "antd";

import { apiPage } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import {
  AfterWizardEditPageTitle,
  DocumentationList,
  FileCardListWrapper,
  PageItemLayoutElements,
  PageItemLayoutGeneral,
  PageItemLayoutGroup,
} from "@ni/common/ui";
import { Tenant } from "@ni/sdk/models";

export const APIPage: FC = () => {
  const [tenant] = useReduxState<Tenant>("tenant", {});
  const cardLists = apiPage;

  return (
    <FileCardListWrapper>
      <PageItemLayoutGeneral>
        <PageItemLayoutGroup>
          <PageItemLayoutElements>
            <AfterWizardEditPageTitle title="API (Application Program Interface)" />
            <Typography.Text>
              API is a protocol used by a software application to access encapsulated functionalities of another
              software application in a secured manner.
            </Typography.Text>
            <Typography.Text>
              Visit our{" "}
              <a href="https://developer.network.ae/" target="_blank" rel="noreferrer">
                Developer portal
              </a>{" "}
              to get more information and to try out API sandboxes.
            </Typography.Text>
          </PageItemLayoutElements>
        </PageItemLayoutGroup>
        <DocumentationList documentationList={cardLists} data={tenant} />
      </PageItemLayoutGeneral>
    </FileCardListWrapper>
  );
};
