/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InitWizardRequest } from '../model';
// @ts-ignore
import { InitWizardResponse } from '../model';
// @ts-ignore
import { InstallmentPaymentPlanWizardRequest } from '../model';
// @ts-ignore
import { InstallmentPaymentPlanWizardResponse } from '../model';
/**
 * IPPWizardApi - axios parameter creator
 * @export
 */
export const IPPWizardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Initializes IPP creation Wizard for given TenantId and returns Workflow and Workflow page.
         * @summary Initialize IPP creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWizard1: async (initWizardRequest: InitWizardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initWizardRequest' is not null or undefined
            assertParamExists('initWizard1', 'initWizardRequest', initWizardRequest)
            const localVarPath = `/api/v1/wizard/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initWizardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores collected data for given TenantId and PlanId and returns Plan, Tenant and Workflow next pageId
         * @summary Store collected data for given TenantId and PlanId
         * @param {InstallmentPaymentPlanWizardRequest} installmentPaymentPlanWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processIPPWizardRequest: async (installmentPaymentPlanWizardRequest: InstallmentPaymentPlanWizardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installmentPaymentPlanWizardRequest' is not null or undefined
            assertParamExists('processIPPWizardRequest', 'installmentPaymentPlanWizardRequest', installmentPaymentPlanWizardRequest)
            const localVarPath = `/api/v1/wizard/plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(installmentPaymentPlanWizardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IPPWizardApi - functional programming interface
 * @export
 */
export const IPPWizardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IPPWizardApiAxiosParamCreator(configuration)
    return {
        /**
         * Initializes IPP creation Wizard for given TenantId and returns Workflow and Workflow page.
         * @summary Initialize IPP creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initWizard1(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitWizardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initWizard1(initWizardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stores collected data for given TenantId and PlanId and returns Plan, Tenant and Workflow next pageId
         * @summary Store collected data for given TenantId and PlanId
         * @param {InstallmentPaymentPlanWizardRequest} installmentPaymentPlanWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processIPPWizardRequest(installmentPaymentPlanWizardRequest: InstallmentPaymentPlanWizardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstallmentPaymentPlanWizardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processIPPWizardRequest(installmentPaymentPlanWizardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IPPWizardApi - factory interface
 * @export
 */
export const IPPWizardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IPPWizardApiFp(configuration)
    return {
        /**
         * Initializes IPP creation Wizard for given TenantId and returns Workflow and Workflow page.
         * @summary Initialize IPP creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWizard1(initWizardRequest: InitWizardRequest, options?: any): AxiosPromise<InitWizardResponse> {
            return localVarFp.initWizard1(initWizardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores collected data for given TenantId and PlanId and returns Plan, Tenant and Workflow next pageId
         * @summary Store collected data for given TenantId and PlanId
         * @param {InstallmentPaymentPlanWizardRequest} installmentPaymentPlanWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processIPPWizardRequest(installmentPaymentPlanWizardRequest: InstallmentPaymentPlanWizardRequest, options?: any): AxiosPromise<InstallmentPaymentPlanWizardResponse> {
            return localVarFp.processIPPWizardRequest(installmentPaymentPlanWizardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IPPWizardApi - interface
 * @export
 * @interface IPPWizardApi
 */
export interface IPPWizardApiInterface {
    /**
     * Initializes IPP creation Wizard for given TenantId and returns Workflow and Workflow page.
     * @summary Initialize IPP creation Wizard
     * @param {InitWizardRequest} initWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IPPWizardApiInterface
     */
    initWizard1(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig): AxiosPromise<InitWizardResponse>;

    /**
     * Stores collected data for given TenantId and PlanId and returns Plan, Tenant and Workflow next pageId
     * @summary Store collected data for given TenantId and PlanId
     * @param {InstallmentPaymentPlanWizardRequest} installmentPaymentPlanWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IPPWizardApiInterface
     */
    processIPPWizardRequest(installmentPaymentPlanWizardRequest: InstallmentPaymentPlanWizardRequest, options?: AxiosRequestConfig): AxiosPromise<InstallmentPaymentPlanWizardResponse>;

}

/**
 * IPPWizardApi - object-oriented interface
 * @export
 * @class IPPWizardApi
 * @extends {BaseAPI}
 */
export class IPPWizardApi extends BaseAPI implements IPPWizardApiInterface {
    /**
     * Initializes IPP creation Wizard for given TenantId and returns Workflow and Workflow page.
     * @summary Initialize IPP creation Wizard
     * @param {InitWizardRequest} initWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IPPWizardApi
     */
    public initWizard1(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig) {
        return IPPWizardApiFp(this.configuration).initWizard1(initWizardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores collected data for given TenantId and PlanId and returns Plan, Tenant and Workflow next pageId
     * @summary Store collected data for given TenantId and PlanId
     * @param {InstallmentPaymentPlanWizardRequest} installmentPaymentPlanWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IPPWizardApi
     */
    public processIPPWizardRequest(installmentPaymentPlanWizardRequest: InstallmentPaymentPlanWizardRequest, options?: AxiosRequestConfig) {
        return IPPWizardApiFp(this.configuration).processIPPWizardRequest(installmentPaymentPlanWizardRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
