import { useEffect } from "react";

import { PRODUCT_CURRENCY } from "@ni/common/constants";
import { Dictionary } from "@ni/sdk/models";

import { useConfigurationApi } from "../api";
import { useReduxState } from "../store";

export const useGetCurrencyList = (currencyCode?: string) => {
  const [currencyList, setCurrencyList] = useReduxState<Dictionary[]>("currencyListState", []);

  const { getDictionariesByCode } = useConfigurationApi();

  useEffect(() => {
    if (currencyList.length === 0)
      void getDictionariesByCode(currencyCode ?? PRODUCT_CURRENCY).then(response =>
        setCurrencyList(response?.dictionaries || []),
      );
  }, [currencyCode, currencyList.length, getDictionariesByCode, setCurrencyList]);

  return { currencyList };
};
