import React, { FC } from "react";
import cn from "classnames";

import { WarningOutlined } from "@ant-design/icons";
import { fileTypeCSV, fileTypeHTML, fileTypeJSON, fileTypeTXT, fileTypeXML } from "@ni/common/assets";
import { CardDisabled, FileCardDurationEnum, FileCardSourceEnum, FileTypeEnum } from "@ni/common/types";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface FileCardProps {
  fileType: FileTypeEnum;
  title: string;
  duration: FileCardDurationEnum;
  source: FileCardSourceEnum;
  cardDisabled?: CardDisabled;
  children?: JSX.Element;
}

export const FileCard: FC<FileCardProps> = props => {
  const {
    fileType,
    title,
    children,
    duration,
    source,
    cardDisabled = {
      disabled: false,
      tooltip: "",
    },
  } = props;

  const fileTypePath = (type: FileTypeEnum) => {
    switch (type) {
      case FileTypeEnum.CSV:
        return fileTypeCSV;
      case FileTypeEnum.HTML:
        return fileTypeHTML;
      case FileTypeEnum.JSON:
        return fileTypeJSON;
      case FileTypeEnum.XML:
        return fileTypeXML;
      case FileTypeEnum.TXT:
        return fileTypeTXT;
      default:
        return fileTypeTXT;
    }
  };

  return (
    <div className={styles["file-card-container"]}>
      <img
        className={cn(cardDisabled?.disabled && styles["card-disabled"])}
        alt="Not Found"
        src={fileTypePath(fileType)}
      />
      <div className={cn(styles["content"], cardDisabled?.disabled && styles["card-disabled"])}>
        <div>
          <div className={styles["title"]}>{title}</div>
          <div className={styles["description"]}>{children}</div>
        </div>
        <div>
          <div className={styles["details"]}>
            <div className="duration">
              <strong>Duration: </strong>
              <span>{duration}</span>
            </div>
            <div className="source">
              <strong>Source: </strong>
              <span>{source}</span>
            </div>
            <div className="format">
              <strong>Format: </strong>
              <span>{fileType}</span>
            </div>
          </div>
        </div>
      </div>
      {cardDisabled?.disabled ? (
        <TooltipInfo tooltipProps={{ className: styles["warning-sign"], title: cardDisabled.tooltip }}>
          <WarningOutlined />
        </TooltipInfo>
      ) : null}
    </div>
  );
};
