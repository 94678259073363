import { FC, ReactNode, useEffect } from "react";
import { Form } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, SwitchedInputsBox } from "@ni/common/ui";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { useIPPWizardApi } from "../../hooks";

import { enableIncentiveFeeOption, feeFixedOption, feePercentageOption, maxFeeOption, minFeeOption } from "./constants";

export const IncentiveFeePage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { processWizardRequest } = useIPPWizardApi();
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  const isEnabled = Form.useWatch<boolean>(enableIncentiveFeeOption.code, form);
  const feeFixed = !!Form.useWatch<string>(feeFixedOption.code, form)?.toString();
  const feePercentage = !!Form.useWatch<string>(feePercentageOption.code, form)?.toString();

  const minFee = +(Form.useWatch<string>(minFeeOption.code, form) as unknown as number);
  const maxFee = +(Form.useWatch<string>(maxFeeOption.code, form) as unknown as number);

  useHydrateForm({
    form,
    entityFields: wizardResponse?.plan?.planValues ?? [],
    keys: {
      strings: [
        enableIncentiveFeeOption.code,
        feeFixedOption.code,
        feePercentageOption.code,
        maxFeeOption.code,
        minFeeOption.code,
      ],
    },
    allowParse: true,
  });

  useEffect(() => {
    if (!formDisabled && !feePercentage) {
      form.resetFields([minFeeOption.code, maxFeeOption.code]);
    }
  }, [form, formDisabled, feePercentage]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="IPP Incentive Fee"
      pageSubtitle="These parameters specify the fee to calculate in case of IPP booking for the merchant billing. The fee is not charged to the cardholder, instead the calculated fee will be reported as part of customized reports and the tenant can reconcile and charge the merchant accordingly."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      buttonDisabled={isEnabled && !feeFixed && !feePercentage}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <SwitchedInputsBox
        form={form}
        formDisabled={formDisabled}
        switchOptions={enableIncentiveFeeOption}
        inputsOptionsList={[
          [
            { ...feeFixedOption, addonAfter: wizardResponse?.plan?.currency as ReactNode },
            feePercentageOption,
            {
              ...minFeeOption,
              isDisabled: !feePercentage,
              max: (maxFee ?? Infinity) - 0.01,
              addonAfter: wizardResponse?.plan?.currency as ReactNode,
            },
            {
              ...maxFeeOption,
              isDisabled: !feePercentage,
              min: (minFee ?? 0) + 0.01,
              addonAfter: wizardResponse?.plan?.currency as ReactNode,
            },
          ],
        ]}
      />
    </CustomFormWrapper>
  );
};
