import { ReactNode } from "react";
import { Space } from "antd";

import { SelectOption } from "@ni/common/types";
import { NetworkForm } from "@ni/common/ui";

export type KeyTab = "setting" | "waiving";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}
// tab 1
const fixedOption: NumericOptions = {
  code: "ins-prem-fixed",
  name: "Insurance fixed premium amount",
  decimalsNumber: 2,
  min: 0,
  tooltip: "You can customize this parameter later on applied product level  or for each  PCT profile settings",
};
const percentageOption: NumericOptions = {
  code: "ins-prem-rate",
  name: "Insurance premium percentage",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  default: 0.89,
  tooltip: (
    <Space direction="vertical">
      <div>
        You can customize this parameter later on applied product level or for each pricing control table profile
        settings
      </div>
      <div>
        This percentage will be charged on customer outstanding liability in addition to the insurance fixed premium
        amount.
      </div>
    </Space>
  ),
};
const capOption: NumericOptions = {
  code: "ins-prem-min",
  name: "Minimum insurance premium cap",
  decimalsNumber: 2,
  min: 0,
  tooltip: (
    <Space direction="vertical">
      <div>
        You can customize this parameter later on applied product level or for each pricing control table profile
        settings
      </div>
      <div>
        This specifies the minimum amount that will be charged to customer, if the Total Insurance Premium (output of
        the Insurance fixed amount + Insurance premium percentage formula amount) is less
      </div>
    </Space>
  ),
};
const periodOption: NumericOptions = {
  code: "ins-prem-period",
  name: "Specify period before charging the first insurance premium",
  decimalsNumber: 0,
  min: 0,
  tooltip:
    "This parameter specifies the number of months or billing cycles to auto reverse the insurance premium. this depends on selected period type",
};
const periodTypeOption: RadioOptions = {
  code: "ins-prem-period-type",
  name: "Period type",
  default: "M",
  options: [
    {
      label: "Calendar Months",
      value: "M",
    },
    {
      label: "Full Billing cycles",
      value: "B",
      tooltip: "Cycle of boarding is not counted",
    },
  ],
};
export const tab1 = {
  fixedOption,
  percentageOption,
  capOption,
  periodOption,
  periodTypeOption,
  page: ({ currency }: { currency: string }) => (
    <Space direction="vertical" size="large">
      <NetworkForm.Number
        formItemOptions={{
          name: fixedOption.code,
          label: fixedOption.name,
          tooltip: fixedOption.tooltip,
        }}
        addonAfter={currency}
        min={fixedOption.min}
        max={fixedOption.max}
        precision={fixedOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: percentageOption.code,
          label: percentageOption.name,
          tooltip: percentageOption.tooltip,
          initialValue: percentageOption.default,
        }}
        addonAfter="%"
        min={percentageOption.min}
        max={percentageOption.max}
        precision={percentageOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: capOption.code,
          label: capOption.name,
          tooltip: capOption.tooltip,
        }}
        addonAfter={currency}
        min={capOption.min}
        max={capOption.max}
        precision={capOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: periodOption.code,
          label: periodOption.name,
          tooltip: periodOption.tooltip,
        }}
        min={periodOption.min}
        max={periodOption.max}
        precision={periodOption.decimalsNumber}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: periodTypeOption.code,
          label: periodTypeOption.name,
          initialValue: periodTypeOption.default,
        }}
        radioList={periodTypeOption.options}
        initialValue={periodTypeOption.default as string}
      />
    </Space>
  ),
} as const;

// tab 2
const coverageOption: Options = {
  code: "ins-prem-waive-zero",
  name: "Waive premium on zero coverage amount",
  default: true,
  tooltip:
    "Premium will be waived if customer has paid the full outstanding balance that is used for premium computation",
};
const transactorOption: Options = {
  code: "ins-prem-waive",
  name: "Waive premium for transactor customer",
  default: false,
  tooltip: "Premium will be waived if customer has paid the previous cycle statement balance",
};
export const tab2 = {
  coverageOption,
  transactorOption,
  page: () => (
    <Space direction="vertical" size={16}>
      <Space direction="horizontal">
        <NetworkForm.Switch
          formItemOptions={{
            name: coverageOption.code,
            label: coverageOption.name,
            tooltip: coverageOption.tooltip,
            valuePropName: "checked",
            initialValue: coverageOption.default,
          }}
        />
      </Space>

      <Space direction="horizontal">
        <NetworkForm.Switch
          formItemOptions={{
            name: transactorOption.code,
            label: transactorOption.name,
            tooltip: transactorOption.tooltip,
            valuePropName: "checked",
            initialValue: transactorOption.default,
          }}
        />
      </Space>
    </Space>
  ),
} as const;
