import { memo, useState } from "react";
import { Collapse, Switch, Typography } from "antd";
import cn from "classnames";
import type { FC } from "react";
import { useDrag, useDrop } from "react-dnd";

import { CopyOutlined, DeleteOutlined, EditOutlined, HolderOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { ConfirmModal } from "@ni/common/ui";
import { PageApi } from "@ni/sdk/apis";
import { Page, State } from "@ni/sdk/models";

import { ItemTypes } from "../../constants";

import styles from "./styles.module.scss";

const pageServiceApi = new PageApi();
const { Panel } = Collapse;
const { Text } = Typography;

interface CardProps {
  page: Page;
  id: string;
  movePageCard: (id: string, to: number) => void;
  findPageCard: (id: string) => { index: number };
  workflowId: number;
  phaseId: number;
  pageItems: Page[] | [];
  onEditHandleClick: (value: Page) => void;
  index: number;
  onUpdatePagesOrder: (pageItems: Page[]) => void;
}

interface PageCopyItems {
  newCode: string;
  newHeader: string;
}

interface Item {
  id: string;
  originalIndex: number;
}

const CardFunc: FC<CardProps> = props => {
  const {
    page,
    id,
    movePageCard,
    findPageCard,
    workflowId,
    phaseId,
    pageItems,
    index,
    onEditHandleClick,
    onUpdatePagesOrder,
  } = props;

  const originalIndex = findPageCard(id).index;

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [, setIsLoading] = useReduxState<boolean>("isPhaseLoading");
  const [, setPageItems] = useReduxState<Page[]>(`${phaseId}-pageItems`, []);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.PAGE,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          movePageCard(droppedId, index);
        }
        onUpdatePagesOrder(pageItems);
      },
    }),
    [id, originalIndex, movePageCard],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.PAGE,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findPageCard(id);
          movePageCard(draggedId, overIndex);
        }
      },
    }),
    [findPageCard, movePageCard],
  );

  const onCancelDuplicateModal = () => {
    setIsDuplicateModalOpen(false);
  };

  const onCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onPageSwitch = () => {
    setIsLoading(true);

    pageServiceApi
      .editPage(
        {
          state: page.state === State.ENABLED ? State.DISABLED : State.ENABLED,
        },
        workflowId,
        phaseId,
        page.id!,
      )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onPageDelete = () => {
    pageServiceApi
      .editPage(
        {
          code: page.code,
          description: page.description,
          header: page.header,
          hiddenConditions: page.hiddenConditions,
          newPhaseId: phaseId,
          order: page.order,
          state: State.DELETED,
        },
        workflowId,
        phaseId,
        page.id!,
      )

      .then(() => {
        onCancelDeleteModal();
        const newPageItemsArray = pageItems.filter(item => item.id !== page.id);
        setPageItems(newPageItemsArray);
      })
      .catch(() => {});
  };

  const onPageCopy = (values: PageCopyItems) => {
    setIsLoading(true);

    pageServiceApi
      .copyPage(
        {
          newCode: values.newCode,
          newHeader: values.newHeader,
        },
        workflowId,
        phaseId,
        page.id!,
      )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const extraHeader = () => (
    <div className={styles["page-collapse-header"]}>
      <HolderOutlined style={{ fontSize: "150%" }} />
      <div className={styles["order"]}>{index + 1 ?? "-"}</div>
      <div className={styles["title"]}>
        <Text className={styles["name"]} ellipsis={{ tooltip: page?.header ?? "Page" }}>
          {page?.header ?? "Page"}
        </Text>
      </div>
    </div>
  );

  const extraPanel = () => (
    <div className={styles["page-collapse-header-extra"]}>
      <Switch
        defaultChecked={page?.state === State.ENABLED}
        checked={page?.state === State.ENABLED}
        onClick={() => onPageSwitch()}
      />
      <EditOutlined className={styles["button"]} onClick={() => onEditHandleClick(page)} />
      <CopyOutlined className={styles["button"]} onClick={() => setIsDuplicateModalOpen(true)} />
      <DeleteOutlined className={styles["button"]} onClick={() => setIsDeleteModalOpen(true)} />
    </div>
  );

  return (
    <>
      <div
        className={cn(styles["page"], id !== "undefined" ? isDragging && styles["opacity"] : styles["notDND"])}
        ref={node => {
          if (id !== "undefined") {
            drag(drop(node));
          }
        }}
      >
        <Panel header={extraHeader()} key={Number(id) + Math.random()} showArrow={false} extra={extraPanel()} />
      </div>
      <ConfirmModal
        title="Copy of page will be created"
        isOpened={isDuplicateModalOpen}
        onCancel={onCancelDuplicateModal}
        onConfirm={onPageCopy}
        isLoading={false}
        type="duplicate-page"
        description="Provide name and code for the copy of page"
      />
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the page and all its elements. Are you sure?"
        isOpened={isDeleteModalOpen}
        onCancel={onCancelDeleteModal}
        onConfirm={onPageDelete}
        isLoading={false}
        type="delete"
      />
    </>
  );
};

const PageCard: FC<CardProps> = memo(CardFunc);
export default PageCard;
