import { InputNumberProps, SwitchProps } from "antd";

import { FormField } from "@ni/common/types";

export const murabahaProfitRate: FormField<Omit<InputNumberProps, "type">> = {
  code: "murab-profit-rate",
  label: "Murabaha profit rate",
  tooltip:
    "This rate will be used to calculate monthly finance payment due and special deposit account profit and bonus. When Murabaha is in effect both cash and retail profit rates are identical.",
  props: {
    min: 0,
    precision: 2,
    controls: false,
    addonAfter: "%",
  },
};

export const murabahaFeeRate: FormField<SwitchProps> = {
  code: "nic-int-rate-zero-all-fees",
  label: "Zero profit rate on all fees",
};

export const murabahaInterestRate: FormField<SwitchProps> = {
  code: "nic-int-rate-zero-all-int",
  label: "Zero profit rate on all interests",
};
