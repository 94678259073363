import { FC, useEffect, useMemo } from "react";
import { Col, Form, Row, Switch } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, FormItemLabel, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues, getSwitchesCount } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import { checkHeader, headerSwitchHandler } from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const switchHeader22: { [key: string]: string[] } = {
  "paym-c-total-enabled": ["d-paym-c-total-enabled", "m-paym-c-total-enabled", "d365-paym-c-total-enabled"],
};

export const TopupCardLimitsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      "d-paym-c-total-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            "d-paym-c-total-enabled",
            "boolean",
          )
        : true,
      "nic-d-paym-c-total-am": formDisabledFn("nic-d-paym-c-total-am", "500000"),
      "nic-d-paym-c-total-nr": formDisabledFn("nic-d-paym-c-total-nr", "20"),
      "nic-d-paym-c-total-single": formDisabledFn("nic-d-paym-c-total-single", "500000"),

      "m-paym-c-total-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            "m-paym-c-total-enabled",
            "boolean",
          )
        : true,
      "nic-m-paym-c-total-am": formDisabledFn("nic-m-paym-c-total-am", "500000"),
      "nic-m-paym-c-total-nr": formDisabledFn("nic-m-paym-c-total-nr", "20"),
      "nic-m-paym-c-total-single": formDisabledFn("nic-m-paym-c-total-single", "500000"),

      "d365-paym-c-total-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables![0].pctProductValues as ProductValue[],
            "d365-paym-c-total-enabled",
            "boolean",
          )
        : true,
      "nic-d365-paym-c-total-am": formDisabledFn("nic-d365-paym-c-total-am", "500000"),
      "nic-d365-paym-c-total-nr": formDisabledFn("nic-d365-paym-c-total-nr", "20"),
      "nic-d365-paym-c-total-single": formDisabledFn("nic-d365-paym-c-total-single", "500000"),
    });

    checkHeader("paym-c-total-enabled", switchHeader22, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  const isShowCardLimits = useMemo(() => {
    return (
      (wizardResponse.product?.parameterTables &&
        wizardResponse.product?.parameterTables[0]?.pctProductValues?.find(
          productValue => productValue.fieldCode === "paym-lim-contr-level",
        )?.value === "C") ||
      (wizardResponse.product?.parameterTables &&
        wizardResponse.product?.parameterTables[0]?.pctProductValues?.find(
          productValue => productValue.fieldCode === "paym-lim-contr-level",
        )?.value === "B")
    );
  }, [wizardResponse.product?.parameterTables]);

  const isShowPerDay = useMemo(() => {
    return (
      isShowCardLimits &&
      wizardResponse.product?.parameterTables &&
      wizardResponse.product?.parameterTables[0]?.pctProductValues
        ?.find(productValue => productValue.fieldCode === "paym-lim-periods")
        ?.value?.includes("D")
    );
  }, [isShowCardLimits, wizardResponse.product?.parameterTables]);

  const isShowPerMonth = useMemo(() => {
    return (
      isShowCardLimits &&
      wizardResponse.product?.parameterTables &&
      wizardResponse.product?.parameterTables[0]?.pctProductValues
        ?.find(productValue => productValue.fieldCode === "paym-lim-periods")
        ?.value?.includes("M")
    );
  }, [isShowCardLimits, wizardResponse.product?.parameterTables]);

  const isShowPer365Days = useMemo(() => {
    return (
      isShowCardLimits &&
      wizardResponse.product?.parameterTables &&
      wizardResponse.product?.parameterTables[0]?.pctProductValues
        ?.find(productValue => productValue.fieldCode === "paym-lim-periods")
        ?.value?.includes("Y")
    );
  }, [isShowCardLimits, wizardResponse.product?.parameterTables]);

  const switchShownCard = {
    perDay: isShowPerDay,
    perMonth: isShowPerMonth,
    per365: isShowPer365Days,
  };

  const cardSwitchesNotOne = getSwitchesCount(switchShownCard) !== 1;

  const onFinish = () => {
    setIsLoading(true);

    const isPerDayAvailable =
      String(form.getFieldValue("paym-c-total-enabled")) === "true" &&
      String(form.getFieldValue("d-paym-c-total-enabled")) === "true";

    const isPerMonthAvailable =
      String(form.getFieldValue("paym-c-total-enabled")) === "true" &&
      String(form.getFieldValue("m-paym-c-total-enabled")) === "true";

    const isPer365hAvailable =
      String(form.getFieldValue("paym-c-total-enabled")) === "true" &&
      String(form.getFieldValue("d365-paym-c-total-enabled")) === "true";

    const perDayFieldValues = isShowPerDay
      ? {
          "d-paym-c-total-enabled": String(form.getFieldValue("d-paym-c-total-enabled")),
          "nic-d-paym-c-total-nr": isPerDayAvailable
            ? String(form.getFieldValue("nic-d-paym-c-total-nr")).replace(/ /g, "")
            : null,
          "nic-d-paym-c-total-am": isPerDayAvailable
            ? String(form.getFieldValue("nic-d-paym-c-total-am")).replace(/ /g, "")
            : null,
          "nic-d-paym-c-total-single": isPerDayAvailable
            ? String(form.getFieldValue("nic-d-paym-c-total-single")).replace(/ /g, "")
            : null,
        }
      : {};
    const perMonthFieldValues = isShowPerMonth
      ? {
          "m-paym-c-total-enabled": String(form.getFieldValue("m-paym-c-total-enabled")),
          "nic-m-paym-c-total-nr": isPerMonthAvailable
            ? String(form.getFieldValue("nic-m-paym-c-total-nr")).replace(/ /g, "")
            : null,
          "nic-m-paym-c-total-am": isPerMonthAvailable
            ? String(form.getFieldValue("nic-m-paym-c-total-am")).replace(/ /g, "")
            : null,
          "nic-m-paym-c-total-single": isPerMonthAvailable
            ? String(form.getFieldValue("nic-m-paym-c-total-single")).replace(/ /g, "")
            : null,
        }
      : {};
    const per365DaysFieldValues = isShowPer365Days
      ? {
          "d365-paym-c-total-enabled": String(form.getFieldValue("d365-paym-c-total-enabled")),
          "nic-d365-paym-c-total-nr": isPer365hAvailable
            ? String(form.getFieldValue("nic-d365-paym-c-total-nr")).replace(/ /g, "")
            : null,
          "nic-d365-paym-c-total-am": isPer365hAvailable
            ? String(form.getFieldValue("nic-d365-paym-c-total-am")).replace(/ /g, "")
            : null,
          "nic-d365-paym-c-total-single": isPer365hAvailable
            ? String(form.getFieldValue("nic-d365-paym-c-total-single")).replace(/ /g, "")
            : null,
        }
      : {};

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues:
        String(form.getFieldValue("paym-c-total-enabled")) === "true"
          ? ({
              "paym-c-total-enabled": String(form.getFieldValue("paym-c-total-enabled")),

              ...perDayFieldValues,
              ...perMonthFieldValues,
              ...per365DaysFieldValues,
            } as { [key: string]: string })
          : ({
              "paym-c-total-enabled": String(form.getFieldValue("paym-c-total-enabled")),
            } as { [key: string]: string }),
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Card Limits - Top-up"
      pageSubtitle="Card limits control payments to the particular card."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <div className="limits-block">
        <div className="limits-block-header">
          <Form.Item valuePropName="checked" name="paym-c-total-enabled" className="header-switch">
            <Switch onClick={value => headerSwitchHandler("paym-c-total-enabled", switchHeader22, value, form)} />
          </Form.Item>
          <div className="col-switch-desc">
            <TooltipInfo label="Card Limits" code="paym-c-total-enabled" tooltipProps={{}} />
          </div>
        </div>
        <div className="limits-block-body">
          <Row>
            <Col span={6} />
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max Number"
                tooltipProps={{
                  title:
                    "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max Amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
                }}
              />
            </Col>
            <Col span={6} className="col-header">
              <TooltipInfo
                label="Max Single Amount"
                tooltipProps={{
                  title:
                    "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
                }}
              />
            </Col>
          </Row>
          {isShowPerDay && (
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="d-paym-c-total-enabled" className="col-switch">
                  {cardSwitchesNotOne && (
                    <Switch onClick={() => checkHeader("paym-c-total-enabled", switchHeader22, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Per Day"
                    code="d-paym-c-total-enabled"
                    tooltipProps={{
                      title:
                        "Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59).",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["d-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-d-paym-c-total-nr">
                        <Form.Item name="nic-d-paym-c-total-nr">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            disabled={!form.getFieldValue("d-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["d-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-d-paym-c-total-am">
                        <Form.Item name="nic-d-paym-c-total-am">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("d-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["d-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-d-paym-c-total-single">
                        <Form.Item name="nic-d-paym-c-total-single">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("d-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          {isShowPerMonth && (
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="m-paym-c-total-enabled" className="col-switch">
                  {cardSwitchesNotOne && (
                    <Switch onClick={() => checkHeader("paym-c-total-enabled", switchHeader22, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Per month"
                    code="m-paym-c-total-enabled"
                    tooltipProps={{
                      title:
                        "Switch it off if restrictions are not intended per calendar month (from the 1st till the last day of the month)",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["m-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-m-paym-c-total-nr">
                        <Form.Item name="nic-m-paym-c-total-nr">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            disabled={!form.getFieldValue("m-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["m-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-m-paym-c-total-am">
                        <Form.Item name="nic-m-paym-c-total-am">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("m-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["m-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-m-paym-c-total-single">
                        <Form.Item name="nic-m-paym-c-total-single">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("m-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          {isShowPer365Days && (
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name="d365-paym-c-total-enabled" className="col-switch">
                  {cardSwitchesNotOne && (
                    <Switch onClick={() => checkHeader("paym-c-total-enabled", switchHeader22, form)} />
                  )}
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Per 365 days"
                    code="d365-paym-c-total-enabled"
                    tooltipProps={{
                      title:
                        "365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["d365-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-d365-paym-c-total-nr">
                        <Form.Item name="nic-d365-paym-c-total-nr">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            disabled={!form.getFieldValue("d365-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["d365-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-d365-paym-c-total-am">
                        <Form.Item name="nic-d365-paym-c-total-am">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("d365-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={["d365-paym-c-total-enabled"]}>
                  {() => {
                    return (
                      <FormItemLabel code="nic-d365-paym-c-total-single">
                        <Form.Item name="nic-d365-paym-c-total-single">
                          <NetworkForm.Number
                            stringMode={true}
                            precision={0}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue("d365-paym-c-total-enabled") || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </CustomFormWrapper>
  );
};
