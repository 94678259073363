import { FC, useEffect, useState } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues, GetProductWithId } from "@ni/common/types";
import { CustomFormWrapper } from "@ni/common/ui";
import { getErrorInstance } from "@ni/common/utils";

import {
  P2P_PAYMENT_ENABLED_OPTIONS_INCLUDED_KEY,
  PRIMARY_SUPPLEMENTARY_OPTIONS_INCLUDED_KEY,
  Rule,
  RuleKeys,
  RulesList,
} from "../../../../components";
import { NIC_USAGE_PAYM_U01_RULES, NIC_USAGE_PAYM_U02_RULES, NIC_USAGE_PAYM_U03_RULES } from "../../../../constants";
import { usePct } from "../../../../hooks";
import { prepareActualPctValues } from "../../../../utils";

type PctRule = {
  fieldCode: RuleKeys;
  value: string;
};

const fullRulesList = [NIC_USAGE_PAYM_U01_RULES, NIC_USAGE_PAYM_U02_RULES, NIC_USAGE_PAYM_U03_RULES] as const;

const prepareRulesToSubmit = (rules: Rule<RuleKeys>[]): FormValues => {
  let preparedRules = {};

  rules.forEach(rule => {
    if (rule.p2p.isEnabled || rule.primarySupplementary.isEnabled) {
      preparedRules = {
        ...preparedRules,
        [rule.key]: `${rule.p2p.prefix}${rule.p2p.value}${rule.primarySupplementary.value}`,
      };
    }
  });

  return preparedRules;
};

const prepareRulesToRender = (pctsRules: PctRule[]): Rule<RuleKeys>[] => {
  return pctsRules.map(rule => {
    let p2p = {
      isEnabled: false,
      prefix: "",
      value: "",
    };

    let primarySupplementary = {
      isEnabled: false,
      value: "",
    };

    const isRuleIncludeP2pConfig = rule.value?.includes(P2P_PAYMENT_ENABLED_OPTIONS_INCLUDED_KEY);
    const isRuleIncludePrimarySupplementaryConfig = rule.value?.includes(PRIMARY_SUPPLEMENTARY_OPTIONS_INCLUDED_KEY);

    if (isRuleIncludeP2pConfig) {
      const p2pPrefixRegex = /^([^=]+)=/;
      const p2pPrefix = rule.value.match(p2pPrefixRegex)?.[1];

      const p2pBodyRegex = /[^=]+=(.*?);/;
      const p2pBody = rule.value.match(p2pBodyRegex)?.[1];

      p2p = {
        isEnabled: true,
        prefix: p2pPrefix ? `${p2pPrefix}=` : "",
        value: p2pBody ? `${p2pBody};` : "",
      };

      if (isRuleIncludePrimarySupplementaryConfig) {
        const primarySupplementaryBodyRegex = /;(.*)$/;
        const primarySupplementaryBody = rule.value.match(primarySupplementaryBodyRegex)?.[1];

        primarySupplementary = {
          isEnabled: true,
          value: primarySupplementaryBody ?? "",
        };
      }
    } else if (rule.value) {
      primarySupplementary = {
        isEnabled: true,
        value: rule.value,
      };
    }

    return {
      key: rule.fieldCode,
      value: rule.value,
      p2p,
      primarySupplementary,
    };
  });
};

const extractSuffix = (str: string): string | null => {
  const pattern = /-u0(\d+)/;
  const match = str.match(pattern);
  return match ? match[0] : null;
};

interface AdditionalRulesPageProps {
  isCredit: boolean;
}

export const AdditionalRulesPage: FC<AdditionalRulesPageProps> = ({ isCredit }) => {
  const [form] = Form.useForm();

  const { id: tenantId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [currentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);

  const { pct, onHardSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [rules, setRules] = useState<Rule<RuleKeys>[]>([]);

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id ?? 0}/pct/${pct.id ?? 0}/account-limits`,
      label: `Account Limits - ${isCredit ? "Payment" : "Top-up"} - ${pct?.displayName ?? ""} PCT`,
    },
    {
      href: `/tenant/${tenantId}/product/${currentProduct.id ?? 0}/pct/${pct.id ?? 0}/card-limits`,
      label: `Card Limits - ${isCredit ? "Payment" : "Top-up"} - ${pct?.displayName ?? ""} PCT`,
    },
  ];

  useEffect(() => {
    if (pct.pctProductValues) {
      setRules(
        prepareRulesToRender(
          (pct.pctProductValues ?? []).filter(
            pctValue =>
              pctValue.fieldCode === NIC_USAGE_PAYM_U01_RULES ||
              pctValue.fieldCode === NIC_USAGE_PAYM_U02_RULES ||
              pctValue.fieldCode === NIC_USAGE_PAYM_U03_RULES,
          ) as unknown as PctRule[],
        ),
      );
    }
  }, [pct.pctProductValues]);

  const onFinish = async () => {
    try {
      const existingRules = prepareActualPctValues(prepareRulesToSubmit(rules));

      const deletedRulesSuffix = fullRulesList
        .filter(rule => !rules.map(r => r.key).includes(rule))
        .map(rule => extractSuffix(rule))
        .filter(Boolean);

      const currentPctValues =
        pct.pctProductValues?.filter(
          value =>
            !fullRulesList.includes(value.fieldCode as (typeof fullRulesList)[number]) &&
            !deletedRulesSuffix.includes(extractSuffix(value.fieldCode)),
        ) ?? [];

      if (rules.length > 0 && existingRules.every(rule => !rule.value)) {
        notification.error({
          placement: "topRight",
          duration: 3,
          message: <div>You cannot submit empty rules! Please fill at least one rule.</div>,
        });
        return;
      }

      await onHardSavePct([...currentPctValues, ...existingRules], false);
    } catch (error) {
      const errorInstance = getErrorInstance(error);
      notification.error({
        placement: "topRight",
        duration: 3,
        message: (
          <div>
            {errorInstance?.response?.status ?? 400} <br />
            {SERVICE_UNAVAILABLE}
          </div>
        ),
      });
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle={`${isCredit ? "Payment" : "Top-up"} Limits – Additional Rules`}
      pageSubtitle={`You can specify your customized additional rules to limit ${
        isCredit ? "payments" : "top-up payments"
      } and apply them on the dedicated pages.`}
      submitHandler={onFinish}
      size="full"
      formSize="full"
      level="pct"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <RulesList rules={rules} setRules={setRules} />
    </CustomFormWrapper>
  );
};
