/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateVariableGroupRequest } from '../model';
// @ts-ignore
import { CreateVariableRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PatchVariableGroupRequest } from '../model';
// @ts-ignore
import { PatchVariableRequest } from '../model';
// @ts-ignore
import { Variable } from '../model';
// @ts-ignore
import { VariableGroup } from '../model';
/**
 * VariablesApi - axios parameter creator
 * @export
 */
export const VariablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Variables
         * @summary Create Variables
         * @param {CreateVariableRequest} createVariableRequest 
         * @param {number} variableGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVariable: async (createVariableRequest: CreateVariableRequest, variableGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVariableRequest' is not null or undefined
            assertParamExists('createVariable', 'createVariableRequest', createVariableRequest)
            // verify required parameter 'variableGroupId' is not null or undefined
            assertParamExists('createVariable', 'variableGroupId', variableGroupId)
            const localVarPath = `/api/v1/variables/variable-groups/{variableGroupId}/variables`
                .replace(`{${"variableGroupId"}}`, encodeURIComponent(String(variableGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVariableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create VariableGroup
         * @summary Create VariableGroup
         * @param {CreateVariableGroupRequest} createVariableGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVariableGroup: async (createVariableGroupRequest: CreateVariableGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVariableGroupRequest' is not null or undefined
            assertParamExists('createVariableGroup', 'createVariableGroupRequest', createVariableGroupRequest)
            const localVarPath = `/api/v1/variables/variable-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVariableGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Variables
         * @summary Delete Variables
         * @param {number} variableGroupId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVariable: async (variableGroupId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableGroupId' is not null or undefined
            assertParamExists('deleteVariable', 'variableGroupId', variableGroupId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVariable', 'id', id)
            const localVarPath = `/api/v1/variables/variable-groups/{variableGroupId}/variables/{id}`
                .replace(`{${"variableGroupId"}}`, encodeURIComponent(String(variableGroupId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete VariableGroup
         * @summary Delete VariableGroup
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVariableGroup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVariableGroup', 'id', id)
            const localVarPath = `/api/v1/variables/variable-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get VariableGroups
         * @summary Get VariableGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariableGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/variables/variable-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Variables
         * @summary Get Variables
         * @param {number} variableGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariables: async (variableGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableGroupId' is not null or undefined
            assertParamExists('getVariables', 'variableGroupId', variableGroupId)
            const localVarPath = `/api/v1/variables/variable-groups/{variableGroupId}/variables`
                .replace(`{${"variableGroupId"}}`, encodeURIComponent(String(variableGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Variables List by Subgroup Id
         * @summary Get Variables List by Subgroup Id
         * @param {number} subgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariablesBySubgroupId: async (subgroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subgroupId' is not null or undefined
            assertParamExists('getVariablesBySubgroupId', 'subgroupId', subgroupId)
            const localVarPath = `/api/v1/variables/subgroups/{subgroupId}`
                .replace(`{${"subgroupId"}}`, encodeURIComponent(String(subgroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch Variables
         * @summary Patch Variables
         * @param {PatchVariableRequest} patchVariableRequest 
         * @param {number} variableGroupId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVariable: async (patchVariableRequest: PatchVariableRequest, variableGroupId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchVariableRequest' is not null or undefined
            assertParamExists('patchVariable', 'patchVariableRequest', patchVariableRequest)
            // verify required parameter 'variableGroupId' is not null or undefined
            assertParamExists('patchVariable', 'variableGroupId', variableGroupId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchVariable', 'id', id)
            const localVarPath = `/api/v1/variables/variable-groups/{variableGroupId}/variables/{id}`
                .replace(`{${"variableGroupId"}}`, encodeURIComponent(String(variableGroupId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchVariableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch VariableGroup
         * @summary Patch VariableGroup
         * @param {PatchVariableGroupRequest} patchVariableGroupRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVariableGroup: async (patchVariableGroupRequest: PatchVariableGroupRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchVariableGroupRequest' is not null or undefined
            assertParamExists('patchVariableGroup', 'patchVariableGroupRequest', patchVariableGroupRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchVariableGroup', 'id', id)
            const localVarPath = `/api/v1/variables/variable-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchVariableGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VariablesApi - functional programming interface
 * @export
 */
export const VariablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VariablesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Variables
         * @summary Create Variables
         * @param {CreateVariableRequest} createVariableRequest 
         * @param {number} variableGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVariable(createVariableRequest: CreateVariableRequest, variableGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Variable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVariable(createVariableRequest, variableGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create VariableGroup
         * @summary Create VariableGroup
         * @param {CreateVariableGroupRequest} createVariableGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVariableGroup(createVariableGroupRequest: CreateVariableGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VariableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVariableGroup(createVariableGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Variables
         * @summary Delete Variables
         * @param {number} variableGroupId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVariable(variableGroupId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVariable(variableGroupId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete VariableGroup
         * @summary Delete VariableGroup
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVariableGroup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVariableGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get VariableGroups
         * @summary Get VariableGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariableGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VariableGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVariableGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Variables
         * @summary Get Variables
         * @param {number} variableGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariables(variableGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Variable>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVariables(variableGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Variables List by Subgroup Id
         * @summary Get Variables List by Subgroup Id
         * @param {number} subgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariablesBySubgroupId(subgroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Variable>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVariablesBySubgroupId(subgroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch Variables
         * @summary Patch Variables
         * @param {PatchVariableRequest} patchVariableRequest 
         * @param {number} variableGroupId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchVariable(patchVariableRequest: PatchVariableRequest, variableGroupId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Variable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchVariable(patchVariableRequest, variableGroupId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch VariableGroup
         * @summary Patch VariableGroup
         * @param {PatchVariableGroupRequest} patchVariableGroupRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchVariableGroup(patchVariableGroupRequest: PatchVariableGroupRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VariableGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchVariableGroup(patchVariableGroupRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VariablesApi - factory interface
 * @export
 */
export const VariablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VariablesApiFp(configuration)
    return {
        /**
         * Create Variables
         * @summary Create Variables
         * @param {CreateVariableRequest} createVariableRequest 
         * @param {number} variableGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVariable(createVariableRequest: CreateVariableRequest, variableGroupId: number, options?: any): AxiosPromise<Variable> {
            return localVarFp.createVariable(createVariableRequest, variableGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create VariableGroup
         * @summary Create VariableGroup
         * @param {CreateVariableGroupRequest} createVariableGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVariableGroup(createVariableGroupRequest: CreateVariableGroupRequest, options?: any): AxiosPromise<VariableGroup> {
            return localVarFp.createVariableGroup(createVariableGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Variables
         * @summary Delete Variables
         * @param {number} variableGroupId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVariable(variableGroupId: number, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVariable(variableGroupId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete VariableGroup
         * @summary Delete VariableGroup
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVariableGroup(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVariableGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get VariableGroups
         * @summary Get VariableGroups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariableGroups(options?: any): AxiosPromise<Array<VariableGroup>> {
            return localVarFp.getVariableGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Variables
         * @summary Get Variables
         * @param {number} variableGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariables(variableGroupId: number, options?: any): AxiosPromise<Array<Variable>> {
            return localVarFp.getVariables(variableGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Variables List by Subgroup Id
         * @summary Get Variables List by Subgroup Id
         * @param {number} subgroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariablesBySubgroupId(subgroupId: number, options?: any): AxiosPromise<Array<Variable>> {
            return localVarFp.getVariablesBySubgroupId(subgroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch Variables
         * @summary Patch Variables
         * @param {PatchVariableRequest} patchVariableRequest 
         * @param {number} variableGroupId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVariable(patchVariableRequest: PatchVariableRequest, variableGroupId: number, id: number, options?: any): AxiosPromise<Variable> {
            return localVarFp.patchVariable(patchVariableRequest, variableGroupId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch VariableGroup
         * @summary Patch VariableGroup
         * @param {PatchVariableGroupRequest} patchVariableGroupRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchVariableGroup(patchVariableGroupRequest: PatchVariableGroupRequest, id: number, options?: any): AxiosPromise<VariableGroup> {
            return localVarFp.patchVariableGroup(patchVariableGroupRequest, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VariablesApi - interface
 * @export
 * @interface VariablesApi
 */
export interface VariablesApiInterface {
    /**
     * Create Variables
     * @summary Create Variables
     * @param {CreateVariableRequest} createVariableRequest 
     * @param {number} variableGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    createVariable(createVariableRequest: CreateVariableRequest, variableGroupId: number, options?: AxiosRequestConfig): AxiosPromise<Variable>;

    /**
     * Create VariableGroup
     * @summary Create VariableGroup
     * @param {CreateVariableGroupRequest} createVariableGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    createVariableGroup(createVariableGroupRequest: CreateVariableGroupRequest, options?: AxiosRequestConfig): AxiosPromise<VariableGroup>;

    /**
     * Delete Variables
     * @summary Delete Variables
     * @param {number} variableGroupId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    deleteVariable(variableGroupId: number, id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete VariableGroup
     * @summary Delete VariableGroup
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    deleteVariableGroup(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get VariableGroups
     * @summary Get VariableGroups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    getVariableGroups(options?: AxiosRequestConfig): AxiosPromise<Array<VariableGroup>>;

    /**
     * Get Variables
     * @summary Get Variables
     * @param {number} variableGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    getVariables(variableGroupId: number, options?: AxiosRequestConfig): AxiosPromise<Array<Variable>>;

    /**
     * Returns Variables List by Subgroup Id
     * @summary Get Variables List by Subgroup Id
     * @param {number} subgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    getVariablesBySubgroupId(subgroupId: number, options?: AxiosRequestConfig): AxiosPromise<Array<Variable>>;

    /**
     * Patch Variables
     * @summary Patch Variables
     * @param {PatchVariableRequest} patchVariableRequest 
     * @param {number} variableGroupId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    patchVariable(patchVariableRequest: PatchVariableRequest, variableGroupId: number, id: number, options?: AxiosRequestConfig): AxiosPromise<Variable>;

    /**
     * Patch VariableGroup
     * @summary Patch VariableGroup
     * @param {PatchVariableGroupRequest} patchVariableGroupRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApiInterface
     */
    patchVariableGroup(patchVariableGroupRequest: PatchVariableGroupRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<VariableGroup>;

}

/**
 * VariablesApi - object-oriented interface
 * @export
 * @class VariablesApi
 * @extends {BaseAPI}
 */
export class VariablesApi extends BaseAPI implements VariablesApiInterface {
    /**
     * Create Variables
     * @summary Create Variables
     * @param {CreateVariableRequest} createVariableRequest 
     * @param {number} variableGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public createVariable(createVariableRequest: CreateVariableRequest, variableGroupId: number, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).createVariable(createVariableRequest, variableGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create VariableGroup
     * @summary Create VariableGroup
     * @param {CreateVariableGroupRequest} createVariableGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public createVariableGroup(createVariableGroupRequest: CreateVariableGroupRequest, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).createVariableGroup(createVariableGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Variables
     * @summary Delete Variables
     * @param {number} variableGroupId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public deleteVariable(variableGroupId: number, id: number, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).deleteVariable(variableGroupId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete VariableGroup
     * @summary Delete VariableGroup
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public deleteVariableGroup(id: number, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).deleteVariableGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get VariableGroups
     * @summary Get VariableGroups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public getVariableGroups(options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).getVariableGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Variables
     * @summary Get Variables
     * @param {number} variableGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public getVariables(variableGroupId: number, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).getVariables(variableGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Variables List by Subgroup Id
     * @summary Get Variables List by Subgroup Id
     * @param {number} subgroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public getVariablesBySubgroupId(subgroupId: number, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).getVariablesBySubgroupId(subgroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch Variables
     * @summary Patch Variables
     * @param {PatchVariableRequest} patchVariableRequest 
     * @param {number} variableGroupId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public patchVariable(patchVariableRequest: PatchVariableRequest, variableGroupId: number, id: number, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).patchVariable(patchVariableRequest, variableGroupId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch VariableGroup
     * @summary Patch VariableGroup
     * @param {PatchVariableGroupRequest} patchVariableGroupRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesApi
     */
    public patchVariableGroup(patchVariableGroupRequest: PatchVariableGroupRequest, id: number, options?: AxiosRequestConfig) {
        return VariablesApiFp(this.configuration).patchVariableGroup(patchVariableGroupRequest, id, options).then((request) => request(this.axios, this.basePath));
    }
}
