import { SelectOption } from "@ni/common/types";

export const authBlockResponseOptions: SelectOption[] = [
  { value: "RC=43", label: "RC=43 (Decline & Pick up Stolen)" },
  { value: "RC=41", label: "RC=41 (Decline & Pick up Lost)" },
  { value: "RC=34", label: "RC=34 (Decline with Fraud Code)" },
  { value: "RC=14", label: "RC=14 (Decline Closed)" },
  { value: "RC=07", label: "RC=07 (Decline & Pick up Special)" },
  { value: "RC=05R", label: "RC=05R (Decline R)" },
  { value: "RC=05", label: "RC=05 (Decline)" },
  { value: "RC=04", label: "RC=04 (Decline & Pick up General)" },
  { value: "RC=01", label: "RC=01 (Decline with Referral)" },
];
