import { TablePaginationConfig } from "antd";

import { PageDataFullInsuranceProgramTemplateDashboard } from "@ni/sdk/models";

export const initialInsuranceData: PageDataFullInsuranceProgramTemplateDashboard = {
  content: [],
  hasContent: false,
  hasNext: false,
  hasPrevious: false,
  isEmpty: false,
  isFirst: false,
  isLast: false,
  number: 0,
  numberOfElements: 0,
  size: 0,
  totalElements: 0,
  totalPages: 0,
};

export const initialInsurancePagination: TablePaginationConfig = {
  pageSize: 6,
  current: 1,
  total: 0,
  showSizeChanger: true,
};
