/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeInsuranceProgramPctValueRequest } from '../model';
// @ts-ignore
import { ChangeInsuranceProgramRequest } from '../model';
// @ts-ignore
import { CopyInsuranceProgramRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InsuranceProgram } from '../model';
// @ts-ignore
import { InsuranceProgramPctValue } from '../model';
/**
 * InsuranceProgramApi - axios parameter creator
 * @export
 */
export const InsuranceProgramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Copies Insurance Program by ID with new DisplayName and returns created one
         * @summary Copy Insurance Program by id
         * @param {CopyInsuranceProgramRequest} copyInsuranceProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy2: async (copyInsuranceProgramRequest: CopyInsuranceProgramRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyInsuranceProgramRequest' is not null or undefined
            assertParamExists('copy2', 'copyInsuranceProgramRequest', copyInsuranceProgramRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copy2', 'id', id)
            const localVarPath = `/api/v1/insurance-programs/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyInsuranceProgramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Insurance Program by ID
         * @param {ChangeInsuranceProgramRequest} changeInsuranceProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit3: async (changeInsuranceProgramRequest: ChangeInsuranceProgramRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeInsuranceProgramRequest' is not null or undefined
            assertParamExists('edit3', 'changeInsuranceProgramRequest', changeInsuranceProgramRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('edit3', 'id', id)
            const localVarPath = `/api/v1/insurance-programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeInsuranceProgramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Insurance Program Pct Values under specified program and parameter table
         * @param {ChangeInsuranceProgramPctValueRequest} changeInsuranceProgramPctValueRequest 
         * @param {number} id 
         * @param {number} parameterTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInsuranceProgramPctValues: async (changeInsuranceProgramPctValueRequest: ChangeInsuranceProgramPctValueRequest, id: number, parameterTableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeInsuranceProgramPctValueRequest' is not null or undefined
            assertParamExists('editInsuranceProgramPctValues', 'changeInsuranceProgramPctValueRequest', changeInsuranceProgramPctValueRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editInsuranceProgramPctValues', 'id', id)
            // verify required parameter 'parameterTableId' is not null or undefined
            assertParamExists('editInsuranceProgramPctValues', 'parameterTableId', parameterTableId)
            const localVarPath = `/api/v1/insurance-programs/{id}/pct-values`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parameterTableId !== undefined) {
                localVarQueryParameter['parameterTableId'] = parameterTableId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeInsuranceProgramPctValueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Insurance Program by ID
         * @summary Get Insurance Program by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById2', 'id', id)
            const localVarPath = `/api/v1/insurance-programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Insurance Programs belonging specified product
         * @summary Gets Insurance Programs by productId
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceProgramsByProductId: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getInsuranceProgramsByProductId', 'productId', productId)
            const localVarPath = `/api/v1/insurance-programs/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceProgramApi - functional programming interface
 * @export
 */
export const InsuranceProgramApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceProgramApiAxiosParamCreator(configuration)
    return {
        /**
         * Copies Insurance Program by ID with new DisplayName and returns created one
         * @summary Copy Insurance Program by id
         * @param {CopyInsuranceProgramRequest} copyInsuranceProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copy2(copyInsuranceProgramRequest: CopyInsuranceProgramRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copy2(copyInsuranceProgramRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Insurance Program by ID
         * @param {ChangeInsuranceProgramRequest} changeInsuranceProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit3(changeInsuranceProgramRequest: ChangeInsuranceProgramRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit3(changeInsuranceProgramRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Insurance Program Pct Values under specified program and parameter table
         * @param {ChangeInsuranceProgramPctValueRequest} changeInsuranceProgramPctValueRequest 
         * @param {number} id 
         * @param {number} parameterTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editInsuranceProgramPctValues(changeInsuranceProgramPctValueRequest: ChangeInsuranceProgramPctValueRequest, id: number, parameterTableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuranceProgramPctValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editInsuranceProgramPctValues(changeInsuranceProgramPctValueRequest, id, parameterTableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Insurance Program by ID
         * @summary Get Insurance Program by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById2(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById2(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Insurance Programs belonging specified product
         * @summary Gets Insurance Programs by productId
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsuranceProgramsByProductId(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuranceProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsuranceProgramsByProductId(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsuranceProgramApi - factory interface
 * @export
 */
export const InsuranceProgramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceProgramApiFp(configuration)
    return {
        /**
         * Copies Insurance Program by ID with new DisplayName and returns created one
         * @summary Copy Insurance Program by id
         * @param {CopyInsuranceProgramRequest} copyInsuranceProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy2(copyInsuranceProgramRequest: CopyInsuranceProgramRequest, id: number, options?: any): AxiosPromise<InsuranceProgram> {
            return localVarFp.copy2(copyInsuranceProgramRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Insurance Program by ID
         * @param {ChangeInsuranceProgramRequest} changeInsuranceProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit3(changeInsuranceProgramRequest: ChangeInsuranceProgramRequest, id: number, options?: any): AxiosPromise<InsuranceProgram> {
            return localVarFp.edit3(changeInsuranceProgramRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Insurance Program Pct Values under specified program and parameter table
         * @param {ChangeInsuranceProgramPctValueRequest} changeInsuranceProgramPctValueRequest 
         * @param {number} id 
         * @param {number} parameterTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editInsuranceProgramPctValues(changeInsuranceProgramPctValueRequest: ChangeInsuranceProgramPctValueRequest, id: number, parameterTableId: number, options?: any): AxiosPromise<Array<InsuranceProgramPctValue>> {
            return localVarFp.editInsuranceProgramPctValues(changeInsuranceProgramPctValueRequest, id, parameterTableId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Insurance Program by ID
         * @summary Get Insurance Program by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById2(id: number, options?: any): AxiosPromise<InsuranceProgram> {
            return localVarFp.getById2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Insurance Programs belonging specified product
         * @summary Gets Insurance Programs by productId
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceProgramsByProductId(productId: number, options?: any): AxiosPromise<Array<InsuranceProgram>> {
            return localVarFp.getInsuranceProgramsByProductId(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceProgramApi - interface
 * @export
 * @interface InsuranceProgramApi
 */
export interface InsuranceProgramApiInterface {
    /**
     * Copies Insurance Program by ID with new DisplayName and returns created one
     * @summary Copy Insurance Program by id
     * @param {CopyInsuranceProgramRequest} copyInsuranceProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApiInterface
     */
    copy2(copyInsuranceProgramRequest: CopyInsuranceProgramRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<InsuranceProgram>;

    /**
     * 
     * @summary Edit Insurance Program by ID
     * @param {ChangeInsuranceProgramRequest} changeInsuranceProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApiInterface
     */
    edit3(changeInsuranceProgramRequest: ChangeInsuranceProgramRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<InsuranceProgram>;

    /**
     * 
     * @summary Edit Insurance Program Pct Values under specified program and parameter table
     * @param {ChangeInsuranceProgramPctValueRequest} changeInsuranceProgramPctValueRequest 
     * @param {number} id 
     * @param {number} parameterTableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApiInterface
     */
    editInsuranceProgramPctValues(changeInsuranceProgramPctValueRequest: ChangeInsuranceProgramPctValueRequest, id: number, parameterTableId: number, options?: AxiosRequestConfig): AxiosPromise<Array<InsuranceProgramPctValue>>;

    /**
     * Returns Insurance Program by ID
     * @summary Get Insurance Program by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApiInterface
     */
    getById2(id: number, options?: AxiosRequestConfig): AxiosPromise<InsuranceProgram>;

    /**
     * Returns Insurance Programs belonging specified product
     * @summary Gets Insurance Programs by productId
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApiInterface
     */
    getInsuranceProgramsByProductId(productId: number, options?: AxiosRequestConfig): AxiosPromise<Array<InsuranceProgram>>;

}

/**
 * InsuranceProgramApi - object-oriented interface
 * @export
 * @class InsuranceProgramApi
 * @extends {BaseAPI}
 */
export class InsuranceProgramApi extends BaseAPI implements InsuranceProgramApiInterface {
    /**
     * Copies Insurance Program by ID with new DisplayName and returns created one
     * @summary Copy Insurance Program by id
     * @param {CopyInsuranceProgramRequest} copyInsuranceProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApi
     */
    public copy2(copyInsuranceProgramRequest: CopyInsuranceProgramRequest, id: number, options?: AxiosRequestConfig) {
        return InsuranceProgramApiFp(this.configuration).copy2(copyInsuranceProgramRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Insurance Program by ID
     * @param {ChangeInsuranceProgramRequest} changeInsuranceProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApi
     */
    public edit3(changeInsuranceProgramRequest: ChangeInsuranceProgramRequest, id: number, options?: AxiosRequestConfig) {
        return InsuranceProgramApiFp(this.configuration).edit3(changeInsuranceProgramRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Insurance Program Pct Values under specified program and parameter table
     * @param {ChangeInsuranceProgramPctValueRequest} changeInsuranceProgramPctValueRequest 
     * @param {number} id 
     * @param {number} parameterTableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApi
     */
    public editInsuranceProgramPctValues(changeInsuranceProgramPctValueRequest: ChangeInsuranceProgramPctValueRequest, id: number, parameterTableId: number, options?: AxiosRequestConfig) {
        return InsuranceProgramApiFp(this.configuration).editInsuranceProgramPctValues(changeInsuranceProgramPctValueRequest, id, parameterTableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Insurance Program by ID
     * @summary Get Insurance Program by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApi
     */
    public getById2(id: number, options?: AxiosRequestConfig) {
        return InsuranceProgramApiFp(this.configuration).getById2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Insurance Programs belonging specified product
     * @summary Gets Insurance Programs by productId
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramApi
     */
    public getInsuranceProgramsByProductId(productId: number, options?: AxiosRequestConfig) {
        return InsuranceProgramApiFp(this.configuration).getInsuranceProgramsByProductId(productId, options).then((request) => request(this.axios, this.basePath));
    }
}
