import React from "react";

import { FileCardDurationEnum, FileCardModel, FileCardSourceEnum, FileSection, FileTypeEnum } from "@ni/common/types";
import { checkIfOneProductHasFieldCodeValue } from "@ni/common/utils";

export const apiOnboarding: FileCardModel[] = [
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Client Create",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to create a cardholder record in NI system. This will register names, demographics and addresses of the cardholder.\n" +
      "This is used only first time when cardholder doesn’t exist in the system. If existing cardholder is applying for a new wallet or card, this API should not be used.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Account Create",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to create an account record in NI system. An account is the financial and transactional rules cards will be governed by and establish the link with potential core banking system record (CBS Number). \n" +
      "This is used only when account/wallet doesn’t exist in the system. If existing account/wallet is applying for a new card this API should not be used.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card Create",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to create a new virtual or physical card in NI system.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
];

export const apiMaintenance: FileCardModel[] = [
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Client Update",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to update a cardholder record in NI system.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Account Update",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to update an account record in NI system.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card Update",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to update a Card record in NI system.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card Replacement",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to replace, reissue or renew an existing card. Several options exist to tailor to many different use cases.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Virtual to Physical",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "Converts an existing Virtual Card into a Physical card and send this card to production. Existing virtual card will still be usable and physical card will inherit PAN & Expiry for enhanced cardholder experience.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
    dependsOnFunction: {
      func: checkIfOneProductHasFieldCodeValue,
      fieldCode: "reiss-phys",
      value: "true",
      disabledContent: "API can not be used because Physical cards option was not selected",
      hide: true,
    },
  },
];

export const apiCardPinManagement: FileCardModel[] = [
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "PIN Verification",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used for verifying the PIN of an existing card.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card set PIN",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to set a new PIN on card. To prepare PIN block, it’s possible to fetch clear PAN number using Lookup card identifier API.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
    dependsOnFunction: {
      func: checkIfOneProductHasFieldCodeValue,
      fieldCode: "nic-card-subtype-pinset-allowed",
      value: "true",
      disabledContent: "API can not be used because PIN set option was not selected",
    },
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card change PIN",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to change a PIN. Old and new PIN are expected in the input of this API. To prepare PIN block, it’s possible to fetch clear PAN number using Lookup card identifier API.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
    dependsOnFunction: {
      func: checkIfOneProductHasFieldCodeValue,
      fieldCode: "nic-card-subtype-pinset-allowed",
      value: "true",
      disabledContent: "API can not be used because PIN set option was not selected",
    },
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "PIN Retries Counter Reset",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "If customer used incorrect PIN, then PIN retries counter would be incremented. And after certain unsuccessful attempts card will be blocked. The service is used to reset the retries counter for Pin to 0.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
];

export const apiStatusManagement: FileCardModel[] = [
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card Status Update",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to change the status of the card or parent account to any configured value. This is typically used for blocking or unblocking a card.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card Activation",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to activate the card after its creation.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
];

export const apiInquiry: FileCardModel[] = [
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Lookup card identifier",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to fetch clear card number using an alternative identifier (e.g. EXID aka proxy number) and vice-versa",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
    dependsOn: {
      fieldLocation: ["tenantValues"],
      fieldCode: "nic-exid-use",
      searchForFieldInArray: true,
      disabledIfValueEquals: "false",
      defaultDisabled: false,
      disabledTooltip: "API can not be used because External card contract ID (EXID) option was not selected",
    },
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card Details",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to enquire the card detail using card number or EXID. Card details will provide statuses, basic balances and plastic information.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Customer Details",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to enquire the cardholder record (personal details, addresses, contact number, etc.)",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Balance Inquiry",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "Provides list of balances for a card",
    exportLink: "https://network.ae/",
    dependsOnFunction: {
      func: checkIfOneProductHasFieldCodeValue,
      fieldCode: "balance-owner",
      value: "CMS",
      disabledContent: " ",
      hide: true,
    },
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Get CVV2 service",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to get encrypted CVV2 value.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "List Of Cards",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used to fetch all the cards linked to a customer using customer number, or fetch all the cards linked to an account using account number",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card  Verification",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This API is used for verifying provided Card Number and Expiry against NI database. This is typically used to identify basic information of a cardholder by the banks automated system.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
  {
    tag: "wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Get Secured Card Details",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description:
      "This composite API will provide the consumer with (Encrypted PAN, masked PAN, expiry date, cardholder  name, embossing line 4, product information, encrypted CVV2)",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Get Dictionaries",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to get all available MCC groups for a given FI",
    exportLink: "https://network.ae/",
  },
];

export const apiExternalAuthorization: FileCardModel[] = [
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Authorization API",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to process the authorization.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
    dependsOn: {
      fieldLocation: ["tenantValues"],
      fieldCode: "nic-cbg-used",
      searchForFieldInArray: true,
      disabledIfValueEquals: "false",
      defaultDisabled: false,
      disabledTooltip: "API can not be used because CB Gate option was not selected",
    },
  },
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Authorization Reversal API",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to process the authorization reversal.",
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
    dependsOn: {
      fieldLocation: ["tenantValues"],
      fieldCode: "nic-cbg-used",
      searchForFieldInArray: true,
      disabledIfValueEquals: "false",
      defaultDisabled: false,
      disabledTooltip: "API can not be used because CB Gate option was not selected",
    },
  },
];

export const apiNotification: FileCardModel[] = [
  {
    tag: "after_wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Notification API",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: React.createElement("div", { key: "div_Notification_API_desc" }, [
      "This API is used to send following SMS notifications:",
      React.createElement("ul", { key: "ul_Notification_API_desc" }, [
        React.createElement("li", { key: "li_3D_Secure_OTP" }, "3D Secure OTP"),
        React.createElement("li", { key: "li_Token_provisioning_OTP" }, "Token provisioning OTP"),
        React.createElement("li", { key: "li_Token_events" }, "Token events"),
      ]),
    ]),
    exportLink: "https://network.ae/",
    tryItOutLink: "https://network.ae/",
  },
];

export const apiTransactions: FileCardModel[] = [
  {
    tag: "wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Card Transaction",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "This API is used to post online payments & perform payment reversals to cardholder account",
    exportLink: "https://network.ae/",
  },
  {
    tag: "wizard_api_",
    fileType: FileTypeEnum.JSON,
    title: "Transaction Details",
    duration: FileCardDurationEnum.Anytime,
    source: FileCardSourceEnum.Client,
    description: "View & filter transactions for a card",
    exportLink: "https://network.ae/",
  },
];

export const apiPage: FileSection[] = [
  {
    tag: "after_wizard_api_onboarding_",
    title: "ONBOARDING",
    files: apiOnboarding,
  },
  {
    tag: "after_wizard_api_maintenance_",
    title: "MAINTENANCE",
    files: apiMaintenance,
  },
  {
    tag: "after_wizard_api_card_pin_management_",
    title: "CARD PIN MANAGEMENT",
    files: apiCardPinManagement,
  },
  {
    tag: "after_wizard_api_status_management_",
    title: "STATUS MANAGEMENT",
    files: apiStatusManagement,
  },
  {
    tag: "after_wizard_api_inquiry_",
    title: "INQUIRY",
    files: apiInquiry,
  },
  {
    tag: "after_wizard_api_external_authorization_",
    title: "EXTERNAL AUTHORIZATION",
    files: apiExternalAuthorization,
    dependsOnFunction: {
      func: checkIfOneProductHasFieldCodeValue,
      fieldCode: "balance-owner",
      value: "CBS",
      disabledContent: " ",
      hide: true,
    },
  },
  {
    tag: "after_wizard_api_notification_",
    title: "NOTIFICATION",
    files: apiNotification,
  },
  {
    tag: "wizard_api_transactions_",
    title: "TRANSACTIONS",
    files: apiTransactions,
    dependsOnFunction: {
      func: checkIfOneProductHasFieldCodeValue,
      fieldCode: "balance-owner",
      value: "CMS",
      disabledContent: " ",
      hide: true,
    },
  },
];
