import { useMemo } from "react";
import { Form } from "antd";
import { useParams, useSearchParams } from "react-router-dom";

import {
  LTY_ENROL_FEE_EN,
  LTY_WELC_BONUS_TARGET,
  NIC_LTY_ACCR_ROUNDING_DECIMALS,
  NIC_LTY_BONUS_W_TAR0_BASE,
  NIC_LTY_ENROL_FEE_VAL,
  NIC_LTY_RED_RATE_CASH_RATE,
  NIC_LTY_RED_RATE_EXT_RATE,
} from "@ni/common/constants";
import { useHydrateForm, useProductSettings, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";
import { LoyaltyProgram } from "@ni/sdk/models";

import { LoyaltyEnrollmentFee, PointRedemption, WelcomeBonus } from "../../../../components/Pct/LoyaltyGeneralSettings";
import { usePct } from "../../../../hooks";

import styles from "../../styles.module.scss";

interface ParamMapObject {
  param: string;
  label: string;
}

type TabKeysLoyaltySettings = "" | "pointsRedemption" | "welcomeBonus" | "loyaltyEnrollment";

export const LoyaltyPctGeneralSettings = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [URLSearchParams] = useSearchParams();

  const { currentProduct, productCurrency } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  const [loyaltyPrograms] = useReduxState<LoyaltyProgram[]>("loyaltyPrograms", []);

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: pct?.pctProductValues ?? [],
      keys: {
        strings: [
          NIC_LTY_RED_RATE_CASH_RATE,
          NIC_LTY_RED_RATE_EXT_RATE,
          NIC_LTY_BONUS_W_TAR0_BASE,
          NIC_LTY_ACCR_ROUNDING_DECIMALS,
          LTY_ENROL_FEE_EN,
          NIC_LTY_ENROL_FEE_VAL,
        ],
      },
    },
    [form],
  );

  const relatedLinks = useMemo(() => {
    const paramMap: Record<string, ParamMapObject> = {
      pointsRedemption: { param: "pointsRedemption", label: "Point Redemption" },
      welcomeBonus: { param: "welcomeBonus", label: "Welcome Bonus" },
      loyaltyEnrollment: { param: "loyaltyEnrollment", label: "Loyalty Enrollment" },
    };

    const tabParam: string = URLSearchParams.get("tab") || paramMap["pointsRedemption"].param;

    return [
      {
        href: `/tenant/${tenantId}/product/${currentProduct?.id}/loyalty-settings?tab=${paramMap[tabParam].param}`,
        label: `${currentProduct?.displayName} - Loyalty - General Settings - ${paramMap[tabParam].label}`,
      },
    ];
  }, [URLSearchParams, tenantId, currentProduct.id, currentProduct.displayName]);

  const onFinish = (values: FormValues) => {
    let combinedValues = values;
    if (!values[LTY_ENROL_FEE_EN]) combinedValues = { ...values, [NIC_LTY_ENROL_FEE_VAL]: "" };

    void onSavePct(combinedValues);
  };

  return (
    <CustomFormWrapper
      pageTitle="Loyalty - General Settings"
      pageSubtitle="While the product can be associated with multiple loyalty programs, the following settings apply universally to all loyalty programs associated with the product for current pricing control table."
      form={form}
      submitHandler={onFinish}
      level="pct"
      formSize="sm"
      submitLabel="Save"
      relatedLinks={relatedLinks}
      customClassName={styles["pct-lty-form"]}
    >
      <Tabs<TabKeysLoyaltySettings>
        pagesList={[
          {
            key: "pointsRedemption" as const,
            children: <PointRedemption loyaltyPrograms={loyaltyPrograms ?? []} />,
            label: <div title="Point Redemption">Point Redemption</div>,
          },
          {
            key: "welcomeBonus" as const,
            children: <WelcomeBonus />,
            label: <div title="Welcome Bonus">Welcome Bonus</div>,
            dependency: Boolean(
              currentProduct.productValues?.some(
                productValue => productValue.fieldCode === LTY_WELC_BONUS_TARGET && productValue.value !== "None",
              ),
            ),
          },
          {
            key: "loyaltyEnrollment" as const,
            children: <LoyaltyEnrollmentFee form={form} productCurrency={productCurrency} />,
            label: <div title="Loyalty Enrollment Fee">Loyalty Enrollment Fee</div>,
          },
        ]}
        isCheckEnabled={true}
        form={form}
        initialValues={initialValues}
        discardAfterChangeTab={false}
        onSave={onFinish}
      />
    </CustomFormWrapper>
  );
};
