export const onKeyDownPreventChars = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const { key, ctrlKey } = e;

  if (
    key === "Backspace" ||
    key === "Delete" ||
    key === "ArrowLeft" ||
    key === "ArrowRight" ||
    key === "Tab" ||
    key === "Enter" ||
    (ctrlKey && (key === "a" || key === "c" || key === "v" || key === "x"))
  )
    return;

  if (!/[\d.]/.test(key)) e.preventDefault();
};

export const onPastePreventChars = (e: React.ClipboardEvent<HTMLInputElement>) => {
  const paste = e.clipboardData.getData("text");
  if (!/^\d*\.?\d*$/.test(paste)) e.preventDefault();
};
