import { Form, Space, Switch } from "antd";

import { NIC_LTY_FLAG_DEV, NIC_LTY_FLAG_EDITABLE } from "@ni/common/constants";
import { FormContent, TooltipInfo } from "@ni/common/ui";

export const LoyaltyEnrollment = () => {
  return (
    <FormContent gap={40}>
      <TooltipInfo
        largeLabel="Loyalty program enrollment refers to the process of signing up or registering for a loyalty program. It is the
      initial step that customers take to become members of the loyalty program and gain access to its benefits and
      rewards."
        tooltipProps={{
          title:
            "Loyalty enrolment fee can be redefined for a particular Pricing control table. Please follow the link at the bottom of the page.",
        }}
      />

      <Space direction="vertical" size={24}>
        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={NIC_LTY_FLAG_DEV}>
            <Switch />
          </Form.Item>
          Enable loyalty feature by default for all accounts from boarding
        </Space>

        <Space direction="horizontal" size={8}>
          <Form.Item valuePropName="checked" name={NIC_LTY_FLAG_EDITABLE}>
            <Switch />
          </Form.Item>
          Allow account level control for loyalty enrolment/ disenrollment
        </Space>
      </Space>
    </FormContent>
  );
};
