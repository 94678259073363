import { ProductBcParams } from "./productBcParams";
import { TableParams } from "./tableBcParams";

export interface FieldValue {
  value: string;
  displayed: string;
  tooltip?: string;
}

export const emptyValue = "- Not specified -";
export const normalValue = "Normal";

export const blockCodeValuesAndLabels: Map<ProductBcParams | TableParams, FieldValue[]> = new Map<
  ProductBcParams,
  FieldValue[]
>();
blockCodeValuesAndLabels.set(ProductBcParams.status, [
  {
    value: "",
    displayed: emptyValue,
  },
  {
    value: "XX",
    displayed: "Deceased",
  },
  {
    value: "XF",
    displayed: "Transfer From",
  },
  {
    value: "VP",
    displayed: "VIP",
  },
  {
    value: "VO",
    displayed: "Voluntary",
  },
  {
    value: "RV",
    displayed: "Recovery",
  },
  {
    value: "RQ",
    displayed: "Delinquency",
  },
  {
    value: "RM",
    displayed: "Return Mail",
  },
  {
    value: "RD",
    displayed: "Declined Reissue",
  },
  {
    value: "PD",
    displayed: "Current Past Due",
  },
  {
    value: "LS",
    displayed: "Lost/Stolen",
  },
  {
    value: "LG",
    displayed: "Legal",
  },
  {
    value: "LE",
    displayed: "Left Employment",
  },
  {
    value: "FR",
    displayed: "Fraud",
  },
  {
    value: "DI",
    displayed: "Dispute",
  },
  {
    value: "DD",
    displayed: "Dispute Disagree",
  },
  {
    value: "CR",
    displayed: "Credit Revoked",
  },
  {
    value: "CO",
    displayed: "Charge Off",
  },
  {
    value: "CN",
    displayed: "Cancelled",
  },
  {
    value: "CL",
    displayed: "Closed",
  },
  {
    value: "BK",
    displayed: "Bankruptcy",
  },
  {
    value: "AS",
    displayed: "Application",
  },
  {
    value: "0F",
    displayed: "Temp block Fraud",
  },
  {
    value: "0C",
    displayed: "Temp block by Customer",
  },
  {
    value: "0B",
    displayed: "Temp block by Bank",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.accountHolder, [
  {
    value: "",
    displayed: emptyValue,
  },
  {
    value: "0",
    displayed: "ID details were renewed",
  },
  {
    value: "1",
    displayed: "ID is to be expired in 2 months",
  },
  {
    value: "3",
    displayed: "ID is expired",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.cardHolder, [
  {
    value: "",
    displayed: emptyValue,
  },
  {
    value: "0",
    displayed: "ID details were renewed",
  },
  {
    value: "1",
    displayed: "ID is to be expired in 2 months",
  },
  {
    value: "3",
    displayed: "ID is expired",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.cardStatus, [
  {
    value: "",
    displayed: emptyValue,
  },
  {
    value: "RC",
    displayed: "Replaced",
  },
  {
    value: "N",
    displayed: "New",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.falconBlock, [
  {
    value: "",
    displayed: emptyValue,
  },
  {
    value: "T",
    displayed: "Temporary",
  },
  {
    value: "P",
    displayed: "Permanent",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.reissue, [
  {
    value: "Y",
    displayed: "Normal",
  },
  {
    value: "R",
    displayed: "Do not issue/reissue and report",
  },
  {
    value: "N",
    displayed: "Do not issue/reissue",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.authorisation, [
  {
    value: "",
    displayed: "RC=00 (Successful Transaction)",
  },
  {
    value: "RC=43",
    displayed: "(Decline & Pick up Stolen)",
  },
  {
    value: "RC=41",
    displayed: "(Decline & Pick up Lost)",
  },
  {
    value: "RC=34",
    displayed: "(Decline with Fraud Code)",
  },
  {
    value: "RC=14",
    displayed: "(Decline Closed)",
  },
  {
    value: "RC=07",
    displayed: "(Decline & Pick up Special)",
  },
  {
    value: "RC=05R",
    displayed: "(Decline R)",
  },
  {
    value: "RC=05",
    displayed: "(Decline)",
  },
  {
    value: "RC=04",
    displayed: "(Decline & Pick up General)",
  },
  {
    value: "RC=01",
    displayed: "(Decline with Referral)",
  },
  {
    value: "RC=00V",
    displayed: "(VIP)",
  },
  {
    value: "RC=00",
    displayed: "(Normal)",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.agreement, [
  {
    value: "A",
    displayed: "Active",
  },
  {
    value: "CC",
    displayed: "Cancelled by Cardholder",
  },
  {
    value: "CB",
    displayed: "Cancelled by Bank",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.fcPosting, [
  {
    value: "0",
    displayed: "Normal",
  },
  {
    value: "1",
    displayed: "Normal + Report",
  },
  {
    value: "2",
    displayed: "Do not post all transactions",
  },
  {
    value: "3",
    displayed: "Do not post all transactions + Report",
  },
  {
    value: "4",
    displayed: "Do not post transactions that debit account + Report Debits",
  },
  {
    value: "5",
    displayed: "Do not post transactions that debit account",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.sms, [
  {
    value: "F",
    displayed: "Normal (from individual flag)",
  },
  {
    value: "N",
    displayed: "No SMS",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.fcStmt, [
  {
    value: "N",
    displayed: "Normal (from individual flag)",
  },
  {
    value: "O",
    displayed: "No statement",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.fcReport, [
  {
    value: "N",
    displayed: "Do not report",
  },
  {
    value: "Y",
    displayed: "Report",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.fcMf, [
  {
    value: "N",
    displayed: "Do not waive",
  },
  {
    value: "Y",
    displayed: "Waive",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.fcStmtFee, [
  {
    value: "N",
    displayed: "Do not waive",
  },
  {
    value: "Y",
    displayed: "Waive",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.fcInactFee, [
  {
    value: "N",
    displayed: "Do not waive",
  },
  {
    value: "Y",
    displayed: "Waive",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.token, [
  {
    value: "",
    displayed: emptyValue,
  },
  {
    value: "S",
    displayed: "Suspend (only considered for the online TLCM solution)",
  },
  {
    value: "C1",
    displayed: "Close",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.abuStatus, [
  {
    value: "N",
    displayed: "Normal",
  },
  {
    value: "R",
    displayed: "Replaced",
  },
  {
    value: "C",
    displayed: "Close",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.delinquencyLevel, [
  {
    value: "",
    displayed: emptyValue,
  },
  {
    value: "2",
    displayed: "Level 2 (1-29 days delinquent)",
  },
  {
    value: "3",
    displayed: "Level 3 (30-59 days delinquent)",
  },
  {
    value: "4",
    displayed: "Level 4 (60-89 days delinquent)",
  },
  {
    value: "5",
    displayed: "Level 5 (90-119 days delinquent)",
  },
  {
    value: "6",
    displayed: "Level 6 (120-149 days delinquent)",
  },
  {
    value: "7",
    displayed: "Level 7 (150-179 days delinquent)",
  },
  {
    value: "8",
    displayed: "Level 8 (180-209 days delinquent)",
  },
  {
    value: "9",
    displayed: "Level 9 (210+ days delinquent)",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.agingCalculation, [
  {
    value: "N",
    displayed: "Normal",
  },
  {
    value: "B",
    displayed: "Bill account for entire outstanding balance",
  },
  {
    value: "F",
    displayed: "Freeze calculation",
  },
  {
    value: "S",
    displayed: "Skip payment (Waive MTP on due date)",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.bureauReporting, [
  {
    value: "N",
    displayed: "Do not report",
  },
  {
    value: "Y",
    displayed: "Report to Credit Bureau",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.directDebit, [
  {
    value: "",
    displayed: normalValue,
  },
  {
    value: "F",
    displayed: "Charge full balance for direct debit",
  },
  {
    value: "N",
    displayed: "Disable the direct debit",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.pointsAccrual, [
  {
    value: "",
    displayed: normalValue,
  },
  {
    value: "N",
    displayed: "Do not accrue",
  },
  {
    value: "W",
    displayed: "Write-off",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.pointsRedemption, [
  {
    value: "",
    displayed: normalValue,
  },
  {
    value: "N",
    displayed: "Do not redeem",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.insurancePrograms, [
  {
    value: "N",
    displayed: normalValue,
    tooltip: "No change to Insurance programs",
  },
  {
    value: "A",
    displayed: "Cancel all programs with auto re-instantiation",
    tooltip: "Cancels all programs with auto re-instantiation once status management code is removed",
  },
  {
    value: "M",
    displayed: "Cancel all programs with manual re-instantiation only",
    tooltip:
      "Insurance programs can be reinstated manually when status management code is removed. System will not reinstate programs automatically.",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.financeCharges, [
  {
    value: "N",
    displayed: "Do not waive",
  },
  {
    value: "W",
    displayed: "Waive",
  },
]);

blockCodeValuesAndLabels.set(ProductBcParams.paymentsFee, [
  {
    value: "N",
    displayed: "Do not waive",
  },
  {
    value: "Y",
    displayed: "Waive",
  },
]);

export const tableCellValues: FieldValue[] = [
  {
    value: "00",
    displayed: " ",
  },
  {
    value: "10",
    displayed: "Could be set",
  },
  {
    value: "01",
    displayed: "Could be removed",
  },
  {
    value: "11",
    displayed: "Could be set and removed",
  },
];
