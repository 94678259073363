export const prepareFieldsToRender = (
  checkboxFields: { [key: string]: string[] },
  setFields: (fieldName: string, key?: string) => void,
) => {
  Object.keys(checkboxFields).forEach(key => {
    checkboxFields[key].forEach(fieldName => {
      setFields(fieldName, key);
    });
  });
};
