import { QueryClient } from "react-query";

import { combineReducers } from "@reduxjs/toolkit";

import state from "./state";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {},
  },
});

const appReducer = combineReducers({
  state,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === "reset/state") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
