import { useCallback, useEffect, useState } from "react";

import { useReduxState } from "@ni/common/hooks";
import { LastWiazrdSubmittedStep } from "@ni/common/types";
import { getPhaseAndPageOrder } from "@ni/common/utils";
import { InsuranceWizardResponse, Phase } from "@ni/sdk/models";

import { useInsuranceWizardApi } from "./useInsuranceWizardApi";

export const useInsuranceWizard = () => {
  const { initWizard: prepareWizard, processWizardRequest: handleWizardRequest } = useInsuranceWizardApi();

  const [wizardResponse, setWizardResponse] = useReduxState<InsuranceWizardResponse>(
    "InsuranceWizardResponseState",
    {} as InsuranceWizardResponse,
  );

  const [phases, setPhases] = useReduxState<Phase[]>("ippWorkflowPhases", []);
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [lastSubmittedStep, setLastSubmittedStep] = useState<LastWiazrdSubmittedStep>({
    phase: 0,
    pageKey: "",
    currentEntityId: undefined,
  });

  const processWizardRequest = useCallback(
    async (tenantId: string, templateId?: string) => {
      try {
        const { pageId, workflow, tenant } = await prepareWizard(tenantId);
        const lastProcessedPage = tenant?.insuranceProgramTemplates?.find(x => x.id === parseInt(templateId ?? "0", 10))
          ?.lastPage?.id;
        if (!lastProcessedPage || !templateId) {
          setWizardResponse({ pageId, tenant, workflow });
          return;
        }

        await handleWizardRequest({
          pageId: lastProcessedPage,
          tenantId: parseInt(tenantId, 10),
          insuranceTemplateId: parseInt(templateId, 10),
          collectedValues: {},
        });
      } catch (error) {
        setIsLoading(false);
      }
    },
    [handleWizardRequest, prepareWizard, setIsLoading, setWizardResponse],
  );

  useEffect(() => {
    if (wizardResponse.workflow?.phases && wizardResponse?.pageId) {
      const wizardPageId = wizardResponse.pageId;
      setPhases(wizardResponse.workflow?.phases ?? []);

      const phaseAndPage = getPhaseAndPageOrder(wizardResponse.workflow?.phases, "", wizardPageId);

      setLastSubmittedStep({
        pageKey: phaseAndPage?.page.code as string,
        phase: phaseAndPage?.phaseOrder as number,
        currentEntityId: wizardResponse.insuranceTemplate?.id,
      });
    }
  }, [setPhases, wizardResponse.pageId, wizardResponse.insuranceTemplate?.id, wizardResponse.workflow?.phases]);

  useEffect(() => {
    return () => {
      setWizardResponse({} as InsuranceWizardResponse);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lastSubmittedStep,
    phases,
    processWizardRequest,
  };
};
