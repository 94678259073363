import { ReactNode } from "react";
import { InputNumberProps, SwitchProps } from "antd";

import { SelectOption } from "@ni/common/types";

interface Options {
  label: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

interface FormField<T> extends Options {
  props?: T;
}

export const murabahaProfitRate: FormField<InputNumberProps> = {
  code: "murab-profit-rate",
  label: "Murabaha profit rate",
  tooltip:
    "This rate will be used to calculate monthly finance payment due and special deposit account profit and bonus. When Murabaha is in effect both cash and retail profit rates are identical.",
  props: {
    precision: 2,
    min: 0,
    max: 100,
    controls: false,
    addonAfter: "%",
  },
};

export const transactionChargeProfit: RadioOptions = {
  code: "int-start",
  label: "Charge profit for transaction starting from:",
  default: "P",
  options: [
    {
      label: "Posting date",
      value: "P",
    },
    {
      label: "Transaction date",
      value: "T",
    },
  ],
};

export const transactionMaximumDays: FormField<InputNumberProps> = {
  code: "int-start-numb",
  label: "Maximum days between the transaction date and the posting date",
  tooltip: "If the number of days exceeds, then interests will be charged starting from the posting date",
  props: {
    precision: 0,
    controls: false,
  },
};

export const murabahaFeeRate: FormField<SwitchProps> = {
  code: "nic-int-rate-zero-all-fees",
  label: "Zero profit rate on all fees",
};

export const murabahaInterestRate: FormField<SwitchProps> = {
  code: "nic-int-rate-zero-all-int",
  label: "Zero profit rate on all profits",
};
