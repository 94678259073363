export const errorCodes = [400, 403, 404, 405, 409, 415, 500, 503, 504];

export const errorDetails: Record<number, { title: string; message: string }> = {
  400: { title: "Bad Request", message: "The server could not understand the request." },
  403: { title: "Forbidden", message: "You don't have permission to access this resource." },
  404: { title: "Not Found", message: "The requested page could not be found." },
  405: { title: "Method Not Allowed", message: "The requested method is not allowed for the resource." },
  409: { title: "Conflict", message: "There was a conflict while processing the request." },
  415: { title: "Unsupported Media Type", message: "The media type of the requested resource is not supported." },
  500: { title: "Internal Server Error", message: "An unexpected error occurred on the server." },
  503: { title: "Service Unavailable", message: "The server is currently unavailable. Please try again later." },
  504: { title: "Gateway Timeout", message: "The server didn't respond in time." },
};
