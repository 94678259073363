import { memo, useState } from "react";
import { Collapse } from "antd";
import cn from "classnames";
import type { FC } from "react";
import { useDrag, useDrop } from "react-dnd";

import { useReduxState } from "@ni/common/hooks";
import { ConfirmModal } from "@ni/common/ui";
import { ConfigurationApi } from "@ni/sdk/apis";
import { EventGroup } from "@ni/sdk/models";

import { ItemTypes } from "../../constants";
import EventGroupForm from "../EventGroupForm";
import { extraHeader, extraPanel } from "../helpers";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();

const { Panel } = Collapse;

interface CardProps {
  template: EventGroup;
  id: string;
  moveEventGroupCard: (id: string, to: number) => void;
  findEventGroupCard: (id: string) => { index: number };
  index: number;
  onUpdateEventGroupOrder: (smsTemplates: EventGroup[]) => void;
}

interface Item {
  id: string;
  originalIndex: number;
}
const CardFunc: FC<CardProps> = props => {
  const { template, id, moveEventGroupCard, findEventGroupCard, index, onUpdateEventGroupOrder } = props;
  const originalIndex = findEventGroupCard(id).index;

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [selectedEventGroupId, setSelectedEventGroupId] = useState<string>("");
  const [smsTemplates, setSmsTemplates] = useReduxState<EventGroup[]>("smsTemplates");

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.EVENT_GROUP,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveEventGroupCard(droppedId, index);
        }
        onUpdateEventGroupOrder(smsTemplates);
      },
    }),
    [id, originalIndex, moveEventGroupCard],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.EVENT_GROUP,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findEventGroupCard(id);
          moveEventGroupCard(draggedId, overIndex);
        }
      },
    }),
    [findEventGroupCard, moveEventGroupCard],
  );

  const onChangeCollapse = () => {
    setIsActive(!isActive);
  };

  const onCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onDeleteEventGroup = () => {
    const newEventGroupList = smsTemplates.filter(item => item.id !== Number(selectedEventGroupId));

    configurationServiceApi
      .deleteEventGroup(Number(selectedEventGroupId))
      .then(() => {
        setSmsTemplates(newEventGroupList);
        onCancelDeleteModal();
      })
      .catch(() => {
        onCancelDeleteModal();
      });
  };

  return (
    <div
      className={cn(styles["event-group"], id !== "undefined" ? isDragging && styles["opacity"] : styles["notDND"])}
      ref={node => {
        if (id !== "undefined") {
          drag(drop(node));
        }
      }}
      key={template.order}
    >
      <Collapse
        className={styles["event-group-collapse"]}
        collapsible="header"
        onChange={() => onChangeCollapse()}
        key={template.order}
        accordion={true}
      >
        <Panel
          header={extraHeader({
            index,
            itemName: template.name || "",
            isActive,
            isShowArrow: true,
          })}
          key={id ?? template.order}
          showArrow={false}
          extra={extraPanel({
            onDeleteModalClick: () => {
              setIsDeleteModalOpen(true);
              setSelectedEventGroupId(id);
            },
          })}
        >
          <EventGroupForm template={template} newEventGroupOrder={originalIndex} key={id} />
        </Panel>
      </Collapse>
      <ConfirmModal
        title="Are you sure delete this Event group? By clicking 'Confirm' you confirm deleting the event group and all its subordinates (event subgroups, events, SMS templates)."
        isOpened={isDeleteModalOpen}
        onCancel={onCancelDeleteModal}
        onConfirm={onDeleteEventGroup}
        isLoading={false}
        type="delete-variable"
      />
    </div>
  );
};

const EventGroupCard: FC<CardProps> = memo(CardFunc);
export default EventGroupCard;
