import { FC } from "react";
import { Spin as SpinAntd } from "antd";
import cn from "classnames";

import styles from "./styles.module.scss";

interface ISpin {
  fullscreen?: boolean;
}

export const Spin: FC<ISpin> = ({ fullscreen = true }) => {
  return <SpinAntd size="large" className={cn(fullscreen ? styles["fullscreen"] : styles["small"])} />;
};
