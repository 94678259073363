import { useEffect } from "react";

import { Dictionary } from "@ni/sdk/models";

import { useConfigurationApi } from "../api";
import { useReduxState } from "../store";

const schemeDictionaryCode = "scheme";

export const useGetPaymentScheme = () => {
  const [schemeList, setSchemeList] = useReduxState<Dictionary[]>("paymentSchemeListState", []);
  const [schemeMap, setSchemeMap] = useReduxState<Map<string, string>>("paymentSchemeMapState");

  const { getDictionariesByCode } = useConfigurationApi();

  useEffect(() => {
    if (schemeList.length === 0)
      void getDictionariesByCode(schemeDictionaryCode)
        .then(response => {
          setSchemeList(response?.dictionaries || []);
          return response?.dictionaries;
        })
        .then(schemeList => {
          if (schemeList) {
            const schemeHolder = new Map();
            schemeList.forEach(scheme => {
              schemeHolder.set(scheme.value, scheme.displayValue);
            });
            setSchemeMap(schemeHolder);
          }
        });
  }, [schemeList.length, getDictionariesByCode, setSchemeList, setSchemeMap]);

  return { schemeList, schemeMap };
};
