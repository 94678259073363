import React, { useEffect, useState } from "react";
import { Divider, Dropdown, DropdownProps, Empty, Flex, Input, theme } from "antd";

import { SearchOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

const { useToken } = theme;

type MenuItemModel = {
  label: string;
  value: string;
  key: string;
};

interface SearchableDropdownProps extends Omit<DropdownProps, "menu"> {
  children: React.ReactNode;
  options: MenuItemModel[];
  menu?: Omit<DropdownProps["menu"], "items">;
}

export const SearchableDropdown: React.FC<SearchableDropdownProps> = ({ children, options, menu, ...props }) => {
  const [items, setItems] = useState<MenuItemModel[]>([]);
  const [filteredItems, setFilteredItems] = useState<MenuItemModel[]>([]);

  useEffect(() => {
    setItems(options);
    setFilteredItems(options);
  }, [options]);

  const { token } = useToken();

  const onFilterOptions = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setFilteredItems(items?.filter(x => x.label.toLowerCase().includes(value.toLowerCase())));
  };

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  return (
    <Dropdown
      menu={{ items: filteredItems, ...menu }}
      dropdownRender={menu => (
        <div style={contentStyle} className={styles["dropdown"]}>
          <div className={styles["dropdown-input"]}>
            <Input prefix={<SearchOutlined />} placeholder="Search" size="middle" onChange={onFilterOptions} />
          </div>

          <Divider className={styles["dropdown-divider"]} />

          {filteredItems && filteredItems.length > 0 ? (
            React.cloneElement(menu as React.ReactElement, { className: styles["dropdown-menu"] })
          ) : (
            <Flex align="center" justify="center" className={styles["dropdown-empty"]}>
              <Empty />
            </Flex>
          )}
        </div>
      )}
      {...props}
    >
      {children}
    </Dropdown>
  );
};
