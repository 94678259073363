import React, { FC } from "react";
import { Collapse } from "antd";

import { CardDisabled, FileCardModel, FileSection } from "@ni/common/types";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { Tenant, TenantProductWizardResponse, TenantValue } from "@ni/sdk/models";

import { FileCard } from "../FileCard";
import { PageItemLayoutGeneral } from "../PageItemLayout";

import styles from "./styles.module.scss";

const { Panel } = Collapse;

interface DocumentationListProps {
  documentationList: FileSection[];
  data: TenantProductWizardResponse | Tenant;
}

export const DocumentationList: FC<DocumentationListProps> = ({ documentationList, data }) => {
  const checkIfCardDisabled = (elem: FileCardModel | FileSection): CardDisabled | undefined => {
    if (elem.dependsOnFunction) {
      const showDefaultContent = elem.dependsOnFunction.func(
        data,
        elem.dependsOnFunction.fieldCode,
        elem.dependsOnFunction.value,
      );

      if (!showDefaultContent) {
        return {
          disabled: true,
          tooltip: elem.dependsOnFunction.disabledContent as string | JSX.Element,
        };
      }
    }

    if (elem.dependsOn) {
      let disabled = elem.dependsOn.defaultDisabled;
      let values: any = data;
      elem.dependsOn.fieldLocation.forEach(field => {
        if (values) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          values = values[field];
        }
      });

      if (!values) {
        return undefined;
      }

      let compareValue: string | boolean | undefined;
      if (elem.dependsOn.searchForFieldInArray) {
        compareValue = (values as TenantValue[]).find(
          (v: TenantValue) => v.fieldCode === elem.dependsOn?.fieldCode,
        )?.value;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        compareValue = values[elem.dependsOn.fieldCode] as boolean;
      }

      if (compareValue !== undefined) {
        disabled = compareValue === elem.dependsOn.disabledIfValueEquals;
      }

      return {
        disabled,
        tooltip: elem.dependsOn.disabledTooltip || "",
      };
    }

    return undefined;
  };

  const checkIfHide = (elem: FileCardModel | FileSection): boolean => {
    if (elem.dependsOnOptional && elem.dependsOnOptional.showIf && elem.dependsOnOptional.showIfValueEquals) {
      if (elem.dependsOnOptional.showIf === "balance-owner") {
        const fieldCode = getFormValueFromProductValues(
          (data as TenantProductWizardResponse)?.product?.productValues,
          "balance-owner",
        ) as string;

        if (fieldCode !== elem.dependsOnOptional.showIfValueEquals) {
          return true;
        }
      }
    }
    if (elem.dependsOnFunction) {
      return (
        !elem.dependsOnFunction.func(data, elem.dependsOnFunction.fieldCode, elem.dependsOnFunction.value) &&
        !!elem.dependsOnFunction.hide
      );
    }
    return false;
  };

  const getComponentsList = (list: FileCardModel[]) => {
    return list.map(element => {
      const disabled = checkIfCardDisabled(element);
      const hide = checkIfHide(element);

      if (hide) {
        return null;
      }

      if (disabled?.disabled && (element.dependsOn?.hide || element.dependsOnFunction?.hide)) {
        return null;
      }

      return (
        <FileCard
          key={element.tag + element.fileType + element.title}
          fileType={element.fileType}
          title={element.title}
          source={element.source}
          duration={element.duration}
          cardDisabled={disabled}
        >
          <div>{element.description}</div>
        </FileCard>
      );
    });
  };

  const checkSectionDisabled = (list: FileSection): JSX.Element | string | undefined => {
    if (list.dependsOnFunction) {
      const showDefaultContent = list.dependsOnFunction.func(
        data,
        list.dependsOnFunction.fieldCode,
        list.dependsOnFunction.value,
      );

      if (!showDefaultContent) {
        return list.dependsOnFunction.disabledContent;
      }
    }

    if (list.dependsOn) {
      const dependsValue = checkIfCardDisabled(list);

      if (dependsValue?.disabled) {
        return list.dependsOn.disabledContent;
      }

      return undefined;
    }

    return undefined;
  };

  return (
    <PageItemLayoutGeneral className="m-r-40">
      {documentationList.map(docList => {
        const hide = checkIfHide(docList);

        if (hide) {
          return null;
        }

        if (docList.title) {
          return (
            <Collapse
              className="documentation-examples"
              key={docList.tag + Math.random().toString()}
              defaultActiveKey={docList.tag + docList.title}
            >
              <Panel key={docList.tag + docList.title} header={docList.title}>
                {checkSectionDisabled(docList) || (
                  <div className={styles["documentation-card"]} key={docList.tag + docList.title}>
                    {getComponentsList(docList.files)}
                  </div>
                )}
              </Panel>
            </Collapse>
          );
        }

        return (
          <div className={styles["documentation-card"]} key={docList.tag}>
            {getComponentsList(docList.files)}
          </div>
        );
      })}
    </PageItemLayoutGeneral>
  );
};
