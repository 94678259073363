import { NavbarItem } from "@ni/common/types";

export const financialInstitutionSettingsItems: NavbarItem[] = [
  {
    name: "Financial institution profile",
    route: "financial-institution-settings",
  },
  {
    name: "Users",
    route: "users",
  },
];
