import { FC } from "react";
import { Form } from "antd";

import { useGetCurrencyList, useHydrateForm } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { companyNameOption, currencyOption, templateNameOption } from "./constants";

export const TemplateInitiationPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest } = useInsuranceWizardApi();
  const { currencyList } = useGetCurrencyList();

  const insuranceName = Form.useWatch(templateNameOption.code, form) as string;

  useHydrateForm({
    form,
    entityFields: wizardResponse?.insuranceTemplate
      ? ([
          { code: templateNameOption.code, value: wizardResponse.insuranceTemplate.name },
          { code: companyNameOption.code, value: wizardResponse.insuranceTemplate.insuranceCompanyName },
          { code: currencyOption.code, value: wizardResponse.insuranceTemplate.currency },
        ] as {
          code: string;
          value: string;
        }[])
      : [{ code: currencyOption.code, value: wizardResponse.tenant?.defaultCurrency }],
    keys: {
      strings: [templateNameOption.code, companyNameOption.code, currencyOption.code],
    },
  });

  const onFinish = (values: FormValues) => {
    void processWizardRequest({
      pageId: wizardResponse?.pageId as number,
      tenantId: wizardResponse.tenant?.id,
      insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Insurance Program Template Initiation"
      pageSubtitle="Please follow the steps to configure an Insurance Program Template. Once completed, it can be applied to specific products. You can reapply your template filled data to new products, you can also create additional templates, and you may change any of those parameters on product level later on, without impacting the original template"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      buttonDisabled={insuranceName?.toString().trim() === templateNameOption.default?.toString().trim()}
    >
      <NetworkForm.String
        formItemOptions={{
          name: templateNameOption.code,
          label: templateNameOption.name,
          rules: [{ required: true, message: "Insurance program name is required" }],
          initialValue: templateNameOption.default,
        }}
        maxLength={64}
      />

      <NetworkForm.String
        formItemOptions={{
          name: companyNameOption.code,
          label: companyNameOption.name,
        }}
        maxLength={24}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: currencyOption.code,
          label: currencyOption.name,
        }}
        optionList={currencyList?.map(currency => ({
          value: currency.value,
          label: currency.displayValue,
        }))}
        showSearch={true}
        filterOption={filterByDisplayValue}
      />
    </CustomFormWrapper>
  );
};
