import { FC, useMemo } from "react";
import { Form } from "antd";

import { TRANS_FEE_OPTIONS } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CheckboxGroup, CustomFormWrapper, FormItemLabel } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { CHECKBOX_GROUP_TRANSACTION_FEES_PRESET } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionFeesPresetPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const isProductTypePrepaid = useMemo(
    () =>
      (getFormValueFromProductValues(wizardResponse.product?.productValues, "product-type") as string) === "Prepaid",
    [wizardResponse.product?.productValues],
  );
  const isProductTypeCredit = useMemo(
    () => (getFormValueFromProductValues(wizardResponse.product?.productValues, "product-type") as string) === "Credit",
    [wizardResponse.product?.productValues],
  );

  const filteredCheckboxes = isProductTypePrepaid
    ? CHECKBOX_GROUP_TRANSACTION_FEES_PRESET.filter(checkbox => !["P"].includes(checkbox.value))
    : isProductTypeCredit
      ? CHECKBOX_GROUP_TRANSACTION_FEES_PRESET.filter(checkbox => !["T"].includes(checkbox.value))
      : CHECKBOX_GROUP_TRANSACTION_FEES_PRESET;

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      lists: [TRANS_FEE_OPTIONS],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        ...(values[TRANS_FEE_OPTIONS] && {
          [TRANS_FEE_OPTIONS]: (values[TRANS_FEE_OPTIONS] as string[]).join(","),
        }),
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Transaction Fees Pre-set"
      pageSubtitle="Transaction fees are charges that can be imposed every time a card is used to make a purchase or transaction. These fees are typically a percentage of the total transaction amount or a flat fee per transaction. Details are available on the ongoing screens."
      size="full"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name={TRANS_FEE_OPTIONS}
        label={
          <FormItemLabel label="Choose transaction fees you want to apply to your product" code={TRANS_FEE_OPTIONS} />
        }
      >
        <CheckboxGroup checkboxes={filteredCheckboxes} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
