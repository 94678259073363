import { WizardPageMock } from "@ni/common/types";

import {
  InsuranceCancelationPage,
  InsuranceCoveragePage,
  InsuranceProductsApplyingPage,
  InsuranceSuccessPage,
  PremiumSettingsPage,
  PremiumWaivingPage,
  ProductEnrollmentSettingsPage,
  TemplateInitiationPage,
} from "../pages";

export const WIZARD_PAGES: WizardPageMock[] = [
  { pageKey: "INS_INIT", component: TemplateInitiationPage },
  { pageKey: "INS_ENR_SET", component: ProductEnrollmentSettingsPage },
  { pageKey: "INS_CANCEL_TEMPL", component: InsuranceCancelationPage },
  { pageKey: "INS_COV_TEMPL", component: InsuranceCoveragePage },
  { pageKey: "INS_APPLY_TEMPL", component: InsuranceProductsApplyingPage },
  { pageKey: "INS_PREM_SETT", component: PremiumSettingsPage },
  { pageKey: "INS_PREM_WAIV", component: PremiumWaivingPage },
  { pageKey: "INS_SUCCESS", component: InsuranceSuccessPage },
];
