import { FC } from "react";
import { Form, FormInstance, Typography } from "antd";

import { LTY_ENROL_FEE_EN, NIC_LTY_ENROL_FEE_VAL } from "@ni/common/constants";
import { FormValues } from "@ni/common/types";
import { FormContent, NetworkForm } from "@ni/common/ui";

import styles from "../../../../pages/pct/styles.module.scss";

interface LoyaltyEnrollmentFeeProps {
  form: FormInstance<FormValues>;
  productCurrency: string;
}

export const LoyaltyEnrollmentFee: FC<LoyaltyEnrollmentFeeProps> = ({ form, productCurrency }) => {
  const isEnrollmentFeeOn = Form.useWatch(LTY_ENROL_FEE_EN, form);

  return (
    <FormContent gap={40}>
      <div>
        A loyalty enrollment fee refers to a fee that customers are required to pay when signing up a loyalty program.
        It is a one-time fee that may be associated with joining the loyalty program and accessing its benefits and
        rewards.
      </div>

      <NetworkForm.Switch
        formItemOptions={{
          name: LTY_ENROL_FEE_EN,
          label: <Typography.Text strong={true}>Enable loyalty enrollment fee on your products</Typography.Text>,
          valuePropName: "checked",
        }}
      />

      {isEnrollmentFeeOn && (
        <NetworkForm.Number
          formItemOptions={{
            name: NIC_LTY_ENROL_FEE_VAL,
            label: "Loyalty enrollment fee amount",
            rules: [{ required: true, message: "Please enter loyalty enrollment fee amount" }],
            className: styles["pct-lty-form-item"],
          }}
          controls={false}
          precision={2}
          suffix={productCurrency}
          className="w-p-100"
        />
      )}
    </FormContent>
  );
};
