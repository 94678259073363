import { FC, ReactNode } from "react";

import styles from "./styles.module.scss";

export interface TabContentLayoutProps {
  description: string;
  sectionTop?: ReactNode;
  sectionMiddle?: ReactNode;
  sectionBottom?: ReactNode;
}

export const TabContentLayout: FC<TabContentLayoutProps> = ({
  description,
  sectionTop,
  sectionMiddle,
  sectionBottom,
}) => {
  return (
    <div className={styles["tab-content-wrapper"]}>
      <div className={styles["tab-content--description"]}>{description}</div>
      {sectionTop && sectionTop}
      {sectionMiddle && <div className={styles["tab-content-grey-section-wrapper"]}>{sectionMiddle}</div>}
      {sectionBottom && sectionBottom}
    </div>
  );
};
