import { useCallback, useEffect } from "react";

import { TenantApi } from "@ni/sdk/apis";
import { Tenant } from "@ni/sdk/models";

import { useReduxState } from "../store";

interface UseTenantProps {
  tenantId?: number;
}

const tenantServiceApi = new TenantApi();

export const useTenant = (props?: UseTenantProps | undefined) => {
  const [tenant, setTenant] = useReduxState<Tenant | undefined>("currentTenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const getTenantById = useCallback(
    async (tenantId: number) => {
      setIsLoading(true);
      try {
        const { data: tenant } = await tenantServiceApi.getTenantById(tenantId);
        setTenant(tenant);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setIsLoading, setTenant],
  );

  useEffect(() => {
    if (props?.tenantId) void getTenantById(props.tenantId);
  }, [getTenantById, props?.tenantId]);

  return { tenant, setTenant };
};
