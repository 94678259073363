import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { colors } from "@ni/common/constants";

const IconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 5L3 10L8 15" stroke={colors.lightColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3 10H11C16.523 10 21 14.477 21 20V21"
      stroke={colors.lightColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BackIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
