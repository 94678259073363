export const TIERS_HEADINGS_AMOUNT = [
  {
    label: "Min spending amount per cycle",
    tooltip: "Min amount is inclusive",
  },
  {
    label: "Max spending amount per cycle",
    tooltip: "Max amount is not inclusive",
  },
  {
    label: "Apply accrual rate",
  },
];

export const TIERS_HEADINGS_COUNT = [
  {
    label: "Min count of transactions per cycle",
    tooltip: "Min count is inclusive",
  },
  {
    label: "Max count of transactions per cycle",
    tooltip: "Max count is not inclusive",
  },
  {
    label: "Apply accrual rate",
  },
];
