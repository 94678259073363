import { ParameterTableValue } from "@ni/sdk/models";

export const enableCTotal = "paym-c-total-enabled";
export const enableDTotalOption = "d-paym-c-total-enabled";
export const dTotalNrOption = "nic-d-paym-c-total-nr";
export const dTotalAmOption = "nic-d-paym-c-total-am";
export const dTotalSingleOption = "nic-d-paym-c-total-single";
export const enableMTotalOption = "m-paym-c-total-enabled";
export const mTotalNrOption = "nic-m-paym-c-total-nr";
export const mTotalAmOption = "nic-m-paym-c-total-am";
export const mTotalSingleOption = "nic-m-paym-c-total-single";
export const enableD365TotalOption = "d365-paym-c-total-enabled";
export const d365TotalNrOption = "nic-d365-paym-c-total-nr";
export const d365TotalAmOption = "nic-d365-paym-c-total-am";
export const d365TotalSingleOption = "nic-d365-paym-c-total-single";

export const initialValues: ParameterTableValue[] = [
  {
    fieldCode: enableCTotal,
    value: "true",
  },
  {
    fieldCode: enableDTotalOption,
    value: "true",
  },
  {
    fieldCode: dTotalNrOption,
    value: "20",
  },
  {
    fieldCode: dTotalAmOption,
    value: "500000",
  },
  {
    fieldCode: dTotalSingleOption,
    value: "500000",
  },
  {
    fieldCode: enableMTotalOption,
    value: "true",
  },
  {
    fieldCode: mTotalNrOption,
    value: "20",
  },
  {
    fieldCode: mTotalAmOption,
    value: "500000",
  },
  {
    fieldCode: mTotalSingleOption,
    value: "500000",
  },
  {
    fieldCode: enableD365TotalOption,
    value: "true",
  },
  {
    fieldCode: d365TotalNrOption,
    value: "20",
  },
  {
    fieldCode: d365TotalAmOption,
    value: "500000",
  },
  {
    fieldCode: d365TotalSingleOption,
    value: "500000",
  },
];
