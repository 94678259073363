import React, { FC } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DocumentationList } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { apiWizardPage } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ApiPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const apiWizardList = apiWizardPage;

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const onFinish = (): void => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {},
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="API (Application Program Interface)"
      pageSubtitle={
        <>
          <div>
            API is a protocol used by a software application to access encapsulated functionalities of another software
            application in a secured manner.
          </div>
          <div>
            Visit our{" "}
            <a href="https://developer.network.ae/" target="_blank" rel="noreferrer">
              Developer portal
            </a>{" "}
            to get more information and to try out API sandboxes.
          </div>
        </>
      }
      size="full"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <DocumentationList documentationList={apiWizardList} data={wizardResponse} />
    </CustomFormWrapper>
  );
};
