import { FC } from "react";
import { Typography } from "antd";

import { interfacesPage } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import {
  AfterWizardEditPageTitle,
  DocumentationList,
  FileCardListWrapper,
  PageItemLayoutElements,
  PageItemLayoutGeneral,
  TooltipInfo,
} from "@ni/common/ui";
import { Tenant } from "@ni/sdk/models";

export const InterfacesPage: FC = () => {
  const [tenant] = useReduxState<Tenant>("tenant", {});
  const cardLists = interfacesPage;

  return (
    <FileCardListWrapper>
      <PageItemLayoutGeneral>
        <PageItemLayoutElements>
          <AfterWizardEditPageTitle title="Interfaces" />
          <Typography.Text>
            <TooltipInfo
              largeLabel="Interfaces are the files which has potential impact on the information in the system."
              tooltipProps={{
                title: (
                  <>
                    <span>Interfaces are usually used for the following reasons:</span>
                    <ul>
                      <li>Incoming files like account boarding files which will update demographic details.</li>
                      <li>Outgoing extract which holds information on the account like demographic details, etc.</li>
                      <li>
                        Transaction dump file which shows all the transactions & its details that are posted in the last
                        batch.
                      </li>
                    </ul>
                  </>
                ),
              }}
            />
          </Typography.Text>
        </PageItemLayoutElements>
        <DocumentationList documentationList={cardLists} data={tenant} />
      </PageItemLayoutGeneral>
    </FileCardListWrapper>
  );
};
