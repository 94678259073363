/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from "react";
import { InputNumberProps } from "antd";

import { SelectOption } from "@ni/common/types";

interface Options {
  label: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

interface FormField<T> extends Options {
  props?: T;
}

export const creditProfitStructure: RadioOptions = {
  code: "credit-type-islamic",
  label: "Islamic credit card profit structure",
  default: "Murabaha",
  options: [
    {
      value: "Murabaha",
      label: "Murabaha",
      tooltip:
        "Murabaha is a cost plus financing structure in which the seller provides the cost and profit margin of an asset",
    },
    {
      value: "Tawarruq",
      label: "Tawarruq",
      tooltip:
        "Tawarruq is a financial concept in Islamic banking that involves a buy-and-sell arrangement to achieve a specific financial objective",
    },
  ],
};

export const murabahaProfitRate: FormField<InputNumberProps> = {
  code: "murab-profit-rate",
  label: "Murabaha profit rate",
  tooltip:
    "This rate will be used to calculate monthly finance payment due and special deposit account profit and bonus. When Murabaha is in effect both cash and retail profit rates are identical.",
  props: {
    min: 0,
    precision: 2,
    controls: false,
    addonAfter: "%",
  },
};
