import { FC, useMemo } from "react";
import { Form } from "antd";

import { PAYM_LIM_PERIODS } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm, DynamicSwitchRule } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { useProductCurrencyType } from "../../hooks";

import {
  bTotalAmOption,
  bTotalNrOption,
  bTotalSingleOption,
  d365TotalAmOption,
  d365TotalNrOption,
  d365TotalSingleOption,
  dTotalAmOption,
  dTotalNrOption,
  dTotalSingleOption,
  enableATotal,
  enableBTotalOption,
  enableD365TotalOption,
  enableDTotalOption,
  enableETotalOption,
  eTotalAmOption,
  eTotalNrOption,
  eTotalSingleOption,
  initialValues,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const AccountLimitsPaymentPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currencyType = useProductCurrencyType(wizardResponse.product?.productValues);

  const { showPerDay, showPerBilling, showPer365, showForever } = useMemo(() => {
    const pctValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues;

    return {
      pctValues,
      showPerDay: pctValues?.find(x => x.fieldCode === PAYM_LIM_PERIODS)?.value?.includes("D"),
      showPerBilling: pctValues?.find(x => x.fieldCode === PAYM_LIM_PERIODS)?.value?.includes("B"),
      showPer365: pctValues?.find(x => x.fieldCode === PAYM_LIM_PERIODS)?.value?.includes("Y"),
      showForever: pctValues?.find(x => x.fieldCode === PAYM_LIM_PERIODS)?.value?.includes("F"),
    };
  }, [wizardResponse?.product?.parameterTables]);

  const rules = useMemo(() => {
    const rules: DynamicSwitchRule[] = [];

    if (showPerDay) {
      rules.push({
        name: "d-paym-a-total-enabled",
        label: "Per Day",
        tooltip: "Switch it off if restrictions are not intended per calendar day (from 00:00:00 to 23:59:59).",
        maxNumber: "nic-d-paym-a-total-nr",
        maxAmount: "nic-d-paym-a-total-am",
        maxSingleAmount: "nic-d-paym-a-total-single",
      });
    }

    if (showPerBilling) {
      rules.push({
        name: "b-paym-a-total-enabled",
        label: "Per Billing",
        tooltip: "Switch it off if restrictions are not intended per billing cycle.",
        maxNumber: "nic-b-paym-a-total-nr",
        maxAmount: "nic-b-paym-a-total-am",
        maxSingleAmount: "nic-b-paym-a-total-single",
      });
    }

    if (showPer365) {
      rules.push({
        name: "d365-paym-a-total-enabled",
        label: "Per 365 Days",
        tooltip:
          "365 sliding days, means that the limiter calculates transactions for 365 days before the current transaction was made. Switch it off if restrictions are not intended.",
        maxNumber: "nic-d365-paym-a-total-nr",
        maxAmount: "nic-d365-paym-a-total-am",
        maxSingleAmount: "nic-d365-paym-a-total-single",
      });
    }

    if (showForever) {
      rules.push({
        name: "e-paym-a-total-enabled",
        label: "Forever",
        tooltip:
          "No frequency cycle is set for the limiter; counter contents accumulate constantly, and counters do not reset to zero. Switch it off if restrictions are not intended.",
        maxNumber: "nic-e-paym-a-total-nr",
        maxAmount: "nic-e-paym-a-total-am",
        maxSingleAmount: "nic-e-paym-a-total-single",
      });
    }
    return rules;
  }, [showForever, showPer365, showPerBilling, showPerDay]);

  useHydrateForm({
    form,
    entityFields: [...initialValues, ...(wizardResponse.product?.parameterTables?.[0].pctProductValues ?? [])],
    keys: {
      strings: [
        enableATotal,
        enableDTotalOption,
        dTotalNrOption,
        dTotalAmOption,
        dTotalSingleOption,
        enableBTotalOption,
        bTotalNrOption,
        bTotalAmOption,
        bTotalSingleOption,
        enableD365TotalOption,
        d365TotalNrOption,
        d365TotalAmOption,
        d365TotalSingleOption,
        enableETotalOption,
        eTotalNrOption,
        eTotalAmOption,
        eTotalSingleOption,
      ],
    },
    allowParse: false,
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Account Limits - Payment"
      pageSubtitle="Account limits govern payments to cards related to the account and payments to the account itself. Both primary and supplementary card loads contribute towards the account limits"
      size="md"
      formSize="lg"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <DynamicSwitchForm
        form={form}
        currency={currencyType}
        name="paym-a-total-enabled"
        title="Account Limits"
        rules={rules}
        disabled={formDisabled}
      />
    </CustomFormWrapper>
  );
};
