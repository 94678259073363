interface Identifiable {
  id?: number;
}

export const replaceObjectById = <T extends Identifiable>(list: T[], newObject: T): T[] => {
  return list.map(item => {
    if (item.id === newObject.id) return newObject;

    return item;
  });
};
