import { useEffect, useMemo, useState } from "react";
import { Button } from "antd";

import { useReduxState } from "@ni/common/hooks";

import styles from "./styles.module.scss";

interface Props {
  length?: number;
  text: string;
}

const ReadMore = ({ length = 256, text }: Props) => {
  const [isPrintMode] = useReduxState<boolean>("isPrintMode");
  const [toggled, setToggled] = useState<boolean>(false);

  useEffect(() => {
    setToggled(isPrintMode);
  }, [isPrintMode]);

  const truncatedText = useMemo(() => {
    return text.length > length ? `${text.slice(0, length)}...` : text;
  }, [text, length]);

  return (
    <div>
      <p className={styles["rm-paragraph"]}>{toggled ? text : truncatedText}</p>

      {!isPrintMode && text.length > length && (
        <Button size="small" type="link" onClick={() => setToggled(prev => !prev)} className={styles["rm-button"]}>
          Read {toggled ? "less" : "more"}
        </Button>
      )}
    </div>
  );
};

export { ReadMore };
