/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchChangePagesRequest } from '../model';
// @ts-ignore
import { ChangePageRequest } from '../model';
// @ts-ignore
import { CopyPageRequest } from '../model';
// @ts-ignore
import { CreatePageRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { Page } from '../model';
// @ts-ignore
import { PlainPage } from '../model';
/**
 * PageApi - axios parameter creator
 * @export
 */
export const PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the copied page
         * @summary Copy Page
         * @param {CopyPageRequest} copyPageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPage: async (copyPageRequest: CopyPageRequest, workflowId: number, phaseId: number, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyPageRequest' is not null or undefined
            assertParamExists('copyPage', 'copyPageRequest', copyPageRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('copyPage', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('copyPage', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('copyPage', 'pageId', pageId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}/copy`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a page
         * @summary Create Page
         * @param {CreatePageRequest} createPageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPage: async (createPageRequest: CreatePageRequest, workflowId: number, phaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPageRequest' is not null or undefined
            assertParamExists('createPage', 'createPageRequest', createPageRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createPage', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('createPage', 'phaseId', phaseId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Page
         * @summary Delete Page by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePage: async (workflowId: number, phaseId: number, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('deletePage', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('deletePage', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('deletePage', 'pageId', pageId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edits a page
         * @summary Edit page
         * @param {ChangePageRequest} changePageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPage: async (changePageRequest: ChangePageRequest, workflowId: number, phaseId: number, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePageRequest' is not null or undefined
            assertParamExists('editPage', 'changePageRequest', changePageRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('editPage', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('editPage', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('editPage', 'pageId', pageId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Pages by phaseId
         * @summary Get Pages
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPages: async (workflowId: number, phaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getPages', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('getPages', 'phaseId', phaseId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Batch Patching of Pages
         * @summary Batch Patching of Pages
         * @param {BatchChangePagesRequest} batchChangePagesRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPages: async (batchChangePagesRequest: BatchChangePagesRequest, workflowId: number, phaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchChangePagesRequest' is not null or undefined
            assertParamExists('putPages', 'batchChangePagesRequest', batchChangePagesRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('putPages', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('putPages', 'phaseId', phaseId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchChangePagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageApi - functional programming interface
 * @export
 */
export const PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the copied page
         * @summary Copy Page
         * @param {CopyPageRequest} copyPageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyPage(copyPageRequest: CopyPageRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyPage(copyPageRequest, workflowId, phaseId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a page
         * @summary Create Page
         * @param {CreatePageRequest} createPageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPage(createPageRequest: CreatePageRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPage(createPageRequest, workflowId, phaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Page
         * @summary Delete Page by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePage(workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePage(workflowId, phaseId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edits a page
         * @summary Edit page
         * @param {ChangePageRequest} changePageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPage(changePageRequest: ChangePageRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editPage(changePageRequest, workflowId, phaseId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Pages by phaseId
         * @summary Get Pages
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPages(workflowId: number, phaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Page>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPages(workflowId, phaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Batch Patching of Pages
         * @summary Batch Patching of Pages
         * @param {BatchChangePagesRequest} batchChangePagesRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPages(batchChangePagesRequest: BatchChangePagesRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlainPage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPages(batchChangePagesRequest, workflowId, phaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageApi - factory interface
 * @export
 */
export const PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageApiFp(configuration)
    return {
        /**
         * Returns the copied page
         * @summary Copy Page
         * @param {CopyPageRequest} copyPageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyPage(copyPageRequest: CopyPageRequest, workflowId: number, phaseId: number, pageId: number, options?: any): AxiosPromise<Page> {
            return localVarFp.copyPage(copyPageRequest, workflowId, phaseId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a page
         * @summary Create Page
         * @param {CreatePageRequest} createPageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPage(createPageRequest: CreatePageRequest, workflowId: number, phaseId: number, options?: any): AxiosPromise<Page> {
            return localVarFp.createPage(createPageRequest, workflowId, phaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Page
         * @summary Delete Page by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePage(workflowId: number, phaseId: number, pageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePage(workflowId, phaseId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edits a page
         * @summary Edit page
         * @param {ChangePageRequest} changePageRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPage(changePageRequest: ChangePageRequest, workflowId: number, phaseId: number, pageId: number, options?: any): AxiosPromise<Page> {
            return localVarFp.editPage(changePageRequest, workflowId, phaseId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Pages by phaseId
         * @summary Get Pages
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPages(workflowId: number, phaseId: number, options?: any): AxiosPromise<Array<Page>> {
            return localVarFp.getPages(workflowId, phaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Batch Patching of Pages
         * @summary Batch Patching of Pages
         * @param {BatchChangePagesRequest} batchChangePagesRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPages(batchChangePagesRequest: BatchChangePagesRequest, workflowId: number, phaseId: number, options?: any): AxiosPromise<Array<PlainPage>> {
            return localVarFp.putPages(batchChangePagesRequest, workflowId, phaseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageApi - interface
 * @export
 * @interface PageApi
 */
export interface PageApiInterface {
    /**
     * Returns the copied page
     * @summary Copy Page
     * @param {CopyPageRequest} copyPageRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApiInterface
     */
    copyPage(copyPageRequest: CopyPageRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): AxiosPromise<Page>;

    /**
     * Creates a page
     * @summary Create Page
     * @param {CreatePageRequest} createPageRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApiInterface
     */
    createPage(createPageRequest: CreatePageRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): AxiosPromise<Page>;

    /**
     * Deletes Page
     * @summary Delete Page by Id
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApiInterface
     */
    deletePage(workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Edits a page
     * @summary Edit page
     * @param {ChangePageRequest} changePageRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApiInterface
     */
    editPage(changePageRequest: ChangePageRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): AxiosPromise<Page>;

    /**
     * Get Pages by phaseId
     * @summary Get Pages
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApiInterface
     */
    getPages(workflowId: number, phaseId: number, options?: AxiosRequestConfig): AxiosPromise<Array<Page>>;

    /**
     * Batch Patching of Pages
     * @summary Batch Patching of Pages
     * @param {BatchChangePagesRequest} batchChangePagesRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApiInterface
     */
    putPages(batchChangePagesRequest: BatchChangePagesRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig): AxiosPromise<Array<PlainPage>>;

}

/**
 * PageApi - object-oriented interface
 * @export
 * @class PageApi
 * @extends {BaseAPI}
 */
export class PageApi extends BaseAPI implements PageApiInterface {
    /**
     * Returns the copied page
     * @summary Copy Page
     * @param {CopyPageRequest} copyPageRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public copyPage(copyPageRequest: CopyPageRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).copyPage(copyPageRequest, workflowId, phaseId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a page
     * @summary Create Page
     * @param {CreatePageRequest} createPageRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public createPage(createPageRequest: CreatePageRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).createPage(createPageRequest, workflowId, phaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Page
     * @summary Delete Page by Id
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public deletePage(workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).deletePage(workflowId, phaseId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edits a page
     * @summary Edit page
     * @param {ChangePageRequest} changePageRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public editPage(changePageRequest: ChangePageRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).editPage(changePageRequest, workflowId, phaseId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Pages by phaseId
     * @summary Get Pages
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public getPages(workflowId: number, phaseId: number, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).getPages(workflowId, phaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Batch Patching of Pages
     * @summary Batch Patching of Pages
     * @param {BatchChangePagesRequest} batchChangePagesRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public putPages(batchChangePagesRequest: BatchChangePagesRequest, workflowId: number, phaseId: number, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).putPages(batchChangePagesRequest, workflowId, phaseId, options).then((request) => request(this.axios, this.basePath));
    }
}
