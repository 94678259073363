import { useCallback, useEffect, useState } from "react";

import { useReduxState } from "@ni/common/hooks";
import { LastWiazrdSubmittedStep } from "@ni/common/types";
import { getPhaseAndPageOrder } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { Phase, Product, Tenant, TenantProductWizardResponse, Workflow } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const useWizard = () => {
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [phases, setPhases] = useReduxState<Phase[]>("workflowPhases", []);

  const [lastSubmittedStep, setLastSubmittedStep] = useState<LastWiazrdSubmittedStep>({
    phase: 0,
    pageKey: "",
    currentEntityId: undefined,
  });

  const initWizard = async (tenantId?: string, productId?: string) => {
    try {
      const response = await wizardServicesApi.initWizard(tenantId ? { tenantId: parseInt(tenantId, 10) } : {});

      return {
        pageId: response.data.pageId,
        workflow: response.data.workflow,
        tenant: response.data.tenant!,
        products: response.data.tenant?.products ?? [],
        product: productId
          ? (response.data.tenant?.products ?? []).find(product => product.id === parseInt(productId, 10))!
          : null,
      };
    } catch (error) {
      return {
        pageId: 0,
        workflow: {} as Workflow,
        products: [] as Product[],
        product: {} as Product,
        tenant: {} as Tenant,
      };
    }
  };

  const processWizardRequest = useCallback(
    async (tenantId?: string, productId?: string) => {
      const { pageId, tenant, product, workflow } = await initWizard(tenantId, productId);
      if (!productId) {
        setWizardResponse({ pageId, tenant, workflow });
      }

      if (tenantId && productId) {
        const processWizardResponse = await wizardServicesApi.processWizardRequest({
          pageId: product?.lastProcessedPage?.id,
          tenantId: parseInt(tenantId, 10),
          productId: product!.id,
          collectedValues: {},
        });

        const response = {
          product: product!,
          workflow,
          ...processWizardResponse.data,
        };

        setWizardResponse(response);
      }
    },
    [setWizardResponse],
  );

  useEffect(() => {
    if (wizardResponse.workflow?.phases) {
      const wizardPageId = wizardResponse.pageId!;
      setPhases(wizardResponse.workflow?.phases ?? []);

      const phaseAndPage = getPhaseAndPageOrder(wizardResponse.workflow?.phases, "", wizardPageId);
      setLastSubmittedStep({
        pageKey: phaseAndPage?.page.code as string,
        phase: phaseAndPage?.phaseOrder as number,
        currentEntityId: wizardResponse.product?.id,
      });
    }
  }, [setPhases, wizardResponse.pageId, wizardResponse.product?.id, wizardResponse.workflow?.phases]);

  useEffect(() => {
    return () => {
      setWizardResponse({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lastSubmittedStep,
    phases,
    processWizardRequest,
  };
};
