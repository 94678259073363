import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { colors } from "@ni/common/constants";

const IconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 6L15.2929 11.2929C15.6834 11.6834 15.6834 12.3166 15.2929 12.7071L10 18"
      stroke={colors.lightColor}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const ChevronRightIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
