import { WizardPageMock } from "@ni/common/types";

import {
  Confirmation,
  LoyaltyApplying,
  LoyaltyPointsPage,
  PointAccrualCalculation,
  PointAccrualCalculationBillingCycle,
  PointAccrualSetup,
  PointExpiry,
  RedemptionRate,
  RedemptionSetup,
  TemplateInitiationPage,
  TransactionCountry,
  TransactionCriteria,
  TransactionMerchantCategory,
  TransactionMerchantId,
  TransactionMerchantTerminal,
  WelcomeBonus,
} from "../pages";
import { PointAccrualCalculationYerlyCumulativeCycle } from "../pages/4.2_LTY_PNT_ACCR_Y_C";

export const WIZARD_PAGES: WizardPageMock[] = [
  { pageKey: "LTY_INIT", component: TemplateInitiationPage },
  { pageKey: "LTY_PNT", component: LoyaltyPointsPage },
  { pageKey: "LTY_TRANS_CRT", component: TransactionCriteria },
  { pageKey: "LTY_TRANS_CNTR", component: TransactionCountry },
  { pageKey: "LTY_TRANS_MCC", component: TransactionMerchantCategory },
  { pageKey: "LTY_TRANS_MERCH", component: TransactionMerchantId },
  { pageKey: "LTY_TRANS_TERM", component: TransactionMerchantTerminal },
  { pageKey: "LTY_ACRL", component: PointAccrualSetup },
  { pageKey: "LTY_PNT_ACCR", component: PointAccrualCalculation },
  { pageKey: "LTY_ACCR_RATE_B", component: PointAccrualCalculationBillingCycle },
  { pageKey: "LTY_ACCR_RATE_Y", component: PointAccrualCalculationYerlyCumulativeCycle },
  { pageKey: "LTY_REDEEM", component: RedemptionSetup },
  { pageKey: "LTY_REDEEM_RATE", component: RedemptionRate },
  { pageKey: "LTY_EXPR", component: PointExpiry },
  { pageKey: "LTY_WELC_BONUS", component: WelcomeBonus },
  { pageKey: "LTY_APPLY", component: LoyaltyApplying },
  { pageKey: "LTY_SUCCESS", component: Confirmation },
];
