/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import React, { ComponentProps, useCallback, useMemo, useState } from "react";
import { Form, FormInstance, GetRef, Input as AntdInput, notification, Select as AntdSelect } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import cn from "classnames";

import { FormValues } from "@ni/common/types";

import { NetworkForm } from "../FormInput";

import { RenderCellValue, SMCRecord } from "./RenderCellValue";

import styles from "./styles.module.scss";

interface Props extends ComponentProps<"td"> {
  form: FormInstance<FormValues>;
  dataIndex: string;
  editable: boolean;
  record: SMCRecord;
  disabled: boolean;
}

const EditableCell = React.memo(({ form, dataIndex, editable, record, disabled, children, ...restProps }: Props) => {
  const [editing, setEditing] = useState<boolean>(false);

  const { name, input } = useMemo(
    () => ({
      name: `${dataIndex}@${record?.fieldCode}`,
      input: record?.input,
    }),
    [dataIndex, record?.fieldCode, record?.input],
  );

  const save = useCallback(async () => {
    try {
      await form.validateFields();
      setEditing(false);
    } catch {
      notification.error({
        placement: "topRight",
        duration: 10,
        message: "Error",
        description: "Something went wrong. Please try again Later.",
      });
    }
  }, [form]);

  const discard = useCallback(() => {
    setEditing(false);
    form.resetFields([name]);
  }, [form, name]);

  const renderInputNode = () => {
    type FocusEvent = GetRef<typeof AntdSelect> | GetRef<typeof AntdInput> | HTMLInputElement | null;

    const commonProps = {
      ref: (e: FocusEvent) => editing && e?.focus(),
      size: "small" as SizeType,
      onBlur: save,
      onPressEnter: save,
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === "Escape") discard();
      },
    };

    switch (input?.type) {
      case "select":
        return (
          <NetworkForm.Select
            {...commonProps}
            optionList={input.options.map(({ label, value }) => ({ label, value }))}
            popupMatchSelectWidth={false}
            className={styles["table-edit-select"]}
          />
        );
      case "number":
        return <NetworkForm.Number {...commonProps} min={0} max={100} className={styles["table-edit-number"]} />;
      default:
        return <NetworkForm.String {...commonProps} autoComplete="off" className={styles["table-edit-input"]} />;
    }
  };

  return editable && !disabled ? (
    <td
      {...restProps}
      className={cn(restProps?.className, editing && styles["is-editing"])}
      onClick={() => setEditing(true)}
    >
      {editing ? (
        <Form.Item name={name} className="m-b-0">
          {renderInputNode()}
        </Form.Item>
      ) : (
        <RenderCellValue form={form} name={name} record={record} />
      )}
    </td>
  ) : (
    <td {...restProps} className={cn(restProps?.className, disabled && styles["is-disabled"])}>
      {children}
    </td>
  );
});

export { EditableCell };
