import { FC, useEffect } from "react";
import { Form } from "antd";

import {
  LTY_WELC_BONUS_ENABLE,
  NIC_LTY_BONUS_W_TAR_BASE,
  NIC_LTY_BONUS_W_TIMER_FROM,
  NIC_LTY_BONUS_W_TIMER_PER,
  NIC_LTY_BONUS_W_TIMER_PER_TYPE,
} from "@ni/common/constants";
import { FormContent, NetworkForm } from "@ni/common/ui";
import { validateNumberInput } from "@ni/common/utils";

import { GeneralSettingsPageProps } from "../../../types";

import { RADIO_GROUP_TIMER_FROM, RADIO_GROUP_TIMER_PER_TYPE } from "./WelcomeBonus.constants";

export const WelcomeBonus: FC<GeneralSettingsPageProps> = ({ form }) => {
  const isWelcomeBonusOn = Form.useWatch(LTY_WELC_BONUS_ENABLE, form);

  useEffect(() => {
    if (isWelcomeBonusOn) {
      if (!form.getFieldValue(NIC_LTY_BONUS_W_TAR_BASE)) {
        form.setFieldValue(NIC_LTY_BONUS_W_TAR_BASE, 0);
      }

      if (!form.getFieldValue(NIC_LTY_BONUS_W_TIMER_FROM)) {
        form.setFieldValue(NIC_LTY_BONUS_W_TIMER_FROM, RADIO_GROUP_TIMER_FROM[0].value);
      }
      if (!form.getFieldValue(NIC_LTY_BONUS_W_TIMER_PER_TYPE)) {
        form.setFieldValue(NIC_LTY_BONUS_W_TIMER_PER_TYPE, RADIO_GROUP_TIMER_PER_TYPE[0].value);
      }
    }
  }, [form, isWelcomeBonusOn]);

  return (
    <FormContent gap={40}>
      <div>
        New customers could receive a certain number of bonus points upon signing up for the loyalty program or after a
        fixed period of time.
      </div>

      <NetworkForm.Switch
        formItemOptions={{
          valuePropName: "checked",
          name: LTY_WELC_BONUS_ENABLE,
          label: "Enable welcome bonus on your loyalty program",
        }}
      />

      {isWelcomeBonusOn && (
        <NetworkForm.Number
          min={0}
          precision={0}
          formItemOptions={{
            name: NIC_LTY_BONUS_W_TAR_BASE,
            label: "Welcome bonus amount (number of points)",
            rules: [
              () => ({
                validator(_, value) {
                  return validateNumberInput(value as string | number | undefined);
                },
              }),
            ],
          }}
        />
      )}

      {isWelcomeBonusOn && (
        <NetworkForm.Radio
          radioList={RADIO_GROUP_TIMER_FROM}
          formItemOptions={{
            name: NIC_LTY_BONUS_W_TIMER_FROM,
            label: "Welcome bonus timer trigger (count down starts from):",
          }}
        />
      )}

      {isWelcomeBonusOn && (
        <NetworkForm.Radio
          radioList={RADIO_GROUP_TIMER_PER_TYPE}
          formItemOptions={{
            name: NIC_LTY_BONUS_W_TIMER_PER_TYPE,
            label: "Welcome bonus count down timer period",
          }}
        />
      )}

      {isWelcomeBonusOn && (
        <NetworkForm.Number
          min={0}
          precision={0}
          formItemOptions={{
            name: NIC_LTY_BONUS_W_TIMER_PER,
            label: "Welcome bonus count down timer (number of periods)",
          }}
        />
      )}
    </FormContent>
  );
};
