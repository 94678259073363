import { LoyaltyProgram, ShortLoyaltyProgram } from "@ni/sdk/models";

export const sortLoyaltyPrograms = (programs: LoyaltyProgram[] | ShortLoyaltyProgram[]) =>
  [...programs].sort((a, b) => {
    const getCodePosition = (code?: string) => {
      if (!code) return 4;
      if (code.startsWith("P")) return 1;
      if (code.startsWith("C")) return 2;
      return 3;
    };

    const codePositionA = getCodePosition(a.loyaltyCode);
    const codePositionB = getCodePosition(b.loyaltyCode);

    if (codePositionA !== codePositionB) {
      return codePositionA - codePositionB;
    }

    const codeNumberA = a.loyaltyCode ? parseInt(a.loyaltyCode.substring(1), 10) : 0;
    const codeNumberB = b.loyaltyCode ? parseInt(b.loyaltyCode.substring(1), 10) : 0;

    return codeNumberA - codeNumberB;
  });
