import { FC, useCallback, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { AxiosResponse } from "axios";

import { useReduxState } from "@ni/common/hooks";
import { Spin } from "@ni/common/ui";
import { ConfigurationApi } from "@ni/sdk/apis";
import { EventGroup } from "@ni/sdk/models";

import { EmptyTemplates } from "./components/EmptyTemplates";
import { Templates } from "./components/Templates";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();

export const SmsTemplates: FC = () => {
  const [mounted, setMounted] = useState<boolean>(true);
  const [smsTemplates, setSmsTemplates] = useReduxState<EventGroup[]>("smsTemplates");
  const [isLoading, setIsLoading] = useReduxState<boolean>("isSMSTemplateLoading", false);

  const getEventGroups = useCallback((): void => {
    setIsLoading(true);

    configurationServiceApi
      .getEventGroups()
      .then((response: AxiosResponse<EventGroup[]>) => {
        setSmsTemplates(response.data);

        if (response.data.length < 1) {
          setMounted(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, setSmsTemplates]);

  useEffect(() => {
    if (!smsTemplates) {
      getEventGroups();
    }
  }, [getEventGroups, smsTemplates]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <ConfigProvider componentSize="middle">
      <div className={styles["sms-templates"]}>
        {!mounted && <EmptyTemplates />}
        {mounted && <Templates getEventGroups={getEventGroups} smsTemplates={smsTemplates} />}
      </div>
    </ConfigProvider>
  );
};
