import { FC } from "react";
import { Form, Space, Switch } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { useProductCurrencyType } from "../../hooks";

import {
  canChargeWhenIncreasingPermanentLimit,
  canChargeWhenTemporaryExceedsPermanent,
  permanentLimitIncreaseFeeAmount,
  temporaryLimitFeeAmount,
} from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const CreditLimitModificationFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.parameterTables![0].pctProductValues ?? [],
    keys: {
      strings: [
        canChargeWhenIncreasingPermanentLimit.code,
        canChargeWhenTemporaryExceedsPermanent.code,
        permanentLimitIncreaseFeeAmount.code,
        temporaryLimitFeeAmount.code,
      ],
    },
    allowParse: true,
  });

  const hasChargeWhenTemporaryExceed = Form.useWatch<boolean>(canChargeWhenTemporaryExceedsPermanent.code, form);
  const hasChargeWhenIncreasingPermanent = Form.useWatch<boolean>(canChargeWhenIncreasingPermanentLimit.code, form);

  const currencyType = useProductCurrencyType(wizardResponse.product?.productValues);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        [temporaryLimitFeeAmount.code]: "",
        [permanentLimitIncreaseFeeAmount.code]: "",
        ...values,
      } as { [key: string]: string },
    };
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Credit Limit Modification Fees"
      pageSubtitle="Credit limit modification fee can be charged when a client requests an increase or decrease in their permanent or temporary credit limit."
      size="md"
      formSize="full"
      gap={30}
      level="root"
      submitHandler={onFinish}
    >
      <Space direction="horizontal">
        <Form.Item
          name={canChargeWhenTemporaryExceedsPermanent.code}
          valuePropName="checked"
          initialValue={canChargeWhenTemporaryExceedsPermanent.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={canChargeWhenTemporaryExceedsPermanent.name}
          code={canChargeWhenTemporaryExceedsPermanent.code}
          tooltipProps={{
            title: canChargeWhenTemporaryExceedsPermanent.tooltip,
          }}
        />
      </Space>
      {hasChargeWhenTemporaryExceed && (
        <Form.Item
          name={temporaryLimitFeeAmount.code}
          label={<FormItemLabel label={temporaryLimitFeeAmount.name} code={temporaryLimitFeeAmount.code} />}
          rules={[
            {
              validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
              message: "Minimum fee amount can not be 0",
            },
            { required: true, message: "Temporary Limit Fee Amount is required!" },
          ]}
        >
          <NetworkForm.Number
            addonAfter={currencyType}
            min={temporaryLimitFeeAmount.min}
            precision={temporaryLimitFeeAmount.decimalsNumber}
          />
        </Form.Item>
      )}

      <Space direction="horizontal">
        <Form.Item
          name={canChargeWhenIncreasingPermanentLimit.code}
          valuePropName="checked"
          initialValue={canChargeWhenIncreasingPermanentLimit.default}
        >
          <Switch />
        </Form.Item>
        <TooltipInfo
          label={canChargeWhenIncreasingPermanentLimit.name}
          code={canChargeWhenIncreasingPermanentLimit.code}
          tooltipProps={{
            title: canChargeWhenIncreasingPermanentLimit.tooltip,
          }}
        />
      </Space>
      {hasChargeWhenIncreasingPermanent && (
        <Form.Item
          name={permanentLimitIncreaseFeeAmount.code}
          label={
            <FormItemLabel label={permanentLimitIncreaseFeeAmount.name} code={permanentLimitIncreaseFeeAmount.code} />
          }
          rules={[
            {
              validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
              message: "Minimum fee amount can not be 0",
            },
            { required: true, message: "Permanent Limit Increase Fee Amount is required!" },
          ]}
        >
          <NetworkForm.Number
            addonAfter={currencyType}
            min={permanentLimitIncreaseFeeAmount.min}
            precision={permanentLimitIncreaseFeeAmount.decimalsNumber}
          />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
