import React, { FC } from "react";

import { reportsPage } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import {
  AfterWizardEditPageTitle,
  DocumentationList,
  FileCardListWrapper,
  PageItemLayoutElements,
  PageItemLayoutGeneral,
  TooltipInfo,
} from "@ni/common/ui";
import { Tenant } from "@ni/sdk/models";

export const ReportsPage: FC = () => {
  const [tenant] = useReduxState<Tenant>("tenant", {});
  const cardLists = reportsPage;

  return (
    <FileCardListWrapper>
      <PageItemLayoutGeneral>
        <PageItemLayoutElements>
          <AfterWizardEditPageTitle title="Reports" />
          <div>
            <TooltipInfo
              largeLabel="Reports are the integral part of the system which provides various information on the activities happened or
            carried out by user or through interfaces."
              tooltipProps={{
                title: (
                  <>
                    <span>Reports are usually generated for the following reasons:</span>
                    <ul>
                      <li>
                        Success and Reject reports corresponding to incoming interface like account boarding, payment
                        file, etc.
                      </li>
                      <li>
                        User activities done on the accounts including both financial and demographic maintenance.
                      </li>
                      <li>
                        Standard reports showing the information like transactions processed in the last batch, etc.
                      </li>
                    </ul>
                  </>
                ),
              }}
            />
          </div>
        </PageItemLayoutElements>
        <DocumentationList documentationList={cardLists} data={tenant} />
      </PageItemLayoutGeneral>
    </FileCardListWrapper>
  );
};
