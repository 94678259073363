/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Import tenants from way4
         * @summary Import tenants from way4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/imports/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Import tenant by external code
         * @summary Import tenant by external code
         * @param {string} fi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOne: async (fi: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fi' is not null or undefined
            assertParamExists('importOne', 'fi', fi)
            const localVarPath = `/api/v1/imports/tenants/{fi}`
                .replace(`{${"fi"}}`, encodeURIComponent(String(fi)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration)
    return {
        /**
         * Import tenants from way4
         * @summary Import tenants from way4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Import tenant by external code
         * @summary Import tenant by external code
         * @param {string} fi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importOne(fi: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importOne(fi, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportApiFp(configuration)
    return {
        /**
         * Import tenants from way4
         * @summary Import tenants from way4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAll(options?: any): AxiosPromise<object> {
            return localVarFp.importAll(options).then((request) => request(axios, basePath));
        },
        /**
         * Import tenant by external code
         * @summary Import tenant by external code
         * @param {string} fi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOne(fi: string, options?: any): AxiosPromise<object> {
            return localVarFp.importOne(fi, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportApi - interface
 * @export
 * @interface ImportApi
 */
export interface ImportApiInterface {
    /**
     * Import tenants from way4
     * @summary Import tenants from way4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importAll(options?: AxiosRequestConfig): AxiosPromise<object>;

    /**
     * Import tenant by external code
     * @summary Import tenant by external code
     * @param {string} fi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApiInterface
     */
    importOne(fi: string, options?: AxiosRequestConfig): AxiosPromise<object>;

}

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI implements ImportApiInterface {
    /**
     * Import tenants from way4
     * @summary Import tenants from way4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importAll(options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Import tenant by external code
     * @summary Import tenant by external code
     * @param {string} fi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public importOne(fi: string, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).importOne(fi, options).then((request) => request(this.axios, this.basePath));
    }
}
