import { useCallback, useEffect } from "react";

import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { InsuranceTemplateApi } from "@ni/sdk/apis";
import {
  ChangeInsuranceProgramTemplateRequest,
  InsuranceProgramTemplate,
  InsuranceProgramTemplateValue,
} from "@ni/sdk/models";

const insuranceApi = new InsuranceTemplateApi();

export const useInsuranceApi = (id?: number) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [currentInsurance, setCurrentInsuarnce] = useReduxState<InsuranceProgramTemplate>("currentInsuranceEdit");

  const formValuesConverter = useCallback((formValues: FormValues): InsuranceProgramTemplateValue[] => {
    return Object.keys(formValues)?.map(
      (key: string) => ({ code: key, value: formValues[key] }) as InsuranceProgramTemplateValue,
    );
  }, []);

  const applyToProducts = useCallback(
    async (ids: Set<number>) => insuranceApi.applyToProducts1({ productIds: ids }, currentInsurance?.id ?? 0),
    [currentInsurance?.id],
  );

  const editInsurance = useCallback(
    async (insurance: Partial<ChangeInsuranceProgramTemplateRequest>) => {
      if (currentInsurance?.id) {
        setIsLoading(true);
        try {
          const response = await insuranceApi.edit4(insurance, currentInsurance?.id);
          setCurrentInsuarnce({ ...currentInsurance, ...response.data });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    },
    [currentInsurance, setCurrentInsuarnce, setIsLoading],
  );

  useEffect(() => {
    const getPlanById = async () => {
      try {
        const insurance = (await insuranceApi.getById3(id ?? 0)).data;
        setCurrentInsuarnce(insurance);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    if (id && (!currentInsurance || currentInsurance.id !== id)) {
      setIsLoading(true);
      void getPlanById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { currentInsurance, editInsurance, applyToProducts, formValuesConverter };
};
