import { RoutesTreeItem } from "@ni/common/types";

import {
  CancellationSettings,
  CoverageSettings,
  Identification,
  InsuranceApplyingProducts,
  PremiumSettings,
} from "../../../pages";

export const pagesRoute: RoutesTreeItem[] = [
  { name: "Insurance Template Identification", route: "insurance-identification", element: <Identification /> },
  { name: "Insurance Coverage Settings", route: "coverage-settings", element: <CoverageSettings /> },
  { name: "Insurance Premium Settings", route: "premium-settings", element: <PremiumSettings /> },
  { name: "Insurance Cancellation Settings", route: "cancellation-settings", element: <CancellationSettings /> },
  { name: "Applying to Products", route: "applying-to-products", element: <InsuranceApplyingProducts /> },
];
