import { FC, useCallback, useEffect, useState } from "react";
import { Button, Table, TableColumnType } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { useParams } from "react-router-dom";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { DashboardFilters } from "@ni/common/types";
import { InviteModal, TooltipInfo } from "@ni/common/ui";
import { mapToServerSorting, UserService } from "@ni/common/utils";
import { FinancialInstitutionApi, UserApi } from "@ni/sdk/apis";
import { InviteUserRequest, Order, SortedFilteredPageRequest, User } from "@ni/sdk/models";

import UserDrawer from "../UserDrawer";

import styles from "./styles.module.scss";

const userServiceApi = new UserApi();
const financialInstitutionServiceApi = new FinancialInstitutionApi();

interface UsersTableViewProps {
  filters: DashboardFilters;
}

const UsersTableView: FC<UsersTableViewProps> = ({ filters }) => {
  const [fiUsers, setFiUsers] = useReduxState<User[]>("fiUsers", []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedPage] = useState<User>();

  const [tablePageConfig, setTablePageConfig] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });
  const [dashboardPageConfig, setDashboardPageConfig] = useState<SortedFilteredPageRequest>();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const getUsers = useCallback((): void => {
    if (dashboardPageConfig && Object.keys(dashboardPageConfig)) {
      setIsLoading(true);

      financialInstitutionServiceApi
        .getUsersByFinancialInstitutionId(dashboardPageConfig, Number(id))
        .then(response => {
          setTablePageConfig({
            ...tablePageConfig,
            current: (dashboardPageConfig.pageLimits?.number as number) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setFiUsers(response.data.content as User[]);
          UserService.updateToken()
            .then(() => {})
            .catch(() => {});
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [dashboardPageConfig, id, setFiUsers, tablePageConfig]);

  useEffect(() => {
    getUsers();
  }, [dashboardPageConfig]);

  useEffect(() => {
    setDashboardPageConfig({
      ...filters,
      sorting: dashboardPageConfig?.sorting as Order[],
      pageLimits: {
        size: tablePageConfig.pageSize,
        number: 0,
      },
    });
  }, [filters]);

  const onOpenDrawer = (value: User) => {
    setVisible(true);
    setSelectedPage(value);
  };

  const formatData = (value: string) => {
    const search = "T";
    const replaceWith = " ";

    return value.split(search).join(replaceWith);
  };

  const columns: TableColumnType<User>[] = [
    {
      title: "Full name",
      dataIndex: ["name"],
      key: "name",
      sorter: (a, b) => Number(a.firstName) - Number(b.firstName),
      render: (_: string, item: User) => {
        return (
          <TooltipInfo tooltipProps={{ placement: "topLeft", title: `${item.firstName ?? ""} ${item.lastName ?? ""}` }}>
            {`${item.firstName ?? ""} ${item.lastName ?? ""}`}
          </TooltipInfo>
        );
      },
      ellipsis: true,
    },
    {
      title: "E-mail",
      dataIndex: ["email"],
      key: "email",
      width: 120,
      sorter: (a, b) => Number(a.email) - Number(b.email),
      render: (_: string, item: User) => {
        return <TooltipInfo tooltipProps={{ placement: "topLeft", title: item.email }}>{item.email}</TooltipInfo>;
      },
      ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: ["phone"],
      key: "phone",
      sorter: (a, b) => Number(a.phone) - Number(b.phone),
      render: (_: string, item: User) => {
        return <TooltipInfo tooltipProps={{ placement: "topLeft", title: item.phone }}>{item.phone}</TooltipInfo>;
      },
      ellipsis: true,
    },
    // TODO refactor after new design
    // {
    //   title: "Role",
    //   dataIndex: ["role"],
    //   key: "role",
    //   sorter: (a, b) => Number(a.userRoles) - Number(b.userRoles),
    //   width: 150,
    //   render: (_: string, item: User) => {
    //     return (
    //       <TooltipInfo tooltipProps={{placement:"topLeft" title:item.userRoles}}>
    //         {item.userRoles}
    //       </TooltipInfo>
    //     );
    //   },
    //   ellipsis: true,
    // },
    {
      title: "State",
      dataIndex: ["state"],
      key: "state",
      sorter: (a, b) => Number(a.state) - Number(b.state),
      render: (_: string, item: User) => {
        return <TooltipInfo tooltipProps={{ placement: "topLeft", title: item.state }}>{item.state}</TooltipInfo>;
      },
      ellipsis: true,
    },
    {
      title: "User login",
      dataIndex: ["userLogin"],
      key: "userLogin",
      sorter: (a, b) => Number(a.login) - Number(b.login),
      render: (_: string, item: User) => {
        return <TooltipInfo label={item.login} tooltipProps={{ placement: "topLeft", title: item.login }} />;
      },
      ellipsis: true,
    },
    {
      title: "Last login",
      dataIndex: ["lastLogin"],
      key: "lastLogin",
      sorter: (a, b) => Number(a.lastLogin) - Number(b.lastLogin),
      width: 150,
      render: (_: string, item: User) => {
        return (
          <TooltipInfo
            label={item.lastLogin ? formatData(item.lastLogin) : "-"}
            tooltipProps={{ placement: "topLeft", title: item.lastLogin ? formatData(item.lastLogin) : "-" }}
          />
        );
      },
      ellipsis: true,
    },

    {
      title: "",
      dataIndex: ["edit"],
      key: "edit",
      width: 100,
      render: (_: string, value: User) => {
        return (
          <Button onClick={() => onOpenDrawer(value)} type="link">
            <EditOutlined />
            Edit
          </Button>
        );
      },
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    let sortArray: Order[] = [];
    if (sorter) {
      if (Array.isArray(sorter)) {
        sortArray = sorter
          .filter(sort => sort.order)
          .map(sortObject => ({
            direction: mapToServerSorting(sortObject.order),
            property: sortObject.columnKey as string,
          }));
      } else if (sorter.order) {
        sortArray = [{ property: sorter.columnKey as string, direction: mapToServerSorting(sorter.order) }];
      }
    }

    setDashboardPageConfig({
      ...dashboardPageConfig,
      pageLimits: {
        size: pagination.pageSize,
        number: (pagination.current as number) - 1,
      },
      sorting: sortArray.length ? sortArray : undefined,
    });
  };

  const onCancelInviteModal = () => {
    setIsInviteModalOpen(false);
  };

  const closeDrawerHandler = () => {
    setVisible(false);
  };

  const handleConfirmInvite = (values: InviteUserRequest) => {
    setIsLoading(true);

    userServiceApi
      .sendInvitation({ name: values.name, email: values.email })
      .then(() => {
        onCancelInviteModal();
        UserService.updateToken()
          .then(() => {})
          .catch(() => {});
        setIsLoading(false);
      })
      .catch(() => {
        onCancelInviteModal();
        setIsLoading(false);
      });
  };

  return (
    <div className={styles["fi-table"]}>
      <Table<User>
        className={styles["fi-table-view"]}
        columns={columns}
        dataSource={fiUsers}
        loading={isLoading}
        rowKey={(item: User) => `${String(item?.id)}` || Math.random()}
        onChange={handleTableChange}
        pagination={tablePageConfig}
        onRow={fi => {
          return {
            onDoubleClick: () => {
              onOpenDrawer(fi);
            },
          };
        }}
      />
      <Button
        onClick={() => {
          setIsInviteModalOpen(true);
        }}
        type="link"
      >
        <PlusOutlined />
        Invite new user
      </Button>

      <InviteModal
        title="Send invite"
        description="Please enter the name and an e-mail to invite a new user."
        isOpened={isInviteModalOpen}
        onCancel={onCancelInviteModal}
        onConfirm={handleConfirmInvite}
        isLoading={false}
      />
      <UserDrawer opened={visible} closeDrawer={closeDrawerHandler} user={selectedUser} />
    </div>
  );
};

export default UsersTableView;
