import { SelectOption } from "@ni/common/types";

export const balanceOwners: SelectOption[] = [
  {
    value: "CBS",
    label: "Core Banking System (stored value account)",
    tooltip:
      "The balance is outside of NetworkOne CMS and our switch will forward the request for balance to your external system",
    aliasLabel: "Tenant",
  },
  {
    value: "CMS",
    label: "Card Management System (Network International)",
    aliasLabel: "Network International",
  },
];
