import { ReactNode } from "react";

import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

export const enrollmentModeOption: RadioOptions = {
  code: "ins-enroll-mode",
  name: "Choose program enrollment mode",
  default: "nic-ins-flag-on-board-only",
  tooltip:
    "You can specify whether the auto enrollment will happen during account boarding only or to include account transfers as well.",
  options: [
    {
      label: "Auto enroll during onboarding only",
      value: "nic-ins-flag-on-board-only",
    },
    {
      label: "Auto enroll for both onboarding and account transfers",
      value: "nic-ins-flag-on-board",
    },
  ],
};
