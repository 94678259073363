import { FC } from "react";

import { PercentageOutlined } from "@ant-design/icons";
import { LTY_REDEEM_TYPE, NIC_LTY_RED_RATE_CASH_RATE, NIC_LTY_RED_RATE_EXT_RATE } from "@ni/common/constants";
import { FormContent, NetworkForm } from "@ni/common/ui";
import { LoyaltyProgramTemplate } from "@ni/sdk/models";

import styles from "../../../../pages/pct/styles.module.scss";

interface PointRedemptionProps {
  loyaltyPrograms: LoyaltyProgramTemplate[];
}

export const PointRedemption: FC<PointRedemptionProps> = ({ loyaltyPrograms }) => {
  const isCashbackAvailable = loyaltyPrograms.some(
    program => program.programValues?.find(value => value?.code === LTY_REDEEM_TYPE)?.value === "C",
  );

  const isVoucherAvailable = loyaltyPrograms.some(
    program => program.programValues?.find(value => value?.code === LTY_REDEEM_TYPE)?.value === "E",
  );

  return (
    <FormContent gap={40}>
      <div>
        Loyalty points redemption is the process of utilizing accumulated loyalty points to obtain rewards, benefits, or
        discounts offered by a loyalty program.
      </div>

      {isCashbackAvailable && (
        <NetworkForm.Number
          formItemOptions={{
            name: NIC_LTY_RED_RATE_CASH_RATE,
            label: "Cashback Redemption Rate",
            className: styles["pct-lty-form-item"],
            tooltip:
              "Points can be redeemed and credit (cashback) transaction is posted to customer account based upon the rate defined for cashback redemption",
          }}
          min={0}
          precision={2}
          controls={false}
          suffix={<PercentageOutlined />}
          className="w-p-100"
        />
      )}

      {isVoucherAvailable && (
        <NetworkForm.Number
          formItemOptions={{
            name: NIC_LTY_RED_RATE_EXT_RATE,
            label: "External Voucher Redemption Rate",
            className: styles["pct-lty-form-item"],
            tooltip:
              "Rewards points can be redeemed against voucher amount based upon the rate defined for external redemption",
          }}
          min={0}
          precision={2}
          controls={false}
          suffix={<PercentageOutlined />}
          className="w-p-100"
        />
      )}
    </FormContent>
  );
};
