import React, { FC } from "react";
import { Button, Table, TableColumnType, TablePaginationConfig, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { SettingOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { PageDataFullPlan, Plan } from "@ni/sdk/models";

interface IPPTableProps {
  planList: PageDataFullPlan;
  tenantId: string;
  pagination: TablePaginationConfig;
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
}

export const IPPTable: FC<IPPTableProps> = ({ planList, pagination, setPagination }) => {
  const [isLoading] = useReduxState<boolean>("isLoading", false);

  const {
    id: tenantId,
    productId,
    pctId,
  } = useParams<{
    id: string;
    productId: string;
    pctId: string;
    planId: string;
  }>();

  const navigate = useNavigate();

  const handleEditClick = (record: Plan) => {
    navigate(
      `/tenant/${tenantId}/product/${productId}/pct/${pctId}/installment-payment-plans/${record.id}/interest-rate`,
    );
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  const groupMap: Record<string, string> = {
    IPP_BC: "Balance conversion",
    IPP_TC: "Transaction conversion",
    IPP_NB: "New balance",
  };

  const columns: TableColumnType<Plan>[] = [
    {
      title: "Number",
      dataIndex: ["code"],
      key: "ippNumber",
      width: "6%",
    },
    {
      title: "Group",
      dataIndex: ["group"],
      key: "ippGroup",
      width: "12%",
      render: (text: string) => (text ? groupMap[text] : ""),
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "ippName",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: ["description"],
      key: "ippDescription",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Currency",
      dataIndex: ["currency"],
      key: "ippCurrency",
      width: "7%",
    },
    {
      key: "ippActions",
      width: "5%",
      render: (_: string, record: Plan) => (
        <Tooltip title="Edit">
          <Button
            type="text"
            htmlType="button"
            icon={<SettingOutlined />}
            className="text-primary"
            onClick={() => handleEditClick(record)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <Table<Plan>
      rowKey="id"
      columns={columns}
      dataSource={planList.content}
      pagination={pagination}
      onChange={handleTableChange}
      loading={isLoading}
    />
  );
};
