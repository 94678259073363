import { FC, useEffect, useMemo } from "react";
import { Col, Form, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/hooks/useForm";

import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { FormItemLabel, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductValue, TenantProductWizardResponse } from "@ni/sdk/models";

import { checkHeader, headerSwitchHandler } from "../../../utils";

import {
  CardBillingFormNames,
  CardBillingSwitchHeaders,
  checkboxFieldsPage35,
  switchHeader35,
} from "./page35.constants";

interface PctCardBillingProps {
  currency: string;
  header?: string;
  form: FormInstance<FormValues>;
  formDisabled?: boolean;
  editMode?: boolean;
}

export const PctCardBilling: FC<PctCardBillingProps> = ({
  currency,
  header,
  form,
  formDisabled = true,
  editMode = false,
}) => {
  const [wizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});

  const productTransPresetValues = useMemo(() => {
    const pctProductValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues as ProductValue[];

    return {
      transCatContC: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("C"),
      transCatContR: (
        getFormValueFromProductValues(pctProductValues, "trans-lim-trans-cat", "string") as string
      )?.includes("R"),
    };
  }, [wizardResponse]);

  useEffect(() => {
    Object.keys(checkboxFieldsPage35).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const item of checkboxFieldsPage35[key]) {
        if (form.getFieldValue(item)) {
          form.setFieldsValue({
            [key]: true,
          });
          break;
        }
      }
    });

    checkHeader(CardBillingSwitchHeaders.allCashAtmEnable, switchHeader35, form);
    checkHeader(CardBillingSwitchHeaders.allRetailEnable, switchHeader35, form);
  });

  return (
    <>
      {header && <div className="text-blue-h3">{header}</div>}
      {(productTransPresetValues?.transCatContC || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item
              valuePropName="checked"
              name={CardBillingSwitchHeaders.allCashAtmEnable}
              className="header-switch"
            >
              <Switch
                onClick={value =>
                  headerSwitchHandler(CardBillingSwitchHeaders.allCashAtmEnable, switchHeader35, value, form)
                }
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Cash"
                code={CardBillingSwitchHeaders.allCashAtmEnable}
                tooltipProps={{ title: "Turns off all cash ATM limits." }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={CardBillingFormNames.cashAtmEnable} className="col-switch" />
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="ATM"
                    code={CardBillingFormNames.cashAtmEnable}
                    tooltipProps={{
                      title: "This limiter counts all ATM transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[CardBillingFormNames.cashAtmEnable]}>
                  {() => {
                    return (
                      <FormItemLabel code={CardBillingFormNames.cashAtmMaxNr}>
                        <Form.Item name={CardBillingFormNames.cashAtmMaxNr}>
                          <NetworkForm.Number
                            stringMode={true}
                            min={0}
                            disabled={!form.getFieldValue(CardBillingFormNames.cashAtmEnable) || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[CardBillingFormNames.cashAtmEnable]}>
                  {() => {
                    return (
                      <FormItemLabel code={CardBillingFormNames.cashAtmMaxAm}>
                        <Form.Item name={CardBillingFormNames.cashAtmMaxAm}>
                          <NetworkForm.Number
                            stringMode={true}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue(CardBillingFormNames.cashAtmEnable) || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[CardBillingFormNames.cashAtmEnable]}>
                  {() => {
                    return (
                      <FormItemLabel code={CardBillingFormNames.cashAtmMaxSingle}>
                        <Form.Item name={CardBillingFormNames.cashAtmMaxSingle}>
                          <NetworkForm.Number
                            stringMode={true}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue(CardBillingFormNames.cashAtmEnable) || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {(productTransPresetValues?.transCatContR || editMode) && (
        <div className="limits-block">
          <div className="limits-block-header">
            <Form.Item
              valuePropName="checked"
              name={CardBillingSwitchHeaders.allRetailEnable}
              className="header-switch"
            >
              <Switch
                onClick={value =>
                  headerSwitchHandler(CardBillingSwitchHeaders.allRetailEnable, switchHeader35, value, form)
                }
              />
            </Form.Item>
            <div className="col-switch-desc">
              <TooltipInfo
                label="Retail"
                code={CardBillingSwitchHeaders.allRetailEnable}
                tooltipProps={{ title: "Turns off all retail transactions." }}
              />
            </div>
          </div>
          <div className="limits-block-body">
            <Row>
              <Col span={6} />
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max number"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal number of transactions per period. Leave it empty, if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of transactions per period. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
              <Col span={6} className="col-header">
                <TooltipInfo
                  label="Max single amount"
                  tooltipProps={{
                    title:
                      "This counter restricts maximal amount of single transaction. Leave it empty if restrictions are not intended. To prohibit any of transaction categories fill Max Number, Max Amount, and Max Single Amount with all zeros.",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item valuePropName="checked" name={CardBillingFormNames.retailEnable} className="col-switch" />
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="All"
                    code={CardBillingFormNames.retailEnable}
                    tooltipProps={{
                      title:
                        "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[CardBillingFormNames.retailEnable]}>
                  {() => {
                    return (
                      <FormItemLabel code={CardBillingFormNames.retailMaxNr}>
                        <Form.Item name={CardBillingFormNames.retailMaxNr}>
                          <NetworkForm.Number
                            stringMode={true}
                            min={0}
                            disabled={!form.getFieldValue(CardBillingFormNames.retailEnable) || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[CardBillingFormNames.retailEnable]}>
                  {() => {
                    return (
                      <FormItemLabel code={CardBillingFormNames.retailMaxAm}>
                        <Form.Item name={CardBillingFormNames.retailMaxAm}>
                          <NetworkForm.Number
                            stringMode={true}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue(CardBillingFormNames.retailEnable) || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item dependencies={[CardBillingFormNames.retailEnable]}>
                  {() => {
                    return (
                      <FormItemLabel code={CardBillingFormNames.retailMaxSingle}>
                        <Form.Item name={CardBillingFormNames.retailMaxSingle}>
                          <NetworkForm.Number
                            stringMode={true}
                            min={0}
                            addonAfter={currency}
                            disabled={!form.getFieldValue(CardBillingFormNames.retailEnable) || formDisabled}
                          />
                        </Form.Item>
                      </FormItemLabel>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export const getCardBillingLimitsPreparedValues = (value: {
  [key: string]: string | boolean;
}): { [key: string]: string } | undefined => {
  let actualValues: { [key: string]: string } | undefined;

  Object.keys(checkboxFieldsPage35).forEach(key => {
    if (value[key]) {
      checkboxFieldsPage35[key].forEach(fieldKey => {
        if (!actualValues) {
          actualValues = {};
        }
        actualValues[fieldKey] = (value[fieldKey] || "").toString().replace(/(\s)/g, "");
      });
    }
  });
  return actualValues;
};
