import { FC } from "react";
import { Form, FormInstance, Radio, Space } from "antd";

import { FeeRow, FormValues } from "@ni/common/types";
import { FeesValuesRow, HeadingsRow, TabContentLayout } from "@ni/common/ui";

import { FEES_LIMITS_HEADINGS } from "../TransactionFees.constants";

import {
  FEE_P2P_RADIO_SOURCE_KEY,
  FEE_P2P_RADIO_SOURCE_LABEL,
  FEE_P2P_RADIO_TARGET_KEY,
  FEE_P2P_RADIO_TARGET_LABEL,
  FEE_P2P_SOURCE_TARGET,
  internalP2PKey,
} from "./TopUpFees.constants";

export interface TopupFeesTabContentProps {
  activeTabKey: string;
  form: FormInstance<FormValues>;
  productCurrency: string;
  description: string;
  rows: FeeRow[];
  isCreditProduct?: boolean;
  headers?: {
    label: string;
    tooltip: string;
  }[];
}

export const TopupFeesTabContent: FC<TopupFeesTabContentProps> = ({
  activeTabKey,
  form,
  productCurrency,
  description,
  rows,
  headers,
}) => {
  const SectionTop = () => {
    return (
      activeTabKey &&
      activeTabKey === internalP2PKey && (
        <Form.Item name={FEE_P2P_SOURCE_TARGET} initialValue={FEE_P2P_RADIO_TARGET_KEY}>
          <Radio.Group>
            <Radio value={FEE_P2P_RADIO_TARGET_KEY}>{FEE_P2P_RADIO_TARGET_LABEL}</Radio>
            <Radio value={FEE_P2P_RADIO_SOURCE_KEY}>{FEE_P2P_RADIO_SOURCE_LABEL}</Radio>
          </Radio.Group>
        </Form.Item>
      )
    );
  };

  const SectionMiddle = (
    headers?: {
      label: string;
      tooltip: string;
    }[],
  ) => {
    return (
      <Space direction="vertical" size={16}>
        <HeadingsRow headings={headers ?? FEES_LIMITS_HEADINGS} firstColSpan={4} />
        {rows.map(row => (
          <FeesValuesRow
            key={row.rowLabel}
            rowTooltip={row.tooltip}
            rowLabel={row.rowLabel}
            form={form}
            productCurrency={productCurrency}
            fields={row.fields}
          />
        ))}
      </Space>
    );
  };

  return (
    <TabContentLayout description={description} sectionTop={SectionTop()} sectionMiddle={SectionMiddle(headers)} />
  );
};
