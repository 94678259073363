import { useCallback, useEffect } from "react";

import { LTY_PROGRAM_ACCR_TYPE } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { parseDataAsProgramValues } from "@ni/common/utils";
import { LoyaltyProgramTemplateApi } from "@ni/sdk/apis";
import { LoyaltyProgramTemplate, LoyaltyProgramTemplateType } from "@ni/sdk/models";

interface UseLoyaltyProgramTemplateApiProps {
  loyaltyTemplateId: number;
}

const loyaltyProgramTemplateApi = new LoyaltyProgramTemplateApi();

export const useLoyaltyProgramTemplateApi = ({ loyaltyTemplateId }: UseLoyaltyProgramTemplateApiProps) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [loyaltyProgramTemplate, setLoyaltyProgramTemplate] = useReduxState<LoyaltyProgramTemplate>(
    "currentLoyaltyProgramTemplate",
  );

  const getLoyaltyProgramTemplate = useCallback(
    async (loyaltyTemplateId: number) => {
      if (loyaltyTemplateId) {
        setIsLoading(true);
        try {
          const response = await loyaltyProgramTemplateApi.getById1(loyaltyTemplateId);
          if (response.data.type)
            response.data.programValues?.push({
              code: LTY_PROGRAM_ACCR_TYPE,
              value: response.data.type.charAt(0).toUpperCase(),
            });
          setLoyaltyProgramTemplate(response.data);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    },
    [setIsLoading, setLoyaltyProgramTemplate],
  );

  const editLoyaltyProgramTemplate = async (
    data: FormValues | { values: FormValues; pctValues: FormValues; type: LoyaltyProgramTemplateType },
    listKey: "programValues" | "programPctValues" | "both" = "programPctValues",
  ) => {
    setIsLoading(true);
    try {
      const response = await loyaltyProgramTemplateApi.edit2(
        listKey === "both"
          ? {
              type: data.type as LoyaltyProgramTemplateType,
              programValues: parseDataAsProgramValues(data?.values as FormValues),
              programPctValues: parseDataAsProgramValues(data?.pctValues as FormValues),
            }
          : {
              [listKey]: parseDataAsProgramValues(data)?.filter(x => x.code !== LTY_PROGRAM_ACCR_TYPE),
            },
        loyaltyTemplateId,
      );

      if (response.data.type)
        response.data.programValues?.push({
          code: LTY_PROGRAM_ACCR_TYPE,
          value: response.data.type.charAt(0).toUpperCase(),
        });
      setLoyaltyProgramTemplate(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const applyToProductsApi = async (ids: number[]) => {
    setIsLoading(true);
    try {
      if (ids?.length > 0) {
        await loyaltyProgramTemplateApi.applyToProducts(
          { productIds: ids as unknown as Set<number> },
          loyaltyTemplateId,
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void getLoyaltyProgramTemplate(loyaltyTemplateId);
  }, [loyaltyTemplateId, getLoyaltyProgramTemplate]);

  return {
    loyaltyProgramTemplate,
    applyToProductsApi,
    editLoyaltyProgramTemplate,
  };
};
