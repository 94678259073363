import { FC } from "react";
import { Form } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { useIPPWizardApi } from "../../hooks";

import { dailyInterestOption, interestAmountOption, interestRate } from "./constants";

export const InterestCalculationOptionsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { processWizardRequest } = useIPPWizardApi();
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );
  const isInterestAmountOriginalBalance = Form.useWatch<string>(interestAmountOption.code, form) === "W";

  useHydrateForm({
    form,
    entityFields: wizardResponse?.plan?.planValues ?? [],
    keys: {
      strings: [dailyInterestOption.code, interestAmountOption.code, interestRate.code],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="IPP Interest Calculation Options"
      pageSubtitle="The following options define the distribution of interest amount across equal monthly installments."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <NetworkForm.Number
        formItemOptions={{
          name: interestRate.code,
          label: interestRate.name,
          tooltip: interestRate.tooltip,
        }}
        addonAfter="%"
        min={interestRate.min}
        max={interestRate.max}
        precision={interestRate.decimalsNumber}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: interestAmountOption.code,
          label: interestAmountOption.name,
          initialValue: interestAmountOption.default,
        }}
        radioList={interestAmountOption.options}
      />

      {!isInterestAmountOriginalBalance && (
        <NetworkForm.Radio
          formItemOptions={{
            name: dailyInterestOption.code,
            label: dailyInterestOption.name,
            initialValue: dailyInterestOption.default,
          }}
          radioList={dailyInterestOption.options}
        />
      )}
    </CustomFormWrapper>
  );
};
