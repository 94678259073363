import { FC, useCallback, useEffect, useState } from "react";
import { Button } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { ConfirmModal, Spin } from "@ni/common/ui";
import { VariablesApi } from "@ni/sdk/apis";
import { Variable, VariableGroup } from "@ni/sdk/models";

import { VariableItem } from "../VariableItem";
import { VariableModal } from "../VariableModal";

import styles from "./styles.module.scss";

interface IVariableList {
  groupId: VariableGroup["id"];
}

const variablesApi = new VariablesApi();

export const VariableList: FC<IVariableList> = ({ groupId }) => {
  const [variableList, setVariableList] = useState<Variable[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedVariableId, setSelectedVariableId] = useState<null | Variable["id"]>(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState<boolean>(false);
  const [isNewModalOpened, setIsNewModalOpened] = useState<boolean>(false);

  const handleDelete = (id: Variable["id"]): void => {
    if (id !== null) {
      variablesApi
        .deleteVariable(groupId, id)
        .then(() => {
          setIsDeleteModalOpened(false);
          setVariableList(variableList.filter(i => i.id !== id));
        })
        .catch(() => {
          setIsDeleteModalOpened(false);
        });
    }
  };

  const getVariableList = useCallback((): void => {
    setVariableList([]);
    setIsLoading(true);

    variablesApi
      .getVariables(groupId)
      .then(response => {
        setVariableList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getVariableList();
  }, []);

  if (isLoading) {
    return <Spin fullscreen={false} />;
  }

  return (
    <>
      <div className={styles["cards-panel"]}>
        {variableList?.map(i => (
          <VariableItem
            key={`${i.id}-card`}
            {...i}
            setIsDeleteModalOpened={setIsDeleteModalOpened}
            setDeleteId={setSelectedVariableId}
            setIsEditModalOpened={setIsEditModalOpened}
          />
        ))}
      </div>
      <Button
        className={styles["add-var"]}
        type="text"
        htmlType="button"
        icon={<PlusOutlined width={12} />}
        onClick={() => {
          setIsNewModalOpened(true);
        }}
      >
        Add variable
      </Button>
      {selectedVariableId && (
        <ConfirmModal
          title="Are you sure delete this variable? By clicking `Confirm` you confirm variable deleting."
          isOpened={isDeleteModalOpened}
          onCancel={() => {
            setIsDeleteModalOpened(false);
            setSelectedVariableId(null);
          }}
          onConfirm={() => {
            handleDelete(selectedVariableId);
            setSelectedVariableId(null);
          }}
          isLoading={false}
          type="delete-variable"
          key={`confirmModal-${Math.random()}`}
        />
      )}
      <VariableModal
        key={groupId + Math.random()}
        opened={isEditModalOpened}
        closeDrawer={() => {
          setIsEditModalOpened(false);
          setSelectedVariableId(null);
        }}
        variableGroupId={groupId}
        item={variableList.find(i => i.id === selectedVariableId)}
        setVariableList={setVariableList}
      />
      <VariableModal
        key={groupId + Math.random()}
        opened={isNewModalOpened}
        closeDrawer={() => {
          setIsNewModalOpened(false);
          setSelectedVariableId(null);
        }}
        variableGroupId={groupId}
        setVariableList={setVariableList}
      />
    </>
  );
};
