export type RuleColumns = {
  label: string;
  tooltip?: React.ReactNode;
};

export const predefinedColumns: RuleColumns[] = [
  {
    label: "Max Number",
    tooltip:
      "This counter restricts maximal number of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
  },
  {
    label: "Max Amount",
    tooltip:
      "This counter restricts maximal amount of payments per period. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
  },
  {
    label: "Max Single Amount",
    tooltip:
      "This counter restricts maximal amount of single payment. Leave it empty if restrictions are not intended. All zeros prohibit payments at all.",
  },
];
