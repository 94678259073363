import { FormValues } from "@ni/common/types";
import { SortedFilteredPageRequest } from "@ni/sdk/models";

type Filters = {
  search?: string;
  filter: Record<string, string>;
};

export const updateFilters = (
  filterKeys: string[],
  filters: SortedFilteredPageRequest | Filters,
  changedValues?: FormValues,
  search?: string,
) => {
  const newFilters: Filters = {
    ...filters,
    filter: {
      ...(filters.filter || {}),
    },
  };

  if (changedValues) {
    filterKeys.forEach(key => {
      if (changedValues[key] !== undefined) {
        if (changedValues[key] === "") {
          const { [key]: removedKey, ...rest } = newFilters.filter;
          newFilters.filter = rest;
        } else {
          newFilters.filter[key] = changedValues[key] as string;
        }
      }
    });
  }

  if (search !== undefined && search !== "") {
    newFilters.search = search;
  } else {
    delete newFilters.search;
  }

  return newFilters;
};
