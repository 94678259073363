import { ReactNode } from "react";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const minAmountOption: NumericOptions = {
  code: "nic-ipp-inst-scheme-plan-min",
  name: "Minimum amount",
  decimalsNumber: 2,
  min: 0,
  tooltip:
    "In case of transaction amount or balance amount is less than the defined amount, then transaction amount / balance amount will not be converted to IPP",
};
export const maxAmountOption: NumericOptions = {
  code: "nic-ipp-inst-scheme-plan-max",
  name: "Maximum amount",
  decimalsNumber: 2,
  min: 0,
  tooltip:
    "In case of transaction amount or balance amount is greater than the defined amount, then transaction amount / balance amount will not be converted to IPP",
};
export const maxDaysOption: NumericOptions = {
  code: "nic-ipp-inst-scheme-plan-max-days",
  name: "Maximum days from transaction",
  decimalsNumber: 0,
  min: 0,
  tooltip:
    "In case transaction is tried to convert to IPP after the number of days defined in this parameter from the date of transaction, then IPP will not be booked",
};
