import { InputNumberProps } from "antd";

import { FormField, Options } from "@ni/common/types";

export const murabahaProfitRate: FormField<InputNumberProps> = {
  code: "murab-profit-rate",
  label: "Murabaha profit rate",
  tooltip:
    "This rate will be used to calculate monthly finance payment due and special deposit account profit and bonus. When Murabaha is in effect both cash and retail profit rates are identical.",
  props: {
    min: 0,
    precision: 2,
    controls: false,
    addonAfter: "%",
  },
};

export const interestStart: Options = {
  code: "int-start",
  label: "Charge profit for transaction starting from",
  default: "P",
  options: [
    {
      value: "P",
      label: "Posting date",
    },
    {
      value: "T",
      label: "Transaction date",
    },
  ],
};

export const interestStartNumber: FormField<InputNumberProps> = {
  code: "int-start-numb",
  label: "Maximum days between the transaction date and the posting date",
  tooltip: "If the number of days exceeds, then profits will be charged starting from the posting date.",
  props: {
    min: 0,
    precision: 0,
    controls: false,
    addonAfter: "Days",
  },
};

export const fullPaymentDate: FormField<InputNumberProps> = {
  code: "nic-fp-date-days",
  label: "Full payment date (number of days from payment due date)",
  tooltip:
    "The full payment date is the deadline by which the entire payment must be made to meet interest grace option conditions. More details are on the Interest Grace Options page, check the link below.",
  props: {
    min: 0,
    precision: 0,
    controls: false,
  },
};

export const zeroProfitOnProfit: Options = {
  code: "zero-int-int",
  label: "Zero profit rate on all profit",
  options: [
    {
      value: "nic-int-rate-zero-retail-int",
      label: "Zero profit rate on Retail profit",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied.",
    },
    {
      value: "nic-int-rate-zero-cash-int",
      label: "Zero profit rate on Cash profit",
      tooltip: "If the option is unselected, then interest rate on cash balance will be applied.",
    },
  ],
};

export const zeroProfitOnFees: Options = {
  code: "zero-int-fees",
  label: "Zero profit rate on all fees",
  options: [
    {
      value: "nic-int-rate-zero-retail-amfs",
      label: "Zero profit rate on Membership Fees",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied.",
    },
    {
      value: "nic-int-rate-zero-retail-lpf",
      label: "Zero profit rate on Late Payment Fee",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied.",
    },
    {
      value: "nic-int-rate-zero-retail-ovl",
      label: "Zero profit rate on Overlimit Fee",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied.",
    },
    {
      value: "nic-int-rate-zero-retail-nsf",
      label: "Zero profit rate on Not Sufficient Funds Fee",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied.",
    },
  ],
};

export const zeroProfitOnInsurance: Options = {
  code: "zero-int-ins",
  label: "Zero profit rate on all insurance",
  options: [
    {
      value: "nic-int-rate-zero-retail-ins",
      label: "Zero profit rate on Retail Insurance",
      tooltip: "If the option is unselected, then interest rate on retail balance will be applied.",
    },
    {
      value: "nic-int-rate-zero-cash-ins",
      label: "Zero profit rate on Cash Insurance",
      tooltip: "If the option is unselected, then interest rate on cash balance will be applied",
    },
  ],
};
