import { FC, useEffect } from "react";
import { Checkbox, Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { TenantCard } from "../../components";

import { ADDITIONAL_SERVICES_CARDS } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const AdditionalServicesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useEffect(() => {
    if (wizardResponse?.tenant?.id) {
      const { isFalconSelected, isCardControlSelected } = wizardResponse.tenant;

      form.setFieldValue(ADDITIONAL_SERVICES_CARDS[0].name, isFalconSelected);
      form.setFieldValue(ADDITIONAL_SERVICES_CARDS[1].name, isCardControlSelected);
    }
  }, [form, wizardResponse.tenant]);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Additional Services"
      pageSubtitle={
        <TooltipInfo
          label="Please, select value added services."
          code="nic-ncontrol-flag-def-new, nic-ncontrol-flag-def, nic-ncontrol-flag-editable, nic-ncontrol-send-tib-first-y, nic-ncontrol-repo, nic-ncontrol-acc-profile, nic-ncontrol-home-profile, nic-falcon-fi-id, nic-tn-activate_card_auto, nic-tn-activate_card_single, nic-tn-bcc-acc1-changed, nic-tn-bcc-acc2-changed, nic-tn-bcc-card-changed, nic-tn-client_new_c, nic-tn-client_new_p, nic-tn-client_update_c, nic-tn-client_update_p, nic-tn-contr_new_a, nic-tn-contr_new_c, nic-tn-contr_replace_c, nic-tn-contr_status-changed_a, nic-tn-contr_status-changed_c, nic-tn-contr_update_a, nic-tn-contr_update_c, nic-tn-cp_news, nic-tn-cp_renews, nic-tn-cp_repls, nic-tn-pin_actions"
          tooltipProps={{}}
        />
      }
      size="md"
      formSize="md"
      gap={0}
      level="root"
      submitHandler={onFinish}
    >
      {ADDITIONAL_SERVICES_CARDS.map(card => (
        <TenantCard
          key={card.name}
          inputId={card.name}
          title={card.title}
          text={card.text}
          imageSrc={card.imageSrc}
          disabled={formDisabled}
        >
          <Form.Item name={card.name} valuePropName="checked">
            <Checkbox id={card.name} disabled={formDisabled} />
          </Form.Item>
        </TenantCard>
      ))}
    </CustomFormWrapper>
  );
};
