/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RoleRestrictionsAccessLevel = {
    MANAGE: 'MANAGE',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
    FORBIDDEN: 'FORBIDDEN'
} as const;

export type RoleRestrictionsAccessLevel = typeof RoleRestrictionsAccessLevel[keyof typeof RoleRestrictionsAccessLevel];



