import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useProductSettings, useReduxState } from "@ni/common/hooks";
import { RoutesHandler, Sidenav, SidenavEntitySelect } from "@ni/common/ui";
import { getLastRoute } from "@ni/common/utils";
import { Product } from "@ni/sdk/models";

import { useInsuranceProgramApi } from "../../../hooks";

import { pagesRoute } from "./route";

import styles from "../../../Style.module.scss";

export const ProductInsuranceProgramEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentProduct] = useReduxState<Product>("currentProduct", {});

  const { id: tenantId, insuranceId, productId } = useParams<{ id: string; insuranceId: string; productId: string }>();

  const { currentInsuranceProgram, insurancePrograms } = useInsuranceProgramApi(
    parseInt(insuranceId ?? "0", 10),
    parseInt(productId ?? "0", 10),
    parseInt(tenantId ?? "0", 10),
  );

  useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: Object.keys(currentProduct).length === 0,
  });

  const handleRouteChange = useCallback(
    (id: number): void => {
      navigate(`/tenant/${tenantId}/product/${productId}/edit-insurance/${id}/${getLastRoute(location.pathname)}`);
    },
    [location.pathname, navigate, productId, tenantId],
  );

  return currentInsuranceProgram?.id && handleRouteChange ? (
    <RoutesHandler
      routePrefix="/"
      routesList={pagesRoute}
      deps={[currentInsuranceProgram?.id, insurancePrograms, handleRouteChange]}
      ParentComponent={({ navItems, children }) => (
        <div className={styles["ni-program"]}>
          <Sidenav items={navItems} disabledItemsViewType="hidden">
            <SidenavEntitySelect
              value={currentInsuranceProgram?.id}
              entities={insurancePrograms ?? []}
              onChange={handleRouteChange}
            />
          </Sidenav>
          <div className={styles["ni-program-content"]}>{children}</div>
        </div>
      )}
    />
  ) : null;
};
