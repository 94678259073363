import { FC, ReactNode } from "react";
import { Modal, Typography } from "antd";

interface PctModalProps {
  isOpen: boolean;
  footer: ReactNode;
  disabled?: boolean;
  afterOpenChange?: (isOpen: boolean) => void;
}

export const UnsavedChangesModal: FC<PctModalProps> = ({ isOpen, footer, disabled, afterOpenChange }) => {
  const modalText = disabled
    ? "You have unsaved changes, but there are validation errors."
    : "Are you sure you want to navigate away? Any unsaved changes will be lost.";

  return (
    <Modal
      title="Unsaved Changes"
      footer={footer}
      forceRender={true}
      getContainer={false}
      open={isOpen}
      centered={true}
      afterOpenChange={afterOpenChange}
    >
      <Typography.Text>{modalText}</Typography.Text>
      {disabled && (
        <ul>
          <li>
            <strong>Stay</strong> to fix the errors and save.
          </li>
          <li>
            <strong>Leave</strong> to discard your changes and navigate away.
          </li>
        </ul>
      )}
    </Modal>
  );
};
