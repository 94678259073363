/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useMemo, useState } from "react";
import { Badge, Flex, Select, Space, Typography } from "antd";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { breadcrumbArrow } from "@ni/common/assets";
import {
  PCT_EDIT_PAGES_CODES,
  PCT_PAGES_CODES,
  PRODUCT_EDIT_PAGES_CODES,
  PRODUCT_PAGES_CODES,
  TENANT_EDIT_PAGES_CODES,
  TENANT_PAGES_CODES,
} from "@ni/common/constants";
import { useDebaunce, useReduxState } from "@ni/common/hooks";
import { Tenant } from "@ni/sdk/models";

import styles from "../../styles.module.scss";

const successPageCode = "8_SUCCESS_PAGE";

const entityEditMainRoutes = {
  tenant: { insurance: "edit-insurance", loyalty: "edit-template", plan: "edit-ipp" },
  product: { insurance: "edit-insurance", loyalty: "edit-program" },
  pct: { insurance: "insurance-edit", loyalty: "loyalty-program", plan: "installment-payment-plans" },
};

const levelColors = {
  tenant: { label: "Tenant", color: "#035ea8" },
  product: { label: "Product", color: "#035ea8c6" },
  pct: { label: "PCT", color: "#035ea881" },
} as const;

const Seperator = () => <img id="breadcrumb-arrow-id" alt="breadcrumb-arrow" src={breadcrumbArrow} />;

const getRouteName = (staticConfig: Record<string, { labelMap: Record<string, string> }>, key: string) =>
  `${staticConfig[key].labelMap[key] ?? _.upperFirst(key.replace(/-/g, " "))}`;

export const CodePagePicker = ({ tenant }: { tenant: Tenant }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [directSearch, setSearch] = useState("");
  const [triggeredPages, setTriggeredPages] = useReduxState<Set<string>>("visitedPagesByCodePageEngine", new Set());
  const search = useDebaunce({ value: directSearch, delay: 200 });

  const canSearch = useMemo(() => /^\/tenant/.test(pathname), [pathname]);

  const addConditionalRoute = (shouldAdd: boolean, route: string) => (shouldAdd ? [route] : []);

  const mappedTenant = useMemo(() => {
    let hasCreditProducts = false;
    const isSmsEnabled =
      tenant?.tenantValues?.find(value => value.fieldCode === "enable-sms-glob")?.value?.toString() === "true";
    const MappedProducts = tenant?.products
      ?.filter(product => product.lastProcessedPage?.code === successPageCode)
      ?.map(product => {
        const productType = product?.productValues?.find(x => x.fieldCode === "product-type")?.value;
        const isCreditProduct = productType === "Credit";
        if (!hasCreditProducts && isCreditProduct) hasCreditProducts = isCreditProduct;
        const productCreditType =
          product?.productValues?.find(x => x.fieldCode === "credit-type-islamic")?.value ??
          product?.productValues?.find(x => x.fieldCode === "credit-type")?.value;
        const isCmsbalanceOwner = product?.productValues?.find(x => x.fieldCode === "balance-owner")?.value === "CMS";
        return {
          id: product?.id,
          name: product?.displayName,
          loyaltyPrograms: {
            hasPrograms: !!product?.loyaltyPrograms?.length,
            programs: product?.loyaltyPrograms ?? [],
          },
          insurancePrograms: {
            hasPrograms: !!product?.shortInsurancePrograms?.length,
            programs: product?.shortInsurancePrograms,
          },
          skipCodeSet: new Set([
            ...addConditionalRoute(!isCreditProduct, "credit-limit"),
            ...addConditionalRoute(!isCreditProduct, "payment-and-delinquency-settings"),
            ...addConditionalRoute(!isCreditProduct, "minimum-to-pay-formula"),
            ...addConditionalRoute(!isCreditProduct, "direct-debit-configuration"),
            ...addConditionalRoute(
              !(isCreditProduct && productCreditType === "Tawarruq" && isCmsbalanceOwner),
              "tawarruq-profit-settings",
            ),
            ...addConditionalRoute(
              !(isCreditProduct && productCreditType === "Conventional" && isCmsbalanceOwner),
              "interest-grace-options",
            ),
            ...addConditionalRoute(
              !(isCreditProduct && productCreditType === "Murabaha" && isCmsbalanceOwner),
              "murabaha-profit-settings",
            ),
            ...addConditionalRoute(!product?.loyaltyPrograms?.length, "loyalty-settings"),
          ]),
          pcts: product?.parameterTables?.map(pct => {
            const hasLoyalty = !!product?.loyaltyPrograms?.filter(loyalty => loyalty.state === "ACTIVE")?.length;
            const isMultiCurrency =
              product?.productValues?.find(value => value?.fieldCode === "curr-setup")?.value === "mult";
            return {
              id: pct?.id,
              name: pct?.displayName,
              code: pct?.externalCode,
              loyaltyPrograms: {
                hasPrograms: hasLoyalty,
                programs: product?.loyaltyPrograms?.filter(loyalty => loyalty.state === "ACTIVE") ?? [],
              },
              insurancePrograms: {
                hasPrograms:
                  !!product?.shortInsurancePrograms?.filter(insurance => insurance?.state === "ACTIVE")?.length &&
                  isCreditProduct,
                programs: product?.shortInsurancePrograms?.filter(insurance => insurance?.state === "ACTIVE") ?? [],
              },
              planPrograms: {
                hasPrograms: !!tenant?.plans?.filter(plan => plan.state === "ACTIVE")?.length,
                programs: tenant?.plans?.filter(plan => plan.state === "ACTIVE") ?? [],
              },
              skipCodeSet: new Set([
                ...addConditionalRoute(!isCreditProduct, "credit-limit"),
                ...addConditionalRoute(!isCreditProduct, "payment-due"),
                ...addConditionalRoute(
                  !(isCreditProduct && productCreditType === "Tawarruq" && isCmsbalanceOwner),
                  "tawarruq-profit-calculation",
                ),
                ...addConditionalRoute(
                  !(isCreditProduct && productCreditType === "Conventional" && isCmsbalanceOwner),
                  "interest-calculation",
                ),
                ...addConditionalRoute(
                  !(isCreditProduct && productCreditType === "Murabaha" && isCmsbalanceOwner),
                  "murabaha-profit-calculation",
                ),
                ...addConditionalRoute(!hasLoyalty, "loyalty-settings"),
                ...addConditionalRoute(!isCmsbalanceOwner, "decline-fee"),
                ...addConditionalRoute(!isCmsbalanceOwner, "balance-inquiry-fees"),
                ...addConditionalRoute(!isCmsbalanceOwner, "currency-conversion-fees"),
                ...addConditionalRoute(!(isCreditProduct && isCmsbalanceOwner), "payment-fees"),
                ...addConditionalRoute(!(isCmsbalanceOwner && productType === "Prepaid"), "top-up-fees"),
                ...addConditionalRoute(!isCmsbalanceOwner, "card-transaction-fees"),
                ...addConditionalRoute(!(isCmsbalanceOwner && isCreditProduct), "credit-limit-modification-fees"),
                ...addConditionalRoute(!(isCmsbalanceOwner && isCreditProduct), "late-payment-fee"),
                ...addConditionalRoute(!isCreditProduct, "overlimit-fee"),
                ...addConditionalRoute(!isMultiCurrency, "inactivity-fees"),
                ...addConditionalRoute(!isCmsbalanceOwner, "card-limits"),
                ...addConditionalRoute(!isCmsbalanceOwner, "account-limits"),
                ...addConditionalRoute(!isCmsbalanceOwner, "additional-rules"),
                ...addConditionalRoute(!isCmsbalanceOwner, "card-limits-billing-period"),
                ...addConditionalRoute(!isCmsbalanceOwner, "account-limits-billing-period"),
              ]),
            };
          }),
        };
      });
    return {
      tenantId: tenant?.id,
      tenantName: tenant?.name,
      loyaltyTemplates: {
        hasTemplates: !!tenant?.loyaltyProgramTemplates?.length,
        templates: tenant?.loyaltyProgramTemplates ?? [],
      },
      insuranceTemplates: {
        hasTemplates: !!tenant?.insuranceProgramTemplates?.length,
        templates: tenant?.insuranceProgramTemplates ?? [],
      },
      planTemplates: { hasTemplates: !!tenant?.plans?.length, templates: tenant?.plans },
      products: MappedProducts,
      skipCodeSet: new Set([
        ...addConditionalRoute(!hasCreditProducts, "credit-products-configuration"),
        ...addConditionalRoute(!tenant?.loyaltyProgramTemplates?.length, "loyalty-settings"),
        ...addConditionalRoute(!isSmsEnabled, "sms-templates"),
      ]),
    };
  }, [
    tenant?.id,
    tenant?.insuranceProgramTemplates,
    tenant?.loyaltyProgramTemplates,
    tenant?.name,
    tenant?.plans,
    tenant?.products,
    tenant?.tenantValues,
  ]);

  const requestedRoutes = useMemo(() => {
    const routesList: { label: string[]; value: string; url: string; isVisited: boolean; level: string }[] = [];

    if (!canSearch) return [];

    Object.keys(TENANT_PAGES_CODES as Record<string, { labelMap: Record<string, string> }>).forEach(key => {
      if (
        typeof key === "string" &&
        !mappedTenant.skipCodeSet.has(key) &&
        [
          ...Object.keys(TENANT_PAGES_CODES[key as keyof typeof TENANT_PAGES_CODES].labelMap).filter(code => code),
          ...Object.values(TENANT_PAGES_CODES[key as keyof typeof TENANT_PAGES_CODES].labelMap).filter(label => label),
        ]
          .join(" ")
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
      ) {
        routesList.push({
          label: [getRouteName(TENANT_PAGES_CODES, key)],
          value: `${routesList.length} ${search} ${key}`,
          url: `/tenant/${mappedTenant?.tenantId}/${key}`,
          isVisited: triggeredPages.has(`/tenant/${mappedTenant?.tenantId}/${key}`),
          level: "tenant",
        });
      }
    });

    if (mappedTenant.loyaltyTemplates.hasTemplates)
      Object.keys(
        TENANT_EDIT_PAGES_CODES.loyaltyTemplates as Record<string, { labelMap: Record<string, string> }>,
      ).forEach(key => {
        if (
          [
            ...Object.keys(
              TENANT_EDIT_PAGES_CODES.loyaltyTemplates[key as keyof typeof TENANT_EDIT_PAGES_CODES.loyaltyTemplates]
                .labelMap,
            ).filter(code => code),
            ...Object.values(
              TENANT_EDIT_PAGES_CODES.loyaltyTemplates[key as keyof typeof TENANT_EDIT_PAGES_CODES.loyaltyTemplates]
                .labelMap,
            ).filter(label => label),
          ]
            .join(" ")
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        ) {
          mappedTenant.loyaltyTemplates.templates.forEach(loyalty => {
            if (loyalty?.id && loyalty?.name)
              routesList.push({
                label: [
                  `Loyalty Templates`,
                  `${_.upperFirst(loyalty?.name)}`,
                  getRouteName(TENANT_EDIT_PAGES_CODES.loyaltyTemplates, key),
                ],
                value: `${routesList.length} ${search} ${key}`,
                url: `/tenant/${mappedTenant?.tenantId}/${entityEditMainRoutes?.tenant?.loyalty}/${loyalty?.id}/${key}`,
                isVisited: triggeredPages.has(
                  `/tenant/${mappedTenant?.tenantId}/${entityEditMainRoutes?.tenant?.loyalty}/${loyalty?.id}/${key}`,
                ),
                level: "tenant",
              });
          });
        }
      });

    if (mappedTenant.insuranceTemplates.hasTemplates)
      Object.keys(
        TENANT_EDIT_PAGES_CODES.insuranceTemplates as Record<string, { labelMap: Record<string, string> }>,
      ).forEach(key => {
        if (
          [
            ...Object.keys(
              TENANT_EDIT_PAGES_CODES.insuranceTemplates[key as keyof typeof TENANT_EDIT_PAGES_CODES.insuranceTemplates]
                .labelMap,
            ).filter(code => code),
            ...Object.values(
              TENANT_EDIT_PAGES_CODES.insuranceTemplates[key as keyof typeof TENANT_EDIT_PAGES_CODES.insuranceTemplates]
                .labelMap,
            ).filter(label => label),
          ]
            .join(" ")
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        ) {
          mappedTenant.insuranceTemplates.templates.forEach(insurance => {
            if (insurance?.id && insurance?.name)
              routesList.push({
                label: [
                  `Insurance Templates`,
                  `${_.upperFirst(insurance?.name)}`,
                  getRouteName(TENANT_EDIT_PAGES_CODES.insuranceTemplates, key),
                ],
                value: `${routesList.length} ${search} ${key}`,
                url: `/tenant/${mappedTenant?.tenantId}/${entityEditMainRoutes?.tenant?.insurance}/${insurance?.id}/${key}`,
                isVisited: triggeredPages.has(
                  `/tenant/${mappedTenant?.tenantId}/${entityEditMainRoutes?.tenant?.insurance}/${insurance?.id}/${key}`,
                ),
                level: "tenant",
              });
          });
        }
      });

    if (mappedTenant.planTemplates.hasTemplates)
      Object.keys(
        TENANT_EDIT_PAGES_CODES.planTemplates as Record<string, { labelMap: Record<string, string> }>,
      ).forEach(key => {
        if (
          [
            ...Object.keys(
              TENANT_EDIT_PAGES_CODES.planTemplates[key as keyof typeof TENANT_EDIT_PAGES_CODES.planTemplates].labelMap,
            ).filter(code => code),
            ...Object.values(
              TENANT_EDIT_PAGES_CODES.planTemplates[key as keyof typeof TENANT_EDIT_PAGES_CODES.planTemplates].labelMap,
            ).filter(label => label),
          ]
            .join(" ")
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        ) {
          mappedTenant?.planTemplates?.templates?.forEach(plan => {
            if (plan?.id && plan?.name)
              routesList.push({
                label: [
                  `Installment Payment Plans`,
                  `${_.upperFirst(plan?.name)}`,
                  getRouteName(TENANT_EDIT_PAGES_CODES.planTemplates, key),
                ],
                value: `${routesList.length} ${search} ${key}`,
                url: `/tenant/${mappedTenant?.tenantId}/${entityEditMainRoutes?.tenant?.plan}/${plan?.id}/${key}`,
                isVisited: triggeredPages.has(
                  `/tenant/${mappedTenant?.tenantId}/${entityEditMainRoutes?.tenant?.plan}/${plan?.id}/${key}`,
                ),
                level: "tenant",
              });
          });
        }
      });

    mappedTenant?.products?.forEach(product => {
      Object.keys(PRODUCT_PAGES_CODES as Record<string, { labelMap: Record<string, string> }>).forEach(key => {
        if (
          typeof key === "string" &&
          !product.skipCodeSet.has(key) &&
          [
            ...Object.keys(PRODUCT_PAGES_CODES[key as keyof typeof PRODUCT_PAGES_CODES].labelMap).filter(code => code),
            ...Object.values(PRODUCT_PAGES_CODES[key as keyof typeof PRODUCT_PAGES_CODES].labelMap).filter(
              label => label,
            ),
          ]
            .join(" ")
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        ) {
          routesList.push({
            label: [_.upperFirst(product?.name), getRouteName(PRODUCT_PAGES_CODES, key)],
            value: `${routesList.length} ${search} ${key}`,
            url: `/tenant/${mappedTenant?.tenantId}/product/${product?.id}/${key}`,
            isVisited: triggeredPages.has(`/tenant/${mappedTenant?.tenantId}/product/${product?.id}/${key}`),
            level: "product",
          });
        }
      });

      if (product.loyaltyPrograms.hasPrograms)
        Object.keys(
          PRODUCT_EDIT_PAGES_CODES.loyaltyPrograms as Record<string, { labelMap: Record<string, string> }>,
        ).forEach(key => {
          if (
            [
              ...Object.keys(
                PRODUCT_EDIT_PAGES_CODES.loyaltyPrograms[key as keyof typeof PRODUCT_EDIT_PAGES_CODES.loyaltyPrograms]
                  .labelMap,
              ).filter(code => code),
              ...Object.values(
                PRODUCT_EDIT_PAGES_CODES.loyaltyPrograms[key as keyof typeof PRODUCT_EDIT_PAGES_CODES.loyaltyPrograms]
                  .labelMap,
              ).filter(label => label),
            ]
              .join(" ")
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          ) {
            product.loyaltyPrograms.programs.forEach(loyalty => {
              if (loyalty?.id && loyalty?.name)
                routesList.push({
                  label: [
                    `${_.upperFirst(product?.name)}`,
                    `Loyalty Programs`,
                    `${_.upperFirst(loyalty?.name)}`,
                    getRouteName(PRODUCT_EDIT_PAGES_CODES.loyaltyPrograms, key),
                  ],
                  value: `${routesList.length} ${search} ${key}`,
                  url: `/tenant/${mappedTenant?.tenantId}/product/${product.id}/${entityEditMainRoutes?.product?.loyalty}/${loyalty?.id}/${key}`,
                  isVisited: triggeredPages.has(
                    `/tenant/${mappedTenant?.tenantId}/product/${product.id}/${entityEditMainRoutes?.product?.loyalty}/${loyalty?.id}/${key}`,
                  ),
                  level: "product",
                });
            });
          }
        });

      if (product.insurancePrograms.hasPrograms)
        Object.keys(
          PRODUCT_EDIT_PAGES_CODES.insurancePrograms as Record<string, { labelMap: Record<string, string> }>,
        ).forEach(key => {
          if (
            [
              ...Object.keys(
                PRODUCT_EDIT_PAGES_CODES.insurancePrograms[
                  key as keyof typeof PRODUCT_EDIT_PAGES_CODES.insurancePrograms
                ].labelMap,
              ).filter(code => code),
              ...Object.values(
                PRODUCT_EDIT_PAGES_CODES.insurancePrograms[
                  key as keyof typeof PRODUCT_EDIT_PAGES_CODES.insurancePrograms
                ].labelMap,
              ).filter(label => label),
            ]
              .join(" ")
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          ) {
            product?.insurancePrograms?.programs?.forEach(insurance => {
              if (insurance?.id && insurance?.name)
                routesList.push({
                  label: [
                    `${_.upperFirst(product?.name)}`,
                    `Insurance Programs`,
                    `${_.upperFirst(insurance?.name)}`,
                    getRouteName(PRODUCT_EDIT_PAGES_CODES.insurancePrograms, key),
                  ],
                  value: `${routesList.length} ${search} ${key}`,
                  url: `/tenant/${mappedTenant?.tenantId}/product/${product.id}/${entityEditMainRoutes?.product?.insurance}/${insurance?.id}/${key}`,
                  isVisited: triggeredPages.has(
                    `/tenant/${mappedTenant?.tenantId}/product/${product.id}/${entityEditMainRoutes?.product?.insurance}/${insurance?.id}/${key}`,
                  ),
                  level: "product",
                });
            });
          }
        });

      product.pcts?.forEach(pct => {
        Object.keys(PCT_PAGES_CODES as Record<string, { labelMap: Record<string, string> }>).forEach(key => {
          if (
            typeof key === "string" &&
            !pct.skipCodeSet.has(key) &&
            [
              ...Object.keys(PCT_PAGES_CODES[key as keyof typeof PCT_PAGES_CODES].labelMap).filter(code => code),
              ...Object.values(PCT_PAGES_CODES[key as keyof typeof PCT_PAGES_CODES].labelMap).filter(label => label),
            ]
              .join(" ")
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          ) {
            routesList.push({
              label: [
                _.upperFirst(product?.name),
                _.upperFirst(`${pct?.name} ${pct?.code ?? ""}`),
                getRouteName(PCT_PAGES_CODES, key),
              ],
              value: `${routesList.length} ${search} ${key}`,
              url: `/tenant/${mappedTenant?.tenantId}/product/${product?.id}/pct/${pct?.id}/${key}`,
              isVisited: triggeredPages.has(
                `/tenant/${mappedTenant?.tenantId}/product/${product?.id}/pct/${pct?.id}/${key}`,
              ),
              level: "pct",
            });
          }
        });

        if (pct.loyaltyPrograms.hasPrograms)
          Object.keys(
            PCT_EDIT_PAGES_CODES.loyaltyPrograms as Record<string, { labelMap: Record<string, string> }>,
          ).forEach(key => {
            if (
              [
                ...Object.keys(
                  PCT_EDIT_PAGES_CODES.loyaltyPrograms[key as keyof typeof PCT_EDIT_PAGES_CODES.loyaltyPrograms]
                    .labelMap,
                ).filter(code => code),
                ...Object.values(
                  PCT_EDIT_PAGES_CODES.loyaltyPrograms[key as keyof typeof PCT_EDIT_PAGES_CODES.loyaltyPrograms]
                    .labelMap,
                ).filter(label => label),
              ]
                .join(" ")
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            ) {
              pct.loyaltyPrograms.programs.forEach(loyalty => {
                if (loyalty?.id && loyalty?.name)
                  routesList.push({
                    label: [
                      `${_.upperFirst(product?.name)}`,
                      `${_.upperFirst(pct?.name)}`,
                      `${_.upperFirst(loyalty?.name)}`,
                      getRouteName(PCT_EDIT_PAGES_CODES.loyaltyPrograms, key),
                    ],
                    value: `${routesList.length} ${search} ${key}`,
                    url: `/tenant/${mappedTenant?.tenantId}/product/${product.id}/pct/${pct.id}/${entityEditMainRoutes?.pct?.loyalty}/${loyalty?.id}/${key}`,
                    isVisited: triggeredPages.has(
                      `/tenant/${mappedTenant?.tenantId}/product/${product.id}/pct/${pct.id}/${entityEditMainRoutes?.pct?.loyalty}/${loyalty?.id}/${key}`,
                    ),
                    level: "pct",
                  });
              });
            }
          });

        if (pct.insurancePrograms.hasPrograms)
          Object.keys(
            PCT_EDIT_PAGES_CODES.insurancePrograms as Record<string, { labelMap: Record<string, string> }>,
          ).forEach(key => {
            if (
              [
                ...Object.keys(
                  PCT_EDIT_PAGES_CODES.insurancePrograms[key as keyof typeof PCT_EDIT_PAGES_CODES.insurancePrograms]
                    .labelMap,
                ).filter(code => code),
                ...Object.values(
                  PCT_EDIT_PAGES_CODES.insurancePrograms[key as keyof typeof PCT_EDIT_PAGES_CODES.insurancePrograms]
                    .labelMap,
                ).filter(label => label),
              ]
                .join(" ")
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            ) {
              pct.insurancePrograms.programs.forEach(insurance => {
                if (insurance?.id && insurance?.name)
                  routesList.push({
                    label: [
                      `${_.upperFirst(product?.name)}`,
                      `${_.upperFirst(pct?.name)}`,
                      `${_.upperFirst(insurance?.name)}`,
                      getRouteName(PCT_EDIT_PAGES_CODES.insurancePrograms, key),
                    ],
                    value: `${routesList.length} ${search} ${key}`,
                    url: `/tenant/${mappedTenant?.tenantId}/product/${product.id}/pct/${pct.id}/${entityEditMainRoutes?.pct?.insurance}/${insurance?.id}/${key}`,
                    isVisited: triggeredPages.has(
                      `/tenant/${mappedTenant?.tenantId}/product/${product.id}/pct/${pct.id}/${entityEditMainRoutes?.pct?.insurance}/${insurance?.id}/${key}`,
                    ),
                    level: "pct",
                  });
              });
            }
          });

        if (pct.planPrograms.hasPrograms)
          Object.keys(
            PCT_EDIT_PAGES_CODES.planPrograms as Record<string, { labelMap: Record<string, string> }>,
          ).forEach(key => {
            if (
              [
                ...Object.keys(
                  PCT_EDIT_PAGES_CODES.planPrograms[key as keyof typeof PCT_EDIT_PAGES_CODES.planPrograms].labelMap,
                ).filter(code => code),
                ...Object.values(
                  PCT_EDIT_PAGES_CODES.planPrograms[key as keyof typeof PCT_EDIT_PAGES_CODES.planPrograms].labelMap,
                ).filter(label => label),
              ]
                .join(" ")
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
            ) {
              pct.planPrograms.programs.forEach(plan => {
                if (plan?.id && plan?.name)
                  routesList.push({
                    label: [
                      `${_.upperFirst(product?.name)}`,
                      `${_.upperFirst(pct?.name)}`,
                      `${_.upperFirst(plan?.name)}`,
                      getRouteName(PCT_EDIT_PAGES_CODES.planPrograms, key),
                    ],
                    value: `${routesList.length} ${search} ${key}`,
                    url: `/tenant/${mappedTenant?.tenantId}/product/${product.id}/pct/${pct.id}/${entityEditMainRoutes?.pct?.plan}/${plan?.id}/${key}`,
                    isVisited: triggeredPages.has(
                      `/tenant/${mappedTenant?.tenantId}/product/${product.id}/pct/${pct.id}/${entityEditMainRoutes?.pct?.plan}/${plan?.id}/${key}`,
                    ),
                    level: "pct",
                  });
              });
            }
          });
      });
    });

    return routesList.length > 0
      ? routesList
      : [{ label: ["Not Found :("], value: search, url: "", isVisited: false, level: "" }];
  }, [
    canSearch,
    mappedTenant.insuranceTemplates.hasTemplates,
    mappedTenant.insuranceTemplates.templates,
    mappedTenant.loyaltyTemplates.hasTemplates,
    mappedTenant.loyaltyTemplates.templates,
    mappedTenant.planTemplates.hasTemplates,
    mappedTenant.planTemplates?.templates,
    mappedTenant?.products,
    mappedTenant.skipCodeSet,
    mappedTenant?.tenantId,
    search,
    triggeredPages,
  ]);

  const onRouteSelection = (_: string, option: unknown) => {
    if ((option as { label: string })?.label) {
      const { label: index } = option as { label: string };
      const targetUrl = requestedRoutes[Number(index)].url;
      if (index && targetUrl && Number.isInteger(Number(index))) {
        setTriggeredPages(new Set([...triggeredPages.keys(), targetUrl]));
        navigate(targetUrl);
      } else {
        setSearch("");
      }
    }
  };

  if (canSearch)
    return (
      <Select
        showSearch={true}
        size="middle"
        placeholder="Search settings"
        suffixIcon={<SearchOutlined />}
        onChange={onRouteSelection}
        allowClear={true}
        popupMatchSelectWidth={false}
        onSearch={setSearch}
        onClear={() => setSearch("")}
        labelRender={options => (
          <Typography.Link className={styles["search-page-option"]}>
            {(options as { title?: string[] })?.title?.map(subLabel => (
              <Space key={subLabel} size={0}>
                {subLabel}
                <Seperator />
              </Space>
            ))}
          </Typography.Link>
        )}
        autoClearSearchValue={true}
        notFoundContent={
          <Flex flex={1} justify="center" align="center">
            <LoadingOutlined size={40} />
          </Flex>
        }
      >
        {requestedRoutes.map((route, index) => (
          <Select.Option key={route.value} title={route.label} label={`${index}`}>
            <Flex vertical={false} gap={1}>
              <Typography.Link className={styles["search-page-option"]}>
                {route.label.map(subLabel => (
                  <Space key={subLabel} size={0}>
                    {subLabel}
                    <Seperator />
                  </Space>
                ))}
              </Typography.Link>
              <Flex flex="1" vertical={false} justify="space-between" align="flex-end" gap={5}>
                <Flex flex={1}>{route.isVisited && <Badge count="Visited" color="#e54360" />}</Flex>
                <Flex flex={1} justify="flex-end">
                  {route.level && (
                    <Badge
                      count={levelColors[route.level as keyof typeof levelColors].label}
                      color={levelColors[route.level as keyof typeof levelColors].color}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Select.Option>
        ))}
      </Select>
    );

  return null;
};
