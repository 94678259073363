import { useCallback, useEffect, useState } from "react";

import { NIC_CCY, NUMBER_OF_WALLETS_MULTICUR, PRODUCT_CURRENCY } from "@ni/common/constants";
import { MultiCurrency } from "@ni/common/types";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { Dictionary, Product } from "@ni/sdk/models";

import { useConfigurationApi } from "../api";

export const usePrepareMultiCurrency = (product: Product | undefined) => {
  const [currenciesDictionary, setCurrenciesDictionary] = useState<MultiCurrency[]>([]);
  const [dataSource, setDataSource] = useState<MultiCurrency[]>([]);
  const [currenciesMaxThreshold, setCurrenciesMaxThreshold] = useState(0);
  const { getDictionariesByCode } = useConfigurationApi();

  const mainCurrency = getFormValueFromProductValues(product?.productValues, NIC_CCY, "string") as string;

  const getAutoOpenByCurrencyCode = useCallback(
    (currencyCode: string, isBaseCurrency: boolean) => {
      if (isBaseCurrency) {
        return true;
      }

      const currency = product?.productValues?.find(
        productValue =>
          productValue.value === currencyCode &&
          productValue.fieldCode.startsWith("nic-prod-ppm-w") &&
          productValue.fieldCode.endsWith("ccy"),
      );
      const currencyNumber = currency?.fieldCode.substring(15, 16);
      const isAutoOpen = product?.productValues?.find(
        productValue =>
          productValue.fieldCode.substring(15, 16) === currencyNumber && productValue.fieldCode.includes("autoopen"),
      )?.value;
      return isAutoOpen === "true";
    },
    [product?.productValues],
  );

  const getPriorityByCurrencyCode = useCallback(
    (currencyCode: string, isBaseCurrency: boolean) => {
      if (isBaseCurrency) {
        return parseInt(
          product?.productValues?.find(productValue => productValue.fieldCode === "nic-prod-ppm-w00-prior")?.value ??
            "500",
          10,
        );
      }

      const currency = product?.productValues?.find(
        productValue =>
          productValue.value === currencyCode &&
          productValue.fieldCode.startsWith("nic-prod-ppm-w") &&
          productValue.fieldCode.endsWith("ccy"),
      );
      const currencyNumber = currency?.fieldCode.substring(15, 16);
      const priority = product?.productValues?.find(
        productValue =>
          productValue.fieldCode.substring(15, 16) === currencyNumber && productValue.fieldCode.includes("prior"),
      )?.value;
      return priority ? parseInt(priority, 10) : undefined;
    },
    [product?.productValues],
  );

  const createDictionary = useCallback(
    (dictionaries: Dictionary[]) => {
      return dictionaries.map(dictionary => {
        const isBaseCurrency = dictionary.value === mainCurrency;
        return {
          priority: getPriorityByCurrencyCode(dictionary?.value, isBaseCurrency),
          currencyCode: dictionary?.value || "",
          currencyName: dictionary?.displayValue || "",
          autoOpening: getAutoOpenByCurrencyCode(dictionary?.value, isBaseCurrency),
          baseCurrency: isBaseCurrency,
        };
      });
    },
    [getAutoOpenByCurrencyCode, getPriorityByCurrencyCode, mainCurrency],
  );

  const prepareDataSource = useCallback((currenciesDictionary: MultiCurrency[]) => {
    return currenciesDictionary.filter(dictionary => dictionary.priority).sort((a, b) => b.priority - a.priority);
  }, []);

  useEffect(() => {
    setDataSource(prepareDataSource(currenciesDictionary));
  }, [currenciesDictionary, prepareDataSource]);

  useEffect(() => {
    if (mainCurrency) {
      Promise.all([getDictionariesByCode(PRODUCT_CURRENCY), getDictionariesByCode(NUMBER_OF_WALLETS_MULTICUR)])
        .then(([dictionaryResponse, maxThresholdResponse]) => {
          setCurrenciesDictionary(createDictionary(dictionaryResponse?.dictionaries ?? []) as MultiCurrency[]);
          const value = parseInt(maxThresholdResponse.dictionaries?.[0].value ?? "3", 10);
          setCurrenciesMaxThreshold(Number.isNaN(value) ? dictionaryResponse.dictionaries?.length ?? 4 - 1 : value);
        })
        .catch(() => {});
    }
  }, [createDictionary, getDictionariesByCode, mainCurrency]);

  return {
    dataSource,
    currenciesDictionary,
    currenciesMaxThreshold,
    setDataSource,
  };
};
