import type { UploadProps } from "antd";

export const uploadFilesProps: UploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      /* empty */
    }
    if (status === "done") {
      /* empty */
    } else if (status === "error") {
      /* empty */
    }
  },
  onDrop() {},
};
