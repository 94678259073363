export const TRANS_LIM_PERIODS = "trans-lim-periods";

export const LTY_PNT_MODE = "lty-pnt-mode";
export const NIC_LTY_ACCR_ROUNDING_DECIMALS = "nic-lty-accr-rounding-decimals";
export const LTY_REFUND_MODE = "lty-refund-mode";

export const LTY_CHECK_RULES = "lty-check-rules";
export const LTY_RULES_CRITERIA = "lty-rules-criteria";

export const LTY_PREF_MIN = "lty-pref-min";
export const LTY_PREF_MAX = "lty-pref-max";

export const LTY_RULE_CNTR = "lty-rule-cntr";
export const LTY_RULE_CNTR_LIST = "lty-rule-cntr-list";

export const LTY_RULE_MCC = "lty-rule-mcc";
export const LTY_RULE_MCC_LIST = "lty-rule-mcc-list";

export const LTY_RULE_MERCH = "lty-rule-merch";
export const LTY_RULE_MERCH_LIST = "lty-rule-merch-list";

export const LTY_RULE_TERM_LIST = "lty-rule-term-list";

export const LTY_PROGRAM_ACCR_TYPE = "type";
export const LTY_PROGRAM_ACCR_FREQ = "lty-program-cum-freq";
export const LTY_PROGRAM_ACCR_MODE = "lty-program-acr-mode";

export const LTY_ACCR_RATE = "lty-accr-rate";
export const LTY_ACCR_CAP_CTD = "lty-accr-cap-ctd";
export const LTY_ACCR_B_RATE = "lty-accr-b-rate";
export const LTY_ACCR_Y_RATE = "lty-accr-y-rate";

export const LTY_POINT_EXPIRY = "lty-exp-enable";
export const LTY_POINT_EXPIRY_WARN = "lty-exp-warn";
export const LTY_POINT_VALIDITY_PERIOD = "lty-exp-move";
export const LTY_POINT_WRITE_OFF = "lty-exp-wo";

export const PRODUCT_IDS = "productIds";

export const LTY_REDEEM_TYPE = "lty-redeem-type";
export const LTY_CASHBACK_AUTO_REDEEM = "lty-cashback-auto-redeem";
export const LTY_CASHBACK_INTERVAL = "lty-cashback-interval";
export const LTY_EXT_AUTO_REDEEM = "lty-ext-auto-redeem";

export const NIC_LTY_RED_RATE_CASH_RATE = "nic-lty-red-rate-cash-rate";
export const NIC_LTY_RED_RATE_EXT_RATE = "nic-lty-red-rate-ext-rate";

export const LTY_EXP_ENABLE = "lty-exp-enable";
export const LTY_EXP_MOVE = "lty-exp-move";
export const LTY_EXP_WO = "lty-exp-wo";

export const LTY_WELC_BONUS_ENABLE = "lty-welc-bonus-enable";
export const NIC_LTY_BONUS_W_TAR_BASE = "nic-lty-bonus_w-tar0-base";
export const NIC_LTY_BONUS_W_TIMER_FROM = "nic-lty-bonus_w-timer-from";
export const NIC_LTY_BONUS_W_TIMER_PER_TYPE = "nic-lty-bonus_w-timer-per-type";
export const NIC_LTY_BONUS_W_TIMER_PER = "nic-lty-bonus_w-timer-per";

// product settings level
export const NIC_LTY_FLAG_DEV = "nic-lty-flag-def";
export const NIC_LTY_FLAG_EDITABLE = "nic-lty-flag-editable";
export const NIC_LTY_ACCR_YEARLY_START = "nic-lty-accr-yearly-start";
export const LTY_RED_CASH_A_TOT = "lty-red-cash-a-tot";
export const NIC_LTY_RED_CASH_A_TOT = "nic-lty-red-cash-a-tot";
export const NIC_LTY_RED_EXT_A_TOT_MBR = "nic-lty-red-ext-a-tot-mbr";
export const LTY_WELC_BONUS_TARGET = "lty-welc-bonus-target";

// pct settings level
export const LTY_ENROL_FEE_EN = "lty-enrol-fee-en";
export const NIC_LTY_ENROL_FEE_VAL = "nic-lty-enrol-fee-val";
export const NIC_LTY_BONUS_W_TAR0_BASE = "nic-lty-bonus_w-tar0-base";
