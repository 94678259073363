import { FormInstance } from "antd/lib/form/hooks/useForm";

import { FormValues } from "@ni/common/types";

export const checkHeader = (headerKey: string, map: { [key: string]: string[] }, form: FormInstance<FormValues>) => {
  let resultValue = false;
  map[headerKey]?.forEach(item => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    resultValue = resultValue || form.getFieldValue(item);
  });

  form.setFieldsValue({
    [headerKey]: resultValue,
  });
};

export const headerSwitchHandler = (
  element: string,
  map: { [key: string]: string[] },
  newValue: boolean,
  form: FormInstance<FormValues>,
) => {
  const newValues: { [key: string]: boolean } = {};

  map[element]?.forEach(item => {
    newValues[item] = newValue;
  });

  form.setFieldsValue(newValues);
};

export const getLimitsPreparedValues = (
  values: {
    [key: string]: string | boolean;
  },
  checkboxFields: { [key: string]: string[] },
): { [key: string]: string } | undefined => {
  // eslint-disable-next-line prefer-const
  let actualValues: { [key: string]: string } = {};

  Object.keys(checkboxFields).forEach(key => {
    if (values[key]) {
      checkboxFields[key].forEach(fieldName => {
        actualValues[fieldName] = (values[fieldName] || "").toString().replace(/(\s)/g, "");
      });
    } else {
      checkboxFields[key].forEach(fieldName => {
        actualValues[fieldName] = "";
      });
    }
  });

  return actualValues;
};
