import { forwardRef, Key, ReactNode } from "react";
import { Form, GetRef, Select as AntdSelect, SelectProps, TooltipProps } from "antd";

import { NetWorkFormItemProps, SelectOption } from "@ni/common/types";

import { TooltipInfo } from "../../TooltipInfo";

interface FormSelectProps extends SelectProps {
  optionList?: SelectOption[];
}

export type NetworkSelectProps = { formItemOptions?: NetWorkFormItemProps } & FormSelectProps;

export const Select = forwardRef<GetRef<typeof AntdSelect>, NetworkSelectProps>(
  ({ optionList, formItemOptions, ...props }, ref) => {
    if (formItemOptions?.name) {
      return (
        <Form.Item
          {...formItemOptions}
          tooltip={undefined}
          label={
            <TooltipInfo
              label={formItemOptions?.label as string}
              code={formItemOptions?.name as string}
              tooltipProps={
                (formItemOptions?.tooltip
                  ? typeof formItemOptions.tooltip === "string"
                    ? { title: formItemOptions?.tooltip }
                    : typeof formItemOptions.tooltip === "object" &&
                        (formItemOptions.tooltip as { title: ReactNode })?.title
                      ? { title: (formItemOptions.tooltip as { title: ReactNode }).title as ReactNode }
                      : formItemOptions.tooltip
                  : {}) as TooltipProps
              }
            />
          }
        >
          <AntdSelect ref={ref} {...props}>
            {[...(optionList ?? [])].map(option => (
              <AntdSelect.Option
                key={option.value as Key}
                label={option.aliasLabel || option.label}
                value={option.value}
              >
                <TooltipInfo
                  label={option.aliasLabel || option.label}
                  code={(option.key ?? option.value)?.toString()}
                  tooltipProps={option?.tooltip ? { title: option.tooltip as ReactNode } : {}}
                />
              </AntdSelect.Option>
            ))}
          </AntdSelect>
        </Form.Item>
      );
    }

    return (
      <AntdSelect ref={ref} {...props}>
        {[...(optionList ?? [])].map(option => (
          <AntdSelect.Option key={option.value as Key} label={option.aliasLabel || option.label} value={option.value}>
            <TooltipInfo
              label={option.aliasLabel || option.label}
              code={(option.key ?? option.value)?.toString()}
              tooltipProps={option?.tooltip ? { title: option.tooltip as ReactNode } : {}}
            />
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    );
  },
);
