import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 8V6H16V8M10 6V18M10 18H12M10 18H8M14 13.5V12H20V13.5M17 12V18M17 18H15.5M17 18H18.5"
      stroke="#888888"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VisualElementIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
