import { ReactNode } from "react";

import { SelectOption } from "@ni/common/types";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

export const balanceComponentsOptions: RadioOptions = {
  code: "nic-mtp-am-formula",
  name: "Balance components that contributed fully into MTP (once they are billed)",
  default: "FEEINT_PERC",
  options: [
    {
      label: "IPP and VAT",
      value: "FEEINT_INS_PERC",
      tooltip: "Installment Payment Plan and Value Added Tax are included fully",
    },
    {
      label: "IPP, VAT and Insurance",
      value: "FEEINT_PERC",
      tooltip: "Installment Payment Plan, Value Added Tax and Insurance are included fully",
    },
    {
      label: "IPP, VAT, Insurance and Fees",
      value: "ALL_FULL",
      tooltip: "Installment Payment Plan, Value Added Tax, Insurance and fees included fully",
    },
    {
      label: "All balance components, except loan",
      value: "FEEINT_FULL",
      tooltip:
        "Only Loan is multiplied by the MTP percentage, other components are included as 100% (once they are billed)",
    },
  ],
};

export const mtpModeOptions: RadioOptions = {
  code: "nic-mtp-am-mode",
  name: "MTP mode for over limit",
  default: "S",
  tooltip: "This parameter specifies the minimum to pay amount calculation when account has over limit.",
  options: [
    {
      label: "Over limit amount is included as part of minimum to pay amount calculation",
      value: "S",
    },
    {
      label: "Over limit amount is excluded from minimum to pay amount calculation and maintained separately",
      value: "O",
    },
  ],
};

export const mtpResultOptions: Options = {
  code: "mtp-res-formula",
  name: "MTP resulting formula",
};
