import { FeeRow } from "@ni/common/types";

export const CASH_LABEL = "Cash";

export const FEES_LIMITS_HEADINGS = [
  {
    label: "Fee fixed",
    tooltip: "The parameter specifies the fixed fee amount to be charged for the transaction.",
  },
  {
    label: "Fee %",
    tooltip:
      "The parameter specifies the percentage of fee amount that needs to be calculated on original transaction. Total Fee = Fee fixed + Fee %",
  },
  {
    label: "Min Fee",
    tooltip:
      "The parameter specifies the minimum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is lesser than the Min fee defined then Min fee will be charged to the customer. If Min Fee > Fee fixed + Fee % then Min Fee will be charged to customer.",
  },
  {
    label: "Max Fee",
    tooltip:
      "The parameter specifies the maximum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is greater than the Max fee defined then Max fee will be charged to the customer. If Max Fee < Fee fixed + Fee % then Max Fee will be charged to customer.",
  },
];

export const FX_FEE_ENABLED = "fx-fee-enabled";

// FX markup
export const NIC_IPS_MARKUP_CASH_FEE_RATE = "nic-ips-markup-cash-fee-rate";
export const NIC_IPS_MARKUP_RETAIL_FEE_RATE = "nic-ips-markup-retail-fee-rate";
export const NIC_IPS_MARKUP_FEES_FIN_ONLY_RETAIL = "nic-ips-markup-fees-fin-only-retail";

// DCC transaction
// Cash

export const NIC_IPS_DCC_CASH_BASE = "nic-ips-dcc-cash-base";
export const NIC_IPS_DCC_CASH_FEE_RATE = "nic-ips-dcc-cash-fee-rate";
export const NIC_IPS_DCC_CASH_MIN = "nic-ips-dcc-cash-min";
export const NIC_IPS_DCC_CASH_MAX = "nic-ips-dcc-cash-max";

// Retail

export const NIC_IPS_DCC_RETAIL_BASE = "nic-ips-dcc-retail-base";
export const NIC_IPS_DCC_RETAIL_FEE_RATE = "nic-ips-dcc-retail-fee-rate";
export const NIC_IPS_DCC_RETAIL_MIN = "nic-ips-dcc-retail-min";
export const NIC_IPS_DCC_RETAIL_MAX = "nic-ips-dcc-retail-max";
export const NIC_IPS_DCC_FEES_FIN_ONLY_RETAIL = "nic-ips-dcc-fees-fin-only-retail";

// Tooltips

export const CASH_TOOLTIP = "Includes cash withdrawal in ATM and POS machines ";
export const FX_MARKUP_DESCRIPTION =
  "FX markup fee is defined as the fee charged to the customer for the transactions where contract currency is different from transaction currency.";
export const DCC_TRANSACTIONS_DESCRIPTION =
  "Dynamic currency conversion is a process whereby the amount of a transaction is converted at the point of sale, ATM or internet to the currency of the card's country of issue. DCC is generally provided by third party operators in association with the merchant, and not by a card issuer. DCC fee is not charged for transactions where FX markup fee is charged.";
export const FEE_FIXED_TOOLTIP = "The parameter specifies the fixed fee amount to be charged for the transaction.";
export const FEE_TOOLTIP =
  "The parameter specifies the percentage of fee amount that needs to be calculated on original transaction. Total Fee = Fee fixed + Fee %";
export const MAX_FEE_TOOLTIP =
  'The parameter specifies the maximum fee amount that needs to be charged to the customer. In case of calculated "Total Fee" is greater than the Max fee defined then Max fee will be charged to the customer. If Max Fee < Fee fixed + Fee % then Max Fee will be charged to customer.';
export const MIN_FEE_TOOLTIP =
  'The parameter specifies the minimum fee amount that needs to be charged to the customer. In case of calculated "Total Fee" is lesser than the Min fee defined then Min fee will be charged to the customer. If Min Fee > Fee fixed + Fee % then Min Fee will be charged to customer.';

// labels

export const FIN_ONLY_RETAIL_LABEL = "Do not block fee amount on authorization for Retail transactions";
export const RETAIL_LABEL = "Retail";

// tab keys

export enum TabKeys {
  FX_MARKUP_KEY = "fxMarkup",
  DCC_TRANSACTION_KEY = "dccTransaction",
}

// FX Markup fields

export const FXMarkupFields = {
  description: FX_MARKUP_DESCRIPTION,
  rows: [
    {
      rowLabel: "Cash",
      tooltip: CASH_TOOLTIP,
      fields: [
        {
          name: NIC_IPS_MARKUP_CASH_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_IPS_MARKUP_RETAIL_FEE_RATE,
          type: "percentage",
        },
      ],
    },
  ] as FeeRow[],
};

// DCC transaction fields

export const DCCTransactionFields = {
  description: DCC_TRANSACTIONS_DESCRIPTION,
  rows: [
    {
      rowLabel: "Cash",
      tooltip: CASH_TOOLTIP,
      fields: [
        {
          name: NIC_IPS_DCC_CASH_BASE,
          type: "default",
        },
        {
          name: NIC_IPS_DCC_CASH_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_IPS_DCC_CASH_MIN,
          type: "min",
        },
        {
          name: NIC_IPS_DCC_CASH_MAX,
          type: "max",
        },
      ],
    },
    {
      rowLabel: "Retail",
      fields: [
        {
          name: NIC_IPS_DCC_RETAIL_BASE,
          type: "default",
        },
        {
          name: NIC_IPS_DCC_RETAIL_FEE_RATE,
          type: "percentage",
        },
        {
          name: NIC_IPS_DCC_RETAIL_MIN,
          type: "min",
        },
        {
          name: NIC_IPS_DCC_RETAIL_MAX,
          type: "max",
        },
      ],
    },
  ] as FeeRow[],
};

// Empty values

export const EMPTY_FX_VALUES = {
  [FX_FEE_ENABLED]: false,
  [NIC_IPS_MARKUP_CASH_FEE_RATE]: null,
  [NIC_IPS_MARKUP_RETAIL_FEE_RATE]: null,
  [NIC_IPS_MARKUP_FEES_FIN_ONLY_RETAIL]: false,
  [NIC_IPS_DCC_CASH_BASE]: null,
  [NIC_IPS_DCC_CASH_FEE_RATE]: null,
  [NIC_IPS_DCC_CASH_MIN]: null,
  [NIC_IPS_DCC_CASH_MAX]: null,
  [NIC_IPS_DCC_RETAIL_BASE]: null,
  [NIC_IPS_DCC_RETAIL_FEE_RATE]: null,
  [NIC_IPS_DCC_RETAIL_MIN]: null,
  [NIC_IPS_DCC_RETAIL_MAX]: null,
  [NIC_IPS_DCC_FEES_FIN_ONLY_RETAIL]: false,
};
