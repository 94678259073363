import { NavLink } from "react-router-dom";

import styles from "./AdminNavigationBar.module.scss";

export const AdminNavigationBar = () => {
  return (
    <div className={styles["links"]}>
      <NavLink to="/admin" className="admin-navigation-bar-link">
        <span>Administration</span>
      </NavLink>
    </div>
  );
};
