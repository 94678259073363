import { FC } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import { useInsuranceProgramApi } from "../../../../hooks";

import { companyNameOption, enrollmentModeOption, templateNameOption } from "./constants";

export const ProductIdentification: FC = () => {
  const { id: tenantId } = useParams<{ id: string }>();
  const [form] = Form.useForm<FormValues>();
  const { currentInsuranceProgram, insurancePrograms, setInsuarncePrograms, editInsurance } = useInsuranceProgramApi();

  useHydrateForm({
    form,
    entityFields: currentInsuranceProgram?.values
      ? [
          ...(currentInsuranceProgram?.values ?? []),
          ...[
            { fieldCode: templateNameOption.code, value: currentInsuranceProgram?.name },
            { fieldCode: companyNameOption.code, value: currentInsuranceProgram?.insuranceCompanyName },
          ],
        ]
      : [],
    keys: {
      strings: [templateNameOption.code, companyNameOption.code, enrollmentModeOption.code],
    },
    allowParse: false,
  });

  const onFinish = async (values: FormValues) => {
    await editInsurance({
      name: values[templateNameOption.code] as string,
      insuranceCompanyName: values[companyNameOption.code] as string,
      values: [{ code: enrollmentModeOption.code, value: values[enrollmentModeOption.code] as string }],
    }).then(() => {
      const insuranceIndex = insurancePrograms?.findIndex(x => x.id === currentInsuranceProgram.id);
      if (
        Number.isInteger(insuranceIndex) &&
        values[templateNameOption.code] &&
        insurancePrograms[insuranceIndex].name !== values[templateNameOption.code]
      ) {
        setInsuarncePrograms(current => {
          if (current[insuranceIndex]?.name) current[insuranceIndex].name = values[templateNameOption.code] as string;
          return current;
        });
      }
    });
  };

  const relatedLinks = [
    {
      label: "Insurance Program Templates",
      href: `/tenant/${tenantId}/insurance-program-templates`,
    },
  ];

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Main Insurance Program Settings"
      pageSubtitle="Below page allows you to change the insurance programs name, insurance company and enrollment mode."
      level="product"
      size="md"
      formSize="md"
      gap={24}
      submitLabel="Save"
      linkTitle="Product Settings"
      submitHandler={onFinish}
      additionalRoute="/insurance-programs"
      relatedLinks={relatedLinks}
    >
      <NetworkForm.String
        formItemOptions={{
          name: templateNameOption.code,
          label: templateNameOption.name,
          rules: [{ required: true, message: "Insurance program name is required" }],
          initialValue: templateNameOption.default,
        }}
        maxLength={64}
      />

      <NetworkForm.String
        formItemOptions={{
          name: companyNameOption.code,
          label: companyNameOption.name,
        }}
        maxLength={24}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: enrollmentModeOption.code,
          label: enrollmentModeOption.name,
          initialValue: enrollmentModeOption.default,
          tooltip: enrollmentModeOption.tooltip,
        }}
        radioList={enrollmentModeOption.options}
        initialValue={enrollmentModeOption.default as string}
      />
    </CustomFormWrapper>
  );
};
