import { memo, useCallback } from "react";
import update from "immutability-helper";
import type { FC } from "react";
import { useDrop } from "react-dnd";

import { useReduxState } from "@ni/common/hooks";
import { ConfigurationApi } from "@ni/sdk/apis";
import { BatchPatchEventGroupRequest, EventGroup, EventSubgroup, VariableGroup } from "@ni/sdk/models";

import EventGroupCard from "../EventGroupCard";
import { ItemTypes } from "../../constants";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();

interface DraggablePhaseSettingsProps {
  templates: EventGroup[];
}

const ContainerFunc: FC<DraggablePhaseSettingsProps> = props => {
  const { templates } = props;
  const [smsTemplates, setSmsTemplates] = useReduxState<EventGroup[]>("smsTemplates");

  const findEventGroupCard = useCallback(
    (id: string) => {
      const card = smsTemplates?.filter(c => c.id === Number(id))[0] as {
        applyConditions: string;
        eventSubgroups: Array<EventSubgroup>;
        id: number;
        name: string;
        order: number;
        variableGroups: Array<VariableGroup>;
      };
      return {
        card,
        index: smsTemplates?.indexOf(card),
      };
    },
    [smsTemplates],
  );

  const onUpdateEventGroupOrder = (items: EventGroup[]) => {
    const requestBody: EventGroup[] = items.map((item, index) => {
      return {
        applyConditions: item.applyConditions,
        eventSubgroups: item.eventSubgroups,
        id: item.id,
        name: item.name,
        order: index,
        variableGroups: item.variableGroups,
      };
    });

    configurationServiceApi
      .batchPatchEventGroup({ eventGroups: requestBody } as BatchPatchEventGroupRequest)
      .then(() => {})
      .catch(() => {});
  };

  const moveEventGroupCard = useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findEventGroupCard(id);
      setSmsTemplates(
        update(smsTemplates, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },
    [findEventGroupCard, smsTemplates, setSmsTemplates],
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.EVENT_GROUP }));

  return (
    <div ref={drop} className={styles["draggable-phases"]}>
      {templates?.map((template, index) => (
        <EventGroupCard
          key={template.id ?? `new-${template.order ?? "phase"}`}
          template={template}
          id={String(template.id)}
          moveEventGroupCard={moveEventGroupCard}
          findEventGroupCard={findEventGroupCard}
          index={index}
          onUpdateEventGroupOrder={onUpdateEventGroupOrder}
        />
      ))}
    </div>
  );
};

const EventGroupContainer = memo(ContainerFunc);

export default EventGroupContainer;
