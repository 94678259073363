/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useMemo, useRef, useState } from "react";
import { notification } from "antd";
import { copyToClipboard } from "ni-ui-kit";
import { FormInstance } from "rc-field-form";
import { FieldData } from "rc-field-form/lib/interface";

import { FormValues } from "@ni/common/types";

import {
  insuranceWizardDefaults,
  ippWizardDefaults,
  loyaltyWizardDefaults,
  productWizardDefaults,
} from "../../../constants/src/lib/wizardDefaults";
import { useParamsList } from "../routes";
import { useReduxState } from "../store";

type WizardType = "product" | "loyalty" | "insurance" | "ipp" | "";

const getWizardType = (pathname: string) => {
  switch (pathname) {
    case "/create-product":
      return "product";
    case "/create-new-loyalty-program":
      return "loyalty";
    case "/create-insurance-program-template":
      return "insurance";
    case "/create-installment-payment-plan":
      return "ipp";
    default:
      return "";
  }
};

const getDefaults = (type: WizardType) => {
  switch (type) {
    case "product":
      return productWizardDefaults as Record<string, unknown>;
    case "loyalty":
      return loyaltyWizardDefaults as Record<string, unknown>;
    case "insurance":
      return insuranceWizardDefaults as Record<string, unknown>;
    case "ipp":
      return ippWizardDefaults as Record<string, unknown>;
    default:
      return null;
  }
};

export const useProceedWizardForm = (
  form: FormInstance<FormValues>,
  pathName: string,
): {
  handleSave: () => void;
  handleSubmit: () => void;
  handleCopy: () => void;
  canSubmit: boolean;
  isWizard: boolean;
} => {
  const {
    params: { page },
  } = useParamsList(["page"]);

  const type = useMemo(() => getWizardType(pathName), [pathName]);

  const defaultRef = useRef(getDefaults(type));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGlobal] = useReduxState("isLoading");

  const [defaultValue, setDefaultValue] = useReduxState(`Proceed${type}WizardHookDefault`, getDefaults(type));

  const canSubmit = useMemo(() => {
    const pageSubmitValue: unknown =
      defaultRef?.current && page ? defaultRef?.current[page] : defaultValue && page ? defaultValue[page] : null;
    return !!pageSubmitValue;
  }, [defaultValue, page]);

  const notifyUser = (title?: string, message?: string) => {
    notification.info({
      message: title,
      description: message,
    });
  };

  const handleCopy = async () => {
    if (type) {
      try {
        await copyToClipboard(JSON.stringify(defaultRef.current ?? defaultValue));
        notifyUser("Submit values copied to clipboard");
      } catch (error) {
        notifyUser("Failed to copy");
      }
    }
  };

  const handleSave = () => {
    if (type && page && defaultValue) {
      const newUpdatedValue = { ...defaultValue, [page]: form.getFieldsValue() };
      setDefaultValue(newUpdatedValue);
      defaultRef.current = { ...newUpdatedValue };
      notifyUser("Submit values update", `The default values for ${page} page has been updated`);
    }
  };

  const handleSubmit = () => {
    if (type && defaultValue && page && defaultRef.current) {
      setIsLoading(true);
      const values = (defaultRef.current[page] ?? defaultValue[page]) as FormValues;
      if (values) {
        form.setFields(Object.keys(values).map(key => ({ name: key, value: values[key] }) as FieldData));
        setTimeout(() => {
          form.submit();
          setIsLoading(false);
        }, 500);
      }
    }
  };

  return {
    handleSave,
    handleSubmit,
    handleCopy,
    canSubmit: canSubmit && !isLoading && !isLoadingGlobal,
    isWizard: !!type,
  };
};
