import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { colors } from "@ni/common/constants";

const IconSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 8C16.7639 8 15.5555 8.36656 14.5277 9.05332C13.4999 9.74007 12.6988 10.7162 12.2258 11.8582C11.7527 13.0003 11.6289 14.2569 11.8701 15.4693C12.1113 16.6817 12.7065 17.7953 13.5806 18.6694C14.4547 19.5435 15.5683 20.1388 16.7807 20.3799C17.9931 20.6211 19.2497 20.4973 20.3918 20.0242C21.5338 19.5512 22.5099 18.7501 23.1967 17.7223C23.8835 16.6945 24.25 15.4861 24.25 14.25C24.25 12.5924 23.5915 11.0027 22.4194 9.83058C21.2473 8.65848 19.6576 8 18 8ZM18 18C17.2583 18 16.5333 17.7801 15.9166 17.368C15.2999 16.956 14.8193 16.3703 14.5355 15.6851C14.2516 14.9998 14.1774 14.2458 14.3221 13.5184C14.4668 12.791 14.8239 12.1228 15.3484 11.5983C15.8728 11.0739 16.541 10.7167 17.2684 10.5721C17.9958 10.4274 18.7498 10.5016 19.4351 10.7855C20.1203 11.0693 20.706 11.5499 21.118 12.1666C21.5301 12.7833 21.75 13.5083 21.75 14.25C21.749 15.2443 21.3536 16.1975 20.6506 16.9006C19.9475 17.6036 18.9943 17.999 18 18Z"
        fill={colors.primaryColor}
      />
      <path
        d="M18 0.5C14.5388 0.5 11.1554 1.52636 8.27753 3.44928C5.39967 5.37221 3.15665 8.10533 1.83212 11.303C0.507582 14.5007 0.161024 18.0194 0.836265 21.4141C1.51151 24.8087 3.17822 27.9269 5.62564 30.3744C8.07306 32.8218 11.1913 34.4885 14.5859 35.1637C17.9806 35.839 21.4993 35.4924 24.697 34.1679C27.8947 32.8434 30.6278 30.6003 32.5507 27.7225C34.4737 24.8446 35.5 21.4612 35.5 18C35.4947 13.3603 33.6493 8.91222 30.3685 5.63148C27.0878 2.35074 22.6397 0.505293 18 0.5ZM10.5 30.9713V29.25C10.501 28.2557 10.8964 27.3025 11.5995 26.5994C12.3025 25.8964 13.2558 25.501 14.25 25.5H21.75C22.7443 25.501 23.6975 25.8964 24.4006 26.5994C25.1036 27.3025 25.499 28.2557 25.5 29.25V30.9713C23.224 32.3003 20.6357 33.0006 18 33.0006C15.3644 33.0006 12.776 32.3003 10.5 30.9713ZM27.99 29.1575C27.9651 27.5179 27.2971 25.9537 26.1299 24.802C24.9627 23.6502 23.3898 23.0031 21.75 23H14.25C12.6102 23.0031 11.0373 23.6502 9.87012 24.802C8.70293 25.9537 8.03492 27.5179 8.01001 29.1575C5.74321 27.1334 4.14467 24.4686 3.42605 21.5158C2.70743 18.5631 2.90263 15.4617 3.98581 12.6223C5.06898 9.78297 6.98903 7.33958 9.49172 5.61571C11.9944 3.89183 14.9617 2.96878 18.0006 2.96878C21.0396 2.96878 24.0069 3.89183 26.5095 5.61571C29.0122 7.33958 30.9323 9.78297 32.0155 12.6223C33.0986 15.4617 33.2938 18.5631 32.5752 21.5158C31.8566 24.4686 30.2581 27.1334 27.9913 29.1575H27.99Z"
        fill={colors.primaryColor}
      />
    </svg>
  );
};

export const CarbonUserAvatarIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={IconSvg} {...props} />
);
