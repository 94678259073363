import { memo, useState } from "react";
import { Collapse } from "antd";
import cn from "classnames";
import type { FC } from "react";
import { useDrag, useDrop } from "react-dnd";

import { useReduxState } from "@ni/common/hooks";
import { ConfirmModal, Spin } from "@ni/common/ui";
import { PhaseApi } from "@ni/sdk/apis";
import { CopyPhaseRequest, Phase, State } from "@ni/sdk/models";

import { ItemTypes } from "../../constants";
import { extraHeader, extraPanel } from "../helpers";
import PhaseForm from "../PhaseForm";

import styles from "./styles.module.scss";

const { Panel } = Collapse;
const phaseServiceApi = new PhaseApi();
interface CardProps {
  phase: Phase;
  id: string;
  movePhaseCard: (id: string, to: number) => void;
  findPhaseCard: (id: string) => { index: number };
  index: number;
  workflowId: number;
  getPhases: () => void;
  onUpdatePhasesOrder: (phaseItems: Phase[]) => void;
}

interface Item {
  id: string;
  originalIndex: number;
}
const CardFunc: FC<CardProps> = props => {
  const { phase, id, movePhaseCard, findPhaseCard, index, workflowId, getPhases, onUpdatePhasesOrder } = props;
  const originalIndex = findPhaseCard(id).index;
  const [phaseItems, setPhaseItems] = useReduxState<Phase[]>("phaseItems", []);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.PHASE,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          movePhaseCard(droppedId, index);
        }
        onUpdatePhasesOrder(phaseItems);
      },
    }),
    [id, originalIndex, movePhaseCard],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.PHASE,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findPhaseCard(id);
          movePhaseCard(draggedId, overIndex);
        }
      },
    }),
    [findPhaseCard, movePhaseCard],
  );

  const onChangeCollapse = () => {
    setIsActive(!isActive);
  };

  const onCancelDuplicateModal = () => {
    setIsDuplicateModalOpen(false);
  };

  const onCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSwitchPhase = () => {
    if (phase.id) {
      phaseServiceApi
        .editPhase(
          {
            code: phase.code || "",
            name: phase.name,
            skipConditions: phase.skipConditions,
            order: phase.order,
            phaseType: phase.phaseType,
            state: phase.state === State.DISABLED ? State.ENABLED : State.DISABLED,
          },
          workflowId,
          phase.id,
        )
        .then(response => {
          const newPhaseItemsArray = phaseItems.map(element => {
            if (element.id === phase.id) {
              return response.data;
            }
            return element;
          });

          setPhaseItems(newPhaseItemsArray);
          onCancelDeleteModal();
        })
        .catch(() => {
          onCancelDeleteModal();
        });
    }
  };

  const removeNewPhaseWithOrder = (order: number) => {
    const newPhaseItemsList = phaseItems.filter(obj => obj.order !== order);
    setPhaseItems(newPhaseItemsList);
  };

  const onPhaseDelete = () => {
    setIsLoading(true);
    if (phase.id) {
      phaseServiceApi
        .editPhase(
          {
            code: phase.code || "",
            name: phase.name,
            skipConditions: phase.skipConditions,
            order: phase.order,
            phaseType: phase.phaseType,
            state: State.DELETED,
          },
          workflowId,
          phase.id,
        )
        .then(() => {
          setIsLoading(false);
          const newPhaseItemsArray = [...phaseItems].filter(item => item.id !== phase.id);
          setPhaseItems(newPhaseItemsArray);
          onCancelDeleteModal();
        })
        .catch(() => {
          setIsLoading(false);
          onCancelDeleteModal();
        });
    } else {
      onCancelDeleteModal();
      removeNewPhaseWithOrder(phase.order!);
      setIsLoading(false);
    }
  };

  const onPhaseCopy = (values: CopyPhaseRequest) => {
    phaseServiceApi
      .copyPhase({ newName: values.newName }, workflowId, phase.id!)
      .then(response => {
        setPhaseItems([...phaseItems, response.data]);
        onCancelDuplicateModal();
      })
      .catch(() => {
        onCancelDuplicateModal();
      });
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div
      className={cn(styles["phase"], id !== "undefined" ? isDragging && styles["opacity"] : styles["notDND"])}
      ref={node => {
        if (id !== "undefined") {
          drag(drop(node));
        }
      }}
      key={phase.order}
    >
      <Collapse
        className={styles["phase-collapse"]}
        collapsible="header"
        onChange={() => onChangeCollapse()}
        key={phase.order}
        accordion={true}
      >
        <Panel
          header={extraHeader({
            index,
            itemName: phase.name || "",
            isActive,
            isShowArrow: true,
            phasePagesLength: phase.pages?.filter(page => page.state !== State.DELETED)?.length || 0,
            isShowDescription: true,
          })}
          key={Number(id) ?? phase.order}
          showArrow={false}
          extra={extraPanel({
            onSwitchPhase,
            onCopyModalClick: () => setIsDuplicateModalOpen(true),
            onDeleteModalClick: () => setIsDeleteModalOpen(true),
            phaseState: phase.state || State.DISABLED,
          })}
        >
          <PhaseForm phase={phase} key={id} workflowId={workflowId} getPhases={() => getPhases()} />
        </Panel>
      </Collapse>
      <ConfirmModal
        title="Copy of phase will be created"
        isOpened={isDuplicateModalOpen}
        onCancel={onCancelDuplicateModal}
        onConfirm={onPhaseCopy}
        isLoading={false}
        type="duplicate"
        description="Provide the name for the new phase"
      />
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the phase and all its subordinates (pages, elements). Are you sure?"
        isOpened={isDeleteModalOpen}
        onCancel={onCancelDeleteModal}
        onConfirm={onPhaseDelete}
        isLoading={false}
        type="delete"
      />
    </div>
  );
};

const PhaseCard: FC<CardProps> = memo(CardFunc);
export default PhaseCard;
