import { FC, useEffect } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { initialPlansPagination, useDebaunce, useInstallmentPaymentPlans, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CollapsedLinks, FilterControls, SettingsPageLayout } from "@ni/common/ui";
import { updateFilters } from "@ni/common/utils";

import { IPPTable } from "../../../components";

import { ippFilterInputs } from "./constants";

const filterKeys = ["plan.group", "plan.currency"];

export const InstallmentPaymentPlans: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId } = useParams<{ id: string }>();

  const [isPrintMode] = useReduxState<boolean>("isPrintMode");

  const searchQuery = Form.useWatch<string>("search", form);
  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });

  const { installmentPlans, pagination, setPagination, setFilters } = useInstallmentPaymentPlans({
    tenantId: parseInt(tenantId ?? "0", 10),
    fetchEnabled: true,
    payload: {
      filter: {
        "plan.state": "ACTIVE",
      },
    },
  });

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setFilters(filters => {
        return updateFilters(
          filterKeys,
          {
            filter: {
              "plan.state": "ACTIVE",
            },
            ...filters,
          },
          undefined,
          debouncedQuery,
        );
      });

      setPagination(initialPlansPagination);
    }
  }, [debouncedQuery, setPagination, setFilters]);

  const handleValuesChange = (changedValues: FormValues) => {
    const { search } = changedValues;

    if (typeof search === "string") return;

    setFilters(filters => {
      return updateFilters(
        filterKeys,
        {
          filter: {
            "plan.state": "ACTIVE",
          },
          ...filters,
        },
        changedValues,
        (search as string) || debouncedQuery,
      );
    });

    setPagination(initialPlansPagination);
  };

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/installment-payment-plans`,
      label: "Installment Payment Plans",
    },
  ];

  return (
    <SettingsPageLayout
      pageTitle="Installment Payment Plans"
      pageSubtitle="Below is the list of applicable Installment Payment Plans. Click the “Edit” icon to view and update details for
    the particular pricing control table such as IPP annual interest rate, and IPP processing fee."
      level="pct"
    >
      <FilterControls form={form} inputs={ippFilterInputs} onValuesChange={handleValuesChange} searchName="search" />

      <IPPTable
        planList={installmentPlans}
        tenantId={tenantId!}
        pagination={pagination}
        setPagination={setPagination}
      />

      {!isPrintMode && <CollapsedLinks list={relatedLinks} />}
    </SettingsPageLayout>
  );
};
