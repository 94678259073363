import React, { FC, MouseEventHandler, useCallback, useEffect, useState } from "react";
import { Button, Table, TableColumnType } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { useNavigate } from "react-router-dom";

import { CopyOutlined, DeleteOutlined, EditOutlined, MessageOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { balanceSourceOptions } from "@ni/common/mocks";
import { DashboardFilters } from "@ni/common/types";
import { ActionsCell, ConfirmModal, MenuItemModel, MultipleSelectWindow, Spin, TooltipInfo } from "@ni/common/ui";
import { mapToServerSorting } from "@ni/common/utils";
import { WorkflowApi } from "@ni/sdk/apis";
import { CopyWorkflowRequest, DashboardWorkflow, Order, SortedFilteredPageRequest } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const workflowServiceApi = new WorkflowApi();

interface WorkflowTableViewProps {
  filters: DashboardFilters;
}

const WorkflowTableView: FC<WorkflowTableViewProps> = ({ filters }) => {
  const navigate = useNavigate();
  const [isDuplicateModalOpened, setIsDuplicateModalOpened] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);
  const [isDeleteAllModalOpened, setIsDeleteAllModalOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const [isShowMultipleAction, setIsShowMultipleAction] = useState<boolean>(false);

  const [workflows, setWorkflows] = useReduxState<DashboardWorkflow[]>("workflows", []);
  const [selectedWorkflowID, setSelectedWorkflowID] = useState<number>();
  const [workflowsLoading, setWorkflowsLoading] = useState<boolean>(false);

  const [tablePageConfig, setTablePageConfig] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });
  const [dashboardPageConfig, setDashboardPageConfig] = useState<SortedFilteredPageRequest>();

  const getWorkflows = useCallback((): void => {
    if (dashboardPageConfig && Object.keys(dashboardPageConfig)) {
      setWorkflowsLoading(true);

      workflowServiceApi
        .getWorkflows(dashboardPageConfig)
        .then(response => {
          setTablePageConfig({
            ...tablePageConfig,
            current: (dashboardPageConfig.pageLimits?.number as number) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setWorkflows(response.data.content as DashboardWorkflow[]);
          setWorkflowsLoading(false);
        })
        .catch(() => {
          setWorkflowsLoading(false);
        });
    }
  }, [dashboardPageConfig, setWorkflows, tablePageConfig]);

  useEffect(() => {
    getWorkflows();
  }, [dashboardPageConfig]);

  useEffect(() => {
    setDashboardPageConfig({
      ...filters,
      sorting: dashboardPageConfig?.sorting as Order[],
      pageLimits: {
        size: tablePageConfig.pageSize,
        number: 0,
      },
    });
  }, [filters]);

  const onCancelDuplicateModal = (): void => {
    setIsDuplicateModalOpened(false);
  };

  const onCancelDeleteModal = (): void => {
    setIsDeleteModalOpened(false);
  };

  const onCancelDeleteAllModal = (): void => {
    setIsDeleteAllModalOpened(false);
  };

  const getActionItems = (): MenuItemModel[] => {
    return [
      {
        label: "Edit",
        icon: <EditOutlined className={styles["table-action-icon"]} />,
        actionCallBack: (data: DashboardWorkflow) => {
          const { id } = data;
          navigate(`${id || ""}`);
        },
      },
      {
        label: "Duplicate",
        icon: <CopyOutlined className={styles["table-action-icon"]} />,
        actionCallBack: (data: DashboardWorkflow) => {
          const { id } = data;
          setSelectedWorkflowID(id);
          setIsDuplicateModalOpened(true);
        },
      },
      {
        label: "Delete workflow",
        icon: <DeleteOutlined className={styles["table-action-icon"]} />,
        actionCallBack: (data: DashboardWorkflow) => {
          const { id } = data;
          setSelectedWorkflowID(id);
          setIsDeleteModalOpened(true);
        },
      },
    ];
  };

  const columns: TableColumnType<DashboardWorkflow>[] = [
    Table.SELECTION_COLUMN,
    {
      title: "Workflow",
      dataIndex: ["id"],
      key: "id",
      sorter: (a, b) => Number(a.id) - Number(b.id),
      render: (_: string, item: DashboardWorkflow) => (
        <div className={styles["table-view-workflow-name"]}>{item.id}</div>
      ),
    },
    {
      title: "Region",
      dataIndex: ["region"],
      key: "region",
      sorter: (a, b) => Number(a.region?.name) - Number(b.region?.name),
      render: (_: string, item: DashboardWorkflow) => {
        return (
          <TooltipInfo tooltipProps={{ placement: "topLeft", title: item?.region?.name }}>
            {item?.region?.name}
          </TooltipInfo>
        );
      },
      ellipsis: true,
    },
    {
      title: "Code",
      dataIndex: ["code"],
      key: "code",
      sorter: (a, b) => Number(a.code) - Number(b.code),
      render: (_: string, item: DashboardWorkflow) => {
        return <TooltipInfo tooltipProps={{ placement: "topLeft", title: item.code }}>{item?.code}</TooltipInfo>;
      },
      ellipsis: true,
    },
    {
      title: "Client type",
      dataIndex: ["clientType"],
      key: "clientType",
      sorter: (a, b) => a.clientType!.length - b.clientType!.length,
      render: (_: string, item: DashboardWorkflow) => {
        return item?.clientType;
      },
    },
    {
      title: "Product type",
      dataIndex: ["productType"],
      key: "productType",
      sorter: (a, b) => a.productType!.length - b.productType!.length,
      render: (_: string, item: DashboardWorkflow) => {
        return item?.productType || "-";
      },
    },
    {
      title: "Balance source",
      dataIndex: ["balanceSource"],
      key: "balanceSource",
      sorter: (a, b) => a.balanceSource!.length - b.balanceSource!.length,
      render: (_: string, item: DashboardWorkflow) => {
        return (
          <TooltipInfo
            tooltipProps={{
              placement: "topLeft",
              title: balanceSourceOptions.find(option => option.value === item.balanceSource)?.label || "-",
            }}
          >
            {balanceSourceOptions.find(option => option.value === item.balanceSource)?.label || "-"}
          </TooltipInfo>
        );
      },
      ellipsis: true,
    },
    {
      title: "Product subtype",
      dataIndex: ["productSubtype"],
      key: "productSubtype",
      sorter: (a, b) => a.productSubtype!.length - b.productSubtype!.length,
      render: (_: string, item: DashboardWorkflow) => {
        return item.productSubtype || "-";
      },
    },
    {
      title: "State",
      dataIndex: ["state"],
      key: "state",
      sorter: (a, b) => a.state!.length - b.state!.length,
      render: (_: string, item: DashboardWorkflow) => {
        return item.state;
      },
    },
    {
      title: "Workflow Type",
      dataIndex: ["workflowType"],
      key: "workflowType",
      sorter: (a, b) => a.workflowType!.length - b.workflowType!.length,
      render: (_: string, item: DashboardWorkflow) => {
        return item.workflowType;
      },
    },
    {
      title: "",
      dataIndex: ["comment"],
      key: "comment",
      width: 100,
      render: (_: string, item: DashboardWorkflow) => {
        return (
          item.businessDescription && (
            <div className={styles["description"]}>
              <TooltipInfo tooltipProps={{ title: item.businessDescription }}>
                <MessageOutlined className={styles["table-cell-icon"]} />
              </TooltipInfo>
            </div>
          )
        );
      },
    },
    {
      title: "",
      dataIndex: ["actions"],
      key: "actions",
      width: 100,
      render: (_: string, item: DashboardWorkflow) => (
        <div>
          <ActionsCell items={getActionItems()} rowData={item} />
        </div>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (newSelectedRowKeys.length > 1) {
      setIsShowMultipleAction(true);
    } else {
      setIsShowMultipleAction(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<DashboardWorkflow> | SorterResult<DashboardWorkflow>[],
  ) => {
    let sortArray: Order[] = [];
    if (sorter) {
      if (Array.isArray(sorter)) {
        sortArray = sorter
          .filter(sort => sort.order)
          .map(sortObject => ({
            direction: mapToServerSorting(sortObject.order),
            property: sortObject.columnKey as string,
          }));
      } else if (sorter.order) {
        sortArray = [{ property: sorter.columnKey as string, direction: mapToServerSorting(sorter.order) }];
      }
    }

    setDashboardPageConfig({
      ...dashboardPageConfig,
      pageLimits: {
        size: pagination.pageSize,
        number: (pagination.current as number) - 1,
      },
      sorting: sortArray.length ? sortArray : undefined,
    });
  };

  const handleDeleteWorkflow = (id: number) => {
    setIsLoading(true);
    workflowServiceApi
      .deleteWorkflow(selectedWorkflowID ?? id)
      .then(() => {
        setIsLoading(false);
        setIsDeleteModalOpened(false);
        setIsDeleteAllModalOpened(false);
        setSelectedWorkflowID(undefined);
        setIsShowMultipleAction(false);
      })
      .catch(() => {
        setIsLoading(false);
        setSelectedWorkflowID(undefined);
        setIsShowMultipleAction(false);
      });
  };

  const handleDeleteSelectedWorkflows = () => {
    selectedRowKeys.forEach((i, index) => {
      if (index !== selectedRowKeys.length - 1) {
        handleDeleteWorkflow(Number(i));
      } else {
        handleDeleteWorkflow(Number(i));
        getWorkflows();
      }
    });
  };

  const deleteOneWorkflow = (id: number) => {
    handleDeleteWorkflow(id);
    getWorkflows();
  };

  if (isLoading) {
    return <Spin />;
  }

  const handleConfirmDuplicateFn = () => {
    setIsLoading(false);
    setIsShowMultipleAction(false);
    getWorkflows();
    onCancelDuplicateModal();
  };

  const handleConfirmDuplicate = (values: CopyWorkflowRequest) => {
    setIsLoading(true);

    workflowServiceApi
      .copyWorkflow({ code: values.code, businessDescription: values.businessDescription }, Number(selectedWorkflowID))
      .then(() => {
        handleConfirmDuplicateFn();
      })
      .catch(() => {
        handleConfirmDuplicateFn();
      });
  };

  return (
    <div className={styles["workflow-table"]}>
      <Table<DashboardWorkflow>
        rowSelection={rowSelection}
        className={styles["workflow-table-view"]}
        columns={columns}
        dataSource={workflows}
        loading={workflowsLoading}
        rowKey={(item: DashboardWorkflow) => {
          return item?.id;
        }}
        onChange={handleTableChange}
        pagination={tablePageConfig}
        onRow={workflow => {
          return {
            onDoubleClick: () => navigate(`/admin/workflows/${workflow.id || ""}`),
          };
        }}
      />

      <ConfirmModal
        title="Copy of workflow will be created"
        isOpened={isDuplicateModalOpened}
        onCancel={onCancelDuplicateModal}
        onConfirm={handleConfirmDuplicate as unknown as MouseEventHandler<HTMLElement>}
        isLoading={false}
        type="duplicate"
        description="Please enter the code and business description to create copy."
      />

      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the workflow and all its subordinates (phases, pages, elements). Are you sure?"
        isOpened={isDeleteModalOpened}
        onCancel={onCancelDeleteModal}
        onConfirm={deleteOneWorkflow as unknown as MouseEventHandler<HTMLElement>}
        isLoading={false}
        type="delete"
      />
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the workflows and all their subordinates (phases, pages, elements). Are you sure?"
        isOpened={isDeleteAllModalOpened}
        onCancel={onCancelDeleteAllModal}
        onConfirm={handleDeleteSelectedWorkflows}
        isLoading={false}
        type="delete"
      />

      <MultipleSelectWindow
        isShow={isShowMultipleAction}
        setIsShow={setIsShowMultipleAction}
        count={selectedRowKeys.length}
      >
        <Button
          type="link"
          onClick={() => {
            setIsDeleteAllModalOpened(true);
            setIsShowMultipleAction(false);
          }}
        >
          <DeleteOutlined />
          <span>Delete all</span>
        </Button>
      </MultipleSelectWindow>
    </div>
  );
};

export default WorkflowTableView;
