import { ReactNode } from "react";

import { blockCodeValuesAndLabels, FieldValue, ProductBcParams } from "@ni/common/constants";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface DropdownOptions extends Options {
  options?: FieldValue[];
}

export const rolloverPlanOption: DropdownOptions = {
  code: "nic-plan-rollover-dlq-level-plan",
  name: "Plan rollover delinquency level",
  default: "3",
  options: blockCodeValuesAndLabels.get(ProductBcParams.delinquencyLevel),
  tooltip:
    "This parameter specifies the delinquency level at which plan will be rolled over. Once the account reached to defined delinquency level then IPP plan balance amount will be moved to retail plan.",
};
