import { SelectOption } from "@ni/common/types";

export const elementTypeListOptions: SelectOption[] = [
  { value: "ONE_OF", label: "ONE_OF" },
  { value: "MULTIPLE_OF", label: "MULTIPLE_OF" },
  { value: "DROPDOWN", label: "DROPDOWN" },
  { value: "DROPDOWN_MULTISELECT", label: "DROPDOWN_MULTISELECT" },
  { value: "TEXT", label: "TEXT" },
  { value: "DATE_PICKER", label: "DATE_PICKER" },
  { value: "DATE_TIME_PICKER", label: "DATE_TIME_PICKER" },
  { value: "TIME_PICKER", label: "TIME_PICKER" },
  { value: "BOOLEAN_CHECKBOX", label: "BOOLEAN_CHECKBOX" },
  { value: "BOOLEAN_SWITCH", label: "BOOLEAN_SWITCH" },
  { value: "BLOCK_HEADER", label: "BLOCK_HEADER" },
  { value: "TAB", label: "TAB" },
  { value: "SUB_TAB", label: "SUB_TAB" },
  { value: "TABLE_HEADER", label: "TABLE_HEADER" },
  { value: "TABLE_LABELS", label: "TABLE_LABELS" },
  { value: "TABLE_ROW_OF_2", label: "TABLE_ROW_OF_2" },
  { value: "TABLE_ROW_OF_3", label: "TABLE_ROW_OF_3" },
  { value: "TABLE_ROW_OF_4", label: "TABLE_ROW_OF_4" },
  { value: "TABLE_ROW_OF_5", label: "TABLE_ROW_OF_5" },
];

export const elementTypeGroup: SelectOption[] = [
  { value: "simple_inputs", label: "simple_inputs" },
  { value: "visual_only_elements", label: "visual_only_elements" },
  { value: "complex_elements", label: "complex_elements" },
];

export const featureList: SelectOption[] = [
  { value: "FEATURE_ONE", label: "Feature one" },
  { value: "FEATURE_TWO", label: "Feature two" },
  { value: "FEATURE_THREE", label: "Feature three" },
];

export const codeList: SelectOption[] = [
  { value: "CODE_ONE", label: "Code one" },
  { value: "CODE_TWO", label: "Code two" },
  { value: "CODE_THREE", label: "Code three" },
];

export const mockElementGroupOptions: SelectOption[] = [
  { value: "all", label: "All" },
  { value: "data-capture", label: "Data capture" },
  { value: "visual-element", label: "Visual element" },
];

export const mockStateOptions: SelectOption[] = [
  { value: "ENABLED", label: "ENABLED" },
  { value: "DISABLED", label: "DISABLED" },
  { value: "DELETED", label: "DELETED" },
];
