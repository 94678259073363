import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33268 3.45101C10.3587 2.27888 12.6587 1.6634 14.9993 1.66701C22.3633 1.66701 28.3327 7.63634 28.3327 15.0003C28.3327 22.3643 22.3633 28.3337 14.9993 28.3337C7.63535 28.3337 1.66602 22.3643 1.66602 15.0003C1.66602 12.5723 2.31535 10.2937 3.45002 8.33367"
      fill="#FFEDEF"
    />
    <path
      d="M8.33268 3.45101C10.3587 2.27888 12.6587 1.6634 14.9993 1.66701C22.3633 1.66701 28.3327 7.63634 28.3327 15.0003C28.3327 22.3643 22.3633 28.3337 14.9993 28.3337C7.63535 28.3337 1.66602 22.3643 1.66602 15.0003C1.66602 12.5723 2.31535 10.2937 3.45002 8.33367"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M21.6654 12.334H8.33203L12.916 8.33398M8.33203 17.6673H21.6654L17.0814 21.6673"
      stroke="#E64360"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DualArrowsRedIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
