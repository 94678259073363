export const CARD_PRODUCTION_FEES_TABS = [
  {
    key: "new" as const,
    children: "New card number, card expire and PIN code (if applicable) are created in the system",
    label: <div title="New card">New card</div>,
  },
  {
    key: "repl" as const,
    children:
      "New card number, CVV, card expire and PIN code (if applicable) are created in the system instead the existing one (i.e. due to card loss or stolen)",
    label: <div title="Replace card">Replace card</div>,
  },
  {
    key: "renexp" as const,
    children: "Card expire date is recalculated according to the separate expiration date setup ",
    label: <div title="Renew expired card">Renew expired card</div>,
  },
  {
    key: "reiss" as const,
    children:
      "Card number remains the same, card expire date is recalculated according to the separate expiration date setup",
    label: <div title="Reissue card">Reissue card</div>,
  },
  {
    key: "plre" as const,
    children:
      "Card number, card expire date, PIN (if applicable) remain the same, only physical plastic (CVV in case of virtual cards) is replaced",
    label: <div title="Reissue plastic">Reissue plastic</div>,
  },
  {
    key: "rnew" as const,
    children: "Only physical plastic is produced for existing virtual card, all card credentials remain the same",
    label: <div title="Physical plastic for virtual card">Physical plastic for virtual card</div>,
    dependency: "$..productValues[?(@.fieldCode == 'reiss-phys' && @.value == 'true')]",
    dependencyType: "PRODUCT",
  },
  {
    key: "pinpr" as const,
    children: "The same PIN is reprinted",
    label: <div title="PIN reprint (same PIN)">PIN reprint</div>,
    dependency: "$..productValues[?(@.fieldCode == 'virt-phys' && @.value != 'V')]",
    dependencyType: "PRODUCT",
  },
  {
    key: "pinre" as const,
    children: "New PIN is printed",
    label: <div title="PIN reissue (new PIN)">PIN reissue</div>,
    dependency: "$..productValues[?(@.fieldCode == 'virt-phys' && @.value != 'V')]",
    dependencyType: "PRODUCT",
  },
];

export const CARD_PRODUCTION_FEES_OPTIONS_AVIABILITY = {
  new: {
    preemb: true,
    pins: true,
    pink: false,
    sde: true,
  },
  repl: {
    preemb: true,
    pins: true,
    pink: false,
    sde: true,
  },
  renexp: {
    preemb: true,
    pins: true,
    pink: true,
    sde: true,
  },
  reiss: {
    preemb: true,
    pins: true,
    pink: true,
    sde: true,
  },
  plre: {
    preemb: true,
    pins: false,
    pink: false,
    sde: true,
  },
  rnew: {
    preemb: false,
    pins: true,
    pink: true,
    sde: true,
  },
  pinpr: {
    preemb: false,
    pins: false,
    pink: false,
    sde: true,
  },
  pinre: {
    preemb: false,
    pins: false,
    pink: false,
    sde: true,
  },
};
