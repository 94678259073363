import { FormValues } from "@ni/common/types";
import { LoyaltyProgramValue } from "@ni/sdk/models";

export const parseDataAsProgramValues = (data: FormValues): LoyaltyProgramValue[] =>
  Object.keys(data).length
    ? Object.keys(data).map(key => {
        const altaredValue = data[key]?.toString().replace(/\s/g, "");
        if (!altaredValue) return { code: key, value: "" };
        const potentioalNumaricValue = +(altaredValue as unknown as number);
        if (Number.isNaN(potentioalNumaricValue)) return { code: key, value: altaredValue } as LoyaltyProgramValue;
        return { code: key, value: potentioalNumaricValue.toString() } as LoyaltyProgramValue;
      })
    : [];
