import { FC } from "react";
import { Form, Space } from "antd";

import { useHydrateForm } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import { usePlanApi } from "../../../../hooks";

import { maxAmountOption, maxDaysOption, minAmountOption } from "./constants";

export const IppTransactionEligibility: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { currentPlan, editPlan, formValuesConverter } = usePlanApi();

  useHydrateForm({
    form,
    entityFields: currentPlan?.planValues ?? [],
    keys: {
      strings: [maxAmountOption.code, maxDaysOption.code, minAmountOption.code],
    },
  });

  const onFinish = async (values: FormValues) => {
    await editPlan({ planValues: formValuesConverter(values) });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Transaction Eligibility for IPP"
      pageSubtitle={
        <Space direction="vertical" size={0}>
          <div>
            The below parameters specify the requirements for retail transactions or balance amounts that turn to IPP.
          </div>
          <div>In case of non-compliance, the plan will not be booked.</div>
        </Space>
      }
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      linkTitle="Plan List"
      submitLabel="Save"
      onValuesChange={form.validateFields}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <NetworkForm.Number
        formItemOptions={{
          name: minAmountOption.code,
          label: minAmountOption.name,
          tooltip: minAmountOption.tooltip,
          rules: [
            {
              type: "number",
              validator: (_, value) => {
                const maximumAmount = +form.getFieldValue(maxAmountOption.code);
                if (value != null && Number.isInteger(maximumAmount)) {
                  return maximumAmount >= value ? Promise.resolve() : Promise.reject();
                }
                return Promise.resolve();
              },
              message: "Minimum amount cannot be higher than Maximum amount",
            },
          ],
        }}
        addonAfter={currentPlan?.currency?.toString()}
        min={minAmountOption.min}
        max={minAmountOption.max}
        precision={minAmountOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: maxAmountOption.code,
          label: maxAmountOption.name,
          tooltip: maxAmountOption.tooltip,
        }}
        addonAfter={currentPlan?.currency?.toString()}
        min={maxAmountOption.min}
        max={maxAmountOption.max}
        precision={maxAmountOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: maxDaysOption.code,
          label: maxDaysOption.name,
          tooltip: maxDaysOption.tooltip,
        }}
        min={maxDaysOption.min}
        max={maxDaysOption.max}
        precision={maxDaysOption.decimalsNumber}
      />
    </CustomFormWrapper>
  );
};
