import { SortOrder } from "antd/lib/table/interface";

import { OrderDirectionEnum } from "@ni/sdk/models";

export const mapToServerSorting = (order?: SortOrder): OrderDirectionEnum => {
  if (order === "descend") {
    return "DESC";
  }

  return "ASC";
};
