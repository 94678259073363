export const loyaltyWizardDefaults = {
  LTY_INIT: { displayName: "Loyalty_test" },
  LTY_PNT: { "lty-pnt-mode": "PN2", "nic-lty-accr-rounding-decimals": 2, "lty-refund-mode": false },
  LTY_TRANS_CRT: { "lty-check-rules": "N" },
  LTY_ACRL: { type: "N" },
  LTY_PNT_ACCR: { "lty-accr-rate": "1.00", "lty-accr-cap-ctd": undefined },
  LTY_REDEEM: { "lty-redeem-type": "C", "lty-cashback-auto-redeem": false },
  LTY_REDEEM_RATE: { "nic-lty-red-rate-cash-rate": 100 },
  LTY_EXPR: { "lty-exp-enable: true, lty-exp-move": 36, "lty-exp-wo": 40 },
  LTY_WELC_BONUS: {
    "lty-welc-bonus-enable": "true",
    "nic-lty-bonus_w-tar0-base": "1.00",
    "nic-lty-bonus_w-timer-from": "START_CONTRACT",
    "nic-lty-bonus_w-timer-per-type": "",
    "nic-lty-bonus_w-timer-per": 0,
  },
  LTY_APPLY: { productIds: [] },
};
