import { FC } from "react";
import { Form, Typography } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings, useRemainingCycleDayAfterDueDate } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { usePct } from "@ni/product-settings";

import {
  enableLatePaymentFeeOptions,
  latePaymentAmountVariance,
  latePaymentDateOptions,
  latePaymentFeeAmountOptions,
} from "./constants";

export const LatePaymentFeePage: FC = () => {
  const { productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();
  const [form] = Form.useForm<FormValues>();

  const { productCurrency: currencyType, currentProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });
  const { pct, onSavePct } = usePct({
    pctId: parseInt(pctId ?? "0", 10),
  });

  useHydrateForm({
    form,
    entityFields: pct?.pctProductValues ?? [],
    keys: {
      strings: [
        enableLatePaymentFeeOptions.code,
        latePaymentAmountVariance.code,
        latePaymentDateOptions.code,
        latePaymentFeeAmountOptions.code,
      ],
    },
    allowParse: true,
  });

  const isLatePaymentFeeEnabled = Form.useWatch<boolean>(enableLatePaymentFeeOptions.code, form);
  const dueDateDays = useRemainingCycleDayAfterDueDate(currentProduct?.parameterTables![0].pctProductValues);

  const onFinish = (values: FormValues) => {
    void onSavePct({
      [latePaymentAmountVariance.code]: "",
      [latePaymentDateOptions.code]: "",
      [latePaymentFeeAmountOptions.code]: "",
      ...values,
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Late Payment Fee"
      pageSubtitle="Late Payment fee is charged to cardholder when the minimum payment is not made towards due amount until the configured late payment date."
      size="md"
      formSize="full"
      gap={30}
      level="pct"
      submitLabel="Save"
      submitHandler={onFinish}
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: enableLatePaymentFeeOptions.code,
          label: <Typography.Text strong={true}>{enableLatePaymentFeeOptions.name}</Typography.Text>,
          initialValue: enableLatePaymentFeeOptions.default,
        }}
      />
      {isLatePaymentFeeEnabled && (
        <>
          <NetworkForm.Number
            min={latePaymentDateOptions.min}
            precision={latePaymentDateOptions.decimalsNumber}
            formItemOptions={{
              name: latePaymentDateOptions.code,
              label: latePaymentDateOptions.name,
              initialValue: latePaymentDateOptions.default,
              tooltip: latePaymentDateOptions.tooltip,
              rules: [
                {
                  type: "number",
                  max: (latePaymentDateOptions.max as number) - dueDateDays,
                  message: "Late payment date cannot exceed remaining cycle days after setting a due date",
                },
              ],
            }}
          />

          <NetworkForm.Number
            addonAfter={currencyType}
            min={latePaymentFeeAmountOptions.min}
            precision={latePaymentFeeAmountOptions.decimalsNumber}
            formItemOptions={{
              name: latePaymentFeeAmountOptions.code,
              label: latePaymentFeeAmountOptions.name,
              tooltip: latePaymentFeeAmountOptions.tooltip,
              rules: [
                {
                  validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                  message: "Minimum fee amount cannot be 0",
                },
                { required: true, message: "Late payment fee amount is required!" },
              ],
            }}
          />

          <NetworkForm.Number
            addonAfter={currencyType}
            min={latePaymentAmountVariance.min}
            precision={latePaymentAmountVariance.decimalsNumber}
            formItemOptions={{
              name: latePaymentAmountVariance.code,
              label: latePaymentAmountVariance.name,
              tooltip: latePaymentAmountVariance.tooltip,
            }}
          />
        </>
      )}
    </CustomFormWrapper>
  );
};
