import { FC } from "react";
import { Form, FormInstance, Space, Switch } from "antd";

import { FeeRow, FormValues } from "@ni/common/types";
import { FeesValuesRow, HeadingsRow, TabContentLayout } from "@ni/common/ui";

import {
  FEES_LIMITS_HEADINGS,
  FIN_ONLY_RETAIL_LABEL,
  NIC_IPS_DCC_FEES_FIN_ONLY_RETAIL,
} from "../TransactionFees.constants";

import styles from "./styles.module.scss";

export interface DCCTransactionTabContentProps {
  activeTabKey: string;
  form: FormInstance<FormValues>;
  productCurrency: string;
  description: string;
  rows: FeeRow[];
}

export const DCCTransactionTabContent: FC<DCCTransactionTabContentProps> = ({
  form,
  productCurrency,
  description,
  rows,
}) => {
  const sectionMiddle = () => {
    return (
      <div className={styles["fields-wrapper"]}>
        <HeadingsRow headings={FEES_LIMITS_HEADINGS} firstColSpan={4} />
        {rows.map(row => (
          <FeesValuesRow
            key={row.rowLabel}
            rowLabel={row.rowLabel}
            rowTooltip={row?.tooltip || undefined}
            form={form}
            productCurrency={productCurrency}
            fields={row.fields}
          />
        ))}
        <Space direction="horizontal" size={8}>
          <Form.Item name={NIC_IPS_DCC_FEES_FIN_ONLY_RETAIL} valuePropName="checked">
            <Switch />
          </Form.Item>
          {FIN_ONLY_RETAIL_LABEL}
        </Space>
      </div>
    );
  };

  return (
    <div className={styles["currency-conversion-page"]}>
      <TabContentLayout description={description} sectionMiddle={sectionMiddle()} />
    </div>
  );
};
