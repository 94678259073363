import { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Modal, Select, Table, TableColumnType } from "antd";
import { SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import cn from "classnames";

import { DeleteOutlined, EditOutlined, PlusOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { editableProductStates, elementGroupOptions, featureList } from "@ni/common/mocks";
import { ConfirmModal, ValidationModal } from "@ni/common/ui";
import { onConditionChangeValidation } from "@ni/common/utils";
import { ElementApi, ConfigurationApi } from "@ni/sdk/apis";
import {
  ChangeElementRequest,
  CreateElementRequest,
  Element,
  ElementTemplate,
  ElementType,
  Dictionary,
  Dictionaries,
  Order,
  ParameterTable,
  ProductState,
  PutValidationRequest,
  SortedFilteredPageRequest,
  State,
  Validation,
  ValidationType,
} from "@ni/sdk/models";

import styles from "./styles.module.scss";

const { TextArea } = Input;

const elementServiceApi = new ElementApi();

interface ElementFormProps {
  element: Element;
  workflowId: number;
  phaseId: number;
  pageId: number;
  getElements: () => void;
}

interface ValidationModalProps {
  id?: string;
  validationType: ValidationType;
  value: string;
  message: string;
}

interface ElementDetailsForm {
  id: number;
  elementGroup: string;
  elementType: ElementType;
  label: string;
  hint: string;
  featureId: number;
  code: string;
  enumCode: string;
  disabledConditions: string;
  hiddenConditions: string;
  defaultValue: string;
  suggestedValue: string;
  state: State;
  editableProductState: ProductState;
  editableTenantState: ProductState;
  validations: Array<Validation>;
  order: number;
}

const emptyValidationConfig = {
  id: undefined,
  validationType: undefined,
  value: "",
  message: "",
  index: undefined,
};

const configurationApi = new ConfigurationApi();

const ElementForm: FC<ElementFormProps> = props => {
  const { element, workflowId, phaseId, pageId, getElements } = props;
  const [searchForm] = Form.useForm();

  const [elementItems, setElementItems] = useReduxState<Element[]>("elementItems", []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);
  const [isDeleteValidationModalOpened, setIsDeleteValidationModalOpened] = useState<boolean>(false);
  const [isValidationModalOpened, setIsValidationModalOpened] = useState<boolean>(false);
  const [isAddValidation, setIsAddValidation] = useState<boolean>(false);
  const [maxOrderValue, setMaxOrderValue] = useState<number>(-1);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [validationItems, setValidationItems] = useState<PutValidationRequest[]>([]);
  const [selectedValidationItem, setSelectedValidationItem] = useState<Validation>();
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedDictionary, setSelectedDictionary] = useState<string>("");
  const [selectedValidationIndex, setSelectedValidationIndex] = useState<number>();
  const [selectedElementTemplateItem] = useReduxState<ElementTemplate>("selectedElementTemplateItem", {});
  const [selectedElementTemplateRow] = useReduxState<ParameterTable>("selectedElementTemplateRow", {});
  const [form] = Form.useForm();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [dashboardPageConfig, setDashboardPageConfig] = useState<SortedFilteredPageRequest>({
    pageLimits: {
      size: 10,
      number: 0,
    },
  });
  const [tablePageConfig, setTablePageConfig] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });
  const [dictionaries, setDictionaries] = useReduxState<Dictionaries[]>("dictionaries");
  const [filteredDictionaries, setFilteredDictionaries] = useState<Dictionaries[]>([]);

  const onCancelDeleteModal = (): void => {
    setIsDeleteModalOpened(false);
  };

  const onCancelDeleteValidationModal = (): void => {
    setIsDeleteValidationModalOpened(false);
  };

  const onCancelValidationModal = (): void => {
    setIsValidationModalOpened(false);
    setIsAddValidation(false);
  };

  const onSetFilteredDictionaries = (elements: Dictionaries[]) => {
    const searchValue = searchForm.getFieldValue("searchValue") as string;
    if (searchValue) {
      setFilteredDictionaries(elements.filter(elem => elem.code.toLowerCase().includes(searchValue.toLowerCase())));
    } else {
      setFilteredDictionaries(elements);
    }
  };

  const getDictionariesDictionaryList = useCallback((): void => {
    if (dashboardPageConfig && Object.keys(dashboardPageConfig)) {
      setIsLoading(true);
      configurationApi
        .getDictionariesList(dashboardPageConfig)
        .then(response => {
          setTablePageConfig({
            ...tablePageConfig,
            current: (dashboardPageConfig.pageLimits?.number as number) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setDictionaries(response.data.content as Dictionaries[]);
          onSetFilteredDictionaries(response.data.content as Dictionaries[]);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [dashboardPageConfig, tablePageConfig]);

  useEffect(() => {
    onSetFilteredDictionaries(dictionaries);
  }, [dictionaries]);

  useEffect(() => {
    getDictionariesDictionaryList();
  }, [dashboardPageConfig]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<Dictionaries> | SorterResult<Dictionaries>[],
  ) => {
    let sortArray: Order[] = [];
    if (sorter) {
      if (Array.isArray(sorter)) {
        sortArray = sorter
          .filter(sort => sort.order)
          .map(sortObject => ({
            property: sortObject.columnKey as string,
          }));
      } else if (sorter.order) {
        sortArray = [{ property: sorter.columnKey as string }];
      }
    }

    setDashboardPageConfig({
      ...dashboardPageConfig,
      pageLimits: {
        size: pagination.pageSize,
        number: (pagination.current as number) - 1,
      },
    });
  };

  useEffect(() => {
    if (element) {
      setValidationItems(element.validations ?? []);
    }
  }, [element]);

  useEffect(() => {
    const maxOrder = elementItems.reduce((max, obj) => (max.order > obj.order ? max : obj));
    setMaxOrderValue(maxOrder.order);
  }, [elementItems]);

  const onSaveElement = (requestBody: ChangeElementRequest, elementId: string) => {
    setIsLoading(true);
    elementServiceApi
      .editElement({ ...requestBody, order: element.order }, workflowId, phaseId, pageId, Number(elementId))
      .then(() => {
        setIsLoading(false);
        getElements();
        setIsEnabled(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsEnabled(false);
      });
  };

  const onCreateElement = (requestBody: CreateElementRequest) => {
    setIsLoading(true);
    elementServiceApi
      .createElement({ ...requestBody, featureId: 1, order: maxOrderValue + 1 }, workflowId, phaseId, pageId)
      .then(() => {
        setIsLoading(false);
        setIsEnabled(false);
        getElements();
      })
      .catch(() => {
        setIsLoading(false);
        setIsEnabled(false);
      });
  };

  const onFinish = (values: ElementTemplate) => {
    setIsLoading(true);
    const requestBody: CreateElementRequest = {
      elementGroup: values.elementGroup ?? "",
      elementType: values.elementType ?? ElementType.TEXT,
      label: values.label,
      hint: values.hint,
      featureId: Number(values.feature?.code),
      code: values.code ?? "",
      disabledConditions: values.disabledConditions,
      hiddenConditions: values.hiddenConditions,
      defaultValue: values.defaultValue,
      suggestedValue: values.suggestedValue,
      state: values.currentState ?? State.ENABLED,
      editableProductState: values.editableProductState,
      editableTenantState: values.editableTenantState,
      order: form.getFieldValue("order") as number,
      validations: validationItems,
      elementTemplateId: selectedElementTemplateRow.id!,
    };

    if (values.id) {
      return onSaveElement(requestBody, String(values.id));
    }
    if (values.id === undefined) {
      return onCreateElement({
        ...requestBody,
        state: State.ENABLED,
        order: maxOrderValue + 1,
      } as CreateElementRequest);
    }

    return false;
  };
  const onSearchChange = () => {
    onSetFilteredDictionaries(dictionaries);
  };

  const extra = (
    <div className={styles["extra-container"]} key={Math.random()}>
      <div className={styles["top-section"]}>
        <Form form={searchForm} onValuesChange={onSearchChange}>
          <Form.Item name="searchValue">
            <Input placeholder="Search" prefix={<SearchOutlined />} autoFocus={true} />
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  const onValueChange = (value: any, values: ElementDetailsForm): void => {
    setIsEnabled(
      ((form.getFieldValue("hiddenConditions")?.length > 0 &&
        onConditionChangeValidation(String(form.getFieldValue("hiddenConditions")))) ||
        form.getFieldValue("hiddenConditions") === undefined ||
        form.getFieldValue("hiddenConditions")?.length === 0) &&
        ((form.getFieldValue("disabledConditions")?.length > 0 &&
          onConditionChangeValidation(String(form.getFieldValue("disabledConditions")))) ||
          form.getFieldValue("disabledConditions") === undefined ||
          form.getFieldValue("disabledConditions")?.length === 0),
    );

    if (values.id) {
      const newElementList: Element[] = elementItems.map(item => {
        if (item.id === values.id) {
          return {
            ...item,
            id: values.id,
            elementGroup: values.elementGroup,
            elementType: values.elementType,
            label: values.label,
            hint: values.hint,
            featureId: values.featureId,
            code: values.code,
            enumCode: values.enumCode,
            disabledConditions: values.disabledConditions,
            hiddenConditions: values.hiddenConditions,
            defaultValue: values.defaultValue,
            suggestedValue: values.suggestedValue,
            state: item.state,
            editableProductState: values.editableProductState,
            editableTenantState: values.editableTenantState,
            validations: item.validations,
            order: item.order,
          };
        }

        return item;
      });
      setElementItems(newElementList);
    } else {
      const newElementList: Element[] = elementItems.map(item => {
        if (
          (values.order === undefined || -1) &&
          !!values.label?.length &&
          !!values.code?.length &&
          !!values.elementType &&
          values.id === undefined &&
          item.order === -1
        ) {
          return {
            elementGroup: values.elementGroup,
            elementType: values.elementType,
            label: values.label,
            hint: values.hint,
            featureId: values.featureId,
            code: values.code,
            disabledConditions: values.disabledConditions,
            hiddenConditions: values.hiddenConditions,
            defaultValue: values.defaultValue,
            suggestedValue: values.suggestedValue,
            state: values.state,
            editableProductState: values.editableProductState,
            editableTenantState: values.editableTenantState,
            validations: values.validations,
            order: 0,
          };
        }

        return item;
      });
      setElementItems(newElementList);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedElementTemplateItem).length) {
      form.setFieldsValue({
        elementGroup: selectedElementTemplateItem.elementGroup,
        elementType: selectedElementTemplateItem.elementType,
        label: selectedElementTemplateItem.label,
        hint: selectedElementTemplateItem.hint,
        featureId: selectedElementTemplateItem.feature,
        code: selectedElementTemplateItem.code,
        disabledConditions: selectedElementTemplateItem.disabledConditions,
        hiddenConditions: selectedElementTemplateItem.hiddenConditions,
        defaultValue: selectedElementTemplateItem.defaultValue,
        suggestedValue: selectedElementTemplateItem.suggestedValue,
        editableProductState: selectedElementTemplateItem.editableProductState,
        editableTenantState: selectedElementTemplateItem.editableTenantState,
        validations: selectedElementTemplateItem.validations,
        order: maxOrderValue,
      });
    } else {
      form.setFieldsValue({
        elementGroup: element.elementGroup,
        elementType: element.elementType,
        label: element.label,
        hint: element.hint,
        featureId: element.feature,
        code: element.code,
        disabledConditions: element.disabledConditions,
        hiddenConditions: element.hiddenConditions,
        defaultValue: element.defaultValue,
        suggestedValue: element.suggestedValue,
        editableProductState: element.editableProductState,
        editableTenantState: element.editableTenantState,
        validations: element.validations,
        order: element.order,
        state: element.state,
      });
    }
    setValidationItems((element.validations ?? []) as PutValidationRequest[]);
  }, [form, selectedElementTemplateItem]);

  const onAddValidation = (values: ValidationModalProps) => {
    if (values.id === "") {
      setValidationItems([
        ...validationItems,
        {
          ...emptyValidationConfig,
          validationType: values.validationType,
          value: values.value,
          message: values.message,
        },
      ]);

      onSaveElement(
        {
          ...element,
          validations: [
            ...validationItems,
            {
              ...emptyValidationConfig,
              validationType: values.validationType,
              value: values.value,
              message: values.message,
            },
          ],
        },
        String(element.id),
      );
    } else {
      const newArray = validationItems.map(item => {
        if (item.id === values.id) {
          return {
            id: values.id,
            validationType: values.validationType,
            value: values.value,
            message: values.message,
          };
        }
        return item;
      });
      onSaveElement(
        {
          ...element,
          validations: newArray,
        },
        String(element.id),
      );
    }
    setIsValidationModalOpened(false);
    setIsAddValidation(false);
  };

  const removeObjectWithIndex = (index: number) => {
    const arrCopy = Array.from(validationItems);
    const objWithIdIndex = arrCopy.findIndex(obj => obj.index === index);
    arrCopy.splice(objWithIdIndex, 1);
    setValidationItems(arrCopy);

    setIsDeleteValidationModalOpened(false);
  };

  const closeSelectModal = () => {
    setVisible(false);
    searchForm.setFieldValue("searchValue", "");
    setFilteredDictionaries(dictionaries);
  };

  const onSelectValue = (code: string) => {
    if (selectedDictionary === code) {
      setSelectedDictionary("");
    } else {
      setSelectedDictionary(code);
    }
  };

  const dictionaryColumns: TableColumnType<Dictionary>[] = [
    {
      title: "Value",
      dataIndex: ["value"],
      key: "value",
      render: (_: string, item: Dictionary) => <div>{item.value}</div>,
    },
    {
      title: "Display value",
      dataIndex: ["displayValue"],
      key: "displayValue",
      render: (_: string, item: Dictionary) => <div>{item.displayValue}</div>,
    },
  ];

  const columns: TableColumnType<Dictionaries>[] = [
    {
      dataIndex: ["id"],
      key: "id",
      render: (_: string, item: Dictionaries) => <div className={styles["code-name"]}>{item.code}</div>,
    },
  ];

  const dictionarysList = (elements: Dictionary[]) => {
    return (
      <div>
        <Table
          className={styles["element-template-dictionary-select"]}
          columns={dictionaryColumns}
          dataSource={elements}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <>
      <Form
        id="elementForm"
        className={styles["element-details-form"]}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={onValueChange}
        initialValues={{ id: element?.id || undefined, elementName: element?.label || "" }}
      >
        <>
          <div className={styles["block"]}>
            <div className={styles["block-title"]}>Element</div>
            <div className={styles["element-details-form-inputs"]}>
              <Form.Item hidden={true} name="id" />
              <Form.Item hidden={true} name="order" />
              <Form.Item hidden={true} name="state" />
              <Form.Item className={styles["element-input"]} label="Element group" name="elementGroup" required={true}>
                <Select
                  placeholder="Select element group"
                  onChange={(value: string) => setSelectedGroup(value)}
                  disabled={true}
                >
                  {elementGroupOptions.map(item => (
                    <Select.Option key={item.label + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className={styles["element-input"]} label="Element type" name="elementType" required={true}>
                <Select placeholder="Select type" disabled={!selectedGroup}>
                  {Object.values(ElementType).map(item => (
                    <Select.Option key={Math.random()} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={styles["element-details-form-inputs"]}>
              <Form.Item className={styles["element-input"]} label="Label" name="label">
                <Input />
              </Form.Item>
              <Form.Item className={styles["element-input"]} label="Hint text" name="hint">
                <TextArea rows={4} />
              </Form.Item>
            </div>
          </div>
          <div className={styles["block"]}>
            <div className={styles["element-details-form-inputs"]}>
              <Form.Item className={styles["element-input"]} label="Feature" name="feature">
                <Select disabled={true}>
                  {featureList.map(branch => (
                    <Select.Option key={branch.label + branch.value} value={branch.value}>
                      {branch.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className={styles["element-input"]} label="Code" name="code">
                <Input disabled={true} />
              </Form.Item>
            </div>
            <div className={cn(styles["element-details-form-inputs"], styles["dictionary-values"])}>
              <Form.Item
                className={cn(styles["element-input"], styles["btn"])}
                label="Values dictionary"
                name="valuesDictionary"
              >
                <Button
                  className={styles["dictionary-values-btn"]}
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <div className={styles["text"]}>{selectedDictionary || "Select..."}</div>
                  <RightOutlined className={styles["icon"]} />
                </Button>
              </Form.Item>
            </div>
          </div>
          <div className={styles["block"]}>
            <div className={styles["element-details-form-inputs"]}>
              <Form.Item className={styles["element-input"]} label="Disabling conditions" name="disabledConditions">
                <Input />
              </Form.Item>
              <Form.Item className={styles["element-input"]} label="Hiding conditions" name="hiddenConditions">
                <Input />
              </Form.Item>
            </div>
            <div className={styles["element-details-form-inputs"]}>
              <Form.Item className={styles["element-input"]} label="Default value" name="defaultValue">
                <Input />
              </Form.Item>
              <Form.Item className={styles["element-input"]} label="Suggested value" name="suggestedValue">
                <Input />
              </Form.Item>
            </div>
            <div className={styles["element-details-form-inputs"]}>
              <Form.Item
                className={cn(styles["element-input"], styles["long"])}
                label="Editable product state"
                name="editableProductState"
              >
                <Select allowClear={true} placeholder="Please select" options={editableProductStates} />
              </Form.Item>
            </div>
            <div className={styles["element-details-form-inputs"]}>
              <Form.Item
                className={cn(styles["element-input"], styles["long"])}
                label="Editable tenant state"
                name="editableTenantState"
              >
                <Select allowClear={true} placeholder="Please select" options={editableProductStates} />
              </Form.Item>
            </div>
          </div>

          <div className={cn(styles["block"], styles["validation"])}>
            <div className={styles["block-title"]}>Validations</div>
            {validationItems &&
              validationItems.map((item, index) => (
                <div className={styles["validation-item"]} key={Math.random()}>
                  <div className={styles["validation-type"]}>
                    {item.validationType} <div className={styles["validation-value"]}>({item.value})</div>
                  </div>
                  <div className={styles["message"]}>{item.message}</div>
                  <div className={styles["actions"]}>
                    <EditOutlined
                      className={styles["button"]}
                      onClick={() => {
                        setIsValidationModalOpened(true);
                        setIsAddValidation(false);
                        setSelectedValidationItem(item);
                      }}
                    />
                    <DeleteOutlined
                      className={styles["button"]}
                      onClick={() => {
                        setIsDeleteValidationModalOpened(true);
                        setSelectedValidationIndex(index);
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className={styles["element-details-form-buttons"]}>
            <Button
              className={styles["button"]}
              type="link"
              htmlType="button"
              onClick={() => {
                setIsValidationModalOpened(true);
                setIsAddValidation(true);
              }}
            >
              <PlusOutlined />
              Add validation
            </Button>
          </div>
          <Button
            loading={isLoading}
            className={styles["element-save-button"]}
            type="primary"
            size="large"
            onClick={form.submit}
            disabled={!isEnabled}
          >
            Save
          </Button>
        </>
      </Form>
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the validation. Are you sure?"
        isOpened={isDeleteModalOpened}
        onCancel={onCancelDeleteModal}
        onConfirm={() => {
          alert("Work in progress");
        }}
        isLoading={false}
        type="delete"
      />
      <ValidationModal
        isOpened={isValidationModalOpened}
        onCancel={onCancelValidationModal}
        onConfirm={onAddValidation}
        isLoading={false}
        isNewValidation={isAddValidation}
        selectedValidationItem={selectedValidationItem}
      />
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the validation. Are you sure?"
        isOpened={isDeleteValidationModalOpened}
        onCancel={onCancelDeleteValidationModal}
        onConfirm={() => removeObjectWithIndex(selectedValidationIndex!)}
        isLoading={false}
        type="delete"
      />
      <Modal
        className={styles["modal-select-option"]}
        title="Values dictionary"
        open={visible}
        width={1000}
        onCancel={closeSelectModal}
        footer={[
          <div className={styles["modal-select-option-footer"]}>
            <Button className={styles["modal-select-option-button"]} key="back" onClick={closeSelectModal}>
              Cancel
            </Button>
            <Button
              className={styles["modal-select-option-button"]}
              disabled={false}
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={closeSelectModal}
            >
              Select
            </Button>
          </div>,
        ]}
      >
        <div className={styles["container"]}>
          <div className={styles["extra-container"]} key={Math.random()}>
            <div className={styles["top-section"]}>
              <Form className={styles["search-form"]} form={searchForm} onValuesChange={onSearchChange}>
                <Form.Item name="searchValue">
                  <Input placeholder="Search" prefix={<SearchOutlined />} autoFocus={true} />
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className={styles["body"]} key={Math.random()}>
            <Table<Dictionaries>
              className={styles["workflow-table-view"]}
              columns={columns}
              dataSource={filteredDictionaries}
              expandable={{
                expandedRowRender: record => dictionarysList(record.dictionaries),
              }}
              loading={isLoading}
              rowKey={(item: Dictionaries) => {
                return item?.code;
              }}
              onChange={handleTableChange}
              pagination={tablePageConfig}
              onRow={dictionary => {
                return {
                  onClick: () => {
                    onSelectValue(dictionary.code);
                  },
                };
              }}
              rowClassName={record => (record.code === selectedDictionary ? "selected-dictionary-value-row" : "")}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ElementForm;
