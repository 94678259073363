/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { Key, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Checkbox, Flex, Form, Space, Table, TablePaginationConfig, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ExpandableConfig } from "antd/lib/table/interface";
import { initialDataFrameworkPagination } from "libs/common/hooks/src/dataframework/constants";
import { useParams } from "react-router-dom";

import { MinusOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useDataFramework, useDebaunce, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { updateFilters } from "@ni/common/utils";
import { ElementDashboard, LevelDashboard, SdmElementDashboard, SectionDashboard } from "@ni/sdk/models";

import { NetworkForm } from "../FormInput";
import { TooltipInfo } from "../TooltipInfo";

import { EditableCell } from "./EditableCell";

import styles from "./styles.module.scss";

interface ElementDashboardTable extends ElementDashboard {
  type: "level" | "section";
}

interface TableData {
  key: string;
  id: number;
  name: string;
  type: "level" | "section";
  children: TableData[] & { children: ElementDashboard[] };
}

type Level = LevelDashboard & { key: string; type: string; children: Section[] };
type Section = SectionDashboard & { key: string; type: string; children: Element[] };
type Element = ElementDashboard & { key: string };

const DataFrameworkTable = memo(() => {
  const [form] = Form.useForm<FormValues>();
  const [isLoading] = useReduxState<boolean>("isLoading");
  const [isPrintMode] = useReduxState<boolean>("isPrintMode", false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showEnabled, setShowEnabled] = useState<boolean>(true);
  const [showMandatory, SetShowMandatory] = useState<boolean>(true);

  const { id: tenantId } = useParams<{ id: string }>();
  const { dataFramework, updateDataFramework, setFilters, setPagination, pagination } = useDataFramework({
    tenantId: parseInt(tenantId ?? "0", 10),
  });

  const debouncedQuery = useDebaunce({ value: searchQuery, delay: 500 });
  const initialLoad = useRef<boolean>(false);

  const getAllKeys = useCallback((data: TableData[]): string[] => {
    const keys: string[] = [];

    const extractKeys = (items: TableData[]) => {
      items.forEach(item => {
        if (!item.key.startsWith("element")) {
          keys.push(item.key);
          if (item.children && item.children.length > 0) {
            extractKeys(item.children);
          }
        }
      });
    };

    extractKeys(data);
    return keys;
  }, []);

  const { initialValues, disabledValues, enabledValues, tableData, initialTableKeys, allTableKeys } = useMemo(() => {
    const initialValues: FormValues = {};
    const disabledValues: FormValues = {};
    const enabledValues: FormValues = {};

    dataFramework?.content
      ?.map(({ element }) => ({
        parmCode: element.parmCode,
        enabled: element.defaultEnabled,
        mandatory: element.defaultMandatory,
        minLength: element.minLength,
        maxLength: element.maxLength,
      }))
      ?.forEach(element => {
        const { parmCode, ...fields } = element;

        Object.entries(fields).forEach(([fieldType, value]) => {
          if (value !== null && value !== undefined) {
            initialValues[`${parmCode}@${fieldType}`] = value;
          }

          if (fieldType === "enabled") {
            enabledValues[`${parmCode}@${fieldType}`] = true;
          }

          disabledValues[`${parmCode}@${fieldType}`] = null;
        });
      });

    const levelMap = new Map<number, Level>();
    const sectionMap = new Map<number, Section>();

    dataFramework?.content?.forEach((item: SdmElementDashboard) => {
      let level = levelMap.get(item.level.id!);
      if (!level) {
        level = {
          key: `level-${item.level.id}`,
          type: "level",
          id: item.level.id,
          name: item.level.name,
          children: [],
        };
        levelMap.set(item.level.id!, level);
      }
      let section = sectionMap.get(item.section.id!);
      if (!section) {
        section = {
          key: `section-${item.section.id}`,
          type: "section",
          id: item.section.id,
          name: item.section.name,
          children: [],
        };
        sectionMap.set(item.section.id!, section);
        level.children.push(section);
      }

      if (item.element.name && (!isPrintMode || item.element.defaultEnabled)) {
        section.children.push({
          key: `element-${item.element.id}`,
          ...item.element,
        });
      }
    });

    levelMap.forEach((level, levelId) => {
      level.children = level.children.filter(section => {
        section.children = section.children.filter(child => {
          return (
            (!isPrintMode || child.defaultEnabled) &&
            (!showEnabled || child.defaultEnabled) &&
            (!showMandatory || child.defaultMandatory)
          );
        });
        return section.children.length > 0;
      });
      if (level.children.length === 0) levelMap.delete(levelId);
    });

    const tableData = Array.from(levelMap.values()).reverse() as TableData[];
    const initialTableKeys = tableData.map(x => x.key);
    const allTableKeys = getAllKeys(tableData);

    return {
      initialValues,
      disabledValues,
      enabledValues,
      tableData,
      initialTableKeys,
      allTableKeys,
    };
  }, [dataFramework?.content, getAllKeys, isPrintMode, showEnabled, showMandatory]);

  useEffect(() => {
    if (Object.keys(initialValues).length && Object.keys(tableData).length) {
      if (isPrintMode) {
        setExpandedRowKeys(allTableKeys);
      }

      if (!initialLoad.current) {
        initialLoad.current = true;
        setExpandedRowKeys(allTableKeys);
      }

      if (!Object.keys(form.getFieldsValue(true) as FormValues).length) {
        form.setFieldsValue(initialValues);
      }
    }
  }, [form, allTableKeys, initialLoad, initialValues, isPrintMode, tableData, initialTableKeys]);

  useEffect(() => {
    if (debouncedQuery !== undefined) {
      setFilters(filters => updateFilters([], filters, undefined, debouncedQuery));
      setPagination(initialDataFrameworkPagination);
    }
  }, [debouncedQuery, setPagination, setFilters]);

  const columns = useMemo(
    () =>
      [
        {
          key: "name",
          title: (
            <Button
              onClick={() =>
                setExpandedRowKeys(prev =>
                  !prev.some((x: Key) => typeof x === "string" && x.startsWith("section"))
                    ? allTableKeys
                    : initialTableKeys,
                )
              }
              icon={
                !expandedRowKeys.some((x: Key) => typeof x === "string" && x.startsWith("section")) ? (
                  <PlusOutlined />
                ) : (
                  <MinusOutlined />
                )
              }
              size="small"
              className={styles["expand-all-custom-button"]}
            />
          ),
          dataIndex: "name",
          rowScope: "row" as const,
          fixed: "left" as const,
          ellipsis: true,
          editable: false,
          width: 200,
          render: (text: string, record: ElementDashboardTable) => (
            <TooltipInfo
              label={
                text?.length > 29 ? (
                  <Typography.Text ellipsis={{ suffix: text.slice(-30).trim(), tooltip: text }}>
                    {text.slice(0, text.length - 30)}
                  </Typography.Text>
                ) : (
                  <Typography.Text>{text}</Typography.Text>
                )
              }
              code={record.parmCode}
              tooltipProps={record.toolTip ? { title: record.toolTip } : {}}
            />
          ),
        },
        {
          key: "enabled",
          title: "Enabled",
          align: "center" as const,
          editable: false,
          width: 50,
          render: (_: string, record: ElementDashboardTable) =>
            !record.type && (
              <Form.Item name={`${record.parmCode}@enabled`} valuePropName="checked" className="m-b-0">
                <Checkbox />
              </Form.Item>
            ),
        },
        {
          key: "mandatory",
          title: "Mandatory",
          align: "center" as const,
          editable: false,
          width: 50,
          render: (_: string, record: ElementDashboardTable) =>
            !record.type && (
              <Form.Item dependencies={[`${record.parmCode}@enabled`]} noStyle={true}>
                {({ getFieldValue, setFieldValue }) => {
                  const isDisabled = !getFieldValue(`${record.parmCode}@enabled`);

                  if (isDisabled && getFieldValue(`${record.parmCode}@mandatory`))
                    setFieldValue(`${record.parmCode}@mandatory`, false);

                  return (
                    <Form.Item name={`${record.parmCode}@mandatory`} valuePropName="checked" className="m-b-0">
                      <Checkbox disabled={!form.getFieldValue(`${record.parmCode}@enabled`)} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ),
        },
        {
          key: "minLength",
          title: "Minimum Length",
          editable: true,
          width: 50,
        },
        {
          key: "maxLength",
          title: "Maximum Length",
          editable: true,
          width: 50,
        },
      ].map(col => {
        if (!col.editable) return col;

        return {
          ...col,
          onCell: (record: ElementDashboardTable) => ({
            form,
            name: `${record.parmCode}@${col.key}`,
            record,
            editable: col.editable,
            title: col.title,
          }),
        };
      }),
    [allTableKeys, expandedRowKeys, form, initialTableKeys],
  );

  const getChangedValues = useCallback((initialValues: FormValues, formValues: FormValues) => {
    const changedValues: FormValues = {};
    const parmCodesWithChanges = new Set<string>();

    Object.keys(formValues).forEach(key => {
      const [parmCode] = key.split("@");
      if (initialValues[key] !== formValues[key]) {
        parmCodesWithChanges.add(parmCode);
      }
    });

    parmCodesWithChanges.forEach(parmCode => {
      Object.keys(formValues).forEach(key => {
        if (key.startsWith(`${parmCode}@`)) {
          changedValues[key] = formValues[key];
        }
      });
    });

    return changedValues;
  }, []);

  const onSubmit = async () => {
    const formValues = form.getFieldsValue(true) as FormValues;
    const changedValues = getChangedValues(initialValues, formValues);
    await updateDataFramework(changedValues);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const expandable: ExpandableConfig<ElementDashboardTable> = {
    expandedRowKeys,
    onExpandedRowsChange: (expandedKeys: readonly Key[]) => setExpandedRowKeys(expandedKeys),
  };

  const rowClassName = useCallback(
    (record: ElementDashboardTable) =>
      record.type === "level" || record.type === "section" ? styles["category-row"] : "",
    [],
  );

  const handleChangeAll = (value: string) => {
    const allNewValue = value as "enable" | "disable" | "";

    if (allNewValue === "enable") form.setFieldsValue(enabledValues);
    else if (allNewValue === "disable") form.setFieldsValue(disabledValues);
    else {
      form.setFieldsValue(initialValues);
    }
  };

  return (
    <Space direction="vertical" size={0} className="w-p-100">
      {!isPrintMode && (
        <Flex justify="space-between">
          <Space direction="horizontal" size={8}>
            <Checkbox checked={showEnabled} onChange={() => setShowEnabled(prev => !prev)}>
              <Typography.Text strong={true}>Show Enabled</Typography.Text>
            </Checkbox>
            <Checkbox checked={showMandatory} onChange={() => SetShowMandatory(prev => !prev)}>
              <Typography.Text strong={true}>Show Mandatory</Typography.Text>
            </Checkbox>
          </Space>
          <Space>
            <Button type="primary" onClick={() => handleChangeAll("enable")}>
              Enable All
            </Button>
            <Button type="primary" onClick={() => handleChangeAll("disable")}>
              Disable All
            </Button>

            <NetworkForm.String
              size="middle"
              placeholder="Search"
              onChange={val => setSearchQuery(val.currentTarget.value)}
              addonAfter={<SearchOutlined />}
              style={{ width: 300 }}
            />
          </Space>
        </Flex>
      )}

      <Form form={form} onFinish={onSubmit} component={false}>
        <Space direction="vertical" size={8} className="w-p-100">
          <Table
            size="small"
            rowKey="key"
            components={components}
            columns={columns as ColumnsType<ElementDashboardTable>}
            dataSource={tableData}
            expandable={expandable}
            loading={isLoading}
            rowClassName={rowClassName}
            pagination={pagination}
            onChange={handleTableChange}
            scroll={{ y: !isPrintMode ? 738 : undefined }}
            rowHoverable={false}
            bordered={true}
            virtual={true}
            className={styles["dataframework-table"]}
          />

          {!isPrintMode && (
            <Button
              className={styles["button"]}
              size="large"
              type="primary"
              htmlType="submit"
              onClick={form.submit}
              disabled={isLoading}
            >
              Save
            </Button>
          )}
        </Space>
      </Form>
    </Space>
  );
});

export { DataFrameworkTable };
