/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InitWizardRequest } from '../model';
// @ts-ignore
import { InitWizardResponse } from '../model';
// @ts-ignore
import { InsuranceWizardRequest } from '../model';
// @ts-ignore
import { InsuranceWizardResponse } from '../model';
/**
 * InsuranceProgramTemplateWizardApi - axios parameter creator
 * @export
 */
export const InsuranceProgramTemplateWizardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Initializes Insurance Program Template creation Wizard for given TenantId and returns Workflow and Workflow page.
         * @summary Initialize Insurance Program Template creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWizard3: async (initWizardRequest: InitWizardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initWizardRequest' is not null or undefined
            assertParamExists('initWizard3', 'initWizardRequest', initWizardRequest)
            const localVarPath = `/api/v1/wizard/insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initWizardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores collected data for given TenantId and InsuranceId and returns Insurance, Tenant and Workflow next pageId
         * @summary Store collected data for given TenantId and InsuranceId
         * @param {InsuranceWizardRequest} insuranceWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processInsuranceProgramTemplateWizardRequest: async (insuranceWizardRequest: InsuranceWizardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceWizardRequest' is not null or undefined
            assertParamExists('processInsuranceProgramTemplateWizardRequest', 'insuranceWizardRequest', insuranceWizardRequest)
            const localVarPath = `/api/v1/wizard/insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insuranceWizardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceProgramTemplateWizardApi - functional programming interface
 * @export
 */
export const InsuranceProgramTemplateWizardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceProgramTemplateWizardApiAxiosParamCreator(configuration)
    return {
        /**
         * Initializes Insurance Program Template creation Wizard for given TenantId and returns Workflow and Workflow page.
         * @summary Initialize Insurance Program Template creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initWizard3(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitWizardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initWizard3(initWizardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stores collected data for given TenantId and InsuranceId and returns Insurance, Tenant and Workflow next pageId
         * @summary Store collected data for given TenantId and InsuranceId
         * @param {InsuranceWizardRequest} insuranceWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest: InsuranceWizardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceWizardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsuranceProgramTemplateWizardApi - factory interface
 * @export
 */
export const InsuranceProgramTemplateWizardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceProgramTemplateWizardApiFp(configuration)
    return {
        /**
         * Initializes Insurance Program Template creation Wizard for given TenantId and returns Workflow and Workflow page.
         * @summary Initialize Insurance Program Template creation Wizard
         * @param {InitWizardRequest} initWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initWizard3(initWizardRequest: InitWizardRequest, options?: any): AxiosPromise<InitWizardResponse> {
            return localVarFp.initWizard3(initWizardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores collected data for given TenantId and InsuranceId and returns Insurance, Tenant and Workflow next pageId
         * @summary Store collected data for given TenantId and InsuranceId
         * @param {InsuranceWizardRequest} insuranceWizardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest: InsuranceWizardRequest, options?: any): AxiosPromise<InsuranceWizardResponse> {
            return localVarFp.processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceProgramTemplateWizardApi - interface
 * @export
 * @interface InsuranceProgramTemplateWizardApi
 */
export interface InsuranceProgramTemplateWizardApiInterface {
    /**
     * Initializes Insurance Program Template creation Wizard for given TenantId and returns Workflow and Workflow page.
     * @summary Initialize Insurance Program Template creation Wizard
     * @param {InitWizardRequest} initWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramTemplateWizardApiInterface
     */
    initWizard3(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig): AxiosPromise<InitWizardResponse>;

    /**
     * Stores collected data for given TenantId and InsuranceId and returns Insurance, Tenant and Workflow next pageId
     * @summary Store collected data for given TenantId and InsuranceId
     * @param {InsuranceWizardRequest} insuranceWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramTemplateWizardApiInterface
     */
    processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest: InsuranceWizardRequest, options?: AxiosRequestConfig): AxiosPromise<InsuranceWizardResponse>;

}

/**
 * InsuranceProgramTemplateWizardApi - object-oriented interface
 * @export
 * @class InsuranceProgramTemplateWizardApi
 * @extends {BaseAPI}
 */
export class InsuranceProgramTemplateWizardApi extends BaseAPI implements InsuranceProgramTemplateWizardApiInterface {
    /**
     * Initializes Insurance Program Template creation Wizard for given TenantId and returns Workflow and Workflow page.
     * @summary Initialize Insurance Program Template creation Wizard
     * @param {InitWizardRequest} initWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramTemplateWizardApi
     */
    public initWizard3(initWizardRequest: InitWizardRequest, options?: AxiosRequestConfig) {
        return InsuranceProgramTemplateWizardApiFp(this.configuration).initWizard3(initWizardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores collected data for given TenantId and InsuranceId and returns Insurance, Tenant and Workflow next pageId
     * @summary Store collected data for given TenantId and InsuranceId
     * @param {InsuranceWizardRequest} insuranceWizardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProgramTemplateWizardApi
     */
    public processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest: InsuranceWizardRequest, options?: AxiosRequestConfig) {
        return InsuranceProgramTemplateWizardApiFp(this.configuration).processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
