import { RoutesTreeItem } from "@ni/common/types";

import { ProductEdit } from "../../../pages";

export const pagesRoute: RoutesTreeItem[] = [
  {
    name: "Point Accrual Setup",
    route: "point-accrual-setup",
    element: <ProductEdit.PointAccrualSetup />,
  },
  {
    name: "Point Redemption Setup",
    route: "point-redemption-setup",
    element: <ProductEdit.PointRedemptionSetup />,
  },
];
