import { LTY_ACCR_YEARLY_RADIO_GROUP, NIC_LTY_ACCR_YEARLY_START } from "@ni/common/constants";
import { FormContent, NetworkForm, TooltipInfo } from "@ni/common/ui";

export const PointAccrual = () => {
  return (
    <FormContent gap={40}>
      <TooltipInfo
        largeLabel="This parameter defines general yearly accrual mode which is applicable for all loyalty programs of the product."
        tooltipProps={{
          title:
            "The entire point accrual setup is specific for each particular loyalty program of the product. Please follow the link at the bottom of the page.",
        }}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: NIC_LTY_ACCR_YEARLY_START,
          label: "Choose yearly accrual auto-start mode",
          tooltip:
            "Yearly accrual can be enabled automatically from the proposed options. This mode represents the moment when the next yearly accrual will be triggered",
        }}
        radioList={LTY_ACCR_YEARLY_RADIO_GROUP}
      />
    </FormContent>
  );
};
