import { ReactNode } from "react";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

export const coverageOption: Options = {
  code: "ins-prem-waive-zero",
  name: "Waive premium on zero coverage amount",
  default: true,
  tooltip:
    "Premium will be waived if customer has paid the full outstanding balance that is used for premium computation",
};

export const transactorOption: Options = {
  code: "ins-prem-waive",
  name: "Waive premium for transactor customer",
  default: false,
  tooltip: "Premium will be waived if customer has paid the previous cycle statement balance",
};
