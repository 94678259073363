export enum ProductBcParams {
  alreadyEdited = "already-edited",
  name = "nic-bc-name",
  status = "nic-status-reason",
  priority = "nic-bc-prior",
  accountHolder = "nic-bc-a1-from-noncompl",
  cardHolder = "nic-bc-c1-from-noncompl_c",
  cardStatus = "nic-bc-c1-from-card_st",
  blockCodeIsPermanent = "nic-dlq_perm",
  delinquencyLevel = "nic-dlq_lev",
  falconBlock = "nic-bc-c1-from-falcon_block",
  reissue = "nic-fc_reiss",
  authorisation = "nic-fc_auth",
  agreement = "nic-fc_agrm",
  sms = "nic-fc_sms",
  token = "nic-fc_tokens",
  agingCalculation = "nic-fc_mtp",
  bureauReporting = "nic-fc_cb_rep",
  directDebit = "nic-fc_dd",
  pointsAccrual = "nic-fc_loy_accr",
  pointsRedemption = "nic-fc_loy_redm",
  insurancePrograms = "nic-fc_ins",
  financeCharges = "nic-fc_finch",
  paymentsFee = "nic-fc_lpf",
  overlimitFee = "nic-fc_ovl",
  abuStatus = "nic-fc_abu_status",
  control = "nic-fc_ncon",
  activation = "nic-r_card_act",
  autoReplacement = "nic-r_card_autorepl",
  accountTransfer = "nic-fc_acctrf",
  accountTransferReplacement = "nic-fc_acctrf_r",
  fcPosting = "nic-fc_posting",
  fcStmt = "nic-fc_stmt",
  fcReport = "nic-fc_report",
  fcMf = "nic-fc_mf",
  fcStmtFee = "nic-fc_stmt_fee",
  fcInactFee = "nic-fc_inact_fee",

  a1 = "nic-bc-a1-allow",
  a2 = "nic-bc-a2-allow",
  a3 = "nic-bc-c1-allow",

  a1ManSet = "nic-bc-a1-man-set",
  a1ManRem = "nic-bc-a1-man-rem",
  a2ManSet = "nic-bc-a2-man-set",
  a2ManRem = "nic-bc-a2-man-rem",
  a3ManSet = "nic-bc-c1-man-set",
  a3ManRem = "nic-bc-c1-man-rem",

  a1Tib1Set = "nic-bc-a1-tib1-set",
  a1Tib1Rem = "nic-bc-a1-tib1-rem",
  a2Tib1Set = "nic-bc-a2-tib1-set",
  a2Tib1Rem = "nic-bc-a2-tib1-rem",
  a3Tib1Set = "nic-bc-c1-tib1-set",
  a3Tib1Rem = "nic-bc-c1-tib1-rem",

  a1Tib2Set = "nic-bc-a1-tib2-set",
  a1Tib2Rem = "nic-bc-a1-tib2-rem",
  a2Tib2Set = "nic-bc-a2-tib2-set",
  a2Tib2Rem = "nic-bc-a2-tib2-rem",
  a3Tib2Set = "nic-bc-c1-tib2-set",
  a3Tib2Rem = "nic-bc-c1-tib2-rem",

  a1FaSet = "nic-bc-a1-fa-set",
  a1FaRem = "nic-bc-a1-fa-rem",
  a2FaSet = "nic-bc-a2-fa-set",
  a2FaRem = "nic-bc-a2-fa-rem",
  a3FaSet = "nic-bc-c1-fa-set",
  a3FaRem = "nic-bc-c1-fa-rem",

  a1SaSet = "nic-bc-a1-sa-set",
  a1SaRem = "nic-bc-a1-sa-rem",
  a2SaSet = "nic-bc-a2-sa-set",
  a2SaRem = "nic-bc-a2-sa-rem",
  a3SaSet = "nic-bc-c1-sa-set",
  a3SaRem = "nic-bc-c1-sa-rem",
}

export const booleanFields = [
  ProductBcParams.control,
  ProductBcParams.activation,
  ProductBcParams.autoReplacement,
  ProductBcParams.accountHolder,
  ProductBcParams.cardHolder,
  ProductBcParams.accountTransfer,
  ProductBcParams.accountTransferReplacement,

  ProductBcParams.a1,
  ProductBcParams.a2,
  ProductBcParams.a3,

  ProductBcParams.a1ManSet,
  ProductBcParams.a1ManRem,
  ProductBcParams.a2ManSet,
  ProductBcParams.a2ManRem,
  ProductBcParams.a3ManSet,
  ProductBcParams.a3ManRem,

  ProductBcParams.a1Tib1Set,
  ProductBcParams.a1Tib1Rem,
  ProductBcParams.a2Tib1Set,
  ProductBcParams.a2Tib1Rem,
  ProductBcParams.a3Tib1Set,
  ProductBcParams.a3Tib1Rem,

  ProductBcParams.a1Tib2Set,
  ProductBcParams.a1Tib2Rem,
  ProductBcParams.a2Tib2Set,
  ProductBcParams.a2Tib2Rem,
  ProductBcParams.a3Tib2Set,
  ProductBcParams.a3Tib2Rem,

  ProductBcParams.a1FaSet,
  ProductBcParams.a1FaRem,
  ProductBcParams.a2FaSet,
  ProductBcParams.a2FaRem,
  ProductBcParams.a3FaSet,
  ProductBcParams.a3FaRem,

  ProductBcParams.a1SaSet,
  ProductBcParams.a1SaRem,
  ProductBcParams.a2SaSet,
  ProductBcParams.a2SaRem,
  ProductBcParams.a3SaSet,
  ProductBcParams.a3SaRem,
];
