export const checkboxFieldsPage10: { [key: string]: string[] } = {
  "nic-c-d-cash-atm-enable": ["nic-c-d-cash-atm-max-am", "nic-c-d-cash-atm-max-nr", "nic-c-d-cash-atm-max-single"],
  "nic-c-d-cash-enable": ["nic-c-d-cash-max-am", "nic-c-d-cash-max-nr", "nic-c-d-cash-max-single"],
  "nic-c-d-cash-pos-enable": ["nic-c-d-cash-pos-max-am", "nic-c-d-cash-pos-max-nr", "nic-c-d-cash-pos-max-single"],
  "nic-c-d-ecomm-enable": ["nic-c-d-ecomm-max-am", "nic-c-d-ecomm-max-nr", "nic-c-d-ecomm-max-single"],
  "nic-c-d-retail-enable": ["nic-c-d-retail-max-am", "nic-c-d-retail-max-nr", "nic-c-d-retail-max-single"],
  "nic-c-d-total-enable": ["nic-c-d-total-max-am", "nic-c-d-total-max-nr", "nic-c-d-total-max-single"],
};

export const switchHeader10: { [key: string]: string[] } = {
  "card-daily-all-cash-enable": ["nic-c-d-cash-enable", "nic-c-d-cash-atm-enable", "nic-c-d-cash-pos-enable"],
  "card-daily-all-retail-enable": ["nic-c-d-retail-enable", "nic-c-d-ecomm-enable"],
  "card-daily-all-total-enable": ["nic-c-d-total-enable"],
};
