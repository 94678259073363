import { DataFrameworkTable, ReadMore, SettingsPageLayout } from "@ni/common/ui";

const pageDesc = `This feature enables the configuration of guidelines for various data elements used in processing requests across different channels (Screens, APIs, Files, etc.). You can indicate whether an element is active and define its mandatory or optional status for different entities. The hierarchy for these entities, established during account onboarding, follows this order: Client, Account, Card.\n\nClient: Identifies customers and records their classification, personal details, and multiple addresses (statement, permanent, home, work). Each client has an associated email and mobile number, and supplementary cards can have unique client numbers storing their details (ID, addresses, etc.).\n\nAccount: Stores contractual and financial information defining customer-product relationships, including financial data, limits, and status. It covers fees, transaction rules, credit limits, interest accumulation, billing cycles, and late payment rules.\n\nCard: Contains card-specific details like the card number, expiration date, and status, and can be designated as primary or supplementary. Supplementary cards share credit limits with primary cards. Card properties include material type, printed customer information, usage limits, bank identification numbers, and randomly assigned numbers by the platform.`;

const DataFrameworkPage = () => (
  <SettingsPageLayout
    size="full"
    pageTitle="Data Framework"
    pageSubtitle={
      <div style={{ maxWidth: 1024 }}>
        <ReadMore length={392} text={pageDesc} />
      </div>
    }
  >
    <DataFrameworkTable />
  </SettingsPageLayout>
);

export { DataFrameworkPage };
