/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { useMemo } from "react";
import { FormInstance } from "antd";

import { FormValues } from "@ni/common/types";

import { BlockInput } from "./constants";

interface SMCRecord {
  code: string;
  fieldCode: string;
  input: BlockInput;
}

interface Props {
  form: FormInstance<FormValues>;
  name: string;
  record: SMCRecord;
}

const RenderCellValue = ({ form, name, record }: Props) => {
  const formValue = form?.getFieldValue(name) as string;

  const getFormattedValue = useMemo(() => {
    if (record?.input?.type === "select") {
      return record.input.options?.find(x => x.value === formValue)?.label;
    }

    return formValue;
  }, [formValue, record]);

  return getFormattedValue;
};

export type { SMCRecord };
export { RenderCellValue };
