export const parseBooleanOrNumber = (value: string): string | boolean | number => {
  if (value === "true" || value === "false") {
    return value === "true";
  }

  if (value === "") {
    return "";
  }

  const numericValue = Number(value);
  return Number.isNaN(numericValue) ? value : numericValue;
};
