import { ReactNode } from "react";
import { FormInstance, Space } from "antd";
import { Rule } from "rc-field-form/lib/interface";

import { FormValues, SelectOption } from "@ni/common/types";
import { NetworkForm, SwitchedInputsBox } from "@ni/common/ui";

export type KeyTab = "interest" | "processing" | "incentive" | "foreclosure";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
  rules?: Rule[];
  addonAfter?: ReactNode;
}

interface RadioOptions extends Options {
  options: SelectOption[];
}

const enableInterestOption: Options = {
  code: "ipp-interest-enabled",
  name: "Enable interest income",
};
const interestRate: NumericOptions = {
  code: "nic-ipp-inst-int-plan-rate",
  name: "Annual interest rate",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  tooltip:
    "This parameter indicates the interest rate that will be applied to the IPP amount. This rate can be redefined for a specific pricing control table of the credit product.",
};
const interestAmountOption: RadioOptions = {
  code: "ipp-int-opt1",
  name: "Calculate interest amount on:",
  default: "W",
  options: [
    {
      label: "The whole deal amount (original balance)",
      value: "W",
    },
    {
      label: "The actual remaining principal amount",
      value: "A",
    },
  ],
};
const dailyInterestOption: RadioOptions = {
  code: "ipp-int-opt2",
  name: "Calculate daily interest rate:",
  default: "Y",
  options: [
    {
      label: "In the same manner for each day and use the actual number of days in the year (365/366)",
      value: "Y",
      tooltip:
        "Daily interest for a billing cycle is calculated as follows: (Account balance * annual rate)/ number of days in a year",
    },
    {
      label: "In accordance with the billing cycle weight in the year, depending on the number of days in the cycle",
      value: "B",
      tooltip:
        "Daily interest for a billing cycle is calculated as follows: (Account balance * annual rate)/ number of days in a billing cycle * 12",
    },
  ],
};
export const tab1 = {
  enableInterestOption,
  interestRate,
  interestAmountOption,
  dailyInterestOption,
  page: ({
    isInterestEnabled,
    isInterestAmountOriginalBalance,
  }: {
    isInterestEnabled: boolean;
    isInterestAmountOriginalBalance: boolean;
  }) => (
    <Space direction="vertical" size="large">
      <p>The following options define the distribution of interest amount across equal monthly installments.</p>
      <NetworkForm.Switch
        formItemOptions={{
          name: enableInterestOption.code,
          label: enableInterestOption.name,
          tooltip: enableInterestOption.tooltip,
          valuePropName: "checked",
          initialValue: enableInterestOption.default,
        }}
      />

      {isInterestEnabled && (
        <>
          <NetworkForm.Number
            formItemOptions={{
              name: interestRate.code,
              label: interestRate.name,
              tooltip: interestRate.tooltip,
            }}
            addonAfter="%"
            min={interestRate.min}
            max={interestRate.max}
            precision={interestRate.decimalsNumber}
          />

          <NetworkForm.Radio
            formItemOptions={{
              name: interestAmountOption.code,
              label: interestAmountOption.name,
              initialValue: interestAmountOption.default,
            }}
            radioList={interestAmountOption.options}
          />

          {!isInterestAmountOriginalBalance && (
            <NetworkForm.Radio
              formItemOptions={{
                name: dailyInterestOption.code,
                label: dailyInterestOption.name,
                initialValue: dailyInterestOption.default,
                rules: [{ required: true, message: `Calculate daily interest rate is required` }],
              }}
              radioList={dailyInterestOption.options}
            />
          )}
        </>
      )}
    </Space>
  ),
} as const;

const enableProcessingFeeOption: Options = {
  code: "ipp-proc-fee-enabled",
  name: "Enable IPP processing fee",
};
const feeProcessingFixedOption: NumericOptions = {
  code: "nic-ipp-proc-plan-base",
  name: "Fee fixed",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>This parameter works with Fee % parameter.</div>
      <div>Processing Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};
const feeProcessingPercentageOption: NumericOptions = {
  code: "nic-ipp-proc-fee-plan-rate",
  name: "Fee %",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  addonAfter: "%",
  tooltip: (
    <Space direction="vertical">
      <div>
        Percentage will be calculated on the IPP booking balance amount. This parameter works with Fee Fixed parameter.
        Processing fee is a summation of Fee Fixed and Fee % parameters.
      </div>
      <div>Processing Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};
const minProcessingFeeOption: NumericOptions = {
  code: "nic-ipp-proc-plan-min",
  name: "Min fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated &quot;Processing Fee&quot; is lesser than the Min fee defined as part of this field then
        Min fee will be charged to the customer.
      </div>
      <div>If Min Fee &gt; Fee fixed + Fee % then Min Fee will be charged to customer.</div>
    </Space>
  ),
};
const maxProcessingFeeOption: NumericOptions = {
  code: "nic-ipp-proc-plan-max",
  name: "Max fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated &quot;Processing Fee&quot; is greater than the Max fee defined as part of this field then
        Max fee will be charged to the customer.
      </div>
      <div>If Max Fee &lt; Fee fixed + Fee % then Max Fee will be charged to customer.</div>
    </Space>
  ),
};
export const tab2 = {
  enableProcessingFeeOption,
  feeProcessingFixedOption,
  feeProcessingPercentageOption,
  minProcessingFeeOption,
  maxProcessingFeeOption,
  page: ({
    form,
    addonAfter,
    feePercentage,
    maxFee,
    minFee,
  }: {
    form: FormInstance<FormValues>;
    addonAfter: ReactNode;
    feePercentage: string;
    maxFee: number;
    minFee: number;
  }) => (
    <Space direction="vertical" size="large">
      <p>
        These parameters specify the fee that needs to be charged to the cardholder as a processing fee in case the
        transaction amount or balance is booked for this plan.
      </p>
      <SwitchedInputsBox
        form={form}
        switchOptions={enableProcessingFeeOption}
        inputsOptionsList={[
          [
            { ...feeProcessingFixedOption, addonAfter },
            feeProcessingPercentageOption,
            {
              ...minProcessingFeeOption,
              isDisabled: !feePercentage,
              max: (maxFee ?? Infinity) - 0.01,
              addonAfter,
            },
            {
              ...maxProcessingFeeOption,
              isDisabled: !feePercentage,
              min: (minFee ?? 0) + 0.01,
              addonAfter,
            },
          ],
        ]}
      />
    </Space>
  ),
} as const;

const enableIncentiveFeeOption: Options = {
  code: "ipp-incent-fee-enabled",
  name: "Enable IPP incentive fee",
};
const feeFixedIncentiveOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-base",
  name: "Fee fixed",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>This parameter works with Fee % parameter.</div>
      <div>Incentive Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};
const feePercentageIncentiveOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-rate",
  name: "Fee %",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  addonAfter: "%",
  tooltip: (
    <Space direction="vertical">
      <div>This parameter works with Fee fIxed parameter.</div>
      <div>Incentive Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};
const minFeeIncentiveOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-min",
  name: "Min fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated fee is lower than the Min fee defined as part of this field then Min fee will be charged
        to the customer.
      </div>
      <div>If Min Fee &gt; Fee fixed + Fee % then Min Fee will be considered as Incentive Fee</div>
    </Space>
  ),
};
const maxFeeIncentiveOption: NumericOptions = {
  code: "nic-ipp-incentive-fee-plan-max",
  name: "Max fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated fee is greater than the Max fee defined as part of this field then Max fee will be
        calculated.
      </div>
      <div>If Max Fee &lt; Fee fixed + Fee % then Max Fee will be considered as Incentive Fee.</div>
    </Space>
  ),
};
export const tab3 = {
  enableIncentiveFeeOption,
  feeFixedIncentiveOption,
  feePercentageIncentiveOption,
  minFeeIncentiveOption,
  maxFeeIncentiveOption,
  page: ({
    form,
    addonAfter,
    feePercentage,
    maxFee,
    minFee,
  }: {
    form: FormInstance<FormValues>;
    addonAfter: ReactNode;
    feePercentage: string;
    maxFee: number;
    minFee: number;
  }) => (
    <Space direction="vertical" size="large">
      <p>
        These parameters specify the fee to calculate in case of IPP booking for the merchant billing. The calculated
        Incentive Fee will be reported to the tenant as part of customized reports and the tenant can reconcile and
        charge the merchant accordingly.
      </p>
      <SwitchedInputsBox
        form={form}
        formDisabled={false}
        switchOptions={enableIncentiveFeeOption}
        inputsOptionsList={[
          [
            { ...feeFixedIncentiveOption, addonAfter },
            feePercentageIncentiveOption,
            {
              ...minFeeIncentiveOption,
              isDisabled: !feePercentage,
              max: (maxFee ?? Infinity) - 0.01,
              addonAfter,
            },
            {
              ...maxFeeIncentiveOption,
              isDisabled: !feePercentage,
              min: (minFee ?? 0) + 0.01,
              addonAfter,
            },
          ],
        ]}
      />
    </Space>
  ),
} as const;

const enableForeclosureFeeOption: Options = {
  code: "ipp-foreclosure-fee-enabled",
  name: "Enable IPP foreclosure fee",
};
const feeFixedForeclosureOption: NumericOptions = {
  code: "nic-ipp-fc-fee-plan-base",
  name: "Fee fixed",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>This parameter works with Fee % parameter.</div>
      <div>IPP Foreclosure Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};
const feePercentageForeclosureOption: NumericOptions = {
  code: "nic-ipp-fc-fee-plan-rate",
  name: "Fee %",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  addonAfter: "%",
  tooltip: (
    <Space direction="vertical">
      <div>This parameter works with Fee fIxed parameter.</div>
      <div>IPP Foreclosure Fee = Fee Fixed + Fee %</div>
    </Space>
  ),
};
const minFeeForeclosureOption: NumericOptions = {
  code: "nic-ipp-fc-fee-plan-min",
  name: "Min fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated fee is lower than the Min fee defined as part of this field then Min fee will be charged
        to the customer.
      </div>
      <div>If Min Fee &gt; Fee fixed + Fee % then Min Fee will be considered as IPP Foreclosure Fee</div>
    </Space>
  ),
};
const maxFeeForeclosureOption: NumericOptions = {
  code: "nic-ipp-fc-fee-plan-max",
  name: "Max fee",
  decimalsNumber: 2,
  min: 0,
  addonAfter: "SAR",
  tooltip: (
    <Space direction="vertical">
      <div>
        In case of calculated fee is greater than the Max fee defined as part of this field then Max fee will be
        calculated.
      </div>
      <div>If Max Fee &lt; Fee fixed + Fee % then Max Fee will be considered as IPP Foreclosure Fee</div>
    </Space>
  ),
};
export const tab4 = {
  enableForeclosureFeeOption,
  feeFixedForeclosureOption,
  feePercentageForeclosureOption,
  minFeeForeclosureOption,
  maxFeeForeclosureOption,
  page: ({
    form,
    addonAfter,
    feePercentage,
    maxFee,
    minFee,
  }: {
    form: FormInstance<FormValues>;
    addonAfter: ReactNode;
    feePercentage: string;
    maxFee: number;
    minFee: number;
  }) => (
    <Space direction="vertical" size="large">
      <p>
        IPP Foreclosure is the process of closing the IPP Plan by the system or by the request from the customer. When
        the IPP plan is foreclosed, the IPP balance is moved to the retail balance and also system allows charging
        foreclosure fees. This fee can be redefined for a specific pricing control table of the credit product.
      </p>
      <SwitchedInputsBox
        form={form}
        switchOptions={enableForeclosureFeeOption}
        inputsOptionsList={[
          [
            { ...feeFixedForeclosureOption, addonAfter },
            feePercentageForeclosureOption,
            {
              ...minFeeForeclosureOption,
              isDisabled: !feePercentage,
              max: (maxFee ?? Infinity) - 0.01,
              addonAfter,
            },
            {
              ...maxFeeForeclosureOption,
              isDisabled: !feePercentage,
              min: (minFee ?? 0) + 0.01,
              addonAfter,
            },
          ],
        ]}
      />
    </Space>
  ),
} as const;

export const tabsObjectCodes = {
  interest: [
    tab1.dailyInterestOption.code,
    tab1.enableInterestOption.code,
    tab1.interestAmountOption.code,
    tab1.interestRate.code,
  ],
  processing: [
    tab2.enableProcessingFeeOption.code,
    tab2.feeProcessingFixedOption.code,
    tab2.feeProcessingPercentageOption.code,
    tab2.maxProcessingFeeOption.code,
    tab2.minProcessingFeeOption.code,
  ],
  incentive: [
    tab3.enableIncentiveFeeOption.code,
    tab3.feeFixedIncentiveOption.code,
    tab3.feePercentageIncentiveOption.code,
    tab3.maxFeeIncentiveOption.code,
    tab3.minFeeIncentiveOption.code,
  ],
  foreclosure: [
    tab4.enableForeclosureFeeOption.code,
    tab4.feeFixedForeclosureOption.code,
    tab4.feePercentageForeclosureOption.code,
    tab4.maxFeeForeclosureOption.code,
    tab4.minFeeForeclosureOption.code,
  ],
};
