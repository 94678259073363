import { FC, useState } from "react";
import { Select, SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/select";

import { useQpApi } from "@ni/common/hooks";
import { Option } from "@ni/common/types";

export interface ParamCodeSelectProps extends SelectProps {
  fiCode: string;
  paramType: string;
}

export const ParamCodeSelect: FC<ParamCodeSelectProps> = ({ fiCode, paramType, ...props }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getParamTypes } = useQpApi({ isFetchEnabled: false });

  const handleVisibilityChange = async (open: boolean) => {
    if (open && !isLoading && options.length === 0) {
      setIsLoading(true);
      const data = await getParamTypes(fiCode, paramType);

      setOptions(data.map(i => ({ value: i.parmCode, displayValue: i.parm }) as Option) ?? []);
      setIsLoading(false);
    }
  };

  return (
    <Select
      {...props}
      onDropdownVisibleChange={handleVisibilityChange}
      options={options as unknown as DefaultOptionType[]}
      loading={isLoading}
    />
  );
};
