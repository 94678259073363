import { useMemo } from "react";

import { NIC_DUE_DATE_DAYS } from "@ni/common/constants";
import { ProductValue } from "@ni/sdk/models";

export const useRemainingCycleDayAfterDueDate = (productValues?: ProductValue[]): number => {
  return useMemo(() => {
    if (productValues) {
      const cycleDays = +(productValues?.find(x => x.fieldCode === NIC_DUE_DATE_DAYS)?.value as unknown as number);
      return Number.isNaN(cycleDays) ? 0 : cycleDays;
    }
    return 0;
  }, [productValues]);
};
