import { FC } from "react";
import { Checkbox, Col, Row, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import styles from "./styles.module.scss";

interface LoyaltyProgramListItemProps {
  name: string;
  value: string | number;
  sections?: { title: string; value: string }[];
  onChange?: (e: CheckboxChangeEvent) => void;
}

export const LoyaltyProgramListItem: FC<LoyaltyProgramListItemProps> = ({ name, value, sections, onChange }) => {
  return (
    <div className={styles["lty-list-item"]}>
      <Checkbox value={value} onChange={onChange} />
      <div className={styles["lty-list-body"]}>
        <Typography.Title level={4} className={styles["lty-list-name"]}>
          {name}
        </Typography.Title>
        {sections && (
          <Row wrap={false} gutter={[16, 0]}>
            <Col flex="120px">
              {sections.map(section => (
                <div key={`${section.title}-${Math.random()}`} className={styles["lty-details-title"]}>
                  {section.title}
                </div>
              ))}
            </Col>
            <Col flex="auto">
              {sections.map(section => (
                <div key={`${section.value}-${Math.random()}`} className={styles["lty-details-value"]}>
                  {section.value}
                </div>
              ))}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
