import { FC, useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { AfterWizardEditPageTitle, Spin } from "@ni/common/ui";
import { PhaseApi } from "@ni/sdk/apis";
import { Phase as PhaseInterface, State } from "@ni/sdk/models";

import DraggablePhases from "./components/DraggablePhases";

import styles from "./styles.module.scss";

const phaseServiceApi = new PhaseApi();

const emptyPhaseConfig = {
  id: undefined,
  code: "",
  name: "",
  skipConditions: "",
  order: undefined,
  phaseType: undefined,
  pages: [],
};
export const Phase: FC = () => {
  const [phaseItems, setPhaseItems] = useReduxState<PhaseInterface[]>("phaseItems", []);

  const [isLoading, setIsLoading] = useReduxState<boolean>("isPhaseLoading");
  const { id } = useParams<{ id: string }>();
  const [maxOrderValue, setMaxOrderValue] = useState<number>(-1);

  useEffect(() => {
    if (phaseItems.length) {
      if (phaseItems.every(item => item.order !== undefined)) {
        const maxOrder = phaseItems?.reduce((max, obj) => (max.order! > obj.order! ? max : obj)).order;
        setMaxOrderValue(maxOrder!);
      } else {
        const maxOrder = phaseItems?.length;
        setMaxOrderValue(maxOrder);
      }
    } else {
      setMaxOrderValue(-1);
    }
  }, [phaseItems, setMaxOrderValue]);

  const onAddNewPhaseBtn = () => {
    setPhaseItems([
      ...phaseItems,
      {
        ...emptyPhaseConfig,
        order: maxOrderValue,
        name: `New phase ${phaseItems.length + 1}`,
        state: State.ENABLED,
      },
    ]);
  };

  const getPhases = useCallback((): void => {
    setIsLoading(true);

    phaseServiceApi
      .getPhases(Number(id))
      .then((response: AxiosResponse<PhaseInterface[]>) => {
        setPhaseItems(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, setPhaseItems, id]);

  useEffect(() => {
    getPhases();
  }, [id]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div className={styles["phase-details"]}>
      <div className="phase-details-title">
        <AfterWizardEditPageTitle
          title="Phases"
          page="Workflows"
          link="/admin/workflows"
          buttonText="Add phase"
          onButtonClick={onAddNewPhaseBtn}
          btnExists={true}
        />
      </div>
      <div className={styles["phase-details-body"]}>
        <DraggablePhases getPhases={getPhases} workflowId={Number(id)} key={Number(id)} />
      </div>
    </div>
  );
};
