import { useCallback, useEffect, useMemo, useState } from "react";
import { FormInstance } from "antd";

import { FormValues, Tab } from "@ni/common/types";
import { parseBooleanOrNumber } from "@ni/common/utils";

export interface ChangesChecker {
  isCheckEnabled: boolean;
  form?: FormInstance<FormValues>;
  excludedFieldsListFromCheck?: string[];
  initialValues?: FormValues;
  discardAfterChangeTab?: boolean;
}

interface Page<K extends string> {
  name: string;
  key: K;
}

type UseTabsReturn<K extends string> = [Tab<K>[], Tab<K>, (key: K) => boolean, ChangesCheckerReturn<K>?];

interface ChangesCheckerReturn<K extends string> {
  nextPage: Page<K>;
  isOpen: boolean;
  disabled: boolean;
  onDiscardChanges: (key: K, isDiscardOn: boolean) => void;
  onCloseModal: () => void;
}

function getInitialPageState() {
  return {
    name: "",
    key: "",
  };
}

export function useTabs<K extends string>(
  tabsList: Tab<K>[],
  { isCheckEnabled, form, excludedFieldsListFromCheck, initialValues, discardAfterChangeTab = true }: ChangesChecker,
): UseTabsReturn<K> {
  const tabs = useMemo(() => tabsList, [tabsList]);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [isOpen, setIsOpen] = useState(false);
  const [nextPage, setNextPage] = useState<Page<K>>(getInitialPageState() as Page<K>);

  const [disabled, setDisabled] = useState(false);

  const checkIfFormWasTouched = useCallback(
    (form: FormInstance<FormValues>, fieldsList: string[]) => {
      const formValues = form.getFieldsValue();

      let touchedAndReverted = false;

      Object.keys(formValues).forEach(field => {
        const isTouched = form.isFieldTouched(field);
        const isExcluded = fieldsList.includes(field);
        const isReverted =
          isTouched &&
          parseBooleanOrNumber(formValues[field] as string) === parseBooleanOrNumber(initialValues?.[field] as string);

        if (isTouched && !isExcluded && !isReverted) {
          touchedAndReverted = true;
        }
      });

      return touchedAndReverted;
    },
    [initialValues],
  );

  const discardFieldsWithoutExcluded = useCallback(
    (discardAfterChangeTab: boolean) => {
      if (form) {
        const allCurrentFieldsExceptExcluded = Object.keys(form.getFieldsValue()).filter(
          key => !(excludedFieldsListFromCheck ?? []).includes(key),
        );

        if (!discardAfterChangeTab && initialValues) {
          Object.entries(initialValues)
            .filter(([key]) => !excludedFieldsListFromCheck?.includes(key))
            .forEach(([key, value]) => {
              form.setFieldValue(key, value);
            });
        } else {
          form.resetFields(allCurrentFieldsExceptExcluded);
          if (initialValues) {
            Object.entries(initialValues)
              .filter(([key]) => !excludedFieldsListFromCheck?.includes(key))
              .forEach(([key, value]) => {
                form.setFieldValue(key, value);
              });
          }
        }
      }
    },
    [excludedFieldsListFromCheck, form, initialValues],
  );

  useEffect(() => {
    if (isCheckEnabled && form && isOpen) {
      form
        .validateFields()
        .then(() => {
          setDisabled(form.getFieldsError().some(field => field.errors.length > 0));
        })
        .catch(() => {
          setDisabled(form.getFieldsError().some(field => field.errors.length > 0));
        });
    }
  }, [form, isCheckEnabled, isOpen]);

  const onChangeActiveTab = useCallback(
    (key: K) => {
      if (isCheckEnabled && form && checkIfFormWasTouched(form, excludedFieldsListFromCheck ?? [])) {
        setNextPage({
          key,
          name: (tabs.find(tab => tab.key === key)?.label.props.title ?? tabs[0].label.props.title) as string,
        });
        setIsOpen(true);

        return false;
      }

      discardFieldsWithoutExcluded(discardAfterChangeTab);

      setActiveTab(tabs.find(tab => tab.key === key) ?? tabs[0]);

      return true;
    },
    [
      checkIfFormWasTouched,
      discardAfterChangeTab,
      discardFieldsWithoutExcluded,
      excludedFieldsListFromCheck,
      form,
      isCheckEnabled,
      tabs,
    ],
  );

  const onDiscardChanges = (key: K, isDiscardOn: boolean = false) => {
    setIsOpen(false);
    setNextPage(getInitialPageState() as Page<K>);
    setActiveTab(tabs.find(tab => tab.key === key) ?? tabs[0]);
    if (isDiscardOn) discardFieldsWithoutExcluded(discardAfterChangeTab);
  };

  return isCheckEnabled
    ? [
        tabs,
        activeTab,
        onChangeActiveTab,
        {
          nextPage,
          isOpen,
          disabled,
          onDiscardChanges,
          onCloseModal: () => setIsOpen(false),
        },
      ]
    : [tabs, activeTab, onChangeActiveTab];
}
