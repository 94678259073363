import { useCallback, useEffect, useRef, useState } from "react";
import { notification, TablePaginationConfig } from "antd";

import { QpApi } from "@ni/sdk/apis";
import {
  PutQpChangeRequest,
  QpChangePageData,
  QpDashboard,
  QpDashboardPageData,
  QpParmType,
  QpQuest,
  QpRowkey,
  SortedFilteredPageRequest,
} from "@ni/sdk/models";

import { useReduxState } from "../store";

import { initialQpConfirmData, initialQpData, initialQpPagination } from "./constants";

interface UseQpApiProps {
  isFetchEnabled?: boolean;
  payload?: SortedFilteredPageRequest;
  isConfirmPage?: boolean;
  currentRowkey?: string | undefined;
}

const qpServiceApi = new QpApi();

export const useQpApi = ({
  isFetchEnabled = true,
  isConfirmPage = false,
  payload = {},
  currentRowkey,
}: UseQpApiProps) => {
  const [tenantRowkey] = useReduxState<string | undefined>("tenantRowkey", undefined);
  const [qpData, setQpData] = useReduxState<QpDashboardPageData>("questionnaireProcessingData", initialQpData);
  const [qpConfirmData, setConfirmQpData] = useReduxState<QpChangePageData>(
    "questionnaireProcessingDataConfirmation",
    initialQpConfirmData,
  );
  const [qpParamTypes, setQpParamTypes] = useReduxState<QpParmType[]>("questionnaireProcessingParamTypes", []);
  const [qpQuests, setQpQuests] = useReduxState<QpQuest[]>("questionnaireProcessingQuests", []);
  const [qpRowkeys, setQpRowkeys] = useReduxState<QpRowkey[]>("questionnaireProcessingRowkeys", []);

  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [pageHasContent, setPageHasContent] = useState<boolean>(false);
  const [filters, setFilters] = useState<SortedFilteredPageRequest>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialQpPagination);

  const initialLoadCompleteRef = useRef<boolean>(false);

  const saveConfirmedQpData = async () => {
    setIsLoading(true);
    try {
      await qpServiceApi.confirm(currentRowkey ?? "");
      setIsLoading(false);
      notification.success({
        placement: "topRight",
        duration: 5,
        message: `Your changes has been submitted successfully!`,
      });
    } catch (error) {
      setIsLoading(false);
      notification.error({
        placement: "topRight",
        duration: 5,
        message: `Something went wrong!`,
      });
    }
  };

  const getQpConfirmData = useCallback(
    async (fiCode: string, currentPage: number, size: number, payload: SortedFilteredPageRequest = {}) => {
      setIsLoading(true);
      try {
        const { data } = await qpServiceApi.getChanges(
          {
            filter: {},
            ...payload,
            pageLimits: {
              number: currentPage - 1,
              size,
            },
          },
          fiCode,
        );
        if (data) {
          setPagination(pagination => ({ ...pagination, total: data.totalElements }));
          setConfirmQpData(data ?? []);
        }

        if (!initialLoadCompleteRef.current) {
          setPageHasContent(data.hasContent);
          initialLoadCompleteRef.current = true;
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setConfirmQpData, setIsLoading],
  );

  const revertConfirmedQpData = async (ids: number[], fiCode?: string) => {
    setIsLoading(true);
    try {
      // eslint-disable-next-line no-underscore-dangle
      const response = await qpServiceApi.revert({ ids });
      if (response?.data?.length && fiCode) {
        setIsLoading(false);
        await getQpConfirmData(fiCode, 1, 10, {});
        notification.success({
          placement: "topRight",
          duration: 5,
          message: `Your changes has been reverted`,
        });
      } else {
        throw new Error("Failed to revert");
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        placement: "topRight",
        duration: 5,
        message: `Something went wrong!`,
      });
    }
  };

  const getQpData = useCallback(
    async (currentPage: number, size: number, payload: SortedFilteredPageRequest = {}) => {
      if (!payload?.filter?.["rowkey.rowkey"] || !tenantRowkey) {
        setQpData([] as unknown as QpDashboardPageData);
      } else {
        try {
          setIsLoading(true);

          const { data } = await qpServiceApi.getQpDashboard({
            ...{ filter: { "rowkey.rowkey": tenantRowkey, ...payload?.filter }, ...payload },
            pageLimits: {
              number: currentPage - 1,
              size,
            },
          });

          setPagination(pagination => ({ ...pagination, total: data.totalElements }));
          setQpData(data ?? []);

          if (!initialLoadCompleteRef.current) {
            setPageHasContent(data.hasContent);
            initialLoadCompleteRef.current = true;
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    },
    [setIsLoading, setQpData, tenantRowkey],
  );

  const getQpFilters = useCallback(async () => {
    setIsLoading(true);

    try {
      const [paramTypes, quests, rowkeys] = await Promise.all([
        qpServiceApi.getParmTypes(),
        qpServiceApi.getQuestionnaires(),
        qpServiceApi.getRowkey(),
      ]);

      setQpParamTypes(paramTypes.data);
      setQpQuests(quests.data);
      setQpRowkeys(rowkeys.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [setIsLoading, setQpParamTypes, setQpQuests, setQpRowkeys]);

  const getParamTypes = useCallback(async (fiCode: string, paramType: string) => {
    try {
      const { data } = await qpServiceApi.getParmCodes(fiCode, paramType);
      return data;
    } catch (error) {
      return [];
    }
  }, []);

  const saveQpData = async (oldValues: QpDashboard, changedValues: QpDashboard, skiploading: boolean = false) => {
    if (!skiploading) setIsLoading(true);
    try {
      const payload: PutQpChangeRequest = {
        columnCode: oldValues.columnCode!,
        columnName: oldValues.name!,
        columnValueOld: oldValues.columnValue,
        columnValueNew: changedValues.columnValue,
        parmCode: changedValues.parmCode ?? "(null)",
        parmType: oldValues.parmType!,
        questionnaire: oldValues.questionnaire!,
        rowkey: changedValues.rowkey!,
        inputId: oldValues.inputId,
      };

      const { status } = await qpServiceApi.put(payload);

      if (status === 200) {
        notification.success({
          placement: "topRight",
          duration: 5,
          message: `Your changes for "${oldValues.questionnaire}" has been submitted for review successfully!`,
        });
      }

      if (!skiploading) setIsLoading(false);
    } catch (error) {
      if (!skiploading) setIsLoading(false);
    }
  };

  const resetQpData = async (qpDataList: (QpDashboard | undefined)[]) => {
    const resetedQpData: QpDashboard[] = qpDataList.filter(x => x) as QpDashboard[];
    if (resetedQpData?.length) {
      setIsLoading(true);
      try {
        const resolvedItems = await Promise.all(
          resetedQpData.map(item => {
            return new Promise((resolve, reject) => {
              saveQpData(item, { rowkey: item?.rowkey, columnValue: "", parmCode: item.parmCode }, true)
                .then(() => {
                  resolve(item.id);
                })
                .catch(reject);
            });
          }),
        );
        const idsSet = new Set(resolvedItems);
        const newQpData = qpData.content?.map(qp => {
          if (idsSet.has(qp.id)) return { ...qp, columnValue: "" };
          return { ...qp };
        });
        setQpData({ ...qpData, content: newQpData });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isFetchEnabled)
      if (isConfirmPage && currentRowkey) {
        void getQpConfirmData(
          currentRowkey,
          pagination.current ?? 1,
          pagination.pageSize ?? 6,
          Object.keys(filters).length === 0 ? payload : filters,
        );
      } else
        void getQpData(
          pagination.current ?? 1,
          pagination.pageSize ?? 6,
          Object.keys(filters).length === 0 ? payload : filters,
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchEnabled, getQpData, pagination.current, pagination.pageSize, filters]);

  useEffect(() => {
    if (isFetchEnabled) void getQpFilters();
  }, [getQpFilters, isFetchEnabled]);

  return {
    qpData,
    qpConfirmData,
    setConfirmQpData,
    setQpData,
    saveQpData,
    pageHasContent,
    pagination,
    setPagination,
    filters,
    setFilters,
    qpParamTypes,
    qpQuests,
    qpRowkeys,
    saveConfirmedQpData,
    getParamTypes,
    revertConfirmedQpData,
    resetQpData,
  };
};
