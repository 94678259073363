import { useMemo } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import {
  LTY_PROGRAM_ACCR_FREQ,
  LTY_RED_CASH_A_TOT,
  LTY_REDEEM_TYPE,
  LTY_WELC_BONUS_ENABLE,
  LTY_WELC_BONUS_TARGET,
  NIC_LTY_ACCR_YEARLY_START,
  NIC_LTY_BONUS_W_TIMER_FROM,
  NIC_LTY_BONUS_W_TIMER_PER,
  NIC_LTY_BONUS_W_TIMER_PER_TYPE,
  NIC_LTY_FLAG_DEV,
  NIC_LTY_FLAG_EDITABLE,
  NIC_LTY_RED_CASH_A_TOT,
  NIC_LTY_RED_EXT_A_TOT_MBR,
} from "@ni/common/constants";
import { useHydrateForm, useParamsList, useProductSettings, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";
import { isNumeric, removeGapsFromStringNumeric } from "@ni/common/utils";
import { LoyaltyProgram, LoyaltyProgramState, ProductValue, Tenant } from "@ni/sdk/models";

import { LoyaltyEnrollment, PointAccrual, PointRedemption, WelcomeBonus } from "../../../components";

type TabKeysLoyaltySettings = "" | "loyaltyEnrollment" | "points" | "pointsRedemption" | "welcomeBonus";

export const GeneralLoyaltySettings = () => {
  const [form] = Form.useForm<FormValues>();

  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const {
    params: { tab },
  } = useParamsList(["tab"]);

  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: true,
  });

  const [loyaltyPrograms] = useReduxState<LoyaltyProgram[]>("loyaltyPrograms", []);
  const activeLoyaltyprograms = useMemo(
    () => loyaltyPrograms.filter(program => program.state === LoyaltyProgramState.ACTIVE),
    [loyaltyPrograms],
  );

  const [, setIsLoading] = useReduxState<boolean>("isLoading", false);

  const [tenant] = useReduxState<Tenant>("tenant", {});

  const chosenLoyaltyProgram = Form.useWatch(LTY_WELC_BONUS_TARGET, form);

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: currentProduct?.productValues ?? [],
      keys: {
        strings: [
          NIC_LTY_FLAG_DEV,
          NIC_LTY_FLAG_EDITABLE,
          NIC_LTY_ACCR_YEARLY_START,
          LTY_RED_CASH_A_TOT,
          NIC_LTY_RED_CASH_A_TOT,
          NIC_LTY_RED_EXT_A_TOT_MBR,
          LTY_WELC_BONUS_TARGET,
          NIC_LTY_BONUS_W_TIMER_FROM,
          NIC_LTY_BONUS_W_TIMER_PER_TYPE,
          NIC_LTY_BONUS_W_TIMER_PER,
        ],
      },
    },
    [form, currentProduct?.productValues],
  );

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const productValues: ProductValue[] = Object.entries(values).map(([key, value]) => ({
      fieldCode: key,
      value: isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value,
    })) as ProductValue[];

    if (chosenLoyaltyProgram === "None") {
      [NIC_LTY_BONUS_W_TIMER_FROM, NIC_LTY_BONUS_W_TIMER_PER_TYPE, NIC_LTY_BONUS_W_TIMER_PER].forEach(fieldName => {
        productValues.push({ fieldCode: fieldName, value: undefined });
      });
    }

    void onUpdateProduct({}, productValues);
  };

  const pagesList = useMemo(
    () => [
      {
        key: "loyaltyEnrollment" as const,
        children: <LoyaltyEnrollment />,
        label: <div title="Loyalty Enrollment">Loyalty Enrollment</div>,
      },
      {
        key: "points" as const,
        children: <PointAccrual />,
        label: <div title="Point Accrual">Point Accrual</div>,
        dependency: Boolean(
          activeLoyaltyprograms?.some(loyaltyProgram =>
            loyaltyProgram.programValues?.some(
              program => program.code === LTY_PROGRAM_ACCR_FREQ && program.value === "Y",
            ),
          ),
        ),
      },
      {
        key: "pointsRedemption" as const,
        children: <PointRedemption form={form} loyaltyPrograms={activeLoyaltyprograms ?? []} />,
        label: <div title="Point Redemption">Point Redemption</div>,
        dependency: Boolean(
          activeLoyaltyprograms?.some(loyaltyProgram =>
            loyaltyProgram.programValues?.some(
              program => (program.code === LTY_REDEEM_TYPE && program.value === "C") || program.value === "E",
            ),
          ),
        ),
      },

      {
        key: "welcomeBonus" as const,
        children: (
          <WelcomeBonus
            form={form}
            loyaltyPrograms={(activeLoyaltyprograms ?? []).filter(
              program => program.state === LoyaltyProgramState.ACTIVE,
            )}
          />
        ),
        label: <div title="Welcome Bonus">Welcome Bonus</div>,
        dependency: Boolean(
          tenant.tenantValues?.some(
            tenantValue => tenantValue.fieldCode === LTY_WELC_BONUS_ENABLE && tenantValue.value === "true",
          ),
        ),
      },
    ],
    [activeLoyaltyprograms, form, tenant.tenantValues],
  );

  const relatedLinks = useMemo(() => {
    const links = [
      {
        href: `/tenant/${tenantId}/product/${currentProduct?.id}/loyalty-programs`,
        label: `${currentProduct?.displayName} - Loyalty Programs`,
      },
    ];

    const currentTab = pagesList.find(page => page.key === tab)?.key;

    if (!(currentTab === "points") && !(currentTab === "welcomeBonus" && chosenLoyaltyProgram === "None")) {
      links.push(
        ...(currentProduct.parameterTables ?? []).map(table => ({
          href: `/tenant/${tenantId}/product/${currentProduct.id}/pct/${table?.id}/loyalty-settings?tab=${tab}`,
          label: `${currentProduct.displayName} - ${table?.displayName} PCT - Loyalty - General Settings - ${
            pagesList.find(page => page.key === tab)?.label.props.title
          }`,
        })),
      );
    }

    return links;
  }, [
    chosenLoyaltyProgram,
    currentProduct.displayName,
    currentProduct.id,
    currentProduct.parameterTables,
    pagesList,
    tab,
    tenantId,
  ]);

  return (
    <CustomFormWrapper
      pageTitle="Loyalty - General Settings"
      pageSubtitle="While the product can be associated with multiple loyalty programs, the following settings apply universally to all loyalty programs associated with the product."
      form={form}
      submitHandler={onFinish}
      level="tenant"
      size="full"
      formSize="full"
      submitLabel="Save"
      additionalRoute="details"
      relatedLinks={relatedLinks}
    >
      <Tabs<TabKeysLoyaltySettings>
        defaultActiveKey="loyaltyEnrollment"
        pagesList={pagesList}
        isCheckEnabled={true}
        form={form}
        initialValues={initialValues}
        discardAfterChangeTab={false}
        onSave={onFinish}
      />
    </CustomFormWrapper>
  );
};
