import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { Sidenav } from "@ni/common/ui";
import { Users } from "@ni/users";

import { financialInstitutionSettingsItems } from "../../constants";
import FinancialInstitutionDetails from "../FinancialInstitutionDetails";

import styles from "./styles.module.scss";

const FinancialInstitutionSettingsLayout: FC = () => {
  return (
    <div className={styles["fi-setting-layout-wrapper"]}>
      <div className={styles["fi-sidenav"]}>
        <Sidenav items={financialInstitutionSettingsItems} />
      </div>
      <div className={styles["page-content"]}>
        <Routes>
          <Route path="/financial-institution-settings" element={<FinancialInstitutionDetails />} />
          <Route path="/users" element={<Users />} />
        </Routes>
      </div>
    </div>
  );
};

export default FinancialInstitutionSettingsLayout;
