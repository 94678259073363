import { FC } from "react";
import { Form } from "antd";

import { useHydrateForm } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { capOption, fixedOption, percentageOption, periodOption, periodTypeOption } from "./constants";

export const PremiumSettingsPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest } = useInsuranceWizardApi();

  useHydrateForm({
    form,
    entityFields: (wizardResponse.insuranceTemplate?.values as { code: string; value: string }[]) ?? [],
    keys: {
      strings: [capOption.code, fixedOption.code, percentageOption.code, periodOption.code, periodTypeOption.code],
    },
  });

  const onFinish = (values: FormValues) => {
    void processWizardRequest({
      pageId: wizardResponse?.pageId as number,
      tenantId: wizardResponse.tenant?.id,
      insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
      collectedValues: values as {
        [key: string]: string;
      },
    });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Premium Settings"
      pageSubtitle="Insurance is billed to the customer at the end of the billing cycle with the amount calculated based on multiple parameters. It can be either a percentage of the statement balance or a fixed amount, charged as an insurance premium.  You can choose to reverse the premium for initial number of months or cycles, while reversing the insurance premium; system will take care of deducting that out of payment due amount and retail plan or full fee cash plan"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
    >
      <NetworkForm.Number
        formItemOptions={{
          name: fixedOption.code,
          label: fixedOption.name,
          tooltip: fixedOption.tooltip,
        }}
        addonAfter={wizardResponse.insuranceTemplate?.currency as string}
        min={fixedOption.min}
        max={fixedOption.max}
        precision={fixedOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: percentageOption.code,
          label: percentageOption.name,
          tooltip: percentageOption.tooltip,
          initialValue: percentageOption.default,
        }}
        addonAfter="%"
        min={percentageOption.min}
        max={percentageOption.max}
        precision={percentageOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: capOption.code,
          label: capOption.name,
          tooltip: capOption.tooltip,
        }}
        addonAfter={wizardResponse.insuranceTemplate?.currency as string}
        min={capOption.min}
        max={capOption.max}
        precision={capOption.decimalsNumber}
      />

      <NetworkForm.Number
        formItemOptions={{
          name: periodOption.code,
          label: periodOption.name,
          tooltip: periodOption.tooltip,
        }}
        min={periodOption.min}
        max={periodOption.max}
        precision={periodOption.decimalsNumber}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: periodTypeOption.code,
          label: periodTypeOption.name,
          initialValue: periodTypeOption.default,
        }}
        radioList={periodTypeOption.options}
        initialValue={periodTypeOption.default as string}
      />
    </CustomFormWrapper>
  );
};
