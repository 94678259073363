import { useEffect } from "react";

import { TenantApi } from "@ni/sdk/apis";
import { FullDashboardProduct } from "@ni/sdk/models";

import { useReduxState } from "../store";

const tenantServiceApi = new TenantApi();

const paginationForNormal = (type: "N" | "C" | "INSURANCE", extraFilterParams?: Record<string, string>) => ({
  filter: {
    ...{ [type === "INSURANCE" ? "searchType" : "loyalty_program.type"]: type },
    ...(extraFilterParams ? { ...extraFilterParams } : {}),
  },
  pageLimits: {
    number: 0,
    size: 15,
  },
});

export const useGetProductsToApply = (
  tenantId?: number,
  type?: "N" | "C" | "INSURANCE",
  extraFilterParams?: Record<string, string>,
) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading", false);
  const [products, setProducts] = useReduxState<FullDashboardProduct[] | undefined>("productsToApply", undefined);

  const getProductListByTenantId = async (tenantId: number, templateType: "N" | "C" | "INSURANCE") => {
    setIsLoading(true);
    const response = await tenantServiceApi.getProductsForApply(
      paginationForNormal(templateType, extraFilterParams),
      tenantId,
    );
    setProducts(response.data?.content ?? []);
    setIsLoading(false);
  };

  useEffect(() => {
    try {
      if (tenantId && type) void getProductListByTenantId(tenantId, type);
    } catch (error) {
      setProducts([]);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const refreshProducts = () => {
    if (tenantId && type) void getProductListByTenantId(tenantId, type);
  };

  return { products, refreshProducts };
};
