import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const TableViewIcon = ({ fill, ...props }: Partial<CustomIconComponentProps>) => {
  const iconSvg = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.5714 7.71429H3.42857C3.04981 7.71391 2.68666 7.56328 2.41884 7.29545C2.15101 7.02762 2.00038 6.66448 2 6.28571V3.42857C2.00038 3.04981 2.15101 2.68666 2.41884 2.41884C2.68666 2.15101 3.04981 2.00038 3.42857 2H20.5714C20.9502 2.00038 21.3133 2.15101 21.5812 2.41884C21.849 2.68666 21.9996 3.04981 22 3.42857V6.28571C21.9996 6.66448 21.849 7.02762 21.5812 7.29545C21.3133 7.56328 20.9502 7.71391 20.5714 7.71429ZM3.42857 3.42857V6.28571H20.5714V3.42857H3.42857ZM20.5714 22H3.42857C3.04981 21.9996 2.68666 21.849 2.41884 21.5812C2.15101 21.3133 2.00038 20.9502 2 20.5714V17.7143C2.00038 17.3355 2.15101 16.9724 2.41884 16.7046C2.68666 16.4367 3.04981 16.2861 3.42857 16.2857H20.5714C20.9502 16.2861 21.3133 16.4367 21.5812 16.7046C21.849 16.9724 21.9996 17.3355 22 17.7143V20.5714C21.9996 20.9502 21.849 21.3133 21.5812 21.5812C21.3133 21.849 20.9502 21.9996 20.5714 22ZM3.42857 17.7143V20.5714H20.5714V17.7143H3.42857ZM20.5714 14.8571H3.42857C3.04981 14.8568 2.68666 14.7061 2.41884 14.4383C2.15101 14.1705 2.00038 13.8073 2 13.4286V10.5714C2.00038 10.1927 2.15101 9.82952 2.41884 9.56169C2.68666 9.29387 3.04981 9.14324 3.42857 9.14286H20.5714C20.9502 9.14324 21.3133 9.29387 21.5812 9.56169C21.849 9.82952 21.9996 10.1927 22 10.5714V13.4286C21.9996 13.8073 21.849 14.1705 21.5812 14.4383C21.3133 14.7061 20.9502 14.8568 20.5714 14.8571ZM3.42857 10.5714V13.4286H20.5714V10.5714H3.42857Z"
          fill={fill}
        />
      </svg>
    );
  };

  return <Icon component={iconSvg} {...props} />;
};
