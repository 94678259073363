/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchChangeElementsRequest } from '../model';
// @ts-ignore
import { ChangeElementRequest } from '../model';
// @ts-ignore
import { CopyElementRequest } from '../model';
// @ts-ignore
import { CreateElementRequest } from '../model';
// @ts-ignore
import { Element } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PlainElement } from '../model';
/**
 * ElementApi - axios parameter creator
 * @export
 */
export const ElementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Copies an Element
         * @summary Copy Element
         * @param {CopyElementRequest} copyElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyElement: async (copyElementRequest: CopyElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyElementRequest' is not null or undefined
            assertParamExists('copyElement', 'copyElementRequest', copyElementRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('copyElement', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('copyElement', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('copyElement', 'pageId', pageId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('copyElement', 'elementId', elementId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}/elements/{elementId}/copy`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyElementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an element
         * @summary Create Element
         * @param {CreateElementRequest} createElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElement: async (createElementRequest: CreateElementRequest, workflowId: number, phaseId: number, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createElementRequest' is not null or undefined
            assertParamExists('createElement', 'createElementRequest', createElementRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('createElement', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('createElement', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('createElement', 'pageId', pageId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}/elements`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createElementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Element
         * @summary Delete Element by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElement: async (workflowId: number, phaseId: number, pageId: number, elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('deleteElement', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('deleteElement', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('deleteElement', 'pageId', pageId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('deleteElement', 'elementId', elementId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}/elements/{elementId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch for the Element and Put for the Validations
         * @summary Edit an element
         * @param {ChangeElementRequest} changeElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editElement: async (changeElementRequest: ChangeElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeElementRequest' is not null or undefined
            assertParamExists('editElement', 'changeElementRequest', changeElementRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('editElement', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('editElement', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('editElement', 'pageId', pageId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('editElement', 'elementId', elementId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}/elements/{elementId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeElementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Elements by pageId
         * @summary Get Elements
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElements: async (workflowId: number, phaseId: number, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('getElements', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('getElements', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getElements', 'pageId', pageId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}/elements`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Batch Patching of Elements
         * @summary Batch Patching of Elements
         * @param {BatchChangeElementsRequest} batchChangeElementsRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putElements: async (batchChangeElementsRequest: BatchChangeElementsRequest, workflowId: number, phaseId: number, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchChangeElementsRequest' is not null or undefined
            assertParamExists('putElements', 'batchChangeElementsRequest', batchChangeElementsRequest)
            // verify required parameter 'workflowId' is not null or undefined
            assertParamExists('putElements', 'workflowId', workflowId)
            // verify required parameter 'phaseId' is not null or undefined
            assertParamExists('putElements', 'phaseId', phaseId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('putElements', 'pageId', pageId)
            const localVarPath = `/api/v1/workflows/{workflowId}/phases/{phaseId}/pages/{pageId}/elements`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"phaseId"}}`, encodeURIComponent(String(phaseId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchChangeElementsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElementApi - functional programming interface
 * @export
 */
export const ElementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ElementApiAxiosParamCreator(configuration)
    return {
        /**
         * Copies an Element
         * @summary Copy Element
         * @param {CopyElementRequest} copyElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyElement(copyElementRequest: CopyElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Element>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyElement(copyElementRequest, workflowId, phaseId, pageId, elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates an element
         * @summary Create Element
         * @param {CreateElementRequest} createElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createElement(createElementRequest: CreateElementRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Element>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createElement(createElementRequest, workflowId, phaseId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Element
         * @summary Delete Element by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteElement(workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteElement(workflowId, phaseId, pageId, elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch for the Element and Put for the Validations
         * @summary Edit an element
         * @param {ChangeElementRequest} changeElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editElement(changeElementRequest: ChangeElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Element>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editElement(changeElementRequest, workflowId, phaseId, pageId, elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Elements by pageId
         * @summary Get Elements
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElements(workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Element>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElements(workflowId, phaseId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Batch Patching of Elements
         * @summary Batch Patching of Elements
         * @param {BatchChangeElementsRequest} batchChangeElementsRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putElements(batchChangeElementsRequest: BatchChangeElementsRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlainElement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putElements(batchChangeElementsRequest, workflowId, phaseId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ElementApi - factory interface
 * @export
 */
export const ElementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ElementApiFp(configuration)
    return {
        /**
         * Copies an Element
         * @summary Copy Element
         * @param {CopyElementRequest} copyElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyElement(copyElementRequest: CopyElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: any): AxiosPromise<Element> {
            return localVarFp.copyElement(copyElementRequest, workflowId, phaseId, pageId, elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an element
         * @summary Create Element
         * @param {CreateElementRequest} createElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createElement(createElementRequest: CreateElementRequest, workflowId: number, phaseId: number, pageId: number, options?: any): AxiosPromise<Element> {
            return localVarFp.createElement(createElementRequest, workflowId, phaseId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Element
         * @summary Delete Element by Id
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElement(workflowId: number, phaseId: number, pageId: number, elementId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteElement(workflowId, phaseId, pageId, elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch for the Element and Put for the Validations
         * @summary Edit an element
         * @param {ChangeElementRequest} changeElementRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editElement(changeElementRequest: ChangeElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: any): AxiosPromise<Element> {
            return localVarFp.editElement(changeElementRequest, workflowId, phaseId, pageId, elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Elements by pageId
         * @summary Get Elements
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElements(workflowId: number, phaseId: number, pageId: number, options?: any): AxiosPromise<Array<Element>> {
            return localVarFp.getElements(workflowId, phaseId, pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Batch Patching of Elements
         * @summary Batch Patching of Elements
         * @param {BatchChangeElementsRequest} batchChangeElementsRequest 
         * @param {number} workflowId 
         * @param {number} phaseId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putElements(batchChangeElementsRequest: BatchChangeElementsRequest, workflowId: number, phaseId: number, pageId: number, options?: any): AxiosPromise<Array<PlainElement>> {
            return localVarFp.putElements(batchChangeElementsRequest, workflowId, phaseId, pageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ElementApi - interface
 * @export
 * @interface ElementApi
 */
export interface ElementApiInterface {
    /**
     * Copies an Element
     * @summary Copy Element
     * @param {CopyElementRequest} copyElementRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApiInterface
     */
    copyElement(copyElementRequest: CopyElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig): AxiosPromise<Element>;

    /**
     * Creates an element
     * @summary Create Element
     * @param {CreateElementRequest} createElementRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApiInterface
     */
    createElement(createElementRequest: CreateElementRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): AxiosPromise<Element>;

    /**
     * Deletes Element
     * @summary Delete Element by Id
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApiInterface
     */
    deleteElement(workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Patch for the Element and Put for the Validations
     * @summary Edit an element
     * @param {ChangeElementRequest} changeElementRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApiInterface
     */
    editElement(changeElementRequest: ChangeElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig): AxiosPromise<Element>;

    /**
     * Get Elements by pageId
     * @summary Get Elements
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApiInterface
     */
    getElements(workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): AxiosPromise<Array<Element>>;

    /**
     * Batch Patching of Elements
     * @summary Batch Patching of Elements
     * @param {BatchChangeElementsRequest} batchChangeElementsRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApiInterface
     */
    putElements(batchChangeElementsRequest: BatchChangeElementsRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig): AxiosPromise<Array<PlainElement>>;

}

/**
 * ElementApi - object-oriented interface
 * @export
 * @class ElementApi
 * @extends {BaseAPI}
 */
export class ElementApi extends BaseAPI implements ElementApiInterface {
    /**
     * Copies an Element
     * @summary Copy Element
     * @param {CopyElementRequest} copyElementRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApi
     */
    public copyElement(copyElementRequest: CopyElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig) {
        return ElementApiFp(this.configuration).copyElement(copyElementRequest, workflowId, phaseId, pageId, elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an element
     * @summary Create Element
     * @param {CreateElementRequest} createElementRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApi
     */
    public createElement(createElementRequest: CreateElementRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig) {
        return ElementApiFp(this.configuration).createElement(createElementRequest, workflowId, phaseId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Element
     * @summary Delete Element by Id
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApi
     */
    public deleteElement(workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig) {
        return ElementApiFp(this.configuration).deleteElement(workflowId, phaseId, pageId, elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch for the Element and Put for the Validations
     * @summary Edit an element
     * @param {ChangeElementRequest} changeElementRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {number} elementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApi
     */
    public editElement(changeElementRequest: ChangeElementRequest, workflowId: number, phaseId: number, pageId: number, elementId: number, options?: AxiosRequestConfig) {
        return ElementApiFp(this.configuration).editElement(changeElementRequest, workflowId, phaseId, pageId, elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Elements by pageId
     * @summary Get Elements
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApi
     */
    public getElements(workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig) {
        return ElementApiFp(this.configuration).getElements(workflowId, phaseId, pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Batch Patching of Elements
     * @summary Batch Patching of Elements
     * @param {BatchChangeElementsRequest} batchChangeElementsRequest 
     * @param {number} workflowId 
     * @param {number} phaseId 
     * @param {number} pageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElementApi
     */
    public putElements(batchChangeElementsRequest: BatchChangeElementsRequest, workflowId: number, phaseId: number, pageId: number, options?: AxiosRequestConfig) {
        return ElementApiFp(this.configuration).putElements(batchChangeElementsRequest, workflowId, phaseId, pageId, options).then((request) => request(this.axios, this.basePath));
    }
}
