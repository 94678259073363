/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ReactNode } from "react";
import { TableColumnType } from "antd";

import { TooltipInfo } from "../../../../ui/src/lib/components/TooltipInfo/TooltipInfo";

export const murabahaScenariosColumns: TableColumnType<{ [x: string]: ReactNode }>[] = [
  {
    title: "",
    dataIndex: "subTitle",
    render: (subTitle: string) => (
      <TooltipInfo
        label={subTitle}
        tooltipProps={{
          title: {
            R: "Transactions were made in the previous billing cycles",
            N: "Transactions were made in the current billing cycle",
            P: "Depending on scenario can be full payment by the full payment day or due payment (MTP) by due date",
          }[subTitle?.charAt(0) as "R" | "N" | "P"],
          className: "tooltip-title",
        }}
      />
    ),
  },
  {
    title: <TooltipInfo label="Billed amount" tooltipProps={{ className: "tooltip-title" }} />,
    dataIndex: "billedAmount",
  },
  {
    title: (
      <TooltipInfo
        label="Accrued net-profit"
        tooltipProps={{
          title: "Accrued net-profit refers to the amount of net-profit that has been calculated but not yet billed",
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "accruedProfit",
  },
  {
    title: (
      <TooltipInfo
        label="Billed net-profit"
        tooltipProps={{
          title: "Accrued net-profit refers to the amount of net-profit that has been calculated but not yet billed",
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "billedProfit",
  },
  {
    title: (
      <TooltipInfo
        label="Deferred net-profit"
        tooltipProps={{
          title:
            "Accrued net-profit that was not billed in the current billing cycle, but was delayed for potential billing in the next cycle",
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "deferredProfit",
  },
  {
    title: (
      <TooltipInfo
        label="Waived net-profit"
        tooltipProps={{
          title: "Net-profit that was waived due to compliance with the grace option conditions",
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "waivedProfit",
  },
  {
    title: (
      <TooltipInfo
        label="Special deposit"
        tooltipProps={{
          title:
            'This represents the monthly profit payment made to the customer, However, if the "Net Profit" payable is greater than monthly finance payment due, this entry will not be generated',
          className: "tooltip-title",
        }}
      />
    ),
    dataIndex: "specialDeposit",
  },
  {
    title: (
      <TooltipInfo
        label="Profit due"
        tooltipProps={{ title: "Accrued monthly finance payment due", className: "tooltip-title" }}
      />
    ),
    dataIndex: "mfpd",
  },
];

export const murabahaScenariosData = [
  {
    title: "none",
    tableData: {
      rates: [
        {
          label: "Credit limit",
          value: "$105,000",
        },
        {
          label: "Profit rate",
          value: "36% per year",
        },
        {
          label: "MTP",
          value: "5%",
        },
      ],
      scenarios: [
        {
          key: "a",
          label: "Ali",
          mainDescription: "",
          scenarioDescription: "Pays MTP only",
          cycles: [
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accruedProfit: "$59.18",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$59.18" },
                  { type: "", value: "" },
                  { type: "warning", value: "$59.18" },
                  { type: "", value: "" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$2,000.00",
                  accruedProfit: "$59.18",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$1,000.00",
                  accruedProfit: "$29.59",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$100.00",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,900.00" },
                  { type: "", value: "$89.00" },
                  { type: "danger", value: "$118.36" },
                  { type: "warning", value: "$29.59" },
                  { type: "", value: "" },
                  { type: "success", value: "$3,031.64" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$2,900.00",
                  accruedProfit: "$85.81",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$500.00",
                  accruedProfit: "$14.79",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$150.92",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$3,367.00" },
                  { type: "", value: "$101.00" },
                  { type: "danger", value: "$115.40" },
                  { type: "warning", value: "$14.79" },
                  { type: "", value: "" },
                  { type: "success", value: "$3,034.60" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
          ],
        },
        {
          key: "b",
          label: "Bilal",
          mainDescription: "",
          scenarioDescription: "Pays MTP in the second cycle, but pays full payment in the third one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accruedProfit: "$2,958.90",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$2,959.00" },
                  { type: "", value: "" },
                  { type: "warning", value: "$2,958.90" },
                  { type: "", value: "" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$100,000.00",
                  accruedProfit: "$2,958.90",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accruedProfit: "$59.18",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "$3,150.00",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$5,000.00",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$97,000.00" },
                  { type: "", value: "$3,018.08" },
                  { type: "danger", value: "$5,917.80" },
                  { type: "warning", value: "$59.18" },
                  { type: "", value: "" },
                  { type: "", value: "" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$97,000.00",
                  accruedProfit: "$2,870.14",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$3,500.00",
                  accruedProfit: "$103.56",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$102,917.80",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$3,500.00" },
                  { type: "", value: "$2,974.00" },
                  { type: "", value: "" },
                  { type: "warning", value: "$103.56" },
                  { type: "success", value: "$2,929.32" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
          ],
        },
        {
          key: "c",
          label: "Chawki",
          mainDescription: "",
          scenarioDescription: "Pays full payment in the second cycle, but pays MTP in the third one",
          cycles: [
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accruedProfit: "$2,958.90",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$2,959.00" },
                  { type: "", value: "" },
                  { type: "warning", value: "$2,958.90" },
                  { type: "", value: "" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$100,000.00",
                  accruedProfit: "$2,958.90",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accruedProfit: "$59.18",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$100,000.00",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$3,018.08" },
                  { type: "", value: "" },
                  { type: "warning", value: "$59.18" },
                  { type: "success", value: "$5,917.80" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$2,000.00",
                  accruedProfit: "$59.18",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$8,000.00",
                  accruedProfit: "$236.71",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$100.00",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$9,900.00" },
                  { type: "", value: "$296.00" },
                  { type: "danger", value: "$118.36" },
                  { type: "warning", value: "$236.71" },
                  { type: "", value: "" },
                  { type: "success", value: "$3,031.64" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
          ],
        },
        {
          key: "d",
          label: "Dima",
          mainDescription: "",
          scenarioDescription: "Pays full payment always",
          cycles: [
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$100,000.00",
                  accruedProfit: "$2,958.90",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$100,000.00" },
                  { type: "", value: "$2,959.00" },
                  { type: "", value: "" },
                  { type: "warning", value: "$2,958.90" },
                  { type: "", value: "" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$100,000.00",
                  accruedProfit: "$2,958.90",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,000.00",
                  accruedProfit: "$59.18",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$100,000.00",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "$3,018.00" },
                  { type: "", value: "" },
                  { type: "warning", value: "$59.18" },
                  { type: "success", value: "$5,917.80" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
            {
              data: [
                {
                  subTitle: "Opening balance",
                  billedAmount: "$2,000.00",
                  accruedProfit: "$59.18",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "New debits",
                  billedAmount: "$2,500.00",
                  accruedProfit: "$73.97",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
                {
                  subTitle: "Payments",
                  billedAmount: "$2,000.00",
                  accruedProfit: "",
                  billedProfit: "",
                  deferredProfit: "",
                  waivedProfit: "",
                  specialDeposit: "",
                  mfpd: "",
                },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,500.00" },
                  { type: "", value: "$133.00" },
                  { type: "", value: "" },
                  { type: "warning", value: "$73.97" },
                  { type: "success", value: "$118.36" },
                  { type: "success", value: "$3,150.00" },
                  { type: "danger", value: "$3,150.00" },
                ],
              },
            },
          ],
        },
      ],
    },
  },
];
