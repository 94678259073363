import { SelectOption } from "@ni/common/types";

export const persoSelectValue = "???";

export const VIRT_PHYS_LIST: SelectOption[] = [
  {
    label: "Just virtual cards",
    value: "V",
  },
  { label: "Both physical and virtual cards", value: "B" },
];

export const REISS_PHYS_LIST: SelectOption[] = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const PERSO_LIST: SelectOption[] = [
  {
    label: "We will use Network International existing partner",
    value: persoSelectValue,
  },
  {
    label: "Other",
    value: "O",
    tooltip:
      "Please reach out to your Network International representative to discuss new integrations for your third-party personalization partners. The values in back-end Mapping will be synched from way4 in a newly created dictionary 'Perso'.",
  },
];
