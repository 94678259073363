import ElementTemplatesLayout from "./components/ElementTemplatesLayout";

import styles from "./styles.module.scss";

export function ElementTemplates() {
  return (
    <div className={styles["container"]}>
      <ElementTemplatesLayout />
    </div>
  );
}

export default ElementTemplates;
