import { FC } from "react";
import { Form } from "antd";

import {
  ALPHA2_COUNTRIES_LIST,
  CHECKBOX_GROUP_ELIGIBILITY_CRITERIA,
  LTY_RULE_CNTR,
  LTY_RULE_CNTR_LIST,
  LTY_RULES_CRITERIA,
  RADIO_GROUP,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useFieldMandatory, useLoyaltyWizardApi } from "../../hooks";

export const TransactionCountry: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const isCountriesFilled = Form.useWatch<string>(LTY_RULE_CNTR_LIST, form);
  const isCountryMandatory = useFieldMandatory({
    values: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    checkAgainst: LTY_RULES_CRITERIA,
    keyToInclude: CHECKBOX_GROUP_ELIGIBILITY_CRITERIA[0].value,
  });

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      lists: [LTY_RULE_CNTR_LIST],
      strings: [LTY_RULE_CNTR],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: {
          ...values,
          [LTY_RULE_CNTR_LIST]: (values[LTY_RULE_CNTR_LIST] as string[]).join(","),
        } as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!isCountriesFilled}
      pageTitle="Transaction Country"
      pageSubtitle="Points will be earned for transaction occurred within specific countries or outside them. Please specify the country criteria."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Radio
        formItemOptions={{
          name: LTY_RULE_CNTR,
          label: "Select countries",
          initialValue: RADIO_GROUP[0].value,
        }}
        radioList={RADIO_GROUP}
        initialValue={RADIO_GROUP[0].value}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: LTY_RULE_CNTR_LIST,
          label: "Country list",
          rules: [
            {
              required: isCountryMandatory,
              validator: (_, value: string[]) => {
                if (!isCountryMandatory || (value && value.length > 0)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please fill out country list."));
              },
            },
          ],
        }}
        mode="multiple"
        optionFilterProp="label"
        optionList={ALPHA2_COUNTRIES_LIST.map(country => ({
          value: country.code,
          label: country.name,
        }))}
      />
    </CustomFormWrapper>
  );
};
