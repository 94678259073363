import { FC, useEffect, useMemo } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DynamicSwitchForm, DynamicSwitchRule } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import {
  CardBillingFormNames,
  CardBillingSwitchHeaders,
  checkboxFieldsPage35,
  checkHeader,
  getCardBillingLimitsPreparedValues,
  switchHeader35,
} from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const TransactionCardLimitsBillingPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    form.setFieldsValue({
      [CardBillingFormNames.cashAtmEnable]: true,
      [CardBillingFormNames.cashAtmMaxAm]: formDisabledFn(CardBillingFormNames.cashAtmMaxAm, "500000"),
      [CardBillingFormNames.cashAtmMaxNr]: formDisabledFn(CardBillingFormNames.cashAtmMaxNr, "20"),
      [CardBillingFormNames.cashAtmMaxSingle]: formDisabledFn(CardBillingFormNames.cashAtmMaxSingle, "500000"),

      [CardBillingFormNames.retailEnable]: true,
      [CardBillingFormNames.retailMaxAm]: formDisabledFn(CardBillingFormNames.retailMaxAm, "500000"),
      [CardBillingFormNames.retailMaxNr]: formDisabledFn(CardBillingFormNames.retailMaxNr, "20"),
      [CardBillingFormNames.retailMaxSingle]: formDisabledFn(CardBillingFormNames.retailMaxSingle, "500000"),
    });

    Object.keys(checkboxFieldsPage35).forEach(key => {
      checkHeader(key, checkboxFieldsPage35, form);
    });

    checkHeader(CardBillingSwitchHeaders.allCashAtmEnable, switchHeader35, form);
    checkHeader(CardBillingSwitchHeaders.allRetailEnable, switchHeader35, form);
  }, [form, formDisabled, wizardResponse.product?.parameterTables]);

  const { showCash, showRetail } = useMemo(() => {
    const pctValues = wizardResponse?.product?.parameterTables?.[0].pctProductValues;

    return {
      pctValues,
      showCash: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("C"),
      showRetail: pctValues?.find(x => x.fieldCode === "trans-lim-trans-cat")?.value?.includes("R"),
    };
  }, [wizardResponse?.product?.parameterTables]);

  const rules = useMemo(() => {
    const rules: Record<"cash" | "retail", DynamicSwitchRule[]> = {
      cash: [
        {
          name: "c-b-cash-atm-enable",
          label: "ATM",
          tooltip: "This limiter counts all cash transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-b-cash-atm-max-nr",
          maxAmount: "nic-c-b-cash-atm-max-am",
          maxSingleAmount: "nic-c-b-cash-atm-max-single",
        },
      ],
      retail: [
        {
          name: "c-b-retail-enable",
          label: "All",
          tooltip: "This limiter counts all retail transactions. Turn it off, if restrictions are not intended.",
          maxNumber: "nic-c-b-retail-max-nr",
          maxAmount: "nic-c-b-retail-max-am",
          maxSingleAmount: "nic-c-b-retail-max-single",
        },
      ],
    };

    return rules;
  }, []);

  const onFinish = (value: FormValues): void => {
    let actualValues: { [key: string]: string } = {};

    actualValues = {
      ...actualValues,
      ...getCardBillingLimitsPreparedValues(value as { [key: string]: string | boolean }),
    };

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: actualValues,
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Card Limits - Billing Period"
      pageSubtitle="Card daily limits control spending on the card level independently from account limits, which even limit
      is reached first."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      {showCash && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-monthly-all-cash-enable"
          title="Cash"
          tooltip="Turns off all cash limits."
          rules={rules.cash}
          disabled={formDisabled}
        />
      )}

      {showRetail && (
        <DynamicSwitchForm
          form={form}
          currency={currency}
          name="card-monthly-all-retail-enable"
          title="Retail"
          tooltip="Turns off all retail transactions."
          rules={rules.retail}
          disabled={formDisabled}
        />
      )}
    </CustomFormWrapper>
  );
};
