import {
  LTY_PNT_MODE,
  LTY_POINT_MODE_RADIO_GROUP,
  LTY_REFUND_MODE,
  NIC_LTY_ACCR_ROUNDING_DECIMALS,
} from "@ni/common/constants";
import { FormContent, NetworkForm } from "@ni/common/ui";
import { validateNumberInput } from "@ni/common/utils";

export const LoyaltyPoints = () => {
  return (
    <FormContent gap={40}>
      <div>
        When a cardholder makes purchases, they earn points based on the count or amount of transaction which can then
        be redeemed for a value.
      </div>

      <NetworkForm.Radio
        radioList={LTY_POINT_MODE_RADIO_GROUP}
        formItemOptions={{ name: LTY_PNT_MODE, label: "Store loyalty points in:" }}
      />

      <NetworkForm.Switch
        formItemOptions={{
          valuePropName: "checked",
          name: LTY_REFUND_MODE,
          label: "Deduct points for refund transactions",
          tooltip:
            "Considering that refunds are not linked to the original transaction, points could be deducted even in case when original transaction might have accrued zero points due to capping or similar",
        }}
      />

      <NetworkForm.Number
        precision={0}
        formItemOptions={{
          name: NIC_LTY_ACCR_ROUNDING_DECIMALS,
          rules: [
            () => ({
              validator(_, value) {
                return validateNumberInput(value as number);
              },
            }),
          ],
          label: "Number of decimals to round points",
          tooltip:
            'Default is rounding to "cents" - fractional units of the currency used for points. 0 will round to basic unit of the currency, 1 - to 0.1, -1 to tens etc.',

          initialValue: 2,
        }}
      />
    </FormContent>
  );
};
