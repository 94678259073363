import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const ListViewIcon = ({ fill, ...props }: Partial<CustomIconComponentProps>) => {
  const IconSvg = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6912_10216)">
          <path
            d="M10.0007 11.3333H2.66732C2.3137 11.3333 1.97456 11.1929 1.72451 10.9428C1.47446 10.6928 1.33398 10.3536 1.33398 10V5.33333C1.33398 4.97971 1.47446 4.64057 1.72451 4.39052C1.97456 4.14048 2.3137 4 2.66732 4H10.0007C10.3543 4 10.6934 4.14048 10.9435 4.39052C11.1935 4.64057 11.334 4.97971 11.334 5.33333V10C11.334 10.3536 11.1935 10.6928 10.9435 10.9428C10.6934 11.1929 10.3543 11.3333 10.0007 11.3333ZM2.66732 5.33333V10H10.0007V5.33333H2.66732Z"
            fill={fill}
          />

          <path
            d="M21.3327 11.3333H13.9993C13.6457 11.3333 13.3066 11.1929 13.0565 10.9428C12.8065 10.6928 12.666 10.3536 12.666 10V5.33333C12.666 4.97971 12.8065 4.64057 13.0565 4.39052C13.3066 4.14048 13.6457 4 13.9993 4H21.3327C21.6863 4 22.0254 4.14048 22.2755 4.39052C22.5255 4.64057 22.666 4.97971 22.666 5.33333V10C22.666 10.3536 22.5255 10.6928 22.2755 10.9428C22.0254 11.1929 21.6863 11.3333 21.3327 11.3333ZM13.9993 5.33333V10H21.3327V5.33333H13.9993Z"
            fill={fill}
          />
          <path
            d="M10.0007 19.9998H2.66732C2.3137 19.9998 1.97456 19.8594 1.72451 19.6093C1.47446 19.3593 1.33398 19.0201 1.33398 18.6665V13.9998C1.33398 13.6462 1.47446 13.3071 1.72451 13.057C1.97456 12.807 2.3137 12.6665 2.66732 12.6665H10.0007C10.3543 12.6665 10.6934 12.807 10.9435 13.057C11.1935 13.3071 11.334 13.6462 11.334 13.9998V18.6665C11.334 19.0201 11.1935 19.3593 10.9435 19.6093C10.6934 19.8594 10.3543 19.9998 10.0007 19.9998ZM2.66732 13.9998V18.6665H10.0007V13.9998H2.66732Z"
            fill={fill}
          />
          <path
            d="M21.3327 19.9998H13.9993C13.6457 19.9998 13.3066 19.8594 13.0565 19.6093C12.8065 19.3593 12.666 19.0201 12.666 18.6665V13.9998C12.666 13.6462 12.8065 13.3071 13.0565 13.057C13.3066 12.807 13.6457 12.6665 13.9993 12.6665H21.3327C21.6863 12.6665 22.0254 12.807 22.2755 13.057C22.5255 13.3071 22.666 13.6462 22.666 13.9998V18.6665C22.666 19.0201 22.5255 19.3593 22.2755 19.6093C22.0254 19.8594 21.6863 19.9998 21.3327 19.9998ZM13.9993 13.9998V18.6665H21.3327V13.9998H13.9993Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_6912_10216">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  return <Icon component={IconSvg} {...props} />;
};
