/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ReactNode } from "react";
import { TableColumnType } from "antd";

import { TooltipInfo } from "../../../../ui/src/lib/components/TooltipInfo/TooltipInfo";

export const tawarruqScenariosColumns: TableColumnType<{ [x: string]: ReactNode }>[] = [
  {
    title: "",
    dataIndex: "subTitle",
    render: (subTitle: string) => (
      <TooltipInfo
        label={subTitle}
        tooltipProps={{
          title: {
            R: "Transactions were made in the previous billing cycles",
            N: "Transactions were made in the current billing cycle",
            P: "Depending on scenario can be full payment by the full payment day or due payment (MTP) by due date",
          }[subTitle?.charAt(0) as "R" | "N" | "P"],
          className: "tooltip-title",
        }}
      />
    ),
  },
  {
    title: <TooltipInfo label="Billed amount" tooltipProps={{ className: "tooltip-title" }} />,
    dataIndex: "billedAmount",
  },
  {
    title: <TooltipInfo label="Billed profit" tooltipProps={{ className: "tooltip-title" }} />,
    dataIndex: "billedProfit",
  },
];

export const tawarruqScenariosData = [
  {
    title: "none",
    tableData: {
      rates: [
        {
          label: "Profit rate",
          value: "8%",
        },
        {
          label: "Sales fee",
          value: "$5.00",
        },
        {
          label: "MTP",
          value: "5%",
        },
      ],
      scenarios: [
        {
          key: "a",
          label: "Ali",
          mainDescription: "",
          scenarioDescription: "Pays MTP only",
          cycles: [
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "$5.00", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$3,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$100.00", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$4,900.00" },
                  { type: "danger", value: "$157.00" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$5,057.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "$5.00", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$500.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$252.85", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$5,304.15" },
                  { type: "danger", value: "$389.33" },
                ],
              },
            },
          ],
        },
        {
          key: "b",
          label: "Bilal",
          mainDescription: "",
          scenarioDescription: "Pays MTP in the first cycle, but pays full payment in the second one",
          cycles: [
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "$5.00", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$3,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$100.00", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$4,900.00" },
                  { type: "danger", value: "$157.00" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$5,057.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$500.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$5,057.00", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$500.00" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
        {
          key: "c",
          label: "Chawki",
          mainDescription: "",
          scenarioDescription: "Pays full payment in the first cycle, but pays MTP in the second one",
          cycles: [
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$3,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$2,000.00", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$3,000.00" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$3,000.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "$5.00", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$500.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$150.00", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$3,350.00" },
                  { type: "danger", value: "$233.00" },
                ],
              },
            },
          ],
        },
        {
          key: "d",
          label: "Dima",
          mainDescription: "",
          scenarioDescription: "Pays full payment always",
          cycles: [
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$2,000.00" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$2,000.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$3,000.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$2,000.00", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$3,000.00" },
                  { type: "", value: "" },
                ],
              },
            },
            {
              data: [
                { subTitle: "Opening balance", billedAmount: "$3,000.00", billedProfit: "" },
                { subTitle: "Sales fee", billedAmount: "", billedProfit: "" },
                { subTitle: "New debits", billedAmount: "$500.00", billedProfit: "" },
                { subTitle: "Payments", billedAmount: "$3,000.00", billedProfit: "" },
              ],
              summary: {
                title: "EOC",
                tooltip: "Full payment date plus grace days if any",
                data: [
                  { type: "", value: "$500.00" },
                  { type: "", value: "" },
                ],
              },
            },
          ],
        },
      ],
    },
  },
];
