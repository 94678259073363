import React, { FC, useEffect, useMemo } from "react";
import { Button, Col, Form, FormInstance, Row, Space } from "antd";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { FormValues } from "@ni/common/types";
import { HeadingsRow, HeadingsRowProps, NetworkForm } from "@ni/common/ui";
import { removeGapsFromStringNumeric } from "@ni/common/utils";

import { PointAccrualTableField, TierField, TierRow } from "./PointAccrualTableField";

import styles from "./styles.module.scss";

interface PointAccrualTableProps {
  heading: HeadingsRowProps["headings"];
  amountCoundMode: string;
  form: FormInstance<FormValues>;
  productCurrency: string;
  tierRows: TierRow[];
  defaultTier: TierRow;
  onAddTier: () => void;
  onRemoveTier: (index: number) => void;
}

const getAccrualRateRule = (type: string) => ({
  required: type === "percentage",
  message: "Please enter Apply accrual rate",
});

export const PointAccrualTable: FC<PointAccrualTableProps> = ({
  heading,
  amountCoundMode,
  form,
  productCurrency,
  tierRows,
  defaultTier,
  onAddTier,
  onRemoveTier,
}) => {
  const latestAdditionalTierElement = useMemo(
    () => tierRows[tierRows.length - 1]?.fields?.find(field => field.name?.includes("max")) ?? ({} as TierField),
    [tierRows],
  );

  const latestAdditionalTierValue = Form.useWatch(latestAdditionalTierElement?.name ?? "", form) as string;

  const getAddonAfter = (type: string) =>
    type === "percentage" ? "%" : amountCoundMode === "AMOUNT" ? productCurrency : "";
  const getDecimalScae = (type: string) => (amountCoundMode === "AMOUNT" ? 2 : type !== "percentage" ? 0 : 2);

  useEffect(() => {
    if (tierRows.length > 0) {
      form.setFieldValue(defaultTier.fields[0].name!, latestAdditionalTierValue);
    } else {
      form.setFieldValue(defaultTier.fields[0].name!, 0);
    }
  }, [defaultTier.fields, form, latestAdditionalTierValue, tierRows.length]);

  return (
    <Space direction="vertical" size={16} className={styles["ni-point-accrual-setup-table"]}>
      <HeadingsRow headings={heading} firstColSpan={4} dividend={22} />
      {tierRows.map((row, index) => (
        <Row key={row.rowLabel}>
          <Col span={4}>
            <div className="ant-form-vertical ant-form-item-label">{row.rowLabel}</div>
          </Col>
          <Col span={20}>
            <Row gutter={[16, 16]}>
              {row.fields.map(field => {
                if (field.type === "button") {
                  return (
                    <Col key={field.name} span={2}>
                      <Button type="text" danger={true} onClick={() => onRemoveTier(index)}>
                        <DeleteOutlined />
                      </Button>
                    </Col>
                  );
                }

                const prevMaxField = tierRows.length > 1 ? tierRows[index - 1]?.fields[1].name : null;

                return (
                  <PointAccrualTableField
                    key={field.name}
                    form={form}
                    tierRows={tierRows}
                    row={row}
                    field={field}
                    rules={[
                      getAccrualRateRule(field.type),
                      {
                        required: field.name?.includes("max"),
                        validator: (_, value: string) => {
                          if (!field.name?.includes("max") || !prevMaxField || field.name.includes("1")) {
                            return Promise.resolve();
                          }

                          if (
                            +removeGapsFromStringNumeric(value) >
                            +removeGapsFromStringNumeric(form.getFieldValue(prevMaxField) as string)
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error("Max value cannot be less or equal then Min value"));
                        },
                      },
                      {
                        validator: (_, value: string) => {
                          if (field.name?.includes("max")) {
                            const maxValue = form.getFieldValue(defaultTier.fields[1].name) as string;
                            if (+removeGapsFromStringNumeric(value) > +maxValue) {
                              return Promise.reject(
                                new Error("Value from Default  tier cannot be less than value from Additional tier"),
                              );
                            }
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                    addonAfter={getAddonAfter(field.type)}
                    decimalScale={getDecimalScae(field.type)}
                  />
                );
              })}
            </Row>
          </Col>
        </Row>
      ))}

      <Row>
        <Col span={4}>
          <div className="ant-form-vertical ant-form-item-label">{defaultTier.rowLabel}</div>
        </Col>
        <Col span={20}>
          <Row gutter={[16, 16]}>
            {defaultTier.fields.map(field => {
              if (field.type === "button") return null;

              return (
                <Col key={field.name} span={Math.round(22 / (defaultTier.fields.length - 1))}>
                  <Form.Item
                    name={field.name}
                    rules={[getAccrualRateRule(field.type)]}
                    initialValue={field.initialValue}
                  >
                    <NetworkForm.Number
                      min={0}
                      precision={getDecimalScae(field.type)}
                      addonAfter={getAddonAfter(field.type)}
                      disabled={field.disabled}
                      defaultValue={field.initialValue}
                      stringMode={true}
                    />
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>

      {tierRows.length < 5 && (
        <Button type="link" icon={<PlusOutlined />} onClick={onAddTier}>
          Add additional {amountCoundMode === "AMOUNT" ? "amount" : "count"} tier
        </Button>
      )}
    </Space>
  );
};
