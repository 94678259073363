import { useCallback } from "react";

import { useReduxState } from "@ni/common/hooks";
import { LoyaltyWizardApi } from "@ni/sdk/apis";
import { FullDashboardProduct, LoyaltyWizardRequest, LoyaltyWizardResponse, Tenant, Workflow } from "@ni/sdk/models";

const loyaltyWizardApi = new LoyaltyWizardApi();

export const useLoyaltyWizardApi = () => {
  const [appliedProducts, setAppliedProducts] = useReduxState<({ name?: string } & FullDashboardProduct)[]>(
    "appliedProducts",
    [],
  );
  const [, setWizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const initWizard = useCallback(async (tenantId: string) => {
    try {
      const response = await loyaltyWizardApi.initWizard2({ tenantId: parseInt(tenantId, 10) });

      return {
        pageId: response.data.pageId,
        workflow: response.data.workflow,
        tenant: response.data.tenant,
      };
    } catch (error) {
      return {
        pageId: 0,
        workflow: {} as Workflow,
        tenant: {} as Tenant,
      };
    }
  }, []);

  const processWizardRequest = useCallback(
    async (loyaltyWizardRequest: LoyaltyWizardRequest) => {
      const response = await loyaltyWizardApi.processWizardRequest1(loyaltyWizardRequest);
      setWizardResponse(response.data);
    },
    [setWizardResponse],
  );

  return {
    initWizard,
    processWizardRequest,
    setAppliedProducts,
    appliedProducts,
  };
};
