import { memo, useState } from "react";
import { Collapse, Switch } from "antd";
import cn from "classnames";
import type { FC, MouseEventHandler } from "react";
import { useDrag, useDrop } from "react-dnd";

import { CopyOutlined, DeleteOutlined, HolderOutlined, UpOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { ConfirmModal } from "@ni/common/ui";
import { ElementApi } from "@ni/sdk/apis";
import { Element, State } from "@ni/sdk/models";

import { ItemTypes } from "../../constants";
import ElementForm from "../ElementForm";

import styles from "./styles.module.scss";

const elementServiceApi = new ElementApi();

const { Panel } = Collapse;

interface CardProps {
  element: Element;
  id: string;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
  workflowId: number;
  phaseId: number;
  pageId: number;
  index: number;
  onUpdateElementsOrder: (elementItems: Element[]) => void;
  getElements: () => void;
}

interface Item {
  id: string;
  originalIndex: number;
}

const CardFunc: FC<CardProps> = props => {
  const { element, id, moveCard, findCard, workflowId, phaseId, pageId, index, onUpdateElementsOrder, getElements } =
    props;

  const originalIndex = findCard(id).index;
  const [elementItems, setElementItems] = useReduxState<Element[]>("elementItems", []);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [, setIsLoading] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.ELEMENT,
      item: { id, originalIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
        onUpdateElementsOrder(elementItems);
      },
    }),
    [id, originalIndex, moveCard],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.ELEMENT,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard],
  );

  const onChangeCollapse = () => {
    setIsActive(!isActive);
  };

  const onCancelDuplicateModal = () => {
    setIsDuplicateModalOpen(false);
  };

  const onCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSwitchElement = () => {
    if (element.id) {
      setIsLoading(true);
      elementServiceApi
        .editElement(
          {
            code: element.code,
            defaultValue: element.defaultValue,
            disabledConditions: element.disabledConditions,
            editableProductState: element.editableProductState,
            editableTenantState: element.editableTenantState,
            elementGroup: element.elementGroup,
            elementType: element.elementType,
            elementValueCode: element.elementValueCode,
            hiddenConditions: element.hiddenConditions,
            hint: element.hint,
            label: element.label,
            order: element.order,
            state: element.state === State.DISABLED ? State.ENABLED : State.DISABLED,
            suggestedValue: element.suggestedValue,
            validations: element.validations,
          },
          workflowId,
          phaseId,
          pageId,
          Number(element.id),
        )
        .then(() => {
          setIsLoading(false);
          getElements();
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      const newElementList: Element[] = elementItems.map(item => {
        if (String(item.id) === String(element.id)) {
          return {
            ...item,
            state: element.state === State.ENABLED ? State.DISABLED : State.ENABLED,
          };
        }

        return item;
      });
      setElementItems(newElementList);
    }
  };

  const extraHeader = () => (
    <div className={styles["element-collapse-header"]}>
      <HolderOutlined style={{ fontSize: "150%" }} />
      <div className={styles["order"]}>{index + 1 ?? "-"}</div>
      <div className={styles["title"]}>
        <div className={styles["name"]}>
          {element?.label ?? "Element"}
          {isActive ? (
            <UpOutlined className={styles["arrow"]} />
          ) : (
            <UpOutlined className={cn(styles["arrow"], styles["open"])} />
          )}
        </div>
        <div className={styles["description"]}>{element?.elementType} </div>
      </div>
    </div>
  );

  const extraPanel = () => (
    <div className={styles["element-collapse-header-extra"]}>
      <Switch
        defaultChecked={element?.state === State.ENABLED}
        checked={element?.state === State.ENABLED}
        onClick={onSwitchElement}
      />
      <CopyOutlined className={styles["button"]} onClick={() => setIsDuplicateModalOpen(true)} />
      <DeleteOutlined className={styles["button"]} onClick={() => setIsDeleteModalOpen(true)} />
    </div>
  );

  const onElementDelete = () => {
    setIsLoading(true);

    elementServiceApi
      .deleteElement(workflowId, phaseId, pageId, element.id!)
      .then(() => {
        onCancelDeleteModal();
        getElements();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onElementCopy = (values: FormValues) => {
    setIsLoading(true);

    elementServiceApi
      .copyElement({ newCode: values["newCode"] as string }, workflowId, phaseId, pageId, element.id!)
      .then(() => {
        onCancelDuplicateModal();
        getElements();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className={cn(styles["element"], id !== "undefined" ? isDragging && styles["opacity"] : styles["notDND"])}
        ref={node => {
          if (id !== "undefined") {
            drag(drop(node));
          }
        }}
      >
        <Collapse
          className={styles["element-collapse"]}
          collapsible="header"
          onChange={onChangeCollapse}
          defaultActiveKey={id ?? "-1"}
        >
          <Panel header={extraHeader()} key={id} showArrow={false} extra={extraPanel()}>
            <ElementForm
              element={element}
              workflowId={workflowId}
              phaseId={phaseId}
              pageId={pageId}
              getElements={getElements}
            />
          </Panel>
        </Collapse>
      </div>
      <ConfirmModal
        title="Copy of element will be created"
        isOpened={isDuplicateModalOpen}
        onCancel={onCancelDuplicateModal}
        onConfirm={onElementCopy as unknown as MouseEventHandler<HTMLElement>}
        isLoading={false}
        type="duplicate-element"
        description="Provide the code for the new element"
      />
      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the element. Are you sure?"
        isOpened={isDeleteModalOpen}
        onCancel={onCancelDeleteModal}
        onConfirm={onElementDelete}
        isLoading={false}
        type="delete"
      />
    </>
  );
};

const ElementCard: FC<CardProps> = memo(CardFunc);
export default ElementCard;
