import { useMemo } from "react";
import { Col, Row } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { GetProductWithId } from "@ni/common/types";
import { PageItemLayoutGeneral, SettingsPageLayout } from "@ni/common/ui";
import { validateJsonPath } from "@ni/common/utils";
import { Tenant } from "@ni/sdk/models";

import { SettingsOverviewCard, SettingsOverviewSection } from "../../../components";

import { SETTINGS_OVERVIEW_LIST } from "./SettingsOverviewLists";

export const SettingsOverviewPage = () => {
  const [currentProduct] = useReduxState<GetProductWithId>("currentProduct", {} as GetProductWithId);
  const [tenant] = useReduxState<Tenant>("tenant", {});

  const settingsOverviewList = useMemo(() => {
    return SETTINGS_OVERVIEW_LIST.map(settings => {
      const filteredElements = settings.elements.filter(element => {
        const objBasedOnDep =
          settings.dependencyType === "PRODUCT"
            ? currentProduct
            : settings.dependencyType === "TENANT"
              ? tenant
              : currentProduct;
        return !element.dependency || validateJsonPath(objBasedOnDep, element.dependency);
      });

      return {
        ...settings,
        elements: filteredElements,
      };
    }).filter(settings => {
      const objBasedOnDep =
        settings.dependencyType === "PRODUCT"
          ? currentProduct
          : settings.dependencyType === "TENANT"
            ? tenant
            : currentProduct;
      return (
        (!settings.dependency || validateJsonPath(objBasedOnDep, settings.dependency)) && settings.elements.length > 0
      );
    });
  }, [currentProduct, tenant]);

  return (
    <SettingsPageLayout pageTitle="Pricing Control Table - Settings Overview" level="pct" size="lg">
      <PageItemLayoutGeneral>
        {settingsOverviewList.map(setting => (
          <SettingsOverviewSection
            key={setting.title}
            title={
              <>
                {setting.titleIcon} {setting.title}
              </>
            }
          >
            <Row wrap={true} gutter={[16, 16]}>
              {setting.elements.map(el => (
                <Col key={el.title} span={8}>
                  <SettingsOverviewCard title={el.title} text={el?.text ?? ""} href={el.href} />
                </Col>
              ))}
            </Row>
          </SettingsOverviewSection>
        ))}
      </PageItemLayoutGeneral>
    </SettingsPageLayout>
  );
};
