export const CHECKBOX_GROUP_GENERAL_FEES_PRESET = [
  {
    value: "M",
    label: "Membership fees",
    tooltip:
      "A card membership fee is a recurring fee. It could be annual or monthly fee that cardholders are required to pay in order to maintain the benefits and privileges associated with the card.",
  },
  {
    value: "J",
    label: "Joining fee",
    tooltip:
      "A joining fee is a one-time fee charged to customer upon account opening, it is separate from any recurring membership fees or dues.",
  },
  {
    value: "C",
    label: "Card production fees",
    tooltip:
      "A card production fee is a one-time fee to cover the cost of producing and issuing the physical card. It is typically charged when a new card is issued or when a card is replaced due to loss, damage, or expiration.",
  },
  {
    value: "S",
    label: "Statement fee",
    tooltip:
      "A statement fee is a charge imposed for the provision of monthly or periodic account statements to the cardholder. It is a fee that covers the cost of generating, processing, and mailing the statement to the cardholder.",
  },
  {
    value: "I",
    label: "Inactivity fees",
    tooltip:
      "An inactivity fee is charged when an account remains inactive for a certain period of time. It is intended to cover the costs associated with maintaining the account, providing customer support, and ensuring compliance with regulations.",
  },
  {
    value: "O",
    label: "Overlimit fee",
    tooltip: "Overlimit fee is charged when the utilization of a card exceeds the allowed credit limit",
  },
  {
    value: "L",
    label: "Late payment fee",
    tooltip:
      "Late Payment fee is charged to cardholder when the minimum payment is not made towards due amount until the configured late payment date",
  },
  {
    value: "X",
    label: "Credit limit modification fees",
    tooltip:
      "It can be charged when a client requests an increase or decrease in their permanent or temporary credit limit.",
  },
];
