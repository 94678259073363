import React, { FC, useEffect, useState } from "react";
import { Form, FormInstance, Input, Slider } from "antd";
import cn from "classnames";
import { parseInt } from "lodash";

import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { FormValues, SliderRanges } from "@ni/common/types";
import { extractNumbers } from "@ni/common/utils";

import { NetworkForm } from "../FormInput";
import { PageItemLayoutGroup } from "../PageItemLayout";

import styles from "./styles.module.scss";

interface InputRangeSliderProps {
  form: FormInstance<FormValues>;
  disabled?: boolean;
}

export const InputRangeSlider: FC<InputRangeSliderProps> = ({ form, disabled = false }) => {
  const [minRange, setMinRange] = useState("0000000000");
  const [maxRange, setMaxRange] = useState("9999999999");
  const [binMask, setBinMask] = useState("XXXX XX");

  const binValue = Form.useWatch<string>("nic-card-subtype-pan-bin", form);
  const minValue = Form.useWatch<SliderRanges>("sliderRanges", form)?.["nic-card-subtype-pan-full-min"] ?? minRange;
  const maxValue = Form.useWatch<SliderRanges>("sliderRanges", form)?.["nic-card-subtype-pan-full-max"] ?? maxRange;

  useEffect(() => {
    if (minValue) setMinRange(minValue);
    if (maxValue) setMaxRange(maxValue);
  }, [maxValue, minValue]);

  const padLeft = (text: string, padChar: string, size: number): string => {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  };

  useEffect(() => {
    if (!binValue) {
      setBinMask("XXXX XX");
      return;
    }

    let maskFirstPart = new Array(4).fill("X");
    let markSecondPart = new Array(2).fill("X");

    maskFirstPart = maskFirstPart.map((maskVal, index) => binValue?.toString().slice(0, 4)[index] || "X");
    markSecondPart = markSecondPart.map((maskVal, index) => binValue?.toString().slice(4, 6)[index] || "X");

    setBinMask(`${maskFirstPart.join("")} ${markSecondPart.join("")}`);
  }, [binValue]);

  const formatBinValue = (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.slice(0, 10);

    if (value.length > 2) {
      let formattedValue = `${value.slice(0, 2)} ${value.slice(2, 6)} ${value.slice(6)}`;
      formattedValue = formattedValue.trim();
      return formattedValue;
    }

    return value;
  };

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const extractedNumbers = extractNumbers(value);

    setMinRange(extractedNumbers);
    form.setFieldsValue({ sliderRanges: { "nic-card-subtype-pan-full-min": extractedNumbers } });
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const extractedNumbers = extractNumbers(value);

    setMaxRange(extractedNumbers);
    form.setFieldsValue({ sliderRanges: { "nic-card-subtype-pan-full-max": extractedNumbers } });
  };

  const setSliderRange = (event: [number, number]) => {
    const minRangeVal = padLeft(event[0].toString(), "0", 10);
    const maxRangeVal = padLeft(event[1].toString(), "0", 10);

    setMinRange(minRangeVal);
    setMaxRange(maxRangeVal);
    form.setFieldsValue({ sliderRanges: { "nic-card-subtype-pan-full-min": minRangeVal } });
    form.setFieldsValue({ sliderRanges: { "nic-card-subtype-pan-full-max": maxRangeVal } });
  };

  const subtractFromRange = () => {
    const newMinRange = parseInt(minRange) - 1 < 0 ? 0 : parseInt(minRange) - 1;
    setMinRange(padLeft(newMinRange.toString(), "0", 10));
    form.setFieldsValue({ sliderRanges: { "nic-card-subtype-pan-full-min": String(newMinRange) } });
  };

  const addToRange = () => {
    const newMaxRange = parseInt(maxRange) + 1 < 9999999999 ? parseInt(maxRange) + 1 : 9999999999;
    setMaxRange(padLeft(newMaxRange.toString(), "0", 10));
    form.setFieldsValue({ sliderRanges: { "nic-card-subtype-pan-full-max": String(newMaxRange) } });
  };

  return (
    <PageItemLayoutGroup className={styles["input-range-slider"]}>
      <Input.Group className={styles["input-group"]}>
        <Form.Item label="Min">
          <NetworkForm.String
            maxLength={12}
            addonBefore={binMask}
            onChange={handleMinChange}
            value={formatBinValue(minRange)}
            disabled={disabled}
          />
        </Form.Item>

        <Form.Item label="Max" className="m-l-16">
          <NetworkForm.String
            maxLength={12}
            addonBefore={binMask}
            onChange={handleMaxChange}
            value={formatBinValue(maxRange)}
            disabled={disabled}
          />
        </Form.Item>
      </Input.Group>
      <div className={styles["icons-slider-wrapper"]}>
        <MinusSquareOutlined
          className={cn(styles["slider-icon"], disabled && styles["slider-icon-disabled"])}
          color="primary"
          onClick={subtractFromRange}
        />
        <Slider
          range={true}
          min={0}
          max={9999999999}
          step={1}
          tooltip={{ placement: "bottom" }}
          value={[parseInt(minRange), parseInt(maxRange)]}
          onChange={setSliderRange}
          disabled={disabled}
        />
        <PlusSquareOutlined
          className={cn(styles["slider-icon"], disabled && styles["slider-icon-disabled"])}
          onClick={addToRange}
        />
      </div>
    </PageItemLayoutGroup>
  );
};
