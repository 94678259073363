import { FC, useCallback, useMemo } from "react";
import { Checkbox, Empty, Space, SpaceProps, Spin, Table, TableColumnType } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import classnames from "classnames";

import { DashboardProduct, FullDashboardProduct } from "@ni/sdk/models";

import { CardView } from "../../CardView";
import { PageItemLayoutElements } from "../../PageItemLayout";

import { InsuranceProgramsList } from "./InsuranceProgramsList";

import styles from "./styles.module.scss";

interface ProductListProps {
  products?: Array<FullDashboardProduct>;
  hasCheckAll?: boolean;
  direction?: SpaceProps["direction"];
  size?: SpaceProps["size"];
  className?: string;
  value?: Array<string>;
  onChange?: (values: CheckboxValueType[]) => void;
  noDataMessage?: string;
  ignoredColumnTitle?: string;
  includeBin?: boolean;
}

export const ProductsTable: FC<ProductListProps> = ({
  products,
  hasCheckAll,
  value,
  onChange,
  noDataMessage,
  ignoredColumnTitle = "",
  includeBin,
}) => {
  const productsId = useMemo(() => products?.map(x => x?.product.id) ?? [], [products]);

  const handleChange = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      if (onChange) {
        onChange(checkedValues);
      }
    },
    [onChange],
  );

  const handleAllcheck = useCallback(
    ({ target: { checked } }: CheckboxChangeEvent) => {
      handleChange(checked ? productsId : []);
    },
    [handleChange, productsId],
  );

  const columns: TableColumnType<DashboardProduct>[] = useMemo(
    () =>
      [
        {
          title: () =>
            hasCheckAll &&
            productsId && (
              <div className={styles["main-check-for-all"]}>
                <Checkbox
                  checked={productsId?.length === value?.length}
                  onChange={handleAllcheck}
                  className={classnames(styles["checkbox"], styles["coloredLabel"])}
                />
              </div>
            ),
          dataIndex: ["id"],
          key: "id",
          width: "6%",
          ellipsis: true,
          render: (_: string, product: DashboardProduct) => (
            <Checkbox
              value={product.id}
              checked={[...(value ?? [])].map(x => x.toString()).includes(product.id.toString())}
              onChange={e => {
                if (e.target.checked) handleChange([...(value ?? []), e.target.value] as CheckboxValueType[]);
                else handleChange([...(value ?? [])].filter(x => x !== e.target.value) as CheckboxValueType[]);
              }}
              className={styles["checkbox"]}
            />
          ),
        },
        {
          title: "Product Name",
          dataIndex: ["name"],
          key: "name",
          width: "34%",
          render: (_: string, item: DashboardProduct) => (
            <Space size={18}>
              <CardView cardImage={item?.cardImage} prodIps={item?.paymentScheme} cardSize="small" />
              {item?.name}
            </Space>
          ),
        },
        ...(includeBin
          ? [
              {
                title: "BIN Number",
                dataIndex: ["bin"],
                key: "bin",
                width: "15%",
                render: (_: string, item: DashboardProduct) => item?.bin,
              },
            ]
          : []),
        {
          title: "Product Type",
          dataIndex: ["productType"],
          key: "loyaltyCode",
          width: "15%",
          ellipsis: true,
          render: (_: string, item: DashboardProduct) => item.productType,
        },
        {
          title: "Product Currency",
          dataIndex: ["currency"],
          key: "type",
          width: "15%",
          ellipsis: true,
          render: (_: string, item: DashboardProduct) => item.currency,
        },
        {
          title: "Enabled Insurance Programs",
          dataIndex: ["currency"],
          key: "type",
          width: "30%",
          ellipsis: true,
          render: (_: string, item: DashboardProduct) => (
            <InsuranceProgramsList namesList={[...(item?.insurancePrograms ?? [])]} />
          ),
        },
      ].filter(x => x.title !== ignoredColumnTitle),
    [handleAllcheck, handleChange, hasCheckAll, ignoredColumnTitle, includeBin, productsId, value],
  );

  return products ? (
    products?.length ? (
      <PageItemLayoutElements>
        <Table<DashboardProduct>
          rowKey="id"
          className={styles["ni-loyalty-program-details"]}
          columns={columns}
          dataSource={products?.map(x => x.product)}
          loading={!products}
          pagination={false}
        />
        <br />
      </PageItemLayoutElements>
    ) : (
      <div className={styles["spinContainer"]}>
        {noDataMessage ? <p className={styles["coloredLabel"]}>{noDataMessage}</p> : <Empty />}
      </div>
    )
  ) : (
    <div className={styles["spinContainer"]}>
      <Spin />
    </div>
  );
};
