import { FC, useCallback, useEffect, useState } from "react";
import { Button, ConfigProvider, Form, Input, Select } from "antd";
import Search from "antd/lib/transfer/search";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import {
  balanceSourceOptionsFilter,
  clientTypeOptionsFilter,
  productSubtypeOptionsFilter,
  productTypeOptionsFilter,
  workflowTypeOptionsFilter,
} from "@ni/common/mocks";
import { WorkflowFilters } from "@ni/common/types";
import { PageHeading, Spin } from "@ni/common/ui";
import { RegionApi } from "@ni/sdk/apis";
import { Region } from "@ni/sdk/models";

import WorkflowTableView from "../../components/WorkflowTableView";

import styles from "./styles.module.scss";

const { Option } = Select;

const regionServiceApi = new RegionApi();
interface RegionListType {
  code: string;
  name: string;
}

export const Workflow: FC = () => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<WorkflowFilters>({});
  const [, setRegions] = useReduxState<Region[]>("regions");
  const [regionList, setRegionList] = useState<RegionListType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onFormChange = (currentValue: { [key: string]: string }, value: { [key: string]: string }) => {
    const filter: WorkflowFilters = Object.keys(value).reduce((acc, key) => {
      if (key === "searchValue" && value[key]) {
        return { ...acc, search: value[key] };
      }
      if (value[key] && value[key] !== "all") {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: value[key],
          },
        };
      }
      if (value[key] && value[key] === "all") {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: "",
          },
        };
      }

      return acc;
    }, {} as WorkflowFilters);
    setFilters(filter);
  };

  const getRegionList = useCallback((): void => {
    setIsLoading(true);
    regionServiceApi
      .getRegions()
      .then((response: AxiosResponse<Region[]>) => {
        setRegions(response.data);
        setRegionList([...response.data, { code: "all", name: "All" }]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, setRegions]);

  useEffect(() => {
    getRegionList();
  }, [getRegionList]);

  const goCreateNewWorkflow = () => {
    navigate(`/admin/workflow-settings/create`);
  };

  const extra = (
    <div className={styles["extra"]} key="extraDiv">
      <Button className={styles["new-workflow-button"]} type="primary" htmlType="button" onClick={goCreateNewWorkflow}>
        Create workflow
      </Button>
      <Form.Item name="searchValue">
        <Search placeholder="Search" />
      </Form.Item>
    </div>
  );

  if (isLoading) {
    return <Spin />;
  }

  return (
    <ConfigProvider componentSize="middle">
      <Form form={form} className={styles["workflow-layout"]} onValuesChange={onFormChange}>
        <PageHeading text="Workflow" extraElements={[extra]} />
        <div className={styles["filters-bar"]}>
          <Form.Item name="region.code">
            <Select placeholder="Region">
              {regionList?.map(option => (
                <Option key={option.code} value={option.code}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="code">
            <Input placeholder="Code" />
          </Form.Item>
          <Form.Item name="clientType">
            <Select placeholder="Client type">
              {clientTypeOptionsFilter.map(option => (
                <Option key={option.value + option.label} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="productType">
            <Select placeholder="Product type">
              {productTypeOptionsFilter.map(option => (
                <Option key={option.value + option.label} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="balanceSource">
            <Select placeholder="Balance source">
              {balanceSourceOptionsFilter.map(option => (
                <Option key={option.value + option.label} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="productSubtype">
            <Select placeholder="Product subtype">
              {productSubtypeOptionsFilter.map(option => (
                <Option key={option.value + option.label} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="type">
            <Select placeholder="Workflow type">
              {workflowTypeOptionsFilter.map(option => (
                <Option key={option.value + option.label} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <WorkflowTableView filters={filters} />
      </Form>
    </ConfigProvider>
  );
};
