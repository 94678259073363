import React, { FC, forwardRef, Ref, useEffect, useState } from "react";
import { Checkbox as AntdCheckbox, Space, SpaceProps, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { SelectOption } from "@ni/common/types";

import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

const { Text } = Typography;

export interface CheckboxGroupProps {
  selectAllTitle?: string;
  checkboxes: Array<Pick<SelectOption, "value" | "label" | "tooltip">>;
  size?: SpaceProps["size"];
  value?: string[];
  showSelectAll?: boolean;
  onChange?: (values: CheckboxValueType[]) => void;
}

export const CheckboxGroup: FC<CheckboxGroupProps> = forwardRef(
  (
    { selectAllTitle, checkboxes, size = 16, value, showSelectAll = true, onChange }: CheckboxGroupProps,
    ref: Ref<HTMLDivElement> | undefined,
  ) => {
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
    const [indeterminate, setIndeterminate] = useState<boolean>(true);
    const [checkAll, setCheckAll] = useState<boolean>(false);

    useEffect(() => {
      if (checkedList.length < checkboxes.length && checkedList.length > 0) setIndeterminate(true);
      else setIndeterminate(false);

      if (checkedList.length === checkboxes.length) setCheckAll(true);
      else setCheckAll(false);
    }, [checkboxes.length, checkedList]);

    useEffect(() => {
      if (value) setCheckedList(value);
    }, [value]);

    const handleChange = (checkedValues: CheckboxValueType[], select?: string) => {
      const newValues: CheckboxValueType[] =
        select === "checkAll" ? (checkAll ? [] : checkboxes.map(checkbox => checkbox.value)) : checkedValues;

      if (onChange) onChange(newValues);
      setCheckedList(newValues);
    };

    return (
      <Space direction="vertical" size={16} className={styles["checkbox-group-container"]}>
        {showSelectAll && (
          <AntdCheckbox
            indeterminate={indeterminate}
            checked={checkAll}
            onChange={() => {
              handleChange(checkedList, "checkAll");
            }}
            className={indeterminate ? styles["checkbox-indeterminate"] : ""}
          >
            {selectAllTitle ? <Text strong={true}>{selectAllTitle}</Text> : "Select All"}
          </AntdCheckbox>
        )}
        <AntdCheckbox.Group onChange={handleChange} value={checkedList}>
          <Space size={showSelectAll ? 24 : 0}>
            <div />
            <Space direction="vertical" size={size} ref={ref}>
              {checkboxes.map(val => (
                <AntdCheckbox key={val.value} value={val.value}>
                  <TooltipInfo
                    label={val.label}
                    code={val.value}
                    tooltipProps={val?.tooltip ? { title: val?.tooltip } : {}}
                  />
                </AntdCheckbox>
              ))}
            </Space>
          </Space>
        </AntdCheckbox.Group>
      </Space>
    );
  },
);
