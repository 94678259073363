export const dateFormat = (
  time: string,
  locales = "en-US",
  options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
): string => {
  return new Date(time).toLocaleString(locales, options);
};
