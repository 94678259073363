import { FC } from "react";
import { Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, DocumentationList, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { interfacesWizardPage } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const InterfacesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const interfacesWizardList = interfacesWizardPage;

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const onFinish = (): void => {
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {},
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Interfaces"
      pageSubtitle={
        <TooltipInfo
          largeLabel="Interfaces are the files which has potential impact on the information in the system."
          tooltipProps={{
            title: (
              <>
                <span>Interfaces are usually used for the following reasons:</span>
                <ul>
                  <li>Incoming files like account boarding files which will update demographic details.</li>
                  <li>Outgoing extract which holds information on the account like demographic details, etc.</li>
                  <li>
                    Transaction dump file which shows all the transactions & its details that are posted in the last
                    batch.
                  </li>
                </ul>
              </>
            ),
          }}
        />
      }
      size="full"
      formSize="full"
      gap={40}
      level="root"
      submitHandler={onFinish}
    >
      <DocumentationList documentationList={interfacesWizardList} data={wizardResponse} />
    </CustomFormWrapper>
  );
};
