import { FC, useCallback, useState } from "react";
import { Button, Collapse, CollapsePanelProps, Dropdown, Typography } from "antd";

import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { ConfirmModal, Spin } from "@ni/common/ui";
import { VariablesApi } from "@ni/sdk/apis";
import { VariableGroup } from "@ni/sdk/models";

import { Tags } from "../Tags";
import { VariableList } from "../VariableList";
import { VariablesGroupModal } from "../VariablesGroupModal";

import styles from "./styles.module.scss";

const { Panel } = Collapse;

const variablesApi = new VariablesApi();

interface IVariableGroupItem extends CollapsePanelProps {
  eventGroups: VariableGroup["eventGroups"];
  name: VariableGroup["name"];
  groupId: VariableGroup["id"];
  getSMSVariablesDictionary: () => void;
  header: string;
}

export const VariableGroupItem: FC<IVariableGroupItem> = props => {
  const { name, groupId, eventGroups, getSMSVariablesDictionary, header, ...panelProps } = props;
  const [varGroupId, setVarGroupId] = useState<number | null>(null);
  const [isEditGroupOpened, setIsEditGroupOpened] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [variableGroups, setVariableGroups] = useReduxState<VariableGroup[]>("variableGroups");

  const onCancelDeleteModal = (): void => {
    setIsDeleteModalOpened(false);
  };

  const handleDelete = useCallback(
    (id: number): void => {
      setIsLoading(true);
      if (varGroupId !== null) {
        variablesApi
          .deleteVariableGroup(id)
          .then(() => {
            onCancelDeleteModal();
            setIsLoading(false);
            setVariableGroups(variableGroups.filter(i => i.id !== id));
          })
          .catch(() => {
            onCancelDeleteModal();
            setIsLoading(false);
          });
      }
    },
    [setIsLoading, setVariableGroups, varGroupId, variableGroups],
  );

  const extra = (id: number) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span onClick={event => event.stopPropagation()}>
      <Dropdown
        menu={{
          items: [
            {
              key: `${Math.random()}-1`,
              label: (
                <Typography.Text
                  onClick={e => {
                    setIsEditGroupOpened(true);
                    e.stopPropagation();
                  }}
                >
                  Edit group
                </Typography.Text>
              ),
              icon: <EditOutlined />,
            },
            {
              key: `${Math.random()}-2`,
              label: (
                <Typography.Text
                  onClick={e => {
                    setIsDeleteModalOpened(true);
                    setVarGroupId(id);
                    e.stopPropagation();
                  }}
                >
                  Delete group
                </Typography.Text>
              ),
              icon: <DeleteOutlined />,
            },
          ],
        }}
        trigger={["hover"]}
      >
        <Button
          type="text"
          shape="circle"
          onClick={e => {
            e.stopPropagation();
          }}
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </span>
  );

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Panel
        {...panelProps}
        header={header}
        extra={extra(groupId)}
        key={`${groupId}-panel}`}
        className={styles["collapsible-panel"]}
      >
        <Tags eventGroup={eventGroups} />
        <VariableList groupId={groupId} />
      </Panel>
      <VariablesGroupModal
        item={{ name, id: groupId, eventGroups }}
        opened={isEditGroupOpened}
        closeDrawer={() => setIsEditGroupOpened(false)}
      />
      <ConfirmModal
        title="Are you sure delete this Variable group? By clicking `Confirm` you confirm deleting the Variable group and variables that inside the group."
        isOpened={isDeleteModalOpened}
        onCancel={onCancelDeleteModal}
        onConfirm={() => {
          if (varGroupId) {
            handleDelete(varGroupId);
            setVarGroupId(null);
          }
        }}
        isLoading={!varGroupId}
        type="delete-variable"
        key={`confirmModal-${Math.random()}`}
      />
    </>
  );
};
