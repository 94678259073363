/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceProgramTemplateState = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DRAFT: 'DRAFT'
} as const;

export type InsuranceProgramTemplateState = typeof InsuranceProgramTemplateState[keyof typeof InsuranceProgramTemplateState];



