import { Value } from "@ni/common/types";
import { ParameterTable, ParameterTableValue } from "@ni/sdk/models";

interface Props {
  pctId?: string;
  parameterTables?: ParameterTable[];
  fieldCode: string;
  fieldValue: Value;
  newFieldValue: Value;
}

const checkEveryPctValue = ({
  pctId,
  parameterTables,
  fieldCode,
  fieldValue,
  newFieldValue,
}: Props): boolean | undefined => {
  return parameterTables?.every(table => {
    let updatedPctProductValues = [...(table.pctProductValues as ParameterTableValue[])];

    if (Number(table.id) === Number(pctId)) {
      updatedPctProductValues = updatedPctProductValues.filter(x => x.fieldCode !== fieldCode);
      updatedPctProductValues.push({
        fieldCode,
        value: newFieldValue?.toString(),
      });
    }

    return updatedPctProductValues.find(item => item.fieldCode === fieldCode)?.value === fieldValue;
  });
};

export { checkEveryPctValue };
