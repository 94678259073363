import { FC, ReactNode } from "react";
import { Typography } from "antd";

import styles from "./styles.module.scss";

interface SettingsOverviewSetcionProps {
  title: ReactNode;
  children: ReactNode;
}

export const SettingsOverviewSection: FC<SettingsOverviewSetcionProps> = ({ title, children }) => {
  return (
    <div className={styles["settings"]}>
      <Typography.Title level={3} className={styles["setting-title"]}>
        {title}
      </Typography.Title>

      {children}
    </div>
  );
};
