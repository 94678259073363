import { FC, useEffect, useState } from "react";
import { Button, Form, Input, Modal, Switch, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import InputMask from "react-input-mask";

import { PlusOutlined } from "@ant-design/icons";
import { User } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface UserEditProps {
  user: User | undefined;
  form: FormInstance;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const UserEdit: FC<UserEditProps> = ({ form, user }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        email: user.email,
        externalUUID: user.externalUUID,
        financialInstitutionId: user.financialInstitutionId,
        firstName: user.firstName,
        id: user.id,
        lastLogin: user.lastLogin,
        lastName: user.lastName,
        login: user.login,
        phone: user.phone,
        state: user.state,
      });
    }
  }, [form, user]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    return (
      setPreviewImage(file.url || (file.preview as string)),
      setPreviewOpen(true),
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1))
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  return (
    <div className={styles["user-settings-wrapper"]}>
      <Form.Item name="avatar" label="Avatar">
        <>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={() => handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </>
      </Form.Item>

      <Form.Item name="id" label="Financial Institution ID">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item
        name="firstName"
        label="First name"
        rules={[
          { max: 255, message: "First name must have maximum 255 characters." },
          { whitespace: true },
          {
            pattern: /^[^\t\r{}]*$/,
            message: "First name contains not permitted characters as: “TAB”, `{', '}` ”.",
          },
        ]}
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last name"
        rules={[
          { max: 255, message: "Last name must have maximum 255 characters." },
          { whitespace: true },
          {
            pattern: /^[^\t\r{}]*$/,
            message: "Last name contains not permitted characters as: “TAB”, `{', '}` ”.",
          },
        ]}
        required={true}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="login"
        label="Login"
        rules={[
          { max: 255, message: "Login must have maximum 255 characters." },
          { whitespace: true },
          {
            pattern: /^[^\t\r{}]*$/,
            message: "Login contains not permitted characters as: “TAB”, `{', '}` ”.",
          },
        ]}
        required={true}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          { max: 255, message: "Last name must have maximum 255 characters." },
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone number"
        rules={[
          {
            required: true,
            message: "Please input your phone number!",
          },
        ]}
      >
        <InputMask className={styles["phone-number-input"]} mask="+999 999 999 999 999" maskChar={null} />
      </Form.Item>

      {/* TODO refactor after new design
      <Form.Item
        name="userRoles"
        label="Role"
        rules={[
          {
            required: true,
            message: "Please add role",
          },
        ]}
      >
        <Select mode="multiple" style={{ width: "100%" }} optionLabelProp="label">
          {roleOptions.map(role => (
            <Select.Option key={role.value} value={role.value} label={role.label}>
              {role.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}

      <div className={styles["reset-password"]}>
        <Button className={styles["reset-btn"]} onClick={() => alert("Work in progress")}>
          Reset password
        </Button>
        <div className={styles["reset-description"]}>
          User will receive a link to reset a password to the e-mail address.
        </div>
      </div>

      <div className={styles["form-item"]}>
        <Form.Item valuePropName="checked" name="state">
          <Switch />
        </Form.Item>
        <div>Activate user state</div>
      </div>
    </div>
  );
};

export default UserEdit;
