import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect x="1" y="1" width="30" height="30" rx="15" fill="#FFEDEF" />
    <rect x="1" y="1" width="30" height="30" rx="15" stroke="#E64360" strokeWidth="2" />
    <path
      d="M10.167 14.332H14.3337"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.167 16.832H16.8337"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1663 24.3346C12.0073 24.3346 13.4997 22.8423 13.4997 21.0013C13.4997 19.1604 12.0073 17.668 10.1663 17.668C8.32539 17.668 6.83301 19.1604 6.83301 21.0013C6.83301 22.8423 8.32539 24.3346 10.1663 24.3346Z"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3747 19.9609V20.7359C10.3747 21.0276 10.2247 21.3026 9.96635 21.4526L9.33301 21.8359"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66699 18.6654V13.4987C7.66699 10.582 9.33366 9.33203 11.8337 9.33203H20.167C22.667 9.33203 24.3337 10.582 24.3337 13.4987V18.4987C24.3337 21.4154 22.667 22.6654 20.167 22.6654H13.0837"
      stroke="#E64360"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CreditRedIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
