import React, { FC, useEffect } from "react";
import { Form } from "antd";
import { useParams } from "react-router-dom";

import { useProductSettings } from "@ni/common/hooks";
import { periodRadioOptions } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, PageItemLayoutGroup } from "@ni/common/ui";
import { checkValueBetweenRangeValidator, getFormValueFromProductValues } from "@ni/common/utils";
import { Purge } from "@ni/tenant-portal/core";

import styles from "./styles.module.scss";

export const ContractPurge: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();

  const { currentProduct: product, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  useEffect(() => {
    if (!product?.id) {
      return;
    }

    form.setFieldsValue({
      "nic-acc-st-auto-purge-per-type":
        (getFormValueFromProductValues(product.productValues, "nic-acc-st-auto-purge-per-type") as string) || "",
      "nic-acc-st-auto-purge-per":
        (getFormValueFromProductValues(product.productValues, "nic-acc-st-auto-purge-per") as string) || "",
      "nic-card-st-auto-purge-per-type":
        (getFormValueFromProductValues(product.productValues, "nic-card-st-auto-purge-per-type") as string) || "",
      "nic-card-st-auto-purge-per":
        (getFormValueFromProductValues(product.productValues, "nic-card-st-auto-purge-per") as string) || "",
    });
  });

  const onSubmit = (values: FormValues) => {
    void onUpdateProduct(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Contract Purge"
      pageSubtitle={<Purge isAfterWizard={true} />}
      submitHandler={onSubmit}
      size="md"
      formSize="md"
      submitLabel="Save"
      gap={24}
    >
      <PageItemLayoutGroup>
        <div className="text-blue-h3">Pre-purge parameters</div>
        <NetworkForm.Radio
          formItemOptions={{
            name: "nic-acc-st-auto-purge-per-type",
            label: "Pre-purge period type (for accounts)",
            className: "input-group-label",
          }}
          radioList={periodRadioOptions}
          initialValue={undefined}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: "nic-acc-st-auto-purge-per",
            label: "Pre-purge period number (for accounts)",
            className: styles["product-details-form-input"],
            dependencies: ["nic-acc-st-auto-purge-per-type"],
            rules: [
              () => ({
                validator(_, value) {
                  return checkValueBetweenRangeValidator(
                    value,
                    form.getFieldValue("nic-acc-st-auto-purge-per-type") === "M"
                      ? "Number of months"
                      : "Number of days",
                    0,
                    form.getFieldValue("nic-acc-st-auto-purge-per-type") === "M" ? 12 : 365,
                  );
                },
              }),
            ],
            initialValue: 3,
          }}
        />

        <NetworkForm.Radio
          formItemOptions={{
            name: "nic-card-st-auto-purge-per-type",
            label: "Pre-purge period type (for cards)",
            className: "input-group-label",
          }}
          radioList={periodRadioOptions}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: "nic-card-st-auto-purge-per",
            label: "Pre-purge period number (for cards)",
            className: styles["product-details-form-input"],
            dependencies: ["nic-card-st-auto-purge-per-type"],
            rules: [
              () => ({
                validator(_, value) {
                  return checkValueBetweenRangeValidator(
                    value,
                    form.getFieldValue("nic-card-st-auto-purge-per-type") === "M"
                      ? "Number of months"
                      : "Number of days",
                    0,
                    form.getFieldValue("nic-card-st-auto-purge-per-type") === "M" ? 12 : 365,
                  );
                },
              }),
            ],
          }}
        />
      </PageItemLayoutGroup>
    </CustomFormWrapper>
  );
};
