import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const IconSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_31266_95071)">
      <path
        d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 6.41071 1.91607 4.95 2.84821 3.80714L12.1929 13.1518C11.05 14.0839 9.58929 14.6429 8 14.6429ZM13.1518 12.1929L3.80714 2.84821C4.95 1.91607 6.41071 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 9.58929 14.0839 11.05 13.1518 12.1929Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_31266_95071">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DiscardIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
