import React from "react";
import { ConfigProvider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { useReduxState } from "@ni/common/hooks";
import { WizardPage, WizardPlacement } from "@ni/common/types";
import { Phase } from "@ni/sdk/models";

import { Spin } from "../../Spin";
import { WizardStepsSidebar } from "../WizardStepsSidebar";

import styles from "./styles.module.scss";

interface WizardProcessProps {
  devMode?: boolean;
  wizardPlacementState: [WizardPlacement, React.Dispatch<WizardPlacement>];
  lastSubmittedStep: WizardPlacement;
  wizardPhases: Phase[];
  wizardPagesList: WizardPage;
}

export const WizardProcess = ({
  devMode = false,
  wizardPlacementState,
  lastSubmittedStep,
  wizardPagesList,
  wizardPhases,
}: WizardProcessProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading] = useReduxState<boolean>("isLoading");

  const [wizardPlacement, setWizardStep] = wizardPlacementState;

  const onChangeStep = (pageKey: string, phase: number) => {
    window.scrollTo(0, 0);

    setWizardStep({
      phase,
      pageKey,
    });

    const existingQueries = new URLSearchParams(location.search);
    existingQueries.set("page", pageKey);

    navigate({
      pathname: location.pathname,
      search: existingQueries.toString(),
    });
  };

  return (
    <ConfigProvider componentSize="middle">
      {isLoading && wizardPhases.length === 0 ? (
        <Spin />
      ) : (
        <div className={styles["wizard-main"]}>
          <WizardStepsSidebar
            devMode={devMode}
            wizardPlacement={wizardPlacement}
            lastSubmittedStep={lastSubmittedStep}
            current={Number(wizardPhases?.findIndex(phase => phase.order === wizardPlacement.phase))}
            phaseList={wizardPhases}
            onChangeStep={onChangeStep}
          />
          {wizardPagesList[wizardPlacement.pageKey]}
        </div>
      )}
    </ConfigProvider>
  );
};
