import { QpRowkey, QpRowkeyProduct } from "@ni/sdk/models";

export const getObjectByRowkey = <T extends QpRowkey | QpRowkeyProduct>(
  qpRowkeys: QpRowkey[],
  rowkey: string,
  isTenantObject: boolean = false,
): T | undefined => {
  const filteredRowkeys = qpRowkeys.filter((item: QpRowkey) => item.rowkey === rowkey?.split("-")[0]);

  if (filteredRowkeys.length === 0) return undefined;

  const productArray = [...(filteredRowkeys[0].products || [])];
  const object = isTenantObject
    ? filteredRowkeys[0]
    : productArray.find((product: QpRowkeyProduct) => product.rowkey === rowkey);

  if (!object || Object.keys(object).length === 0) return undefined;

  return object as T;
};
