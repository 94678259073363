import { FC, useEffect, useState } from "react";
import { Button, Form } from "antd";
import Search from "antd/lib/transfer/search";

import { useReduxState } from "@ni/common/hooks";
import { PageHeading, Spin } from "@ni/common/ui";
import { EventGroup } from "@ni/sdk/models";

import DraggableEventGroups from "../DraggableEventGroups";
import { EventGroupDrawer } from "../EventGroupDrawer";

import styles from "./styles.module.scss";

export interface TemplatesProps {
  smsTemplates: EventGroup[];
  getEventGroups: () => void;
}

export const Templates: FC<TemplatesProps> = ({ getEventGroups, smsTemplates }) => {
  const [isLoading] = useReduxState<boolean>("isSMSTemplateLoading", false);

  const [visible, setVisible] = useState<boolean>(false);
  const [maxOrderValue, setMaxOrderValue] = useState<number>(-1);

  useEffect(() => {
    const maxOrder = smsTemplates?.reduce((max, obj) => (max?.order > obj?.order ? max : obj));
    setMaxOrderValue(maxOrder?.order ?? 0);
  }, [smsTemplates]);

  const onOpenNewEventGroup = () => {
    setVisible(true);
  };

  const extra = (
    <div className={styles["extra-container"]} key={Math.random()}>
      <div className={styles["top-section"]}>
        <Button className={styles["new-button"]} type="primary" size="middle" onClick={onOpenNewEventGroup}>
          New event group
        </Button>
        <Form.Item name="searchValue">
          <Search disabled={true} placeholder="Search" />
        </Form.Item>
      </div>
    </div>
  );

  const closeDrawerHandler = () => {
    setVisible(false);
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <div className={styles["page-heading-wrapper"]}>
        <PageHeading text="SMS Templates" extraElements={[extra]} />
      </div>
      <div className={styles["content"]}>
        <DraggableEventGroups smsTemplates={smsTemplates} />
      </div>
      <EventGroupDrawer
        opened={visible}
        getEventGroups={getEventGroups}
        closeDrawer={closeDrawerHandler}
        maxOrderValue={maxOrderValue}
      />
    </>
  );
};
