import { FC, ReactNode, useState } from "react";
import { Space, Switch, Tooltip, TooltipProps } from "antd";
import { copyToClipboard } from "ni-ui-kit";

import { KQpKeyCircule, QpKey } from "@ni/common/assets";
import { useReduxState } from "@ni/common/hooks";

import styles from "./styles.module.scss";

interface ItemLabelProps {
  label?: ReactNode;
  code?: string;
  tooltipProps?: TooltipProps;
  children?: ReactNode;
}

const mappedCode: Record<string, string> = {
  countryCode: "nic-fi-country",
  defaultCurrency: "nic-fi-local-ccy",
  "balance-owner": "nic-prod-group, nic-prod-group-rep",
  "curr-setup": "nic-prod-group",
  "credit-type": "nic-tags-1-prod, nic-product-is-islamic",
  "displayName-pw": "nic-prod-ips, co-badged-name, nic-prod-core-name, nic-ccy",
};

const CircularKeyIcon: FC<{ onClick: () => Promise<void>; className: string }> = props => (
  <img src={KQpKeyCircule} alt="QpKeyCircule-Icon" {...props} />
);

const KeyIcon = () => <img src={QpKey} alt="QpKey-Icon" />;

export const LabelCodeToggle = () => {
  const [canShowCode, setCanShowCode] = useReduxState<boolean>("canShowCodeTooltip", false);

  return (
    <Switch
      checked={canShowCode}
      onChange={setCanShowCode}
      checkedChildren={<KeyIcon />}
      unCheckedChildren={<KeyIcon />}
    />
  );
};

export const FormItemLabel: FC<ItemLabelProps> = ({ label, code, tooltipProps = {}, children }) => {
  const [informativeString, setInformativeString] = useState("");
  const [canShowCode] = useReduxState<boolean>("canShowCodeTooltip");

  const handleCodeCopy = async () => {
    if (code) {
      try {
        await copyToClipboard(code?.trim());
        setInformativeString("copied!");
      } catch (error) {
        setInformativeString("try again!");
      } finally {
        setTimeout(() => {
          setInformativeString("");
        }, 1500);
      }
    }
  };

  return (
    <Space direction="horizontal" className={styles["ni-form-label-container"]}>
      {label}
      {canShowCode && code ? (
        <Tooltip
          title={`${mappedCode[code] ?? code} ${informativeString}`}
          {...tooltipProps}
          mouseLeaveDelay={0.2}
          trigger={children ? "click" : "hover"}
          color="red"
          overlayStyle={{ maxWidth: "800px" }}
        >
          {children || <CircularKeyIcon className={styles["form-item-code-icon"]} onClick={handleCodeCopy} />}
        </Tooltip>
      ) : (
        children
      )}
    </Space>
  );
};
