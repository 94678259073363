import { FC } from "react";
import { Form } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { useIPPWizardApi } from "../../hooks";

import { rolloverPlanOption } from "./constants";

export const InstallmentRolloverPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { processWizardRequest } = useIPPWizardApi();
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  useHydrateForm({
    form,
    entityFields: wizardResponse?.plan?.planValues ?? [],
    keys: {
      lists: [rolloverPlanOption.code],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: values as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="IPP Rollover"
      pageSubtitle="IPP Rollover occurs when an account becomes delinquent or is updated with specific block codes, based on preconfigured parameters. When a rollover takes place, the outstanding balance is transferred from the installment payment plan (IPP) to the regular retail balance, and it starts accumulating interest at the rate associated with the retail balance."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <NetworkForm.Select
        formItemOptions={{
          name: rolloverPlanOption.code,
          label: rolloverPlanOption.name,
          tooltip: rolloverPlanOption.tooltip,
          initialValue: rolloverPlanOption.default,
        }}
        optionList={rolloverPlanOption?.options?.map(({ displayed, value }) => ({ label: displayed, value })) ?? []}
      />
    </CustomFormWrapper>
  );
};
