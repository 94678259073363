import { useCallback, useEffect } from "react";

import { useReduxState, useTenant } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { InsuranceProgramApi } from "@ni/sdk/apis";
import { ChangeInsuranceProgramPctValueRequest, InsuranceProgram } from "@ni/sdk/models";

const insuranceApi = new InsuranceProgramApi();

export const useInsuranceProgramPctApi = (id?: number, productId?: number, tenantId?: number) => {
  const { tenant } = useTenant({ tenantId });

  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [currentInsuranceProgram, setCurrentInsuarnceProgram] =
    useReduxState<InsuranceProgram>("currentInsuranceProgramEdit");
  const [insurancePrograms, setInsuarncePrograms] = useReduxState<InsuranceProgram[]>("InsuranceProgramsEdit");

  const formValuesConverter = useCallback((formValues: FormValues, pctId: number) => {
    return Object.keys(formValues)?.map((key: string) => ({
      code: key,
      pctId,
      value: formValues[key],
    }));
  }, []);

  const editInsurance = useCallback(
    async (values: FormValues, pctId?: number, tableId?: number) => {
      if (currentInsuranceProgram?.id && tableId && pctId) {
        setIsLoading(true);
        try {
          const response = await insuranceApi.editInsuranceProgramPctValues(
            { programPctValues: formValuesConverter(values, pctId) } as ChangeInsuranceProgramPctValueRequest,
            currentInsuranceProgram?.id,
            tableId,
          );
          currentInsuranceProgram.pctValues = response.data;
          setCurrentInsuarnceProgram(currentInsuranceProgram);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    },
    [currentInsuranceProgram, formValuesConverter, setCurrentInsuarnceProgram, setIsLoading],
  );

  useEffect(() => {
    const getprogramById = async () => {
      try {
        const insurance = (await insuranceApi.getById2(id ?? 0)).data;
        const insurancePrograms = (await insuranceApi.getInsuranceProgramsByProductId(productId ?? 0)).data;
        setCurrentInsuarnceProgram(insurance);
        setInsuarncePrograms(insurancePrograms.filter(x => x.state === "ACTIVE"));
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    if (id && productId && (!currentInsuranceProgram || currentInsuranceProgram.id !== id)) {
      setIsLoading(true);
      void getprogramById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    currentInsuranceProgram,
    insurancePrograms,
    setInsuarncePrograms,
    editInsurance,
    currency: tenant?.insuranceProgramTemplates?.find(x => x.id === currentInsuranceProgram?.insuranceProgramTemplateId)
      ?.currency,
  };
};
