import { FC } from "react";
import { Button, Table, TableColumnType, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { SettingOutlined } from "@ant-design/icons";
import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { ShortLoyaltyProgram } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface LoyaltyProgramTemplatesTableProps {
  loyaltyPrograms?: ShortLoyaltyProgram[];
}

export const LoyaltyProgramTemplatesTable: FC<LoyaltyProgramTemplatesTableProps> = ({ loyaltyPrograms }) => {
  const navigate = useNavigate();
  const { id: tenantId, productId, pctId } = useParams<{ id: string; productId: string; pctId: string }>();

  const handleEditClick = (id: number) => {
    navigate(`/tenant/${tenantId}/product/${productId}/pct/${pctId}/loyalty-program/${id}/transaction-eligibility`);
  };

  const columns: TableColumnType<ShortLoyaltyProgram>[] = [
    {
      title: "Program Code",
      dataIndex: ["loyaltyCode"],
      key: "loyaltyCode",
      width: "12%",
      ellipsis: true,
      render: (_: string, item: ShortLoyaltyProgram) => item.loyaltyCode?.toUpperCase() || EMPTY_DATA_FLAG,
    },
    {
      title: "Program name",
      dataIndex: ["name"],
      key: "name",
      width: "35%",
      ellipsis: true,
      render: (_: string, item: ShortLoyaltyProgram) => item.name || EMPTY_DATA_FLAG,
    },
    {
      title: "Accrual type",
      dataIndex: ["type"],
      key: "type",
      width: "35%",
      ellipsis: true,
      render: (_: string, item: ShortLoyaltyProgram) => (item.type === "NORMAL" ? "Transaction based" : "Cumulative"),
    },
    {
      title: "",
      dataIndex: ["loyaltyProgramTemplateId"],
      key: "loyaltyProgramTemplateId",
      width: "8%",
      ellipsis: true,
      render: (_: string, { id }: ShortLoyaltyProgram) => (
        <Tooltip title="Edit">
          <Button
            type="text"
            htmlType="button"
            icon={<SettingOutlined />}
            className="text-primary"
            onClick={() => handleEditClick(id!)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <Table<ShortLoyaltyProgram>
      rowKey="id"
      className={styles["ni-loyalty-program-details"]}
      columns={columns}
      dataSource={loyaltyPrograms}
      loading={!loyaltyPrograms}
      pagination={false}
    />
  );
};
