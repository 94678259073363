import { useEffect, useState } from "react";

interface UseDebaunceArgs {
  value: string;
  delay: number;
}

export const useDebaunce = ({ value, delay }: UseDebaunceArgs) => {
  const [debaunceValue, setDebaunceValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebaunceValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debaunceValue;
};
