import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { colors } from "@ni/common/constants";

const IconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 14L11.2929 8.70711C11.6834 8.31658 12.3166 8.31658 12.7071 8.70711L18 14"
      stroke={colors.lightColor}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const ChevronUpIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={IconSvg} {...props} />;
