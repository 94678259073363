import { FC } from "react";
import { Form } from "antd";

import { LTY_EXP_ENABLE, LTY_EXP_MOVE, LTY_EXP_WO } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { isNumeric, minMaxValidator, removeGapsFromStringNumeric } from "@ni/common/utils";
import { LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

import { LTY_EXP_ENABLE_TOOLTIP, LTY_EXP_MOVE_TOOLTIP, LTY_EXP_WO_TOOLTIP } from "./constants";

export const PointExpiry: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const isAutoRedeem = Form.useWatch<boolean>(LTY_EXP_ENABLE, form);

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: { strings: [LTY_EXP_ENABLE, LTY_EXP_MOVE, LTY_EXP_WO] },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);

    const formValues: FormValues = Object.keys(values).reduce((newValues, fieldName) => {
      const value = values[fieldName];
      newValues[fieldName] = isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value;
      return newValues;
    }, {} as FormValues);

    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: formValues as {
          [key: string]: string;
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Point Expiry"
      pageSubtitle="Expiration period can be setup for accrued loyalty points. Points can not be redeemed for a value after expiration."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Switch
        formItemOptions={{
          name: LTY_EXP_ENABLE,
          label: "Enable points expiry",
          initialValue: true,
          tooltip: LTY_EXP_ENABLE_TOOLTIP,
        }}
        checked={isAutoRedeem}
      />

      {isAutoRedeem && (
        <>
          <NetworkForm.Number
            formItemOptions={{
              name: LTY_EXP_MOVE,
              label: "Points validity period (number of cycles after accrual)",
              tooltip: LTY_EXP_MOVE_TOOLTIP,
              initialValue: 36,
              rules: [
                { required: true, message: "Points validity period is required" },
                () => ({
                  validator(_, value: number) {
                    return minMaxValidator(value, 0, 999);
                  },
                }),
              ],
            }}
            controls={false}
            precision={0}
          />

          <NetworkForm.Number
            formItemOptions={{
              name: LTY_EXP_WO,
              label: "Points write-off period (number of cycles after accrual)",
              tooltip: LTY_EXP_WO_TOOLTIP,
              initialValue: 40,
              rules: [
                { required: true, message: "Points validity period is required" },
                () => ({
                  validator(_, value: number) {
                    return minMaxValidator(value, 0, 999);
                  },
                }),
              ],
            }}
            controls={false}
            precision={0}
          />
        </>
      )}
    </CustomFormWrapper>
  );
};
