import { FC, useEffect } from "react";
import { Form } from "antd";

import { useGetProductsToApply } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, ProductsTable } from "@ni/common/ui";

import { useInsuranceWizardApi } from "../../hooks/useInsuranceWizardApi";

import { productsOption } from "./constants";

export const InsuranceProductsApplyingPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { wizardResponse, processWizardRequest, appliedPrograms } = useInsuranceWizardApi();

  const { products } = useGetProductsToApply(wizardResponse.tenant?.id, "INSURANCE", {
    insurance_program_template_id: wizardResponse.insuranceTemplate?.id?.toString() ?? "0",
  });

  useEffect(() => {
    if (appliedPrograms?.length && formDisabled) {
      form.setFieldValue(
        productsOption.code,
        appliedPrograms.map(x => x.productId),
      );
    }
  }, [appliedPrograms, form, formDisabled]);

  const onFinish = (values: FormValues) => {
    void processWizardRequest(
      {
        pageId: wizardResponse?.pageId as number,
        tenantId: wizardResponse.tenant?.id,
        insuranceTemplateId: wizardResponse.insuranceTemplate?.id,
        collectedValues: values[productsOption.code]
          ? ({ [productsOption.code]: (values[productsOption.code] as number[])?.join(",") ?? "" } as {
              [key: string]: string;
            })
          : {},
      },
      true,
    );
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Insurance Applying to Products"
      pageSubtitle="Select the product to apply your template on, for program creation. You can modify created programs on each product independently after wizard. You can also customize some of the parameters for each pricing control table profile under your product for specific cardholder groups. Your template will be saved for future programs creations, modifications on existing template will not impact previously created programs settings"
      level="tenant"
      additionalRoute="insurance-program-templates"
      size="full"
      formSize="full"
      gap={40}
      submitHandler={onFinish}
      submitLabel="Continue"
    >
      <Form.Item name={productsOption.code}>
        <ProductsTable products={products} hasCheckAll={true} />
      </Form.Item>
    </CustomFormWrapper>
  );
};
