import { FC, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  useLoyaltyProgramApi,
  useLoyaltyTemplates,
  useProductLoyaltyPrograms,
  useProductSettings,
} from "@ni/common/hooks";
import { getRouteAfterProvidedRoute } from "@ni/common/utils";

import { Sidebar } from "./components/Sidebar";
import { getPctPagesRoutes, getProductPagesRoutes } from "./pages/route";
import { useProducts } from "./hooks";

import styles from "./styles.module.scss";

const EDIT_PROGRAM_ROUTE_KEY = "edit-program";

export const ProductSettings: FC = () => {
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const { pathname } = useLocation();

  const [products] = useProducts({ tenantId: parseInt(tenantId ?? "0", 10) });

  const programId = useMemo(() => getRouteAfterProvidedRoute(pathname, EDIT_PROGRAM_ROUTE_KEY), [pathname]);

  const { loyaltyPrograms } = useProductLoyaltyPrograms({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: true,
  });
  useLoyaltyTemplates({
    tenantId: parseInt(tenantId ?? "0", 10),
  });
  useLoyaltyProgramApi({
    loyaltyProgramId: parseInt(programId ?? "0", 10),
    isFetchEnabled: !!programId,
  });

  const { currentProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: true,
  });

  return (
    <Sidebar
      products={products}
      currentProduct={currentProduct}
      loyaltyPrograms={loyaltyPrograms}
      getProductPagesRoutes={getProductPagesRoutes}
      getPctPagesRoutes={getPctPagesRoutes}
      styles={styles}
    />
  );
};
