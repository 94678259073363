import { FC, useMemo } from "react";
import { Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { tawarruqScenariosColumns, tawarruqScenariosData } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CollapsedTable, CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";

import {
  salesFeeAmount,
  salesFeeBase,
  salesFeePercentage,
  tawarruqProfitAmount,
  TawarruqProfitBase,
  tawarruqProfitPercentage,
  tawarruqSalesFee,
} from "./constants";

export const TawarruqProfitPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const productCurrency = getFormValueFromProductValues(currentProduct?.productValues, "nic-ccy", "string");
  const fixedBaseValue = Form.useWatch<string>(TawarruqProfitBase.code, form)?.toString();
  const isSalesApplied = Form.useWatch<string>(tawarruqSalesFee.code, form)?.toString() === "true";
  const percentageFeeType = Form.useWatch<string>(salesFeeBase.code, form)?.toString();

  const relatedLinks = useMemo(
    () => [
      ...(currentProduct.parameterTables?.map(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/tawarruq-profit-calculation`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - Tawarruq Profit Calculation`,
      })) || []),
    ],
    [currentProduct.displayName, currentProduct.parameterTables, productId, tenantId],
  );

  useHydrateForm({
    form,
    entityFields: [
      { fieldCode: TawarruqProfitBase.code, value: TawarruqProfitBase.default as string },
      ...(currentProduct.productValues ?? []),
    ],
    keys: {
      strings: [
        TawarruqProfitBase.code,
        tawarruqProfitPercentage.code,
        tawarruqProfitAmount.code,
        tawarruqSalesFee.code,
        salesFeeBase.code,
        salesFeeAmount.code,
        salesFeePercentage.code,
      ],
    },
    allowParse: false,
  });

  const onFinish = onUpdateProduct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Tawarruq Profit Settings"
      pageSubtitle={
        <Space direction="vertical">
          <div>
            Tawarruq is a financial concept in Islamic Banking that involves a buy-and-sell arrangement to achieve a
            specific financial objective, while complying with Islamic principles that prohibit interest (usury) and
            promote ethical financial transactions. Tawarruq profit is a component of this concept and is calculated and
            charged in accordance with Islamic banking principles. The Tawarruq balance includes all billed amounts,
            fees, charges, and profits.
          </div>
          <div>
            Customers can make payments toward their accounts until the full payment date to avoid Tawarruq profit.
          </div>
        </Space>
      }
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Radio
        formItemOptions={{
          name: TawarruqProfitBase.code,
          label: TawarruqProfitBase.label,
          initialValue: TawarruqProfitBase.default,
        }}
        radioList={TawarruqProfitBase.options}
        initialValue={TawarruqProfitBase.default as string}
      />

      {fixedBaseValue !== "Amount" && (
        <NetworkForm.Number
          formItemOptions={{
            name: tawarruqProfitPercentage.code,
            label: tawarruqProfitPercentage.label,
            rules: [{ required: true, message: `${tawarruqProfitPercentage.label} is required!` }],
          }}
          {...tawarruqProfitPercentage.props}
        />
      )}

      {fixedBaseValue !== TawarruqProfitBase.default && (
        <NetworkForm.Number
          formItemOptions={{
            name: tawarruqProfitAmount.code,
            label: tawarruqProfitAmount.label,
            rules: [{ required: true, message: `${tawarruqProfitAmount.label} is required!` }],
          }}
          {...tawarruqProfitAmount.props}
          addonAfter={productCurrency}
        />
      )}

      <NetworkForm.Switch
        formItemOptions={{
          name: tawarruqSalesFee.code,
          valuePropName: "checked",
          label: tawarruqSalesFee.label,
          tooltip: tawarruqSalesFee.tooltip,
        }}
      />

      {isSalesApplied && (
        <NetworkForm.Radio
          formItemOptions={{
            name: salesFeeBase.code,
            label: salesFeeBase.label,
            initialValue: salesFeeBase.default,
            rules: [{ required: true, message: `${salesFeeBase.label} is required!` }],
          }}
          radioList={salesFeeBase.options}
          initialValue={salesFeeBase.default as string}
        />
      )}

      {percentageFeeType ? (
        percentageFeeType === "Percentage" ? (
          <NetworkForm.Number
            formItemOptions={{
              name: salesFeePercentage.code,
              label: salesFeePercentage.label,
              rules: [{ required: true, message: `${salesFeePercentage.label} is required!` }],
            }}
            {...salesFeePercentage.props}
            addonAfter="%"
          />
        ) : (
          <NetworkForm.Number
            formItemOptions={{
              name: salesFeeAmount.code,
              label: salesFeeAmount.label,
              rules: [{ required: true, message: `${salesFeeAmount.label} is required!` }],
            }}
            {...salesFeeAmount.props}
            addonAfter={productCurrency}
          />
        )
      ) : null}

      <CollapsedTable title="What if scenarios" tabsList={tawarruqScenariosData} columns={tawarruqScenariosColumns} />
    </CustomFormWrapper>
  );
};
