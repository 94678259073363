import { ReactNode } from "react";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const enableLatePaymentFeeOptions: Options = {
  code: "lpf-fee-enabled",
  name: "Enable late payment fee for your product",
  default: true,
};

export const latePaymentFeeAmountOptions: NumericOptions = {
  code: "nic-lpf-val-base",
  name: "Late payment fee amount",
  decimalsNumber: 2,
  min: 0,
  tooltip:
    "This parameter specifies the late payment fee value for charging the account when qualified payment is not received.",
};

export const latePaymentAmountVariance: NumericOptions = {
  code: "nic-lpf-min-base-bal",
  name: "Late payment amount variance",
  decimalsNumber: 2,
  min: 0,
  tooltip:
    "If statement balance is equal or less then amount specified by this parameter then the late payment fee will not be charged",
};
