import { useEffect, useState } from "react";
import { Button, Flex, Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { MCC_LIST } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant, TenantValue } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const groupNameCode = "grp-name-";
const groupCodesCode = "grp-codes-";

const tenantsServiceApi = new TenantApi();

export const CardControlPage = () => {
  const { id: tenantId } = useParams();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [form] = Form.useForm();
  const [groups, setGroups] = useState<{ id: number; isDeleted?: boolean }[]>([]);

  const addGroup = () => {
    setGroups([...groups, { id: groups.length }]);
    form.setFieldValue(`${groupCodesCode}${groups.length}`, []);
  };

  const deleteGroup = (deletedId: number) => {
    const deletedIndex = groups.findIndex(({ id }) => id === deletedId);
    const newList = [...groups];
    newList[deletedIndex].isDeleted = true;
    form.setFieldValue(`${groupCodesCode}${deletedId}`, "");
    form.setFieldValue(`${groupNameCode}${deletedId}`, "");
    setGroups(newList);
  };

  const onFinish = (values: FormValues) => {
    let indexPointer = 0;
    const valuesHolder: FormValues = {};
    setIsLoading(true);

    groups.forEach(group => {
      if (!group?.isDeleted) {
        valuesHolder[`${groupCodesCode}${indexPointer}`] = (values[`${groupCodesCode}${group.id}`] as string[])?.join(
          ",",
        );
        valuesHolder[`${groupNameCode}${indexPointer}`] = values[`${groupNameCode}${group.id}`] as string;
        indexPointer += 1;
      }
      values[`${groupCodesCode}${group.id}`] = "";
      values[`${groupNameCode}${group.id}`] = "";
    });

    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys({ ...values, ...valuesHolder }).map((val: string) => {
        return {
          fieldCode: val,
          value: valuesHolder[val] ?? values[val],
        };
      }) as TenantValue[],
    };

    tenantsServiceApi
      .editTenant(requestBody, parseInt(tenantId ?? "0", 10))
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (tenant?.tenantValues) {
      let groupCodesList: { id: number }[] = [];
      const tenantValues = tenant.tenantValues?.reduce((previous, value) => {
        if (value?.fieldCode) {
          if (value?.fieldCode.includes(groupCodesCode)) {
            const groupIndex = Number(value.fieldCode.charAt(value.fieldCode.length - 1));

            if (!Number.isNaN(groupIndex)) {
              groupCodesList[groupIndex] = { id: groupIndex };
              previous.set(value.fieldCode, value?.value?.split(","));
            }
          } else previous.set(value.fieldCode, value.value);
        }
        return previous;
      }, new Map<string, unknown>());

      groupCodesList = groupCodesList.filter(item => tenantValues.get(`${groupNameCode}${item.id}`));

      setGroups(groupCodesList);
      const formValues = form.getFieldsValue() as FormValues;
      groupCodesList.forEach(({ id }) => {
        formValues[`${groupNameCode}${id}`] = "";
        formValues[`${groupCodesCode}${id}`] = null;
      });
      Object.keys(formValues).forEach(key => {
        formValues[key] = tenantValues?.get(key) as string | string[];
      });
      form.setFieldsValue(formValues);
    }
  }, [form, tenant.tenantValues]);

  return (
    <CustomFormWrapper
      pageTitle="Card Control Configuration"
      pageSubtitle="The Card Control Configuration page enables customers to effectively manage MCC (Merchant Category Code) groups at the tenant level. Through this functionality, clients can define and configure the merchant categories that are permitted or restricted for transactions. Customers have the ability to view, modify, or add new MCC groups and codes as needed, providing them with precise control over card transaction policies. This ensures that businesses can tailor their card usage settings to meet specific operational, security, and compliance requirements."
      form={form}
      submitHandler={onFinish}
      formSize="sm"
      size="full"
      gap={40}
      submitLabel="Save"
      relatedLinks={[
        {
          label: "Additional services details (Card control and Falcon)",
          href: `/tenant/${tenantId}/additional-services-details`,
        },
      ]}
    >
      <Space direction="vertical">
        <NetworkForm.String formItemOptions={{ name: "ccs-repo_name", label: "Repository Name" }} />
        <NetworkForm.String formItemOptions={{ name: "ccs-apikeys", label: "Card Control API Key " }} />
        <NetworkForm.String formItemOptions={{ name: "ccs-mobile-apikeys", label: "Card Control Mobile API Key" }} />
      </Space>

      <Space direction="vertical">
        <div className="text-blue-h3">
          <TooltipInfo
            label="Marchant Categories Groups Codes"
            tooltipProps={{
              title:
                "Create Groups with the MCC codes you need to control only. Use Comma as a separator when adding multiple MCC codes . Try to Narrow your MCC groups as much as possible to have more precise control on it.",
            }}
          />
        </div>

        {groups
          .filter(item => !item?.isDeleted)
          .map(group => (
            <Flex key={group.id} justify="space-between" className={styles["mcc-card"]}>
              <Space direction="vertical">
                <NetworkForm.String
                  formItemOptions={{
                    name: `${groupNameCode}${group.id}`,
                    label: "Group Name",
                    rules: [
                      { required: true, message: "Group name can't be empty" },
                      { pattern: /^((?!(?<!\.)\s+).)*$/, message: "Group name can't include spaces" },
                    ],
                  }}
                  className="w-p-75"
                />
                <NetworkForm.Select
                  formItemOptions={{
                    name: `${groupCodesCode}${group.id}`,
                    label: "MCC",
                    initialValue: [],
                    className: "w-p-75",
                  }}
                  options={MCC_LIST.map(x => ({ label: x.value?.toString(), value: x.key?.toString() }))}
                  showSearch={true}
                  mode="multiple"
                  allowClear={true}
                />
              </Space>
              <Button
                type="text"
                danger={true}
                onClick={() => {
                  deleteGroup(group.id);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          ))}

        <Button type="link" icon={<PlusOutlined />} onClick={addGroup}>
          Add group
        </Button>
      </Space>
    </CustomFormWrapper>
  );
};
