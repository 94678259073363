import { FC } from "react";
import { Form, Radio, Space } from "antd";
import { useWatch } from "rc-field-form";

import { useFormButtonDisabled, useHydrateForm, useReduxState, useToggleFeature } from "@ni/common/hooks";
import { balanceOwners } from "@ni/common/mocks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, RadioGroup, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { getCurrencySetupList, getProductTypeCMSList, interestTypeList, productTypeCBSList } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ProductTypeSelectionPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const balanceMaintenance = useWatch("balance-owner", form);
  const productType = useWatch("product-type", form);

  const { isDisabled: isCreditDisabled, isInitiated } = useToggleFeature("CREDIT");
  const { isDisabled: isMultiDisabled } = useToggleFeature("PP_MULT");

  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form });

  const balanceOwnerList = balanceOwners;

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: ["product-type", "balance-owner", "curr-setup", "credit-type"],
    },
  });

  const resetBalanceMaintenanceHandler = () => {
    form.setFieldsValue({
      "product-type": undefined,
    });

    form.setFieldsValue({
      "curr-setup": undefined,
    });
  };

  const resetProductTypeHandler = () => {
    form.setFieldsValue({
      "curr-setup": undefined,
    });
  };

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        "nic-card-carr-address-lev-suppl":
          wizardResponse.tenant?.countryCode === "SA" && form.getFieldValue("product-type") === "Prepaid" ? "A" : "",
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled}
      pageTitle="Product Type Selection"
      size="md"
      formSize="md"
      gap={32}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Form.Item
        name="balance-owner"
        label={<FormItemLabel label="Where is the balance maintained?" code="balance-owner" />}
        className="wizard-input input-group-label"
        rules={[{ required: true, message: "Balance owner is required!" }]}
      >
        <Radio.Group onChange={resetBalanceMaintenanceHandler}>
          <Space direction="vertical">
            {balanceOwnerList.map(entry => (
              <Radio value={entry.value} key={entry.value}>
                <div className="flex flex-align-center">
                  <TooltipInfo label={entry.label} code={entry?.value} tooltipProps={{ title: entry.tooltip }} />
                  {entry.extra}
                </div>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>

      {balanceMaintenance === "CBS" && (
        <Form.Item
          name="product-type"
          label={
            <FormItemLabel label="What product type do you want the consumer product to be?" code="product-type" />
          }
          className="wizard-input input-group-label"
          rules={[{ required: true, message: "Card Type is required!" }]}
        >
          <Radio.Group onChange={resetProductTypeHandler}>
            <Space direction="vertical">
              {productTypeCBSList.map(entry => (
                <Radio value={entry.value} key={entry.value}>
                  <FormItemLabel label={entry?.label} code={entry?.value} />
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      )}

      {balanceMaintenance === "CMS" && isInitiated && (
        <Form.Item
          name="product-type"
          label={
            <FormItemLabel label="What product type do you want the consumer product to be?" code="product-type" />
          }
          className="wizard-input input-group-label"
          rules={[{ required: true, message: "Card Type is required!" }]}
        >
          <Radio.Group onChange={resetProductTypeHandler}>
            <Space className="wizard-input-block" direction="vertical">
              {getProductTypeCMSList(isCreditDisabled).map(entry => (
                <Radio value={entry.value} key={entry.value} disabled={formDisabled || entry?.disabled}>
                  <div className="flex flex-align-center">
                    <span className={entry.value === "Credit" && !formDisabled ? "ant-menu-submenu-selected" : ""}>
                      <FormItemLabel label={entry?.label} code={entry?.value} />
                    </span>
                    {entry?.isExist && <div className="alert-div center small m-l-16">Coming soon</div>}
                  </div>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      )}

      {isInitiated && balanceMaintenance === "CMS" && productType === "Prepaid" && (
        <Form.Item
          name="curr-setup"
          label={<FormItemLabel label="What currency setup do you want?" code="curr-setup" />}
          className="wizard-input input-group-label"
          rules={[{ required: true, message: "Currency setup is required!" }]}
          initialValue={isMultiDisabled ? "single" : ""}
        >
          <Radio.Group>
            <Space direction="vertical">
              {getCurrencySetupList(isMultiDisabled).map(entry => (
                <Radio value={entry.value} key={entry.value} disabled={formDisabled || entry?.disabled}>
                  <div className="flex flex-align-center">
                    <span className={entry.value === "mult" && !formDisabled ? "ant-menu-submenu-selected" : ""}>
                      <FormItemLabel label={entry?.label} code={entry?.value} />
                    </span>
                    {entry?.isExist && <div className="alert-div center small m-l-16">Coming soon</div>}
                  </div>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      )}

      {isInitiated && productType === "Credit" && (
        <Form.Item
          name="credit-type"
          label={<FormItemLabel label="Interest type" code="credit-type" />}
          className="wizard-input input-group-label"
          rules={[{ required: true, message: "Interest type is required!" }]}
          initialValue="Conventional"
        >
          <RadioGroup
            gap={8}
            radioList={interestTypeList}
            initialValue={interestTypeList[0].value}
            disabled={formDisabled}
          />
        </Form.Item>
      )}
    </CustomFormWrapper>
  );
};
