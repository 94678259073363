import { ReactNode } from "react";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumericOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const enableOverlimitFee: Options = {
  code: "ovl-fee-enable",
  name: "Enable overlimit fee for your product",
  default: true,
};

export const OverlimitFeeOptions: NumericOptions = {
  code: "nic-ovl-fee-val-base",
  name: "Overlimit fee value",
  decimalsNumber: 2,
  min: 0,
};

export const OverlimitTolerancePercentageOptions: NumericOptions = {
  code: "nic-ovl-fee-variance",
  name: "Overlimit tolerance (as a percentage of allowed account/card limit)",
  default: 0,
  decimalsNumber: 2,
  min: 0,
  tooltip: "This percentage will specify the maximum amount an account can go overlimit and not be charged",
};
