import { FC } from "react";
import { Form } from "antd";

import { CHECKBOX_GROUP_ELIGIBILITY_CRITERIA, LTY_RULE_TERM_LIST, LTY_RULES_CRITERIA } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { LoyaltyWizardRequest, LoyaltyWizardResponse } from "@ni/sdk/models";

import { useFieldMandatory, useLoyaltyWizardApi } from "../../hooks";

export const TransactionMerchantTerminal: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const isIDSFilled = Form.useWatch<string>(LTY_RULE_TERM_LIST, form);
  const isIDSMandatory = useFieldMandatory({
    values: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    checkAgainst: LTY_RULES_CRITERIA,
    keyToInclude: CHECKBOX_GROUP_ELIGIBILITY_CRITERIA[3].value,
  });

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programPctValues ?? [],
    keys: {
      lists: [LTY_RULE_TERM_LIST],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: {
          ...values,
          [LTY_RULE_TERM_LIST]: (values[LTY_RULE_TERM_LIST] as string[]).join(","),
        } as LoyaltyWizardRequest["collectedValues"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={!isIDSFilled}
      pageTitle="Transaction Merchant Terminal"
      pageSubtitle="Points can be earned for transactions made at specific merchant terminals. Please specify which merchant terminals are included to determine your loyalty eligibility."
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      <NetworkForm.Select
        formItemOptions={{
          name: LTY_RULE_TERM_LIST,
          label: "Specify merchant terminal ID list",
          rules: [
            {
              required: isIDSMandatory,
              validator: (_, value: string[]) => {
                if (!isIDSMandatory || (value && value.length > 0)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Please fill out Id list."));
              },
            },
          ],
        }}
        mode="tags"
        optionList={[]} // Add your options here if needed
      />
    </CustomFormWrapper>
  );
};
