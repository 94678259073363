import { NavbarItem, RoutesTreeItem } from "@ni/common/types";

export const mapRoutesTree = (routes: RoutesTreeItem[]): NavbarItem[] =>
  routes
    .filter(route => !route?.guard)
    .map(({ name, route, children, dependency, dependencyType }) => ({
      name,
      route,
      nestedItems: children ? mapRoutesTree(children) : undefined,
      dependency,
      dependencyType,
    }));
