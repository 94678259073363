import { FC, useState } from "react";
import type { MenuProps } from "antd";
import { Button, ConfigProvider, Dropdown, Form, Select, Space, Spin } from "antd";
import Search from "antd/lib/transfer/search";

import { DownOutlined } from "@ant-design/icons";
import { DataCaptureElementIcon, VisualElementIcon } from "@ni/common/icons";
import { elementTypeListOptions, mockElementGroupOptions, mockStateOptions } from "@ni/common/mocks";
import { WorkflowFilters } from "@ni/common/types";
import { PageHeading } from "@ni/common/ui";
import { ParameterTable } from "@ni/sdk/models";

import ElementTemplateDrawer from "../ElementTemplateDetails/ElementTemplateDrawer";
import ElementTemplatesTable from "../ElementTemplatesTable";

import "./styles.module.scss";

const { Option } = Select;

const ElementTemplatesLayout: FC = () => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<WorkflowFilters>({});
  const [isLoading] = useState<boolean>(false);
  const [newElementTemplate, setNewElementTemplate] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [elementType, setElementType] = useState<boolean>(false);
  const [selectedElementTemplate] = useState<ParameterTable>({});

  const onFormChange = (currentValue: { [key: string]: string }, value: { [key: string]: string }) => {
    const filter: WorkflowFilters = Object.keys(value).reduce((acc, key) => {
      if (key === "searchValue" && value[key]) {
        return { ...acc, search: value[key] };
      }
      if (value[key] && value[key] !== "all") {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: value[key],
          },
        };
      }
      if (value[key] && value[key] === "all") {
        return {
          ...acc,
          filter: {
            ...acc.filter,
            [key]: "",
          },
        };
      }

      return acc;
    }, {} as WorkflowFilters);
    setFilters(filter);
  };

  const onCreateBtn = (type: string) => {
    setNewElementTemplate(true);
    setVisible(true);
    setElementType(type === "data-capture");
  };

  const items: MenuProps["items"] = [
    {
      label: "Data capture element",
      key: "data-capture",
      icon: <DataCaptureElementIcon />,
    },
    {
      label: "Visual element",
      key: "visual-element",
      icon: <VisualElementIcon />,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = e => {
    if (e.key === "data-capture") {
      onCreateBtn("data-capture");
    } else {
      onCreateBtn("visual");
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const extra = (
    <div className="extra" key="extra">
      <Dropdown menu={menuProps}>
        <Button className="new-element-template-button" type="primary" htmlType="button" loading={isLoading}>
          <Space>
            New element
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Form.Item name="searchValue">
        <Search placeholder="Search by Code" />
      </Form.Item>
    </div>
  );

  const closeDrawerHandler = () => {
    setVisible(false);
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <ConfigProvider componentSize="middle">
        <Form form={form} className="element-template-layout" onValuesChange={onFormChange}>
          <PageHeading text="Element Templates" extraElements={[extra]} />
          <div className="filters-bar">
            <Form.Item name="elementGroup">
              <Select placeholder="Element group">
                {mockElementGroupOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="elementType">
              <Select placeholder="Element type">
                {elementTypeListOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="featureName">
              <Select placeholder="Feature name">
                {elementTypeListOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="currentState">
              <Select placeholder="Current state">
                {mockStateOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <ElementTemplatesTable filters={filters} />
        </Form>
      </ConfigProvider>
      <ElementTemplateDrawer
        opened={visible}
        closeDrawer={closeDrawerHandler}
        elementTemplate={selectedElementTemplate}
        newElementTemplate={newElementTemplate}
        isElementTypeDataCapture={elementType}
      />
    </>
  );
};

export default ElementTemplatesLayout;
