import { FC, useEffect, useState } from "react";
import { Form } from "antd";

import { LTY_REDEEM_TYPE, NIC_LTY_RED_RATE_CASH_RATE, NIC_LTY_RED_RATE_EXT_RATE } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { isNumeric, removeGapsFromStringNumeric } from "@ni/common/utils";
import { LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

import { NIC_LTY_RED_RATE_CASH_RATE_TOOPTIP, NIC_LTY_RED_RATE_EXT_RATE_TOOPTIP } from "./constants";

export const RedemptionRate: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const { processWizardRequest } = useLoyaltyWizardApi();

  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  const [redeemType, setRedeemType] = useState<string>();

  useHydrateForm({
    form,
    entityFields: wizardResponse?.loyaltyProgramTemplate?.programValues ?? [],
    keys: { strings: [NIC_LTY_RED_RATE_CASH_RATE, NIC_LTY_RED_RATE_EXT_RATE] },
  });

  useEffect(() => {
    if (wizardResponse.loyaltyProgramTemplate) {
      const redeemTypeValue = wizardResponse.loyaltyProgramTemplate.programValues?.find(
        field => field?.code === LTY_REDEEM_TYPE,
      )?.value;

      setRedeemType(redeemTypeValue);
    }
  }, [wizardResponse.loyaltyProgramTemplate]);

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      const formValues: FormValues = Object.keys(values).reduce((newValues, fieldName) => {
        const value = values[fieldName];
        newValues[fieldName] = isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value;
        return newValues;
      }, {} as FormValues);

      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        loyaltyTemplateId: wizardResponse.loyaltyProgramTemplate?.id,
        collectedValues: formValues as {
          [key: string]: string;
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Redemption Rate"
      pageSubtitle={
        redeemType === "C"
          ? "Redemption rate is a certain percentage of accrued loyalty points, that is redeemed for a money back to a customer"
          : "Redemption rate is a certain percentage of accrued loyalty points, that is redeemed for a voucher amount."
      }
      size="sm"
      level="tenant"
      submitHandler={onFinish}
      additionalRoute="loyalty-program-templates"
    >
      {redeemType !== "E" && (
        <NetworkForm.Number
          formItemOptions={{
            name: NIC_LTY_RED_RATE_CASH_RATE,
            label: "Cashback redemption rate",
            initialValue: 100,
            tooltip: NIC_LTY_RED_RATE_CASH_RATE_TOOPTIP,
            rules: [{ required: redeemType === "C" }],
          }}
          min={0}
          precision={2}
          addonAfter="%"
          stringMode={true}
        />
      )}
      {redeemType !== "C" && (
        <NetworkForm.Number
          formItemOptions={{
            name: NIC_LTY_RED_RATE_EXT_RATE,
            label: "External voucher redemption rate",
            initialValue: 100,
            tooltip: NIC_LTY_RED_RATE_EXT_RATE_TOOPTIP,
            rules: [{ required: redeemType === "E" }],
            required: redeemType === "E",
          }}
          min={0}
          precision={2}
          addonAfter="%"
          stringMode={true}
        />
      )}
    </CustomFormWrapper>
  );
};
