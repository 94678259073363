import { ReactNode } from "react";
import { Space } from "antd";

interface Options {
  name: string;
  code: string;
  default?: boolean | string | number;
  tooltip?: ReactNode;
}

interface NumaricOptions extends Options {
  decimalsNumber?: number;
  min?: string | number;
  max?: string | number;
}

export const accountCashLimitOptions: NumaricOptions = {
  code: "nic-cash-limit-acc-rate",
  name: "Cash limit (as a percentage of allowed account limit)",
  decimalsNumber: 2,
  min: 0,
  max: 100,
};

export const cardCashLimitOptions: NumaricOptions = {
  code: "nic-cash-limit-card-rate",
  name: "Cash limit (as a percentage of allowed card limit) for supplementary card",
  decimalsNumber: 2,
  min: 0,
  max: 100,
};

export const shadowLimitOptions: NumaricOptions = {
  code: "nic-ovl-auth-var",
  name: "Shadow limit (as a percentage of allowed account limit)",
  decimalsNumber: 2,
  min: 0,
  max: 100,
  tooltip: (
    <Space direction="vertical">
      <div>
        System provides the feasibility of giving certain percentage of extra credit limit on top of credit limit called
        as shadow limit.
      </div>
      <div>It is possible to charge overlimit fee in case of transactions amount exceeds credit limit.</div>
      <div>
        E.g. Credit limit is 1000 AED and shadow limit is 5%, then customer is allowed to do authorization of 1050 AED
        amount.
      </div>
    </Space>
  ),
};
