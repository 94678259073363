import { FC } from "react";
import { Form, Space, Typography } from "antd";
import { Rule } from "antd/es/form";
import { FormInstance } from "rc-field-form";

import { QuestionCircleFilled } from "@ant-design/icons";
import {
  INQ_ALLOW_DOM,
  INQ_ALLOW_ONUS,
  NIC_BAL_INQ_FEE_DOM_MAX_NR,
  NIC_BAL_INQ_FEE_DOM_VAL,
  NIC_BAL_INQ_FEE_ONUS_MAX_NR,
  NIC_BAL_INQ_FEE_OTHER_ONUS_VAL,
} from "@ni/common/constants";
import { NetworkForm, PageItemLayoutGeneral, TooltipInfo } from "@ni/common/ui";

import styles from "../../styles.module.scss";

interface CustomizedInquiryFeesProps {
  isEnabled: boolean;
  productCurrency: string;
  form: FormInstance;
  isIntraregionalInqueryEnabled: boolean;
}

export const CustomizedInquiryFees: FC<CustomizedInquiryFeesProps> = ({
  isEnabled,
  productCurrency,
  form,
  isIntraregionalInqueryEnabled,
}) => {
  const inqAllowOnus = Form.useWatch<string>(INQ_ALLOW_ONUS, form);
  const inqAllowDom = Form.useWatch<string>(INQ_ALLOW_DOM, form);
  const inqAllowIntra = Form.useWatch<string>("inq-allow-intrareg", form);

  const handleIntraregionalFlag = (value: boolean) => {
    if (!isIntraregionalInqueryEnabled && value) form.setFieldValue("inq-allow-intrareg", false);
  };

  return (
    <div className={styles["customized-inquiry-fees"]}>
      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <NetworkForm.Switch
          formItemOptions={{
            name: INQ_ALLOW_ONUS,
            label: <Typography.Text strong={true}>Apply custom fee on OnUs balance inquires</Typography.Text>,
            tooltip:
              "Enable this if you want to specify different fee for balance inquiries through your acceptance network (ATM, POS) other than Default fee setting",
            valuePropName: "checked",
          }}
        />

        <Form.Item dependencies={[INQ_ALLOW_ONUS]}>
          {() => {
            return (
              <NetworkForm.Number
                formItemOptions={{
                  name: NIC_BAL_INQ_FEE_OTHER_ONUS_VAL,
                  label: "OnUs balance inquiry fee amount",
                  rules: [
                    ...(inqAllowOnus
                      ? [
                          {
                            validator: (_: Rule, value: number) => (value === 0 ? Promise.reject() : Promise.resolve()),
                            message: "Minimum fee amount can not be 0",
                          },
                          {
                            required: true,
                            message: "OnUs balance inquiry fee amount is required",
                          },
                        ]
                      : []),
                  ],
                  initialValue: 0,
                }}
                precision={2}
                addonAfter={productCurrency}
                disabled={!isEnabled || !inqAllowOnus}
              />
            );
          }}
        </Form.Item>

        <Form.Item dependencies={[INQ_ALLOW_ONUS]}>
          {() => {
            return (
              <NetworkForm.Number
                formItemOptions={{
                  name: NIC_BAL_INQ_FEE_ONUS_MAX_NR,
                  label: "Free OnUs balance inquiries per billing cycle",
                  tooltip: "Specify number of free of charge balance inquiries per statement cycle",
                  initialValue: 0,
                }}
                min={0}
                precision={0}
                step={1}
                disabled={!isEnabled || !inqAllowOnus}
              />
            );
          }}
        </Form.Item>

        {inqAllowOnus && (
          <div className="alert-div">
            Please reach out to your Network International representative for providing your acquiring process details
            to segregate OnUs transactions.
          </div>
        )}
      </PageItemLayoutGeneral>

      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <NetworkForm.Switch
          formItemOptions={{
            name: INQ_ALLOW_DOM,
            label: <Typography.Text strong={true}>Apply custom fee on domestic balance inquiries</Typography.Text>,
            tooltip:
              "Enable this if you want to specify different fee for domestic balance inquiry transactions other than the Default fee setting",
            valuePropName: "checked",
          }}
        />

        <Form.Item dependencies={[INQ_ALLOW_DOM]}>
          {() => {
            return (
              <NetworkForm.Number
                formItemOptions={{
                  name: NIC_BAL_INQ_FEE_DOM_VAL,
                  label: "Domestic balance inquiry fee amount",
                  rules: [
                    {
                      validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                      message: "Minimum fee amount can not be 0",
                    },
                    {
                      required: (form.getFieldValue(INQ_ALLOW_DOM) as boolean) || false,
                      message: "Domestic balance inquiry fee amount is required",
                    },
                  ],
                }}
                precision={2}
                addonAfter={productCurrency}
                disabled={!isEnabled || !inqAllowDom}
              />
            );
          }}
        </Form.Item>

        <Form.Item dependencies={[INQ_ALLOW_DOM]}>
          {() => {
            return (
              <NetworkForm.Number
                formItemOptions={{
                  name: NIC_BAL_INQ_FEE_DOM_MAX_NR,
                  label: "Free domestic balance inquiries per cycle",
                  tooltip: "Specify number of free of charge balance inquiries per statement cycle",
                }}
                min={0}
                precision={0}
                step={1}
                disabled={!isEnabled || !inqAllowDom}
              />
            );
          }}
        </Form.Item>
      </PageItemLayoutGeneral>

      <PageItemLayoutGeneral className={styles["section__wrapper--gray"]}>
        <Form.Item>
          <Space direction="horizontal">
            <Form.Item
              name="inq-allow-intrareg"
              valuePropName="checked"
              rules={[
                {
                  type: "boolean",
                  validator: (_, value) => {
                    if (value && !isIntraregionalInqueryEnabled)
                      return Promise.reject(
                        new Error(
                          "Please enable custom intraregional balance inquiry fees on Tenant level fees first, to enable this feature",
                        ),
                      );
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <NetworkForm.Switch
                customLabel={
                  <Typography.Text strong={true}>
                    <TooltipInfo
                      label="Apply custom fee on intraregional balance inquires"
                      tooltipProps={{
                        title:
                          "Enable this if you want to specify different fee for intraregional balance inquiry transactions other than the Default fee setting",
                      }}
                    />
                  </Typography.Text>
                }
                onChange={handleIntraregionalFlag}
              />
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item dependencies={["inq-allow-intrareg"]}>
          {() => {
            return (
              <Form.Item
                name="nic-bal-inq-fee-intrareg-val"
                rules={[
                  {
                    validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                    message: "Minimum fee amount can not be 0",
                  },
                  {
                    required: (form.getFieldValue("inq-allow-intrareg") as boolean) || false,
                    message: "Intraregional balance inquiry fee amount is required",
                  },
                ]}
                label="Intraregional balance inquiry fee amount"
              >
                <NetworkForm.Number
                  precision={2}
                  addonAfter={productCurrency}
                  disabled={!isEnabled || !inqAllowIntra}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item dependencies={["inq-allow-intrareg"]}>
          {() => {
            return (
              <Form.Item
                name="nic-bal-inq-fee-intrareg-max-nr"
                label="Free intraregional balance inquiries per cycle"
                tooltip={{
                  title: "Specify number of free of charge balance inquiries per statement cycle",
                  icon: <QuestionCircleFilled />,
                }}
              >
                <NetworkForm.Number disabled={!isEnabled || !inqAllowIntra} min={0} precision={0} step={1} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </PageItemLayoutGeneral>
    </div>
  );
};
