import { MouseEventHandler } from "react";
import { Button, Divider, Form, Modal, Select, Upload } from "antd";

import { CloudUploadOutlined } from "@ant-design/icons";
import { uploadFilesProps } from "@ni/common/mocks";
import { SelectOption } from "@ni/common/types";

import styles from "./styles.module.scss";

const { Dragger } = Upload;

export interface ImportModalProps {
  isOpened: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: MouseEventHandler<HTMLElement>;
  title: string;
  description: string;
  branchOptions: SelectOption[];
}

export const ImportModal = ({
  isOpened,
  isLoading,
  onCancel,
  onConfirm,
  title,
  description,
  branchOptions,
}: ImportModalProps) => {
  const [form] = Form.useForm();

  return (
    <Modal
      className={styles["modal-import"]}
      title={title}
      open={isOpened}
      width="800px"
      onCancel={onCancel}
      footer={[
        <div className={styles["modal-import-footer"]}>
          <Button className={styles["modal-import-button"]} key="back" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className={styles["modal-import-button"]}
            disabled={false}
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={form.submit}
          >
            Confirm
          </Button>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onConfirm}>
        <Form.Item>{description}</Form.Item>
        <Form.Item>
          <div>Upload file</div>
          <Dragger {...uploadFilesProps}>
            <div className="dragger-title">
              <CloudUploadOutlined className={styles["button"]} />
              <p className={styles["ant-upload-text"]}>Drag & Drop your files here or Browse</p>
            </div>
            <p className={styles["ant-upload-hint"]}>Max size 10MB</p>
          </Dragger>
        </Form.Item>
        <Divider>or</Divider>

        <Form.Item
          name="newDisplayName"
          label="Import from VCS"
          rules={[{ type: "string", max: 256, message: "Cannot be empty. Max length is 256 letters" }]}
        >
          <Select placeholder="Select a branch">
            {branchOptions.map(option => (
              <Select.Option key={option.value + Math.random().toString()} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
