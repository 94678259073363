import { FC } from "react";
import { Button, Typography } from "antd";
import cn from "classnames";

import { templatesStart } from "@ni/common/assets";
import { PageHeading } from "@ni/common/ui";

import styles from "./styles.module.scss";

export const EmptyTemplates: FC = () => {
  const extra = (
    <div className={cn(styles["extra"], styles["info"])} key="extra">
      <Button type="primary" size="large">
        Create new group
      </Button>
    </div>
  );

  return (
    <>
      <PageHeading text="SMS Templates" extraElements={[extra]} />
      <div className={cn(styles["empty-container"], "m-b-40")}>
        <img src={templatesStart} alt="start" />
        <div className={styles["info-container"]}>
          <div className={cn(styles["info"], "m-b-40")}>
            <Typography.Title level={2}>
              There are no SMS templates yet. Let’s import or create a new one.
            </Typography.Title>
          </div>
        </div>
      </div>
    </>
  );
};
