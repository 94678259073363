import { FC, useEffect, useMemo } from "react";
import { Button, Space } from "antd";
import { useNavigate } from "react-router-dom";

import { LTY_REDEEM_TYPE } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps } from "@ni/common/types";
import { ProductsOverviewTable, SettingsPageLayout, SimpleCardList } from "@ni/common/ui";
import { LoyaltyWizardResponse } from "@ni/sdk/models";

import { useLoyaltyWizardApi } from "../../hooks";

export const Confirmation: FC<BaseWizardPageProps> = () => {
  const navigate = useNavigate();

  const { appliedProducts, setAppliedProducts } = useLoyaltyWizardApi();
  const [wizardResponse] = useReduxState<LoyaltyWizardResponse>("loyaltyWizard", {} as LoyaltyWizardResponse);

  useEffect(() => {
    if (wizardResponse?.instantiatedLoyaltyPrograms?.length) {
      setAppliedProducts(
        appliedProducts?.map(x => ({
          ...x,
          name:
            wizardResponse?.instantiatedLoyaltyPrograms?.find(
              y =>
                y.loyaltyProgramTemplateId === wizardResponse.loyaltyProgramTemplate?.id &&
                y.productId?.toString() === x.product.id?.toString(),
            )?.name ?? "",
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardResponse?.instantiatedLoyaltyPrograms, wizardResponse.loyaltyProgramTemplate?.id]);

  const { title, subtitle } = useMemo(() => {
    switch (appliedProducts.length) {
      case 0:
        return {
          title: "Congratulations Template Created Successfully",
          subtitle:
            "Loyalty template was created successfully, you can apply this template to eligible products from loyalty program product level options or from inside loyalty program template management",
        };
      case 1:
        return {
          title: "Congratulations Program Created Successfully",
          subtitle:
            "You can find below program you just created using this template, you can visit the -product level or pricing control table level- program settings for further customization",
        };
      default:
        return {
          title: "Congratulations Programs Created Successfully",
          subtitle:
            "You can find below programs you just created using this template, you can visit the -product level or pricing control table level- programs settings for further customization",
        };
    }
  }, [appliedProducts.length]);

  const simpleCardscontent = useMemo(
    () => [
      { title: wizardResponse?.loyaltyProgramTemplate?.name, description: "Template Name" },
      {
        title: wizardResponse?.loyaltyProgramTemplate?.type === "NORMAL" ? "Transaction based" : "Cumulative",
        description: "Accrual Type",
      },
      {
        title: wizardResponse?.loyaltyProgramTemplate?.accrualDisplayDetails,
        description: "Point Accrual",
      },
      {
        title:
          wizardResponse?.loyaltyProgramTemplate?.programValues?.find(x => x.code === LTY_REDEEM_TYPE)?.code === "E"
            ? "External voucher"
            : "Cashback",
        description: "Redemption Type",
      },
    ],
    [
      wizardResponse?.loyaltyProgramTemplate?.accrualDisplayDetails,
      wizardResponse?.loyaltyProgramTemplate?.name,
      wizardResponse?.loyaltyProgramTemplate?.programValues,
      wizardResponse?.loyaltyProgramTemplate?.type,
    ],
  );

  const handleDashboardRedirect = () => {
    navigate("/");
    setAppliedProducts([]);
  };

  const handleTemplatesRedirect = () => {
    if (wizardResponse?.tenant?.id) navigate(`/tenant/${wizardResponse?.tenant?.id}/loyalty-program-templates`);
    setAppliedProducts([]);
  };

  return (
    <SettingsPageLayout
      pageTitle={title}
      pageSubtitle={subtitle}
      size="full"
      level="tenant"
      additionalRoute="loyalty-program-templates"
    >
      <Space direction="vertical" size={40}>
        <SimpleCardList contentList={simpleCardscontent} />
        <ProductsOverviewTable
          data={appliedProducts}
          tenantId={wizardResponse?.tenant?.id?.toString() ?? ""}
          route="loyalty-programs"
          emptyDescription="Template is not applied to any product"
        />
        <Space direction="horizontal">
          <Button size="large" type="default" htmlType="submit" onClick={handleDashboardRedirect}>
            Back to Dashboard
          </Button>
          <Button size="large" type="primary" htmlType="submit" onClick={handleTemplatesRedirect}>
            Back to Loyalty Program Templates
          </Button>
        </Space>
      </Space>
    </SettingsPageLayout>
  );
};
