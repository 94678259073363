import React from "react";
import { Space, SpaceProps } from "antd";

import styles from "./styles.module.scss";

interface FormContentProps {
  gap?: number;
  direction?: SpaceProps["direction"];
  title?: React.ReactNode;
  children?: React.ReactNode;
}

export const FormContent: React.FC<FormContentProps> = ({ gap = 24, direction = "vertical", title, children }) => {
  return (
    <Space size={gap} direction={direction} className={styles["ni-form-content"]}>
      {title ? <div className="text-blue-h3">{title}</div> : null}
      {children}
    </Space>
  );
};
