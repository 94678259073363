import { FC } from "react";
import { Form, Radio } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

import { TenantCard } from "../../components";

import { CLIENT_TYPE_SERVICES_CARDS } from "./constants";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const ClientTypeSelectionPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  useHydrateForm({
    form,
    entityFields: wizardResponse.product?.productValues ?? [],
    keys: {
      strings: ["client-type"],
    },
  });

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: values as { [key: string]: string },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Client Type Selection"
      size="md"
      formSize="md"
      gap={16}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item
        name="client-type"
        label={<FormItemLabel label="Who will use the product?" code="client-type" />}
        rules={[{ required: true, message: "Client type is required!" }]}
        initialValue="Consumers"
      >
        <Radio.Group>
          {CLIENT_TYPE_SERVICES_CARDS.map(card => (
            <TenantCard
              key={card.name}
              inputId={card.name}
              title={<FormItemLabel label={card.title} code={card.name} />}
              imageSrc={card.imageSrc}
              disabled={formDisabled || card.disabled}
            >
              <Radio id={card.name} disabled={formDisabled || card.disabled} value={card.name} />
            </TenantCard>
          ))}
        </Radio.Group>
      </Form.Item>
    </CustomFormWrapper>
  );
};
