import { FC, MouseEventHandler, useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Select, Space, Switch } from "antd";
import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";

import { CopyOutlined, DeleteOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import {
  balanceSourceOptions,
  clientTypeOptions,
  productSubtypeOptions,
  productTypeOptionsWithoutAll,
  workflowTypesWithoutAll,
} from "@ni/common/mocks";
import { AfterWizardEditPageTitle, ConfirmModal, Spin, WarningModal } from "@ni/common/ui";
import { RegionApi, WorkflowApi } from "@ni/sdk/apis";
import {
  BalanceSource,
  ClientType,
  CopyWorkflowRequest,
  CreateWorkflowRequest,
  CreateWorkflowRequestRegionCodeEnum,
  ProductSubtype,
  ProductType,
  Region,
  State,
  Workflow,
  WorkflowType,
} from "@ni/sdk/models";

import styles from "./styles.module.scss";

const regionServiceApi = new RegionApi();
const workflowServiceApi = new WorkflowApi();

const { Option } = Select;
interface WorkflowDetailsForm {
  regionCode: CreateWorkflowRequestRegionCodeEnum;
  code: string;
  clientType: ClientType;
  productType: ProductType;
  balanceSource: BalanceSource;
  productSubtype: ProductSubtype;
  businessDescription: string;
  state: State;
  workflowType: WorkflowType;
}

interface WorkflowDetailsProps {
  existing: boolean;
  workflows: Workflow[];
}

const emptyWorkflowConfig: Workflow = {
  id: Number(null),
  region: { code: "UNDEFINED", name: "" },
  code: "",
  clientType: undefined,
  productType: undefined,
  balanceSource: undefined,
  productSubtype: undefined,
  phases: [],
  businessDescription: "",
  state: undefined,
  workflowType: undefined,
};

const WorkflowDetails: FC<WorkflowDetailsProps> = ({ existing, workflows }) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();

  const [workflow, setWorkflow] = useReduxState<Workflow>("workflow", emptyWorkflowConfig);
  const [regions, setRegions] = useReduxState<Region[]>("regions");

  const [isWorkflowActivity, setIsWorkflowActivity] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [isDuplicateModalOpened, setIsDuplicateModalOpened] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(false);

  const [isWarningModalShow, setIsWarningModalShow] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const [isSameWorkflowExists, setIsSameWorkflowExists] = useState<boolean>(false);

  const getRegionList = useCallback((): void => {
    setIsLoading(true);
    regionServiceApi
      .getRegions()
      .then((response: AxiosResponse<Region[]>) => {
        setRegions(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, setRegions]);

  const getWorkflow = useCallback((): void => {
    setIsLoading(true);
    workflowServiceApi
      .getWorkflowById(parseInt(id ?? "0", 10))
      .then((response: AxiosResponse<Workflow>) => {
        setWorkflow(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [id, setIsLoading, setWorkflow]);

  useEffect(() => {
    if (existing) {
      getWorkflow();
    }
  }, [existing, getWorkflow, setIsLoading]);

  useEffect(() => {
    if (regions === undefined) {
      getRegionList();
    }
  }, [getRegionList, regions]);

  useEffect(() => {
    if (existing && workflow) {
      form.setFieldsValue({
        regionCode: workflow?.region?.code,
        code: workflow?.code,
        clientType: workflow?.clientType,
        productType: workflow?.productType,
        balanceSource: workflow?.balanceSource,
        productSubtype: workflow?.productSubtype,
        businessDescription: workflow?.businessDescription,
        state: workflow?.state === State.ENABLED,
        workflowType: workflow?.workflowType,
      });
    }
  }, [existing, form, workflow]);

  useEffect(() => {
    if (isEnabled) {
      const isExists = workflows.some(
        el =>
          el.balanceSource === form.getFieldValue("balanceSource") &&
          el.clientType === form.getFieldValue("clientType") &&
          el.productSubtype === form.getFieldValue("productSubtype") &&
          el.productType === form.getFieldValue("productType") &&
          el.region.code === form.getFieldValue("regionCode") &&
          el.code === form.getFieldValue("code") &&
          el.workflowType === form.getFieldValue("workflowType"),
      );

      if (isExists) {
        setIsSameWorkflowExists(true);
      } else {
        setIsSameWorkflowExists(false);
      }
    }
  }, [form, isEnabled, workflows]);

  const onFinish = (values: WorkflowDetailsForm) => {
    setIsLoading(true);

    const requestBody: CreateWorkflowRequest = {
      regionCode: values.regionCode,
      code: values.code,
      clientType: values.clientType,
      balanceSource: values.balanceSource,
      productType: values.productType,
      productSubtype: values.productSubtype,
      businessDescription: values.businessDescription,
      state: isWorkflowActivity ? State.ENABLED : State.DISABLED,
      workflowType: values.workflowType,
    };

    if (existing) {
      workflowServiceApi
        .updateWorkflow(requestBody, workflow.id ?? Number(id))
        .then(res => {
          setWorkflow(res.data);

          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (isSameWorkflowExists) {
      setIsLoading(false);
      setIsWarningModalShow(true);
    } else {
      workflowServiceApi
        .createWorkflow(requestBody)
        .then(res => {
          setWorkflow(res.data);
          navigate(`/admin/workflows/${res.data.id || ""}`);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const onCancelDeleteModal = (): void => {
    setIsDeleteModalOpened(false);
  };

  const onCancelWarningModalModal = (): void => {
    setIsWarningModalShow(false);
    setIsSameWorkflowExists(false);
    setIsEnabled(false);
  };

  const onCancelDuplicateModal = (): void => {
    setIsDuplicateModalOpened(false);
  };

  const onFormChange = () => {
    const isFieldsFilled =
      form.getFieldValue("regionCode")?.length > 0 &&
      form.getFieldValue("code")?.length > 0 &&
      form.getFieldValue("clientType")?.length > 0 &&
      form.getFieldValue("productType")?.length > 0 &&
      form.getFieldValue("balanceSource")?.length > 0 &&
      form.getFieldValue("workflowType")?.length > 0 &&
      form.getFieldValue("productSubtype")?.length > 0;
    setIsEnabled(isFieldsFilled);
  };

  const handleDeleteWorkflow = useCallback((): void => {
    setIsLoading(true);
    workflowServiceApi
      .deleteWorkflow(Number(id))
      .then(() => {
        setIsWarningModalShow(false);
        setIsLoading(false);
        navigate(`/admin/workflows`);
      })
      .catch(() => {
        setIsLoading(false);
        setIsWarningModalShow(false);
      });
  }, [id, navigate, setIsLoading]);

  const handleWorkflowActivity = () => {
    setIsWorkflowActivity(!isWorkflowActivity);
  };

  const handleConfirmDuplicate = (values: CopyWorkflowRequest) => {
    setIsLoading(true);

    workflowServiceApi
      .copyWorkflow({ code: values.code, businessDescription: values.businessDescription }, Number(id))
      .then(() => {
        setIsLoading(false);
        onCancelDuplicateModal();
      })
      .catch(() => {
        setIsLoading(false);
        onCancelDuplicateModal();
      });
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div className={styles["workflow-details"]}>
      <AfterWizardEditPageTitle title="Workflow settings" page="Workflows" link="/admin/workflows" />
      <Form
        className={styles["workflow-details-form"]}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <div className={styles["workflow-details-form-switch"]}>
          <Form.Item name="state" valuePropName="checked">
            <Switch checked={isWorkflowActivity} onClick={handleWorkflowActivity} />
          </Form.Item>
          <div className={styles["workflow-details-switch-text"]}>Enable workflow</div>

          <div className={styles["workflow-details-settings-buttons"]}>
            <CopyOutlined
              className={existing ? "button" : "button disabled"}
              onClick={() => setIsDuplicateModalOpened(true)}
            />
            <DeleteOutlined
              className={existing ? "button" : "button disabled"}
              onClick={() => setIsDeleteModalOpened(true)}
            />
          </div>
        </div>
        <Form.Item
          name="regionCode"
          label="Region"
          tooltip={{ title: "Region", icon: <QuestionCircleFilled /> }}
          rules={[{ required: true, message: "Region is required" }]}
        >
          <Select>
            {regions?.map(country => (
              <Select.Option key={country.code} value={country.code}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          tooltip={{ title: "Code", icon: <QuestionCircleFilled /> }}
          rules={[
            { required: true, message: "Code is required" },
            { max: 255, message: "Code must have maximum 255 characters." },
            { whitespace: true },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Code contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
          ]}
        >
          <Input placeholder="Workflow code" />
        </Form.Item>
        <Form.Item
          name="clientType"
          label="Client type"
          tooltip={{ title: "Client type", icon: <QuestionCircleFilled /> }}
          rules={[{ required: true, message: "Client type is required" }]}
        >
          <Select>
            {clientTypeOptions.map(option => (
              <Select.Option key={option.value + option.label} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="productType"
          label="Product type"
          tooltip={{ title: "Product type", icon: <QuestionCircleFilled /> }}
          rules={[{ required: true, message: "Product type is required" }]}
        >
          <Select>
            {productTypeOptionsWithoutAll.map(option => (
              <Option key={option.value + option.label} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="balanceSource"
          label="Balance source"
          tooltip={{ title: "Balance source", icon: <QuestionCircleFilled /> }}
          rules={[{ required: true, message: "Balance source is required" }]}
        >
          <Select>
            {balanceSourceOptions.map(option => (
              <Option key={option.value + option.label} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="productSubtype"
          label="Product subtype"
          tooltip={{ title: "Product subtype", icon: <QuestionCircleFilled /> }}
          rules={[{ required: true, message: "Product subtype is required" }]}
        >
          <Select>
            {productSubtypeOptions.map(option => (
              <Option key={option.value + option.label} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="workflowType"
          label="Workflow type"
          tooltip={{ title: "Workflow type", icon: <QuestionCircleFilled /> }}
          rules={[{ required: true, message: "Workflow type is required" }]}
        >
          <Select>
            {workflowTypesWithoutAll.map(option => (
              <Option key={option.value + option.label} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="businessDescription"
          label="Business Description"
          tooltip={{ title: "Business Description", icon: <QuestionCircleFilled /> }}
        >
          <Input placeholder="Business Description" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              loading={isLoading}
              className="save-button"
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!isEnabled || isWarningModalShow}
            >
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <ConfirmModal
        title="Copy of workflow will be created"
        isOpened={isDuplicateModalOpened}
        onCancel={onCancelDuplicateModal}
        onConfirm={handleConfirmDuplicate as unknown as MouseEventHandler<HTMLElement>}
        isLoading={false}
        type="duplicate-workflow"
        description="Please enter the code and business description to create copy."
      />

      <ConfirmModal
        title="By clicking 'Ok' you confirm deleting the workflow and all its subordinates (phases, pages, elements). Are you sure?"
        isOpened={isDeleteModalOpened}
        onCancel={onCancelDeleteModal}
        onConfirm={handleDeleteWorkflow}
        isLoading={false}
        type="delete"
      />

      <WarningModal
        title="Set of parameters should be unique for each workflow"
        isOpened={isWarningModalShow}
        onCancel={onCancelWarningModalModal}
        onConfirm={onCancelWarningModalModal}
      />
    </div>
  );
};

export default WorkflowDetails;
