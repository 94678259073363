/* tslint:disable */
/* eslint-disable */
/**
 * Front API
 * End-point for all Front API calls
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ChangeLoyaltyProgramPctValueRequest } from '../model';
// @ts-ignore
import { ChangeLoyaltyProgramRequest } from '../model';
// @ts-ignore
import { ChangeLoyaltyProgramsOrderRequest } from '../model';
// @ts-ignore
import { ChangeLoyaltyProgramsOrderResponse } from '../model';
// @ts-ignore
import { CopyLoyaltyProgramRequest } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { LoyaltyProgram } from '../model';
// @ts-ignore
import { LoyaltyProgramPctValue } from '../model';
/**
 * LoyaltyProgramApi - axios parameter creator
 * @export
 */
export const LoyaltyProgramApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Change loyalty programs order and returns map of loyalty ids to their new loyalty codes. Order begins with 0.
         * @summary Change loyalty programs order
         * @param {ChangeLoyaltyProgramsOrderRequest} changeLoyaltyProgramsOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLoyaltyProgramOrder: async (changeLoyaltyProgramsOrderRequest: ChangeLoyaltyProgramsOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeLoyaltyProgramsOrderRequest' is not null or undefined
            assertParamExists('changeLoyaltyProgramOrder', 'changeLoyaltyProgramsOrderRequest', changeLoyaltyProgramsOrderRequest)
            const localVarPath = `/api/v1/loyalty-programs/loyalty-programs-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeLoyaltyProgramsOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies Loyalty Program by ID with new DisplayName and returns created one
         * @summary Copy Loyalty Program by OriginalId
         * @param {CopyLoyaltyProgramRequest} copyLoyaltyProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy: async (copyLoyaltyProgramRequest: CopyLoyaltyProgramRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyLoyaltyProgramRequest' is not null or undefined
            assertParamExists('copy', 'copyLoyaltyProgramRequest', copyLoyaltyProgramRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copy', 'id', id)
            const localVarPath = `/api/v1/loyalty-programs/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyLoyaltyProgramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Loyalty Program by ID
         * @param {ChangeLoyaltyProgramRequest} changeLoyaltyProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit1: async (changeLoyaltyProgramRequest: ChangeLoyaltyProgramRequest, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeLoyaltyProgramRequest' is not null or undefined
            assertParamExists('edit1', 'changeLoyaltyProgramRequest', changeLoyaltyProgramRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('edit1', 'id', id)
            const localVarPath = `/api/v1/loyalty-programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeLoyaltyProgramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit Loyalty Program Pct Values under specified program and parameter table
         * @param {ChangeLoyaltyProgramPctValueRequest} changeLoyaltyProgramPctValueRequest 
         * @param {number} id 
         * @param {number} parameterTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLoyaltyProgramPctValues: async (changeLoyaltyProgramPctValueRequest: ChangeLoyaltyProgramPctValueRequest, id: number, parameterTableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeLoyaltyProgramPctValueRequest' is not null or undefined
            assertParamExists('editLoyaltyProgramPctValues', 'changeLoyaltyProgramPctValueRequest', changeLoyaltyProgramPctValueRequest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editLoyaltyProgramPctValues', 'id', id)
            // verify required parameter 'parameterTableId' is not null or undefined
            assertParamExists('editLoyaltyProgramPctValues', 'parameterTableId', parameterTableId)
            const localVarPath = `/api/v1/loyalty-programs/{id}/pct-values`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parameterTableId !== undefined) {
                localVarQueryParameter['parameterTableId'] = parameterTableId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeLoyaltyProgramPctValueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Loyalty Program by ID
         * @summary Get Loyalty Program by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/api/v1/loyalty-programs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Loyalty Programs belonging specified product
         * @summary Gets Loyalty Programs by productId
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoyaltyProgramsByProductId: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getLoyaltyProgramsByProductId', 'productId', productId)
            const localVarPath = `/api/v1/loyalty-programs/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyProgramApi - functional programming interface
 * @export
 */
export const LoyaltyProgramApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyProgramApiAxiosParamCreator(configuration)
    return {
        /**
         * Change loyalty programs order and returns map of loyalty ids to their new loyalty codes. Order begins with 0.
         * @summary Change loyalty programs order
         * @param {ChangeLoyaltyProgramsOrderRequest} changeLoyaltyProgramsOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLoyaltyProgramOrder(changeLoyaltyProgramsOrderRequest: ChangeLoyaltyProgramsOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeLoyaltyProgramsOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLoyaltyProgramOrder(changeLoyaltyProgramsOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copies Loyalty Program by ID with new DisplayName and returns created one
         * @summary Copy Loyalty Program by OriginalId
         * @param {CopyLoyaltyProgramRequest} copyLoyaltyProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copy(copyLoyaltyProgramRequest: CopyLoyaltyProgramRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copy(copyLoyaltyProgramRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Loyalty Program by ID
         * @param {ChangeLoyaltyProgramRequest} changeLoyaltyProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async edit1(changeLoyaltyProgramRequest: ChangeLoyaltyProgramRequest, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.edit1(changeLoyaltyProgramRequest, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit Loyalty Program Pct Values under specified program and parameter table
         * @param {ChangeLoyaltyProgramPctValueRequest} changeLoyaltyProgramPctValueRequest 
         * @param {number} id 
         * @param {number} parameterTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editLoyaltyProgramPctValues(changeLoyaltyProgramPctValueRequest: ChangeLoyaltyProgramPctValueRequest, id: number, parameterTableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyProgramPctValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editLoyaltyProgramPctValues(changeLoyaltyProgramPctValueRequest, id, parameterTableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Loyalty Program by ID
         * @summary Get Loyalty Program by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Loyalty Programs belonging specified product
         * @summary Gets Loyalty Programs by productId
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoyaltyProgramsByProductId(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoyaltyProgramsByProductId(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyProgramApi - factory interface
 * @export
 */
export const LoyaltyProgramApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyProgramApiFp(configuration)
    return {
        /**
         * Change loyalty programs order and returns map of loyalty ids to their new loyalty codes. Order begins with 0.
         * @summary Change loyalty programs order
         * @param {ChangeLoyaltyProgramsOrderRequest} changeLoyaltyProgramsOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLoyaltyProgramOrder(changeLoyaltyProgramsOrderRequest: ChangeLoyaltyProgramsOrderRequest, options?: any): AxiosPromise<ChangeLoyaltyProgramsOrderResponse> {
            return localVarFp.changeLoyaltyProgramOrder(changeLoyaltyProgramsOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies Loyalty Program by ID with new DisplayName and returns created one
         * @summary Copy Loyalty Program by OriginalId
         * @param {CopyLoyaltyProgramRequest} copyLoyaltyProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copy(copyLoyaltyProgramRequest: CopyLoyaltyProgramRequest, id: number, options?: any): AxiosPromise<LoyaltyProgram> {
            return localVarFp.copy(copyLoyaltyProgramRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Loyalty Program by ID
         * @param {ChangeLoyaltyProgramRequest} changeLoyaltyProgramRequest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        edit1(changeLoyaltyProgramRequest: ChangeLoyaltyProgramRequest, id: number, options?: any): AxiosPromise<LoyaltyProgram> {
            return localVarFp.edit1(changeLoyaltyProgramRequest, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit Loyalty Program Pct Values under specified program and parameter table
         * @param {ChangeLoyaltyProgramPctValueRequest} changeLoyaltyProgramPctValueRequest 
         * @param {number} id 
         * @param {number} parameterTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editLoyaltyProgramPctValues(changeLoyaltyProgramPctValueRequest: ChangeLoyaltyProgramPctValueRequest, id: number, parameterTableId: number, options?: any): AxiosPromise<Array<LoyaltyProgramPctValue>> {
            return localVarFp.editLoyaltyProgramPctValues(changeLoyaltyProgramPctValueRequest, id, parameterTableId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Loyalty Program by ID
         * @summary Get Loyalty Program by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: number, options?: any): AxiosPromise<LoyaltyProgram> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Loyalty Programs belonging specified product
         * @summary Gets Loyalty Programs by productId
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoyaltyProgramsByProductId(productId: number, options?: any): AxiosPromise<Array<LoyaltyProgram>> {
            return localVarFp.getLoyaltyProgramsByProductId(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyProgramApi - interface
 * @export
 * @interface LoyaltyProgramApi
 */
export interface LoyaltyProgramApiInterface {
    /**
     * Change loyalty programs order and returns map of loyalty ids to their new loyalty codes. Order begins with 0.
     * @summary Change loyalty programs order
     * @param {ChangeLoyaltyProgramsOrderRequest} changeLoyaltyProgramsOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApiInterface
     */
    changeLoyaltyProgramOrder(changeLoyaltyProgramsOrderRequest: ChangeLoyaltyProgramsOrderRequest, options?: AxiosRequestConfig): AxiosPromise<ChangeLoyaltyProgramsOrderResponse>;

    /**
     * Copies Loyalty Program by ID with new DisplayName and returns created one
     * @summary Copy Loyalty Program by OriginalId
     * @param {CopyLoyaltyProgramRequest} copyLoyaltyProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApiInterface
     */
    copy(copyLoyaltyProgramRequest: CopyLoyaltyProgramRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<LoyaltyProgram>;

    /**
     * 
     * @summary Edit Loyalty Program by ID
     * @param {ChangeLoyaltyProgramRequest} changeLoyaltyProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApiInterface
     */
    edit1(changeLoyaltyProgramRequest: ChangeLoyaltyProgramRequest, id: number, options?: AxiosRequestConfig): AxiosPromise<LoyaltyProgram>;

    /**
     * 
     * @summary Edit Loyalty Program Pct Values under specified program and parameter table
     * @param {ChangeLoyaltyProgramPctValueRequest} changeLoyaltyProgramPctValueRequest 
     * @param {number} id 
     * @param {number} parameterTableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApiInterface
     */
    editLoyaltyProgramPctValues(changeLoyaltyProgramPctValueRequest: ChangeLoyaltyProgramPctValueRequest, id: number, parameterTableId: number, options?: AxiosRequestConfig): AxiosPromise<Array<LoyaltyProgramPctValue>>;

    /**
     * Returns Loyalty Program by ID
     * @summary Get Loyalty Program by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApiInterface
     */
    getById(id: number, options?: AxiosRequestConfig): AxiosPromise<LoyaltyProgram>;

    /**
     * Returns Loyalty Programs belonging specified product
     * @summary Gets Loyalty Programs by productId
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApiInterface
     */
    getLoyaltyProgramsByProductId(productId: number, options?: AxiosRequestConfig): AxiosPromise<Array<LoyaltyProgram>>;

}

/**
 * LoyaltyProgramApi - object-oriented interface
 * @export
 * @class LoyaltyProgramApi
 * @extends {BaseAPI}
 */
export class LoyaltyProgramApi extends BaseAPI implements LoyaltyProgramApiInterface {
    /**
     * Change loyalty programs order and returns map of loyalty ids to their new loyalty codes. Order begins with 0.
     * @summary Change loyalty programs order
     * @param {ChangeLoyaltyProgramsOrderRequest} changeLoyaltyProgramsOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApi
     */
    public changeLoyaltyProgramOrder(changeLoyaltyProgramsOrderRequest: ChangeLoyaltyProgramsOrderRequest, options?: AxiosRequestConfig) {
        return LoyaltyProgramApiFp(this.configuration).changeLoyaltyProgramOrder(changeLoyaltyProgramsOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies Loyalty Program by ID with new DisplayName and returns created one
     * @summary Copy Loyalty Program by OriginalId
     * @param {CopyLoyaltyProgramRequest} copyLoyaltyProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApi
     */
    public copy(copyLoyaltyProgramRequest: CopyLoyaltyProgramRequest, id: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramApiFp(this.configuration).copy(copyLoyaltyProgramRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Loyalty Program by ID
     * @param {ChangeLoyaltyProgramRequest} changeLoyaltyProgramRequest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApi
     */
    public edit1(changeLoyaltyProgramRequest: ChangeLoyaltyProgramRequest, id: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramApiFp(this.configuration).edit1(changeLoyaltyProgramRequest, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit Loyalty Program Pct Values under specified program and parameter table
     * @param {ChangeLoyaltyProgramPctValueRequest} changeLoyaltyProgramPctValueRequest 
     * @param {number} id 
     * @param {number} parameterTableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApi
     */
    public editLoyaltyProgramPctValues(changeLoyaltyProgramPctValueRequest: ChangeLoyaltyProgramPctValueRequest, id: number, parameterTableId: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramApiFp(this.configuration).editLoyaltyProgramPctValues(changeLoyaltyProgramPctValueRequest, id, parameterTableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Loyalty Program by ID
     * @summary Get Loyalty Program by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApi
     */
    public getById(id: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramApiFp(this.configuration).getById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Loyalty Programs belonging specified product
     * @summary Gets Loyalty Programs by productId
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyProgramApi
     */
    public getLoyaltyProgramsByProductId(productId: number, options?: AxiosRequestConfig) {
        return LoyaltyProgramApiFp(this.configuration).getLoyaltyProgramsByProductId(productId, options).then((request) => request(this.axios, this.basePath));
    }
}
