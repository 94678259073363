export const PCT_PAGES_CODES = {
  "transaction-restrictions": {
    labelMap: {
      "transaction-restrictions": "Transaction Restrictions",
      "nic-pers-restr-moto-en": "MOTO (Mail Order/ Telephone Order) transactions",
      "nic-pers-restr-ecomm-en": "E-commerce transactions",
      "nic-pers-restr-contactless-en": "Contactless transactions",
      "nic-pers-restr-fallback-en": "Chip fallback transactions",
      "nic-pers-restr-for-country-en": "Foreign country transactions",
      "nic-pers-restr-cash-atm-en": "Cash ATM transactions",
      "nic-pers-restr-all-dr-en": "All debit transactions",
      "nic-mcc-restr-card-pers-rules": "Merchant Category Code (MCC) to exclude",
    },
    hydrationKeys: {},
  },
  "account-limits-daily": {
    labelMap: {
      "account-limits-daily": "Account Limits Daily",
      "acc-daily-all-cash-enable": "Cash",
      "acc-daily-all-retail-enable": "Retail",
      "acc-daily-all-total-enable": "Total",
      "nic-a-d-total-enable": "All",
      "nic-a-d-retail-for-enable": "Retail Foreign",
      "nic-a-d-retail-enable": "Retail All",
      "nic-a-d-retail-dom-enable": "Retail Domestic",
      "nic-a-d-ecomm-enable": "Ecommerce",
      "nic-a-d-cash-pos-enable": "Cash POS",
      "nic-a-d-cash-for-enable": "Cash Foreign",
      "nic-a-d-cash-enable": "Cash All",
      "nic-a-d-cash-dom-enable": "Cash Domestic",
      "nic-a-d-cash-atm-enable": "Cash ATM",
      "nic-a-d-cash-atm-max-am": "ATM Max amount",
      "nic-a-d-cash-atm-max-nr": "ATM Max number",
      "nic-a-d-cash-atm-max-single": "ATM Max single amount",
      "nic-a-d-cash-dom-max-am": "Domestic Max amount",
      "nic-a-d-cash-dom-max-nr": "Domestic Max number",
      "nic-a-d-cash-dom-max-single": "Domestic Max single amount",
      "nic-a-d-cash-max-am": "Cash Max amount",
      "nic-a-d-cash-max-nr": "Cash Max number",
      "nic-a-d-cash-max-single": "Cash Max single amount",
      "nic-a-d-cash-for-max-am": "Foreign Max amount",
      "nic-a-d-cash-for-max-nr": "Foreign Max number",
      "nic-a-d-cash-for-max-single": "Foreign Max single amount",
      "nic-a-d-cash-pos-max-am": "POS Max amount",
      "nic-a-d-cash-pos-max-nr": "POS Max number",
      "nic-a-d-cash-pos-max-single": "POS Max single amount",
      "nic-a-d-ecomm-max-am": "Ecommerce Max amount",
      "nic-a-d-ecomm-max-nr": "Ecommerce Max number",
      "nic-a-d-ecomm-max-single": "Ecommerce Max single amount",
      "nic-a-d-retail-dom-max-am": "Retail Domestic Max amount",
      "nic-a-d-retail-dom-max-nr": "Retail Domestic Max number",
      "nic-a-d-retail-dom-max-single": "Retail Domestic Max single amount",
      "nic-a-d-retail-max-am": "Retail Max amount",
      "nic-a-d-retail-max-nr": "Retail Max number",
      "nic-a-d-retail-max-single": "Retail Max single amount",
      "nic-a-d-retail-for-max-am": "Retail Foreign Max amount",
      "nic-a-d-retail-for-max-nr": "Retail Foreign Max number",
      "nic-a-d-retail-for-max-single": "Retail Foreign Max single amount",
      "nic-a-d-total-max-am": "Total Max amount",
      "nic-a-d-total-max-nr": "Total Max number",
      "nic-a-d-total-max-single": "Total Max single amount",
    },
    hydrationKeys: {},
  },
  "account-limits-billing-period": {
    labelMap: {
      "account-limits-billing-period": "Account Limits Billing Period",
      "card-billing-all-cash-enable": "Cash",
      "card-billing-all-retail-enable": "Retail",
      "card-billing-all-total-enable": "Total",
      "nic-a-b-total-max-single": "Total Max single amount",
      "nic-a-b-total-max-am": "Total Max amount",
      "nic-a-b-total-max-nr": "Total Max number",
      "a-b-total-enable": "All Total",
      "nic-a-b-retail-for-max-single": "Retail Foreign Max single amount",
      "nic-a-b-retail-for-max-am": "Retail Foreign Max amount",
      "nic-a-b-retail-for-max-nr": "Retail Foreign Max number",
      "a-b-retail-for-enable": "Retail Foreign",
      "nic-a-b-retail-max-single": "Retail Max single amount",
      "nic-a-b-retail-max-am": "Retail Max amount",
      "nic-a-b-retail-max-nr": "Retail Max number",
      "a-b-retail-enable": "Retail All",
      "nic-a-b-cash-for-max-single": "Cash Foreign Max single amount",
      "nic-a-b-cash-for-max-am": "Cash Foreign Max amount",
      "nic-a-b-cash-for-max-nr": "Cash Foreign Max number",
      "a-b-cash-for-enable": "Cash Foreign",
      "nic-a-b-cash-max-single": "Cash Max single amount",
      "nic-a-b-cash-max-am": "Cash Max amount",
      "nic-a-b-cash-max-nr": "Cash Max number",
      "a-b-cash-enable": "Cash All",
    },
    hydrationKeys: {},
  },
  "card-limits-daily": {
    labelMap: {
      "card-limits-daily": "Card Limits Daily",
      "card-daily-all-cash-enable": "Cash All",
      "nic-c-d-cash-enable": "Cash All Max",
      "nic-c-d-cash-atm-enable": "ATM Cash Enable",
      "nic-c-d-cash-pos-enable": "POS Cash Enable",
      "card-daily-all-retail-enable": "Retail All Enable",
      "nic-c-d-retail-enable": "Retail Max",
      "nic-c-d-ecomm-enable": "Ecommerce Enable",
      "card-daily-all-total-enable": "Total All Enable",
      "nic-c-d-total-enable": "Total Max Enable",
      "nic-c-d-cash-atm-max-am": "ATM Cash Max Amount",
      "nic-c-d-cash-atm-max-nr": "ATM Cash Max Number",
      "nic-c-d-cash-atm-max-single": "ATM Cash Max Single Amount",
      "nic-c-d-cash-max-am": "All Cash Max Amount",
      "nic-c-d-cash-max-nr": "All Cash Max Number",
      "nic-c-d-cash-max-single": "All Cash Max Single Amount",
      "nic-c-d-cash-pos-max-am": "POS Cash Max Amount",
      "nic-c-d-cash-pos-max-nr": "POS Cash Max Number",
      "nic-c-d-cash-pos-max-single": "POS Cash Max Single Amount",
      "nic-c-d-ecomm-max-am": "Ecommerce Max Amount",
      "nic-c-d-ecomm-max-nr": "Ecommerce Max Number",
      "nic-c-d-ecomm-max-single": "Ecommerce Max Single Amount",
      "nic-c-d-retail-max-am": "Retail Max Amount",
      "nic-c-d-retail-max-nr": "Retail Max Number",
      "nic-c-d-retail-max-single": "Retail Max Single Amount",
      "nic-c-d-total-max-am": "Total Max Amount",
      "nic-c-d-total-max-nr": "Total Max Number",
      "nic-c-d-total-max-single": "Total Max Single Amount",
    },
    hydrationKeys: {},
  },
  "card-limits-monthly": {
    labelMap: {
      "card-limits-monthly": "Card Limits Monthly",
      "card-monthly-all-cash-enable": "Cash All Enable",
      "nic-c-m-cash-enable": "Cash All Max Enable",
      "nic-c-m-cash-for-enable": "Foreign Cash Enable",
      "card-monthly-all-retail-enable": "Retail All Enable",
      "nic-c-m-retail-for-enable": "Foreign Retail Enable",
      "nic-c-m-retail-enable": "Retail All Max Enable",
      "card-monthly-all-total-enable": "Total All Enable",
      "nic-c-m-total-enable": "Total Max Enable",
      "nic-c-m-cash-for-max-am": "Foreign Cash Max Amount",
      "nic-c-m-cash-for-max-nr": "Foreign Cash Max Number",
      "nic-c-m-cash-for-max-single": "Foreign Cash Max Single Amount",
      "nic-c-m-cash-max-am": "Cash Max Amount",
      "nic-c-m-cash-max-nr": "Cash Max Number",
      "nic-c-m-cash-max-single": "Cash Max Single Amount",
      "nic-c-m-retail-for-max-am": "Foreign Retail Max Amount",
      "nic-c-m-retail-for-max-nr": "Foreign Retail Max Number",
      "nic-c-m-retail-for-max-single": "Foreign Retail Max Single Amount",
      "nic-c-m-retail-max-am": "Retail Max Amount",
      "nic-c-m-retail-max-nr": "Retail Max Number",
      "nic-c-m-retail-max-single": "Retail Max Single Amount",
      "nic-c-m-total-max-am": "Total Max Amount",
      "nic-c-m-total-max-nr": "Total Max Number",
      "nic-c-m-total-max-single": "Total Max Single Amount",
    },
    hydrationKeys: {},
  },
  "card-limits-billing-period": {
    labelMap: {
      "card-limits-billing-period": "Card Limits Billing Period",
      "retail_card-monthly-all-retail-enable": "Retail",
      "cash_card-monthly-all-cash-enable": "Cash",
      "atm_c-b-cash-atm-enable": "ATM",
      "atm_nic-c-b-cash-atm-max-nr": "ATM Max number",
      "atm_nic-c-b-cash-atm-max-am": "ATM Max amount",
      "atm_nic-c-b-cash-atm-max-single": "ATM Max single amount",
      "retail_c-b-retail-enable": "Retail",
      "retail_nic-c-b-retail-max-nr": "Retail Max number",
      "retail_nic-c-b-retail-max-am": "Retail Max amount",
      "retail_nic-c-b-retail-max-single": "Retail Max single amount",
    },
    hydrationKeys: {},
  },
  "additional-rules": {
    labelMap: {
      "additional-rules": "Additional Rules",
      "nic-usage-paym-u01-rules": "Payment Limits Additional Rule 1",
      "nic-usage-paym-u02-rules": "Payment Limits Additional Rule 2",
      "nic-usage-paym-u03-rules": "Payment Limits Additional Rule 3",
    },
    hydrationKeys: {},
  },
  "account-limits": {
    labelMap: {
      "account-limits": "Account Limits",
      "b-paym-a-total-enabled": "Per billing Total",
      "d-paym-a-total-enabled": "Per Day Total",
      "d365-paym-a-total-enabled": "Per 365 days Total",
      "e-paym-a-total-enabled": "Forever Total",
      "nic-b-paym-a-total-single": "Per billing Max single amount",
      "paym-e-enabled": "Forever",
      "paym-d365-enabled": "Per 365 days",
      "paym-d-enabled": "Per Day",
      "paym-b-enabled": "Per billing",
      "nic-e-paym-a-total-single": "Forever Max single amount",
      "nic-e-paym-a-total-nr": "Forever Max number",
      "nic-e-paym-a-total-am": "Forever Max amount",
      "nic-d365-paym-a-total-single": "Per 365 days Max single amount",
      "nic-d365-paym-a-total-nr": "Per 365 days Max number",
      "nic-d365-paym-a-total-am": "Per 365 days Max amount",
      "nic-d-paym-a-total-single": "Per Day Max single amount",
      "nic-d-paym-a-total-nr": "Per Day Max number",
      "nic-d-paym-a-total-am": "Per Day Max amount",
      "nic-b-paym-a-total-nr": "Per billing Max number",
      "nic-b-paym-a-total-am": "Per billing Max amount",
    },
    hydrationKeys: {},
  },
  "card-limits": {
    labelMap: {
      "card-limits": "Card Limits",
      "d-paym-c-total-enabled": "Per Day Total",
      "d365-paym-c-total-enabled": "Per 365 Days Total",
      "m-paym-c-total-enabled": "Per Month Total",
      "nic-d-paym-c-total-am": "Per Day Max amount",
      "nic-d-paym-c-total-nr": "Per Day Max number",
      "nic-d-paym-c-total-single": "Per Day Max single amount",
      "nic-d365-paym-c-total-am": "Per 365 Days Max amount",
      "nic-d365-paym-c-total-nr": "Per 365 Days Max number",
      "nic-d365-paym-c-total-single": "Per 365 Days Max single amount",
      "nic-m-paym-c-total-am": "Per Month Max amount",
      "nic-m-paym-c-total-nr": "Per Month Max number",
      "nic-m-paym-c-total-single": "Per Month Max single amount",
      "paym-b-enabled": "Per Month",
      "paym-d-enabled": "Per Day",
      "paym-d365-enabled": "Per 365 Days",
    },
    hydrationKeys: {},
  },
  "membership-fees": {
    labelMap: {
      "membership-fees": "Membership Fees",
      "amf-suppl-enabled": "Supplementary card annual membership parameters",
      "nic-amf-card-val": "Supplementary card annual membership fee amount",
      "nic-amf-card-ex-months": "Delay first charge by (months)",
      "nic-mmf-card-enabled-pct": "Supplementary card monthly membership parameters",
      "nic-mmf-card-val": "Supplementary card monthly membership fee amount",
      mem_fee_type: "Membership fee interval",
      "nic-amf-acc-ex-months": "Delay first charge by (months)",
      "waive-amf-spend-amount": "Net spend amount",
      "nic-amf-acc-val": "Annual membership fee amount",
      "nic-mmf-acc-val": "Monthly membership fee amount",
      "waive-amf-spend": "Waive fee on eligible net spend",
    },
    hydrationKeys: {
      strings: [
        "amf-suppl-enabled",
        "nic-amf-card-val",
        "nic-amf-card-ex-months",
        "nic-mmf-card-enabled-pct",
        "nic-mmf-card-val",
        "mem_fee_type",
        "nic-amf-acc-ex-months",
        "waive-amf-spend-amount",
        "nic-amf-acc-val",
        "nic-mmf-acc-val",
        "waive-amf-spend",
      ],
    },
  },
  "joining-fee": {
    labelMap: {
      "joining-fee": "Joining Fee",
      "jf-acc-enabled": "Enable joining fee on your product",
      "nic-jf-acc-val": "Joining fee amount",
    },
    hydrationKeys: {},
  },
  "card-production-fees": {
    labelMap: {
      "card-production-fees": "Card Production Fees",
      "nic-cp-pinre-add-opt-enabled": "",
      "nic-cp-pinpr-add-opt-enabled": "",
      "nic-cp-rnew-add-opt-enabled": "",
      "nic-cp-plre-add-opt-enabled": "Differentiate the fee by additional production options for Reissue plastic",
      "nic-cp-reiss-add-opt-enabled": "Differentiate the fee by additional production options for Reissue card",
      "nic-cp-renexp-add-opt-enabled": "Differentiate the fee by additional production options for Renew expired card",
      "nic-cp-repl-add-opt-enabled": "Differentiate the fee by additional production options for Replace card",
      "nic-cp-new-add-opt-enabled": "Differentiate the fee by additional production options for New card",
      "nic-cp-fees-enabled": "Enable card production fees on your product",
      "nic-cp-new": "Default fee amount for New card",
      "nic-cp-new-pins": "PIN set for New card",
      "nic-cp-repl": "Default fee amount for Replace card",
      "nic-cp-repl-preemb-pins": "PIN set for Replace card",
      "nic-cp-renexp": "Default fee amount for Renew expired card",
      "nic-cp-renexp-pins": "PIN set for Renew expired card",
      "nic-cp-reiss": "Default fee amount for Reissue card",
      "nic-cp-reiss-preemb": "",
      "nic-cp-plre-pink": "Default fee amount for Reissue plastic",
      "nic-cp-plre-preemb-pink": "Virtual card (physical plastic is not produced) for Reissue plastic",
    },
    hydrationKeys: {},
  },
  "statement-fee": {
    labelMap: {
      "statement-fee": "Statement Fee",
      "stmt-fee-enabled": "Enable Statement fee for your product",
      "nic-stmt-fee-val": "Statement fee amount",
    },
    hydrationKeys: {},
  },
  "inactivity-fees": {
    labelMap: {
      "inactivity-fees": "Inactivity Fees",
      "inact-fee-once-en": "Enable one-time inactivity fee",
      "nic-inact-fee-once-val": "Amount charged once (on exceeding inactivity period)",
      "inact-fee-billing-en": "Enable regular inactivity fee",
      "nic-inact-fee-billing-val": "Amount charged on the regular base (on statement day)",
    },
    hydrationKeys: {
      strings: ["inact-fee-once-en", "nic-inact-fee-once-val", "inact-fee-billing-en", "nic-inact-fee-billing-val"],
    },
  },
  "overlimit-fee": {
    labelMap: {
      "overlimit-fee": "Overlimit Fee",
      "ovl-fee-enable": "Enable overlimit fee for your product",
      "nic-ovl-fee-val-base": "Overlimit fee value",
      "nic-ovl-fee-variance": "Overlimit tolerance (as a percentage of allowed account/card limit)",
    },
    hydrationKeys: {
      strings: ["ovl-fee-enable", "nic-ovl-fee-val-base", "nic-ovl-fee-variance"],
    },
  },
  "late-payment-fee": {
    labelMap: {
      "late-payment-fee": "Late Payment Fee",
      "lpf-fee-enabled": "Enable late payment fee for your product",
      "nic-lpf-min-base-bal": "Late payment amount variance",
      "nic-lp-date-days": "Late payment date (number of days from payment due date)",
      "nic-lpf-val-base": "Late payment fee amount",
    },
    hydrationKeys: {
      strings: ["lpf-fee-enabled", "nic-lpf-min-base-bal", "nic-lp-date-days", "nic-lpf-val-base"],
    },
  },
  "credit-limit-modification-fees": {
    labelMap: {
      "credit-limit-modification-fees": "Credit Limit Modification Fees",
      "crlim-perm-fee-mode": "Charge fee in case of increasing permanent limit",
      "crlim-temp-fee-mode": "Charge fee in case of temporary limit exceeds permanent limit",
      "nic-crlim-perm-fee": "Permanent limit increase fee amount",
      "nic-crlim-temp-fee": "Temporary limit fee amount",
    },
    hydrationKeys: {},
  },
  "card-transaction-fees": {
    labelMap: {
      "card-transaction-fees": "Card Transaction Fees",
      "ips-trans-fee-enabled": "Enable card transaction fees on your product",
      "nic-ips-fees-fin-only": "Do not block fee amount on authorization",
      "ips-cash-fee-enabled": "Enable Cash Transaction Fees",
      "ips-quasi-fee-enabled": "Enable Quasi Cash Transaction Fees",
      "nic-ips-retail-qsi-bf-base": "Base Quasi Cash Retail Fee",
      "nic-ips-retail-qsi-bf-fee-rate": "Fee Rate Quasi Cash Retail",
      "nic-ips-retail-qsi-bf-min": "Minimum Quasi Cash Retail Fee",
      "nic-ips-retail-qsi-bf-max": "Maximum Quasi Cash Retail Fee",
      "nic-ips-cash-atm-def-bf-base": "Base ATM Fee",
      "nic-ips-cash-atm-def-bf-fee-rate": "ATM Fee Rate",
      "nic-ips-cash-atm-def-bf-min": "Minimum ATM Fee",
      "nic-ips-cash-atm-def-bf-max": "Maximum ATM Fee",
      "nic-ips-cash-pos-def-bf-base": "Base POS Fee",
      "nic-ips-cash-pos-def-bf-fee-rate": "POS Fee Rate",
      "nic-ips-cash-pos-def-bf-max": "Maximum POS Fee",
      "nic-ips-cash-pos-def-bf-min": "Minimum POS Fee",
      "def-tier-min-atm": "Default Tier Minimum ATM",
      "def-tier-min-cash": "Default Tier Minimum Cash",
      "nic-ips-cash-pos-dom-t1-max": "Maximum Domestic POS Fee",
      "nic-ips-cash-pos-onus-t1-max": "Maximum OnUs POS Fee",
      "nic-ips-cash-pos-def-t1-max": "Maximum Default POS Fee",
      "nic-ips-cash-atm-dom-t1-max": "Maximum Domestic ATM Fee",
      "nic-ips-cash-atm-onus-t1-max": "Maximum OnUs ATM Fee",
      "nic-ips-cash-atm-def-t1-max": "Maximum Default ATM Fee",
      "atm-reg-enable": "Enable ATM Fees by Merchant/Device Origin",
      "atm-amount-enable": "Differentiate ATM Fee by Transaction Amount",
      "cash-reg-enable": "Enable Cash Fees by Merchant/Device Origin",
      "cash-amount-enable": "Differentiate Cash Fee by Transaction Amount",
      "retail-reg-enable": "Enable Retail Fees",
      "refund-reg-enable": "Enable Refund Fees",
      "p2p-deb-reg-enable": "Enable P2P Debit Fees",
      "p2p-paym-reg-enable": "Enable P2P Payment Fees",
      "quasi-reg-enable": "Enable Quasi Cash Fees",
    },
    hydrationKeys: {},
  },
  "payment-fees": {
    labelMap: {
      "payment-fees": "Payment Fees",
      "paym-fee-enabled": "Enable payment fees on your product",
      "fee-p2p-source-target": "P2P Source/Target",
      "nic-paym-online-first-base": "Online First Base",
      "nic-paym-online-first-fee-rate": "Online First Fee Rate",
      "nic-paym-online-first-min": "Online First Min",
      "nic-paym-online-first-max": "Online First Max",
      "nic-paym-online-subseq-base": "Online Subsequent Base",
      "nic-paym-online-subseq-fee-rate": "Online Subsequent Fee Rate",
      "nic-paym-online-subseq-min": "Online Subsequent Min",
      "nic-paym-online-subseq-max": "Online Subsequent Max",
      "nic-paym-out-def-first-base": "Outbound Default First Base",
      "nic-paym-out-def-first-fee-rate": "Outbound Default First Fee Rate",
      "nic-paym-out-def-first-min": "Outbound Default First Min",
      "nic-paym-out-def-first-max": "Outbound Default First Max",
      "nic-paym-out-def-subseq-base": "Outbound Default Subsequent Base",
      "nic-paym-out-def-subseq-fee-rate": "Outbound Default Subsequent Fee Rate",
      "nic-paym-out-def-subseq-min": "Outbound Default Subsequent Min",
      "nic-paym-out-def-subseq-max": "Outbound Default Subsequent Max",
      "nic-paysrc_p2p_def-bf-base": "P2P Default Base",
      "nic-paysrc_p2p_def-bf-fee-rate": "P2P Default Fee Rate",
      "nic-paysrc_p2p_def-bf-min": "P2P Default Min",
      "nic-paysrc_p2p_def-bf-max": "P2P Default Max",
      "nic-paysrc_p2p_main-bf-base": "P2P Main Base",
      "nic-paysrc_p2p_main-bf-fee-rate": "P2P Main Fee Rate",
      "nic-paysrc_p2p_main-bf-min": "P2P Main Min",
      "nic-paysrc_p2p_main-bf-max": "P2P Main Max",
      "nic-paysrc_p2p_cl-bf-base": "P2P CL Base",
      "nic-paysrc_p2p_cl-bf-fee-rate": "P2P CL Fee Rate",
      "nic-paysrc_p2p_cl-bf-min": "P2P CL Min",
      "nic-paysrc_p2p_cl-bf-max": "P2P CL Max",
      "nic-paysrc_p2p_fi-bf-base": "P2P FI Base",
      "nic-paysrc_p2p_fi-bf-fee-rate": "P2P FI Fee Rate",
      "nic-paysrc_p2p_fi-bf-min": "P2P FI Min",
      "nic-paysrc_p2p_fi-bf-max": "P2P FI Max",
      "nic-paym_p2p_def-bf-base": "PayM P2P Default Base",
      "nic-paym_p2p_def-bf-fee-rate": "PayM P2P Default Fee Rate",
      "nic-paym_p2p_def-bf-min": "PayM P2P Default Min",
      "nic-paym_p2p_def-bf-max": "PayM P2P Default Max",
      "nic-paym_p2p_main-bf-base": "PayM P2P Main Base",
      "nic-paym_p2p_main-bf-fee-rate": "PayM P2P Main Fee Rate",
      "nic-paym_p2p_main-bf-min": "PayM P2P Main Min",
      "nic-paym_p2p_main-bf-max": "PayM P2P Main Max",
      "nic-paym_p2p_cl-bf-base": "PayM P2P CL Base",
      "nic-paym_p2p_cl-bf-fee-rate": "PayM P2P CL Fee Rate",
      "nic-paym_p2p_cl-bf-min": "PayM P2P CL Min",
      "nic-paym_p2p_cl-bf-max": "PayM P2P CL Max",
      "nic-paym_p2p_fi-bf-base": "PayM P2P FI Base",
      "nic-paym_p2p_fi-bf-fee-rate": "PayM P2P FI Fee Rate",
      "nic-paym_p2p_fi-bf-min": "PayM P2P FI Min",
      "nic-paym_p2p_fi-bf-max": "PayM P2P FI Max",
      "nic-paym-alansari-base": "Al Ansari Base",
      "nic-paym-alansari-fee-rate": "Al Ansari Fee Rate",
      "nic-paym-alansari-min": "Al Ansari Min",
      "nic-paym-alansari-max": "Al Ansari Max",
      "nic-paym-alfardan-base": "Al Fardan Base",
      "nic-paym-alfardan-fee-rate": "Al Fardan Fee Rate",
      "nic-paym-alfardan-min": "Al Fardan Min",
      "nic-paym-alfardan-max": "Al Fardan Max",
      "nic-paym-atm-base": "ATM Base",
      "nic-paym-atm-fee-rate": "ATM Fee Rate",
      "nic-paym-atm-min": "ATM Min",
      "nic-paym-atm-max": "ATM Max",
      "nic-paym-banknet-base": "BankNet Base",
      "nic-paym-banknet-fee-rate": "BankNet Fee Rate",
      "nic-paym-banknet-min": "BankNet Min",
      "nic-paym-banknet-max": "BankNet Max",
      "nic-paym-cash-base": "Cash Base",
      "nic-paym-cash-fee-rate": "Cash Fee Rate",
      "nic-paym-cash-min": "Cash Min",
      "nic-paym-cash-max": "Cash Max",
      "nic-paym-cdm-base": "CDM Base",
      "nic-paym-cdm-fee-rate": "CDM Fee Rate",
      "nic-paym-cdm-min": "CDM Min",
      "nic-paym-cdm-max": "CDM Max",
      "nic-paym-cheque-base": "Cheque Base",
      "nic-paym-cheque-fee-rate": "Cheque Fee Rate",
      "nic-paym-cheque-min": "Cheque Min",
      "nic-paym-cheque-max": "Cheque Max",
      "nic-paym-collect-base": "Collect Base",
      "nic-paym-collect-fee-rate": "Collect Fee Rate",
      "nic-paym-collect-min": "Collect Min",
      "nic-paym-collect-max": "Collect Max",
      "nic-paym-offline-base": "Offline Base",
      "nic-paym-offline-fee-rate": "Offline Fee Rate",
      "nic-paym-offline-min": "Offline Min",
      "nic-paym-offline-max": "Offline Max",
      "nic-paym-swift-base": "Swift Base",
      "nic-paym-swift-fee-rate": "Swift Fee Rate",
      "nic-paym-swift-min": "Swift Min",
      "nic-paym-swift-max": "Swift Max",
      "nic-paym-transfer-base": "Transfer Base",
      "nic-paym-transfer-fee-rate": "Transfer Fee Rate",
      "nic-paym-transfer-min": "Transfer Min",
      "nic-paym-transfer-max": "Transfer Max",
      "nic-paym-uaeexc-base": "UAE Exchange Base",
      "nic-paym-uaeexc-fee-rate": "UAE Exchange Fee Rate",
      "nic-paym-uaeexc-min": "UAE Exchange Min",
      "nic-paym-uaeexc-max": "UAE Exchange Max",
      "nic-paym-uaefts-base": "UAE FTS Base",
      "nic-paym-uaefts-fee-rate": "UAE FTS Fee Rate",
      "nic-paym-uaefts-min": "UAE FTS Min",
      "nic-paym-uaefts-max": "UAE FTS Max",
      "nic-paym-dd-base": "Direct Debit Base",
      "nic-paym-dd-fee-rate": "Direct Debit Fee Rate",
      "nic-paym-dd-min": "Direct Debit Min",
      "nic-paym-dd-max": "Direct Debit Max",
      "nic-paym-uaedds-base": "UAE Direct Debit Network Base",
      "nic-paym-uaedds-fee-rate": "UAE Direct Debit Network Fee Rate",
      "nic-paym-uaedds-min": "UAE Direct Debit Network Min",
      "nic-paym-uaedds-max": "UAE Direct Debit Network Max",
      "nic-paymrev-nsf-base": "Insufficient Fund Rejection Base",
      "nic-paymrev-nsf-fee-rate": "Insufficient Fund Rejection Fee Rate",
      "nic-paymrev-nsf-min": "Insufficient Fund Rejection Min",
      "nic-paymrev-nsf-max": "Insufficient Fund Rejection Max",
    },
    hydrationKeys: {
      strings: [
        "paym-fee-enabled",
        "fee-p2p-source-target",
        "nic-paym-online-first-base",
        "nic-paym-online-first-fee-rate",
        "nic-paym-online-first-min",
        "nic-paym-online-first-max",
        "nic-paym-online-subseq-base",
        "nic-paym-online-subseq-fee-rate",
        "nic-paym-online-subseq-min",
        "nic-paym-online-subseq-max",
        "nic-paym-out-def-first-base",
        "nic-paym-out-def-first-fee-rate",
        "nic-paym-out-def-first-min",
        "nic-paym-out-def-first-max",
        "nic-paym-out-def-subseq-base",
        "nic-paym-out-def-subseq-fee-rate",
        "nic-paym-out-def-subseq-min",
        "nic-paym-out-def-subseq-max",
        "nic-paysrc_p2p_def-bf-base",
        "nic-paysrc_p2p_def-bf-fee-rate",
        "nic-paysrc_p2p_def-bf-min",
        "nic-paysrc_p2p_def-bf-max",
        "nic-paysrc_p2p_main-bf-base",
        "nic-paysrc_p2p_main-bf-fee-rate",
        "nic-paysrc_p2p_main-bf-min",
        "nic-paysrc_p2p_main-bf-max",
        "nic-paysrc_p2p_cl-bf-base",
        "nic-paysrc_p2p_cl-bf-fee-rate",
        "nic-paysrc_p2p_cl-bf-min",
        "nic-paysrc_p2p_cl-bf-max",
        "nic-paysrc_p2p_fi-bf-base",
        "nic-paysrc_p2p_fi-bf-fee-rate",
        "nic-paysrc_p2p_fi-bf-min",
        "nic-paysrc_p2p_fi-bf-max",
        "nic-paym_p2p_def-bf-base",
        "nic-paym_p2p_def-bf-fee-rate",
        "nic-paym_p2p_def-bf-min",
        "nic-paym_p2p_def-bf-max",
        "nic-paym_p2p_main-bf-base",
        "nic-paym_p2p_main-bf-fee-rate",
        "nic-paym_p2p_main-bf-min",
        "nic-paym_p2p_main-bf-max",
        "nic-paym_p2p_cl-bf-base",
        "nic-paym_p2p_cl-bf-fee-rate",
        "nic-paym_p2p_cl-bf-min",
        "nic-paym_p2p_cl-bf-max",
        "nic-paym_p2p_fi-bf-base",
        "nic-paym_p2p_fi-bf-fee-rate",
        "nic-paym_p2p_fi-bf-min",
        "nic-paym_p2p_fi-bf-max",
        "nic-paym-alansari-base",
        "nic-paym-alansari-fee-rate",
        "nic-paym-alansari-min",
        "nic-paym-alansari-max",
        "nic-paym-alfardan-base",
        "nic-paym-alfardan-fee-rate",
        "nic-paym-alfardan-min",
        "nic-paym-alfardan-max",
        "nic-paym-atm-base",
        "nic-paym-atm-fee-rate",
        "nic-paym-atm-min",
        "nic-paym-atm-max",
        "nic-paym-banknet-base",
        "nic-paym-banknet-fee-rate",
        "nic-paym-banknet-min",
        "nic-paym-banknet-max",
        "nic-paym-cash-base",
        "nic-paym-cash-fee-rate",
        "nic-paym-cash-min",
        "nic-paym-cash-max",
        "nic-paym-cdm-base",
        "nic-paym-cdm-fee-rate",
        "nic-paym-cdm-min",
        "nic-paym-cdm-max",
        "nic-paym-cheque-base",
        "nic-paym-cheque-fee-rate",
        "nic-paym-cheque-min",
        "nic-paym-cheque-max",
        "nic-paym-collect-base",
        "nic-paym-collect-fee-rate",
        "nic-paym-collect-min",
        "nic-paym-collect-max",
        "nic-paym-offline-base",
        "nic-paym-offline-fee-rate",
        "nic-paym-offline-min",
        "nic-paym-offline-max",
        "nic-paym-swift-base",
        "nic-paym-swift-fee-rate",
        "nic-paym-swift-min",
        "nic-paym-swift-max",
        "nic-paym-transfer-base",
        "nic-paym-transfer-fee-rate",
        "nic-paym-transfer-min",
        "nic-paym-transfer-max",
        "nic-paym-uaeexc-base",
        "nic-paym-uaeexc-fee-rate",
        "nic-paym-uaeexc-min",
        "nic-paym-uaeexc-max",
        "nic-paym-uaefts-base",
        "nic-paym-uaefts-fee-rate",
        "nic-paym-uaefts-min",
        "nic-paym-uaefts-max",
        "nic-paym-dd-base",
        "nic-paym-dd-fee-rate",
        "nic-paym-dd-min",
        "nic-paym-dd-max",
        "nic-paym-uaedds-base",
        "nic-paym-uaedds-fee-rate",
        "nic-paym-uaedds-min",
        "nic-paym-uaedds-max",
        "nic-paymrev-nsf-base",
        "nic-paymrev-nsf-fee-rate",
        "nic-paymrev-nsf-min",
        "nic-paymrev-nsf-max",
      ],
    },
  },
  "currency-conversion-fees": {
    labelMap: {
      "currency-conversion-fees": "Currency Conversion Fees",
      "nic-ips-dcc-cash-base": "Cash DCC Base Fee",
      "nic-ips-dcc-cash-fee-rate": "Cash DCC Fee Rate",
      "nic-ips-dcc-cash-min": "Cash DCC Min Fee",
      "nic-ips-dcc-cash-max": "Cash DCC Max Fee",
      "nic-ips-dcc-retail-base": "Retail DCC Base Fee",
      "nic-ips-dcc-retail-fee-rate": "Retail DCC Fee Rate",
      "nic-ips-dcc-retail-min": "Retail DCC Min Fee",
      "nic-ips-dcc-retail-max": "Retail DCC Max Fee",
      "nic-ips-markup-cash-fee-rate": "Cash Markup Fee Rate",
      "nic-ips-markup-retail-fee-rate": "Retail Markup Fee Rate",
      "fx-fee-enabled": "Enable Currency Conversion Fees",
      "nic-ips-markup-fees-fin-only-retail": "Do Not Block Fee for Retail",
      "nic-ips-dcc-fees-fin-only-retail": "Do Not Block Fee for DCC Retail",
    },
    hydrationKeys: {
      strings: [
        "nic-ips-dcc-cash-base",
        "nic-ips-dcc-cash-fee-rate",
        "nic-ips-dcc-cash-min",
        "nic-ips-dcc-cash-max",
        "nic-ips-dcc-retail-base",
        "nic-ips-dcc-retail-fee-rate",
        "nic-ips-dcc-retail-min",
        "nic-ips-dcc-retail-max",
        "nic-ips-markup-cash-fee-rate",
        "nic-ips-markup-retail-fee-rate",
        "fx-fee-enabled",
        "nic-ips-markup-fees-fin-only-retail",
        "nic-ips-dcc-fees-fin-only-retail",
      ],
    },
  },
  "balance-inquiry-fees": {
    labelMap: {
      "balance-inquiry-fees": "Balance Inquiry Fees",
      "bal-inq-enabled": "Enable Balance Inquiry Fee",
      "nic-bal-inq-fee-other-val": "Default Balance Inquiry Fee (AED)",
      "nic-bal-inq-fee-other-max-nr": "Free Balance Inquiries Per Billing Cycle",
      "inq-allow-onus": "Apply Custom Fee on OnUs Balance Inquiries",
      "nic-bal-inq-fee-onus-val": "OnUs Balance Inquiry Fee (AED)",
      "nic-bal-inq-fee-onus-max-nr": "Free OnUs Balance Inquiries Per Billing Cycle",
      "inq-allow-dom": "Apply Custom Fee on Domestic Balance Inquiries",
      "nic-bal-inq-fee-dom-val": "Domestic Balance Inquiry Fee (AED)",
      "nic-bal-inq-fee-dom-max-nr": "Free Domestic Balance Inquiries Per Cycle",
    },
    hydrationKeys: {},
  },
  "decline-fee": {
    labelMap: {
      "decline-fee": "Decline Fee",
      "decline-fee-enabled": "Enable decline fee for your product",
      "nic-decl-trans-fee-val": "Decline transaction fee amount",
      "nic-decl-trans-fee-max-nr": "Free of charge decline transactions per cycle",
    },
    hydrationKeys: {
      strings: ["decline-fee-enabled", "nic-decl-trans-fee-val", "nic-decl-trans-fee-max-nr"],
    },
  },
  "loyalty-settings": {
    labelMap: {
      "loyalty-settings": "Loyalty Settings",
      "nic-lty-red-rate-cash-rate": "Cashback Redemption Rate",
      "nic-lty-bonus_w-tar0-base": "Welcome Bonus Amount (Number of Points)",
      "lty-enrol-fee-en": "Enable Loyalty Enrollment Fee",
      "nic-lty-enrol-fee-val": "Loyalty Enrollment Fee Amount (AED)",
    },
    hydrationKeys: {
      strings: [
        "nic-lty-red-rate-cash-rate",
        "nic-lty-red-rate-ext-rate",
        "nic-lty-bonus_w-tar0-base",
        "nic-lty-accr-rounding-decimals",
        "lty-enrol-fee-en",
        "nic-lty-enrol-fee-val",
      ],
    },
  },
  "credit-limit": {
    labelMap: {
      "credit-limit": "Credit Limit",
      "nic-cash-limit-acc-rate": "Cash limit (as a percentage of allowed account limit)",
      "nic-ovl-auth-var": "Shadow limit (as a percentage of allowed account limit)",
      "nic-cash-limit-card-rate": "Cash limit (as a percentage of allowed card limit) for supplementary card",
    },
    hydrationKeys: {
      strings: ["nic-cash-limit-acc-rate", "nic-ovl-auth-var", "nic-cash-limit-card-rate"],
    },
  },
  "payment-due": {
    labelMap: {
      "payment-due": "Payment Due",
      "nic-dlq-date-days": "Delinquency aging day (number of days from payment due date)",
      "nic-due-date-days": "Payment due date (number of days from the billing day)",
      "nic-mtp-val-base": "MTP minimum amount",
      "nic-mtp-val-rate": "MTP percentage",
      "nic-sms-due-reminder-days": "",
    },
    hydrationKeys: {
      strings: [
        "nic-dlq-date-days",
        "nic-due-date-days",
        "nic-mtp-val-base",
        "nic-mtp-val-rate",
        "nic-sms-due-reminder-days",
      ],
    },
  },
  "murabaha-profit-calculation": {
    labelMap: {
      "murabaha-profit-calculation": "Murabaha Profit Calculation",
      "murab-profit-rate": "Murabaha profit rate",
      "int-start": "Charge profit for transaction starting from",
      "int-start-numb": "Maximum days between the transaction date and the posting date",
      "nic-fp-date-days": "Full payment date (number of days from payment due date)",
      "zero-profit-rate-all-profit": "Zero profit rate on all profit",
      "nic-int-rate-zero-retail-int": "Zero profit rate on Retail profit",
      "nic-int-rate-zero-cash-int": "Zero profit rate on Cash profit",
      "zero-profit-rate-all-fees": "Zero profit rate on all fees",
      "nic-int-rate-zero-retail-amfs": "Zero profit rate on Membership Fees",
      "nic-int-rate-zero-retail-lpf": "Zero profit rate on Late Payment Fee",
      "nic-int-rate-zero-retail-ovl": "Zero profit rate on Overlimit Fee",
      "nic-int-rate-zero-retail-nsf": "Zero profit rate on Not Sufficient Funds Fee",
      "zero-profit-rate-all-insurance": "Zero profit rate on all insurance",
      "nic-int-rate-zero-retail-ins": "Zero profit rate on Retail Insurance",
      "nic-int-rate-zero-cash-ins": "Zero profit rate on Cash Insurance",
    },
    hydrationKeys: {
      strings: ["nic-fp-date-days", "int-start", "int-start-numb", "murab-profit-rate"],
    },
  },
  "tawarruq-profit-calculation": {
    labelMap: {
      "tawarruq-profit-calculation": "Tawarruq Profit Calculation",
      "tawarruq-sales-fee-percentage": "Tawarruq sales fee percentage",
      "tawarruq-profit-type": "Tawarruq profit base",
      "tawarruq-profit-percentage": "Tawarruq profit percentage",
      "tawarruq-profit-amount": "Tawarruq profit amount",
      "tawarruq-sales-fee": "Apply Tawarruq sales fee",
      "tawarruq-sales-fee-type": "Tawarruq sales fee base",
      "tawarruq-sales-fee-amount": "Tawarruq sales fee amount",
      "nic-fp-date-days": "Full payment date (number of days from payment due date)",
    },
    hydrationKeys: {
      strings: [
        "nic-fp-date-days",
        "tawarruq-profit-amount",
        "tawarruq-profit-type",
        "tawarruq-profit-percentage",
        "tawarruq-sales-fee",
        "tawarruq-sales-fee-amount",
        "tawarruq-sales-fee-percentage",
        "tawarruq-sales-fee-type",
      ],
    },
  },
  "interest-calculation": {
    labelMap: {
      "interest-calculation": "Interest Calculation",
      "nic-due-date-days": "",
      "nic-int-rate-retail": "Annual interest rate on retail balance",
      "nic-int-rate-cash": "Annual interest rate on cash balance",
      "int-start": "Charge interest for transaction starting from:",
      "int-start-numb": "Maximum days between the transaction date and the posting date",
      "nic-fp-date-days": "Full payment date (number of days from payment due date)",
      "zero-int-fees": "Zero interest rate on all fees",
      "nic-int-rate-zero-retail-amf": "Zero interest rate on Membership Fees",
      "nic-int-rate-zero-retail-lpf": "Zero interest rate on Late Payment Fee",
      "nic-int-rate-zero-retail-ovl": "Zero interest rate on Overlimit Fee",
      "nic-int-rate-zero-retail-nsf": "Zero interest rate on Not Sufficient Funds Fee",
      "nic-int-rate-zero-retail-ins": "Zero interest rate on Retail Insurance",
      "nic-int-rate-zero-cash-ins": "Zero interest rate on Cash Insurance",
      "zero-int-ins": "Zero interest rate on all insurance",
    },
    hydrationKeys: {
      strings: [
        "nic-int-rate-cash",
        "int-start",
        "int-start-numb",
        "nic-int-rate-retail",
        "nic-fp-date-days",
        "nic-due-date-days",
      ],
    },
  },
};

export const PCT_EDIT_PAGES_CODES = {
  loyaltyPrograms: {
    "transaction-eligibility": {
      labelMap: {
        "transaction-eligibility": "Transaction Eligibility",
        "lty-check-rules": "Transaction eligibility mode",
        "lty-pref-min": "Min amount",
        "lty-pref-max": "Max amount",
        "lty-rule-merch": "Select merchants",
        "lty-rule-mcc": "Select merchant category code (MCC)",
        "lty-rule-cntr": "Select countries",
        "lty-rule-term-list": "Specify merchant terminal ID list",
        "lty-rule-merch-list": "Specify merchant ID list",
        "lty-rule-mcc-list": "Merchant category code list",
        "lty-rule-cntr-list": "Country list",
      },
      hydrationKeys: {
        strings: ["lty-pref-min", "lty-pref-max", "lty-check-rules", "lty-rule-merch", "lty-rule-mcc", "lty-rule-cntr"],
        lists: [
          "lty-rules-criteria",
          "lty-rule-term-list",
          "lty-rule-merch-list",
          "lty-rule-mcc-list",
          "lty-rule-cntr-list",
        ],
      },
    },
    "point-accrual-setup": {
      labelMap: {
        "point-accrual-setup": "Point Accrual Setup",
        "lty-accr-rate": "Points accrual percentage rate",
        "lty-accr-cap-ctd": "Total points cap per cycle",
      },
      hydrationKeys: {
        strings: [
          "lty-accr-rate",
          "lty-accr-b-rate-tiers-en",
          "lty-accr-y-rate-tiers-en",
          "lty-accr-b-rate",
          "lty-accr-y-rate",
          "lty-accr-cap-ctd",
        ],
      },
    },
    "point-expiry": {
      labelMap: {
        "point-expiry": "Point Expiry",
        "lty-exp-enable": "Enable points expiry",
        "lty-exp-move": "Points validity period",
        "lty-exp-wo": "Points write off period",
      },
      hydrationKeys: {
        strings: ["lty-exp-enable", "lty-exp-move", "lty-exp-wo"],
      },
    },
  },

  insurancePrograms: {
    "insurance-premium-settings": {
      labelMap: {
        "insurance-premium-settings": "Insurance Premium Settings",
        "ins-prem-fixed": "Insurance fixed premium amount",
        "ins-prem-rate": "Insurance premium percentage",
        "ins-prem-min": "Minimum insurance premium cap",
        "ins-cov-max": "Insurance maximum coverage amount",
        "ins-prem-waive": "Waive premium for transactor customer",
        "ins-cncl-age-limit": "Insurance program cancellation age limit",
      },
      hydrationKeys: {
        strings: [
          "ins-prem-min",
          "ins-prem-fixed",
          "ins-prem-rate",
          "ins-cov-max",
          "ins-prem-waive",
          "ins-cncl-age-limit",
        ],
      },
    },
  },

  planPrograms: {
    "interest-rate": {
      labelMap: {
        "interest-rate": "IPP Interest Rate",
        "ipp-interest-enabled": "Enable Interest Income",
        "nic-ipp-inst-int-plan-rate": "Annual interest rate",
      },
      hydrationKeys: {
        strings: ["ipp-interest-enabled", "nic-ipp-inst-int-plan-rate"],
      },
    },
    "processing-fee": {
      labelMap: {
        "processing-fee": "IPP Processing Fee",
        "ipp-proc-fee-enabled": "Enable IPP processing fee",
        "nic-ipp-proc-plan-base": "Fee fixed",
        "nic-ipp-proc-fee-plan-rate": "Fee rate",
        "nic-ipp-proc-plan-min": "Min fee",
        "nic-ipp-proc-plan-max": "Max fee",
      },
      hydrationKeys: {
        strings: [
          "ipp-proc-fee-enabled",
          "nic-ipp-proc-plan-base",
          "nic-ipp-proc-fee-plan-rate",
          "nic-ipp-proc-plan-max",
          "nic-ipp-proc-plan-min",
        ],
      },
    },
  },
};
