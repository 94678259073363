import { FC, useMemo } from "react";
import { Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { murabahaScenariosColumns, murabahaScenariosData } from "@ni/common/constants";
import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CollapsedTable, CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import {
  murabahaFeeRate,
  murabahaInterestRate,
  murabahaProfitRate,
  transactionChargeProfit,
  transactionMaximumDays,
} from "./constants";

export const MurabahaProfitPage: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId, productId } = useParams<{ id: string; productId: string }>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const isPostingCharge = Form.useWatch<string>(transactionChargeProfit.code, form)?.toString() === "P";

  const relatedLinks = useMemo(
    () => [
      ...(currentProduct.parameterTables?.map(table => ({
        href: `/tenant/${tenantId}/product/${productId}/pct/${table.id}/murabaha-profit-calculation`,
        label: `${currentProduct.displayName} - ${table.displayName} PCT - Murabaha Profit Calculation`,
      })) || []),
    ],
    [currentProduct.displayName, currentProduct.parameterTables, productId, tenantId],
  );

  useHydrateForm({
    form,
    entityFields: currentProduct.productValues ?? [],
    keys: {
      strings: [
        murabahaFeeRate.code,
        murabahaInterestRate.code,
        murabahaProfitRate.code,
        transactionChargeProfit.code,
        transactionMaximumDays.code,
      ],
    },
    allowParse: false,
  });

  const onFinish = onUpdateProduct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Murabaha Profit Settings"
      pageSubtitle="Murabaha, also referred to as cost-plus financing, is an Islamic financing structure in which the seller provides the cost and profit margin of an asset. Murabaha is not an interest-bearing loan (qardh ribawi) but is an acceptable form of credit sale under Islamic law. For Islamic covered Murabaha cards, two transactions are posted to the cardholder on the statement day, If a complete and timely payment is made, all profit on the retail balance will be forgiven."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitLabel="Save"
      submitHandler={onFinish}
      relatedLinks={relatedLinks}
    >
      <NetworkForm.Number
        formItemOptions={{
          name: murabahaProfitRate.code,
          label: murabahaProfitRate.label,
          tooltip: murabahaProfitRate.tooltip,
          rules: [{ required: true, message: `${murabahaProfitRate.label} is required!` }],
        }}
        {...murabahaProfitRate.props}
      />

      <NetworkForm.Radio
        formItemOptions={{
          name: transactionChargeProfit.code,
          label: transactionChargeProfit.label,
          initialValue: transactionChargeProfit.default,
        }}
        radioList={transactionChargeProfit.options}
        initialValue={transactionChargeProfit.default as string}
      />

      {!isPostingCharge && (
        <NetworkForm.Number
          formItemOptions={{
            name: transactionMaximumDays.code,
            label: transactionMaximumDays.label,
            tooltip: transactionMaximumDays.tooltip,
            rules: [{ required: true, message: `${transactionMaximumDays.label} is required!` }],
          }}
          {...transactionMaximumDays.props}
        />
      )}

      <Space direction="vertical" size={16}>
        <NetworkForm.Switch
          formItemOptions={{
            name: murabahaFeeRate.code,
            valuePropName: "checked",
            label: murabahaFeeRate.label,
          }}
        />

        <NetworkForm.Switch
          formItemOptions={{
            name: murabahaInterestRate.code,
            valuePropName: "checked",
            label: murabahaInterestRate.label,
          }}
        />
      </Space>
      <CollapsedTable title="What if scenarios" tabsList={murabahaScenariosData} columns={murabahaScenariosColumns} />
    </CustomFormWrapper>
  );
};
