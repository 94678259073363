import { FC, useEffect, useState } from "react";
import { Button, Drawer, Form } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { onConditionChangeValidation } from "@ni/common/utils";
import { ConfigurationApi, VariablesApi } from "@ni/sdk/apis";
import { CreateEventGroupRequest, CreateEventRequest, Variable, VariableGroup } from "@ni/sdk/models";

import EventGroupEdit from "../EventGroupEdit";

import styles from "./styles.module.scss";

const configurationServiceApi = new ConfigurationApi();

interface EventGroupDrawerProps {
  opened: boolean;
  closeDrawer: () => void;
  getEventGroups: () => void;
  maxOrderValue: number;
}

interface EventGroupDrawerForm {
  applyConditions: string;
  eventSubgroups: [];
  groupName: string;
  name: string;
  order: number;
  applyConditionsSG: string;
  variableGroups: Array<VariableGroup>;
  messageContent: string;
}
const variablesServiceApi = new VariablesApi();

export const EventGroupDrawer: FC<EventGroupDrawerProps> = ({ opened, closeDrawer, getEventGroups, maxOrderValue }) => {
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [plainVariableGroupsOptions, setPlainVariableGroupsOptions] = useReduxState<{ id: number; name: string }[]>(
    "plainVariableGroupsOptions",
    [],
  );
  const [selectedVariableGroups, setSelectedVariableGroups] = useState<{ id: number; name: string }[]>([]);

  const [variableGroupsOptions, setVariableGroupsOptions] = useReduxState<VariableGroup[]>("variableGroupsOptions", []);

  const [smsVariableList, setSmsVariableList] = useReduxState<Variable[]>("smsVariableList", []);

  const getVariableGroupsOptions = () => {
    setIsLoading(true);

    variablesServiceApi
      .getVariableGroups()
      .then(response => {
        setVariableGroupsOptions(response.data);
        const modifiedList = response.data.map(item => {
          return { id: item.id, name: item.name };
        });

        setPlainVariableGroupsOptions(modifiedList);
        setIsLoading(false);
        return response.data;
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!plainVariableGroupsOptions.length) {
      getVariableGroupsOptions();
    }
  }, [plainVariableGroupsOptions]);

  const onFinish = (values: EventGroupDrawerForm) => {
    setIsLoading(true);
    const requestBody: CreateEventGroupRequest = {
      applyConditions: values.applyConditions,
      eventSubgroups: [
        {
          name: values.name,
          applyConditions: values.applyConditionsSG,
          order: 0,
          template: {
            code: "GENERAL",
            fieldCode: "nic-sms-t-fi-str1",
            fieldValue: values.messageContent,
          },
        },
      ],
      name: values.groupName,
      order: maxOrderValue + 1,
      variableGroups: selectedVariableGroups.map(item => item.id),
    };

    configurationServiceApi
      .createEventGroup(requestBody)
      .then(response => {
        const defaultEventRequest: CreateEventRequest = {
          code: `${response.data.eventSubgroups![0].id}_GEN`,
          isGeneral: true,
          eventName: "General template for the group",
        };
        configurationServiceApi
          .createEvent(defaultEventRequest, response.data.id, response.data.eventSubgroups![0].id)
          .catch(ex => {
            throw ex;
          });
        setIsLoading(false);
        closeDrawer();
        getEventGroups();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const merge = (arrays: (Variable[] | undefined)[]) => {
    const newArray: Variable[] = [];
    for (let i = 0; i < arrays.length; i += 1) {
      newArray.push(...arrays[i]);
    }

    return newArray;
  };

  const onValuesChange = (): void => {
    const variableGroupsArray = variableGroupsOptions?.filter(
      item => (form.getFieldValue("variableGroups") as [string])?.includes(item.name),
    );

    setSelectedVariableGroups(variableGroupsArray);

    const variablesFromSelectedVariableGroupsArray =
      variableGroupsArray?.map(item => {
        return item.variables;
      }) ?? [];

    const variablesFromSelectedVariableGroups = merge(variablesFromSelectedVariableGroupsArray);
    setSmsVariableList(variablesFromSelectedVariableGroups);

    const isFieldsFilled = form.getFieldValue("groupName")?.length > 0;
    setIsEnabled(
      isFieldsFilled &&
        ((form.getFieldValue("applyConditions")?.length > 0 &&
          onConditionChangeValidation(String(form.getFieldValue("applyConditions")))) ||
          form.getFieldValue("applyConditions") === undefined ||
          form.getFieldValue("applyConditions")?.length === 0) &&
        ((form.getFieldValue("applyConditionsSG")?.length > 0 &&
          onConditionChangeValidation(String(form.getFieldValue("applyConditionsSG")))) ||
          form.getFieldValue("applyConditionsSG") === undefined ||
          form.getFieldValue("applyConditionsSG")?.length === 0),
    );
  };

  const closeNewEventGroupDrawer = () => {
    form.setFieldsValue({
      groupName: "",
      applyConditions: "",
      variableGroups: "",
      name: "",
      applyConditionsSG: "",
      messageContent: "",
    });
    closeDrawer();
  };

  return (
    <Drawer
      title="New Event Group"
      placement="right"
      open={opened}
      width="99%"
      onClose={closeNewEventGroupDrawer}
      keyboard={false}
      maskClosable={false}
      extra={
        <div className={styles["buttons"]}>
          <Button
            loading={isLoading}
            disabled={!isEnabled}
            className={styles["event-group-save-button"]}
            type="primary"
            size="large"
            htmlType="submit"
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        className={styles["event-group-editing-container"]}
        layout="vertical"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        <EventGroupEdit form={form} smsVariableList={smsVariableList} />
      </Form>
    </Drawer>
  );
};
