import { FC, useCallback, useEffect, useState } from "react";
import { Table, TableColumnType } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { CopyOutlined, SettingOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { FinancialInstitutionsFilters } from "@ni/common/types";
import { mapToServerSorting, UserService } from "@ni/common/utils";
import { FinancialInstitutionApi } from "@ni/sdk/apis";
import { FinancialInstitution, Order, SortedFilteredPageRequest } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface FinancialInstitutionsTableViewProps {
  filters: FinancialInstitutionsFilters;
}

interface SortedFilteredFIRequest {
  number: number;
  pageSize: number;
  sorting: Array<Order>;
  search: string;
  filter: { [key: string]: string };
}

const financialInstitutionServiceApi = new FinancialInstitutionApi();

const FinancialInstitutionsTableView: FC<FinancialInstitutionsTableViewProps> = ({ filters }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [financialInstitutions, setFinancialInstitutions] = useReduxState<FinancialInstitution[]>(
    "financialInstitutions",
    [],
  );

  const [isFiLoading, setIsFiLoading] = useState<boolean>(false);

  const [tablePageConfig, setTablePageConfig] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [dashboardFIPageConfig, setDashboardFIPageConfig] = useState<SortedFilteredFIRequest>();
  const [dashboardPageConfig, setDashboardPageConfig] = useState<SortedFilteredPageRequest>();
  const [, setSelectedDashboardFI] = useReduxState<string>("selectedDashboardFI", "");

  const getFinancialInstitutions = useCallback((): void => {
    if (dashboardFIPageConfig && Object.keys(dashboardFIPageConfig)) {
      setIsFiLoading(true);

      financialInstitutionServiceApi
        .get(
          pageSize,
          pageNumber,
          filters.search,
          dashboardFIPageConfig.sorting && dashboardFIPageConfig.sorting[0]?.direction,
          dashboardFIPageConfig.sorting && dashboardFIPageConfig.sorting[0]?.property,
        )
        .then(response => {
          setTablePageConfig({
            ...tablePageConfig,
            current: (dashboardFIPageConfig?.number || 0) + 1,
            pageSize: response.data.size,
            total: response.data.totalElements,
          });
          setPageNumber((dashboardFIPageConfig?.number || 0) + 1);
          setPageSize(response.data.size || 0);
          setFinancialInstitutions(response.data.content as FinancialInstitution[]);
          UserService.updateToken()
            .then(() => {})
            .catch(() => {});
          setIsFiLoading(false);
        })
        .catch(() => {
          setIsFiLoading(false);
        });
    }
  }, [dashboardFIPageConfig, filters.search, pageNumber, pageSize, setFinancialInstitutions, tablePageConfig]);

  useEffect(() => {
    getFinancialInstitutions();
  }, [dashboardFIPageConfig]);

  useEffect(() => {
    if (!dashboardFIPageConfig) {
      setDashboardFIPageConfig({
        ...filters,
        sorting: dashboardFIPageConfig?.sorting as Order[],
        pageSize: tablePageConfig.pageSize,
        number: 0,
      });
    }

    setPageNumber(0);
    setPageSize(tablePageConfig.pageSize || 0);
  }, [dashboardFIPageConfig, dashboardFIPageConfig?.sorting, filters, tablePageConfig.pageSize]);

  useEffect(() => {
    setDashboardPageConfig({
      ...filters,
      sorting: dashboardPageConfig?.sorting as Order[],
      pageLimits: {
        size: tablePageConfig.pageSize,
        number: 0,
      },
    });
  }, [filters]);

  const columns: TableColumnType<FinancialInstitution>[] = [
    {
      title: "ID",
      dataIndex: ["id"],
      key: "id",
      sorter: (a, b) => Number(a.id) - Number(b.id),
      render: (_: string, item: FinancialInstitution) => <div className="table-view-fi-name">{item.id}</div>,
      width: 120,
    },
    {
      title: "FI name",
      dataIndex: ["name"],
      key: "name",
      sorter: (a, b) => Number(a.name) - Number(b.name),
      render: (_: string, item: FinancialInstitution) => {
        return item.name;
      },
      ellipsis: true,
    },
    {
      title: "Address",
      dataIndex: ["address"],
      key: "address",
      sorter: (a, b) => Number(a.address) - Number(b.address),
      render: (_: string, item: FinancialInstitution) => {
        return item.address;
      },
      ellipsis: true,
    },
    {
      title: "Phone",
      dataIndex: ["phone"],
      key: "phone",
      sorter: (a, b) => Number(a.phone) - Number(b.phone),
      width: 150,
      render: (_: string, item: FinancialInstitution) => {
        return item.phone;
      },
      ellipsis: true,
    },

    {
      title: "",
      dataIndex: ["dashboard"],
      key: "dashboard",
      width: 100,
      render: (_: string, value: FinancialInstitution) => (
        <Link
          to={{
            pathname: `/financial-institution/${value.id}/dashboard`,
            state: { prevPath: location.pathname, fiId: value.id },
          }}
          onClick={() => setSelectedDashboardFI(value.name)}
        >
          <div className={styles["link-action"]}>
            <CopyOutlined />
            Dashboard
          </div>
        </Link>
      ),
    },

    {
      title: "",
      dataIndex: ["settings"],
      key: "settings",
      width: 100,
      render: (_: string, item: FinancialInstitution) => (
        <Link to={`/admin/financial-institutions/${item.id}/financial-institution-settings`}>
          <div className={styles["link-action"]}>
            <SettingOutlined />
            Settings
          </div>
        </Link>
      ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<FinancialInstitution> | SorterResult<FinancialInstitution>[],
  ) => {
    let sortArray: Order[] = [];
    if (sorter) {
      if (Array.isArray(sorter)) {
        sortArray = sorter
          .filter(sort => sort.order)
          .map(sortObject => ({
            direction: mapToServerSorting(sortObject.order),
            property: sortObject.columnKey as string,
          }));
      } else if (sorter.order) {
        sortArray = [{ property: sorter.columnKey as string, direction: mapToServerSorting(sorter.order) }];
      }
    }

    setDashboardFIPageConfig({
      ...dashboardFIPageConfig,
      size: pagination.pageSize,
      number: (pagination.current as number) - 1,
      sorting: sortArray.length ? sortArray : [],
    });

    setPageNumber((pagination.current as number) - 1);
    setPageSize(pagination.pageSize || 0);
  };

  return (
    <div className={styles["fi-table"]}>
      <Table<FinancialInstitution>
        className={styles["fi-table-view"]}
        columns={columns}
        dataSource={financialInstitutions}
        loading={isFiLoading}
        rowKey={(item: FinancialInstitution) => `${String(item?.id)}_${item.externalUUID}` || Math.random()}
        onChange={handleTableChange}
        pagination={tablePageConfig}
        onRow={fi => {
          return {
            onDoubleClick: () => {
              navigate(`/admin/financial-institutions/${fi.id}/financial-institution-settings`);
            },
          };
        }}
      />
    </div>
  );
};

export default FinancialInstitutionsTableView;
