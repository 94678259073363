import { SelectOption } from "@ni/common/types";

export const contractLevelList: SelectOption[] = [
  {
    value: "A",
    label: "Per account",
  },
  {
    value: "C",
    label: "Per card",
    tooltip: "It makes sense, if you want to have separate limits for primary and supplementary cards",
  },
  {
    value: "B",
    label: "Both",
  },
  {
    value: "N",
    label: "None",
    tooltip: "You can add limits after creating the product",
  },
];

export const periodRestrictListPerAccount: SelectOption[] = [
  {
    value: "D",
    label: "Day ",
  },
  {
    value: "B",
    label: "Billing ",
  },
  {
    value: "Y",
    label: "365 sliding days",
  },
  {
    value: "F",
    label: "Forever",
  },
];

export const periodRestrictListPerCard: SelectOption[] = [
  {
    value: "D",
    label: "Day ",
  },
  {
    value: "M",
    label: "Month ",
  },
  {
    value: "Y",
    label: "365 sliding days",
  },
];
export const periodRestrictListBoth: SelectOption[] = [
  {
    value: "D",
    label: "Day",
  },
  {
    value: "M",
    label: "Month (not available for account)",
  },
  {
    value: "B",
    label: "Billing (not available for cards)",
  },
  {
    value: "Y",
    label: "365 sliding days",
  },
  {
    value: "F",
    label: "Forever (not available for cards)",
  },
];
