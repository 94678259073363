import { FC, useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Select } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { ConfigurationApi, VariablesApi } from "@ni/sdk/apis";
import { CreateVariableGroupRequest, EventGroup, PatchVariableGroupRequest, VariableGroup } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface PageDrawerProps {
  opened: boolean;
  closeDrawer: () => void;
  item?: VariableGroup;
}

interface VariablesGroupModalForm {
  eventGroups?: EventGroup["id"][];
  name: VariableGroup["name"];
}

const variablesApi = new VariablesApi();
const configurationApi = new ConfigurationApi();

export const VariablesGroupModal: FC<PageDrawerProps> = props => {
  const { opened, closeDrawer, item } = props;
  const [isLoading, setIsLoading] = useReduxState<boolean>("isVariablesGroupLoading");
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [eventGroupList, setEventGroupList] = useState<EventGroup[]>([]);
  const [variableGroups, setVariableGroups] = useReduxState<VariableGroup[]>("variableGroups");

  useEffect(() => {
    if (opened) {
      form.setFieldsValue({
        eventGroups: item?.eventGroups?.map(i => i.id) || [],
        name: item?.name || "",
      });
    }
  }, [opened]);

  useEffect(() => {
    if (opened) {
      setIsLoading(true);
      configurationApi
        .getEventGroups()
        .then(response => {
          setEventGroupList(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [opened]);

  const onFinish = (values: VariablesGroupModalForm) => {
    setIsLoading(true);

    const requestBodyPatch: PatchVariableGroupRequest = {
      name: values.name,
      eventGroups: values.eventGroups?.map(i => eventGroupList.find(k => k.id === i)) as EventGroup[],
    };

    const requestBodyPost: CreateVariableGroupRequest = {
      name: values.name,
      eventGroups: values.eventGroups?.map(i => eventGroupList.find(k => k.id === i)) as EventGroup[],
    };

    if (item) {
      variablesApi
        .patchVariableGroup(requestBodyPatch, item.id)
        .then(i => {
          closeDrawer();
          setIsLoading(false);
          setVariableGroups(variableGroups.map(ii => (ii.id === item.id ? i.data : ii)));
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      variablesApi
        .createVariableGroup(requestBodyPost)
        .then(i => {
          closeDrawer();
          setIsLoading(false);
          setVariableGroups([...variableGroups, i.data]);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    setIsEnabled(false);
  };

  const onValueChange = (): void => {
    const isFieldsFilled = form.getFieldValue("name")?.length > 0 && form.getFieldValue("name")?.length < 255;
    if (item) {
      const isSomethingChange =
        item.name !== String(form.getFieldValue("name")) ||
        (!item.eventGroups?.every(element => Array(form.getFieldValue("eventGroups"))?.includes(element.id)) &&
          item.eventGroups?.length !== form.getFieldValue("eventGroups").length);
      setIsEnabled(isFieldsFilled && !!isSomethingChange);
    } else {
      setIsEnabled(isFieldsFilled);
    }
  };

  return (
    <Drawer
      title={`${item ? "Edit" : "New"} variable group`}
      placement="right"
      open={opened}
      width="99%"
      onClose={closeDrawer}
      keyboard={false}
      maskClosable={false}
      extra={
        <div className={styles["buttons"]}>
          <Button
            loading={isLoading}
            disabled={!isEnabled}
            className="page-save-button"
            type="primary"
            size="large"
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        className={styles["variables-dictionary-group-modal"]}
        layout="vertical"
        onValuesChange={onValueChange}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Group name"
          rules={[
            { max: 255, message: "Group name must have maximum 255 characters." },
            {
              required: true,
              message: "Please input group name",
            },
          ]}
        >
          <Input placeholder="Group name" />
        </Form.Item>
        <Form.Item name="eventGroups" label="Event groups">
          <Select mode="multiple" loading={isLoading}>
            {eventGroupList.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
