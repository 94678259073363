import { FC } from "react";
import { Form } from "antd";

import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";
import { InstallmentPaymentPlanWizardRequest, InstallmentPaymentPlanWizardResponse } from "@ni/sdk/models";

import { useIPPWizardApi } from "../../hooks";

import { profitTypeOption } from "./constants";

export const ProfitPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const { processWizardRequest } = useIPPWizardApi();
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse] = useReduxState<InstallmentPaymentPlanWizardResponse>(
    "installmentPaymentPlanWizard",
    {} as InstallmentPaymentPlanWizardResponse,
  );

  useHydrateForm({
    form,
    entityFields: wizardResponse?.plan?.planValues ?? [],
    keys: {
      lists: [profitTypeOption.code],
    },
  });

  const onFinish = async (values: FormValues) => {
    setIsLoading(true);
    try {
      await processWizardRequest({
        pageId: wizardResponse.pageId as number,
        tenantId: wizardResponse?.tenant?.id,
        planId: wizardResponse?.plan?.id,
        collectedValues: {
          [profitTypeOption.code]: ((values[profitTypeOption.code] as string[]) ?? []).join(","),
        } as InstallmentPaymentPlanWizardRequest["collectedValues"],
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Profit from IPP"
      pageSubtitle="Kindly define the basic profit options for the plan. Details are available on the ongoing screens."
      level="tenant"
      size="md"
      formSize="md"
      gap={40}
      submitHandler={onFinish}
      additionalRoute="./installment-payment-plans"
    >
      <NetworkForm.CheckBox
        formItemOptions={{
          name: profitTypeOption.code,
          label: profitTypeOption.name,
          initialValue: profitTypeOption.default,
        }}
        checkboxes={profitTypeOption.options}
      />
    </CustomFormWrapper>
  );
};
