import { Tag } from "antd";

import { EventGroup } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface IProps {
  eventGroup: EventGroup[] | undefined;
}

export const Tags = ({ eventGroup = [] }: IProps) => {
  return (
    <div className={styles["tags"]}>
      {eventGroup.map(({ name }) => (
        <Tag color="#E3EDF4" key={`${name || ""}-${Math.random()}`}>
          {name}
        </Tag>
      ))}
    </div>
  );
};
