import { cardControl, falcon } from "@ni/common/assets";

export const ADDITIONAL_SERVICES_CARDS = [
  {
    name: "isFalconSelected",
    title: "Falcon Fraud Monitoring",
    text: (
      <>
        End-to-end fraud management solution comprising of FICO® Falcon® Fraud Manager as the fraud management tool
        with real-time and scoring capabilities and a managed services option for efficient fraud monitoring operations.
        <br /> <br />
        The solution prepares issuers better for any fraud attacks in a dynamically changing payments landscape with
        real-time and scoring capabilities, at the same time improving efficiency and minimizing cardholder
        inconvenience.
        <br /> <br />
        <a href="https://www.network.ae/en/contents/listing/fraud-solutions#book-1" target="_blank" rel="noreferrer">
          Read more &#10132;
        </a>
      </>
    ),
    imageSrc: falcon,
  },
  {
    name: "isCardControlSelected",
    title: "Card Control",
    text: (
      <>
        The solution helps issuers and cardholders to manage their cards effectively.
        <br /> <br />
        Know where and when your card is being used and protect it by changing limit&apos;s level or turning it off if
        fraud is suspected or if card is lost.
        <br /> <br />
        <a href="https://www.network.ae/en/contents/listing/addonsolutions#mbook-1" target="_blank" rel="noreferrer">
          Read more &#10132;
        </a>
      </>
    ),
    imageSrc: cardControl,
  },
];
