import { useCallback, useEffect, useState } from "react";
import { notification, TablePaginationConfig } from "antd";

import { SERVICE_UNAVAILABLE } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { getErrorInstance, replaceObjectById } from "@ni/common/utils";
import { DeploymentsApi } from "@ni/sdk/apis";
import { Deployment, SortedFilteredPageRequest } from "@ni/sdk/models";

const deploymetsApi = new DeploymentsApi();

export const useDeployments = () => {
  const [deploymentList, seDeploymentList] = useReduxState<Deployment[]>("deploymentsList", []);
  const [, setLoading] = useReduxState("isLoading", false);

  const [deploymentsFilters, setDeploymentsFilters] = useState<SortedFilteredPageRequest>({});

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
  });

  const fetchDepeloyments = useCallback(
    (filters: SortedFilteredPageRequest, currentPage: number, size: number) => {
      setLoading(true);

      deploymetsApi
        .getDashboardDeployments1({
          ...filters,
          pageLimits: {
            number: currentPage - 1,
            size,
          },
        })
        .then(response => {
          if (response.data.content && response.data.content?.length > 0) {
            setPagination(pagination => ({ ...pagination, total: response.data.totalElements }));
          }
          seDeploymentList(response.data?.content ?? []);

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [seDeploymentList, setLoading, setPagination],
  );

  const fetchDepeloymentById = (id: number) => {
    setLoading(true);
    deploymetsApi
      .getDeploymentById1(id)
      .then(response => {
        if (deploymentList.length > 0) {
          const newLS = replaceObjectById(deploymentList, response?.data);
          seDeploymentList(newLS);
        }

        setLoading(false);
      })
      .catch(error => {
        const errorInstance = getErrorInstance(error);
        notification.error({
          placement: "topRight",
          duration: 3,
          message: (
            <div>
              {errorInstance?.response.status ?? 400} <br />
              {SERVICE_UNAVAILABLE}
            </div>
          ),
        });
        setLoading(false);
      });
  };

  const reTryDeployment = (deploymentId: number) => {
    setLoading(true);
    deploymetsApi
      .retryDeployment(deploymentId)
      .then(() => {
        notification.success({
          placement: "topRight",
          duration: 3,
          message: <div>Success!</div>,
        });

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDepeloyments(deploymentsFilters, pagination.current ?? 1, pagination.pageSize ?? 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deploymentsFilters, fetchDepeloyments, pagination.current, pagination.pageSize]);

  return {
    deploymentList,
    pagination,
    setPagination,
    setDeploymentsFilters,
    fetchDepeloyments,
    fetchDepeloymentById,
    reTryDeployment,
  };
};
