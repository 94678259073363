import { useMemo, useState } from "react";
import { FormInstance } from "rc-field-form";

import { useQpApi, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { FilterInputProps } from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";

import { transformRowkeysToOptions } from "../../utils";

export const useQpConfirmFilters = ({ form }: { form: FormInstance<FormValues> }) => {
  const [tenantRowkey] = useReduxState<string | undefined>("tenantRowkey", undefined);
  const [productRowkey, setProductRowkey] = useState<string | undefined>(undefined);

  const { qpRowkeys, qpQuests, qpParamTypes } = useQpApi({ isFetchEnabled: false });

  const qpInputFilters = useMemo(
    (): FilterInputProps[] => [
      {
        name: "rowkey.rowkey",
        type: "select",
        props: {
          placeholder: qpRowkeys?.find(x => x.rowkey === tenantRowkey)?.name ?? tenantRowkey ?? "",
          disabled: true,
          maxTagCount: "responsive",
          value: qpRowkeys?.find(x => x.rowkey === tenantRowkey)?.name ?? tenantRowkey,
        },
      },
      {
        name: "rowkey.product",
        type: "select",
        props: {
          placeholder: "Product",
          options: transformRowkeysToOptions(qpRowkeys, tenantRowkey ?? "0", true),
          onSelect: (value: string) => setProductRowkey(value),
          onClear: () => {
            setProductRowkey(undefined);
            form.resetFields(["rowkey.pct"]);
          },
          disabled: tenantRowkey === undefined,
          filterOption: filterByDisplayValue,
          showSearch: true,
          allowClear: true,
          maxTagCount: "responsive",
        },
      },
      {
        name: "rowkey.pct",
        type: "select",
        props: {
          placeholder: "PCT",
          options: transformRowkeysToOptions(qpRowkeys, productRowkey ?? "0", false),
          disabled: productRowkey === undefined,
          filterOption: filterByDisplayValue,
          showSearch: true,
          allowClear: true,
          maxTagCount: "responsive",
        },
      },
      {
        name: "parameterType",
        type: "select",
        props: {
          placeholder: "Parameter Type",
          options: qpParamTypes.map(i => ({ value: i.parmType, label: i.comments })),
          mode: "multiple",
          filterOption: filterByDisplayValue,
          showSearch: true,
          allowClear: true,
          maxTagCount: "responsive",
        },
      },
      {
        name: "questionnaire",
        type: "select",
        props: {
          placeholder: "Questionnaire",
          options: qpQuests.map(i => ({ value: i.questCode, label: i.questName })),
          mode: "multiple",
          allowClear: true,
          maxTagCount: "responsive",
        },
      },
      {
        name: "action",
        type: "select",
        props: {
          placeholder: "Action",
          options: [
            { value: "CREATE", label: "Create" },
            { value: "UPDATE", label: "Update" },
            { value: "DELETE", label: "Delete" },
          ],
          mode: "multiple",
          allowClear: true,
          maxTagCount: "responsive",
        },
      },
    ],
    [form, productRowkey, qpParamTypes, qpQuests, qpRowkeys, tenantRowkey],
  );

  return { qpInputFilters, qpRowkeys, currentRowkey: tenantRowkey };
};
