import { FC, useEffect, useState } from "react";
import { Col, Form, Row, Space, Switch } from "antd";

import { useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues } from "@ni/common/types";
import { CustomFormWrapper, FormItemLabel, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";
import { headerSwitchHandler } from "@ni/tenant-portal/core";

const wizardServicesApi = new ProductAndTenantWizardApi();
const checkboxFieldsPage28: { [key: string]: string[] } = {
  "ips-quasi-fee-enabled": [
    "nic-ips-retail-qsi-bf-base",
    "nic-ips-retail-qsi-bf-fee-rate",
    "nic-ips-retail-qsi-bf-min",
    "nic-ips-retail-qsi-bf-max",
  ],
  "ips-trans-fee-enabled": [
    "nic-ips-fees-fin-only",
    "nic-ips-cash-atm-def-bf-base",
    "nic-ips-cash-atm-def-bf-fee-rate",
    "nic-ips-cash-atm-def-bf-min",
    "nic-ips-cash-atm-def-bf-max",
    "nic-ips-cash-pos-def-bf-base",
    "nic-ips-cash-pos-def-bf-fee-rate",
    "nic-ips-cash-pos-def-bf-min",
    "nic-ips-cash-pos-def-bf-max",
  ],
};

const switchHeader28: { [key: string]: string[] } = {
  "ips-trans-fee-enabled": [],
  "ips-quasi-fee-enabled": [],
};
export const CardTransactionFeesPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();
  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [isMinMaxFeeQuasiCashEnabled, setIsMinMaxFeeQuasiCashEnabled] = useState<boolean>(true);
  const [isMinMaxFeeAtmCashEnabled, setIsMinMaxFeeAtmCashEnabled] = useState<boolean>(true);
  const [isMinMaxFeePosCashEnabled, setIsMinMaxFeePosCashEnabled] = useState<boolean>(true);

  const currency = (getFormValueFromProductValues(wizardResponse.product?.productValues, "nic-ccy") as string) || "";

  useEffect(() => {
    const formDisabledFn = (field: string, number: string | number | boolean) =>
      formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            field,
            "string",
          ) as string) || ""
        : number;

    const getMinVal = () => {
      switch (currency) {
        case "SAR":
        case "AED":
          return "10";

        case "USD":
          return "3";

        default:
          return "";
      }
    };

    const getMaxVal = () => {
      switch (currency) {
        case "SAR":
        case "AED":
          return "300";

        case "USD":
          return "85";

        default:
          return "";
      }
    };

    form.setFieldsValue({
      "ips-trans-fee-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            "ips-trans-fee-enabled",
            "boolean",
          )
        : true,
      "nic-ips-fees-fin-only": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            "nic-ips-fees-fin-only",
            "boolean",
          )
        : false,
      "ips-cash-fee-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            "ips-cash-fee-enabled",
            "boolean",
          )
        : true,
      "nic-ips-cash-atm-def-bf-base": formDisabledFn("nic-ips-cash-atm-def-bf-base", ""),
      "nic-ips-cash-atm-def-bf-fee-rate": formDisabledFn("nic-ips-cash-atm-def-bf-fee-rate", "3.00"),
      "nic-ips-cash-atm-def-bf-min": formDisabledFn("nic-ips-cash-atm-def-bf-min", getMinVal()),
      "nic-ips-cash-atm-def-bf-max": formDisabledFn("nic-ips-cash-atm-def-bf-max", getMaxVal()),
      "nic-ips-cash-pos-def-bf-base": formDisabledFn("nic-ips-cash-pos-def-bf-base", ""),
      "nic-ips-cash-pos-def-bf-fee-rate": formDisabledFn("nic-ips-cash-pos-def-bf-fee-rate", "3.00"),
      "nic-ips-cash-pos-def-bf-min": formDisabledFn("nic-ips-cash-pos-def-bf-min", getMinVal()),
      "nic-ips-cash-pos-def-bf-max": formDisabledFn("nic-ips-cash-pos-def-bf-max", getMaxVal()),

      "ips-quasi-fee-enabled": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.parameterTables?.[0].pctProductValues as ProductValue[],
            "ips-quasi-fee-enabledd",
            "boolean",
          )
        : true,
      "nic-ips-retail-qsi-bf-base": formDisabledFn("nic-ips-retail-qsi-bf-base", ""),
      "nic-ips-retail-qsi-bf-fee-rate": formDisabledFn("nic-ips-retail-qsi-bf-fee-rate", "3.00"),
      "nic-ips-retail-qsi-bf-min": formDisabledFn("nic-ips-retail-qsi-bf-min", getMinVal()),
      "nic-ips-retail-qsi-bf-max": formDisabledFn("nic-ips-retail-qsi-bf-max", getMaxVal()),
    });

    Object.keys(checkboxFieldsPage28).forEach(key => {
      form.setFieldsValue({
        [key]: false,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const item of checkboxFieldsPage28[key]) {
        if (form.getFieldValue(item)) {
          form.setFieldsValue({
            [key]: true,
          });
          break;
        }
      }
    });
  }, [currency, form, formDisabled, wizardResponse.product?.parameterTables]);

  const onFormChange = () => {
    setIsMinMaxFeeQuasiCashEnabled(!!form.getFieldValue("nic-ips-retail-qsi-bf-fee-rate")?.length);
    setIsMinMaxFeeAtmCashEnabled(!!form.getFieldValue("nic-ips-cash-atm-def-bf-fee-rate")?.length);
    setIsMinMaxFeePosCashEnabled(!!form.getFieldValue("nic-ips-cash-pos-def-bf-fee-rate")?.length);
  };

  const onFinish = () => {
    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues:
        String(form.getFieldValue("ips-trans-fee-enabled")) === "true"
          ? ({
              "ips-trans-fee-enabled": String(form.getFieldValue("ips-trans-fee-enabled")),
              "nic-ips-fees-fin-only": String(form.getFieldValue("nic-ips-fees-fin-only")),
              "ips-cash-fee-enabled": String(form.getFieldValue("ips-cash-fee-enabled")),
              "ips-quasi-fee-enabled": String(form.getFieldValue("ips-quasi-fee-enabled")),
              "nic-ips-cash-atm-def-bf-base": form.getFieldValue("nic-ips-cash-atm-def-bf-base")
                ? String(form.getFieldValue("nic-ips-cash-atm-def-bf-base")).replace(/ /g, "")
                : null,
              "nic-ips-cash-atm-def-bf-fee-rate": form.getFieldValue("nic-ips-cash-atm-def-bf-fee-rate")
                ? String(form.getFieldValue("nic-ips-cash-atm-def-bf-fee-rate")).replace(/ /g, "")
                : null,
              "nic-ips-cash-atm-def-bf-min": form.getFieldValue("nic-ips-cash-atm-def-bf-min")
                ? String(form.getFieldValue("nic-ips-cash-atm-def-bf-min")).replace(/ /g, "")
                : null,
              "nic-ips-cash-atm-def-bf-max": form.getFieldValue("nic-ips-cash-atm-def-bf-max")
                ? String(form.getFieldValue("nic-ips-cash-atm-def-bf-max")).replace(/ /g, "")
                : null,
              "nic-ips-cash-pos-def-bf-base": form.getFieldValue("nic-ips-cash-pos-def-bf-base")
                ? String(form.getFieldValue("nic-ips-cash-pos-def-bf-base")).replace(/ /g, "")
                : null,
              "nic-ips-cash-pos-def-bf-fee-rate": form.getFieldValue("nic-ips-cash-pos-def-bf-fee-rate")
                ? String(form.getFieldValue("nic-ips-cash-pos-def-bf-fee-rate")).replace(/ /g, "")
                : null,
              "nic-ips-cash-pos-def-bf-min": form.getFieldValue("nic-ips-cash-pos-def-bf-min")
                ? String(form.getFieldValue("nic-ips-cash-pos-def-bf-min")).replace(/ /g, "")
                : null,
              "nic-ips-cash-pos-def-bf-max": form.getFieldValue("nic-ips-cash-pos-def-bf-max")
                ? String(form.getFieldValue("nic-ips-cash-pos-def-bf-max")).replace(/ /g, "")
                : null,
              "nic-ips-retail-qsi-bf-base": form.getFieldValue("nic-ips-retail-qsi-bf-base")
                ? String(form.getFieldValue("nic-ips-retail-qsi-bf-base")).replace(/ /g, "")
                : null,
              "nic-ips-retail-qsi-bf-fee-rate": form.getFieldValue("nic-ips-retail-qsi-bf-fee-rate")
                ? String(form.getFieldValue("nic-ips-retail-qsi-bf-fee-rate")).replace(/ /g, "")
                : null,
              "nic-ips-retail-qsi-bf-min": form.getFieldValue("nic-ips-retail-qsi-bf-min")
                ? String(form.getFieldValue("nic-ips-retail-qsi-bf-min")).replace(/ /g, "")
                : null,
              "nic-ips-retail-qsi-bf-max": form.getFieldValue("nic-ips-retail-qsi-bf-max")
                ? String(form.getFieldValue("nic-ips-retail-qsi-bf-max")).replace(/ /g, "")
                : null,
            } as { [key: string]: string })
          : ({ "ips-trans-fee-enabled": String(form.getFieldValue("ips-trans-fee-enabled")) } as {
              [key: string]: string;
            }),
    };

    setIsLoading(true);
    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Card Transaction Fees"
      pageSubtitle="Transaction Based Fees are charged to an account only when certain types of transactions occur on an
      account."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <Space direction="horizontal" align="center">
        <Form.Item valuePropName="checked" name="ips-trans-fee-enabled">
          <Switch
            onClick={value => {
              headerSwitchHandler("ips-trans-fee-enabled", switchHeader28, value, form);
            }}
          />
        </Form.Item>
        <TooltipInfo
          label="Enable card transaction fees on your product"
          code="ips-trans-fee-enabled"
          tooltipProps={{}}
        />
      </Space>

      <Form.Item dependencies={["ips-trans-fee-enabled"]}>
        {() => {
          return (
            <Space direction="horizontal" align="center">
              <Form.Item valuePropName="checked" name="nic-ips-fees-fin-only" className="col-switch">
                <Switch disabled={!(String(form.getFieldValue("ips-trans-fee-enabled")) === "true") || formDisabled} />
              </Form.Item>

              <TooltipInfo
                label="Do not block fee amount on authorization"
                code="nic-ips-fees-fin-only"
                tooltipProps={{}}
              />
            </Space>
          );
        }}
      </Form.Item>

      <div className="limits-block">
        <Form.Item dependencies={["ips-trans-fee-enabled"]} style={{ width: "100%" }}>
          {() => {
            return (
              <div className="limits-block-header">
                <Form.Item valuePropName="checked" name="ips-cash-fee-enabled" className="header-switch">
                  <Switch
                    onClick={value => {
                      headerSwitchHandler("ips-cash-fee-enabled", switchHeader28, value, form);
                    }}
                    disabled={!form.getFieldValue("ips-trans-fee-enabled") || formDisabled}
                  />
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo label="Cash withdrawal" code="ips-cash-fee-enabled" tooltipProps={{}} />
                </div>
              </div>
            );
          }}
        </Form.Item>

        <div className="limits-block-body">
          <Row>
            <Col span={4} />
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Fee fixed"
                tooltipProps={{
                  title: "The parameter specifies the fixed fee amount to be charged for the transaction.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Fee %"
                tooltipProps={{
                  title:
                    "The parameter specifies the percentage of fee amount that needs to be calculated on original transaction. Total Fee = Fee fixed + Fee %",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Min Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the minimum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is lesser than the Min fee defined then Min fee will be charged to the customer. If Min Fee > Fee fixed + Fee % then Min Fee will be charged to customer.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Max Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the maximum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is greater than the Max fee defined then Max fee will be charged to the customer. If Max Fee < Fee fixed + Fee % then Max Fee will be charged to customer. ",
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={4}>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="ATM"
                  tooltipProps={{
                    title:
                      "ATM withdrawal fee is charged to the customers for the ATM withdrawal transactions. You can redefine these values after product creation, if you want to differentiate it for some specific cases: for defined merchant origin (on-us, domestic), for defined transaction amount range.",
                  }}
                />
              </div>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-atm-def-bf-base">
                      <Form.Item name="nic-ips-cash-atm-def-bf-base">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-atm-def-bf-fee-rate">
                      <Form.Item name="nic-ips-cash-atm-def-bf-fee-rate">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter="%"
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-atm-def-bf-min">
                      <Form.Item name="nic-ips-cash-atm-def-bf-min">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            !isMinMaxFeeAtmCashEnabled ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-atm-def-bf-max">
                      <Form.Item name="nic-ips-cash-atm-def-bf-max">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            !isMinMaxFeeAtmCashEnabled ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div className="col-switch-desc">
                <TooltipInfo
                  label="Cash POS"
                  tooltipProps={{
                    title:
                      "Cash POS fee is charged to the customers for the cash withdrawal transactions at the POS machines that are not considered as retail purchases. You can redefine these values after product creation, if you want to differentiate it for some specific cases: for defined merchant origin (on-us, domestic), for defined transaction amount range.",
                  }}
                />
              </div>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-pos-def-bf-base">
                      <Form.Item name="nic-ips-cash-pos-def-bf-base">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-pos-def-bf-fee-rate">
                      <Form.Item name="nic-ips-cash-pos-def-bf-fee-rate">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter="%"
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-pos-def-bf-min">
                      <Form.Item name="nic-ips-cash-pos-def-bf-min">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !isMinMaxFeePosCashEnabled ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-trans-fee-enabled", "ips-cash-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-cash-pos-def-bf-max">
                      <Form.Item name="nic-ips-cash-pos-def-bf-max">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-cash-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !isMinMaxFeePosCashEnabled ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className="limits-block">
        <Form.Item dependencies={["ips-trans-fee-enabled"]} style={{ width: "100%" }}>
          {() => {
            return (
              <div className="limits-block-header">
                <Form.Item
                  valuePropName="checked"
                  name="ips-quasi-fee-enabled"
                  className="header-switch"
                  dependencies={["ips-trans-fee-enabled"]}
                >
                  <Switch
                    onClick={value => {
                      headerSwitchHandler("ips-quasi-fee-enabled", switchHeader28, value, form);
                    }}
                    disabled={!form.getFieldValue("ips-trans-fee-enabled") || formDisabled}
                  />
                </Form.Item>
                <div className="col-switch-desc">
                  <TooltipInfo
                    label="Quasi Cash"
                    code="ips-quasi-fee-enabled"
                    tooltipProps={{
                      title:
                        "System identifies a transaction as Quasi cash transaction based on the transaction Merchant category code (MCC). E.g. ‘6050 - Quasi Cash, fin Inst’, ‘6051 - Foreign Currency, Money, TC’s' After wizard completing you can configure this list at tenant settings (visit Fees Tenant Configuration page).",
                    }}
                  />
                </div>
              </div>
            );
          }}
        </Form.Item>
        <div className="limits-block-body">
          <Row>
            <Col span={4} />
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Fee fixed"
                tooltipProps={{
                  title: "The parameter specifies the fixed fee amount to be charged for the transaction.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Fee %"
                tooltipProps={{
                  title:
                    "The parameter specifies the percentage of fee amount that needs to be calculated on original transaction. Total Fee = Fee fixed + Fee %",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Min Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the minimum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is lesser than the Min fee defined then Min fee will be charged to the customer. If Min Fee > Fee fixed + Fee % then Min Fee will be charged to customer.",
                }}
              />
            </Col>
            <Col span={5} className="col-header">
              <TooltipInfo
                label="Max Fee"
                tooltipProps={{
                  title:
                    "The parameter specifies the maximum fee amount that needs to be charged to the customer. Incase of calculated `Total Fee` is greater than the Max fee defined then Max fee will be charged to the customer. If Max Fee < Fee fixed + Fee % then Max Fee will be charged to customer. ",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <div className="col-switch-desc">Default Fee</div>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-quasi-fee-enabled", "ips-trans-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-retail-qsi-bf-base">
                      <Form.Item name="nic-ips-retail-qsi-bf-base">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-quasi-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-quasi-fee-enabled", "ips-trans-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-retail-qsi-bf-fee-rate">
                      <Form.Item name="nic-ips-retail-qsi-bf-fee-rate">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter="%"
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-quasi-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-quasi-fee-enabled", "ips-trans-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-retail-qsi-bf-min">
                      <Form.Item name="nic-ips-retail-qsi-bf-min">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-quasi-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !isMinMaxFeeQuasiCashEnabled ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item dependencies={["ips-quasi-fee-enabled"]}>
                {() => {
                  return (
                    <FormItemLabel code="nic-ips-retail-qsi-bf-max">
                      <Form.Item name="nic-ips-retail-qsi-bf-max">
                        <NetworkForm.Number
                          stringMode={true}
                          addonAfter={currency}
                          min={0}
                          precision={2}
                          disabled={
                            !form.getFieldValue("ips-quasi-fee-enabled") ||
                            !form.getFieldValue("ips-trans-fee-enabled") ||
                            !isMinMaxFeeQuasiCashEnabled ||
                            formDisabled
                          }
                        />
                      </Form.Item>
                    </FormItemLabel>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </CustomFormWrapper>
  );
};
