import { Form } from "antd";
import { useParams } from "react-router-dom";

import {
  LTY_PNT_MODE,
  LTY_REFUND_MODE,
  LTY_WELC_BONUS_ENABLE,
  NIC_LTY_ACCR_ROUNDING_DECIMALS,
  NIC_LTY_BONUS_W_TAR_BASE,
  NIC_LTY_BONUS_W_TIMER_FROM,
  NIC_LTY_BONUS_W_TIMER_PER,
  NIC_LTY_BONUS_W_TIMER_PER_TYPE,
  NIC_LTY_RED_RATE_CASH_RATE,
  NIC_LTY_RED_RATE_EXT_RATE,
} from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, Tabs } from "@ni/common/ui";
import { isNumeric, removeGapsFromStringNumeric } from "@ni/common/utils";
import { TenantApi } from "@ni/sdk/apis";
import { Tenant, TenantValue } from "@ni/sdk/models";

import { LoyaltyPoints, PointRedemption, WelcomeBonus } from "../../../components";

type TabKeysLoyaltySettings = "" | "points" | "pointsRedemption" | "welcomeBonus";

const tenantsServiceApi = new TenantApi();

export const GeneralLoyaltySettings = () => {
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading", false);
  const [form] = Form.useForm<FormValues>();
  const { id: tenantId } = useParams();

  const isWelcomeBonusOn = Form.useWatch(LTY_WELC_BONUS_ENABLE, form);

  const initialValues = useHydrateForm(
    {
      form,
      entityFields: tenant?.tenantValues ?? [],
      keys: {
        strings: [
          LTY_PNT_MODE,
          LTY_REFUND_MODE,
          NIC_LTY_ACCR_ROUNDING_DECIMALS,
          NIC_LTY_RED_RATE_CASH_RATE,
          NIC_LTY_RED_RATE_EXT_RATE,
          LTY_WELC_BONUS_ENABLE,
          NIC_LTY_BONUS_W_TAR_BASE,
          NIC_LTY_BONUS_W_TIMER_FROM,
          NIC_LTY_BONUS_W_TIMER_PER_TYPE,
          NIC_LTY_BONUS_W_TIMER_PER,
        ],
      },
    },
    [form, tenant?.tenantValues],
  );

  const relatedLinks = [
    {
      href: `/tenant/${tenantId}/loyalty-program-templates`,
      label: "Loyalty Program Templates",
    },
  ];

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const tenantValues: TenantValue[] = Object.entries(values).map(([key, value]) => ({
      fieldCode: key,
      value: isNumeric(value as string) ? removeGapsFromStringNumeric(value as string) : value,
    })) as TenantValue[];

    if (typeof isWelcomeBonusOn === "boolean" && !isWelcomeBonusOn) {
      [
        NIC_LTY_BONUS_W_TAR_BASE,
        NIC_LTY_BONUS_W_TIMER_FROM,
        NIC_LTY_BONUS_W_TIMER_PER_TYPE,
        NIC_LTY_BONUS_W_TIMER_PER,
      ].forEach(fieldName => {
        tenantValues.push({ fieldCode: fieldName, value: undefined });
      });
    }

    tenantsServiceApi
      .editTenant(
        {
          tenantValues,
        },
        tenant?.id ?? 0,
      )
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      pageTitle="Loyalty - General Settings"
      pageSubtitle="While the tenant can be associated with multiple loyalty programs, the following settings apply universally to all loyalty programs and some of the settings can be redefined at a particular product level."
      form={form}
      submitHandler={onFinish}
      level="tenant"
      formSize="full"
      submitLabel="Save"
      additionalRoute="details"
      relatedLinks={relatedLinks}
    >
      <Tabs<TabKeysLoyaltySettings>
        pagesList={[
          {
            key: "points" as const,
            children: <LoyaltyPoints />,
            label: <div title="Loyalty Points">Loyalty Points</div>,
          },
          {
            key: "pointsRedemption" as const,
            children: <PointRedemption loyaltyPrograms={tenant?.loyaltyProgramTemplates ?? []} />,
            label: <div title="Point Redemption">Point Redemption</div>,
          },

          {
            key: "welcomeBonus" as const,
            children: <WelcomeBonus form={form} />,
            label: <div title="Welcome Bonus">Welcome Bonus</div>,
          },
        ]}
        isCheckEnabled={true}
        form={form}
        initialValues={initialValues}
        discardAfterChangeTab={false}
        onSave={onFinish}
      />
    </CustomFormWrapper>
  );
};
