import { FC, ReactNode, useMemo, useRef, useState } from "react";
import { Button, Flex, Form, Input, Modal, Space, Switch, Table, TableColumnType, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import { EditOutlined, SettingOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { CopyIcon } from "@ni/common/icons";
import { FormValues } from "@ni/common/types";
import { TooltipInfo } from "@ni/common/ui";
import { InsuranceProgram, InsuranceProgramTemplate } from "@ni/sdk/models";

import styles from "./styles.module.scss";

interface LoyaltyTableProps {
  insurancePrograms: InsuranceProgram[];
  insuranceTemplates: InsuranceProgramTemplate[];
  insuranceActions: {
    duplicateInsuranceProgram: (value: FormValues, id: number, errorMessage: ReactNode) => Promise<void>;
    renameInsuranceProgram: (value: FormValues, id: number) => Promise<void>;
    toggleInsuranceProgram: (state: string, id: number) => Promise<void>;
  };
}

export const InsuranceTable: FC<LoyaltyTableProps> = ({ insurancePrograms, insuranceTemplates, insuranceActions }) => {
  const insuranceId = useRef(0);
  const navigate = useNavigate();
  const [modalForm] = Form.useForm<FormValues>();
  const [modalDuplicateForm] = Form.useForm<FormValues>();
  const [isLoading] = useReduxState<boolean>("isLoading", false);
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);

  const shouldDisableActivation = useMemo(() => {
    return insurancePrograms.filter(x => x.state === "ACTIVE")?.length >= 12;
  }, [insurancePrograms]);

  const handleRenameClick = (id: number, name: string) => {
    insuranceId.current = id;
    modalForm.setFieldsValue({
      name,
    });
    setModalOpen(true);
  };

  const handleEditClick = (id: number) => {
    navigate(`../edit-insurance/${id}/main-insurance-settings`);
  };

  const handleDuplicateClick = (id: number, name: string) => {
    insuranceId.current = id;
    modalDuplicateForm.setFieldsValue({
      newDisplayName: `${name} - copy`,
    });
    setDuplicateModalOpen(true);
  };

  const columns: TableColumnType<InsuranceProgram>[] = [
    {
      key: "switchHandler",
      width: "8%",
      render: (_: string, { id, state }: InsuranceProgram) => {
        return (
          <Space direction="horizontal">
            <Switch
              checked={state === "ACTIVE"}
              onChange={() => {
                insuranceId.current = id ?? 0;
                setShowToggleModal(true);
              }}
            />
            <TooltipInfo
              label=""
              tooltipProps={{
                title: "Switching this off will not allow subscribing new onboarding accounts under this program",
              }}
            />
          </Space>
        );
      },
    },
    {
      title: "Program code",
      dataIndex: ["code"],
      key: "code",
      width: "11%",
      render: (_: string, { code, state }, index) =>
        state === "ACTIVE" ? code?.toUpperCase() ?? `P${index < 9 ? `0${index + 1}` : index + 1}` : "",
    },
    {
      title: "Program name",
      dataIndex: ["name"],
      key: "name",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Insurance Company",
      dataIndex: ["insuranceCompanyName"],
      key: "insuranceCompanyName",
      width: "18%",
      render: (_: string, { insuranceCompanyName }: InsuranceProgram) => insuranceCompanyName ?? "",
    },
    {
      title: "Currency",
      dataIndex: ["insuranceProgramTemplateId"],
      key: "insuranceProgramTemplateId",
      width: "10%",
      render: (_: string, { insuranceProgramTemplateId }: InsuranceProgram) => {
        return insuranceTemplates?.find(x => x.id === insuranceProgramTemplateId)?.currency ?? "";
      },
    },
    {
      key: "loyaltyActions",
      width: "10%",
      render: (_: string, { id, name, state }: InsuranceProgram) => (
        <Flex justify="start" gap={8} className="w-p-100">
          <Tooltip title="Rename">
            <Button
              type="text"
              htmlType="button"
              icon={<EditOutlined />}
              className={styles["lty-action-button"]}
              onClick={() => handleRenameClick(id ?? 0, name ?? "")}
            />
          </Tooltip>
          {state === "ACTIVE" && (
            <Tooltip title="Edit">
              <Button
                type="text"
                htmlType="button"
                icon={<SettingOutlined />}
                className={styles["lty-action-button"]}
                onClick={() => handleEditClick(id ?? 0)}
              />
            </Tooltip>
          )}
          <Tooltip title="Duplicate">
            <Button
              type="text"
              htmlType="button"
              icon={<CopyIcon />}
              className={styles["lty-action-button"]}
              onClick={() => handleDuplicateClick(id ?? 0, name ?? "")}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Table rowKey="id" columns={columns} dataSource={insurancePrograms} pagination={false} loading={isLoading} />

      <Modal
        title="Rename insurance program"
        open={modalOpen}
        width="500px"
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="back" disabled={modalIsLoading} onClick={() => setModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={modalIsLoading} onClick={modalForm.submit}>
            Save
          </Button>,
        ]}
      >
        <Form
          form={modalForm}
          autoComplete="off"
          layout="vertical"
          disabled={modalIsLoading}
          onFinish={value => {
            setModalIsLoading(true);
            void insuranceActions.renameInsuranceProgram(value, insuranceId.current).finally(() => {
              setModalIsLoading(false);
              setModalOpen(false);
            });
          }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ type: "string", required: true, max: 64, message: "Cannot be empty. Max length is 64 letters" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Duplicate insurance program"
        open={duplicateModalOpen}
        width="500px"
        onCancel={() => setDuplicateModalOpen(false)}
        footer={[
          <Button key="back" disabled={modalIsLoading} onClick={() => setDuplicateModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={modalIsLoading} onClick={modalDuplicateForm.submit}>
            Duplicate
          </Button>,
        ]}
      >
        <Form
          form={modalDuplicateForm}
          autoComplete="off"
          layout="vertical"
          disabled={modalIsLoading}
          onFinish={value => {
            setModalIsLoading(true);
            void insuranceActions
              .duplicateInsuranceProgram(
                value,
                insuranceId.current,
                <Space direction="vertical">
                  <Typography.Text strong={true}>Error</Typography.Text>
                  <Typography.Text>A maximum of 12 insurance programs can be applied to every product</Typography.Text>
                </Space>,
              )
              .finally(() => {
                setModalIsLoading(false);
                setDuplicateModalOpen(false);
              });
          }}
        >
          <Form.Item
            name="newDisplayName"
            label="Name"
            rules={[{ type: "string", required: true, max: 64, message: "Cannot be empty. Max length is 64 letters" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          shouldDisableActivation && insurancePrograms?.find(x => x.id === insuranceId.current)?.state === "INACTIVE"
            ? "You have already activated the maximum number of insurance programs on your product"
            : `Are you sure you want to ${
                insurancePrograms?.find(x => x.id === insuranceId.current)?.state === "ACTIVE" ? "disable" : "enable"
              } this program on your product?`
        }
        open={showToggleModal}
        width="500px"
        onCancel={() => setShowToggleModal(false)}
        footer={[
          <Button key="back" disabled={modalIsLoading} onClick={() => setShowToggleModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={modalIsLoading}
            hidden={
              shouldDisableActivation &&
              insurancePrograms?.find(x => x.id === insuranceId.current)?.state === "INACTIVE"
            }
            onClick={() => {
              setModalIsLoading(true);
              void insuranceActions
                .toggleInsuranceProgram(
                  insurancePrograms?.find(x => x.id === insuranceId.current)?.state ?? "",
                  insuranceId.current ?? 0,
                )
                .finally(() => {
                  setModalIsLoading(false);
                  setShowToggleModal(false);
                });
            }}
          >
            Save
          </Button>,
        ]}
      />
    </>
  );
};
