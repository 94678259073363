export const PRODUCT_PAGES_CODES = {
  "product-details": {
    labelMap: {
      "product-details": "Product Details",
      "nic-prod-ips": "Payment scheme",
      "nic-prod-core-name": "Product name",
      displayName: "Product displayed name",
      "product-type": "Product group",
      "is-co-badged-product": "Co-badged product",
      "co-badged-name": "Co-badged scheme",
      "credit-type": "Product Interest type",
      "credit-type-islamic": "Islamic credit card profit structure",
    },
    hydrationKeys: {
      strings: [
        "nic-prod-ips",
        "nic-prod-core-name",
        "nic-ccy",
        "product-type",
        "nic-card-subtype-pan-bin",
        "use-test-bin",
        "nic-card-subtype-pan-full-min",
        "nic-card-subtype-pan-full-max",
        "is-co-badged-product",
        "nic-mada-use",
        "co-badged-name",
        "credit-type",
        "credit-type-islamic",
        "add-prod-card-control",
      ],
    },
  },
  "currency-setup": {
    labelMap: {
      "currency-setup": "Currency Setup",
      "nic-ccy": "Select product currency",
      "nic-prod-ppm-w00": "curr-setup",
    },
    hydrationKeys: {},
  },
  "card-numbering": {
    labelMap: {
      "card-numbering": "Card Numbering",
      "nic-card-subtype-pan-bin": "BIN",
      "nic-prod-parms-ica-number": "",
      "use-test-bin": "Use Network test BIN if you haven't got the real one",
      "nic-card-subtype-pan-full-min": "BIN min",
      "nic-card-subtype-pan-full-max": "BIN max",
      "ica-number-mc": "",
      "rep-bin-visa": "",
      "nic-prod-ips": "",
      "bin-length": "What is the length of product BIN?",
      "member-id": "Member ID",
    },
    hydrationKeys: {},
  },
  "credit-limit": {
    labelMap: {
      "credit-limit": "Credit Limit",
      "nic-crlim-min": "Minimum credit limit",
      "nic-crlim-max": "Maximum credit limit",
      "nic-crlim-default": "Default credit limit",
      "nic-cash-limit-acc-rate": "Cash limit (as a percentage of allowed account limit)",
      "nic-cash-limit-card-rate": "Cash limit (as a percentage of allowed card limit) for supplementary card",
      "nic-ovl-auth-var": "Shadow limit (as a percentage of allowed account limit)",
      "nic-ch_agrm-canc-crlim-nullify-acc": "Nullify account credit limit on account cancellation",
      "nic-ch_agrm-canc-crlim-nullify-card": "Nullify card credit limit on card cancellation",
      "nic-nullify-crlim-acc-acc_st-8": "Nullify card credit limit on account closure",
      "nic-nullify-crlim-acc-acc_st-9": "Nullify card credit limit on account purge",
      "nic-nullify-crlim-acc-acc_st-z": "Nullify card credit limit on account charge-off",
    },
    hydrationKeys: {
      strings: [
        "nic-crlim-min",
        "nic-crlim-max",
        "nic-crlim-default",
        "nic-cash-limit-acc-rate",
        "nic-cash-limit-card-rate",
        "nic-ovl-auth-var",
        "nic-ch_agrm-canc-crlim-nullify-acc",
        "nic-ch_agrm-canc-crlim-nullify-card",
        "nic-nullify-crlim-acc-acc_st-8",
        "nic-nullify-crlim-acc-acc_st-9",
        "nic-nullify-crlim-acc-acc_st-z",
      ],
    },
  },
  "payment-and-delinquency-settings": {
    labelMap: {
      "payment-and-delinquency-settings": "Payment and Delinquency Settings",
      "nic-due-date-days": "Payment due offset (days)",
      "nic-dlq-date-days": "Delinquency aging offset (days)",
      "sms-due-reminder-flag": "Enable SMS due reminders",
      "nic-sms-due-reminder-days": "SMS due reminder offset (days)",
      "nic-fp-date-days": "Full payment date offset (days)",
      "nic-fp-date-base": "Full payment date base",
      "nic-lp-date-days": "Late payment offset days",
      "nic-lp-date-base": "Late Payment trigger date",
      "nic-dlq-date-base": "Delinquency aging trigger",
    },
    hydrationKeys: {
      strings: [
        "nic-due-date-days",
        "nic-dlq-date-days",
        "sms-due-reminder-flag",
        "nic-sms-due-reminder-days",
        "nic-fp-date-days",
        "nic-fp-date-base",
        "nic-lp-date-days",
        "nic-lp-date-base",
        "nic-dlq-date-base",
      ],
    },
  },
  "minimum-to-pay-formula": {
    labelMap: {
      "minimum-to-pay-formula": "Minimum to Pay Formula",
      "nic-mtp-am-formula": "Balance components that contributed fully into MTP (once they are billed)",
      "nic-mtp-am-mode": "MTP mode for over limit",
      "": "MTP resulting formula",
    },
    hydrationKeys: {
      strings: ["nic-mtp-am-formula", "nic-mtp-am-mode"],
    },
  },
  "murabaha-profit-settings": {
    labelMap: {
      "murabaha-profit-settings": "Murabaha Profit Settings",
      "nic-int-rate-zero-all-fees": "Zero profit rate on all fees",
      "nic-int-rate-zero-all-int": "Zero profit rate on all profits",
      "murab-profit-rate": "Murabaha profit rate",
      "int-start": "Charge profit for transaction starting from",
      "int-start-numb": "Posting date or Transaction date",
    },
    hydrationKeys: {
      strings: [
        "nic-int-rate-zero-all-fees",
        "nic-int-rate-zero-all-int",
        "murab-profit-rate",
        "int-start",
        "int-start-numb",
      ],
    },
  },
  "tawarruq-profit-settings": {
    labelMap: {
      "tawarruq-profit-settings": "Tawarruq Profit Settings",
      "tawarruq-profit-type": "Tawarruq profit base",
      "tawarruq-profit-percentage": "Tawarruq profit percentage",
      "tawarruq-profit-amount": "Tawarruq profit amount",
      "tawarruq-sales-fee": "Apply Tawarruq sales fee",
      "tawarruq-sales-fee-type": "Sales fee base",
      "tawarruq-sales-fee-percentage": "Percentage or Fixed amount",
      "tawarruq-sales-fee-amount": "Tawarruq sales fee amount",
    },
    hydrationKeys: {
      strings: [
        "tawarruq-profit-type",
        "tawarruq-profit-percentage",
        "tawarruq-profit-amount",
        "tawarruq-sales-fee",
        "tawarruq-sales-fee-type",
        "tawarruq-sales-fee-amount",
        "tawarruq-sales-fee-percentage",
      ],
    },
  },
  "interest-grace-options": {
    labelMap: {
      "interest-grace-options": "Interest Grace Options",
      "nic-int-grace-retail": "Retail interest grace options",
      "nic-int-grace-cash": "Cash interest grace options",
      "nic-int-rate-zero-all-fees": "Zero interest rate on all fees",
      "nic-int-rate-zero-all-int": "Zero interest rate on all interests",
      "grace-opt": "Interest Grace Options",
    },
    hydrationKeys: {
      strings: [
        "grace-opt",
        "nic-int-grace-cash",
        "nic-int-grace-retail",
        "nic-int-rate-zero-all-fees",
        "nic-int-rate-zero-all-int",
      ],
    },
  },
  "direct-debit-configuration": {
    labelMap: {
      "direct-debit-configuration": "Direct Debit Configuration",
      "nic-dd-date-mode": "Direct debit day base",
      "nic-dd-date-day": "Direct debit day (number of days before due date)",
      "nic-dd-block-activ-cycle": "Skip direct debit request on activation cycle",
      "nic-sms-dd-request-days":
        "Period for sending SMS reminder about direct debit request (number of days after billing day)",
      "dd-amount-calc": "Direct debit amount calculation mode",
      "nic-dd-am-formula": "Direct debit resulting formula",
      "nic-dd-am-mode": "Direct debit amount mode (this field is implied based on context)",
      "dd-recovery-mode": "Direct debit request occurrences",
      "nic-dd-mbr": "Default direct debit member ID",
      "nic-dd-flag-def": "Activate direct debit feature by default for all accounts of the product",
      "dd-daily-recovery-opt": "Direct debit daily recovery option (if applicable)",
    },
    hydrationKeys: {
      strings: [
        "nic-dd-date-mode",
        "nic-dd-date-day",
        "nic-dd-block-activ-cycle",
        "nic-sms-dd-request-days",
        "dd-amount-calc",
        "nic-dd-am-formula",
        "nic-dd-am-mode",
        "dd-recovery-mode",
        "nic-dd-mbr",
        "nic-dd-flag-def",
      ],
      lists: ["dd-daily-recovery-opt"],
    },
  },
  "card-and-pin-maintenance": {
    labelMap: {
      "card-and-pin-maintenance": "Card and Pin Maintenance",
      "virt-phys": "What type of card do you want to issue?",
      perso: "Card personalization partners",
      PERSO_SUBSTANCE: "Network International partners (Perso Bureau)",
      "nic-card-subtype-exp-months": "Number of months from card issuing till card expiration",
      "nic-card-subtype-autorenew-days": "Number of days before card expiration to initiate card renewal process",
      "nic-card-carr-address-lev-prim": "Deliver primary cards and PINs",
      "nic-card-carr-address-lev-suppl": "Deliver supplementary cards and PINs",
      "reiss-phys": "Allow re-issuing of virtual cards into physical cards",
      "nic-preemb-auto-activate": "Virtual cards auto-activation",
      "nic-card-subtype-pinset-allowed": "PIN set is allowed",
      "nic-card-subtype-pinset-allowed-for-locked": "Change PIN allowed for locked plastics",
      sde: "Allow same day embossing",
    },
    hydrationKeys: {
      strings: [
        "virt-phys",
        "nic-card-subtype-exp-months",
        "nic-card-subtype-autorenew-days",
        "nic-card-carr-address-lev-prim",
        "nic-card-carr-address-lev-suppl",
        "reiss-phys",
        "nic-preemb-auto-activate",
        "nic-card-subtype-pinset-allowed",
        "nic-card-subtype-pinset-allowed-for-locked",
        "sde",
        "PERSO_SUBSTANCE",
        "perso",
      ],
    },
  },
  "payment-schemas-services": {
    labelMap: {
      "payment-schemas-services": "Payment Schemas Services",
      "nic-card-subtype-abu-active":
        "Automated updating 'card-on-file' information for recurring and non-recurring payments (ABU service from Payments Schemas)",
      "nic-card-subtype-token-used": "Tokenization (Visa / MasterCard)",
      "nic-card-subtype-token-mada-used": "Tokenization (Mada)",
    },
    hydrationKeys: {
      strings: ["nic-card-subtype-abu-active", "nic-card-subtype-token-used", "nic-card-subtype-token-mada-used"],
    },
  },
  "auto-closure": {
    labelMap: {
      "auto-closure": "Auto Closure",
      "auto-closure-enabled": "Enable auto-closure",
      "nic-acc-st-auto-close-per-type": "Pre-auto closure period type",
      "nic-acc-st-auto-close-per": "Pre-auto closure period number",
      "nic-acc-st-auto-dorm-eod": "Auto closure triggers",
    },
    hydrationKeys: {
      strings: [
        "auto-closure-enabled",
        "nic-acc-st-auto-close-per-type",
        "nic-acc-st-auto-close-per",
        "nic-acc-st-auto-dorm-eod",
      ],
    },
  },
  "fees-settings": {
    labelMap: {
      "fees-settings": "Fees Settings",
      "amf-acc-moment": "",
      "amf-card-moment": "",
      "nic-amf-card-waive-unexp": "",
      "nic-mmf-card-waive-unexp": "",
      "nic-inact-period-unit": "",
      "nic-inact-period": "",
      "nic-ovl-fee-repeat-mode": "",
      "nic-amf-acc-start-mode": "",
      "nic-amf-card-start-mode": "",
      "nic-stmt-fee-flag": "",
    },
    hydrationKeys: {},
  },
  "loyalty-settings": {
    labelMap: {
      "loyalty-settings": "Loyalty Settings",
      "nic-lty-flag-def": "Enable loyalty feature by default for all accounts from boarding",
      "nic-lty-flag-editable": "Allow account level control for loyalty enrolment/ disenrollment",
      "nic-lty-accr-yearly-start": "Choose yearly accrual auto-start mode",
      "lty-red-cash-a-tot": "Enable shared (cross-program) cashback auto-redemption",
      "nic-lty-red-cash-a-tot": "Enable cashback auto-redemption",
      "nic-lty-red-ext-a-tot-mbr": "Allow external cashback auto-redemption for members",
      "lty-welc-bonus-target": "Choose the loyalty program you want to apply welcome bonus",
      "nic-lty-bonus_w-timer-from": "Welcome bonus timer trigger (count down starts from)",
      "nic-lty-bonus_w-timer-per-type": "Welcome bonus count down timer period",
      "nic-lty-bonus_w-timer-per": "Welcome bonus count down timer (number of periods)",
    },
    hydrationKeys: {
      strings: [
        "nic-lty-flag-def",
        "nic-lty-flag-editable",
        "nic-lty-accr-yearly-start",
        "lty-red-cash-a-tot",
        "nic-lty-red-cash-a-tot",
        "nic-lty-red-ext-a-tot-mbr",
        "lty-welc-bonus-target",
        "nic-lty-bonus_w-timer-from",
        "nic-lty-bonus_w-timer-per-type",
        "nic-lty-bonus_w-timer-per",
      ],
    },
  },
  "sms-notification": {
    labelMap: {
      "sms-notification": "SMS Notification & Statement Generation",
      "nic-stmt-flag-def-new": "",
      "nic-autorenew-event-per": "",
      "nic-autorenew-event-per-type": "",
      "nic-sms-flag-def-new": "",
    },
    hydrationKeys: {},
  },
  "manual-closure": {
    labelMap: {
      "manual-closure": "Manual Closure",
      "nic-acc-st-man-close-days": "",
      "nic-card-st-man-close-days": "",
    },
    hydrationKeys: {},
  },
  purge: {
    labelMap: {
      purge: "Purge",
      "nic-acc-st-auto-purge-per-type": "Pre-purge period type (for accounts)",
      "nic-acc-st-auto-purge-per": "Pre-purge period number (for accounts)",
      "nic-card-st-auto-purge-per-type": "Pre-purge period type (for cards)",
      "nic-card-st-auto-purge-per": "Pre-purge period number (for cards)",
    },
    hydrationKeys: {},
  },
};

export const PRODUCT_EDIT_PAGES_CODES = {
  loyaltyPrograms: {
    "point-accrual-setup": {
      labelMap: {
        "point-accrual-setup": "Point Accrual Setup",
        type: "Choose loyalty program accrual type",
        "lty-program-cum-freq": "Choose point accrual frequency",
        "lty-program-acr-mode": "Choose cumulative program accrual mode",
      },
      hydrationKeys: {
        strings: ["type", "lty-program-cum-freq", "lty-program-acr-mode"],
      },
    },
    "point-redemption-setup": {
      labelMap: {
        "point-redemption-setup": "Point Redemption Setup",
        "lty-redeem-type": "Choose Redemption Type",
        "lty-cashback-auto-redeem": "Enable cashback auto-redemption",
        "lty-ext-auto-redeem": "Enable external auto-redemption",
        "lty-cashback-interval": "Choose cashback auto-redemption interval",
      },
      hydrationKeys: {
        strings: ["lty-redeem-type", "lty-cashback-auto-redeem", "lty-ext-auto-redeem", "lty-cashback-interval"],
      },
    },
  },

  insurancePrograms: {
    "main-insurance-settings": {
      labelMap: {
        "main-insurance-settings": "Main Insurance Program Settings",
        displayName: "Insurance Template Name",
        insuranceCompanyName: "Insurance Company Name",
        "ins-enroll-mode": "Choose Program Enrollment Mode",
      },
      hydrationKeys: {
        strings: ["displayName", "insuranceCompanyName", "ins-enroll-mode"],
      },
    },
    "coverage-settings": {
      labelMap: {
        "coverage-settings": "Insurance Coverage Settings",
        "ins-cov-trunc":
          "Specify system action in case account's outstanding balance exceeds the maximum coverage amount",
        "ins-cov-max": "Insurance maximum coverage amount",
        "ins-cov-ipp":
          "Default insurance covers: principal amount, unpaid fees, interests, VAT & due installments. Confirm whether to include unbilled payment plans installments",
      },
      hydrationKeys: {
        strings: ["ins-cov-trunc", "ins-cov-max", "ins-cov-ipp"],
      },
    },
    "premium-settings": {
      labelMap: {
        "premium-settings": "Premium Settings",
        "ins-prem-min": "Minimum insurance premium cap",
        "ins-prem-fixed": "Insurance fixed premium amount",
        "ins-prem-rate": "Insurance premium percentage",
        "ins-prem-period": "Specify period before charging the first insurance premium",
        "ins-prem-period-type": "Period type",
      },
      hydrationKeys: {
        strings: ["ins-prem-min", "ins-prem-fixed", "ins-prem-rate", "ins-prem-period", "ins-prem-period-type"],
      },
    },
    "waiving-settings": {
      labelMap: {
        "waiving-settings": "Waiving Settings",
        "ins-prem-waive-zero": "Waive premium on zero coverage amount",
        "ins-prem-waive": "Waive premium for transactor customer",
      },
      hydrationKeys: {
        strings: ["ins-prem-waive-zero", "ins-prem-waive"],
      },
    },
    "cancellation-settings": {
      labelMap: {
        "cancellation-settings": "Cancellation Settings",
        "ins-cncl-age-limit": "Insurance program cancellation age limit",
        "ins-cncl-delinq-auto": "Specify delinquency level to cancel insurance with auto re-instantiation",
        "ins-cncl-delinq-man": "Specify delinquency level to cancel insurance with manual re-instantiation",
      },
      hydrationKeys: {
        strings: ["ins-cncl-age-limit", "ins-cncl-delinq-auto", "ins-cncl-delinq-man"],
      },
    },
  },
};
