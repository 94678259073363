import { RoutesTreeItem } from "@ni/common/types";

import {
  LoyaltyApplyingToProducts,
  PointAccrualSetup,
  PointExpiry,
  PointRedemptionSetup,
  TransactionEligibility,
} from "../../../pages/tenant";

export const pagesRoute: RoutesTreeItem[] = [
  {
    name: "Transaction Eligibility",
    route: "transaction-eligibility",
    element: <TransactionEligibility />,
  },
  {
    name: "Point Accrual Setup",
    route: "point-accrual-setup",
    element: <PointAccrualSetup />,
  },
  {
    name: "Point Redemption Setup",
    route: "redemption-setup",
    element: <PointRedemptionSetup />,
  },
  {
    name: "Point Expiry",
    route: "point-expiry",
    element: <PointExpiry />,
  },
  {
    name: "Applying to Products",
    route: "applying-to-products",
    element: <LoyaltyApplyingToProducts />,
  },
];
