import { useCallback, useEffect } from "react";

import { useReduxState, useTenant } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { InsuranceProgramApi } from "@ni/sdk/apis";
import { ChangeInsuranceProgramTemplateRequest, InsuranceProgram, InsuranceProgramTemplateValue } from "@ni/sdk/models";

const insuranceApi = new InsuranceProgramApi();

export const useInsuranceProgramApi = (id?: number, productId?: number, tenantId?: number) => {
  const { tenant } = useTenant({ tenantId });

  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [currentInsuranceProgram, setCurrentInsuarnceProgram] =
    useReduxState<InsuranceProgram>("currentInsuranceProgramEdit");
  const [insurancePrograms, setInsuarncePrograms] = useReduxState<InsuranceProgram[]>("InsuranceProgramsEdit");

  const formValuesConverter = useCallback((formValues: FormValues): InsuranceProgramTemplateValue[] => {
    return Object.keys(formValues)?.map(
      (key: string) => ({ code: key, value: formValues[key] }) as InsuranceProgramTemplateValue,
    );
  }, []);

  const editInsurance = useCallback(
    async (insurance: Partial<ChangeInsuranceProgramTemplateRequest>) => {
      if (currentInsuranceProgram?.id) {
        setIsLoading(true);
        try {
          const response = await insuranceApi.edit3(insurance, currentInsuranceProgram?.id);
          setCurrentInsuarnceProgram({ ...currentInsuranceProgram, ...response.data });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    },
    [currentInsuranceProgram, setCurrentInsuarnceProgram, setIsLoading],
  );

  useEffect(() => {
    const getprogramById = async () => {
      try {
        const insurance = (await insuranceApi.getById2(id ?? 0)).data;
        const insurancePrograms = (await insuranceApi.getInsuranceProgramsByProductId(productId ?? 0)).data;
        setCurrentInsuarnceProgram(insurance);
        setInsuarncePrograms(insurancePrograms.filter(x => x.state === "ACTIVE"));
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    if (id && productId && (!currentInsuranceProgram || currentInsuranceProgram.id !== id)) {
      setIsLoading(true);
      void getprogramById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    currentInsuranceProgram,
    insurancePrograms,
    setInsuarncePrograms,
    editInsurance,
    formValuesConverter,
    currency: tenant?.insuranceProgramTemplates?.find(x => x.id === currentInsuranceProgram?.insuranceProgramTemplateId)
      ?.currency,
  };
};
