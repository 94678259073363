import { useCallback, useEffect } from "react";

import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { PlanApi } from "@ni/sdk/apis";
import { Plan, PlanPctValue, PlanValue } from "@ni/sdk/models";

const planApi = new PlanApi();

export const usePlanApi = (id?: number) => {
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const [currentPlan, setCurrentPlan] = useReduxState<Plan>("currentPlanEditIpp", undefined);

  const formValuesConverter = useCallback((formValues: FormValues, pctId?: number): PlanValue[] | PlanPctValue[] => {
    if (!pctId)
      return Object.keys(formValues)?.map((key: string) => ({ fieldCode: key, value: formValues[key] }) as PlanValue);
    return Object.keys(formValues)?.map(
      (key: string) => ({ fieldCode: key, value: formValues[key], pctId }) as PlanPctValue,
    );
  }, []);

  const editPlan = useCallback(
    async (plan: Partial<Plan>) => {
      if (currentPlan?.id) {
        setIsLoading(true);
        try {
          const response = await planApi.editPlan(plan, currentPlan?.id);
          setCurrentPlan({ ...currentPlan, ...response.data });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    },
    [currentPlan, setCurrentPlan, setIsLoading],
  );

  useEffect(() => {
    const getPlanById = async () => {
      try {
        const plan = (await planApi.getPlanById(id ?? 0)).data;
        setCurrentPlan(plan);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    if (id && (!currentPlan || currentPlan.id !== id)) {
      setIsLoading(true);
      void getPlanById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { currentPlan, editPlan, formValuesConverter };
};
