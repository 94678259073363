import { useCallback, useEffect, useRef, useState } from "react";
import { TablePaginationConfig } from "antd";

import { TenantApi } from "@ni/sdk/apis";
import { PageDataFullPlan, SortedFilteredPageRequest } from "@ni/sdk/models";

import { useReduxState } from "../store";

import { initialPlans, initialPlansPagination } from "./constants";

interface InstallmentPaymentPlansProps {
  tenantId: number;
  fetchEnabled: boolean;
  payload?: SortedFilteredPageRequest;
}

const tenantServiceApi = new TenantApi();

export const useInstallmentPaymentPlans = ({
  tenantId,
  fetchEnabled = true,
  payload = {},
}: InstallmentPaymentPlansProps) => {
  const [installmentPlans, setInstallmentPlans] = useReduxState<PageDataFullPlan>(
    "installmentPaymentPlans",
    initialPlans,
  );
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [pageHasContent, setPageHasContent] = useState<boolean>(false);
  const [filters, setFilters] = useState<SortedFilteredPageRequest>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>(initialPlansPagination);

  const initialLoadCompleteRef = useRef<boolean>(false);

  const getInstallmentPlans = useCallback(
    async (tenantId: number, currentPage: number, size: number, payload: SortedFilteredPageRequest = {}) => {
      setIsLoading(true);

      try {
        const { data } = await tenantServiceApi.searchForPlansOfTenant(
          {
            ...payload,
            pageLimits: {
              number: currentPage - 1,
              size,
            },
          },
          tenantId,
        );

        setPagination(pagination => ({ ...pagination, total: data.totalElements }));
        setInstallmentPlans(data ?? []);

        if (!initialLoadCompleteRef.current) {
          setPageHasContent(data.hasContent);
          initialLoadCompleteRef.current = true;
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [setIsLoading, setInstallmentPlans],
  );

  useEffect(() => {
    if (fetchEnabled)
      void getInstallmentPlans(
        tenantId,
        pagination.current ?? 1,
        pagination.pageSize ?? 10,
        Object.keys(filters).length === 0 ? payload : filters,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEnabled, getInstallmentPlans, tenantId, pagination.current, pagination.pageSize, filters]);

  return {
    installmentPlans,
    setInstallmentPlans,
    pageHasContent,
    pagination,
    setPagination,
    filters,
    setFilters,
  };
};
