import { useCallback } from "react";

import { useReduxState } from "@ni/common/hooks";
import { InsuranceProgramTemplateWizardApi } from "@ni/sdk/apis";
import { InsuranceProgram, InsuranceWizardRequest, InsuranceWizardResponse, Tenant, Workflow } from "@ni/sdk/models";

const wizardApi = new InsuranceProgramTemplateWizardApi();

export const useInsuranceWizardApi = () => {
  const [, setIsLoading] = useReduxState("isLoading", false);
  const [wizardResponse, setWizardResponse] = useReduxState<InsuranceWizardResponse>(
    "InsuranceWizardResponseState",
    {} as InsuranceWizardResponse,
  );
  const [appliedPrograms, setAppliedPrograms] = useReduxState<InsuranceProgram[]>("InsuranceAppliedPrograms", []);

  const initWizard = useCallback(async (tenantId: string) => {
    try {
      const response = await wizardApi.initWizard3({ tenantId: parseInt(tenantId, 10) });
      return {
        pageId: response.data.pageId,
        workflow: response.data.workflow,
        tenant: response.data.tenant,
      };
    } catch (error) {
      return {
        pageId: 0,
        workflow: {} as Workflow,
        tenant: {} as Tenant,
      };
    }
  }, []);

  const processWizardRequest = useCallback(
    async (insuranceWizardRequest: InsuranceWizardRequest, isApplyingToProducts?: boolean) => {
      setIsLoading(true);
      try {
        const response = await wizardApi.processInsuranceProgramTemplateWizardRequest(insuranceWizardRequest);
        if (isApplyingToProducts) {
          const newlyAdded =
            response?.data?.instantiatedInsurancePrograms?.filter(
              x => x.insuranceProgramTemplateId === insuranceWizardRequest?.insuranceTemplateId,
            ) ?? [];

          setAppliedPrograms(newlyAdded);
        }
        setWizardResponse(response.data);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    },
    [setAppliedPrograms, setIsLoading, setWizardResponse],
  );

  return {
    initWizard,
    wizardResponse,
    processWizardRequest,
    appliedPrograms,
  };
};
