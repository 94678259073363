import { FC } from "react";
import { Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { useHydrateForm, useProductSettings } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { CustomFormWrapper, NetworkForm } from "@ni/common/ui";

import {
  balanceComponentsOptions,
  delinquencyAgingDayOptions,
  delinquencyAgingTriggerOptions,
  fullPaymentDateOptions,
  latePaymentDateOptions,
  latePaymentTriggerOptions,
  paymentDueDateOptions,
  smsDueReminderFlag,
  smsDueReminderOptions,
} from "./constants";

export const PaymentAndDelinquencySettings: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const { productId } = useParams<{ id: string; productId: string }>();
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  useHydrateForm({
    form,
    entityFields: [...(currentProduct?.productValues ?? [])],
    keys: {
      strings: [
        paymentDueDateOptions.code,
        delinquencyAgingDayOptions.code,
        smsDueReminderFlag.code,
        smsDueReminderOptions.code,
        fullPaymentDateOptions.code,
        balanceComponentsOptions.code,
        latePaymentDateOptions.code,
        latePaymentTriggerOptions.code,
        delinquencyAgingTriggerOptions.code,
      ],
    },
    allowParse: true,
  });

  const hasSmsDueReminder = Form.useWatch<string>(smsDueReminderFlag.code, form)?.toString() === "true";
  const dueDateDays = +(Form.useWatch<string>(paymentDueDateOptions.code, form) as unknown as number);

  const onFinish = onUpdateProduct;

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Payment and Delinquency Settings"
      pageSubtitle="Payment due dates, delinquency and late payment events, allows for optimizing and managing the payment process. Additionally setting SMS due reminders enhances customer experience and improves collection efficiency. Offset days can be customized for each pricing control table after wizard."
      size="md"
      formSize="full"
      gap={24}
      level="root"
      submitHandler={onFinish}
    >
      <NetworkForm.Number
        formItemOptions={{
          name: paymentDueDateOptions.code,
          label: paymentDueDateOptions.name,
          initialValue: paymentDueDateOptions.default,
          tooltip: paymentDueDateOptions.tooltip,
          rules: [
            { required: true, message: "Payment due date is required!" },
            {
              type: "number",
              max: paymentDueDateOptions.max as number,
              message: "Due date cannot exceed cycle length",
            },
          ],
        }}
        min={paymentDueDateOptions.min}
        precision={paymentDueDateOptions.decimalsNumber}
      />

      <Space direction="vertical" size={20}>
        <NetworkForm.Radio
          formItemOptions={{
            name: balanceComponentsOptions.code,
            label: balanceComponentsOptions.name,
            initialValue: balanceComponentsOptions.default,
            tooltip: balanceComponentsOptions.tooltip,
          }}
          radioList={balanceComponentsOptions.options}
          initialValue={balanceComponentsOptions.default as string}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: fullPaymentDateOptions.code,
            label: fullPaymentDateOptions.label,
            tooltip: fullPaymentDateOptions.tooltip,
            rules: [
              {
                type: "number",
                max: 30 - dueDateDays,
                message: "Late payment date cannot exceed remaining cycle days after setting a due date",
              },
            ],
          }}
          {...fullPaymentDateOptions.props}
        />
      </Space>
      <Space direction="vertical" size={20}>
        <NetworkForm.Switch
          formItemOptions={{
            name: smsDueReminderFlag.code,
            valuePropName: "checked",
            label: smsDueReminderFlag.name,
            tooltip: smsDueReminderFlag.tooltip,
            initialValue: smsDueReminderFlag.default,
          }}
        />

        {hasSmsDueReminder && (
          <NetworkForm.Number
            formItemOptions={{
              name: smsDueReminderOptions.code,
              label: smsDueReminderOptions.name,
              initialValue: smsDueReminderOptions.default,
              rules: [{ required: true, message: "SMS due reminder is required!" }],
              tooltip: smsDueReminderOptions.tooltip,
            }}
            min={smsDueReminderOptions.min}
            precision={smsDueReminderOptions.decimalsNumber}
          />
        )}
      </Space>
      <Space direction="vertical" size={20}>
        <NetworkForm.Radio
          formItemOptions={{
            name: latePaymentTriggerOptions.code,
            label: latePaymentTriggerOptions.name,
            initialValue: latePaymentTriggerOptions.default,
            tooltip: latePaymentTriggerOptions.tooltip,
          }}
          radioList={latePaymentTriggerOptions.options}
          initialValue={latePaymentTriggerOptions.default as string}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: latePaymentDateOptions.code,
            label: latePaymentDateOptions.name,
            initialValue: latePaymentDateOptions.default,
            tooltip: latePaymentDateOptions.tooltip,
            rules: [
              {
                type: "number",
                max: (latePaymentDateOptions.max as number) - dueDateDays,
                message: "Late payment date cannot exceed remaining cycle days after setting a due date",
              },
            ],
          }}
          min={latePaymentDateOptions.min}
          precision={latePaymentDateOptions.decimalsNumber}
        />
      </Space>
      <Space direction="vertical" size={20}>
        <NetworkForm.Radio
          formItemOptions={{
            name: delinquencyAgingTriggerOptions.code,
            label: delinquencyAgingTriggerOptions.name,
            initialValue: delinquencyAgingTriggerOptions.default,
            tooltip: delinquencyAgingTriggerOptions.tooltip,
          }}
          radioList={delinquencyAgingTriggerOptions.options}
          initialValue={delinquencyAgingTriggerOptions.default as string}
        />

        <NetworkForm.Number
          formItemOptions={{
            name: delinquencyAgingDayOptions.code,
            label: delinquencyAgingDayOptions.name,
            initialValue: delinquencyAgingDayOptions.default,
            tooltip: delinquencyAgingDayOptions.tooltip,
            rules: [
              { required: true, message: "Delinquency aging day is required!" },
              {
                type: "number",
                max: (delinquencyAgingDayOptions.max as number) - (dueDateDays ?? 0),
                message: "Delinquency date cannot exceed remaining cycle days after setting a due date",
              },
            ],
          }}
          min={delinquencyAgingDayOptions.min}
          precision={paymentDueDateOptions.decimalsNumber}
        />
      </Space>
    </CustomFormWrapper>
  );
};
