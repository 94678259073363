import { FC } from "react";
import { Form } from "antd";

import { NIC_CCY } from "@ni/common/constants";
import { usePrepareMultiCurrency, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues, MultiCurrency } from "@ni/common/types";
import { CustomFormWrapper, MultiCurrencyTable, TooltipInfo } from "@ni/common/ui";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const wizardServicesApi = new ProductAndTenantWizardApi();

export const MultiCurrencyPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [isLoading, setIsLoading] = useReduxState<boolean>("isLoading");

  const { dataSource, currenciesDictionary, currenciesMaxThreshold, setDataSource } = usePrepareMultiCurrency(
    wizardResponse.product,
  );

  const onFinish = () => {
    setIsLoading(true);

    const baseCurrencyObject = dataSource.find(item => item.baseCurrency);

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        [NIC_CCY]: baseCurrencyObject?.currencyCode as string,
        "nic-prod-ppm-w00-prior": String(baseCurrencyObject?.priority),
        ...dataSource
          .filter(item => item.baseCurrency === false)
          .reduce((acc: { [key: string]: string }, item: MultiCurrency, index: number): { [key: string]: string } => {
            const currencyKey = `nic-prod-ppm-w${String(index + 1).padStart(2, "0")}-ccy`;
            const autoKey = `nic-prod-ppm-w${String(index + 1).padStart(2, "0")}-to-autoopen`;
            const priority = `nic-prod-ppm-w${String(index + 1).padStart(2, "0")}-prior`;

            return {
              ...acc,
              [currencyKey]: item.currencyCode,
              [autoKey]: item.autoOpening.toString(),
              [priority]: String(item.priority),
            };
          }, {}),
        displayName:
          wizardResponse.product?.displayName?.replace(
            wizardResponse.product.productValues?.find(value => value.fieldCode === NIC_CCY)?.value ?? "",
            dataSource.find(currency => currency.baseCurrency)?.currencyCode ?? "",
          ) ?? "",
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      pageTitle="Multi-currency Details"
      pageSubtitle={
        <TooltipInfo
          label={`Set base currency and select up to ${currenciesMaxThreshold} additional wallets. Define their priority.`}
          code="currencies"
          tooltipProps={{
            title:
              "A wallet corresponding to a transaction currency will be debited first. In case of insufficient funds, the system will automatically debit the wallets in order of priority. To change the priority, simply drag and drop the rows.",
            overlayStyle: { maxWidth: "350px" },
          }}
        />
      }
      size="full"
      formSize="full"
      gap={16}
      level="root"
      submitHandler={onFinish}
    >
      <Form.Item name="currencies">
        <MultiCurrencyTable
          setDataSource={setDataSource}
          isLoading={isLoading}
          countriesDictionary={currenciesDictionary}
          dataSource={dataSource}
          currenciesMaxThreshold={currenciesMaxThreshold}
          formDisabled={formDisabled}
        />
      </Form.Item>
    </CustomFormWrapper>
  );
};
