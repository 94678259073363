export const durationConvertHandler = (duration: string): number => {
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:([\d.]+)S)?/;
  const matches = duration?.match(regex);

  if (!matches) {
    return 0;
  }

  const hours = matches[1] ? parseInt(matches[1], 10) : 0;
  const minutes = matches[2] ? parseInt(matches[2], 10) : 0;
  const seconds = matches[3] ? parseFloat(matches[3]) : 0;

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};
