export const checkboxFieldsPage9: { [key: string]: string[] } = {
  "nic-a-d-cash-atm-enable": ["nic-a-d-cash-atm-max-am", "nic-a-d-cash-atm-max-nr", "nic-a-d-cash-atm-max-single"],
  "nic-a-d-cash-dom-enable": ["nic-a-d-cash-dom-max-am", "nic-a-d-cash-dom-max-nr", "nic-a-d-cash-dom-max-single"],
  "nic-a-d-cash-enable": ["nic-a-d-cash-max-am", "nic-a-d-cash-max-nr", "nic-a-d-cash-max-single"],
  "nic-a-d-cash-for-enable": ["nic-a-d-cash-for-max-am", "nic-a-d-cash-for-max-nr", "nic-a-d-cash-for-max-single"],
  "nic-a-d-cash-pos-enable": ["nic-a-d-cash-pos-max-am", "nic-a-d-cash-pos-max-nr", "nic-a-d-cash-pos-max-single"],
  "nic-a-d-ecomm-enable": ["nic-a-d-ecomm-max-am", "nic-a-d-ecomm-max-nr", "nic-a-d-ecomm-max-single"],
  "nic-a-d-retail-dom-enable": [
    "nic-a-d-retail-dom-max-am",
    "nic-a-d-retail-dom-max-nr",
    "nic-a-d-retail-dom-max-single",
  ],
  "nic-a-d-retail-enable": ["nic-a-d-retail-max-am", "nic-a-d-retail-max-nr", "nic-a-d-retail-max-single"],
  "nic-a-d-retail-for-enable": [
    "nic-a-d-retail-for-max-am",
    "nic-a-d-retail-for-max-nr",
    "nic-a-d-retail-for-max-single",
  ],
  "nic-a-d-total-enable": ["nic-a-d-total-max-am", "nic-a-d-total-max-nr", "nic-a-d-total-max-single"],
};

export const switchHeader9: { [key: string]: string[] } = {
  "acc-daily-all-cash-enable": [
    "nic-a-d-cash-atm-enable",
    "nic-a-d-cash-dom-enable",
    "nic-a-d-cash-enable",
    "nic-a-d-cash-for-enable",
    "nic-a-d-cash-pos-enable",
  ],
  "acc-daily-all-retail-enable": [
    "nic-a-d-ecomm-enable",
    "nic-a-d-retail-dom-enable",
    "nic-a-d-retail-enable",
    "nic-a-d-retail-for-enable",
  ],
  "acc-daily-all-total-enable": ["nic-a-d-total-enable"],
};
