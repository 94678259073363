import { FC } from "react";
import { Form } from "antd";
import { FormInstance } from "rc-field-form";

import { BAL_INQ_ENABLED, NIC_BAL_INQ_FEE_OTHER_MAX_NRR, NIC_BAL_INQ_FEE_OTHER_VAL } from "@ni/common/constants";
import { NetworkForm, PageItemLayoutGeneral } from "@ni/common/ui";

import styles from "../../styles.module.scss";

interface DefaultInquiryFeeProps {
  isEnabled: boolean;
  productCurrency: string;
  form: FormInstance;
}

export const DefaultInquiryFee: FC<DefaultInquiryFeeProps> = ({ isEnabled, productCurrency, form }) => (
  <div className={styles["default-inquiry-fee"]}>
    <PageItemLayoutGeneral>
      <Form.Item dependencies={[BAL_INQ_ENABLED]}>
        {() => {
          return (
            <NetworkForm.Number
              formItemOptions={{
                name: NIC_BAL_INQ_FEE_OTHER_VAL,
                label: "Default fee amount",
                rules: [
                  {
                    validator: (_, value) => (value === 0 ? Promise.reject() : Promise.resolve()),
                    message: "Minimum fee amount can not be 0",
                  },
                  {
                    required: (form.getFieldValue(BAL_INQ_ENABLED) as boolean) || false,
                    message: "Default fee amount is required",
                  },
                ],
              }}
              precision={2}
              addonAfter={productCurrency}
              disabled={!isEnabled}
            />
          );
        }}
      </Form.Item>

      <Form.Item dependencies={[BAL_INQ_ENABLED]}>
        {() => {
          return (
            <NetworkForm.Number
              formItemOptions={{
                name: NIC_BAL_INQ_FEE_OTHER_MAX_NRR,
                label: "Free balance inquiries per billing cycle",
                tooltip: "Specify number of free of charge balance inquiries per statement cycle",
              }}
              min={0}
              precision={0}
              disabled={!isEnabled}
            />
          );
        }}
      </Form.Item>
    </PageItemLayoutGeneral>
  </div>
);
